import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// eslint-disable-next-line
declare let uetq: any;

@Injectable()
export class BingService {
	constructor(private router: Router) {
		this.setupPageView();
	}

	/**
	 * Append the tracking script once the application has loaded.
	 *
	 */
	public appendScript(): void {
		// This is an iframe, do not load tag.
		if (window.location !== window.parent.location) {
			return;
		}

		const elem = document.createElement('script');

		elem.innerHTML = `
		(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"25076321"};
		o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,
		n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&
		s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],
		i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
		`;

		document.body.appendChild(elem);
	}

	public signup(): void {
		this.send('sign-up', { event_category: 'lead' });
	}

	public chat(): void {
		this.send('chat', { event_category: 'chat' });
	}

	/**
	 * Track the purchase event.
	 */
	public purchase(data: object): void {
		if (!data['cart']) {
			return;
		}
		const cart = data['cart'];

		this.send('purchase', {
			event_category: 'purchase',
			revenue_value: +cart.total,
			currency: 'USD',
		});
	}

	/**
	 * Send page view on navigation.
	 *
	 * @since 1.9.0
	 */
	public setupPageView(): void {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.send('page_view', {
					page_path: '/central' + event.urlAfterRedirects,
				});
			}
		});
	}

	/**
	 * Send the data to twitter.
	 *
	 * Simply a wrapper of the uetq method.
	 *
	 * @param   data      Data to pass to bing.
	 */
	public send(action: string, data?: object): void {
		if ('undefined' !== typeof uetq) {
			uetq = uetq || [];
			uetq.push('event', action, data || {});
		}
	}
}
