import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@central/ng-shared';
import { ProjectService } from '../../../project/project.service';
import { ScheduleService } from '../../../project/site/scheduler.service';
import { InstallEstimate } from './install-estimate';
import { map } from 'rxjs/operators';
import { concat } from 'rxjs';
import { LicenseService } from '../../../shared/license/license.service';

@Component({
	selector: 'app-install-snap',
	templateUrl: './install-snap.component.html',
	styleUrls: [
		'../app-access/app-access.component.scss',
		'./install-snap.component.scss',
	],
})
export class InstallSnapComponent implements OnInit {
	public snapId;
	public state = 'loading';
	public currentEnvironment = false;
	public installState = 'pending';
	public snap;
	public estimate: number;
	public cloudsUsed = 0;
	public currentCloudLimit = 0;
	public cloudsRemaining = 0;
	public offerCurrentEnvironment = false;
	public showUpgrade = false;

	constructor(
		public activatedRoute: ActivatedRoute,
		private apiService: ApiService,
		private projectService: ProjectService,
		private licenseService: LicenseService,
		private schedulerService: ScheduleService,
		public router: Router
	) {
		this.offerCurrentEnvironment = !!+this.projectService.environment.is_development;
	}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params['snap_id']) {
				this.snapId = params['snap_id'];
			} else {
				this.router.navigate(['/404']);
			}

			this.currentEnvironment = !!parseInt(params['current_env'], 10);

			this.licenseService.onReady().subscribe(() => {
				const cwpLicense =
					this.licenseService.getAccountLicense('cloud-wordpress');
				this.currentCloudLimit = cwpLicense.data?.limit;

				concat(this.fetchCloudsUsed(), this.fetchSnap()).subscribe(
					() => {},
					() => (this.state = 'failed'),
					() => (this.state = 'success')
				);
			});
		});
	}

	public login() {
		this.projectService.login();

		this.router.navigate([
			'projects',
			this.projectService.project.id,
			'site',
		]);
	}

	public fetchCloudsUsed() {
		return this.apiService
			.get('/v1/cloud-wordpress', {
				headerOptions: {
					xOrgId: this.projectService.project.fields.organization_id,
				},
			})
			.pipe(
				map((clouds: object[]) => {
					this.cloudsUsed = clouds.filter(
						(val: any) => 'active' === val.status
					).length;
					this.cloudsRemaining =
						this.currentCloudLimit - this.cloudsUsed;
				})
			);
	}

	public fetchSnap() {
		return this.apiService.get('getSnap', { snap_id: this.snapId }).pipe(
			map((response) => {
				this.snap = response;

				const installEstimate = new InstallEstimate();
				this.estimate = installEstimate.getEstimate(this.snap);

				// Install into the current environment automatically.
				if (this.currentEnvironment) {
					this.installCurrent();
				}
			})
		);
	}

	public installCurrent() {
		this.state = 'installing';

		this.schedulerService
			.runner('installSnap', this.snap, this.projectService.environment)
			.subscribe(
				() => {},
				() => (this.state = 'failed'),
				() => (this.state = 'install-complete')
			);
	}

	public install() {
		if (!this.currentEnvironment && this.cloudsRemaining === 0) {
			this.showUpgrade = true;
			return;
		}

		this.state = 'installing';
		this.projectService
			.addEnvironment({ label: `${this.snap.label} - Snap` })
			.subscribe(
				() => {},
				() => (this.state = 'failed'),
				() => {
					this.schedulerService
						.runner(
							'installSnap',
							this.snap,
							this.projectService.stagingEnvironment
						)
						.subscribe(
							() => {},
							() => {
								this.state = 'failed';
							},
							() => {
								this.projectService.project.children.push(
									this.projectService.stagingEnvironment
								);
								this.projectService.setEnvironment(
									this.projectService.stagingEnvironment.id
								);
								this.state = 'install-complete';
							}
						);
				}
			);
	}
}
