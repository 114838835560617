import { Input, Component, Output, OnInit, EventEmitter } from '@angular/core';
import { ProjectService } from '../../project.service';

@Component({
	selector: 'app-wpstats-card',
	templateUrl: './wpstats-card.component.html',
})
export class WpstatsCardComponent {
	@Input() public stats = {};
	@Output() updatesPending = new EventEmitter();

	public updates = 0;
	public recommendations = 0;
	public hasUpdates = false;
	public updateThemes = [];
	public updatePlugins = [];
	public summary = '';

	constructor(public projectService: ProjectService) { }

	ngOnInit() {
		this.checkThemeUpdates();
		this.checkPluginUpdates();
		this.getHealthSummary();
		this.hasUpdates = this.checkHasUpdates();
		//this.updatesPending.emit(this.checkHasUpdates());
	}

	/**
	 * Does this user have pending updates.
	 */
	public checkHasUpdates(): boolean {
		this.updates += this.updateThemes.length;
		this.updates += this.updatePlugins.length;

		return !!(
			this.hasWpUpdate() ||
			this.updateThemes.length ||
			this.updatePlugins.length
		);
	}

	/**
	 * Does the user need a WP update.
	 */
	public hasWpUpdate(): boolean {
		return this.stats['debug']['wp-core']['fields']['version'][
			'value'
		].match('Latest version');
	}

	/**
	 * Determine which themes need updates.
	 */
	public checkThemeUpdates(): void {
		const themes = this.getInactiveThemes();
		themes.push(this.stats['debug']['wp-active-theme'].fields);

		for (const theme of themes) {
			const test = theme['version']
				? theme['version'].value
				: theme['value'];

			if (test.match('Latest version')) {
				this.updateThemes.push(theme);
			}
		}
	}

	/**
	 * Determine which plugins need updates.
	 */
	public checkPluginUpdates(): void {
		let plugins = Object.values(
			this.stats['debug']['wp-plugins-inactive'].fields
		);
		plugins = plugins.concat(
			Object.values(this.stats['debug']['wp-plugins-active'].fields)
		);

		for (const plugin of plugins) {
			const test = plugin['version']
				? plugin['version'].value
				: plugin['value'];

			if (test.match('Latest version')) {
				this.updatePlugins.push(plugin);
			}
		}
	}

	/**
	 * Get a list of inactive themes.
	 *
	 * @return Themes.
	 */
	public getInactiveThemes(): any[] {
		let themes = [];
		if (this.stats['debug']['wp-themes-inactive']) {
			themes = Object.values(
				this.stats['debug']['wp-themes-inactive'].fields
			);
		}
		return themes;
	}

	/**
	 * Remove theme and plugin slugs from label
	 *
	 * @return clean name
	 */
	public stripSlug(name: string): string {
		return name.replace(/\(.*\)/, '');
	}

	/**
	 * Get the number of active plugins.
	 */
	public countPlugins() {
		return Object.entries(this.stats['debug']['wp-plugins-active'].fields)
			.length;
	}

	/**
	 * Get text for site health summary.
	 *
	 * @return Site health summary.
	 */
	public getHealthSummary(): string {
		const sort = {};
		for (const test of this.stats['health']) {
			sort[test.status] = sort[test.status] || 0;
			sort[test.status]++;
		}

		const getSummary = (type: string) => `${sort[type]} ${type} issue${sort[type] > 1 ? 's' : ''}`;

		let summary: string;

		if (this.projectService.configService.hasFeature('IMC-683')) {
			if (sort['critical']) {
				this.updates += sort['critical'] - (this.hasWpUpdate() ? 1 : 0);
				this.recommendations = sort['critical'];
				this.summary = sort['critical'] + ' Critical';
			} else if (sort['recommended']) {
				this.recommendations = sort['recommended'];
				this.summary = sort['recommended'] + ' Minor';
			} else {
				this.summary = "0 Health";
			}
		} else {
			if (sort['critical']) {
				summary = getSummary('critical');
			} else if (sort['recommended']) {
				summary = getSummary('recommended');
			} else {
				summary = "Everything's looking good!";
			}
		}

		this.updatesPending.emit(this.updates);

		return summary;
	}
}
