import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AccountService, ApiService, ProfileService } from '@central/ng-shared';

import { DomainThirdPartyDeleteDialogComponent } from './domain-third-party-delete-dialog/domain-third-party-delete-dialog.component';
import { DomainThirdPartyAddDialogComponent } from './domain-third-party-add-dialog/domain-third-party-add-dialog.component';

@Component({
	selector: 'central-domain',
	templateUrl: './domain.component.html',
	styleUrls: ['./domain.component.scss'],
})
export class DomainComponent implements OnInit {
	public firstPartyRequestState = 'loading';
	public thirdPartyRequestState = 'loading';

	public deletingRecordIndex = -1;
	public thirdPartyDeleteRequestState = 'inactive';

	private adminAccountIds = [];

	public firstPartyDomains = [];
	public thirdPartyDomains = [];

	public allDomains = [];

	public displayedColumns = ['domain', 'owner', 'manage'];

	constructor(
		public accountService: AccountService,
		private apiService: ApiService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private profileService: ProfileService
	) {}

	ngOnInit(): void {
		this.profileService.onReady().subscribe(() => {
			this.profileService.data.account_access.forEach((account) => {
				if (account.role === 'owner' || account.role === 'maintainer') {
					this.adminAccountIds.push(account.account_id);
				}
			});

			// Load first-party domains
			this.apiService.get('/v1/domain-service/domain', {}).subscribe(
				(response: any[]) => {
					response.sort().forEach((domain) => {
						if (this.adminAccountIds?.includes(domain.account_id)) {
							this.firstPartyDomains.push(domain);
						}
					});

					this.firstPartyDomains.forEach((domain) => {
						this.allDomains.push({
							id: null,
							domainName: domain.name,
							accountId: domain.account_id,
						});
					});

					this.thirdPartyDomains.sort(function (
						firstDomain,
						secondDomain
					) {
						if (firstDomain.domainName > secondDomain.domainName) {
							return 1;
						}

						if (firstDomain.domainName < secondDomain.domainName) {
							return -1;
						}

						return 0;
					});

					this.firstPartyRequestState = 'succeeded';
					this.sortAllDomains();
				},
				() => {
					this.firstPartyRequestState = 'failed';
				}
			);

			// Load third-party domains
			this.apiService.get('/v1/domains', {}).subscribe(
				(response: any[]) => {
					// const currentAccount = this.accountService.account.organization_id || null;
					// const userAccountId = this.accountService.authService.getAccountId();

					// // Filter out third-party domains that are not owned by the current account.
					// response = response.filter( ( domain ) => {
					// 	if ( currentAccount ) {
					// 		return currentAccount === domain.fields.organization_id;
					// 	} else {
					// 		return userAccountId === domain.fields.organization_id || ! domain.fields.organization_id;
					// 	}
					// } );

					response
						.sort(function (firstDomain, secondDomain) {
							if (firstDomain.label > secondDomain.label) {
								return 1;
							}

							if (firstDomain.label < secondDomain.label) {
								return -1;
							}

							return 0;
						})
						.forEach((domain) => {
							if (
								this.adminAccountIds?.includes(
									domain.fields.organization_id
								)
							) {
								this.thirdPartyDomains.push(domain);
							}
						});

					this.thirdPartyDomains.forEach((domain) => {
						this.allDomains.push({
							id: domain.id,
							domainName: domain.label,
							accountId: domain.fields.organization_id,
						});
					});

					this.thirdPartyRequestState = 'succeeded';
					this.sortAllDomains();
				},
				(error) => {
					this.thirdPartyRequestState = 'failed';
				}
			);
		});
	}

	private sortAllDomains() {
		this.allDomains.sort(function (firstDomain, secondDomain) {
			if (firstDomain.domainName > secondDomain.domainName) {
				return 1;
			}

			if (firstDomain.domainName < secondDomain.domainName) {
				return -1;
			}

			return 0;
		});
	}

	public getAccountNameFromId(accountId) {
		const access = this.profileService.data.account_access.find(
			(acc) => acc.account_id === accountId
		);

		let name = this.profileService.data.display_name || 'You';
		if (access) {
			name = access.display_name;
		}

		return name;
	}

	addThirdPartyDomain() {
		const dialogRef = this.dialog.open(
			DomainThirdPartyAddDialogComponent,
			{
				width: '500px',
			}
		);

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				console.log(result)
			}
		});
    }

    deleteThirdPartyDomain(thirdPartyDomain, index) {
		const dialogRef = this.dialog.open(
			DomainThirdPartyDeleteDialogComponent,
			{
				width: '500px',
				data: { thirdPartyDomain: thirdPartyDomain },
			}
		);

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.deletingRecordIndex = index;
				this.thirdPartyDeleteRequestState = 'loading';

				this.apiService
					.delete('/v1/domains/' + thirdPartyDomain.id, {})
					.subscribe(
						(response: any[]) => {
							this.thirdPartyDomains =
								this.thirdPartyDomains.filter(
									(domain) =>
										domain.id !== thirdPartyDomain.id
								);
							this.allDomains = this.allDomains.filter(
								(domain) => domain.id !== thirdPartyDomain.id
							);
							this.thirdPartyDeleteRequestState = 'inactive';
						},
						(error) => {
							this.thirdPartyDeleteRequestState = 'inactive';
							this.snackBar.open('Error deleting domain', '', {
								duration: 3000,
							});
						}
					);
			}
		});
	}
}
