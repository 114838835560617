import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ApiService } from '@central/ng-shared';
import { DEFAULT_THIRD_PARTY_DNS_ZONE_DATA } from '../domain-constants';
import { Config } from '../../shared';

@Component({
	selector: 'central-domain-third-party-add-dialog',
	templateUrl: './domain-third-party-add-dialog.component.html',
	styleUrls: ['./domain-third-party-add-dialog.component.scss'],
})
export class DomainThirdPartyAddDialogComponent {
    public ownerAccountId = '';
    public isReady = false;
    public domainIsValid = false;
    public domainName = '';
    public progressButtonState = 'pending';

    @Output() dnsAuthority = new EventEmitter();

    constructor(
        public router: Router,
		private apiService: ApiService,
		private snackBar: MatSnackBar,
		private httpClient: HttpClient,
		public dialogRef: MatDialogRef<DomainThirdPartyAddDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
    ) { }

    public accountChanged($event) {
		setTimeout(() => {
			this.ownerAccountId = $event['account_id'];
			this.isReady = true;
		}, 0);
	}

    public domainUpdated(event) {
        if (event && typeof (event) === 'string') {
            this.domainName = event;
            this.domainIsValid = true;
        } else {
            this.domainIsValid = false;
        }
    }

    public addDomain() {
        this.domainName = this.domainName.trim().toLowerCase();
        this.progressButtonState = 'submitted';


			// Make API call to add domain
			this.apiService
				.post(`/v1/accounts/${this.ownerAccountId}/domains`, {
					label: this.domainName,
				})
				.subscribe(
					(response) => {
						const domainObject = response;
						// Make API call to create zone file
						const url =
							Config['host'] + '/v1/domain-service/dns-zone/';
						const headers = this.apiService.getHeaders({
							contentType: 'application/json',
						});
						const body = {
							domainName: domainObject['id'],
							zoneData: DEFAULT_THIRD_PARTY_DNS_ZONE_DATA,
						};
						this.httpClient.patch(url, body, { headers }).subscribe(
							() => {
                                this.router.navigate(['/domains/dns-manager', domainObject['id']]);
                                this.snackBar.open(domainObject['label'] + ' added', '', {
									duration: 3000,
                                });
                                this.dialogRef.close();
                                this.progressButtonState = '';
                                this.domainName = '';
							},
                            () => {
								// Make API call to delete domain on failure
								this.apiService
									.delete(
										'/v1/domains/' + domainObject['id'],
										{}
									)
									.subscribe();

								this.snackBar.open('Error adding domain', '', {
									duration: 3000,
								});

								this.progressButtonState = '';
							}
						);
					},
                    (error) => {
                        if (error.errors && error.errors.length) {
                            this.dnsAuthority.emit(error.errors[0].message);
                            this.domainIsValid = false;
                        } else {
                            console.log(error);
                        }
                        this.progressButtonState = '';
					}
				);

    }
}
