<mat-progress-bar *ngIf="statsState === 'pending'" mode="indeterminate" color="primary"></mat-progress-bar>

<div *ngIf="statsState === 'success'"
	class="actions animate__animated animate__fadeIn ctw-grid ctw-grid-cols-2 ctw-mx-auto ctw-mt-8 ctw-px-8">
	<a *ngIf="this.projectService.environment.fields.machine_details" mat-raised-button color="secondary"
		target="_blank" [href]="this.projectService.environment.fields.site_url">Live Site
		<mat-icon>open_in_new</mat-icon></a>
	<a *ngIf="!this.projectService.environment.fields.machine_details" mat-raised-button class="playground"
		target="_blank" [href]="this.projectService.environment.fields.site_url">Playground Site
		<mat-icon>open_in_new</mat-icon></a>
	<button mat-raised-button color="primary" (click)="
			this.projectService.wpRestService.login(
				this.projectService.environment,
				'wp-admin/'
			)
		">
		WP Admin <mat-icon>open_in_new</mat-icon>
	</button>
</div>
<div *ngIf="statsState === 'success'"
	class="simple-dash dashboard-items animate__animated animate__fadeIn ctw-grid ctw-mx-auto ctw-mt-6 ctw-px-8 ctw-mb-12">
	<ng-container *ngIf="statsState === 'success'">
		<dash-card icon="language" cardTitle="" iframe="{{ this.url }}" class="dash-card stat-card preview-container"
			disabled="true">
			<div class="site"><iframe [src]="this.sanitizedUrl" loading="lazy" sandbox></iframe></div>
		</dash-card>
		<app-wpstats-card [stats]="this.stats" class="stat-card"></app-wpstats-card>
		<page-views-card *ngIf="'an InMotion Cloud Playground' !== getServerLocation()" [stats]="this.stats.views"
			class="page-views-card stat-card"></page-views-card>
		<app-update-card [stats]="this.stats" class="stat-card"></app-update-card>
	</ng-container>
</div>
