import { Input, Component, OnInit } from '@angular/core';
import { ProjectService } from '../../project.service';

@Component({
	selector: 'app-update-card',
	templateUrl: './update-card.component.html',
	styleUrls: ['./update-card.component.scss'],
})
export class UpdateCardComponent implements OnInit {
	@Input() public stats = {};
	public hasUpdates = false;
	public updateThemes = [];
	public updatePlugins = [];

	constructor(public projectService: ProjectService) {}

	ngOnInit() {
		this.checkThemeUpdates();
		this.checkPluginUpdates();
		this.hasUpdates = this.checkHasUpdates();
	}

	/**
	 * Does this user have pending updates.
	 */
	public checkHasUpdates(): boolean {
		return !!(
			this.hasWpUpdate() ||
			this.updateThemes.length ||
			this.updatePlugins.length
		);
	}

	/**
	 * Does the user need a WP update.
	 */
	public hasWpUpdate(): boolean {
		return this.stats['debug']['wp-core']['fields']['version'][
			'value'
		].match('Latest version');
	}

	/**
	 * Determine which themes need updates.
	 */
	public checkThemeUpdates(): void {
		const themes = this.getInactiveThemes();
		themes.push(this.stats['debug']['wp-active-theme'].fields);

		for (const theme of themes) {
			const test = theme['version']
				? theme['version'].value
				: theme['value'];

			if (test.match('Latest version')) {
				this.updateThemes.push(theme);
			}
		}
	}

	/**
	 * Determine which plugins need updates.
	 */
	public checkPluginUpdates(): void {
		let plugins = Object.values(
			this.stats['debug']['wp-plugins-inactive'].fields
		);
		plugins = plugins.concat(
			Object.values(this.stats['debug']['wp-plugins-active'].fields)
		);

		for (const plugin of plugins) {
			const test = plugin['version']
				? plugin['version'].value
				: plugin['value'];

			if (test.match('Latest version')) {
				this.updatePlugins.push(plugin);
			}
		}
	}

	/**
	 * Get a list of inactive themes.
	 *
	 * @return Themes.
	 */
	public getInactiveThemes(): any[] {
		let themes = [];
		if (this.stats['debug']['wp-themes-inactive']) {
			themes = Object.values(
				this.stats['debug']['wp-themes-inactive'].fields
			);
		}
		return themes;
	}

	/**
	 * Remove theme and plugin slugs from label
	 *
	 * @return clean name
	 */
	public stripSlug(name: string): string {
		return name.replace(/\(.*\)/, '');
	}
}
