import { Component, OnInit, OnChanges, OnDestroy, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ApiService, ConfigurationService } from '@central/ng-shared';
import { ProjectService } from '../project.service';
import { WpRestService } from '../site/rest.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BreakpointObserver } from '@angular/cdk/layout'; // Angular's observer for media queries
import { AuthService } from '@central/ng-shared';
import { SidebarComponent } from '../../sidebar/sidebar.component';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'bgc-advanced',
	templateUrl: 'layout-advanced.component.html',
	styleUrls: ['layout-advanced.component.scss'],
})
export class AdvancedComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public isProjectRoute;

	@ViewChild('sidenav') public sidenav: MatSidenav;
	@ViewChild('screenshotComponent') public screenshotComponent: any;

	public screenshot = '';
	public accountProjects = [];
	public status = 'pending';
	public loadingClient = true;
	public screenshotUpdatingState = 'pending';
	public sidenavMode;
	public agentNeedsControl = false;
	public ansibleManaged;
	public currentRoute;
	public connecting = false;

	public constructor(
		public apiService: ApiService,
		public authService: AuthService,
		public wpRestService: WpRestService,
		public projectService: ProjectService,
		public sidebarComponent: SidebarComponent,
		public configService: ConfigurationService,
		private snackBar: MatSnackBar,
		private mediaQuery: BreakpointObserver,
		private router: Router
	) {}

	public ngOnInit() {
		setTimeout(() => {
			if (this.sidenav) {
				this.setupSidenav();
			}
		});

		this.bindEnvironmentChange();

		this.sidebarComponent.toggleMenu.subscribe(() => {
			this.sidenav.toggle();
		});

		this.sidebarComponent.hasChildMenu = true;

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.agentNeedsControl =
					event.urlAfterRedirects.includes('/agent');
				this.ansibleManaged =
					event.urlAfterRedirects.match(/(php|nginx|domain)/);
				this.currentRoute = event.urlAfterRedirects;
				this.checkSidebar();
			}
		});

		this.projectService.connected.subscribe((msg: any) => {
			this.status = msg;
		});
		this.wpRestService.connection.subscribe((msg: any) => {
			if (msg.status === 'connected') {
				this.projectService.connected.next('ready');
			}
		});
	}

	public ngOnChanges() {
		this.checkSidebar();
	}

	public ngOnDestroy(): void {
		this.sidebarComponent.hasChildMenu = false;
	}

	// determine window size and set sidenav style
	public setupSidenav() {
		this.mediaQuery.observe('(max-width: 768px)').subscribe((res) => {
			const matched = res.matches;
			if (matched) {
				this.sidenav.close();
				this.sidenavMode = 'over';
			} else if (
				!matched &&
				!this.hasRoute('/projects/' + this.projectService.project.id) &&
				!this.hasRoute('/projects/' + this.projectService.project.id + '/add/hosting') &&
				!this.hasRoute('/projects/' + this.projectService.project.id + '/resize-server', true)
			) {
				this.sidenavMode = 'side';
				this.sidenav.open();
				this.checkDropdown();
			}
		});
	}

	public getAccountProjects() {
		this.apiService.get('/v1/projects', {}).subscribe(
			(projects: any) => {
				// Limit list to 10.
				projects = projects.slice(0, 10);
				this.accountProjects = projects;
			},
			(error) => {
				console.error('Unable To retrive account projects');
			}
		);
	}

	/**
	 * Update the users screenshot.
	 */
	public updateScreenshot(): void {
		this.screenshotUpdatingState = 'submitted';
		this.apiService
			.post(
				`/v1/environments/${this.projectService.environment.id}/screenshot`,
				{}
			)
			.subscribe(
				(environment: any) => {
					this.projectService.environment.fields = environment.fields;
					this.screenshotUpdatingState = 'pending';

					this.snackBar.open('Screenshot Updated', '', {
						duration: 2000,
						horizontalPosition: 'right',
					});
				},
				() => {
					this.snackBar.open('Unable to update screenshot', '', {
						duration: 2000,
						horizontalPosition: 'right',
					});

					this.screenshotUpdatingState = 'pending';
				}
			);
	}

	/**
	 * Connect the client to an environment.
	 *
	 */
	public connectClient(): void {
		this.connecting = true;
		if (
			!this.projectService.hasEnvironmentControl(
				this.projectService.environment
			) ||
			this.projectService.environment?.fields?.playbook_running ===
				'in-progress'
		) {
			this.connecting = false;
			return;
		}

		if (this.currentRoute && this.currentRoute.includes('add/hosting')) {
			// If user is on the add hosting page, abandon connection attempt
			this.connecting = false;
			return;
		}

		this.projectService.client = null;
		this.projectService.loadEnvironmentClient().subscribe(
			(client) => {
				this.projectService.client = client;
				if (client) {
					this.projectService.connected.next('ready');
					this.status = 'ready';
				} else {
					this.projectService.connected.next('failed');
					this.status = 'failed';
					this.connecting = false;
				}
			},
			(error) => {
				console.log(error.message);
				this.projectService.connected.next('failed');
				this.status = 'failed';
				this.connecting = false;
			}
		);
	}

	public reconnectClient() {
		this.projectService.reloadProject(false).subscribe(() => {
			this.projectService.getSiteStats();
		});
	}

	/**
	 * Each time the environment changes, update the connection to WordPress.
	 */
	private bindEnvironmentChange(): void {
		this.projectService.onEnvironmentChange.subscribe(() => {
			this.connectClient()
		});
	}

	/**
	 * Is the given site URL on localhost.
	 *
	 * @param  siteUrl Site URL to test.
	 * @return         Is this localhost.
	 */
	public isLocalhost(siteUrl: string): boolean {
		return siteUrl && !!siteUrl.match(/(https?:\/\/localhost)|(^127\.)/);
	}

	/**
	 * Check if the router url contains the specified route
	 *
	 * @param  {string}  route
	 * @return {boolean} Does route match?
	 */
	public hasRoute(route: string, partial: boolean = false) {
		if ( !partial ) {
			return this.router.url === route;
		} else {
			return this.router.url.includes(route);
		}
	}

	public checkSidebar(hide = false): void {
		if (this.sidenav) {
			if (!this.hasRoute('/projects/' + this.projectService.project.id) &&
				!this.hasRoute('/projects/' + this.projectService.project.id + '/add/hosting') &&
				!this.hasRoute('/projects/' + this.projectService.project.id + '/resize-server', true) &&
				!this.hasRoute('/projects/' + this.projectService.project.id + '/add/playgrounds') &&
				!hide)
			{
				this.sidenavMode = 'side';
				this.sidenav.open();
				this.checkDropdown();
			} else {
				this.checkDropdown();
				this.sidenav.close();
				this.sidenavMode = 'over';
			}
		}
	}

	public checkDropdown(): void {
		document
			.querySelectorAll(
				'bgc-project .advanced-layout .mat-expansion-panel-content'
			)
			.forEach((dropdown) => {
				setTimeout(() => {
					if ((dropdown.parentElement.classList.contains('mat-expanded') && !dropdown.querySelector('.router-link-active')) || (!dropdown.parentElement.classList.contains('mat-expanded') && dropdown.querySelector('.router-link-active'))) {
						// @ts-ignore
						dropdown.previousSibling.click();
					}
				})
			});
	}
}
