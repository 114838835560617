import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConnectionErrorDialogComponent } from './connection-error-dialog/connection-error-dialog.component';
import { ProjectService } from '../../project.service';
import { Router } from '@angular/router';

@Component({
	selector: 'connection-error',
	templateUrl: './connection-error.component.html',
	styleUrls: ['./connection-error.component.scss'],
})
export class ConnectionErrorComponent implements OnInit {
	@Input() public url: string;
	@Output() public connect = new EventEmitter();

	public checks = [];
	public failedChecks = [];
	public recommended = [];
	public state = 'loading';
	public status = 'pending';
	public message = '';
	public title = 'Diagnosing...';
	public issue = '';
	public icon = '';

	constructor(
		private dialog: MatDialog,
		private projectService: ProjectService,
		private router: Router,
		public apiService: ApiService
	) {}

	ngOnInit() {
		if (
			this.projectService.environment.fields?.environment_type ===
				'vps' ||
			this.projectService.environment.fields?.hostname?.includes(
				'inmotionhosting.com'
			)
		) {
			if (
				this.projectService.environment.fields?.playbook_running ===
				'in-progress'
			) {
				this.projectService.playbookStatus.next('playbook-running');
			} else {
				this.runDiag();
			}
		} else {
			this.state = 'unavailable';
			this.icon = 'cloud';
			this.title = 'Playground Unreachable';
			this.issue = 'Reason Unknown';
		}
	}

	public runDiag(delay = 1000) {
		if (this.failedChecks.length) {
			this.title = 'Attempting Repairs';
			this.issue = 'This may take a couple of minutes...';
			this.status = 'submitted';
			delay = this.failedChecks[0]?.fix_delay || 1000;
		}
		if (delay === 1000) {
			this.state = 'loading';
			this.checks = [];
			this.failedChecks = [];
			this.recommended = [];
			this.status = 'pending';
		}

		setTimeout(() => {
			this.apiService
				.get('/v1/diagnose/' + this.projectService.environment.id, {})
				.subscribe((response: any) => {
					if (response.status && response.status === 'pending') {
						// Fix in progress
						this.title = 'Attempting Repairs';
						this.issue = 'This may take a couple of minutes...';
						this.status = 'submitted';
						this.recommended = [];
						this.runDiag(this.failedChecks[0]?.fix_delay || 1000);
					} else {
						this.checks = [];
						this.failedChecks = [];
						this.recommended = [];
						this.state = 'loading';
						this.status = 'pending';
						this.message = '';
						this.title = 'Diagnosing...';
						this.issue = '';
						this.icon = '';

						// Sort by weight
						response.checks.results.sort(function (a, b) {
							if (a.checkWeight < b.checkWeight) return -1;
							if (a.checkWeight > b.checkWeight) return 1;
							return 0;
						});

						// Identify recomended fix (lowest weight)
						response.checks.results.forEach((check, index) => {
							// Sort by weight
							check.diags.sort(function (a, b) {
								if (a.diagWeight < b.diagWeight) return -1;
								if (a.diagWeight > b.diagWeight) return 1;
								return 0;
							});

							check.diags.forEach((diag) => {
								diag.input.forEach((input) => {
									if (input.action === 'link') {
										input.href = input.href.replace(
											'PROJECT_ID',
											this.projectService.project.id
										);
									}
								});
								if (
									diag.diagResult !== 'pass' &&
									this.recommended.length === 0
								) {
									diag.recommended = true;
									this.checks.push(check);
									this.recommended.push(diag);
								}
							});
						});

						this.failedChecks = this.checks.filter(
							(check) => check.checkResult !== 'pass'
						);

						if (this.failedChecks.length) {
							this.state = 'loaded';
							this.status = 'pending';
							this.issue = this.recommended[0].diagTitle;
							this.icon = this.recommended[0].diagIcon;
							this.title =
								this.checks[0].checkTitle + ' Issues Detected';
						} else {
							this.projectService
								.reloadProject(false)
								.subscribe(() => {
									this.connect.emit();
									this.router.navigate(
										[
											'/projects/' +
												this.projectService.project.id +
												'/reconnected',
										],
										{ skipLocationChange: true }
									);
								});
						}
					}
				});
		}, delay);
	}

	public openFixDialog() {
		const ref = this.dialog.open(ConnectionErrorDialogComponent, {
			width: '650px',
			data: {},
		});
		const instance = ref.componentInstance;
		instance.diag = this.recommended[0];
		instance.environment = this.projectService.environment;
		instance.projectService = this.projectService;
		/*
		 */

		ref.afterClosed().subscribe({
			next: () => {
				if ('success' === ref.componentInstance.state) {
					this.status = this.failedChecks[0].fix_status;
					this.runDiag(this.failedChecks[0]?.fix_delay || 1000);
				}
			},
		});
	}
}
