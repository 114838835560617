<ng-container *ngIf="!this.data.hasScheduledBackups">
	<ng-container *ngIf="this.state !== 'failed'">
		<div class="title ctw-mb-5">Automated Backups</div>
		<p>
			Want to make sure you always have a working copy of your site?
			Enable automatic backups to make a backup of your site once a week.
			<a
				(click)="
					this.data.projectService.login(
						'wp-admin/admin.php?page=boldgrid-backup-settings'
					)
				"
				>Manage in WordPress</a
			>
			for more options.
		</p>
		<div class="action-buttons">
			<button
				mat-button
				mat-raised-button
				[disabled]="this.state === 'submitted'"
				(click)="this.matDialogRef.close()"
			>
				Cancel
			</button>
			<central-progress-button [state]="this.state" (click)="this.enable()"
				>Enable</central-progress-button
			>
		</div>
	</ng-container>
	<ng-container *ngIf="this.state === 'failed'">
		<div class="title ctw-mb-5">An unexpected error occurred</div>
		<p>
			We were unable to update your setting. Try again or manage from
			WordPress.
		</p>
		<div class="action-buttons">
			<button
				mat-button
				mat-raised-button
				color="accent"
				(click)="this.matDialogRef.close()"
			>
				Okay
			</button>
		</div>
	</ng-container>
</ng-container>
<ng-container *ngIf="this.data.hasScheduledBackups">
	<div class="title ctw-mb-5">Automated Backups Enabled</div>
	<p>
		Your site is setup to create backups periodically. If you want to change
		the frequency or control when backups occur, please
		<a
			(click)="
				this.data.projectService.login(
					'wp-admin/admin.php?page=boldgrid-backup-settings'
				)
			"
			>Manage in WordPress</a
		>.
	</p>
	<div class="action-buttons">
		<button
			mat-button
			mat-raised-button
			(click)="this.matDialogRef.close()"
			color="accent"
		>
			Okay, Got It!
		</button>
	</div>
</ng-container>
