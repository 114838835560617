import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { ScheduleService } from './site/scheduler.service';
import { WpRestService } from './site/rest.service';
import { DashboardComponent } from './site/dashboard/dashboard.component';
import { DashboardComponent as ProjectDashboard } from './dashboard/dashboard.component';
import { AdvancedComponent } from './layout-advanced/layout-advanced.component';
import { SharedModule } from '../shared/shared.module';
import { OverviewComponent } from './site/overview/overview.component';
import { PluginsComponent } from './site/plugins/plugins.component';
import { ThemesComponent } from './site/themes/themes.component';
import { BackupsComponent } from './site/backups/backups.component';
import { FileManagerComponent } from './site/file-manager/file-manager.component';
import { CloudSnapModule } from '../cloud-snap/cloud-snap.module';
import { ListSelectComponent } from './list-select/list-select.component';
import { SnackLoadingComponent } from './snack-loading/snack-loading.component';
import {
	ListComponent,
	CachedSrcDirective,
} from './collection/collection.component';
import { PageViewsComponent } from './site/page-views/page-views.component';
import { PreviewComponent } from './site/preview/preview.component';
import { UpdateCardComponent } from './site/update-card/update-card.component';
import { AnsibleCardComponent } from './site/ansible-card/ansible-card.component';
import { WpstatsCardComponent } from './site/wpstats-card/wpstats-card.component';
import { ProjectComponent } from './project.component';
import { MomentModule } from 'ngx-moment';
import { ConnectKeyComponent } from './site/connect-key/connect-key.component';
import { W3Component } from './site/w3/w3.component';
import { HealthComponent } from './site/health/health.component';
import { InstallSnapComponent } from './site/install-snap/install-snap.component';
import { AppAccessComponent } from './site/app-access/app-access.component';
import { RestorationConfirmComponent } from './site/backups/dialog-restoration/restoration-confirm.component';
import { SiteSetupComponent } from './site/site-setup/site-setup.component';
import { ScreenshotComponent } from './site/screenshot/screenshot.component';
import { BackupsDialogScheduleComponent } from './site/backups/dialog-schedule/backups-dialog-schedule.component';
import { BackupsUpdateProtectionComponent } from './site/backups/dialog-update-protection/backups-update-protection.component';
import { ConnectionErrorComponent } from './site/connection-error/connection-error.component';
import { ConnectionErrorDialogComponent } from './site/connection-error/connection-error-dialog/connection-error-dialog.component';
import { MetaService } from '../shared/meta/meta.service';
import { WizardComponent } from './site/publish/wizard/wizard.component';
import { PlanSelectorComponent } from './site/publish/plan-selector/plan-selector.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { DomainSelectorComponent } from './site/publish/domain-selector/domain-selector.component';
import { DomainComponent } from './site/domain/domain.component';
import { DataCenterSelectorComponent } from './site/publish/data-center-selector/data-center-selector.component';
import { DeployCheckoutComponent } from './site/publish/deploy-checkout/deploy-checkout.component';
import { CheckoutModule } from '../checkout/checkout.module';
import { AuthenticationModule, BillingModule } from '@central/ng-shared';
import { HostingComponent } from './site/publish/hosting/hosting.component';
import { DomainModule } from '../domain';
import { VzManagerComponent } from './site/vz-manager/vz-manager.component';
import { SnapshotsComponent } from './site/snapshots/snapshots.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { PasswordResetComponent } from './site/password-reset/password-reset.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CreateSnapshotDialogComponent } from './site/create-snapshot-dialog/create-snapshot-dialog.component';
import { AddPublicKeyComponent } from './site/add-public-key/add-public-key.component';
import { AccountService as CheckoutAccountService } from '../checkout/account.service';
import { AccountService } from './account.service';
import { ApplyDomainComponent } from './site/apply-domain/apply-domain.component';
import { NginxProfilesComponent } from './site/nginx-profiles/nginx-profiles.component';
import { AgentComponent } from './site/agent/agent.component';
import { AnsibleComponent } from './site/agent/ansible/ansible.component';
import { PhpSwitcherComponent } from './site/php-switcher/php-switcher.component';
import { ConfirmationDialogComponent } from './site/confirmation-dialog/confirmation-dialog.component';
import { AgentOverrideComponent } from './site/agent/ansible/agent-override/agent-override.component';
import { ProjectDetailsDialogComponent } from './dashboard/project-details-dialog/project-details-dialog.component';
import { NewProjectDialogComponent } from './collection/new-project-dialog/new-project-dialog.component';
import { ConnectKeyDialogComponent } from './dashboard/connect-key-dialog/connect-key-dialog.component';
import { SiteSetupWarnDialogComponent } from './site/site-setup/site-setup-warn-dialog.component';
import { PlaybookRunningComponent } from './site/playbook-running/playbook-running.component';
import { JobsComponent } from './dashboard/jobs/jobs.component';
import { AddonsComponent } from './site/addons/addons.component';
import { DiagnosticToolComponent } from './site/diagnostic-tool/diagnostic-tool.component';
import { DiagnosticToolDialogComponent } from './site/diagnostic-tool/diagnostic-tool-dialog/diagnostic-tool-dialog.component';
import { ManagedHostingComponent } from './site/managed-hosting/managed-hosting.component';
import { AutoSslComponent } from './site/apply-domain/auto-ssl/auto-ssl.component';
@NgModule({
	imports: [
		CommonModule,
		MaterialModule,
		AuthenticationModule,
		RouterModule,
		CloudSnapModule,
		MomentModule,
		NgxSliderModule,
		CheckoutModule,
		BillingModule,
		DomainModule,
		SharedModule,
		MatPasswordStrengthModule.forRoot(),
		MatTableModule,
	],
	declarations: [
		AddonsComponent,
		AdvancedComponent,
		DashboardComponent,
		OverviewComponent,
		PluginsComponent,
		ThemesComponent,
		ProjectDashboard,
		ProjectComponent,
		BackupsComponent,
		FileManagerComponent,
		ListSelectComponent,
		SnackLoadingComponent,
		ListComponent,
		PageViewsComponent,
		PreviewComponent,
		UpdateCardComponent,
		AnsibleCardComponent,
		WpstatsCardComponent,
		ConnectKeyComponent,
		W3Component,
		HealthComponent,
		InstallSnapComponent,
		AppAccessComponent,
		RestorationConfirmComponent,
		SiteSetupComponent,
		ScreenshotComponent,
		BackupsDialogScheduleComponent,
		BackupsUpdateProtectionComponent,
		ConnectionErrorComponent,
		ConnectionErrorDialogComponent,
		DiagnosticToolComponent,
		DiagnosticToolDialogComponent,
		ManagedHostingComponent,
		WizardComponent,
		PlanSelectorComponent,
		DomainSelectorComponent,
		DomainComponent,
		DataCenterSelectorComponent,
		DeployCheckoutComponent,
		HostingComponent,
		VzManagerComponent,
		SnapshotsComponent,
		PasswordResetComponent,
		CreateSnapshotDialogComponent,
		AddPublicKeyComponent,
		ApplyDomainComponent,
		AutoSslComponent,
		NginxProfilesComponent,
		AgentComponent,
		AnsibleComponent,
		PhpSwitcherComponent,
		ConfirmationDialogComponent,
		AgentOverrideComponent,
		ProjectDetailsDialogComponent,
		NewProjectDialogComponent,
		ConnectKeyDialogComponent,
		SiteSetupWarnDialogComponent,
		PlaybookRunningComponent,
		CachedSrcDirective,
		JobsComponent
	],
	exports: [
		PlanSelectorComponent,
		ConfirmationDialogComponent,
		ProjectDetailsDialogComponent,
		NewProjectDialogComponent,
		ConnectKeyDialogComponent,
		SiteSetupWarnDialogComponent,
		ConnectKeyComponent,
	],
	providers: [
		ScheduleService,
		MetaService,
		WpRestService,
		{ provide: CheckoutAccountService, useClass: AccountService },
	],
})
export class ProjectModule {}
