<dash-card
	cardTitle="Name your Snap"
	icon="save"
	class="card-small"
	disabled="true"
>
	<div class="content">
		<form (ngSubmit)="!snapName.invalid && this.save()">
			<mat-form-field>
				<input
					#snapName="ngModel"
					autocomplete="off"
					matInput
					required
					placeholder="Name"
					name="snap-name"
					[(ngModel)]="this.name"
				/>
				<mat-error *ngIf="snapName.invalid"
					>This field is required.</mat-error
				>
			</mat-form-field>

			<mat-form-field>
				<textarea
					autocomplete="off"
					matInput
					placeholder="Description (Optional)"
					name="snap-description"
					[(ngModel)]="this.description"
				></textarea>
			</mat-form-field>
			<div
				class="owner ctw-mt-5"
				*ngIf="!this.isUpdate"
				[hidden]="
					1 ===
					accountSelector.profileService.data?.account_access.length
				"
			>
				<p>
					Are you creating this Snap for your personal account, or for
					a team?
				</p>
				<central-account-selector
					defaultType="organization"
					presentation="radio"
					(accountChanged)="this.ownerAccount = $event"
					permission="write"
					#accountSelector
				></central-account-selector>
			</div>
			<div class="action-buttons ctw-text-right">
				<button
					type="button"
					mat-button
					mat-raised-button
					[disabled]="this.status === 'submitted'"
					(click)="this.back.emit()"
				>
					Back
				</button>
				<central-progress-button [state]="this.status"
					>Save Snap</central-progress-button
				>
			</div>
		</form>
	</div>
</dash-card>
