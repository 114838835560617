<bgc-filter-nav
	[steps]="[]"
	[filters]="this.filters"
	(search)="this.search($event)"
	(next)="this.onNext()"
></bgc-filter-nav>
<custom-url
	#customURL
	limit="1"
	[customUrls]="this.selectedThemes"
	[hidden]="this.view !== 'customURL'"
	(removeEvent)="this.remove($event)"
	(addEvent)="this.select($event)"
	resourceType="Theme"
></custom-url>
<div [hidden]="this.view === 'customURL'">
	<div class="themes">
		<div
			class="theme mat-elevation-z1"
			*ngFor="let theme of this.response['themes']"
			[class.selected]="this.isSelected(theme)"
		>
			<div class="image-wrap">
				<img [src]="theme.screenshot_url" />
				<div class="actions">
					<a target="_blank" [href]="theme.homepage">More Info</a>
				</div>
			</div>
			<div class="theme__name">
				<span>{{ theme.name }}</span>
				<ng-container *ngIf="!action">
					<button
						*ngIf="!this.isSelected(theme)"
						mat-button
						mat-raised-button
						color="primary"
						(click)="this.select(theme)"
					>
						Select
					</button>
					<button
						*ngIf="this.isSelected(theme)"
						mat-button
						mat-raised-button
						color="primary"
						(click)="this.onNext()"
					>
						Continue
					</button>
				</ng-container>
				<ng-container *ngIf="action">
					<central-progress-button
						[state]="theme.state"
						color=""
						(click)="action.event(theme)"
						*ngIf="!theme.actionDisabled"
						>{{ action.label }}</central-progress-button
					>
				</ng-container>
			</div>
		</div>
	</div>
	<div
		class="ctw-text-center no-results"
		*ngIf="
			this.status !== 'loading' &&
			this.response['themes'] &&
			!this.response['themes'].length
		"
	>
		<div class="title">No Results Found</div>
		<p>We did not find any themes with the given search term</p>
	</div>
	<ng-container *ngIf="this.status === 'failed'">
		<div class="ctw-text-center no-results">
			<div class="title">An unexpected error occurred</div>
			<p>
				Your results where not returned successfully. Please refresh the
				page and try again.
			</p>
		</div>
	</ng-container>
</div>
<mat-spinner
	*ngIf="this.status === 'loading'"
	color="accent"
	diameter="50"
></mat-spinner>
