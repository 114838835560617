<div *ngIf="this.view === 'register-domain'">
	<div>
		The domain you entered
		<span class="ctw-text-red-700 ctw-font-bold">{{
			this.currentDomain
		}}</span>
		is available and able to be registered! You should register this domain
		now before using it as your Site URL.
	</div>

	<div class="ctw-mt-8 ctw-text-right">
		<button
			color="primary"
			mat-stroked-button
			routerLink="/domains/register"
			[queryParams]="{ domain: this.currentDomain }"
			class="ctw-mr-2"
		>
			Register {{ this.currentDomain }}
		</button>
		<button
			color="warn"
			mat-raised-button
			(click)="this.view = 'sub-domain'"
		>
			Continue Anyways
		</button>
	</div>
</div>
<div *ngIf="this.view === 'change-domain'">
	<div *ngIf="this.status === 'ready'">
		The current Site URL for this environment is
		<a target="_blank" href="{{ this.current }}"> {{ this.current }}</a
		>.
		<p>
			To change your WordPress site's primary URL, select from one of the
			domains you've previously configured or enter one manually below:
		</p>
	</div>

	<div [class.ctw-mt-5]="this.status === 'ready'">
		<central-domain-select
			#domainSelect
			(domainSelectReady)="this.isReady()"
			(domainInputChange)="updateIsDisabled($event)"
			[allowManual]="true"
			[context]="this.context"
			[hostname]="this.hostname"
			[invalid]="this.invalid"
		></central-domain-select>
	</div>

	<div
		class="ctw-mt-5 ctw-flex ctw-justify-between"
		*ngIf="this.status === 'ready'"
	>
		<span class="ctw-mr-3"
			><button color="primary" mat-stroked-button routerLink="/domains">
				<mat-icon
					class="ctw-relative ctw-pb-8 ctw-pr-3 ctw-text-2xl mat-primary"
					>add</mat-icon
				>Add / Register New Domain
			</button></span
		>
		<div>
			<central-progress-button
				color="primary"
				[state]="this.validating"
				[disabled]="getIsDisabled(domainSelect.selectedDomainName)"
				(click)="validateSelected(domainSelect.selectedDomainName)"
			>
				Continue
			</central-progress-button>
		</div>
	</div>
</div>
<div *ngIf="this.view === 'sub-domain'">
	<central-subdomain
		[usedDomains]="this.usedDomains"
		[state]="this.state"
		[currentDomain]="this.currentDomain"
		(subdomainSubmit)="this.domainChangeSubmit.emit($event)"
		(subdomainBack)="this.view = 'change-domain'"
	></central-subdomain>
</div>
