import { Route } from '@angular/router';
import { GuestGuard } from '@central/ng-shared';
import { DomainComponent } from './domain.component';
import { DomainRegisterComponent } from './domain-register/domain-register.component';
import { DomainAddComponent } from './domain-add/domain-add.component';
import { DomainDetailsComponent } from './domain-details/domain-details.component';
import { DnsManagerComponent } from './dns-manager/dns-manager.component';

export const DomainRoutes: Route[] = [
	{
		path: 'domains',
		canActivate: [GuestGuard],
		component: DomainComponent,
	},
	{
		path: 'domains/register',
		canActivate: [GuestGuard],
		component: DomainRegisterComponent,
	},
	{
		path: 'domains/add',
		canActivate: [GuestGuard],
		component: DomainAddComponent,
	},
	{
		path: 'domains/:domainName',
		canActivate: [GuestGuard],
		component: DomainDetailsComponent,
	},
	{
		path: 'domains/:domainName/dns-manager',
		canActivate: [GuestGuard],
		component: DnsManagerComponent,
	},
	{
		path: 'domains/dns-manager/:domainName',
		canActivate: [GuestGuard],
		component: DnsManagerComponent,
	},
];
