import { Component, OnInit, ViewChild } from '@angular/core';
import { TitleService } from '@central/ng-shared';
import { PlanSelectorComponent } from '../../../project/site/publish/plan-selector/plan-selector.component';
import { ProjectCreateService } from '../project-create.service';
@Component({
	templateUrl: './size.component.html',
	styleUrls: ['./size.component.scss'],
})
export class SizeComponent implements OnInit {
	@ViewChild('planSelector') planSelector: PlanSelectorComponent;

    public couponData = null;
	public openingChat = false;
	public enableNext = false;

	constructor(
		public projectCreateService: ProjectCreateService,
		public titleService: TitleService
	) {}

	ngOnInit(): void {
		this.titleService.setTitle('Select your plan');
		if (this.couponData && !this.couponData.item_codes.length) {
			this.couponData = null;
		}
	}

	public select() {
		this.projectCreateService.updateSelections(
			'serverTier',
			this.planSelector.value
		);
		this.projectCreateService.updateSelections(
			'dataCenter',
			this.planSelector.dataCenter
		);
		this.projectCreateService.next();
	}

	public getInitialValue() {
		if (this.getItemCodeArray()) {
			// Returns the first tier attached to the coupon.
			// Will need to adjust this later.
			return parseInt(JSON.parse(this.getItemCodeArray().toString())[0], 10);
		} else {
			return this.projectCreateService.getSelections().serverTier;
		}
	}

	public getItemCodeArray() {
		if (this.projectCreateService.couponData) {
			const itemCodes = [];
			this.projectCreateService.couponData.item_codes.map((itemCode) => {
				const matches = itemCode.match(/(\d)*([a])?$/);
				itemCodes.push({
					itemCode: matches[1],
				});
			});
			return JSON.stringify(itemCodes);
		} else {
			return false;
		}
	}

	public handleSingleSelectionCoupon() {
		if (
			this.projectCreateService.couponData &&
			this.projectCreateService.couponData.item_codes
		) {
			const matches =
				this.projectCreateService.couponData.item_codes[0].match(
					/(\d)*([a])?$/
				);

			this.projectCreateService.updateSelections(
				'serverTier',
				matches[1]
			);
		}
		if (this.projectCreateService.couponData.item_codes.length === 1) {
			this.projectCreateService.next();
		}
    }

    openChat() {
		this.openingChat = true;
		if (window['zE']) {
			if (window['zE'].widget === 'classic') {
				window['zE']('webWidget', 'show');
				window['zE']('webWidget', 'open');
			}
			if (window['zE'].widget === 'messenger') {
				window['zE']('messenger', 'show');
				window['zE']('messenger', 'open');
			}
			setTimeout(() => (this.openingChat = false), 2000);
		}
	}
}
