<h3 class="ctw-font-bold">
	{{ !this.useBackup ? 'Security Verification:' : 'Two-Factor Recovery' }}
</h3>
<p *ngIf="!this.useBackup">
	We do not recognize this device. To verify your identity, we'll send you a
	security code.
</p>

<central-two-fa-send-code
	[hidden]="this.state !== 'sendCode' && this.state !== 'failure'"
	[twoFaUserData]="this.twoFaUserData"
	[sendError]="this.codeFailure"
	[state]="this.state"
	(cancel)="this.handleCancel()"
	(sendCode)="sendCode($event)"
	(sendCodeCancel)="this.handleCancel()"
></central-two-fa-send-code>

<p *ngIf="this.useBackup">
	Enter one of your recovery codes created when you added Two-Factor
	authentication.
</p>
<p *ngIf="this.useBackup">The recovery code will be voided after use.</p>

<form
	[hidden]="this.state !== 'validateCode' && this.state !== 'submitted'"
	class="twoFa-form"
	(ngSubmit)="submit()"
	novalidate
>
	<p *ngIf="!this.useBackup">
		Please enter the code we sent to
		{{ this.model.deviceSelection?.message }}.
	</p>
	<mat-form-field [appearance]="this.inputAppearance" class="twoFaCode-field">
		<mat-label
			>{{ !this.useBackup ? 'Verification' : 'Backup' }} Code:</mat-label
		>
		<input
			matInput
			class="validated"
			name="twoFaCode"
			[(ngModel)]="model.twoFaCode"
			autofocus
			type="text"
			required
			#twoFaCode="ngModel"
		/>

		<mat-hint
			align="start"
			class="animate__animated error"
			[class.animate__slideInUp]="
				fieldHasError(twoFaCode) ||
				twoFaUserData.invalidCode ||
				this.codeFailure?.name === 'rate_limit_exceeded'
			"
		>
			<span *ngIf="fieldHasError(twoFaCode)"
				>{{ !this.useBackup ? 'An authentication' : 'A backup' }} code
				is required.</span
			>
			<span *ngIf="!fieldHasError(twoFaCode) && twoFaUserData.invalidCode"
				>The {{ !this.useBackup ? 'authentication' : 'backup' }} code
				you entered is invalid.</span
			>
			<span *ngIf="this.codeFailure?.name === 'rate_limit_exceeded'"
				>Number of resubmissions reached. Please try again later.</span
			>
		</mat-hint>
	</mat-form-field>

	<div class="row flex actions">
		<div class="col s6 left-align">
			<mat-checkbox
				*ngIf="this.showRememberMe && !this.useBackup"
				name="rememberMetwoFa"
				[(ngModel)]="model.rememberMetwoFa"
				class="remember-me"
				>Remember This Device</mat-checkbox
			>
		</div>
		<div class="col s6 right-align" *ngIf="!this.useBackup">
			<button
				type="button"
				name="resend-code"
				[disabled]="this.resendIsDisabled === true"
				(click)="this.triggerCode()"
				class="verify-button"
				mat-stroked-button
				color="primary"
			>
				Resend Code
			</button>
		</div>
	</div>

	<button
		mat-ripple
		mat-raised-button
		color="accent"
		class="btn-spinner send-code-button"
		[disabled]="this.state === 'submitted'"
	>
		<mat-spinner
			diameter="20"
			*ngIf="this.state == 'submitted' && this.showBtnSpinner"
		></mat-spinner>
		Verify Code
	</button>
	<button
		type="button"
		[disabled]="this.state === 'submitted'"
		(click)="this.handleCancel()"
		class="cancel-button"
		mat-raised-button
	>
		<mat-icon>arrow_back</mat-icon> Cancel
	</button>
	<button
		type="button"
		name="resend-code"
		class="backup-button"
		*ngIf="!this.useBackup && this.enableBackup"
		(click)="this.useBackup = 1"
		mat-stroked-button
		color="primary"
	>
		Use Recovery Code
	</button>
</form>
