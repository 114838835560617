<mat-progress-bar
	*ngIf="!this.isAnsibleControled && 0"
	mode="indeterminate"
	color="primary"
></mat-progress-bar>
<div
	class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8 ctw-mb-12"
>
	<h2>Agent Controls</h2>
	<ng-container *ngIf="!this.isAnsibleControled">
		<p>
			Playground environments do not have any Agent overrides
			available.<br /><br />
		</p>
	</ng-container>
	<central-ansible *ngIf="this.isAnsibleControled"></central-ansible>
</div>
