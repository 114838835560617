import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, AuthService } from '@central/ng-shared';
import { Subscription } from 'rxjs';

@Component({
	selector: 'central-email-new',
	templateUrl: './email-new.component.html',
	styleUrls: ['./email-new.component.scss'],
})
export class EmailNewComponent implements OnInit {
	public state = 'pending';
	public isReady = false;
	public checkoutComplete = false;
	public ownerAccountId = '';
	public packages = [
		{
			planCode: 'email_account',
			quantity: 1,
			options: {
				allows_multiple: true,
				allows_term_discount: false,
			},
		},
	];

	message: string;
	subscription: Subscription;

	constructor(public router: Router, public accountService: AccountService, public authService: AuthService) {}

	ngOnInit(): void {
		this.subscription = this.accountService.currentMessage.subscribe(
			(message) => {
				this.message = message;
			}
		);
	}

	public accountChanged($event) {
		this.ownerAccountId = $event['account_id'];
		this.accountService.updateAccount(this.authService.profile.data.account_access[1]);
		this.isReady = true;
	}

	public paymentSucceeded() {
		this.checkoutComplete = true;
	}
}
