import { OnInit, Input, Component, AfterViewInit } from '@angular/core';
import Chart from 'chart.js';
import moment from 'moment';

@Component({
	selector: 'page-views-card',
	templateUrl: './page-views.component.html',
	styleUrls: ['./page-views.component.scss'],
})
export class PageViewsComponent implements OnInit, AfterViewInit {
	@Input() public stats;
	@Input() public mode = 'daily';

	public chart;
	public dates = [];
	public modeConfig = {
		daily: {
			format: 'L',
			subtract: 'days',
			key: 'YYYY-MM-DD',
		},
		monthly: {
			format: 'MMMM YYYY',
			subtract: 'months',
			key: 'YYYY-MM',
		},
	};

	public ngOnInit() {
		this.dates = this.getDates();
		this.stats.monthly = this.getMonthlyStats();
		this.stats.daily = this.stats.dates;
	}

	ngAfterViewInit() {
		this.setupChart();
	}

	/**
	 * When the mode changes update the data display.
	 */
	public update(): void {
		this.dates = this.getDates();
		this.chart.data.labels = this.getLabels();
		this.chart.data.datasets = this.getDataset();
		this.chart.update();
	}

	/**
	 * Setup the chart populating the data.
	 */
	private setupChart(): void {
		const element: any = document.getElementById('viewChart');
		const ctx = element.getContext('2d');
		this.chart = new Chart(ctx, {
			// The type of chart we want to create
			type: 'line',

			// The data for our dataset
			data: {
				labels: this.getLabels(),
				datasets: this.getDataset(),
			},

			// Configuration options go here
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								suggestedMin: 0,
							},
						},
					],
				},
			},
		});
	}

	private getDataset() {
		return [
			{
				label: 'Page Views',
				// 	backgroundColor: '#c32227',
				borderColor: '#cc4347',
				data: this.getData(),
			},
		];
	}

	/**
	 * Current labels for the X axis.
	 */
	private getLabels(): string[] {
		const config = this.modeConfig[this.mode];
		return this.dates.map((date) => date.format(config['format']));
	}

	/**
	 * Get the monthly stats.
	 *
	 * @return object
	 */
	private getMonthlyStats(): object {
		const stats = {};
		for (const date of Object.keys(this.stats.dates)) {
			const monthDate = date.substring(0, 7);
			stats[monthDate] = stats[monthDate] || 0;
			stats[monthDate] = stats[monthDate] + this.stats.dates[date];
		}

		return stats;
	}

	/**
	 * Get the
	 *
	 * @return array Data.
	 */
	private getData(): any[] {
		const config = this.modeConfig[this.mode];

		return this.dates.map((date) => this.stats[this.mode][date.format(config.key)] || 0);
	}

	/**
	 * Get the dates we want data for.
	 *
	 * @return array
	 */
	private getDates(): any[] {
		const config = this.modeConfig[this.mode];

		const dates = [];
		for (let i = 0; i < 6; i++) {
			dates.push(moment().subtract(i, config['subtract']));
		}

		return dates.reverse();
	}
}
