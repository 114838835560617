import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Config } from '../../../shared/config/env.config';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ApiService } from '@central/ng-shared';
interface postData {
	[others: string]: any;
}
@Component({
	selector: 'central-add-public-key',
	templateUrl: './add-public-key.component.html',
})
export class AddPublicKeyComponent {
	status = 'loading';
	badKey = false;
	serverDown = false;
	duplicate = false;

	constructor(
		public dialogRef: MatDialogRef<AddPublicKeyComponent>,
		private apiService: ApiService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private httpClient: HttpClient
	) {}
	processKey(pubKey) {
		this.status = 'submitted';
		this.serverDown = false;
		this.badKey = false;
		this.duplicate = false;
		let url = '/v1/vps/server/authorized-keys/add';
		let post: postData = {
			veid: this.data.veid,
			pubkey: pubKey.trim(),
		};

		if ( !this.data.veid ) {
			url = '/v1/cloud-wordpress/add-ssh-key';
			post = {
				trial_id: this.data.trial_id,
				ssh_key: pubKey.trim(),
			};
		}

		this.apiService
			.post(url, {
				headerOptions: {
					xOrgId: this.data.organization_id,
				},
				...post,
			})
			.subscribe(
				(response: any) => {
					console.log( response );
					// returns 200 for success and 803 for fail(there may be other status codes).
					if (400 === response.status || 400 === response?.data?.status) {
						this.status = 'failed';
						this.badKey = true;
						// server is not up.
					} else if (404 === response.status || 404 === response?.data?.status) {
						this.status = 'failed';
						this.serverDown = true;

						// attempting to add duplicate key.
					} else if (
						(0 === response.status || 0 === response?.data?.status)
						&& response.message.includes('already')
					) {
						this.status = 'failed';
						this.duplicate = true;

						// success.
					} else {
						this.status = 'success';
						this.dialogRef.close('success');
					}
				},
				(e: HttpErrorResponse) => {
					this.status = 'failed';
					this.dialogRef.close('error');
				}
			);
	}
}
