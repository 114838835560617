import { Component, ViewChild, OnInit } from '@angular/core';
import { ProjectService } from '../../project.service';
import { ApiService } from '@central/ng-shared';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
	MatLegacyMenuModule as MatMenuModule,
	MatLegacyMenuTrigger as MatMenuTrigger,
} from '@angular/material/legacy-menu';
import { WpRestService } from '../rest.service';

@Component({
	selector: 'app-plugins',
	templateUrl: './plugins.component.html',
	styleUrls: ['./plugins.component.scss', './resource-display.scss'],
})
export class PluginsComponent implements OnInit {
	constructor(
		private projectService: ProjectService,
		private snackBar: MatSnackBar,
		public wpRestService: WpRestService,
		private apiService: ApiService
	) {}
	@ViewChild('tabgroup') public tabgroup;
	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

	public activePlugins = [];
	public status = 'loading';
	public tabSelected = false;
	public recommended = [];
	public installingPlugins = [];
	public installingPlugin = false;
	public deactivatingPlugins = [];
	public deactivatingPlugin = false;
	public deletingPlugins = [];
	public deletingPlugin = false;
	public activatingPlugins = [];
	public activatingPlugin = false;

	public displayedColumns: string[] = [
		'name',
		'version',
		'deactivated',
		'options',
	];

	public displayedSuggested: string[] = ['position', 'symbol'];

	public action = {
		pluginType: (plugin) => {
			const active = this.activePlugins.find(
				(el) => el.Name === plugin.name
			);

			if (active && active.IsActive) {
				return 'deactivatable';
			}

			if (active && !active.IsActive) {
				return 'activatable';
			}

			if (!active) {
				return 'installable';
			}
		},
		activate: {
			label: 'Activate',
			event: (plugin) => {
				const inactive = this.getInactivePlugins();
				const pluginRef = inactive.find(
					(el) => el.Name === plugin.name
				);
				const currentPlugin = Object.assign(plugin, pluginRef);
				this.activate(currentPlugin);
			},
		},

		install: {
			label: 'Install',
			event: (plugin) => this.install(plugin),
		},

		deactivate: {
			label: 'Deactivate',
			event: (plugin) => {
				const active = this.getActivePlugins();
				const pluginRef = active.find((el) => el.Name === plugin.name);
				const currentPlugin = Object.assign(plugin, pluginRef);
				this.uninstall(currentPlugin);
			},
		},

		delete: {
			label: 'Delete',
			event: (plugin) => {
				const inactive = this.getInactivePlugins();
				const pluginRef = inactive.find(
					(el) => el.Name === plugin.name
				);
				const currentPlugin = Object.assign(plugin, pluginRef);
				this.delete(currentPlugin);
			},
		},
	};

	ngOnInit() {
		this.getPlaybookStatus();
		this.projectService.connected.subscribe((msg: any) => {
			if (
				msg === 'ready' &&
				this.projectService.client &&
				this.status !== 'ready' &&
				this.status !== 'blocked'
			) {
				this.setActivePlugins();
			}

			if (msg !== 'ready') {
				this.status = 'blocked';
			}
		});
	}

	/**
	 * Gets status of project's playbook runs
	 */
	getPlaybookStatus() {
		this.projectService.playbookStatus.subscribe((msg: any) => {
			this.status = msg === 'playbook-running' ? 'blocked' : this.status;
			if (
				this.status === 'blocked' &&
				(msg === 'playbook-not-running' || msg === 'complete')
			) {
				this.status = 'ready';
				this.setActivePlugins();
			}
		});
	}

	/**
	 * Detect mobility
	 */

	public isPhone() {
		if (window.screen.width <= 599) {
			return true;
		}
	}

	public isTablet() {
		if (window.screen.width > 600 && window.screen.width < 1280) {
			return true;
		}
	}

	/**
	 * Install, uninstall, delete, activate
	 */

	public activate(plugin?: any) {
		if (plugin) {
			plugin.state = 'submitted';
			this.activatingPlugins.push(plugin);
		}

		if (this.activatingPlugins.length && !this.activatingPlugin) {
			const currentPlugin = this.activatingPlugins.shift();
			this.callActivate(currentPlugin);
		}
	}

	public uninstall(plugin?: any) {
		if (plugin) {
			plugin.state = 'submitted';
			this.deactivatingPlugins.push(plugin);
		}

		if (this.deactivatingPlugins.length && !this.deactivatingPlugin) {
			const currentPlugin = this.deactivatingPlugins.shift();
			this.callUninstall(currentPlugin);
		}
	}

	public delete(plugin?: any) {
		if (plugin) {
			if (plugin.IsActive) {
				this.snackBar.open(
					'You must deactivate this plugin first!',
					'',
					{
						duration: 2000,
						horizontalPosition: 'right',
					}
				);
			} else if (!plugin.IsActive) {
				plugin.state = 'submitted';
				this.deletingPlugins.push(plugin);
			}
		}

		if (this.deletingPlugins.length && !this.deletingPlugin) {
			const currentPlugin = this.deletingPlugins.shift();
			this.callDelete(currentPlugin);
		}
	}

	public install(plugin?: any) {
		if (plugin) {
			plugin.state = 'submitted';
			this.installingPlugins.push(plugin);
		}

		if (this.installingPlugins.length && !this.installingPlugin) {
			const currentPlugin = this.installingPlugins.shift();
			this.callInstall(currentPlugin);
		}
	}

	/**
	 * Install a plugin.
	 */
	public callInstall(plugin): void {
		this.installingPlugin = true;

		this.snackBar.open('Installing ' + plugin.name || plugin.Name, '', {
			duration: 2000,
			horizontalPosition: 'right',
		});

		// After we're done installing this plugin trigger the next.
		const postInstall = () => {
			this.installingPlugin = false;
			this.install();
		};

		this.projectService.client
			.namespace('bgc/v1')
			.plugins()
			.create({
				plugins: [plugin.download_link || plugin.versions.trunk],
				activate: true,
			})
			.then((plugins) => {
				this.activePlugins = plugins;

				this.snackBar.open('Installed Successfully!', '', {
					duration: 3000,
					horizontalPosition: 'right',
				});

				plugin.actionDisabled = true;
				plugin.state = 'success';
				this.filterRecommended();
				postInstall();
			})
			.catch((error) => {
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								this.callInstall(plugin);
							},
							() => {
								this.snackBar.open('Unable to Install', '', {
									duration: 3000,
									horizontalPosition: 'right',
								});

								plugin.state = 'fail';
								postInstall();
							}
						);
				} else {
					this.projectService.connected.next('failed');
					this.snackBar.open('Unable to Install', '', {
						duration: 3000,
						horizontalPosition: 'right',
					});

					plugin.state = 'fail';
					postInstall();
				}
			});
	}

	/**
	 * Activate a plugin
	 */
	public callActivate(plugin): void {
		this.activatingPlugin = true;
		this.snackBar.open('Activating ' + plugin.Name, '', {
			duration: 2000,
			horizontalPosition: 'right',
		});

		const postActivation = () => {
			this.activatingPlugin = false;
			this.activate();
		};

		this.projectService.client
			.namespace('bgc/v1')
			.plugins()
			.update({ active: true, files: [plugin.File] })
			.then((plugins) => {
				this.activePlugins = plugins;

				this.snackBar.open('Activated Successfully!', '', {
					duration: 3000,
					horizontalPosition: 'right',
				});

				plugin.state = 'success';
				this.filterRecommended();
				postActivation();
			})
			.catch((error) => {
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								this.callActivate(plugin);
							},
							() => {
								this.snackBar.open('Unable to Activate', '', {
									duration: 3000,
									horizontalPosition: 'right',
								});

								plugin.state = 'fail';
								postActivation();
							}
						);
				} else {
					this.projectService.connected.next('failed');
					this.snackBar.open('Unable to Activate', '', {
						duration: 3000,
						horizontalPosition: 'right',
					});

					plugin.state = 'fail';
					postActivation();
				}
			});
	}

	/**
	 * Deactivate a plugin
	 */
	public callUninstall(plugin): void {
		this.deactivatingPlugin = true;
		this.snackBar.open('Deactivating ' + plugin.Name, '', {
			duration: 2000,
			horizontalPosition: 'right',
		});

		// After we're done uninstalling this plugin, trigger the next.
		const postUninstall = () => {
			this.deactivatingPlugin = false;
			this.uninstall();
		};

		this.projectService.client
			.namespace('bgc/v1')
			.plugins()
			.update({ active: false, files: [plugin.File] })
			.then((plugins) => {
				this.activePlugins = plugins;

				this.snackBar.open('Deactivated Sucecssfully!', '', {
					duration: 3000,
					horizontalPosition: 'right',
				});

				plugin.state = 'success';
				this.filterRecommended();
				postUninstall();
			})
			.catch((error) => {
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								this.callUninstall(plugin);
							},
							() => {
								this.snackBar.open('Unable to Deactivate', '', {
									duration: 3000,
									horizontalPosition: 'right',
								});

								plugin.state = 'fail';
								postUninstall();
							}
						);
				} else {
					this.snackBar.open('Unable to Deactivate', '', {
						duration: 3000,
						horizontalPosition: 'right',
					});

					plugin.state = 'fail';
					postUninstall();
				}
			});
	}
	/**
	 * Delete a plugin
	 */

	public callDelete(plugin): void {
		this.deletingPlugin = true;

		this.snackBar.open('Deleting ' + plugin.Name, '', {
			duration: 2000,
			horizontalPosition: 'right',
		});

		// After we're done deleting this plugin, trigger the next.
		const postDelete = () => {
			this.deletingPlugin = false;
			this.delete();
		};

		this.projectService.client
			.namespace('bgc/v1')
			.plugins()
			.delete({ files: plugin.File })
			.then((plugins) => {
				this.activePlugins = plugins;

				this.snackBar.open('Deleted Successfully!', '', {
					duration: 3000,
					horizontalPosition: 'right',
				});

				plugin.state = 'success';
				this.getRecommendedPlugins();
				postDelete();
			})
			.catch((error) => {
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								this.callDelete(plugin);
							},
							() => {
								this.snackBar.open('Unable to Delete', '', {
									duration: 3000,
									horizontalPosition: 'right',
								});

								plugin.state = 'fail';
								postDelete();
							}
						);
				} else {
					this.snackBar.open('Unable to Delete', '', {
						duration: 3000,
						horizontalPosition: 'right',
					});

					plugin.state = 'fail';
					postDelete();
				}
			});
	}

	/**
	 * Get a list of the recommended plugins from bg api.
	 */
	public getRecommendedPlugins(): void {
		this.apiService.get('/v1/plugins', {}).subscribe((plugins: any[]) => {
			plugins = plugins.filter((val) =>
				// Gross -- Boldgrid gallery...
				!val.licenses && val.name !== 'BoldGrid Gallery'
			);

			this.recommended = plugins;
			this.filterRecommended();
		});
	}

	/**
	 * Remove plugins from recommended list that are already installed.
	 */
	private filterRecommended(): void {
		this.recommended = this.recommended.filter((plugin) => (
				-1 ===
				this.activePlugins.findIndex((val) => (
						val.Name === plugin.name ||
						(val.Name && val.Name.toLowerCase() === plugin.slug)
					))
			));
	}

	/**
	 * Get a list of all plugins
	 */
	public getPlugins(): any[] {
		const plugins = this.activePlugins
			.filter((val) => val.IsActive)
			.concat(this.activePlugins.filter((val) => !val.IsActive));
		return plugins;
	}

	/**
	 * Get a list active plugins.
	 */
	public getActivePlugins(): any[] {
		return this.activePlugins.filter((val) => val.IsActive);
	}

	/**
	 * Get a list of the inactive plugins.
	 */
	public getInactivePlugins(): any[] {
		return this.activePlugins.filter((val) => !val.IsActive);
	}

	/**
	 * Grab the installed plugins from the wp
	 */
	private setActivePlugins(): void {
		this.projectService.client
			.namespace('bgc/v1')
			.plugins()
			.get()
			.then((plugins) => {
				this.activePlugins = plugins;
				this.filterRecommended();
				this.status = 'ready';
			})
			.catch((error) => {
				console.log(error);
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								this.setActivePlugins();
							},
							() => {
								this.status = 'failed-loading';
							}
						);
				} else {
					this.status = 'failed-loading';
				}
			});
	}
}
