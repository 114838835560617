import { Component, Output, OnInit, EventEmitter, Input, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { ApiService, ConfigurationService } from '@central/ng-shared';
import { Config } from '../../shared/config/env.config';
import { DEFAULT_REGISTERABLE_TLDS } from '../domain-constants';

@Component({
	selector: 'central-domain-register-shared',
	templateUrl: './domain-register-shared.component.html',
	styleUrls: ['./domain-register-shared.component.scss'],
})

export class DomainRegisterSharedComponent implements OnInit {
	public domainValidator;
	public progressButtonState = '';
	public progressButtonTitle = 'Check Availability';
	public checkedDomainName = '';
	public domainAvailability = '';
	public DOMAIN_PRICE = 19.99;
	public domainPriceStatus = 'loading';
	public domainPrice;
	public domainTldsAllowed = DEFAULT_REGISTERABLE_TLDS;

	@Input() public domainName = '';
	@Output() domainAvailabilityEvent = new EventEmitter<object>();

	@ViewChild('form') ngForm: NgForm;

	constructor(
		private httpClient: HttpClient,
		private apiService: ApiService,
		private configService: ConfigurationService,
	) {}

	ngOnInit(): void {
		this.fetchTlds();
		this.domainPriceStatus = 'success';
	}

	ngAfterViewInit(): void {
		if (this.domainName !== '') {
			this.ngForm.ngSubmit.emit();
		}
	}

	getErrorMessage() {
		if (this.domainValidator.hasError('required')) {
			return 'Enter a domain name';
		}

		const tld = '.' + this.domainName.split('.').slice(1).join('.');

		if (this.configService.hasFeature('bulkTldSearch')) {
			if (this.domainName.match(/\.$/)) {
				return 'Not a valid domain name';
			}

			return this.domainValidator.hasError('pattern')
				? 'Unable to register <strong>' + tld.replace(/\.\./g, '') + '</strong> domains at this time'
				: '';
		} else {
			if (this.domainName.split('.').length > 1 ||
				!this.domainName.match(/^(?!-)([-0-9a-z]{0,62})?[0-9a-z]\.[a-z]+$/)) {
				return 'Not a valid domain name';
			}

			if (this.domainName.split('.').length >= 2
				&& !this.domainTldsAllowed.includes('.' + tld)) {
				return 'Unable to register <strong>' + tld.replace(/\.\./g, '') + '</strong> domains at this time';
			}

			return this.domainValidator.hasError('pattern')
				? 'Not a valid domain name'
				: '';
		}
	}

	public fetchTlds() {
		const allowedTlds = this.configService.hasFeature('recommendedTlds')
			? 'recommended' : 'default'; // or 'all'

		const url = `${Config['host']}/v1/domain-service/domain/tlds/${allowedTlds}`;
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.httpClient.get(url, { headers }).subscribe(
			(response: []) => {
				this.domainTldsAllowed = response;
				this.domainValidator = new UntypedFormControl('', [
					Validators.pattern('^(?!-)([-0-9a-z]{0,62})?[0-9a-z](' + this.domainTldsAllowed.join('|') + '|)$'),
				]);
				this.fetchCatalog();
			},
			(error) => {
				console.warn('Unable to fetch Registerable TLDs', error);
			});
	}

	public fetchCatalog() {
		this.apiService.get('/v1/wpcr/catalog', []).subscribe(
			(catalog: any) => {
				this.DOMAIN_PRICE =
					catalog.filter((plan) => plan.code === 'domain-gtld-1')[0]
						.price_in_cents / 100;
				this.domainPriceStatus = 'success';
			},
			() => {
				this.domainPriceStatus = 'failed';
			}
		);
	}

	public isDomainAvailable() {
		if (!this.domainValidator.invalid) {
			this.domainAvailability = '';
			this.domainAvailabilityEvent.emit({
				domainAvailability: '',
			});
			if (this.configService.hasFeature('bulkTldSearch')) {
				this.checkedDomainName = this.domainName.trim().toLowerCase().split('.')[0];
				this.domainAvailabilityEvent.emit({
					domainName: `${this.checkedDomainName}`,
					domainAvailability: this.domainAvailability,
					domainPrice: this.domainPrice,
				});
				this.domainName = '';
			} else {
				this.progressButtonState = 'submitted';
				this.progressButtonTitle = 'Checking Availability...';
				this.checkedDomainName = this.domainName.trim().toLowerCase();

				const url =
					Config['host'] +
					'/v1/domain-service/domain/availability/' +
					`${this.checkedDomainName}`;
				const headers = this.apiService.getHeaders({
					contentType: 'application/json',
				});
				this.httpClient.get(url, { headers }).subscribe(
					(response) => {
						if (response['is_success'] === '1') {
							if ('price' in response['attributes']) {
								this.domainAvailability = 'available';
								this.domainName = '';
								if (this.configService.hasFeature('dynamicTldPricing')) {
									this.domainPrice = parseFloat(response['attributes']['price']).toFixed(2);
								} else {
									this.domainPrice = this.DOMAIN_PRICE;
								}
							} else {
								if (
									'status' in response['attributes'] &&
									response['attributes']['status'] === 'taken'
								) {
									this.domainAvailability = 'taken';
								} else {
									this.domainAvailability = 'error';
								}
							}
						} else {
							this.domainAvailability = 'error';
						}

						this.progressButtonState = '';
						this.progressButtonTitle = 'Check Availability';

						this.domainAvailabilityEvent.emit({
							domainName: `${this.checkedDomainName}`,
							domainAvailability: this.domainAvailability,
							domainPrice: this.domainPrice,
						});
					},
					() => {
						this.progressButtonState = '';
						this.progressButtonTitle = 'Check Availability';
					}
				);
			}
		}
	}
}
