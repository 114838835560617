<dash-card icon="rocket" cardTitle="Professional Services"
	class="dash-card dash-card-mh ctw-mb-8" disabled="true">
	<ng-container subTitleHTML *ngIf="this.clarityMinutes !== undefined">
		<span class="sub-title ctw-text-base">
			<span class="{{
				this.clarityMinutes > 0
					? 'ctw-text-green-700'
					: 'ctw-text-red-700'
			}}" *ngIf="this.clarityMinutes !== -1">
				{{ this.clarityHours }} Hour<span *ngIf="this.clarityHours > 1 || this.clarityHours === 0">s</span><span *ngIf="(this.clarityMinutes % 60) > 0"> {{ (this.clarityMinutes % 60) }} Minutes</span> Remaining
			</span>
			<span class="ctw-text-red-700" *ngIf="this.clarityMinutes === -1">Failed to load hours</span>
		</span>
	</ng-container>
	<mat-progress-bar *ngIf="this.clarityMinutes === undefined" mode="indeterminate" color="primary"></mat-progress-bar>
	<div class="dash-card-content ctw-p-8" *ngIf="this.clarityMinutes !== undefined">
		<div class="ctw-mb-4" *ngIf="this.clarityMinutes > 0">
			Devoted to providing you with the solutions and support that you need to succeed, our Launch Assist Service comes included with your server.  We can assist with general consultation, troubleshooting configuration or common CMS issues, website migration or more….
			<br><br>
			Our Professional Services Team's hours of operation are Monday through Friday, 9 AM to 9 PM Eastern Time and excluding major U.S. holidays. Let us help you get your project off to a great start!!
		</div>
		<div class="ctw-mb-4" *ngIf="this.clarityMinutes <= 0">
			Devoted to understanding your needs, our team provides low-cost IT resources.  We provide you with the solutions and support that you need to succeed. We can assist with general consultation, troubleshooting advanced web servers configuration or common CMS issues, and offer Launch Assist Website Migration Service
			<br><br>
			Our Professional Services Team's hours of operation are Monday through Friday, 9 AM to 9 PM Eastern Time and excluding major U.S. holidays. Let us help you get your project off to a great start!!
		</div>
		<div class="ctw-mt-5 ctw-text-right">
			<button mat-button mat-raised-button color="primary" (click)="this.openDialog({department: 'managed_hosting'})">
				Get Help
			</button>
		</div>
	</div>
</dash-card>
