import { Injectable } from '@angular/core';
import { AuthService } from '../../authentication/services';
import { ApiService } from '../../core';
import { NotificationService } from '../../notifications';
import { AccountService } from '../account.service';

@Injectable()
export class AccountNotificationService extends NotificationService {
	constructor(
		public apiService: ApiService,
		public authService: AuthService,
		public accountService: AccountService
	) {
		super(apiService, authService);
	}

	public getAccountId() {
		return (
			this.accountService.getResourceId() ||
			this.authService.getAccountId()
		);
	}
}
