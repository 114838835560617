<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8 ctw-text-center ctw-px-8" *ngIf="
		this.state === 'playbook-overridden' &&
		!this.ignoreOverride &&
		this.ansibleManaged
	">
    <h2 class="ctw-text-center">Feature Disabled</h2>
    <p *ngIf="this.overrideBy !== 'Agent'">
        This feature was disabled by your
        <strong>
            <a [routerLink]="[
					'/projects',
					this.projectService.project.id,
					'vz-manager'
				]">Server Software</a></strong>
        settings to prevent Central from reverting any custom modifications
        performed on the server.<br /><br />
    </p>
    <p *ngIf="this.overrideBy === 'Agent'">
        You've asked us to disable Central's ability to manage your server's
        software and configurations.<br />
        You can continue to make server level modifications however via SSH by
        <strong>
            <a [routerLink]="[
					'/projects',
					this.projectService.project.id,
					'vz-manager'
				]">adding an SSH Key</a></strong>.<br /><br />If you feel this was disabled in error, you can
        <a [routerLink]="[
				'/account',
				this.projectService.project.fields.organization_id,
				'help',
				'requests'
			]">open a Support Ticket</a>.

        <br /><br />
    </p>
</div>

<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8 ctw-text-center ctw-px-8" *ngIf="
		this.state === 'playbook-started' ||
		this.state === 'playbook-running' ||
		this.state === 'in-progress'
	">
    <mat-spinner color="primary" class="ctw-mx-auto ctw-mt-10 ctw-mb-10"></mat-spinner>
    <h2>Update In Progress</h2>
    <p>
        Your server is currently receiving updates.<br /><br />
        <strong>Feel free to leave this page and return later</strong>.<br />
        You will regain access to this section once the updates have
        completed.<br />
    </p>
</div>
<failed-request *ngIf="'playbook-request-failed' === this.state"></failed-request>