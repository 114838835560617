<mat-spinner
	*ngIf="state === 'loading'"
	class="component-loader"
	diameter="50"
	color="primary"
></mat-spinner>
<failed-request *ngIf="state === 'failed'"></failed-request>
<ng-container *ngIf="state === 'success'">
	<div class="ctw-mx-auto ctw-max-w-xl" *ngIf="!this.showUpgrade">
		<button routerLink=".." mat-stroked-button>Back</button>
		<div class="ctw-text-center ctw-mt-8">
			<mat-icon>camera_alt</mat-icon>
		</div>
		<div>
			<h2 class="ctw-text-center">Install A Snap</h2>

			<div class="ctw-mt-8">
				<h3 class="ctw-text-left">Where should we install it?</h3>
			</div>
			<p>
				You can install this snap onto a new Playground environment, or
				your current environment:
				{{ this.projectService.environment.label }}.
			</p>
			<p>
				You currently have
				<strong
					>{{ this.cloudsRemaining }} playground
					environment(s)</strong
				>
				available.
			</p>
			<div class="alert alert-default">
				Installing into your current environment is only available for
				development environments.
			</div>
			<div class="ctw-mt-10 ctw-text-center">
				<span class="ctw-mr-2"
					><button
						mat-button
						mat-stroked-button
						color="primary"
						(click)="install()"
					>
						New Playground
					</button></span
				>
				<span class="ctw-ml-2"
					><button
						[disabled]="!this.offerCurrentEnvironment"
						mat-button
						mat-stroked-button
						color="primary"
						(click)="this.currentEnvironment = true; install()"
					>
						Current Environment
					</button></span
				>
			</div>
		</div>
	</div>
	<div
		class="ctw-text-center premium-feature animate__animated animate__fadeIn"
		*ngIf="this.showUpgrade"
	>
		<cwp-require-upgrade></cwp-require-upgrade>
	</div>
</ng-container>

<cloud-loader
	class="cloud-loader"
	#cloudLoader
	*ngIf="'installing' === this.state"
	[hidden]="'success' === this.installStatus && cloudLoader.isDone()"
	[estimatedTime]="this.estimate"
></cloud-loader>

<div
	*ngIf="'install-complete' === this.state"
	class="ctw-text-center install-complete"
>
	<h3>Install Complete</h3>
	<p>You're all set! Login to your WordPress dashboard to check it out.</p>
	<p>
		<a mat-button mat-raised-button color="primary" (click)="this.login()"
			>Login</a
		>
	</p>
</div>
