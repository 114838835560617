<div
	*ngIf="this.dnsView === 'state-based' && this.status === 'ready'"
	class="ctw-p-8"
>
	<ng-container *ngIf="this.domainState === 'needs-dns'">
		<ng-container *ngIf="this.hasDomain()">
			<div>
				<span class="error" *ngIf="failedDns">
					We are unable to automatically update your domain's DNS.
				</span>

				<span>
					The DNS for this domain appears to be managed by another
					system. You should add the following A record to where DNS
					for {{ this.projectService.environment.fields.domain }} is
					being managed before continuing:
				</span>
				<mat-form-field appearance="outline" class="ctw-mt-4">
					<mat-label>DNS Entry</mat-label>
					<input
						class="dns-entry"
						matInput
						readonly
						value="{{ this.dnsEntry }}"
					/>
					<div class="ctw-absolute ctw-top-0 ctw-right-0">
						<button class="ctw-mr-2" mat-button (click)="copy()">
							Copy
						</button>
						<button
							class="ctw-top-0 ctw-right-0"
							mat-raised-button
							color="primary"
							[state]="domainCheckState"
							(click)="checkDomainStatus()"
						>
							Validate
						</button>
					</div>
				</mat-form-field>
			</div>
			<div class="ctw-text-right domain-actions">
				<button
					mat-button
					(click)="this.dnsView = 'change-domain'"
					class="ctw-mr-2"
				>
					Cancel
				</button>
				<button
					color="warn"
					mat-raised-button
					(click)="this.dnsView = 'skip-confirmation'"
				>
					Continue Anyway
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="!this.hasDomain()">
			<div>
				We need a domain for your visitors to access your site. Please
				continue to select your domain.
			</div>
			<div class="ctw-mt-5 ctw-text-center">
				<button
					color="primary"
					mat-raised-button
					(click)="this.dnsView = 'change-domain'"
				>
					Add Domain
				</button>
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="this.domainState === 'ready'">
		<div class="ctw-pb-6">
			We have everything we need to
			<span *ngIf="!this.domain.includes('inmotionhosting.com')"
				>make your site live!  </span
			>
			<span *ngIf="this.domain.includes('inmotionhosting.com')"
				>change your site's domain.  </span
			>
			Your WordPress site will soon be available at
			<span class="ctw-font-bold ctw-text-red-700 ctw-text-sm">{{
				this.domain
			}}</span>.
			During this process we will install a new SSL certificate on
			your server, and enable SSL on your WordPress site.
		</div>
		<div class="ctw-text-right ctw-mt-5">
			<button
				mat-button
				(click)="this.dnsView = 'change-domain'"
				class="ctw-mr-2"
			>
				Cancel
			</button>
			<central-progress-button
				color="primary"
				[state]="this.state"
				(click)="applyUrl()"
				>Apply Site URL</central-progress-button
			>
		</div>
	</ng-container>
</div>
<div
	*ngIf="this.dnsView === 'skip-confirmation' && this.status === 'ready'"
	[class]="
		this.status === 'ready'
			? 'animate__animated animate__fadeIn ctw-p-8'
			: ''
	"
>
	<div>
		<strong>Are you sure you want to proceed without verification?</strong>
	</div>
	<div>
		We didn't verify that your DNS was setup correctly. However, our system
		may have been unable to verify your DNS due to an uncommon
		configuration.
	</div>
	<div class="ctw-text-right ctw-mt-8">
		<span class="ctw-mr-2"
			><button mat-button (click)="this.dnsView = 'state-based'">
				Cancel
			</button></span
		>
		<central-progress-button
			color="warn"
			[state]="forceContinueState"
			(click)="forceContinue()"
			>Yes, I'm Sure</central-progress-button
		>
	</div>
</div>
<div
	*ngIf="this.usedState === 'success' && this.dnsView === 'change-domain'"
	[class]="
		this.status === 'ready'
			? 'animate__animated animate__fadeIn ctw-p-8'
			: ''
	"
>
	<central-domain-change
		(domainChangeReady)="this.isReady()"
		[current]="this.projectService.environment.fields.site_url"
		[hostname]="this.hostname"
		[usedDomains]="this.usedDomains"
		[currentDomain]="this.newDomain"
		[state]="this.domainCheckState"
		[context]="this.projectService.owner.account_id"
		(domainChangeBack)="this.dnsView = 'state-based'"
		(domainChangeSubmit)="checkDomainStatus($event)"
	></central-domain-change>


</div>
