import { Component, Inject } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
	ApiService,
	AuthService,
	ProfileService,
    ConfigurationService,
} from '@central/ng-shared';

@Component({
	selector: 'central-team-create-dialog',
	templateUrl: './create-team-dialog.component.html',
	styleUrls: ['./create-team-dialog.component.scss'],
})
export class TeamCreateDialogComponent {
	public teamName = '';
	public contactEmail = '';
	public billingEmail = '';
	public state = 'pending';
	public orgWebsite = '';
	public websiteOptional = false;

	constructor(
		public authService: AuthService,
		public apiService: ApiService,
		public profileService: ProfileService,
        public configurationService: ConfigurationService,
		public dialogRef: MatDialogRef<TeamCreateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.websiteOptional =
			this.configurationService.config.teamForm?.websiteOptional;
	}

	public fixUrls() {
		if (this.orgWebsite && !/^https?:\/\//i.test(this.orgWebsite)) {
			this.orgWebsite = this.orgWebsite.replace(/^.*:\/\//, '');
			this.orgWebsite = 'http://' + this.orgWebsite;
		}
	}

	public submit() {
		this.state = 'submitted';
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl('/v1/organizations');
		const orgDetails = {
			name: this.teamName,
			primary_email: this.contactEmail,
			billing_email: this.billingEmail || '',
			website: this.orgWebsite,
			members: [],
		};

		this.apiService.http.post(url, orgDetails, { headers }).subscribe(
            () => {
                this.state = 'success';
				this.profileService.fetch();
                this.dialogRef.close();
			},
			() => {
				this.state = 'failed';
			}
		);
	}
}
