<div class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray"
	[class.signup]="this.isOnboarding">
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<img *ngIf="this.isOnboarding" [src]="this.brandingService.getThemedAssetUrl('logo2.svg')"
			style="height: 48px;">
		<h2>Domains</h2>
		<ng-container *ngIf="!paymentSubmitted">
			<span class="ctw-flex ctw-justify-end">
				<button *ngIf="'available' !== domainAvailability" mat-raised-button color="primary"
					[matMenuTriggerFor]="cart" class="checkout ctw-ml-2 ctw-mb-5" matBadge="{{this.selected.length}}"
					matBadgePosition="after" matBadgeColor="accent" [disabled]="!this.selected.length">
					<mat-icon class="ctw-m-0 ctw-pl-1">shopping_cart</mat-icon>
				</button>
				<mat-menu #cart="matMenu" xPosition="before" [overlapTrigger]="true" matBadge="{{this.selected.length}}"
					matBadgePosition="after" matBadgeColor="secondary" class="ctw-p-4">
					<h3 class="ctw-mb-0">My Cart</h3>
					<ng-container *ngFor="let domain of this.selected; index as i">
						<button mat-menu-item class="ctw-flex ctw-justify-between ctw-gap-4 ctw-p-0">
							<span>{{domain.domainName}}</span>
							<span class="ctw-ml-4">${{domain.price}}
								<mat-icon (click)="this.select(i, false, $event)"
									class="ctw-mb-4 ctw-ml-3 ctw-text-red-700">
									close
								</mat-icon>
							</span>
						</button>
					</ng-container>
					<button mat-raised-button (click)="this.checkout()" color="primary"
						class="ctw-text-center ctw-w-full">
						Checkout
					</button>
				</mat-menu>
			</span>
		</ng-container>
	</div>

	<mat-progress-bar *ngIf="!this.isReady" mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-px-5 ctw-pt-16 ctw-mb-12 content ctw-text-center"
	[class.ctw-hidden]="!this.isReady">
	<central-domain-register-shared *ngIf="this.domainAvailability !== 'available'"
		(domainAvailabilityEvent)="updateDomainAvailability($event)" [domainName]="this.domainName"
		class="ctw-p-12"></central-domain-register-shared>


	<h4 *ngIf="!paymentSubmitted && 'available' !== domainAvailability" class="ctw-mb-12">
		{{ this.state !== 'submitted' ? 'Search for any of the following' : '' }}
		<div class="ctw-mt-8 ctw-grid {{ this.state === 'submitted' ? '' : 'ctw-grid-cols-5' }} ctw-gap-4 ctw-mx-16">
			<ng-container *ngFor="let tld of this.domainTldsAllowed; index as i">
				<span id="tld{{i}}" *ngIf="tld.availability === 'unknown'" class="tld ctw-px-5">{{tld.tld}}</span>
				<span id="tld{{i}}" *ngIf="tld.availability === 'pending'"
					class="tld ctw-px-5 ctw-flex ctw-justify-between">
					<span class="ctw-mr-4">{{tld.domainName}}</span>
					<central-progress-button state="submitted" mat-raised-button [color]="secondary"
						class="animate__animated animate__fadeIn" disabled>
					</central-progress-button>
				</span>
				<span id="tld{{i}}" *ngIf="tld.availability === 'available'"
					class="tld ctw-px-5 ctw-flex ctw-justify-between">
					<span class="ctw-mr-4">{{tld.domainName}}</span>
					<button mat-raised-button [color]="tld.selected ? 'secondary' : 'primary'" (click)="this.select(i)"
						class="{{tld.selected ? 'ctw-bg-neutral-200 ctw-text-green-700' : ''}}">
						<span class="ctw-flex">
							${{tld.price}}
							<mat-icon class="ctw-text-2xl ctw-m-0 ctw-p-0 ctw-ml-2">
								{{tld.selected ? 'check' : 'shopping_cart'}}
							</mat-icon>
						</span>
					</button>
				</span>
				<span id="tld{{i}}" *ngIf="tld.availability === 'taken'"
					class="tld ctw-px-5 ctw-text-red-700 ctw-flex ctw-justify-between">
					<span class="ctw-mr-4">{{tld.domainName}}</span>
					<button mat-stroked-button color="warn" disabled>Taken</button>
				</span>
				<span id="tld{{i}}" *ngIf="tld.availability === 'error'"
					class="tld ctw-px-5 ctw-text-red-700 ctw-flex ctw-justify-between">
					<span class="ctw-mr-4">{{tld.domainName}}</span>
					<button mat-stroked-button color="accent" disabled>Unavailable</button>
				</span>
			</ng-container>
		</div>
	</h4>

	<ng-container *ngIf="'error' === domainAvailability">
		<p class="error">Error checking availability, try again</p>
	</ng-container>
	<ng-container *ngIf="'taken' === domainAvailability">
		<h3 *ngIf="'taken' === domainAvailability">
			<mat-icon color="warn">cancel</mat-icon><span class="bold">{{ this.domainName }}</span> is already
			registered
		</h3>
		<p>
			If you control this domain name, you can add it to your
			<a routerLink="../">DNS Manager</a>.
		</p>
	</ng-container>

	<ng-container *ngIf="'available' === domainAvailability">
		<h3 *ngIf="
				!this.configService.hasFeature('bulkTldSearch') &&
				'available' === domainAvailability &&
				'success' === domainPriceStatus &&
				!paymentSubmitted
			" class="ctw-mb-8">
			<mat-icon class="available">check_circle</mat-icon><span class="bold">{{ this.domainName }}</span> is
			available for
			${{ this.domainPrice || this.DOMAIN_PRICE }} per year
		</h3>
		<payment-form [organizationId]="ownerAccountId" [packages]="this.packages" [hideGuarantee]="true"
			(paymentSubmit)="paymentSubmit()" (paymentBack)="this.updateDomainAvailability(this.searched)"
			(paymentFailure)="paymentSubmitted = false" [forceLogin]="true" [loginModalTitle]="this.loginModalTitle"
			[loginModalSubTitle]="this.loginModalSubTitle" [minimalForm]="true"
			[excludedInputs]="['organization']"></payment-form>
	</ng-container>
</div>
