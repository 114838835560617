<mat-spinner class="ctw-mx-auto ctw-mt-8" *ngIf="'loading' === this.projectCreateService.state" color="primary"
	diameter="50"></mat-spinner>
<div *ngIf="projectCreateService.state === 'error'">
	<central-failed-request></central-failed-request>
</div>
<central-guide *ngIf="'ready' === this.projectCreateService.state" #guide
	[class.last-step]="this.projectCreateService.getSelections().primarySubscriptionUuid"
	[step]="this.projectCreateService.getSelections().currentStep" [enableStepNavigation]="!guide.isLastStep()"
	stepOneImage="https://inmotionhosting.github.io/static-assets/hero/sidebar/lights-alt.jpg"
	[dynamicTitle]="this.dynamicTitle" [steps]="this.projectCreateService.steps"
	[logoLink]="this.projectCreateService.pricingPage">
	<img class="ctw-w-full logo" logo src="{{ this.brandingService.getThemedAssetUrl('logo3.svg') }}" />
	<img class="ctw-w-full logo" logo-dark src="{{ this.brandingService.getThemedAssetUrl('logo2.svg') }}" />
	<img class="ctw-w-40" logo-top src="{{ this.brandingService.getThemedAssetUrl('logo3.svg') }}" />

	<div name>
		<p class="tagline">
			One Platform, <br />Endless Possibilities. <br /><br />
			The Future of WordPress Hosting is Here.
		</p>
	</div>
	<div beforeContent class="ctw-text-right">
		<div class="start-over" *ngIf="
				!guide.isLastStep() &&
				!guide.isFirstStep() &&
				this.projectCreateService.getSelections().mode === 'purchase'
			">
			<button mat-stroked-button (click)="this.projectCreateService.restart()">
				<mat-icon>rotate_left</mat-icon> Start Over
			</button>
		</div>
	</div>
</central-guide>
