import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../project.service';
import { ApiService } from '@central/ng-shared';
import { NgForm } from '@angular/forms';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from './../confirmation-dialog/confirmation-dialog.component';

interface PhpVersion {
	version: string;
	recommended: boolean;
	eol: boolean;
	allowed: boolean;
	securityFixesOnly: boolean;
}

@Component({
	selector: 'central-php-switcher',
	templateUrl: './php-switcher.component.html',
	styleUrls: ['./php-switcher.component.css'],
})
export class PhpSwitcherComponent implements OnInit {
	versions: PhpVersion[] = [
		{
			version: '8.3',
			recommended: true,
			eol: false,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '8.2',
			recommended: true,
			eol: false,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '8.1',
			recommended: false,
			eol: false,
			allowed: true,
			securityFixesOnly: true,
		},
		{
			version: '8.0',
			recommended: false,
			eol: true,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '7.4',
			recommended: false,
			eol: true,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '7.3',
			recommended: false,
			eol: true,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '7.2',
			recommended: false,
			eol: true,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '7.1',
			recommended: false,
			eol: true,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '7.0',
			recommended: false,
			eol: true,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '5.6',
			recommended: false,
			eol: true,
			allowed: false,
			securityFixesOnly: false,
		},
	];

	setProfile: string =
		this.projectService.environment.fields.playbook_values?.php_version ||
		'7.4';
	selectedProfile: string =
		this.projectService.environment.fields.playbook_values?.php_version ||
		'7.4';
	isRunningPlaybook: boolean | null = null;
	hasSubmittedForm = false;
	isOverriden = false;
	state = 'loading';
	error: string;
	interval;
	profile: string | null = null;
	nonPublishingStates = ['success', 'fail'];
	ready = false;

	constructor(
		private projectService: ProjectService,
		private apiService: ApiService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		if (this.projectService.environmentState === 'pending') {
			this.state = 'failed';
		} else {
			this.getPlaybookStatus();
		}
	}

	/**
	 * Gets status of project's playbook runs
	 */
	getPlaybookStatus() {
		this.projectService.playbookStatus.subscribe((msg: any) => {
			this.state =
				msg !== 'playbook-not-running' || msg === 'playbook-overridden'
					? 'blocked'
					: this.state;
			if (
				this.state === 'blocked' &&
				(msg === 'playbook-not-running' || msg === 'complete')
			) {
				this.state = 'loading';
				this.setProfile =
					this.projectService.environment.fields.playbook_values
						?.php_version || '7.4';
			}
		});
	}

	updateProfile(submission: NgForm) {
		this.state = 'submitted';
		this.projectService.playbookStatus.next('playbook-started');
		this.hasSubmittedForm = true;
		this.isRunningPlaybook = true;
		this.profile = submission.form.value.profile;
		// If saved value is the same as what's being submitted.
		const storedProfile =
			this.projectService.environment.fields.playbook_values
				?.php_version || '7.4';

		if (storedProfile === submission.form.value.profile) {
			this.state = 'success';
			this.isRunningPlaybook = false;
			return;
		}

		this.apiService
			.post(
				'/v1/environments/' +
					this.projectService.environment.id +
					'/run-playbook',
				{
					php_version: submission.form.value.profile,
				}
			)
			.subscribe(
				() => {},
				(error: any[]) => {
					this.isRunningPlaybook = false;
					this.state = 'failed';
					this.error = error['errors'][0]['message'];
				}
			);
	}

	openDialog(submission: NgForm) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = false;
		dialogConfig.width = '500px';
		dialogConfig.data = {
			title: 'Warning!',
			message:
				'Changing PHP versions will cause your site to be unavailable for several minutes while your server is being updated!',
			important: true,
		};

		const dialogRef = this.dialog.open(
			ConfirmationDialogComponent,
			dialogConfig
		);

		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.updateProfile(submission);
			}
		});
	}
}
