// Modules.
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { CheckoutModule } from '../checkout/checkout.module';
import { SharedModule } from '../shared/shared.module';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import {
	AccountService,
	BillingModule,
	MembershipModule,
	OrganizationModule,
	PhoneNumberModule,
} from '@central/ng-shared';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router } from '@angular/router';

// Components.
import {
	AccountComponent,
	UpdateBillingComponent,
	BillingComponent,
} from './index';
import { ReceiptListComponent, ReceiptViewComponent } from '../receipt';
import { SubscriptionComponent } from './subscription';

@NgModule({
	imports: [
		CommonModule,
		BillingModule,
		SharedModule,
		CheckoutModule,
		MomentModule,
		MatPasswordStrengthModule,
		MembershipModule,
		OrganizationModule,
		PhoneNumberModule,
		MatSidenavModule,
	],
	declarations: [
		AccountComponent,
		UpdateBillingComponent,
		BillingComponent,
	],
	exports: [UpdateBillingComponent, BillingComponent],
	providers: [AccountService],
})
export class AccountModule {
	public routes = [
		{
			path: 'billing',
			component: BillingComponent,
			icon: 'payments',
			organizationRoute: true,
			permission: 'manage',
			label: 'Billing',
			orgOnly: true,
		},
		{
			path: 'subscriptions',
			component: SubscriptionComponent,
			icon: 'account_balance',
			organizationRoute: true,
			permission: 'manage',
			label: 'Subscriptions',
			orgOnly: true,
		},
		{
			path: 'receipts',
			component: ReceiptListComponent,
			icon: 'receipt',
			organizationRoute: true,
			permission: 'manage',
			label: 'Receipts',
			orgOnly: true,
		},
		{
			path: 'receipts/:receiptId',
			component: ReceiptViewComponent,
		},
	];

	public constructor(public router: Router) {
		this.mergeProjectRoutes();
	}

	public mergeProjectRoutes() {
		this.router.config.unshift({
			path: 'account/organizations',
			redirectTo: '/teams',
		});
		this.router.config.unshift({
			path: 'account/:accountId/organizations',
			redirectTo: '/teams',
		});
		this.router.config.unshift({
			path: 'account/:accountId/organization/create',
			redirectTo: '/teams/new',
		});
		this.router.config.unshift({
			path: 'account/notifications',
			redirectTo: '/notifications/all',
			pathMatch: 'full',
		});
		this.router.config.unshift({
			path: 'account/:accountId/notifications',
			redirectTo: '/notifications/all',
			pathMatch: 'full',
		});

		this.router.config.find((val) => {
			if (val.path.startsWith('account') && val.children) {
				const children = val.children.filter((value) => {
					if (
						value.path === 'organizations' ||
						value.path === 'organization'
					) {
						return false;
					}

					return true;
				});

				val.children = [...this.routes, ...children];
			}
		});
	}
}
