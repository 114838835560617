<mat-progress-bar
	class="loader"
	mode="indeterminate"
	*ngIf="this.loading"
></mat-progress-bar>

<ng-container *ngIf="!this.loading">
	<p>
		This value is used to connect your website to Central. It is also needed
		to purchase images within our WordPress plugins. It should already be
		saved in your WordPress, so you should only need to enter it if
		prompted.
	</p>
	<mat-form-field appearance="outline">
		<mat-label>Connect Key</mat-label>
		<input
			class="connect-key"
			matInput
			readonly
			[(ngModel)]="this.connectKey.key"
		/>
		<button
			mat-stroked-button
			class="copy-button"
			[matTooltip]="this.copyText"
			matTooltipPosition="above"
			(click)="copy($event)"
			[aria-label]="this.copyText"
			color="primary"
		>
			Copy
		</button>
	</mat-form-field>
	<h3>Replace Your Key</h3>
	<p>
		Use if your Connect Key has become compromised or you would like to
		change your Connect Key for security reasons.
	</p>
	<div class="ctw-text-right">
		<button
			[routerLink]="['/connect-key/replace', this.connectKey?.connect_id]"
			mat-stroked-button
			color="primary"
		>
			Replace Key
		</button>
	</div>
</ng-container>
