<form (ngSubmit)="this.update()">
	<h3>Name this {{ data.label }}</h3>

	<mat-form-field>
		<input matInput placeholder="Name" name="cloud_name" required [(ngModel)]="resource['label']" />
		<mat-hint class="error" *ngIf="this.state === 'failed'">
			Could not rename. Please refresh and try again.
		</mat-hint>
	</mat-form-field>

	<div class="action-buttons ctw-text-right">
		<button type="button" [disabled]="state === 'submitted'" mat-button (click)="this.reset()">
			Cancel
		</button>
		<central-progress-button color="primary" [state]="this.state">Update</central-progress-button>
	</div>
</form>
