<div class="usage-header">
	<h2>All Users</h2>
	<button mat-raised-button (click)="this.fetch()">
		<mat-icon>refresh</mat-icon> Refresh
	</button>
</div>

<!-- Load State -->
<mat-progress-bar *ngIf="'submitted' === this.state" mode="indeterminate"></mat-progress-bar>

<!-- Success State -->
<div class="main-content">
	<div *ngIf="state === 'fail'" class="failed">
		<h3 class="error">Failed to load users.</h3>
		<button mat-raised-button (click)="this.fetch()">Reload</button>
	</div>

	<div class="user_table ctw-p-4">
		<div class="search">
			<mat-form-field appearance="fill" class="ctw-w-full ctw-pt-4">
				<mat-label>{{ this.searchLabel }}</mat-label>
				<mat-icon matPrefix>search</mat-icon>
				<input matInput type="text" placeholder="{{ this.searchPlaceholder }}" [(ngModel)]="searchBox"
					(keyup)="search($event)" />
			</mat-form-field>
		</div>
		<table mat-table [dataSource]="data" matSort>
			<ng-container matColumnDef="account_id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
				<td mat-cell *matCellDef="let element">
					{{ element.account_id }}
				</td>
			</ng-container>

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
				<td mat-cell *matCellDef="let element">
					<a class="id_link" [routerLink]="['/agent/user', element.account_id]">{{ element.name }}</a>
				</td>
			</ng-container>

			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
				<td mat-cell *matCellDef="let element">{{ element.email }}</td>
			</ng-container>

			<ng-container matColumnDef="phone" *ngIf="this.configService.config.brandConfig.id !== 'imh'">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
				<td mat-cell *matCellDef="let element">{{ element.phone }}</td>
			</ng-container>

			<ng-container matColumnDef="phone" *ngIf="this.configService.config.brandConfig.id === 'imh'">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Onboarded</th>
				<td mat-cell *matCellDef="let element"
					class="ctw-text-{{ element.paid === 'Yes' ? 'green' : 'red' }}-700">{{ element.paid }}</td>
			</ng-container>

			<ng-container matColumnDef="created_at">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					Signup Date
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.created_at | amDateFormat : 'LLLL' }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
			<tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
		</table>
		<mat-paginator [length]="this.paginator_count" [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 500]"
			[showFirstLastButtons]="true" (page)="this.fetch()"></mat-paginator>
	</div>
</div>
