<h3 mat-dialog-title>Login as User</h3>
<div mat-dialog-content>
	Logging in as this user will allow you to see the application as they see
	it. You'll have administrative control over their account. While logged in
	as the user, you will be logged out of your own account. All of your actions
	will be recorded.
</div>
<div class="ctw-mt-10">You will need to use the VPN to log in as a user.</div>
<div class="ctw-mt-10">
	Would you like to log in as <strong>{{ this.data.email }}</strong
	>?
</div>
<div class="ctw-mt-5" *ngIf="this.errorMessage" class="error">
	Error: {{ this.errorMessage }}
</div>
<div class="ctw-mt-10 ctw-text-right">
	<button class="ctw-mr-3" mat-button mat-dialog-close>Cancel</button>
	<progress-button [state]="state" (click)="loginAsUser()"
		>Continue</progress-button
	>
</div>
