import { Route } from '@angular/router';
import { GuestGuard } from '@central/ng-shared';
import { ListComponent } from './list/list.component';
import { DetailsComponent } from './details/details.component';
import { SaveComponent } from './save/save.component';
import { InstallComponent } from './install/install.component';

export const CloudSnapRoutes: Route[] = [
	{
		path: 'cloud-wordpress/snaps',
		canActivate: [GuestGuard],
		children: [
			{
				path: '',
				component: ListComponent,
			},
			{
				path: 'save',
				component: SaveComponent,
			},
			{
				path: 'install',
				component: InstallComponent,
			},
			{
				path: 'snaps',
				redirectTo: '',
			},
			{
				path: ':snapId',
				component: DetailsComponent,
			},
			{
				path: ':snapId/save',
				component: SaveComponent,
			},
		],
	},
];
