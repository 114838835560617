<mat-sidenav-container *ngIf="this.projectService.hasEnvironments()">
	<mat-sidenav id="side-nav" class="advanced-layout" [ngClass]="{
			'sidebar-disabled':
				this.projectService.environment?.fields?.environment_usage ===
				'unknown'
		}" [mode]="this.sidenavMode" #sidenav>
		<mat-nav-list id="side-nav-list ctw-p-0">
			<mat-list-item class="menu-project-header" [routerLink]="['/projects/', this.projectService.project.id]"
				[routerLinkActiveOptions]="{ exact: true }" routerLinkActive="router-link-active"
				(click)="this.checkSidebar(true)">
				<div class="menu-project-settings ctw-flex ctw-flex-row ctw-items-center" matLine>
					<mat-icon mat-list-icon>keyboard_arrow_left</mat-icon>
					<span>{{ this.projectService.project.label }}</span>
				</div>
				<div matLine class="menu-project-label" *ngIf="this.projectService.environment">
					<h3>{{ this.projectService.environment?.label }}</h3>
				</div>
			</mat-list-item>

			<mat-list-item *ngIf="this.authService.isAdmin() && 0" [routerLink]="[
					'/projects',
					this.projectService.project.id,
					'agent',
					'tools'
				]" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="router-link-active">
				<mat-icon mat-list-icon>rule_folder</mat-icon>
				<span matLine>Agent Tools</span>
			</mat-list-item>

			<ng-container *ngIf="this.projectService.environment">
				<ng-container *ngIf="!this.projectService.hasEnvironmentControl()">
					<mat-list-item routerLinkActive="router-link-active" [routerLinkActiveOptions]="{ exact: true }"
						[routerLink]="[
							'/projects',
							this.projectService.project.id,
							'site'
						]">
						<mat-icon mat-list-icon>assessment</mat-icon>
						<span matLine>Environment Info</span>
					</mat-list-item>
				</ng-container>
				<ng-container *ngIf="this.projectService.hasEnvironmentControl()">
					<mat-list-item routerLinkActive="router-link-active" [routerLinkActiveOptions]="{ exact: false }"
						[routerLink]="[
							'/projects',
							this.projectService.project.id,
							this.configService.hasFeature('IMC-683') ? 'overview' : 'site'
						]">
						<mat-icon mat-list-icon class="mat-primary">tv</mat-icon>
						<span matLine>Overview</span>
						<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
					</mat-list-item>
					<mat-list-item [disabled]="this.projectService.environmentState === 'pending'" [routerLink]="[
							'/projects',
							this.projectService.project.id,
							'domain'
						]" *ngIf="this.projectService.environment?.fields.hostname" routerLinkActive="router-link-active"
						[class.ctw-opacity-50]="this.projectService.environmentState === 'pending'">
						<mat-icon mat-list-icon class="mat-primary">language</mat-icon>
						<span matLine>Site URL</span>
						<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
					</mat-list-item>
					<mat-accordion displayMode="flat" multi="true">
						<mat-expansion-panel class="mat-elevation-z" *ngIf="!this.projectService.environment?.fields
						.cloud_id">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<span>
										<mat-icon mat-list-icon>speed</mat-icon>
										Optimize</span>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-list-item [disabled]="!this.projectService.client" [routerLink]="[
									'/projects',
									this.projectService.project.id,
									'w3'
								]" routerLinkActive="router-link-active" class="ctw-pl-4" [class.ctw-opacity-50]="!this.projectService.client">
								<mat-icon mat-list-icon class="">layers</mat-icon>
								<span matLine>
									<span *ngIf="
											!this.projectService.environment
												?.fields?.package_code &&
											!this.projectService.environment
												?.fields?.cloud_id
										">Application</span>
									<span *ngIf="
											this.projectService.environment?.fields?.package_code?.includes(
												'wphosting'
											) ||
											this.projectService.environment
												?.fields?.cloud_id
										">WordPress</span>
								</span>
								<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
							</mat-list-item>
							<mat-list-item [disabled]="this.projectService.environmentState === 'pending'" *ngIf="
									this.projectService.environment?.fields
										.has_publish_setup_user
								" [routerLink]="[
									'/projects',
									this.projectService.project.id,
									'nginx-settings'
								]" routerLinkActive="router-link-active" class="ctw-pl-4"
								[class.ctw-opacity-50]="this.projectService.environmentState === 'pending'">
								<mat-icon mat-list-icon class="">memory</mat-icon>
								<span matLine>Server Cache</span>
								<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
							</mat-list-item>
							<mat-list-item [disabled]="this.projectService.environmentState === 'pending'" *ngIf="
									this.projectService.environment?.fields
										.has_publish_setup_user
								" [routerLink]="[
									'/projects',
									this.projectService.project.id,
									'php-settings'
								]" routerLinkActive="router-link-active" class="ctw-pl-4"
								[class.ctw-opacity-50]="this.projectService.environmentState === 'pending'">
								<mat-icon mat-list-icon class="">code</mat-icon>
								<span matLine>PHP Settings</span>
								<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
							</mat-list-item>
						</mat-expansion-panel>
						<mat-expansion-panel class="mat-elevation-z">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<span>
										<mat-icon mat-list-icon>build</mat-icon>
										Tools</span>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-list-item [disabled]="!this.projectService.client" [routerLink]="[
									'/projects',
									this.projectService.project.id,
									'backups'
								]" routerLinkActive="router-link-active" class="ctw-pl-4" [class.ctw-opacity-50]="!this.projectService.client">
								<mat-icon mat-list-icon class="">backup</mat-icon>
								<span matLine>Backups</span>
								<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
							</mat-list-item>

							<mat-list-item [disabled]="this.projectService.environmentState === 'pending'" [routerLink]="[
									'/projects',
									this.projectService.project.id,
									'files'
								]" routerLinkActive="router-link-active" class="ctw-pl-4"
								[class.ctw-opacity-50]="this.projectService.environmentState === 'pending'">
								<mat-icon mat-list-icon class="">folder</mat-icon>
								<span matLine>Files & Databases</span>
								<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
							</mat-list-item>

							<mat-list-item *ngIf="!this.configService.hasFeature('IMC-683')"
								[routerLink]="['/projects', this.projectService.project.id, 'vz-manager']"
								routerLinkActive="router-link-active" class="ctw-pl-4">
								<mat-icon mat-list-icon class="">tune</mat-icon>
								<span matLine>{{this.projectService.environment?.fields?.cloud_id ? 'Manage Playground'
									: 'Manage Server'}}</span>
								<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
							</mat-list-item>
							<mat-list-item *ngIf="this.authService.isAdmin()" [routerLink]="[
									'/projects',
									this.projectService.project.id,
									'sites',
									this.projectService.environment?.id,
									'agent'
								]" routerLinkActive="router-link-active" class="ctw-pl-4">
								<mat-icon mat-list-icon class="ctw-text-red-700">admin_panel_settings</mat-icon>
								<span matLine class="ctw-text-red-700 agent">Agent Controls</span>
								<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
							</mat-list-item>
							<ng-container *ngIf="0">
								<mat-list-item [routerLink]="[
										'/projects',
										this.projectService.project.id,
										'error-logs'
									]" routerLinkActive="router-link-active">
									<mat-icon mat-list-icon>tools</mat-icon>
									<span matLine>Error Logs</span>
								</mat-list-item>
								<mat-list-item [routerLink]="[
										'/projects',
										projectService.project.id,
										'access-logs'
									]" routerLinkActive="router-link-active">
									<mat-icon mat-list-icon>tools</mat-icon>
									<span matLine>Access Logs</span>
								</mat-list-item>
							</ng-container>
						</mat-expansion-panel>
					</mat-accordion>
					<mat-list-item class="wp-admin" *ngIf="this.projectService.client" (click)="
							this.wpRestService.login(
								this.projectService.environment,
								''
							)
						">
						<mat-icon mat-list-icon><span class="svg-icon"
								inlineSVG="assets/img/wordpress.svg"></span></mat-icon>
						<span matLine>WP Admin</span>
						<mat-icon class="open-in-new">open_in_new</mat-icon>
					</mat-list-item>
				</ng-container>
			</ng-container>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<ng-container *ngIf="'updating' === this.projectService.environment?.state">
			<mat-icon>update</mat-icon>
			<h3>Updating...</h3>
			<p>
				Your environment is currently being updated and is unavailable. Please wait
				or check back later.
			</p>
		</ng-container>
		<central-site-setup *ngIf="
				this.projectService.environment?.fields.environment_usage ===
					'unknown' &&
				this.projectService.environment?.fields.environment_type ===
					'vps' &&
				!this.hasRoute('/projects/' + this.projectService.project.id) &&
				!this.hasRoute('/projects/' + this.projectService.project.id + '/resize-server', true) &&
				!this.hasRoute(
					'/projects/' +
						this.projectService.project.id +
						'/add/hosting'
				) &&
				!this.hasRoute(
					'/projects/' +
						this.projectService.project.id +
						'/resize-server'
				) &&
                !this.hasRoute(
                    '/projects/' +
                        this.projectService.project.id +
                        '/php-settings'
                )
			" [environment]="this.projectService.environment">
		</central-site-setup>
		<ng-container *ngIf="
				('updating' !== this.projectService.environment?.state &&
					!(
						this.projectService.environment?.fields
							.environment_usage === 'unknown' &&
						this.projectService.environment?.fields
							.environment_type === 'vps'
					)) ||
				this.hasRoute('/projects/' + this.projectService.project.id)
				|| this.hasRoute('/projects/' + this.projectService.project.id + '/resize-server', true)
				|| this.hasRoute(
                    '/projects/' +
                        this.projectService.project.id +
                        '/php-settings'
                )
			">
			<ng-container *ngIf="
					!this.projectService.hasEnvironmentControl() &&
						!this.isProjectRoute;
					else hasControl
				">
				<div class="no-access">
					<img class="logo"
						src="https://inmotionhosting.github.io/static-assets/illustrations/small/ssh-key.svg" alt="" />
					<div class="ctw-text-center ctw-mb-5">
						<h2>View Only Access</h2>
					</div>
					<p>
						You do not have access to this environment but you can
						still log in to WordPress site manually if you have
						credentials.
					</p>
					<div>
						<span class="ctw-mr-3">
							<a href="{{
									this.projectService.environment?.fields
										.site_url
								}}/wp-admin" mat-stroked-button color="primary" target="_blank">
								<span>WP Login</span>
							</a>
						</span>
						<a href="{{
								this.projectService.environment?.fields.site_url
							}}" mat-stroked-button color="primary" target="_blank">
							<span>View Site</span>
						</a>
					</div>
				</div>
			</ng-container>
			<ng-template #hasControl>
				<ng-container *ngIf="status === 'loading' && !this.isProjectRoute">
					<mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
				</ng-container>
				<router-outlet *ngIf="
						this.configService.hasFeature('IMC-683') ||
						this.status === 'ready' ||
						this.isProjectRoute ||
						!this.ansibleManaged
					"></router-outlet>
				<connection-error
					*ngIf="!this.configService.hasFeature('IMC-683') && (this.status === 'failed') && !this.isProjectRoute && !this.hasRoute('/projects/' + this.projectService.project.id)"
					(connect)="connectClient($event)"
					[url]="this.projectService.environment?.fields.site_url"></connection-error>
				<central-playbook-running [ansibleManaged]="this.ansibleManaged" [section]="XXXX"
					*ngIf="!this.agentNeedsControl && !this.hasRoute('/projects/' + this.projectService.project.id + '/resize-server', true) && !this.hasRoute('/projects/' + this.projectService.project.id)"></central-playbook-running>
			</ng-template>
		</ng-container>
	</mat-sidenav-content>
</mat-sidenav-container>
<ng-container *ngIf="!this.projectService.hasEnvironments()">
	<router-outlet *ngIf="this.status === 'ready' || this.isProjectRoute"></router-outlet>
</ng-container>

<mat-menu class="resource-menu" #siteMenu="matMenu" yPosition="below" xPosition="before">
	<button class="resources" *ngFor="let val of projectService.project.children" mat-menu-item
		[routerLink]="['/projects', projectService.project.id, 'site']" [queryParams]="{ environment_id: val.id }"
		[class.selected]="val.id === this.projectService.environment?.id">
		<mat-icon>check</mat-icon>{{ val.label }}
	</button>
	<button *ngIf="projectService.currentUserCanPurchase"
		[routerLink]="['/projects', projectService.project.id, 'site', 'setup']" mat-menu-item class="add-new">
		<mat-icon>add</mat-icon> New Environment
	</button>
</mat-menu>
