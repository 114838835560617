import { Route } from '@angular/router';
import {
	CheckoutComponent,
	ConfirmationComponent,
	ForgotPasswordComponent,
	GuestComponent,
	LoginComponent,
	LogoutComponent,
	ResetSentComponent,
	UpdatePasswordComponent,
} from './index';

import { UserGuard } from '@central/ng-shared';
import { AppAuthComponent } from './app-auth/app-auth.component';
import { InviteComponent } from './invite/invite.component';
import { DomainRegisterComponent } from '../domain/domain-register/domain-register.component';

export const GuestRoutes: Route[] = [
	{
		path: '',
		component: GuestComponent,
		pathMatch: 'prefix',
		children: [
			{
				path: 'account/:accountId/checkout',
				component: CheckoutComponent,
			},
			{
				path: 'account/:accountId/checkout/:products',
				component: CheckoutComponent,
			},
			{
				path: 'domains/search',
				component: DomainRegisterComponent,
			},
			{
				path: 'domains/search/:domainName',
				component: DomainRegisterComponent,
			},
			{
				path: 'checkout',
				component: CheckoutComponent,
			},
			{
				path: 'checkout/:products',
				component: CheckoutComponent,
			},
			{
				path: 'auth/invite/:membershipId/:membershipCode',
				component: InviteComponent,
			},
			{
				path: 'login',
				component: LoginComponent,
				canActivate: [UserGuard],
			},
			{
				path: 'sso',
				component: LoginComponent,
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
				canActivate: [UserGuard],
			},
			{
				path: 'update-password/:email/:code',
				component: UpdatePasswordComponent,
			},
			{
				path: 'oauth2/:application_id',
				component: AppAuthComponent,
			},
			{
				path: 'confirm-account/:email/:code',
				component: ConfirmationComponent,
			},
			{
				path: 'signup',
				redirectTo: 'login',
			},
			{
				path: 'reset-sent',
				component: ResetSentComponent,
				canActivate: [UserGuard],
			},
			{
				path: 'logout',
				component: LogoutComponent,
			},
		],
	},
];
