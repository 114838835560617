<central-organization-settings
	*ngIf="accountService.isOrganization()"
	[accountId]="accountService.getResourceId()"
></central-organization-settings>

<ng-container *ngIf="!accountService.isOrganization()">
	<central-user-settings></central-user-settings>
	<central-update-password
		appearance="{{
			this.configService.config.brandConfig.id === 'imh'
				? 'standard'
				: 'fill'
		}}"
		class="password-update"
	></central-update-password>
	<central-enable-two-factor
		*ngIf="this.features.twoFactor"
		class="two-factor-enable"
	></central-enable-two-factor>
</ng-container>

<ng-content select="[contentHeader]"></ng-content>

<ng-container *ngIf="!accountService.isOrganization()">
	<central-admin-settings *ngIf="this.isAdmin"></central-admin-settings>
</ng-container>
