import { Component, OnInit } from '@angular/core';
import { AccountService, ApiService } from '@central/ng-shared';
import { Observable } from 'rxjs';
import { merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'receipts-list',
	templateUrl: 'list.component.html',
	styleUrls: ['list.component.scss'],
})
export class ReceiptListComponent implements OnInit {
	public status = 'pending';
	public singleLink = '/account/receipts';
	public receipts = [];
	public invoices = [];

	constructor(
		public apiService: ApiService,
		public accountService: AccountService
	) {}

	public ngOnInit() {
		this.singleLink = `/account/${this.accountService.id}/receipts`;

		merge(...[this.getReceipts(), this.getInvoices()]).subscribe({
			next: () => {
				this.status = 'success';
			},
			error: () => {
				this.status = 'fail';
			},
		});
	}

	public moneyFormat(priceInCents) {
		return (priceInCents / 100).toFixed(2);
	}

	private getInvoices() {
		return this.apiService
			.get(`/v1/wpcr/accounts/${this.accountService.id}/invoices`, {})
			.pipe(
				map((invoices: any[]) => {
					this.invoices = invoices;
				})
			);
	}

	public getReceipts() {
		return new Observable((observer) => {
			this.apiService.post('receiptList', {}).subscribe(
				(response) => {
					const result = response['result'];

					if (result && result['data'] && result['data']['success']) {
						this.receipts = result['data']['receipts'];
						observer.complete();
					} else {
						observer.error();
					}
				},
				(err) => {
					observer.error();
				}
			);
		});
	}
}
