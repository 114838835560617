<div class="option ctw-mt-5">
	<div>
		<h4>Account Suspension</h4>
		<p>Suspend an account and prevent them from logging into central.</p>
		<p>Current State: {{ this.isSuspended ? 'Suspended' : 'Active' }}</p>
	</div>
	<div class="ctw-mt-2">
		<progress-button [state]="this.state" (click)="toggleState()">
			{{ this.isSuspended ? 'Unsuspend' : 'Suspend' }} Account
		</progress-button>
	</div>
</div>
