<dash-card disabled="1" cardTitle="Cart" [subTitle]="[this.subtitle]" icon="shopping_cart">
	<mat-progress-bar *ngIf="this.status === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
	<ng-container *ngIf="status === 'failed'">
		<p><strong>Sorry! An error occurred.</strong></p>
		<p>
			We were unable to retrieve your requested products. Please go back
			and try again.
		</p>
	</ng-container>
	<ng-container *ngIf="status === 'success'">
		<table mat-table [dataSource]="dataSource">
			<!--- Note that these columns can be defined in any order.
		        The actual rendered columns are set as a property on the row definition" -->

			<!-- Quantity Column -->
			<ng-container matColumnDef="quantity">
				<th mat-header-cell *matHeaderCellDef>Qty</th>
				<td mat-cell *matCellDef="let element">
					<mat-form-field class="ctw-w-10 ctw-m-0 ctw-text-center">
						<input (input)="
								this.changeQuantity(
									quantity.value,
									element.code,
									quantity
								)
							" #quantity matInput [disabled]="
								!element.allows_multiple ||
								this.lockCartQuantity
							" type="number" min="1" max="99" value="{{ element.quantity }}" />
					</mat-form-field>
				</td>
			</ng-container>

			<!-- Product Column -->
			<ng-container matColumnDef="product">
				<th mat-header-cell *matHeaderCellDef>Product</th>
				<td mat-cell *matCellDef="let element">
					<div>
						{{
						element.product
						.replace(' - Yearly', '')
						.replace(' - Monthly', '')
						.replace('WordPress Hosting', 'UltraStack VPS')
						}}
					</div>
                    <div *ngIf="element.domain" class="ctw-font-bold ctw-italic ctw-text-sm">
						{{ element.domain }}
					</div>
					<div class="ctw-text-imh-gray-200 ctw-italic ctw-text-xs">
						<span>{{ element.is_recurring ? element.term.replace('s', '') : '' }}</span>
						<span>{{ element.is_recurring ? ' Subscription' : 'One-time Fee' }}</span>
					</div>
				</td>
			</ng-container>

			<!-- Price Column -->
			<ng-container matColumnDef="price">
				<th mat-header-cell *matHeaderCellDef>Price</th>
				<td mat-cell *matCellDef="let element">
					<ng-container>$</ng-container>{{ (element.price * element.quantity).toFixed(2) }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<div class="ctw-px-6 ctw-py-4">
			<div *ngIf="this.hasDiscount">
				<div class="ctw-text-sm ctw-mb-4">
					<div>
						Subtotal
						<span class="ctw-float-right"><ng-container>$</ng-container>{{ this.subTotal }}</span>
					</div>
					<div class="discount ctw-text-imh-green">
						{{ this.discount.value }}% Discount
						<span class="ctw-float-right"><ng-container>-$</ng-container>{{ this.discount.amount }}</span>
					</div>
				</div>
				<div class="ctw-border-b ctw-border-solid ctw-border-[rgba(0,0,0,0.12)] ctw--ml-6 ctw--mr-6 ctw-mb-4">
				</div>
			</div>

			<div class="final" *ngIf="this.isUpdatePlan">
				<strong>Credit</strong>
				<span class="ctw-float-right ctw-text-imh-green"><ng-container>-$</ng-container>{{
					this.updateCredit.toFixed(2) }}</span>
			</div>
			<div class="ctw-font-bold">
				Total
				<span class="ctw-float-right"><ng-container>$</ng-container>{{ this.total }}</span>
			</div>
		</div>
	</ng-container>
</dash-card>
<ng-conatainer *ngIf="allowsTermDiscount">
	<dash-card disabled="1" cardTitle="Renewal Period" icon="date_range" *ngIf="status === 'success'">
		<div class="ctw-px-5 ctw-pt-5">
			<mat-radio-group aria-label="Select an option" [(ngModel)]="this.term" (change)="this.updateProducts()"
				class="ctw-flex ctw-flex-col ctw-items-start">
				<mat-radio-button class="ctw-mb-5" value="yearly" [disabled]="!this.yearlyEnabled"
					[class.ctw-hidden]="!this.yearlyEnabled">Yearly -
					<span class="ctw-text-imh-green ctw-font-bold">2 months FREE!</span></mat-radio-button>
				<mat-radio-button class="ctw-mb-5" value="monthly" [disabled]="!this.monthlyEnabled"
					[class.ctw-hidden]="!this.monthlyEnabled">Monthly</mat-radio-button>
			</mat-radio-group>
		</div>
	</dash-card>
</ng-conatainer>
