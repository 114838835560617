import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'bgc-snap-apply',
	templateUrl: './save-new.component.html',
	styleUrls: ['./save-new.component.scss'],
})
export class SaveNewComponent implements OnInit {
	@Output() public back = new EventEmitter();
	@Output() public saved = new EventEmitter();

	@Input() public themes;
	@Input() public plugins;
	@Input() public snap;

	public name = 'My First Snap';
	public description = '';
	public status = 'pending';
	public isUpdate = false;
	public ownerAccount;

	constructor(private apiService: ApiService) {}

	public ngOnInit(): void {
		if (this.snap) {
			this.name = this.snap.label;
			this.description = this.snap.description;
			this.isUpdate = true;
		}
	}

	/**
	 * Format the themes into a standard request format.
	 *
	 * @since 1.26.0
	 *
	 * @return Formatted themes.
	 */
	public formatAssets(assets: any[]): object[] {
		const formatted = [];
		for (const asset of assets) {
			formatted.push({
				type: asset.type || 'wporg',
				slug: asset.slug || '',
				label: asset.name || '',
				description:
					asset.type === 'custom'
						? asset.short_description || ''
						: '',
				url: asset.url,
			});
		}

		return formatted;
	}

	/**
	 * Save the new snap.
	 *
	 * @since 1.26.0
	 */
	public save(): void {
		this.status = 'submitted';

		let headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		headers = headers.append(
			'X-Organization-Id',
			this.ownerAccount?.account_id || this.snap.account_id
		);

		let call = this.apiService.http.post(
			this.apiService.formatter.getUrl('snapCreate'),
			{
				label: this.name || '',
				description: this.description || '',
				config: {
					themes: this.formatAssets(this.themes),
					plugins: this.formatAssets(this.plugins),
				},
			},
			{ headers }
		);

		if (this.isUpdate) {
			call = this.apiService.http.post(
				this.apiService.formatter.getUrl(
					`/v1/snaps/${this.snap.snap_id}`
				),
				{
					snap_id: this.snap.snap_id,
					label: this.name || '',
					description: this.description || '',
					config: {
						themes: this.formatAssets(this.themes),
						plugins: this.formatAssets(this.plugins),
					},
				},
				{ headers }
			);
		}

		call.subscribe(
			(response) => {
				this.status = 'success';
				this.snap = response;
				this.saved.emit(this.snap);
			},
			() => (this.status = 'failed')
		);
	}
}
