import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService, ProfileService } from '@central/ng-shared';

@Component({
	selector: 'central-domain-select',
	templateUrl: './domain-select.component.html',
	styleUrls: ['./domain-select.component.scss'],
})

export class DomainSelectComponent implements OnInit {
	public componentState = 'loading';
	private firstPartyRequestState = 'loading';
	private thirdPartyRequestState = 'loading';

	private adminAccountIds = [];
	public options: string[] = [];

	public selectedDomainName: string;
	public enteredDomainName: string;
	public manual = false;
	public status = '';

	@Input() allowManual: boolean;
	@Input() context: string;
	@Input() usedDomains: string[] = [];
	@Input() hostname: string;
	@Input() invalid: boolean;

	@Output() public domainInputChange = new EventEmitter();
	@Output() domainSelectReady = new EventEmitter();

	constructor(
		public apiService: ApiService,
		private profileService: ProfileService
	) {}

	ngOnInit(): void {
		if (this.context) {
			this.adminAccountIds.push(this.context);
		} else {
			this.profileService.data.account_access.forEach((account) => {
				if (account.role === 'owner') {
					this.adminAccountIds.push(account.account_id);
				}
			});
		}

		if (this.hostname) {
			this.options.push(`${this.hostname} (Original Hostname)`);
		}

		this.fetchDomains();
	}

	public fetchDomains() {
		// Load first-party domains
		this.apiService.get('/v1/domain-service/domain', {}).subscribe(
			(response: any[]) => {
				response.forEach((domain) => {
					if (
						this.adminAccountIds?.includes(domain.account_id) &&
						!this.usedDomains?.includes(domain.label)
					) {
						this.options.push(domain.name);
					}
				});
				this.firstPartyRequestState = 'succeeded';
				this.updateComponentState();
			},
			(error) => {
				this.firstPartyRequestState = 'failed';
				this.updateComponentState();
			}
		);

		// Load third-party domains
		this.apiService.get('/v1/domains', {}).subscribe(
			(response: any[]) => {
				response.forEach((domain) => {
					if (
						this.adminAccountIds?.includes(
							domain.fields.organization_id
						) &&
						!this.usedDomains?.includes(domain.label)
					) {
						this.options.push(domain.label);
					}
				});
				this.thirdPartyRequestState = 'succeeded';
				this.updateComponentState();
			},
			(error) => {
				this.thirdPartyRequestState = 'failed';
				this.updateComponentState();
			}
		);
	}
	private updateComponentState() {
		if (
			this.firstPartyRequestState === 'succeeded' &&
			this.thirdPartyRequestState === 'succeeded'
		) {
			this.options.sort();
			this.componentState = 'success';
			this.domainSelectReady.emit();
			this.status = 'ready';
		} else if (
			this.firstPartyRequestState === 'failed' ||
			this.thirdPartyRequestState === 'failed'
		) {
			this.componentState = 'error';
		}
	}

	public domainSelectChanged(domain) {
		this.domainInputChange.emit(domain);
		this.selectedDomainName = domain;
	}
}
