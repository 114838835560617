<mat-spinner
	*ngIf="status === 'loading'"
	class="component-loader"
	diameter="50"
	color="primary"
></mat-spinner>
<failed-request *ngIf="status === 'failed-loading'"></failed-request>

<mat-tab-group
	#tabGroup
	class="site-theme--component"
	*ngIf="status === 'ready'"
	(selectedTabChange)="
		this.tabSelected = this.tabSelected || tabGroup.selectedIndex === 1
	"
>
	<mat-tab label="My Themes">
		<div class="ctw-mt-8 animate__animated animate__fadeIn">
			<div class="installed">
				<h3>Active</h3>
				<a
					mat-button
					mat-stroked-button
					color="primary"
					(click)="
						this.wpRestService.login(
							this.projectService.environment,
							'wp-admin/themes.php'
						)
					"
					>Manage in WordPress</a
				>
			</div>
			<table
				mat-table
				[dataSource]="themes"
				class="mat-elevation-z activeThemes"
			>
				<!--- Note that these columns can be defined in any order.
				  The actual rendered columns are set as a property on the row definition" -->

				<!-- Position Column -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Theme</th>
					<td mat-cell *matCellDef="let element">
						{{ element.Name }}
					</td>
				</ng-container>

				<!-- Weight Column -->
				<ng-container matColumnDef="version">
					<th mat-header-cell *matHeaderCellDef>Version</th>
					<td mat-cell *matCellDef="let element">
						<button
							*ngIf="element.UpdateAvailable"
							class="update-button"
							(click)="
								this.wpRestService.login(
									this.projectService.environment,
									'wp-admin/update-core.php'
								)
							"
							mat-button
							mat-stroked-button
							color="primary"
						>
							Update Available
						</button>
						<span class="version">{{ element.Version }}</span>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumns"
					[class.hidden]="!row.IsActive"
				></tr>
			</table>

			<ng-container *ngIf="recommended.length">
				<h3>Recommended</h3>
				<table
					mat-table
					[dataSource]="recommended"
					class="mat-elevation-z recommended"
				>
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>Theme</th>
						<td mat-cell *matCellDef="let element">
							<div class="plugin-info">
								<div class="plugin-icon">
									<img [src]="element.img" />
								</div>
								<div>
									<strong>{{ element.name }}</strong>
									<div>{{ element.description }}</div>
								</div>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="activate">
						<th mat-header-cell *matHeaderCellDef>Install</th>
						<td mat-cell *matCellDef="let element">
							<central-progress-button
								(click)="install(element)"
								[state]="element.state"
								color=""
								>Activate</central-progress-button
							>
						</td>
					</ng-container>

					<tr
						mat-header-row
						*matHeaderRowDef="displayedSuggested"
					></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: displayedSuggested"
					></tr>
				</table>
			</ng-container>

			<div class="installed">
				<h3>Inactive</h3>
			</div>

			<table
				mat-table
				[dataSource]="themes"
				class="mat-elevation-z plugins"
			>
				<!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

				<!-- Position Column -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Theme</th>
					<td mat-cell *matCellDef="let element">
						{{ element.Name }}
					</td>
				</ng-container>

				<!-- Weight Column -->
				<ng-container matColumnDef="version">
					<th mat-header-cell *matHeaderCellDef>Version</th>
					<td mat-cell *matCellDef="let element">
						<button
							*ngIf="element.UpdateAvailable"
							class="update-button"
							(click)="
								this.wpRestService.login(
									this.projectService.environment,
									'wp-admin/update-core.php'
								)
							"
							mat-button
							mat-stroked-button
							color="primary"
						>
							Update Available
						</button>
						<span class="version">{{ element.Version }}</span>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumns"
					[class.hidden]="row.IsActive"
				></tr>
			</table>
		</div>
	</mat-tab>
	<mat-tab label="Add New Theme">
		<theme-selector
			[action]="action"
			*ngIf="this.tabSelected"
			[steps]="[]"
			[allowedFilters]="['featured', 'popular', 'latest']"
		></theme-selector>
	</mat-tab>
</mat-tab-group>
