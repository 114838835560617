<ng-container *ngIf="'loading' === domainPriceStatus">
	<mat-spinner diameter="24"></mat-spinner>Loading Domains...
</ng-container>

<p *ngIf="'failed' === domainPriceStatus">
	Error getting domain prices
	<button class="simple-button" (click)="fetchCatalog()">[try again]</button>
</p>

<ng-container *ngIf="'success' === domainPriceStatus">
	<form #form="ngForm" (ngSubmit)="form.valid && isDomainAvailable()">
		<div class="ctw-flex input-wrap">
			<mat-form-field appearance="standard" floatLabel="always">
				<input matInput type="text" [(ngModel)]="domainName" [formControl]="domainValidator" autofocus
					placeholder="Start your search here..." />
				<mat-error *ngIf="domainValidator?.invalid" [innerHTML]="getErrorMessage()"></mat-error>
			</mat-form-field>
			<central-progress-button color="primary" [disabled]="!domainName" [state]="progressButtonState">{{
				progressButtonTitle }}</central-progress-button>
		</div>
	</form>

</ng-container>
