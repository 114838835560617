import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProjectService } from '../../project.service';
import { PasswordResetComponent } from '../password-reset/password-reset.component';
import { AddPublicKeyComponent } from '../add-public-key/add-public-key.component';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ApiService, ConfigurationService, SocketService } from '@central/ng-shared';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import config from '../publish/plan-selector/config';
@Component({
	templateUrl: './vz-manager.component.html',
	styleUrls: ['./vz-manager.component.scss'],
})
export class VzManagerComponent implements OnInit {
	veid: string;
	isBusy = false;
	status = 'loading';
	buttonStatus = '';
	containerStatus = '';
	stoppedBy = '';
	stoppedFor = '';
	state = 'loading';
	ready = false;
	env: string;
	sshUser = 'root';
	sshUserStatus: string;
	plans = config.plans;

	constructor(
		public projectService: ProjectService,
		private dialog: MatDialog,
		public socketService: SocketService,
		private apiService: ApiService,
		private _snackBar: MatSnackBar,
		public changeDetectorRef: ChangeDetectorRef,
		public configService: ConfigurationService
	) {}

	ngOnInit(): void {
		this.veid =
			this.projectService.environment.fields?.machine_details?.veid;

		// If environment is a playground
		if (this.projectService.environment.fields.environment_type === 'vps' || this.projectService.environment.fields?.hostname) {
			this.setupVps();
			this.env = 'vps';
		} else {
			this.env = 'playground';
			this.status = 'success';
		}

		this.getUser();
	}

	setupVps() {
		this.getContainerInfo();
		this.playbookStatus();
		const events = ['restartComplete', 'startComplete', 'stopComplete'];
		events.forEach((event) => {
			this.socketService
				.getSocket()
				.on(event, (message) => this.callComplete(message));
		});
	}

	getContainerInfo() {
		this.apiService
			.get('/v1/vps/server/info', { veid: this.veid })
			.subscribe(
				(response) => {
					if (response['stoppedby']) {
						this.stoppedBy =
							response['stoppedby'] === 'User'
								? 'You or another user on your team'
								: response['stoppedby'];
						if (response['SuspendReason']) {
							this.stoppedFor = response['SuspendReason'];
						}
					}

					// If container doesn't return 'running' as status it should be stopped.
					if ('running' !== response['status']) {
						this.containerStatus = 'stopped';
						this.buttonStatus = 'start';
					} else {
						this.containerStatus = 'running';
						this.buttonStatus = 'stop';
					}
					// Loaded info needed for view.
					this.status = 'success';
				},
				(error) => {
					this.status = 'failed';
				}
			);
	}

	callComplete(message) {
		if (
			('restartComplete' === message.name && 0 === message.data.status) ||
			'startComplete' === message.name
		) {
			this.containerStatus = 'running';
			this.buttonStatus = 'stop';
		} else {
			this.containerStatus = 'stopped';
			this.buttonStatus = 'start';
		}
		this.isBusy = false;
	}

	public restartServer() {
		// Start server if it's stopped.
		if ('stopped' === this.containerStatus) {
			this.startServer();
			return;
		}

		if (!this.isBusy) {
			this.apiService
				.post('/v1/vps/server/restart', { veid: this.veid })
				.subscribe(
					(response) => {
						this.containerStatus = 'restarting';
						this.isBusy = true;
					},
					(error) => {
						this.status = 'failed';
					}
				);
		}
	}

	public updateServer() {
		if ('running' === this.containerStatus) {
			this.stopServer();
		} else {
			this.startServer();
		}
	}

	public startServer() {
		if (!this.isBusy) {
			this.apiService
				.post('/v1/vps/server/start', { veid: this.veid })
				.subscribe(
					(response) => {
						this.containerStatus = 'starting';
						this.isBusy = true;
					},
					(error) => {
						this.status = 'failed';
					}
				);
		}
	}

	public stopServer() {
		if (!this.isBusy) {
			this.apiService
				.post('/v1/vps/server/stop', { veid: this.veid })
				.subscribe(
					(response) => {
						this.containerStatus = 'stopping';
						this.isBusy = true;
					},
					(error) => {
						this.status = 'failed';
					}
				);
		}
	}

	public openAddSshDialog(env) {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = false;
		dialogConfig.width = '500px';
		dialogConfig.data = {
			veid: this.veid,
			trial_id: this.projectService.environment?.fields?.cloud_id,
			organization_id: this.projectService.environment?.fields?.organization_id,
		};

		const dialogRef = this.dialog.open(AddPublicKeyComponent, dialogConfig);

		dialogRef.afterClosed().subscribe((result) => {
			if ('success' === result) {
				this._snackBar.open(
					'Public SSH key successfully added to server!',
					'Dismiss'
				);
			}
			if ('error' === result) {
				this._snackBar.open(
					'There was an error processing your request, the Public SSH key was not added!',
					'Dismiss'
				);
			}
		});
	}

	public openHelpDialog() {
		const dialogRef = this.dialog.open(PasswordResetComponent, {
			width: '500px',
		});
		dialogRef.afterClosed().subscribe((result) => {
			if ('success' === result) {
				this._snackBar.open(
					'Password successfully updated!',
					'Dismiss'
				);
			}
		});
	}

	/**
	 * Set the playbook running status from the playbook running component.
	 *
	 * We must trigger change detection in this case so the view doesn't become
	 * out of sync.
	 *
	 * @see https://angular.io/errors/NG0100
	 */
	public playbookStatus() {
		this.projectService.playbookStatus.subscribe((msg: any) => {
			this.state = msg === 'playbook-running' ? 'blocked' : this.state;
			if (this.state === 'blocked' && msg === 'playbook-not-running') {
				this.state = 'loading';
			}
		});
	}

	public getHostname() {
		let hostname = 'demo3.cloudwp.dev';

		if (this.env === 'vps') {
			hostname = this.projectService.environment.fields.hostname
		} else if (this.configService.config.environment !== 'production') {
			hostname = 'demo-dev-new.boldgrid.com';
		}

		return hostname;
	}

	public getIp() {
		let ip = '173.231.217.236';

		if (this.env === 'vps') {
			ip = this.projectService.environment.fields.ipAddress;
		} else if (this.configService.config.environment !== 'production') {
			ip = '173.231.254.88';
		}

		return ip;
	}

	public getUser() {
		if (this.env === 'playground' && !this.sshUserStatus) {
			this.sshUserStatus = 'getting-user';
			this.apiService
				.post('/v1/cloud-wordpress/get-ssh-user', { trial_id: this.projectService.environment?.fields?.cloud_id })
				.subscribe(
					(response:any) => {
						this.sshUser = response.message;
						this.sshUserStatus = 'success';
					},
					(error) => {
						this.sshUserStatus = 'failed';
					}
				);
		}

		return this.sshUser;
	}
}
