import { Inject, Component, EventEmitter } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AutoUpdate } from '../Settings/AutoUpdate';

@Component({
	selector: 'app-backups-update-protection',
	templateUrl: './backups-update-protection.component.html',
	styleUrls: ['../dialog/dialog.scss'],
})
export class BackupsUpdateProtectionComponent {
	public onComplete = new EventEmitter();
	public state = 'pending';
	public settingsHelper = new AutoUpdate();

	constructor(
		private snackBar: MatSnackBar,
		public matDialogRef: MatDialogRef<BackupsUpdateProtectionComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	public enable() {
		const autoupdate = this.settingsHelper.enable(this.data.settings);
		this.state = 'submitted';
		this.data.projectService.client
			.namespace('bgbkup/v1')
			.settings()
			.create({ settings: { autoupdate } })
			.then((response) => {
				this.state = 'success';
				this.matDialogRef.close();
				this.onComplete.emit(response);
				this.snackBar.open('Auto Updates Enabled', '', {
					duration: 3000,
				});
			})
			.catch(() => (this.state = 'failed'));
	}
}
