<mat-progress-bar *ngIf="this.state !== 'success' && this.state !== 'blocked' && this.state !== 'failed'"
	mode="indeterminate" color="primary"></mat-progress-bar>

<central-playbook-running *ngIf="0" [section]="'Files & Databases'"></central-playbook-running>

<failed-request *ngIf="this.state === 'failed'"></failed-request>

<div *ngIf="this.state === 'success'"
	class="feature-page animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8 ctw-mb-12">
	<div *ngIf="this.hasUltrastack || this.environmentType === 'playground'">
		<div class="icon">
			<span class="svg" [inlineSVG]="this.image"></span>
		</div>
		<div class="feature-page__info ctw-text-center">
			<ng-content></ng-content>
			<div class="action-buttons" *ngIf="!hasPremium">
				<a mat-raised-button [href]="this.helpLink" target="_blank">Learn More</a>
				<button mat-raised-button color="primary" (click)="openUpgrade()">
					Upgrade Now
				</button>
			</div>
			<div class="action-buttons" *ngIf="hasPremium">
				<button mat-raised-button color="primary" (click)="this.login(this.site, this.appLink)">
					Launch App <mat-icon>open_in_new</mat-icon>
				</button>
			</div>
		</div>
	</div>
	<app-ansible-card *ngIf="this.environmentType === 'server' && !this.hasUltrastack" class="stat-card"
		[needsUpdate]="!this.hasUltrastack" [feature]="'files-0.22'"></app-ansible-card>
</div>
