import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MomentModule } from 'ngx-moment';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TeamSettingsComponent } from './settings/settings.component';
import { TeamMembershipComponent } from './membership/membership.component';
import { MembershipModule } from '@central/ng-shared';
import { CoreModule } from '@central/ng-shared';
import { RouterModule } from '@angular/router';

import { AccountService } from '@central/ng-shared';
import { SharedModule } from '../shared/shared.module';
import { TeamComponent } from './team.component';
import { TeamCreateComponent } from './create/create.component';
import { LeaveTeamDialogComponent } from './dialogs/leave-team-dialog/leave-team-dialog.component';
import { TeamCreateDialogComponent } from './dialogs/create-team-dialog/create-team-dialog.component';
import { FormsModule } from '@angular/forms';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
	declarations: [
		TeamComponent,
		TeamCreateComponent,
		TeamSettingsComponent,
		TeamMembershipComponent,
        LeaveTeamDialogComponent,
        TeamCreateDialogComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		MomentModule,
		MatProgressSpinnerModule,
        MatProgressBarModule,
        SharedModule,
		MatIconModule,
		MembershipModule,
        MatSidenavModule,
        MatButtonModule,
		CoreModule,
		FormsModule,
		MatListModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatSelectModule,
		MatTooltipModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatMenuModule,
		MatDialogModule,
		MatTableModule,
	],
	exports: [TeamSettingsComponent],
	providers: [AccountService],
})
export class TeamModule {}
