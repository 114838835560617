import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'bg-receipt',
	templateUrl: 'receipt.component.html',
	styleUrls: ['receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {
	@Input() public orderNumber: string;
	@Input() public receipt: object;
	public status = 'pending';
	public lineItems: object[] = [];

	constructor(public apiService: ApiService) {}

	public ngOnInit() {
		if (!this.receipt && this.orderNumber) {
			this.getReceipt();
		} else {
			this.updateLineItems();
			this.status = 'success';
		}
	}

	private updateLineItems() {
		const lineItems: any[] = [];
		for (const product of this.receipt['products'] || []) {
			const existingEntry = lineItems.findIndex((item: any) => (
					product.name === item.name &&
					item.renewal_date === product.renewal_date
				));

			const price = +product.price;

			if (-1 !== existingEntry) {
				lineItems[existingEntry].quantity =
					lineItems[existingEntry].quantity + 1;
				lineItems[existingEntry].price =
					lineItems[existingEntry].price + price;
			} else {
				product.price = price;
				product.quantity = 1;
				lineItems.push(product);
			}
		}

		this.lineItems = lineItems;
	}

	private getReceipt() {
		this.apiService
			.post('receiptGet', { order_number: this.orderNumber })
			.subscribe(
				(response) => {
					const result = response['result'];

					if (result && result['data'] && result['data']['success']) {
						this.receipt = result['data']['receipt'];
						this.updateLineItems();
						this.status = 'success';
					} else {
						this.status = 'fail';
					}
				},
				() => (this.status = 'fail')
			);
	}
}
