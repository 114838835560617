import { Input, Component, OnInit } from '@angular/core';
import { ProjectService } from '../../project.service';
import { ApiService, PollingService } from '@central/ng-shared';

@Component({
	selector: 'app-ansible-card',
	templateUrl: './ansible-card.component.html',
	styleUrls: ['./ansible-card.component.scss'],
})
export class AnsibleCardComponent implements OnInit {
	@Input() public needsUpdate: boolean;
	@Input() public feature: boolean;

	public overrideState;
	public overrideBy;
	public hasUpdates = false;
	public currentVersion = 'Checking...';
	public latestVersion: string;
	public projectId;
	public state = 'loading';
	public error: string;
	public isRunningPlaybook = false;
	interval;

	constructor(
		public projectService: ProjectService,
		public pollingService: PollingService,
		private apiService: ApiService
	) {
		this.projectId = projectService.project.id;
	}

	ngOnInit() {
		this.getLatestVersion();
	}

	/**
	 * Does this user have pending updates.
	 */
	public checkHasUpdates() {
		this.hasUpdates = this.currentVersion !== this.latestVersion;
		this.loadOverrideState();
	}

	/**
	 * Does this user have pending updates.
	 */
	public getCurrentVersion() {
		this.currentVersion =
			this.projectService.environment.fields.playbook_values?.version ||
			'Not available';
		this.checkHasUpdates();
	}

	/**
	 * Does this user have pending updates.
	 */
	public getLatestVersion() {
		this.apiService.get('/v1/playbook/latest', {}).subscribe(
			(response: any[]) => {
				this.latestVersion = response[0];
				this.getCurrentVersion();
			},
			() => {
				//failed
			}
		);
	}

	public runPlaybook() {
		this.state = 'submitted';
		this.isRunningPlaybook = true;
		this.apiService
			.post(
				'/v1/environments/' +
					this.projectService.environment.id +
					'/run-playbook',
				{}
			)
			.subscribe(
				() => {
					this.projectService.reloadProject().subscribe((msg) => {
						this.setStatus(this.projectService.environment);
					});
				},
				(error: any[]) => {
					this.isRunningPlaybook = false;
					this.state = 'failed';
					this.error = error['errors'][0]['message'];
				}
			);
		this.getLatestVersion();
	}

	setupPolling() {
		this.state = 'playbook-running';
		this.projectService.playbookStatus.next(this.state);
		this.doPolling();
	}

	public setStatus(environment) {
		// Update Project Service fields.
		this.projectService.environment.fields = {
			...this.projectService.environment.fields,
			...environment.fields,
		};

		if (
			this.projectService.environment.fields.playbook_running ===
			'in-progress'
		) {
			this.setupPolling();
		} else {
			this.state = 'playbook-not-running';
			this.projectService.playbookStatus.next(this.state);
		}
	}

	public doPolling() {
		this.pollingService
			.startPoll(
				() => this.projectService.reloadProject(false),
				() =>
					!this.projectService.environment.fields?.playbook_running ||
					this.projectService.environment.fields.playbook_running ===
						'complete'
			)
			.subscribe(
				() => {
					this.setStatus(this.projectService.environment);
					this.state = 'playbook-not-running';
					this.projectService.playbookStatus.next(this.state);
				},
				(error) => {
					this.state = 'playbook-request-failed';
					this.projectService.playbookStatus.next(this.state);
				}
			);
	}

	public toggleOverride() {
		this.state = 'loading';

		let comment = 'User Disabled';
		if (this.overrideState) {
			comment = 'User Enabled';
		}
		const postData = {
			state: this.overrideState ? false : true,
			comment: comment,
		};

		this.apiService
			.post('/v1/override/' + this.projectId + '/create', postData)
			.subscribe((response) => {
				this.loadOverrideState();
			});
	}

	public loadOverrideState() {
		this.apiService
			.get('/v1/override/' + this.projectId + '/state', {})
			.subscribe((response) => {
				this.apiService
					.get('/v1/override/' + this.projectId, {})
					.subscribe((history: any) => {
						this.overrideState = response['state'];
						if (response['state']) {
							this.overrideBy =
								history[0]['Comment'] !== 'User Disabled'
									? 'Agent'
									: 'User';
							if (!this.feature) {
								this.projectService.playbookStatus.next(
									'playbook-overridden'
								);
							}
						} else {
							this.overrideBy = '';
							this.projectService.playbookStatus.next(
								'playbook-not-running'
							);
						}
						this.state = 'success';
					});
			});
	}
}
