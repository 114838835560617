import { WpRestService } from './rest.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Installer } from '../../cloud-snap/installer';

@Injectable()
export class ScheduleService {
	private siteStates: Installer[] = [];
	private messenger = new EventEmitter();

	constructor(private wpRestService: WpRestService) {}

	public getMessenger() {
		return this.messenger;
	}

	public runner(method: string, snap: Snap, environment: Environment) {
		let siteProvisioner = this.siteStates.find((siteState) => siteState.getSiteUrl() === environment.fields.site_url);

		if (!siteProvisioner || siteProvisioner.state === 'available') {
			if (!siteProvisioner) {
				siteProvisioner = new Installer(
					this.wpRestService,
					environment,
					snap,
					this.messenger
				);
				this.siteStates.push(siteProvisioner);
			}

			return siteProvisioner[method]();
		} else {
			return new Observable((observer) =>
				observer.error('Site is unavailble.')
			);
		}
	}
}
