<ng-container *ngIf="this.paymentPage">
	<div class="lead ctw-text-left">
		<div class="header">
			<h3>Purchase Complete</h3>
			<button mat-raised-button mat-button color="primary" [routerLink]="this.getPurchaseRoute()"
				class="ctw-float-right">
				Continue
			</button>
		</div>
		<div class="content">
			<p>
				Your receipt for this transaction can be found below and can be
				accessed at any time in
				<a [routerLink]="['/account/' + this.account + '/receipts']">My Purchases</a>. Please see the following
				sections for more information on
				your purchased items. Thank you for your business!
			</p>

			<ng-content></ng-content>
		</div>
	</div>
	<div class="ctw-text-right dashboard"></div>
</ng-container>
<ng-container *ngIf="this.products && 0">
	<ng-container *ngFor="let productType of this.productTypes">
		<dash-card class="product-type ctw-text-left" cardTitle="{{ productType.label }}" icon="{{ productType.icon }}"
			*ngIf="countTypePurchased(productType.name); let count">
			<div class="product-type__body" *ngIf="'key' === productType.name">
				<ng-container *ngIf="
						0 < count - this.countUpgradeByType(productType.name)
					">
					<strong>New
						{{
						1 === count ? 'Connect Key' : 'Connect Keys'
						}}</strong>
					<ng-container *ngIf="1 === count && this.returnWpUrl">
						<p>
							Congratulations on your new Connect Key. Enable your
							features by adding the following Connect Key to your
							WordPress.
						</p>
					</ng-container>
					<ng-container *ngIf="!(1 === count && this.returnWpUrl)">
						<p>
							Congratulations on your new
							{{ 1 === count ? 'Connect Key' : 'Connect Keys' }}.
							You can begin enjoying any new features immediately.
							You'll find instructions on updating your Connect
							Key here:
							<a target="_blank" href="{{
									this.config['externalUrls'][
										'activating-connect-key'
									]
								}}">Activating your BoldGrid Connect Key</a>.
						</p>
					</ng-container>
					<div class="alert">
						<ul>
							<ng-container *ngFor="let product of this.products">
								<li *ngIf="
										isType(product, productType.name) &&
										!product.is_upgrade
									">
									{{ product?.purchased_item?.api_key }}
								</li>
							</ng-container>
						</ul>
					</div>
					<div *ngIf="1 === count && this.returnWpUrl" class="wp-redirect center-align">
						<button mat-button mat-raised-button (click)="addToWordpress(this.products[0])" color="primary">
							<mat-icon>vpn_key</mat-icon> Add to My WordPress
						</button>
					</div>
				</ng-container>
				<ng-container *ngIf="0 < this.countUpgradeByType(productType.name)">
					<strong>{{
						1 === count ? 'Connect Key' : 'Connect Keys'
						}}
						Upgraded</strong>
					<p>
						You can begin using any of your new BoldGrid Connect Key
						features immediately. If your currently using WordPress
						with this Connect Key, you'll need to refresh WordPress
						in your browser in order to see the changes. Your
						Connect Key number is still the same, no further action
						is necessary. You can download any Premium plugins from
						the
						<a [routerLink]="['/account/plugins']">Plugins</a> page.
					</p>

					<div class="alert">
						<ul>
							<ng-container *ngFor="let product of this.products">
								<li *ngIf="
										isType(product, productType.name) &&
										'upgrade' === product.purchase_type
									">
									{{ product?.purchased_item?.api_key }}
								</li>
							</ng-container>
						</ul>
					</div>
				</ng-container>
			</div>

			<div class="product-type__body" *ngIf="'service' === productType.name">
				<p>
					The following services were added to your account. Please
					see the support article to get started.
				</p>
				<ng-container *ngFor="let product of this.products">
					<div class="alert" *ngIf="
							isType(product, productType.name) &&
							!this.hasCustomUI(product.name)
						">
						<ul>
							<li>Name: {{ product.label }}</li>
							<li>
								Getting Started:
								<a target="_blank" href="{{ product.article }}">{{ product.article }}</a>
							</li>
						</ul>
					</div>
				</ng-container>
			</div>

			<div class="product-type__body" *ngIf="'crio' === productType.name">
				<div *ngIf="this.getProductDetails('crio'); let product">
					<p>
						You purchased the Premium {{ product.label }}! To use
						this theme, you'll first need to install and activate
						the free theme. Afterward, the premium features are
						unlocked by installing our premium plugin. You'll find
						links to your files and a Getting Started guide below.
					</p>
					<div class="alert">
						<ul>
							<li>
								Theme:
								<a target="_blank" href="https://downloads.wordpress.org/theme/crio.zip">Download
									File</a>
							</li>
							<li>
								Premium Plugin:
								<a target="_blank" href="https://repo.boldgrid.com/crio-premium.zip">Download File</a>
							</li>
							<li>
								Getting Started:
								<a target="_blank" href="{{ product.article }}">{{ product.article }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="product-type__body" *ngIf="'cloud-wordpress' === productType.name">
				<cwp-post-purchase></cwp-post-purchase>
			</div>

			<div class="product-type__body" *ngIf="'speedcoach' === productType.name">
				<speedcoach-purchase></speedcoach-purchase>
			</div>

			<div class="product-type__body" *ngIf="'coin' === productType.name">
				<p>
					You can begin using your Copyright Coins immediately by
					logging into your WordPress Installation and making any
					purchase that requires coins. Please visit the
					<a [routerLink]="['/']">{{ brandingService.getAppName() }} Dashboard</a>
					to view the coin balances for any of your Connect Keys.
				</p>
				<div class="alert">
					<ul>
						<li>
							Copyright Coin Balance:
							{{ this.getCoinBalance() }} Coins
						</li>
					</ul>
				</div>
			</div>

			<div class="product-type__body" *ngIf="this.isAssetType(productType.name)">
				<p>
					Your files are contained within the following zip file.
					Please see the support article to get started.
				</p>
				<ng-container *ngFor="let product of this.products">
					<div class="alert" *ngIf="isType(product, productType.name)">
						<ul>
							<li>Name: {{ product.label }}</li>
							<li>
								File:
								<a target="_blank" href="{{ product?.purchased_item?.zip }}">Download File</a>
							</li>
							<li>
								Getting Started:
								<a target="_blank" href="{{ product.article }}">{{ product.article }}</a>
							</li>
						</ul>
					</div>
				</ng-container>
			</div>
		</dash-card>
	</ng-container>
</ng-container>
<div *ngIf="this.products.length && this.receipt" class="receipt-divider"></div>
<div class="user-community mat-elevation-z1"
	*ngIf="this.paymentPage && brandingService.getAppName().startsWith('Bold')">
	<div class="user-community--message">
		<img src="./assets/img/boldgrid-mark.svg" />
		<span>
			Maximize your investment today by joining our BoldGrid User
			Community for free support, tips, and tricks.
		</span>
	</div>
	<div class="cta">
		<a mat-button mat-raised-button target="_blank"
			href="https://www.facebook.com/groups/BGTeamOrange"><mat-icon>group</mat-icon> User Community</a>
	</div>
</div>
<div class="receipt-wrapper ctw-text-left" *ngIf="receipt">
	<central-invoice *ngIf="isInvoice(receipt)" [invoice]="this.receipt"></central-invoice>
	<bg-receipt *ngIf="!isInvoice(receipt)" [receipt]="this.receipt"></bg-receipt>
</div>
