<div class="page-content">
	<div class="container">
		<h1 *ngIf="'success' !== payment.state">Checkout</h1>
		<payment-form
			#payment
			cardClass=""
			[includeReceiptQuery]="true"
			[excludedInputs]="['organization']"
			[includeBackButton]="false"
		>
		</payment-form>
	</div>
</div>
