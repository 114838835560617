import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../project.service';
import { ApiService, PollingService } from '@central/ng-shared';

@Component({
	selector: 'central-playbook-running',
	templateUrl: './playbook-running.component.html',
})
export class PlaybookRunningComponent implements OnInit {
	@Input() display = true;
	@Input() section = 'Server';
	@Input() ansibleManaged;

	public state;
	public overrideBy = '';
	public ignoreOverride = false;

	constructor(
		public projectService: ProjectService,
		public pollingService: PollingService,
		public activatedRoute: ActivatedRoute,
		public apiService: ApiService,
		public router: Router
	) {}

	ngOnInit(): void {
		if (this.projectService.environment?.fields?.environment_type === 'vps') {
			this.projectService.playbookStatus.subscribe((msg: any) => {
				this.state = msg === 'in-progress' ? 'playbook-running' : msg;
				if (this.state === 'playbook-started') {
					this.setupPolling();
				}
			});
			this.checkOverrideState();
			this.projectService.reloadProject(false).subscribe((msg) => {
				this.setStatus(this.projectService.environment);
			});
		}
	}

	setupPolling() {
		this.state = 'playbook-running';
		this.projectService.playbookStatus.next(this.state);
		this.doPolling();
	}

	public setStatus(environment) {
		// Update Project Service fields.
		this.projectService.environment.fields = {
			...this.projectService.environment.fields,
			...environment.fields,
		};

		if (
			this.projectService.environment.fields.playbook_running ===
			'in-progress'
		) {
			this.state = 'playbook-running';
			this.projectService.playbookStatus.next(this.state);
			this.doPolling();
		} else {
			this.state = 'playbook-not-running';
			this.projectService.playbookStatus.next(this.state);
		}
	}

	public doPolling() {
		this.pollingService
			.startPoll(
				() => this.projectService.reloadProject(),
				() =>
					!this.projectService.environment.fields?.playbook_running ||
					this.projectService.environment.fields.playbook_running ===
						'complete'
			)
			.subscribe(
				() => {
					this.setStatus(this.projectService.environment);
				},
				(error) => {
					this.state = 'playbook-request-failed';
					this.projectService.playbookStatus.next(this.state);
				}
			);
	}

	public checkOverrideState() {
		this.ignoreOverride = false;
		if (
			this.activatedRoute.snapshot.routeConfig.path.includes('vz-manager')
		) {
			this.ignoreOverride = true;
		} else {
			this.projectService.playbookStatus.next(this.state);
		}

		this.apiService
			.get(
				'/v1/override/' + this.projectService.project.id + '/state',
				{}
			)
			.subscribe((response: any) => {
				this.apiService
					.get('/v1/override/' + this.projectService.project.id, {})
					.subscribe((history: any) => {
						if (response.state) {
							this.state = this.ignoreOverride
								? this.state
								: 'playbook-overridden';
							this.projectService.playbookStatus.next(this.state);
							this.overrideBy =
								history[0]['Comment'] !== 'User Disabled'
									? 'Agent'
									: 'User';
						} else {
							this.projectService
								.reloadProject(false)
								.subscribe(() =>
									this.setStatus(
										this.projectService.environment
									)
								);
						}
					});
			});
	}
}
