import { Route } from '@angular/router';
import { EmailComponent } from './email.component';
import { EmailNewComponent } from './email-new/email-new.component';

export const EmailRoutes: Route[] = [
	{
		path: 'email',
		component: EmailComponent,
	},
	{
		path: 'email/new',
		component: EmailNewComponent,
	},
];
