import { LocalStorageService } from "./local-storage/local-storage.service";

export class ConfigurationService {
	private localStorage;

	constructor(public config: any) {
		this.localStorage = new LocalStorageService();
	}

	public hasFeature(feature: string) {
		const val = this.config.features[feature] || this.privateBeta(feature);
		return val ? val : false;
	}

	public privateBeta(feature: string) {
		const val = this.config.beta[feature] && this.localStorage.getItem('tester');
		return val ? val : false;
	}
}
