<mat-expansion-panel #matPanel class="dash-card card" (opened)="this.opened.emit()" [expanded]="this.expanded"
	[disabled]="this.disabled">
	<mat-expansion-panel-header *ngIf="this.showHeader" collapsedHeight="auto" expandedHeight="auto"
		class="ctw-bg-neutral-200">
		<mat-panel-title class="dash-card-title valign-wrapper" [class.ctw-default-cursor]="disabled"
			[class.ctw-mr-0]="disabled">
			<span *ngIf="customIcon" class="{{ customIcon }}"></span>
			<mat-icon class="ctw-z-10" *ngIf="icon">{{ icon }}</mat-icon>
			<span class="valign title-text">
				<span class="title" *ngIf="cardTitle">{{ cardTitle }}</span>
				<ng-container *ngIf="!isTemplate">
					<ng-container *ngFor="let sub of subTitle">
						<span class="sub-title ctw-text-base" *ngIf="sub">
							{{ sub }}
						</span>
					</ng-container>
					<ng-content select="[subTitleHTML]"></ng-content>
				</ng-container>
				<ng-container *ngIf="isTemplate">
					<ng-container *ngTemplateOutlet="subTitle"></ng-container>
				</ng-container>
			</span>
			<a *ngIf="iframe" class="address-bar ctw-inline-block ctw-text-base ctw-z-10"
				[class]="iframe.includes('https') ? 'ssl' : ''" href="{{ iframe }}" target="_blank">
				{{ iframe }}
				<mat-icon class="ctw-text-sm ctw-float-right ctw-p-1">open_in_new</mat-icon>
			</a>

			<span help *ngIf="help && this.centralArticle" (click)="this.openHelp()"
				class="ctw-flex ctw-ml-auto ctw-cursor-pointer">
				<mat-icon class="ctw-z-10 ctw-text-imh-gray-200">help</mat-icon>
			</span>
		</mat-panel-title>
	</mat-expansion-panel-header>

	<ng-template matExpansionPanelContent>
		<div>
			<ng-content></ng-content>
		</div>
	</ng-template>
</mat-expansion-panel>
