import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileService } from '@central/ng-shared';
import { ProjectService } from '../../project.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./simple.scss', './advanced.scss'],
})
export class DashboardComponent implements OnInit {
	public ready = false;
	public statsState = 'pending';
	private subscribedToSiteStats = false;
	public stats;
	public url: string;
	public sanitizedUrl;

	constructor(
		public projectService: ProjectService,
		private profileService: ProfileService,
		private sanitizer: DomSanitizer,
		private ref: ChangeDetectorRef
	) {}

	public ngOnInit() {
		this.url = this.projectService.environment.fields.site_url;
		this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
			this.url + '?central=1'
		);

		this.getPlaybookStatus();
	}

	/**
	 * Gets status of project's playbook runs
	 */
	getPlaybookStatus() {
		this.projectService.playbookStatus.subscribe((msg: any) => {
			if (msg === 'playbook-not-running' || msg === 'complete') {
				this.fetchStats();
			}
		});
	}

	public fetchStats() {
		try {
			if (
				this.statsState !== 'success' &&
				this.projectService.client.namespace('bgc/v1')
			) {
				// Check if already subscribed to projectService.getSiteStats
				if ( ! this.subscribedToSiteStats) {
					this.projectService.getSiteStats(false).subscribe(
						(stats) => {
							this.stats = stats;
							this.statsState = 'success';
						},
						(error) => {
							if (error.message.match(/null.*namespace/)) {
								// We're often invoking wpapi before the client is set
								setTimeout(() => this.fetchStats(), 1000);
							} else {
								this.projectService.connected.next('failed');
								this.statsState = 'failed';
							}
						}
					);
					this.subscribedToSiteStats = true;
				}
			} else {
				if (this.statsState === 'success' && this.stats) {
					return;
				}
				setTimeout(() => this.fetchStats(), 1000);
			}
		} catch (mes) {
			this.statsState = 'blocked';
			setTimeout(() => this.fetchStats(), 1000);
		}
	}

	public getServerLocation() {
		const environmentFields = this.projectService.environment.fields;
		let location = 'a third party';

		if (environmentFields.hasOwnProperty('cloud_id')) {
			location = 'an InMotion Cloud Playground';
		} else if (environmentFields.hasOwnProperty('machine_details')) {
			location = 'an InMotion Cloud WordPress';
		}

		return location;
	}

	public getOwnerName() {
		const orgId = this.projectService.project.fields.organization_id;

		const access = this.profileService.data.account_access.find(
			(i) => i.account_id === orgId
		);

		let name = this.profileService.data.display_name || 'You';
		if (access) {
			name = access.display_name;
		}

		return name;
	}

	public getTeamUrl() {
		const orgId = this.projectService.project.fields.organization_id;
		const access = this.profileService.data.account_access.find(
			(i) => i.account_id === orgId
		);

		if (access && 'organization' === access.type) {
			return `/account/${this.projectService.project.fields.organization_id}/members`;
		} else {
			return `/account/settings`;
		}
	}
}
