// Modules.
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { SharedModule } from '../shared/shared.module';

// Components.
import { ReceiptModule } from '../receipt/receipt.module';
import { CartComponent } from './cart/cart.component';
import { CartService } from './cart/cart.service';
import { CatalogDialogComponent } from './catalog-dialog/catalog-dialog.component';
import { CatalogComponent } from './catalog/catalog.component';
import { DiscountComponent } from './discount/discount.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentService } from './payment/payment.service';
import { ProductService } from './product/product.service';
import { UpgradeDialogComponent } from './upgrade-dialog/upgrade-dialog.component';
import { BillingModule } from '@central/ng-shared';
import { AccountService } from './account.service';
import { AuthenticationModule } from '@central/ng-shared';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		RecaptchaModule,
		ReceiptModule,
		BillingModule,
		AuthenticationModule,
	],
	declarations: [
		CatalogDialogComponent,
		PaymentComponent,
		UpgradeDialogComponent,
		CatalogComponent,
		DiscountComponent,
		CartComponent,
	],
	exports: [
		PaymentComponent,
		CatalogComponent,
		CartComponent,
	],
	providers: [CartService, PaymentService, AccountService, ProductService],
})
export class CheckoutModule {}
