import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ApiService, ConfigurationService } from '@central/ng-shared';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DiagnosticToolDialogComponent } from './diagnostic-tool-dialog/diagnostic-tool-dialog.component';
import { ProjectService } from '../../project.service';
import { Router } from '@angular/router';

@Component({
	selector: 'central-diagnostic-tool',
	templateUrl: './diagnostic-tool.component.html',
	styleUrls: ['./diagnostic-tool.component.scss'],
})
export class DiagnosticToolComponent implements OnInit {
	@Input() public url: string;
	@Output() public connect = new EventEmitter();

	public checks = [];
	public failedChecks = [];
	public recommended = [];
	public state = 'loading';
	public status = 'pending';
	public message = '';
	public title = 'Site Health';
	public issue = '';
	public icon = 'local_hospital';
	public attempts = 0;
	public stats;

	constructor(
		private dialog: MatDialog,
		private router: Router,
		public apiService: ApiService,
		public projectService: ProjectService,
		public configService: ConfigurationService
	) {}

	ngOnInit() {
		this.runDiag();
	}

	public runDiag(delay = 1000) {
		if (this.title !== 'Attempting Repairs') {
			this.attempts++;
		}

		if (this.attempts > 5) {
			this.attempts = 0;
			this.state = 'unavailable';
			this.issue = 'Service Unreachable';
		} else {
			if (this.failedChecks.length) {
				this.title = 'Attempting Repairs';
				this.issue = 'This may take a couple of minutes...';
				this.status = 'submitted';
				delay = this.failedChecks[0]?.fix_delay || 1000;
			}

			if (delay === 1000) {
				this.state = 'loading';
				this.checks = [];
				this.failedChecks = [];
				this.recommended = [];
				this.status = 'pending';
			}

			setTimeout(() => {
				if (
					this.projectService.environment.fields?.environment_type ===
					'vps' ||
					this.projectService.environment.fields?.hostname?.includes(
						'inmotionhosting.com'
					)
				) {
					if (
						this.projectService.environment.fields?.playbook_running ===
						'in-progress'
					) {
						this.projectService.playbookStatus.next('playbook-running');
					} else {
						this.diagnose(
							this.projectService.environment.fields?.environment_type,
							this.projectService.project.fields.project_type
						);
					}
				} else {
					if (this.projectService.client) {
						this.projectService.connected.subscribe((msg) => {
							if (msg === 'ready') {
								this.projectService.getSiteStats(false).subscribe(
									(stats: any) => {
										this.attempts = 0;
										this.stats = stats;
										this.recommended.push({});
										this.state = 'ok';
									},
									(error) => {}
								);
							}
						});
					} else {
						this.runDiag();
					}
				}

			}, delay);
		}
	}

	public openFixDialog() {
		const ref = this.dialog.open(DiagnosticToolDialogComponent, {
			width: '650px',
			data: {},
		});
		const instance = ref.componentInstance;
		instance.diag = this.recommended[0];
		instance.environment = this.projectService.environment;
		instance.projectService = this.projectService;
		/*
		 */

		ref.afterClosed().subscribe({
			next: () => {
				if ('success' === ref.componentInstance.state) {
					this.status = this.failedChecks[0].fix_status;
					this.runDiag(this.failedChecks[0]?.fix_delay || 1000);
				}
			},
		});
	}

	public diagnose(environmentType: string, projectType: string) {
		this.apiService
			.get('/v1/diagnose/' + this.projectService.environment.id, {environmentType, projectType})
			.subscribe((response: any) => {
				if (response.status && response.status === 'pending') {
					// Fix in progress
					this.title = 'Attempting Repairs';
					this.issue = 'This may take a couple of minutes...';
					this.status = 'submitted';
					this.runDiag(this.failedChecks[0]?.fix_delay || 1000);
				} else {
					this.checks = [];
					this.failedChecks = [];
					this.recommended = [];
					this.state = 'loading';
					this.status = 'pending';
					this.message = '';
					this.issue = '';

					// Sort by weight
					response.checks.results.sort(function (a, b) {
						if (a.checkWeight < b.checkWeight) return -1;
						if (a.checkWeight > b.checkWeight) return 1;
						return 0;
					});

					// Identify recomended fix (lowest weight)
					response.checks.results.forEach((check, index) => {
						// Sort by weight
						check.diags.sort(function (a, b) {
							if (a.diagWeight < b.diagWeight) return -1;
							if (a.diagWeight > b.diagWeight) return 1;
							return 0;
						});

						check.diags.forEach((diag) => {
							diag.input.forEach((input) => {
								if (input.action === 'link') {
									input.href = input.href.replace(
										'PROJECT_ID',
										this.projectService.project.id
									);
								}
							});
							if (
								diag.diagResult !== 'pass' &&
								this.recommended.length === 0
							) {
								diag.recommended = true;
								this.checks.push(check);
								this.recommended.push(diag);
							}
						});
					});

					this.failedChecks = this.checks.filter(
						(check) => check.checkResult !== 'pass'
					);

					if (this.failedChecks.length) {
						this.state = 'loaded';
						this.status = 'pending';
						this.issue = this.recommended[0].diagTitle;
					} else {
						this.projectService
							.reloadProject(false)
							.subscribe(() => {
								if (this.projectService.client === null) {
									this.projectService.loadEnvironmentClient().subscribe(
										(client) => {
											this.projectService.client = client;
											if (client) {
												this.projectService.connected.next('ready');

											} else {
												this.projectService.connected.next('failed');
											}
										},
										(error) => {
											console.log(error.message);
											this.projectService.connected.next('failed');
										}
									);
								}
								this.projectService.connected.subscribe((msg) => {
									if (msg === 'ready') {
										this.projectService.getSiteStats(false).subscribe(
											(stats: any) => {
												this.stats = stats;
												this.recommended.push({});
												this.state = 'ok';
											},
											(error) => {}
										);
									}
								});
							});
					}
				}
			});
	}
}
