<h3 mat-dialog-title>Manage a Domain</h3>
<mat-dialog-content class="ctw-mb-4">
    <p>
        If you control a domain registered through a
        third party, you can manage it's DNS records here.
    </p>
    <p>Select the Team that will manage this Domain's DNS</p>
    <central-account-selector permission="manage" (accountChanged)="accountChanged($event)"
        inputAppearance="standard"></central-account-selector>
    <central-domain-add-shared [organizationId]="ownerAccountId"
        (domainUpdated)="domainUpdated($event)"></central-domain-add-shared>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="ctw-text-right ctw-w-full">
        <button mat-button [mat-dialog-close]="false">Cancel</button>
        <central-progress-button mat-raised-button color="primary" [disabled]="!this.domainIsValid || !this.isReady"
            [state]="progressButtonState" (click)="addDomain()" class="ctw-ml-2">Add Domain</central-progress-button>
    </div>
</mat-dialog-actions>
