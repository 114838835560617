export class InstallEstimate {
	public estimatedTime: number;
	public estimatedTimePerPlugin = 2500;
	public baseTime = 15000;

	/**
	 * Get an estimated install time.
	 *
	 * @since 1.3
	 *
	 * @return  Estimated Install.
	 */
	public getEstimate(snap) {
		let estimatedTime = this.baseTime;

		estimatedTime =
			estimatedTime +
			snap.config.plugins.length * this.estimatedTimePerPlugin +
			snap.config.themes.length * this.estimatedTimePerPlugin;

		return estimatedTime;
	}
}
