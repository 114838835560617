<mat-progress-bar
	class="loader"
	mode="indeterminate"
	*ngIf="fetchState === 'loading'"
></mat-progress-bar>
<layout-base pageTitle="Billing Information">
	<failed-request *ngIf="'failed' === fetchState"></failed-request>
	<p class="notice" *ngIf="'unauthorized' === fetchState">
		Accounts with "Editor" or "Member" role cannot view team billing
		information
	</p>
	<form
		#form="ngForm"
		[hidden]="fetchState !== 'success'"
		(ngSubmit)="form.valid && this.submit()"
		class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-2xl ctw-mb-12 ctw-py-8"
	>
		<div class="contact">
			<div class="ctw-mb-10">
				<h2>
					{{ !this.isOrganization ? 'Personal' : 'Team' }} Billing
				</h2>
			</div>
			<bg-contact
				#contact
				[excludedInputs]="['email', 'organization']"
			></bg-contact>
			<mat-card class="credit-card ctw-mt-5" *ngIf="!this.changeCard">
				<mat-icon>credit_card</mat-icon>
				<div>
					<div>
						<span class="ctw-pr-3">{{
							this.paymentMethod.card_type
						}}</span>
						XXXX-{{ this.paymentMethod.last_four }}
					</div>
					<div>
						<span class="ctw-pr-3">Expires</span>&nbsp;<span
							>{{ this.paymentMethod.month }}/{{
								this.paymentMethod.year
							}}</span
						>
					</div>
				</div>
				<button
					mat-button
					mat-raised-button
					(click)="this.changeCard = true"
				>
					Change
				</button>
			</mat-card>
			<central-recurly-credit-card
				*ngIf="!this.hasInternalBilling"
				#creditCard
				[hidden]="!this.changeCard"
			></central-recurly-credit-card>
			<a
				class="saved-card"
				*ngIf="this.paymentMethod.zip && this.changeCard"
				(click)="this.changeCard = false"
				>Use saved card</a
			>
			<div
				class="result ctw-text-center"
				[class.ctw-invisible]="
					this.updateState !== 'success' &&
					this.updateState !== 'failed'
				"
			>
				<p
					[class.success]="this.updateState === 'success'"
					[class.error]="this.updateState === 'failed'"
					[class.ctw-invisible]="
						this.updateState !== 'failed' &&
						this.updateState !== 'success'
					"
				>
					<ng-container *ngIf="this.updateState === 'success'"
						>Successfully updated your billing
						information.</ng-container
					>
					<ng-container
						*ngIf="
							this.updateState === 'failed' && !creditCard.error
						"
						>{{
							this.errorMessage ||
								'We were unable to save valid billing information. Please try again.'
						}}</ng-container
					>
					<ng-container
						*ngIf="
							this.updateState !== 'failed' &&
							this.updateState !== 'success'
						"
					>
						<hr />
					</ng-container>
				</p>
			</div>
			<div class="action-buttons ctw-text-right">
				<button
					mat-button
					type="button"
					(click)="this.cancel.emit()"
					*ngIf="this.cancelable"
				>
					Cancel
				</button>
				<central-progress-button color="primary" [state]="this.updateState"
					>Update
					{{
						!this.isOrganization
							? 'Your
                    Personal'
							: "Your Team's"
					}}
					Billing Info</central-progress-button
				>
			</div>
		</div>
	</form>
</layout-base>
