<h3>Reset Root Password</h3>
<p *ngIf="serverDown" class="notice danger">
	Server is currently down, so we can't update your password. Start server to
	update password!
</p>
<p *ngIf="!serverDown" class="notice warning">
	This will reset your server's root password, make sure to update any
	references to your password you might have store locally.
</p>
<div>
	<mat-form-field appearance="outline" style="width: 100%">
		<mat-label>Password</mat-label>
		<mat-pass-toggle-visibility
			#toggle
			matSuffix
		></mat-pass-toggle-visibility>
		<input
			matInput
			#passwordWithValidation
			[type]="toggle.type"
			required
			[formControl]="passwordComponentWithValidation.passwordFormControl"
			placeholder="Password"
		/>
		<mat-hint align="end" aria-live="polite">
			{{ passwordWithValidation.value.length }} / 25
		</mat-hint>
		<mat-error
			*ngIf="
				passwordComponentWithValidation.passwordFormControl.hasError(
					'required'
				)
			"
		>
			Password is required
		</mat-error>
		<mat-error
			*ngIf="
				passwordComponentWithValidation.passwordFormControl.hasError(
					'pattern'
				)
			"
		>
			Password is not valid
		</mat-error>
	</mat-form-field>
	<mat-password-strength
		#passwordComponentWithValidation
		[password]="passwordWithValidation.value"
		(onStrengthChanged)="onStrengthChanged($event)"
	>
	</mat-password-strength>
	<!--Password's strength info-->
	<mat-password-strength-info
		[passwordComponent]="passwordComponentWithValidation"
	></mat-password-strength-info>
	<div class="mat-button-row">
		<button mat-raised-button mat-dialog-close>Cancel</button>
		<!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
		<central-progress-button
			(click)="closeDialog(passwordWithValidation.value)"
			mat-raised-button
			color="primary"
			[disabled]="!isValid"
			[state]="this.status"
			>Update</central-progress-button
		>
	</div>
</div>
