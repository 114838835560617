import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { CloudSnapRoutes } from './cloud-snap/cloud-snap.routes';
import { EmailRoutes } from './email/email.routes';
import { DomainRoutes } from './domain/domain.routes';
import { GuestRoutes } from './guest/guest.routes';
import { ReceiptRoutes } from './receipt/receipt.routes';
import { PageNotFoundComponent } from './shared/404/page-not-found.component';
import { ProjectRoutes } from './project/project.routes';
import {
	AccountRoutes,
	AgentGuard,
	GuestGuard,
	JwtComponent,
} from '@central/ng-shared';
import { TeamComponent, TeamCreateComponent } from './team/index';
import { NotificationsComponent } from './notifications/index';
import { SubscriptionRoutes } from './account/subscription/subscription.routes';

export const routes: Routes = [
	{
      path: '',
		redirectTo: 'projects',
		pathMatch: 'full'
	},
	...GuestRoutes,
	...EmailRoutes,
	...DomainRoutes,
	...ReceiptRoutes,
	...ProjectRoutes,
	...AccountRoutes,
	// Disabled because programmatically adding routes wasn't working for lazy loaded modules.
	// { path: 'account', loadChildren: () => import( '../../../../libs/ng-shared/src/lib/account/account.module' ).then( m => m.AccountModule ), canActivate: [GuestGuard] },
	...CloudSnapRoutes,
	...SubscriptionRoutes,

	// Teams
	{ path: 'teams', component: TeamComponent, canActivate: [GuestGuard] },
	{
		path: 'teams/new',
		component: TeamCreateComponent,
		canActivate: [GuestGuard],
	},
	{
		path: 'notifications/:type',
		component: NotificationsComponent,
		canActivate: [GuestGuard],
	},

	{ path: 'token', component: JwtComponent },
	{ path: 'sign-up', redirectTo: 'guide/project/signup', pathMatch: 'full' },
	//redirectTo must be local or query params don't get forwarded.
	//See https://github.com/angular/angular/issues/28661
	{ path: 'welcome', redirectTo: '/guide/project/signup' },
	{
		path: 'guide',
		loadChildren: () =>
			import('./guide/guide.module').then((m) => m.GuideModule),
	},
	{
		path: 'agent',
		loadChildren: () =>
			import('./agent/agent.module').then((m) => m.AgentModule),
		canActivate: [AgentGuard],
	},
	{ path: '**', component: PageNotFoundComponent },
];
@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
