<h2 *ngIf="title">{{ title }}</h2>
<mat-dialog-content>
	<p>{{ message }}</p>
</mat-dialog-content>
<mat-dialog-actions class="ctw-justify-end">
	<button mat-button mat-dialog-close tabindex="-1">
		{{ cancelButtonText }}
	</button>
	<button
		mat-raised-button
		color="{{ confirmButtonColor }}"
		(click)="onConfirmClick()"
		tabindex="1"
	>
		{{ confirmButtonText }}
	</button>
</mat-dialog-actions>
