<mat-progress-bar
	*ngIf="this.state !== 'success' && this.state !== 'blocked'"
	mode="indeterminate"
	color="primary"
></mat-progress-bar>

<failed-request *ngIf="this.state === 'failed'"></failed-request>

<div
	*ngIf="this.state === 'success'"
	class="plugin-page animate__animated animate__fadeIn wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8 ctw-px-8 ctw-mb-12"
>
	<div class="plugin-page__image"></div>
	<div class="plugin-page__info">
		<ng-container *ngIf="!this.hasPlugin">
			<h3>Lightning Fast Websites with</h3>
			<h3>W3 Total Cache</h3>
			<p>
				W3 Total Cache improves the SEO and user experience of your site
				by increasing website performance, reducing load times via
				features like content delivery network (CDN) integration and the
				latest best practices.
			</p>
			<div class="action-buttons">
				<a
					mat-button
					mat-raised-button
					href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/projects/caching/"
					target="_blank"
					[disabled]="this.enableState === 'submitted'"
					>Learn More <mat-icon>open_in_new</mat-icon></a
				>
				<central-progress-button
					[state]="enableState"
					(click)="enablePageCaching()"
					color="primary"
					>Enable Plugin</central-progress-button
				>
			</div>
		</ng-container>
		<ng-container *ngIf="this.hasPlugin">
			<h3>Great! W3 Total Cache is Enabled</h3>
			<p>
				W3 Total Cache improves the SEO and user experience of your site
				by increasing website performance, reducing load times via
				features like content delivery network (CDN) integration and the
				latest best practices.
			</p>
			<div class="action-buttons">
				<central-progress-button
					[state]="deleteState"
					color="secondary"
					(click)="this.disableFeature()"
					>Remove Plugin & Disable</central-progress-button
				>
				<button
					mat-button
					mat-raised-button
					color="primary"
					[disabled]="this.deleteState === 'submitted'"
					(click)="
						this.projectService.login(
							'wp-admin/admin.php?page=w3tc_setup_guide'
						)
					"
				>
					Setup Guide <mat-icon>open_in_new</mat-icon>
				</button>
			</div>
		</ng-container>
	</div>
</div>
