import {
	Component,
	EventEmitter,
	Inject,
	Output,
	ɵConsole,
} from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
	selector: 'bottom-sheet-overview-example-sheet',
	templateUrl: 'plugin-sheet.component.html',
	styleUrls: ['plugin-sheet.component.scss'],
})
export class PluginSheetComponent {
	@Output() public remove = new EventEmitter();
	@Output() public next = new EventEmitter();

	constructor(
		public bottomSheetRef: MatBottomSheetRef<PluginSheetComponent>,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: any
	) {}

	public trimText(text: string): string {
		return text.length > 70 ? text.substr(0, 70) + '...' : text;
	}

	public removeAction(plugin: object): void {
		this.remove.emit(plugin);
		this.data.plugins = this.data.plugins.filter((val) => {
			if (plugin['slug']) {
				return val.slug !== plugin['slug'];
			} else if (plugin['name']) {
				return val.name !== plugin['name'];
			} else if (plugin['label']) {
				return val.label !== plugin['label'];
			}
		});

		if (!this.data.plugins.length) {
			this.bottomSheetRef.dismiss();
		}
	}
}
