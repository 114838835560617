import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { SharedModule } from '../shared/shared.module';
import { PostPurchaseComponent } from './post-purchase/post-purchase.component';
import { BillingModule } from '../billing/billing.module';

import {
	ReceiptComponent,
	ReceiptListComponent,
	ReceiptViewComponent,
} from './index';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		MomentModule,
		BillingModule,
	],
	declarations: [
		ReceiptListComponent,
		ReceiptViewComponent,
		ReceiptComponent,
		PostPurchaseComponent,
	],
	exports: [PostPurchaseComponent],
})
export class ReceiptModule {}
