<ng-container *ngIf="this.state !== 'failed'">
	<div class="title">Are you sure you want to restore your site?</div>
	<p>
		This action will overwrite your site with the backup you selected. Your
		environment will be unavailable while this process completes.
	</p>
	<p>
		Restore Backup From:
		{{ data.backup.creation_date | amDateFormat : 'LLLL' }}
	</p>
	<div class="action-buttons">
		<button
			mat-button
			mat-raised-button
			[disabled]="this.state === 'submitted'"
			(click)="this.matDialogRef.close()"
		>
			No
		</button>
		<central-progress-button [state]="this.state" (click)="this.onSubmit.emit()"
			>Yes, Restore</central-progress-button
		>
	</div>
</ng-container>
<ng-container *ngIf="this.state === 'failed'">
	<div class="title">An unexpected error occurred</div>
	<p>We were not able to restore this backup. Please try again later.</p>
	<div class="action-buttons">
		<button
			mat-button
			mat-raised-button
			color="accent"
			(click)="this.matDialogRef.close()"
		>
			Okay
		</button>
	</div>
</ng-container>
