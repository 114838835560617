import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HostingComponent } from '../hosting/hosting.component';
import { PublishService } from '../publish.service';
import { ProjectService } from '../../../project.service';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'publish-wizard',
	templateUrl: './wizard.component.html',
	styleUrls: ['./wizard.component.scss'],
	providers: [PublishService],
})
export class WizardComponent implements OnInit {
	@ViewChild('centralHosting') public centralHosting: HostingComponent;
	@ViewChild(MatStepper) public stepper: MatStepper;

	public isReady = false;
	public isComplete = false;
	public allowPurchase = true;
	public isUpdatePlan = false;
	public isAddPlan = false;
	public currentPlan;
	public termType = 'yearly';
	public subscriptionUuid;
	public openingChat = false;
	public enableNext = true;
	public couponData;

	constructor(
		public publishService: PublishService,
		public projectService: ProjectService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		public apiService: ApiService
	) {
		// Check route to see if this is updating plan or new plan purchase.
		this.route.data.subscribe(data => {
			this.isUpdatePlan = !!data.isUpdatePlan;
		});

		this.allowPurchase = this.isUpdatePlan ||
			this.projectService.project.children.filter(
				(project) => project.fields.environment_type === 'vps'
			).length === 0;

		this.isAddPlan = this.allowPurchase && !this.isUpdatePlan;
	}

	ngOnInit() {
		this.couponData = this.projectService.couponService.couponData['server'];

		if ( this.isUpdatePlan ) {
			this.setHasServer();
			this.couponData = null;
		}
		if (!this.allowPurchase && this.stepper) {
			this.stepper.selectedIndex = 2;
		}
		this.isReady = 'success' === this.centralHosting?.state;
	}

	stepChange() {
		if (!this.centralHosting.hasServer || this.isUpdatePlan) {
			this.publishService.packages =
				this.centralHosting.planSelector.removePackageType(
					this.publishService.packages
				);
			this.publishService.packages.push(
				this.centralHosting.planSelector.getPackage()
			);
		}
		if (this.stepper.selectedIndex === 0) {
			this.isReady = false;
		}
	}

	retryFailure() {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = 'reload';
		this.router.navigate(['./'], {
			relativeTo: this.route,
			queryParamsHandling: 'preserve',
		});
	}

	back() {
		this.location.back();
	}

	public setHasServer() {
        this.projectService.getSubscription.subscribe({
			next: (subscription) => {
				const addons = subscription['subscription_add_ons'].find(
					(addon) => {
						if (addon.add_on_code.startsWith('wphosting_tier')) {
							this.currentPlan = addon.add_on_code.replace('wphosting_tier_', '');
							this.subscriptionUuid = subscription['uuid'];
							this.termType = subscription['plan_code'] === 'wpc_project_monthly' ? 'monthly' : 'yearly';
							return true;
						}
					}
				);

				// handle success state here.
				//this.state = 'success';
			},
			error: (error) => {
                console.log(error);
				// handle error state here.
				//this.state = 'success';
			},
		});
	}

	openChat() {
		this.openingChat = true;
		if (window['zE']) {
			if (window['zE'].widget === 'classic') {
				window['zE']('webWidget', 'show');
				window['zE']('webWidget', 'open');
			}
			if (window['zE'].widget === 'messenger') {
				window['zE']('messenger', 'show');
				window['zE']('messenger', 'open');
			}
			setTimeout(() => (this.openingChat = false), 2000);
		}
	}

	chatEnabled() {
		return window['zE'].widget || false;
	}
}
