import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, ProfileService } from '@central/ng-shared';
import { NotificationService } from './notification.service';

@Component({
	selector: 'bg-notifications',
	templateUrl: 'notifications.component.html',
	styleUrls: ['notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
	public status = 'loading';
	public notifications: Array<any>;
	public activeIndex: number;
	public previousIndex: number;
	public showLoader = false;
	public type = 'account';

	constructor(
		public apiService: ApiService,
		public profileService: ProfileService,
		public activatedRoute: ActivatedRoute,
		public notificationService: NotificationService
	) {}

	public ngOnInit() {
		this.type = this.activatedRoute.snapshot.params.type;
		this.getNotifications();
	}

	public activateFirstUnread() {
		let unread = this.notifications.findIndex(
			(notification) => !notification.has_read
		);

		unread = -1 === unread ? 0 : unread;

		// @TODO Activate first unread.
		this.readNotification(unread);
	}

	public readNotification(index: number) {
		const currentMessage = this.notifications[index];
		this.previousIndex = this.activeIndex;
		this.activeIndex = index;

		if (this.previousIndex !== this.activeIndex) {
			this.showLoader = true;

			setTimeout(() => {
				this.showLoader = false;
			}, 500);
		}

		if (currentMessage && false === currentMessage['has_read']) {
			this.notificationService.updateNotification(
				currentMessage.notification_id
			);
			this.notifications[index]['has_read'] = true;
			this.notificationService.decrementUnreadCount();
		}
	}

	public countUnread() {
		return this.notifications.filter((val) => !val.has_read).length;
	}

	public getToName(accountId) {
		const to = 'You';
		const account = (this.profileService.data.account_access || []).find(
			(val) => val.account_id === accountId
		);

		return account ? account.display_name : to;
	}

	public getNotifications() {
		let url = `/v1/accounts/${this.notificationService.getAccountId()}/notifications`;
		if (this.type === 'all') {
			url = `/v1/notifications`;
		}

		this.apiService.get(url, { type: 'central' }).subscribe(
			(notifications: Array<any>) => {
				this.notifications = notifications;
				this.notifications = this.notifications.sort((a, b) =>
					a.date_sent < b.date_sent ? 1 : -1
				);
				this.activateFirstUnread();
				this.notificationService.updateCount(this.countUnread());
				this.status = 'success';
			},
			(err) => {
				this.status = 'fail';
			}
		);
	}
}
