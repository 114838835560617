<div class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray">
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<h2>Email</h2>
		<div>
			<button mat-raised-button color="primary" routerLink="/email/new" *ngIf="subscriptions"
				class="animate__animated animate__fadeIn">
				<mat-icon>add</mat-icon> New Subscription
			</button>
		</div>
	</div>

	<mat-progress-bar *ngIf="'succeeded' !== requestState" mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<failed-request *ngIf="'failed' === requestState"></failed-request>

<ng-container *ngIf="'succeeded' === requestState">
	<ng-container *ngIf="subscriptions.length">
		<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-px-5 content">
			<ng-container *ngIf="'succeeded' === requestState && subscriptions.length">
				<mat-card *ngFor="let subscription of subscriptions">
					<div class="flex">
						<h3 *ngIf="!subscription.external_service_id">
							Configure new email subscription
						</h3>
						<h3 *ngIf="subscription.external_service_id">
							<span class="gray">@</span>{{ subscription.domain_id }}
						</h3>
						<p>
							Owned by
							{{ getAccountNameFromId(subscription.account_id) }}
						</p>
					</div>
					<mat-divider></mat-divider>
					<div class="card-content">
						<central-email-subscription-setup *ngIf="!subscription.external_service_id"
							[usedDomains]="usedDomains" [accountId]="subscription.account_id"
							[purchaseId]="subscription.purchase_id" [subscriptionUuid]="subscription.subscription_uuid"
							(cpanelProvisionSuccessEvent)="
								reloadSubscription($event)
							"></central-email-subscription-setup>
						<central-email-subscription-manage *ngIf="subscription.external_service_id"
							[accountId]="subscription.account_id" [cpanelUsername]="subscription.external_service_id"
							[thirdPartyDomainId]="
								subscription.third_party_domain_id
							" [domainName]="subscription.domain_id"
							[subscriptionUuid]="subscription.subscription_uuid"></central-email-subscription-manage>
					</div>
				</mat-card>
			</ng-container>
		</div>
	</ng-container>
	<div *ngIf="!subscriptions.length" class="animate__animated animate__fadeIn ctw-container ctw-mx-auto no-emails">
		<img class="ctw-mx-auto ctw-mt-10"
			src="https://inmotionhosting.github.io/static-assets/illustrations/small/unlimited-email.svg" />
		<h2 class="ctw-text-center">Fast and Simple Email</h2>

		<div class="ctw-mx-auto ctw-max-w-xl ctw-text-center">
			<div class="ctw-text-lg ctw-mb-4">
				Our affordable email is the perfect solution to start sending
				and receiving emails from your domain starting at
				<strong>$1.99</strong> per month.
			</div>

			<central-progress-button routerLink="/email/new" type="stroked" color="primary" class="ctw-ml-3">Start Your
				Team's
				Email Account <mat-icon class="next">navigate_next</mat-icon></central-progress-button>
		</div>
	</div>
</ng-container>
