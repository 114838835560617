import { Route } from '@angular/router';
import { GuestGuard, AgentGuard } from '@central/ng-shared';
import { OverviewComponent } from './site/overview/overview.component';
import { DashboardComponent } from './site/dashboard/dashboard.component';
import { DashboardComponent as ProjectDashboard } from './dashboard/dashboard.component';
import { PluginsComponent } from './site/plugins/plugins.component';
import { ThemesComponent } from './site/themes/themes.component';
import { BackupsComponent } from './site/backups/backups.component';
import { FileManagerComponent } from './site/file-manager/file-manager.component';
import { HealthComponent } from './site/health/health.component';
import { ListComponent } from './collection/collection.component';
import { PreviewComponent } from './site/preview/preview.component';
import { ConnectKeyComponent } from './site/connect-key/connect-key.component';
import { ProjectComponent } from './project.component';
import { W3Component } from './site/w3/w3.component';
import { InstallSnapComponent } from './site/install-snap/install-snap.component';
import { DomainComponent } from './site/domain/domain.component';
import { SiteSetupComponent } from './site/site-setup/site-setup.component';
import { AddonsComponent } from './site/addons/addons.component';

// Cloud Snaps.
import { ListComponent as SnapListComponent } from '../cloud-snap/list/list.component';
import { DetailsComponent as SnapDetailsComponent } from '../cloud-snap/details/details.component';
import { SaveComponent as SaveSnapComponent } from '../cloud-snap/save/save.component';
import { WizardComponent } from './site/publish/wizard/wizard.component';

// VzManager
import { VzManagerComponent } from './site/vz-manager/vz-manager.component';
import { SnapshotsComponent } from './site/snapshots/snapshots.component';
import { NginxProfilesComponent } from './site/nginx-profiles/nginx-profiles.component';
import { PhpSwitcherComponent } from './site/php-switcher/php-switcher.component';

import { AgentComponent } from './site/agent/agent.component';
import { JobsComponent } from './dashboard/jobs/jobs.component';

export const ProjectRoutes: Route[] = [
	{
		path: 'projects',
		canActivate: [GuestGuard],
		component: ListComponent,
	},
	{
		path: 'projects/:project_id/jobs',
		canActivate: [GuestGuard],
		component: JobsComponent,
	},
	{
		path: 'projects/:project_id',
		canActivate: [GuestGuard],
		component: ProjectComponent,
		children: [
			{
				path: '',
				component: ProjectDashboard,
			},
			{
				path: 'site',
				component: DashboardComponent,
			},
			{
				path: 'sites/:environment_id',
				children: [
					{
						path: 'agent',
						component: AgentComponent,
					},
				],
			},
			{
				path: 'publish',
				component: SiteSetupComponent,
			},
			{
				path: 'publish/cloud-wordpress',
				component: WizardComponent,
			},
			{
				path: 'add/hosting',
				component: WizardComponent,
				data: {
					isUpdatePlan: false,
					offer: '',
				}
			},
			{
				path: 'reconnected',
				component: DashboardComponent,
			},
			{
				path: 'preview',
				component: PreviewComponent,
			},
			{
				path: 'domain',
				component: DomainComponent,
			},
			{
				path: 'add/playgrounds',
				component: AddonsComponent,
				data: {
					title: 'Add Playgrounds',
				}
			},
			{
				path: 'w3',
				component: W3Component,
			},
			{
				path: 'connect-key',
				component: ConnectKeyComponent,
			},
			{
				path: 'backups',
				component: BackupsComponent,
			},
			{
				path: 'snaps',
				component: SnapListComponent,
			},
			{
				path: 'snaps/save',
				component: SaveSnapComponent,
			},
			{
				path: 'snaps/snaps',
				redirectTo: 'snaps',
			},
			{
				path: 'snaps/install',
				component: InstallSnapComponent,
			},
			{
				path: 'snaps/:snapId',
				component: SnapDetailsComponent,
			},
			{
				path: 'snaps/:snapId/save',
				component: SaveSnapComponent,
			},
			{
				path: 'plugins',
				component: PluginsComponent,
			},
			{
				path: 'themes',
				component: ThemesComponent,
			},
			{
				path: 'health',
				component: HealthComponent,
			},
			{
				path: 'backups',
				component: BackupsComponent,
			},
			{
				path: 'files',
				component: FileManagerComponent,
			},
			{
				path: 'vz-manager',
				component: VzManagerComponent,
			},
			{
				path: 'overview',
				component: OverviewComponent,
			},
			{
				path: 'snapshots',
				component: SnapshotsComponent,
			},
			{
				path: 'nginx-settings',
				component: NginxProfilesComponent,
			},
			{
				path: 'php-settings',
				component: PhpSwitcherComponent,
			},
			{
				path: 'resize-server',
				component: WizardComponent,
				data: {
					isUpdatePlan: true,
					offer: '',
				}
			},
		],
	},
];
