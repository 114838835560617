import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService, ProfileService } from '../../authentication/services';

@Component({
	selector: 'central-beta-tester',
	templateUrl: './beta-tester.component.html',
	styleUrls: ['./beta-tester.component.scss'],
})
export class BetaTesterComponent implements OnInit {
	@Input() accountId = false;
	@Input() isTester = false;

	public state = 'pending';

	constructor(
		private profileService: ProfileService,
		public snackBar: MatSnackBar,
		private authService: AuthService
	) {
		this.isTester = this.profileService.data.tester;
	}

	ngOnInit() {
		if (!this.accountId) {
			this.accountId = this.authService.getAccountId();
			this.isTester = this.profileService.data.options['public_beta_tester'];
		}
	}

	toggleState() {
		this.state = 'submitted';

		this.authService.profile
			.update({
				public_beta_tester: !this.isTester,
			})
			.subscribe({
				next: (response: any) => {
					console.log(response);
					this.isTester = !!response.options['public_beta_tester'];
					this.snackBar.open('Account Updated', 'Dismiss', {
						duration: 4000,
						horizontalPosition: 'center',
					});
					this.state = 'pending';
				},
				error: (error) => {
					this.snackBar.open('Error', 'Dismiss', {
						duration: 4000,
						horizontalPosition: 'center',
					});
					this.state = 'pending';
				},
			});
	}
}
