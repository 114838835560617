import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { ProjectService } from '../../../project.service';
import { PollingService } from '@central/ng-shared';
import { DisableSslDialogueComponent } from './disable-ssl-dialogue.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
	selector: 'central-auto-ssl',
	templateUrl: './auto-ssl.component.html',
	//styleUrls: ['./auto-ssl.component.css'],
})

export class AutoSslComponent implements OnInit {

	public enableSsl = false;

	public playbookRunning = false;

	public playbookError = false;

	constructor(
		public apiService: ApiService,
		public projectService: ProjectService,
		public pollingService: PollingService,
		public dialog: MatDialog,
	) {}

	ngOnInit(): void {
		this.updateFields();
	}

	public setOverrideState() {
		this.playbookError = false;
		this.enableSsl = !this.enableSsl;
		this.handleEnabledSsl();
	}

	public openDialogue() {
		const dialogueRef = this.dialog.open(DisableSslDialogueComponent, {
			width: '500px',
		});
		dialogueRef.afterClosed().subscribe(result => {
			if (result === true) {
				this.playbookError = false;
				this.enableSsl = false;
				this.handleEnabledSsl();
			}
		});
	}

	private handleEnabledSsl() {
		this.playbookRunning = true;
		this.apiService.post('/v1/environments/' + this.projectService.environment.id + '/apply_ssl', {
			enable_ssl: this.enableSsl,
		}).subscribe((res) => {
			this.startPolling();
		});
	}

	private updateFields() {
		const backendSslEnabled = this.projectService.environment.fields.ssl_enabled;
		if (backendSslEnabled === 1 || backendSslEnabled === "1" || backendSslEnabled === true || backendSslEnabled === "true") {
			this.enableSsl = true;
		} else {
			this.enableSsl = false;
		}
		if (this.projectService.environment.fields.playbook_running) {
			this.playbookRunning = this.projectService.environment.fields.playbook_running !== 'complete';
		} else {
			this.playbookRunning = false;
		}
		this.playbookError = this.projectService.environment.fields.playbook_error ?? false;
		// If Playbook is in error state do not indicate SSL is installed
		if (this.playbookError) {
			this.enableSsl = false;
		}
	}

	private startPolling() {
		this.pollingService.startPoll(
			() => this.projectService.reloadProject(),
			() => !this.projectService.environment.fields?.playbook_running || this.projectService.environment.fields.playbook_running === 'complete',
		).subscribe(() => {
			this.updateFields();
			this.playbookRunning = false;
		},(err) => {
			console.log('Polling Error', err);
			this.playbookRunning = false;
			this.updateFields();
			this.playbookError = true;
		});
	}
}
