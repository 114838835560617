<div class="ctw-grid">
	<p class="ctw-text-base ctw-mb-0">
		<span *ngIf="diag[0]?.diagFixMessage">{{
			diag[0].diagFixMessage
			}}</span>
		<span *ngIf="this.state === 'submitted'"><br>{{ diag[0].display[0].diagFixMessage }}</span>
	</p>
	<div *ngIf="this.state !== 'submitted' && diag[0]?.input"
		class="action ctw-mt-4 ctw-mx-auto ctw-max-w-4xl ctw-w-full ctw-text-right" [hidden]="!getSubmitInput()">
		<ng-container *ngFor="let input of diag[0].input">
			<button *ngIf="
					input.type === 'button' &&
					input.action === 'link' &&
					input.label !== 'Update Site URL' &&
					input.label.includes('Register')
				" (click)="this.router.navigate([input.href])" mat-raised-button color="primary" [mat-dialog-close]="true"
				class="ctw-ml-2">
				{{ input.label }}
			</button>
			<button *ngIf="
					input.type === 'button' &&
					input.action === 'link' &&
					input.label !== 'Update Site URL' &&
					!input.label.includes('Register')
				" (click)="this.router.navigate([input.href])" mat-stroked-button color="primary" [mat-dialog-close]="true"
				class="ctw-ml-2">
				{{ input.label }}
			</button>
			<mat-form-field *ngIf="input.type === 'text' && input.action === 'input'" appearance="standard"
				floatLabel="always">
				<mat-label>{{ input.label }}</mat-label>
				<input matInput type="text" />
			</mat-form-field>
			<central-domain-select *ngIf="input.type === 'domain-select'" #centralDomainSelect
				[context]="this.environment.fields.organization_id" [allowManual]="true"
				[hostname]="this.environment.fields.hostname"
				(domainInputChange)="updateDomain($event)"></central-domain-select>

			<central-progress-button *ngIf="input.action === 'submit' && getSubmitInput()" class="ctw-ml-4" mat-button
				mat-raised-button mat-ripple color="primary" (click)="submitFix()" [disabled]="disableFix()"
				[state]="this.state">{{
				this.state === 'submitted'
				? 'Reparing'
				: getSubmitInput().label
				}}</central-progress-button>
		</ng-container>
	</div>
</div>
