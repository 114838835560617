<ng-container *ngIf="data.action === 'remove-addon'">
	<h3>Remove Add-On</h3>
	<div mat-dialog-content>
		<p>
			The add-on will remain available until
			{{ this.getRenewalDate(data.subscription) | amDateFormat : 'LL' }}.
		</p>
	</div>
	<div class="ctw-text-right ctw-mt-4">
		<button mat-button mat-dialog-close>Close</button>
		<central-progress-button
			color="warn"
			[state]="this.status"
			(click)="removeAddon()"
			class="ctw-ml-2"
			>Continue</central-progress-button
		>
	</div>
</ng-container>
<ng-container *ngIf="data.action === 'cancel-auto-renewal'">
	<h3>Disable Auto Renewal</h3>
	<div mat-dialog-content>
		<p>
			The subscription's services and add-ons will remain available until
			{{ this.getRenewalDate(data.subscription) | amDateFormat : 'LL' }}.
		</p>
	</div>
	<div class="ctw-text-right ctw-mt-4">
		<button mat-button mat-dialog-close>Close</button>
		<central-progress-button
			color="warn"
			[state]="this.status"
			(click)="cancelAutoRenewal()"
			class="ctw-ml-2"
			>Continue</central-progress-button
		>
	</div>
</ng-container>
<ng-container *ngIf="data.action === 'enable-auto-renew'">
	<h3>Enable Auto Renewal</h3>
	<div mat-dialog-content>
		<p>
			The
			<a
				routerLink="{{
					this.data.isOrg
						? '/account/' + data.organizationId + '/billing'
						: '/account/billing'
				}}"
				>payment method</a
			>
			on file will be automatically billed going forward.
		</p>
	</div>
	<div class="ctw-text-right ctw-mt-4">
		<button mat-button mat-dialog-close>Close</button>
		<central-progress-button
			[state]="this.status"
			(click)="activateAutoRenewal()"
			class="ctw-ml-2"
			>Enable Auto Renewal</central-progress-button
		>
	</div>
</ng-container>

<div class="error-message" *ngIf="errorMessage">{{ this.errorMessage }}</div>
