import { Injectable } from '@angular/core';
import LogRocket from 'logrocket';
import { AuthService } from '../../authentication/services/auth.service';
import { ConfigurationService } from '../../core/configuration.service';
import { DecoderService } from '../../core/jwt/decoder';
import { RollbarService } from '../rollbar/rollbar.service';
@Injectable()
export class LogrocketService {
	public privateEndpoints = [
		// Passwords.
		'/account/v1/auth/reset-password',
		'/account/v1/auth/update-password',
		'/account/v1/auth/change-password',

		// Billing
		'/payments/method',

		// Login
		'/account/v1/auth/get-token',
		'/v1/accounts/provider-auth',
	];

	constructor(
		public configurationService: ConfigurationService,
		public decoderService: DecoderService,
		public rollbarService: RollbarService,
		public authService: AuthService
	) {
		const config = this.configurationService.config;
		if (!config.logrocket?.appId) {
			console.log('Logrocket not configured');
			return;
		}

		LogRocket.init(config.logrocket.appId, {
			rootHostname: config.logrocket?.rootHostname,
			network: {
				requestSanitizer: (request) => {
					const privateEndpoints = [
						...(config.logrocketPrivateEndpoints || []),
						...this.privateEndpoints,
					];

					// Dont pass request to LogRocket.
					for( const ignoreEndpoint of privateEndpoints ) {
						if( typeof(request.url) === 'string') {
							if ( request.url.includes( ignoreEndpoint ) ) {
								return null;
							}
						}
					}

					request.headers['authorization'] = null;
					return request;
				},
			},
		});

		this.configureLogrocket();
		this.configureRollbar();

		this.authService.authStateChange.subscribe(() => {
			if (this.authService.isLoggedIn()) {
				this.configureLogrocket();
				this.configureRollbar();
			}
		});
	}

	configureLogrocket() {
		const accountId = this.decoderService.getTokenVal('user_id') || '';
		const email = this.decoderService.getTokenVal('email') || '';

		LogRocket.identify(accountId, {
			email: email,
		});
	}

	configureRollbar() {
		this.rollbarService.rollbar.configure({
			transform: (obj) => {
				obj.environmentUrl = location.protocol + '//' + location.host;
				obj.sessionURL = LogRocket.sessionURL;
				obj.person = {
					id: this.decoderService.getTokenVal('user_id') || '',
					email: this.decoderService.getTokenVal('email') || '',
				};
				return obj;
			},
		});
	}
}
