import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'central-project-selector',
	templateUrl: './project-selector.component.html',
	styleUrls: ['./project-selector.component.scss'],
})
export class ProjectSelectorComponent implements OnInit {
	@Input() inputAppearance = 'fill';
	@Output() selectionChanged = new EventEmitter();

	public state = 'pending';
	public projects;
	public resource;

	constructor(public apiService: ApiService) {}

	ngOnInit(): void {
		this.fetchProjects();
	}

	public change(project) {
		this.selectionChanged.emit(project);
	}

	public fetchProjects() {
		this.state = 'loading';

		this.apiService.get('/v1/projects', {}).subscribe({
			next: (projects) => {
				this.projects = projects;
				this.resource = this.getDefault();
				this.state = 'success';
			},
			error: () => {
				this.state = 'failed';
			},
		});
	}

	private getDefault() {
		return this.projects[0] || null;
	}
}
