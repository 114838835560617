import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ConfigurationService } from '../../core/configuration.service';
import { AuthService } from '../services/auth.service';
import { FirebaseAuthComponent } from '../firebase-auth/firebase-auth.component';
import { User } from './user.model';
import { ApiService } from '../../core/api/api.service';
import { TrackingService } from '../../tracking/tracking.service';
import { SharedStorageService } from '../../core/local-storage/shared-storage.service';
import { PublicStorageService } from '../../core/local-storage/public-storage.service';
import { CaptchaService } from './captcha.service';
import { ActivatedRoute } from '@angular/router';
import {
	TwoFactorAuthComponent,
	TwoFaUserData,
} from '../two-factor-auth/two-factor-auth.component';
import { Router } from '@angular/router';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'login-form',
	templateUrl: 'login-form.component.html',
	styleUrls: ['login-form.component.scss'],
})
export class LoginFormComponent implements AfterViewInit, OnInit {
	public features;

	// View children.
	@ViewChild('captcha') public captcha: ElementRef;
	@ViewChild(FirebaseAuthComponent)
	public firebaseAuth: FirebaseAuthComponent;
	@ViewChild('emailField') public emailField: any;

	// Inputs.
	@Input() public disableRedirect = false;
	@Input() public allowSignup = true;
	@Input() public authMethods: string[] = [];
	@Input() public inputAppearence = 'outline';
	@Input() public enableNameInput = false;
	@Input() public autoSignup = false;
	@Input() public isGuide = false;
	@Input() public termsApprove = false;
	@Input() public wasInvited = false;
	@Input() public isModal = false;

	// Events.
	// eslint-disable-next-line
	@Output() public onLoginSuccess = new EventEmitter();
	// eslint-disable-next-line
	@Output() public onPreAuth = new EventEmitter();
	// eslint-disable-next-line
	@Output() public onForgotPassword = new EventEmitter();

	// email flow.
	public emailErrors: any[] = [];
	public captchaRendered = false;
	public invalidPassword: any;
	public errorMessage: string;
	public captchaSuccess: any;
	public temporaryAuthorization: any;

	public twoFaUserData: TwoFaUserData = {
		username: '',
		deviceOptions: [],
		invalidCode: false,
		authData: {},
	};

	// UI States.
	public emailState = 'pending';
	public providerState = 'pending';
	public state = 'pending';
	public twoFaVerifyCodeState = 'sendCode';

	public model = new User('', '', true, true, '');
	public additionalRequestParams: any;
	public recaptchaResponse = '';
	public captchaKey: string;
	public useSocial = '';
	public name = '';
	public confirmationToken: string;
	public isNewProviderAccount = false;
	public refCode = '';
	public headers = {};
	public providerDataTmp;
	public invalidEmailDomain = false;

	// Capctha.
	public captchaRequired = false;

	private accountSetupErrors = [
		'needs_password_auto',
		'needs_password_repeat',
		'email_verification',
	];

	/**
	 * @param trackingService Track events.
	 * @param authService     Authentication service.
	 * @param apiService      API service.
	 * @param ngZone          Run callbacks in zones.
	 */
	constructor(
		public trackingService: TrackingService,
		public configService: ConfigurationService,
		private captchaService: CaptchaService,
		private activatedRoute: ActivatedRoute,
		private publicLocalStorage: PublicStorageService,
		private authService: AuthService,
		private router: Router,
		private apiService: ApiService,
		private sharedStorage: SharedStorageService,
		private ngZone: NgZone
	) {
		this.captchaKey = this.configService.config.recaptcha.publicKey;
	}

	public ngOnInit() {
		this.model.email =
			this.activatedRoute.snapshot.queryParams['email'] || '';
		this.features = this.configService.config.features;
	}

	/**
	 * On component init, setup firebase.
	 *
	 * @since 1.17.0
	 */
	public ngAfterViewInit(): void {
		this.refCode = this.sharedStorage.getItem('ref') || '';
		this.setupFirebaseAuth();
	}

	/**
	 * Did the client create a new account?
	 *
	 * @since 1.17.0
	 *
	 * @return Is this a new account?
	 */
	public isNewUser(): boolean {
		return !!('signup' === this.emailState || this.isNewProviderAccount);
	}

	/**
	 * Submit the form and run the coresponsing actions depending on state.
	 *
	 * Bound to form submission.
	 *
	 * @since 1.17.0
	 */
	public submit(): void {
		if (this.providerDataTmp) {
			this.acceptTerms();
		} else if (this.emailState === 'signup') {
			this.signup();
		} else if (
			this.emailState !== 'passwordEntry' &&
			this.emailState !== 'twoFa' &&
			!this.emailField.valid
		) {
			this.emailField.control.markAsTouched();
			this.emailField.control.markAsDirty();
		} else if (
			this.emailState !== 'passwordEntry' &&
			this.emailState !== 'twoFa' &&
			this.emailField.valid
		) {
			this.checkEmail();
		} else if (
			this.emailState === 'passwordEntry' ||
			this.emailState === 'twoFa'
		) {
			this.login();
		}
	}

	/**
	 * Check if the given auth method is enabled.
	 *
	 * @param method Authentication method.
	 */
	public hasAuthMethod(method: string): boolean {
		return (
			!this.authMethods.length || -1 !== this.authMethods.indexOf(method)
		);
	}

	/**
	 * Reset the state of the component form.
	 *
	 * @since 1.17.0
	 */
	public reset() {
		this.state = 'pending';
		this.providerState = 'pending';
		this.emailState = 'pending';
		this.isNewProviderAccount = false;
		this.firebaseAuth.reset();
	}

	/**
	 * Remove trailing spaces from email input, often added by autofill.
	 *
	 * @since 1.7.0
	 */
	public sanitizeEmail() {
		this.model.email = this.model.email.trim();
	}

	/**
	 * Does a given field have an error.
	 *
	 * @since 1.17.0
	 *
	 * @param  field Angular form field.
	 * @return       SHould show error?
	 */
	public fieldHasError(field: any): boolean {
		return (
			!field.valid &&
			field.dirty &&
			field.touched &&
			this.state !== 'submitted'
		);
	}

	/**
	 * After we check if an email is valid, update the UI.
	 *
	 * @since 1.20.0
	 *
	 * @param  response Response from API.
	 */
	public postEmailCheck(response: any): void {
		// If this is a successful response.
		if (response['message'] === 'Invalid Captcha.') {
			this.waitForCapctha(() => this.checkEmail());
		} else if (Array.isArray(response) && !response.length) {
			this.state = 'pending';
			this.emailState = 'passwordEntry';

			// Handle known errors.
		} else if (response.errors && response.errors.length) {
			this.emailErrors = response;
			// Account has no password or social auth.
			const error = this.getError(
				response.errors,
				this.accountSetupErrors
			);
			if (error) {
				this.state = 'pending';
				this.emailState = 'account-setup';
				this.confirmationToken = error['token'];
				this.onPreAuth.emit(true);
			}
			if (this.getError(response.errors, ['invalid_email_domain'])) {
				this.state = 'pending';
				this.invalidEmailDomain = true;
				this.model.email += ' ';
			}

			if (this.getError(response.errors, ['temporary_authorization'])) {
				this.state = 'pending';
				this.temporaryAuthorization = true;
				this.emailState = 'passwordEntry';
			}

			// User account not found, request signup.
			if (this.getError(response.errors, ['account_not_found'])) {
				this.emailState = 'signup';
				this.onPreAuth.emit(true);

				if (this.autoSignup && !this.termsApprove) {
					this.emailState = 'accept-terms-email';
					this.state = 'pending';
					return;
				} else if (this.autoSignup) {
					this.signup();
					return;
				} else {
					this.state = 'pending';
				}
			}

			// User must sign in with social.
			const socialError = this.getError(response.errors, [
				'provider_required_twitter',
				'provider_required_facebook',
				'provider_required_google',
			]);

			if (socialError) {
				const errorName = socialError['name'].replace(
					'provider_required_',
					''
				);
				this.state = 'pending';
				this.useSocial = errorName;
			}

			if ('submitted' === this.state) {
				this.state = 'failed';
			}
		}
	}

	/**
	 * Handle twoFa validate code submission
	 *
	 * @since 3.49.0
	 *
	 * @param twoFaData
	 */
	public handletwoFaSubmit(twoFaFormData) {
		this.model.rememberMetwoFa = twoFaFormData.rememberMetwoFa;
		this.model.twoFaCode = twoFaFormData.twoFaCode;
		this.login();
	}

	/**
	 * Get any errors by name.
	 *
	 * @since 1.17.0
	 *
	 * @param  errors List of errors.
	 * @param  names  list of names.
	 * @return        All matched values.
	 */
	private getError(errors: any[], names: any[]): any[] {
		return errors.find((val: object) => -1 !== names.indexOf(val['name']));
	}

	/**
	 * Track when someone signup.
	 *
	 * @since 1.1
	 *
	 * @param  data Data to pass into tracking.
	 */
	private trackSignup(method: string): void {
		this.trackingService.track('signup', {
			page: 'Login',
			method,
		});

		this.authService.profile
			.update({
				public_signup_url: this.router.url.split('?')[0],
			})
			.subscribe();
	}

	/**
	 * Check that the email exsist.
	 *
	 * @since 1.17.0
	 */
	private checkEmail(): void {
		this.state = 'submitted';
		this.apiService.http
			.get(`https://api-wpc.inmotionhosting.com/v1/domain-service/domain/info/${this.model.email}`, {})
			.subscribe(
				(emailDomain: any) => {
					if (emailDomain.isIcann) {
						this.apiService
							.get('authEmail', {
								email: this.model.email,
								'g-recaptcha-response': this.recaptchaResponse,
							})
							.subscribe(
								(response) => this.postEmailCheck(response),
								(response) => this.postEmailCheck(response),
								() => {
									// After completion, if errors or success isnt handled, show error.
									if ('submitted' === this.state) {
										this.state = 'failed';
									}
								}
							);
					} else {
						this.postEmailCheck({ errors: [{ name: 'invalid_email_domain' }]})
					}
				},
				() => {
					// After completion, if errors or success isnt handled, show error.
					if ('submitted' === this.state) {
						this.state = 'failed';
					}
				}
			);
	}

	/**
	 * When the user makes a request that requires a capctha to be submitted,
	 * prompt them.
	 *
	 * @since 1.17.0
	 *
	 * @param  finishCb Callback for success.
	 */
	private waitForCapctha(finishCb: any): void {
		this.state = 'recaptcha';
		if (
			window['grecaptcha'] &&
			window['grecaptcha'].reset &&
			this.captchaRendered
		) {
			try {
				window['grecaptcha'].reset();
			} catch (e) {
				console.log(e);
			}
		}

		this.captchaService.load().subscribe(() => {
			this.captchaRendered = true;
			window['grecaptcha'].render(this.captcha.nativeElement, {
				theme: 'dark',
				sitekey: this.captchaKey,
				callback: (code) => {
					this.recaptchaResponse = code;
					this.state = 'submitted';
					finishCb();
				},
			});
		});
	}

	/**
	 * Update State to show 2fa component
	 *
	 * @since 3.48.13
	 */
	private waitFortwoFa(data = [], authData = null) {
		this.twoFaUserData = {
			username:
				this.providerState === 'complete' ? null : this.model.email,
			invalidCode: this.headers['multifactor'] ? true : false,
			deviceOptions: data,
			authData: this.providerState === 'complete' ? authData : null,
		};

		this.state = 'pending';
		this.emailState = 'twoFa';
	}
	private totalDevices() {
		return this.twoFaUserData.deviceOptions.length;
	}
	/**
	 * Process to run when the user fails their login.
	 *
	 * @since 1.0.0
	 *
	 * @param  data Login Response.
	 */
	private failedLogin(data?: object): void {
		data = data || {};

		if (false === data['success'] && false === data['token']) {
			this.invalidPassword = this.model.password;
			this.state = 'pending';
		} else {
			this.state = 'failed';
		}
	}

	/**
	 * Signup for a new account with your email address.
	 *
	 * @since 1.17.0
	 */
	private signup() {
		const email = this.model.email.trim();
		const name = this.name.trim();
		const visitorId = this.publicLocalStorage.getItem('visitorId');

		this.state = 'submitted';
		this.apiService
			.post('createAccount', {
				name,
				email,
				send_email: 1,
				state: 0,
				'g-recaptcha-response': this.recaptchaResponse,
				visitorId,
				ref: this.refCode,
				wasInvited: this.wasInvited,
			})
			.subscribe(
				(response) => {
					if (response['message'] === 'Invalid Captcha.') {
						this.waitForCapctha(() => this.signup());
					} else if (response['token']) {
						this.authService.setLogin(
							response['token'],
							this.disableRedirect
						);
						this.trackSignup('email');
						this.onLoginSuccess.emit();
					} else {
						this.state = 'failed';
					}
				},
				() => (this.state = 'failed')
			);
	}

	/**
	 * Login to the user account with password and email.
	 *
	 * @since 1.17.0
	 */
	private login(): void {
		const email = this.model.email.trim();
		const password = this.model.password;
		const rememberMe = this.model.rememberMe;
		const prevTwoFaState = this.twoFaVerifyCodeState;

		this.state = 'submitted';
		this.twoFaVerifyCodeState = 'submitted';

		this.invalidPassword = false;

		const request = {
			username: email,
			password,
			'remember-me': rememberMe,
			send_setup_email: true,
			'g-recaptcha-response': this.recaptchaResponse,
			headerOptions: this.headers,
		};

		this.apiService.post('getToken', request).subscribe(
			(response) => {
				const data =
					response['result'] && response['result']['data']
						? response['result']['data']
						: {};

				this.twoFaVerifyCodeState = prevTwoFaState;

				// User must complete captcha.
				if (response['message'] === 'Invalid Captcha.') {
					this.waitForCapctha(() => this.login());

					// Failed authentication..
				} else if (!data['success'] || !data.token) {
					if (data['link']) {
						// Navigate to the link provided.
						this.router.navigate([data['link']]);
					}
					this.failedLogin(data);
				} else {
					this.trackingService.googleAnalytics.gaSend({
						event_action: 'login',
					});
					this.authService.setLogin(data.token, this.disableRedirect);
					this.onLoginSuccess.emit();
				}
			},
			(error) => {
				this.errorMessage = '';
				if (
					error.status === 401 &&
					error.message === 'Two-factor required.'
				) {
					this.twoFaVerifyCodeState = prevTwoFaState;
					this.waitFortwoFa(error.errors);
				} else {
					this.errorMessage = this.apiService.getPublicError(error);
					this.failedLogin();
				}
			}
		);
	}

	/**
	 * Authenticate a user with a provider account.
	 *
	 * @since 1.17.0
	 *
	 * @param  provider Provider name.
	 * @param  authData Authentication Data.
	 */
	private authenticateProviderAccount(
		provider: string,
		authData: object
	): void {
		const user = authData['user'];
		delete authData['user'];
		const request = {
			provider,
			authData: JSON.stringify(authData),
			ref: this.refCode,
			headerOptions: this.headers,
		};

		if (this.emailState === 'alt-email') {
			request['altEmail'] = this.model.email;
		}

		this.state = 'submitted';
		this.providerState = 'complete';

		this.apiService.post('providerAuth', request).subscribe(
			(response) => {
				this.authService.setLogin(
					response['token'],
					this.disableRedirect
				);
				this.isNewProviderAccount = !!response['is_new_user'];
				this.onLoginSuccess.emit({
					request,
					response,
					user
				});

				if (this.isNewProviderAccount) {
					delete this.providerDataTmp;
					this.trackSignup(
						authData['provider'].providerId.replace('.com', '')
					);
				} else {
					this.trackingService.googleAnalytics.gaSend({
						event_action: 'login',
					});
				}
			},
			(err) => {
				let state = 'failed';
				if ('account_not_found' === err.errors?.[0].name) {
					state = 'ready';
					this.model = new User('', '', true, true, '');
					this.providerDataTmp = {
						token: authData['token'],
						provider: authData['provider'],
					};
					this.termsApprove = false;
					this.emailState = 'accept-terms';
					this.providerState = 'pending';
					this.onPreAuth.emit(true);
				}
				if ('primary_email_invalid' === err.errors?.[0].name) {
					state = 'ready';
					this.model = new User('', '', true, true, '');
					this.emailState = 'alt-email';
					this.providerState = 'pending';
					this.onPreAuth.emit(true);
				}
				if ('verify_alt_email' === err.errors?.[0].name) {
					state = 'pending';
					this.emailState = 'account-setup';
					this.confirmationToken = authData['token'];
					this.onPreAuth.emit(true);
				}
				this.state = state;
				this.errorMessage = this.apiService.getPublicError(err);
				if (err.status === 401) {
					state = 'pending';
					this.waitFortwoFa(err.errors, {
						providerName: provider,
						...authData,
					});
					this.state = 'pending';
					this.emailState = 'twoFa';
				}
			}
		);
	}
	/**
	 *
	 * Finalize firebase registration
	 *
	 */
	public acceptTerms() {
		if (this.providerDataTmp && this.termsApprove) {
			this.ngZone.run(() =>
				this.authenticateProviderAccount('firebase', {
					token: this.providerDataTmp.token,
					provider: this.providerDataTmp.provider,
					create: true,
				})
			);
		}
	}
	public setTerms(value, accept = false) {
		this.termsApprove = value;
		if (accept) {
			this.acceptTerms()
		}
	}
	/**
	 * When firebase says someone has been authenticated, call our API server to create them an account.
	 *
	 * @since 1.17.0
	 */
	private setupFirebaseAuth(): void {
		this.firebaseAuth.onAuth.subscribe((authData: any) => {
			if (authData.authResult.credential.providerId === 'github.com') {
				console.log(authData);
			}
			authData.authResult.user.getIdToken().then((token: any) => {
				this.ngZone.run(() =>
					this.authenticateProviderAccount('firebase', {
						token,
						provider: authData.authResult.credential,
						create: this.termsApprove,
						user: authData.authResult.user
					})
				);
			});
		});
	}

	public setHeaders(headers = {}) {
		this.twoFaVerifyCodeState = 'validateCode';

		this.headers = headers;
		if (this.providerState === 'complete') {
			this.ngZone.run(() =>
				this.authenticateProviderAccount(
					this.twoFaUserData.authData.providerName,
					{
						token: this.twoFaUserData.authData.token,
						provider: this.twoFaUserData.authData.provider,
						create: true,
					}
				)
			);
		} else {
			this.login();
		}
	}

	public getTwoFaState() {
		return this.twoFaVerifyCodeState;
	}

	public cancel() {
		this.providerState = 'pending';
		this.emailState = 'pending';
		this.firebaseAuth.reset();
		this.onPreAuth.emit(false);
	}
}
