import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from './notification.service';
import { NotificationsComponent } from './notifications.component';
import { MatIconModule } from '@angular/material/icon';
import { MomentModule } from 'ngx-moment';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
	declarations: [NotificationsComponent],
	imports: [
		CommonModule,
		MomentModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatIconModule,
	],
	providers: [NotificationService],
})
export class NotificationsModule {}
