<mat-progress-bar
	class="loader"
	mode="indeterminate"
	*ngIf="status === 'start'"
></mat-progress-bar>
<central-failed-request
	*ngIf="'failed' === this.status"
></central-failed-request>

<div
	class="tab-wrap"
	*ngIf="status === 'success'"
	[class]="
		this.configService.config.brandConfig.id === 'imh'
			? 'wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8 ctw-mb-8'
			: ''
	"
>
	<span class="open-request">
		<button
			mat-raised-button
			*ngIf="this.configService.config?.hasSupportAgentKey"
			(click)="this.openAgentAccessDialog()"
		>
			Support Agent Access
		</button>
		<button mat-raised-button class="support-access" (click)="openDialog()">
			{{
				this.configService.config.brandConfig.id === 'imh'
					? 'Open a Ticket'
					: 'Open Request'
			}}
		</button>
		<button
			mat-raised-button
			class="support-access"
			[disabled]="this.openingChat"
			*ngIf="this.configService.config?.zendesk?.chatIsEnabled"
			(click)="this.openChat()"
			[class.mat-primary]="
				this.configService.config.brandConfig.id === 'imh'
			"
		>
			Live Chat
		</button>
	</span>
	<mat-tab-group
		animationDuration="0ms"
		[(selectedIndex)]="selectedTabIndex"
		class="{{ this.configService.config.brandConfig.id }}"
		color="accent"
	>
		<mat-tab *ngIf="contextTabTickets.label" color="accent">
			<ng-template matTabLabel>
				<span
					matBadge="{{ contextTabTickets.openTickets }}"
					matBadgeSize="medium"
					color="accent"
					>{{ contextTabTickets.label }}
				</span>
			</ng-template>

			<ng-container *ngIf="contextTabTickets.tickets.length < 1">
				<ng-container
					*ngTemplateOutlet="
						noRequestsFound;
						context: { ownerLabel: contextTabTickets.label }
					"
				></ng-container>
			</ng-container>

			<ng-container *ngIf="contextTabTickets.tickets.length > 0">
				<ng-container
					*ngTemplateOutlet="
						listTickets;
						context: { tickets: contextTabTickets.tickets }
					"
				></ng-container>
			</ng-container>
		</mat-tab>
		<mat-tab color="accent">
			<ng-template matTabLabel color="accent">
				<span
					*ngIf="this.configService.config.brandConfig.id === 'imh'"
					matBadge="{{ accountTickets.openTickets }}"
					matBadgeSize="medium"
					color="accent"
				>
					{{ accountTickets.openTickets < 1 ? 'No' : '' }} Open
					Tickets
				</span>
				<span
					*ngIf="this.configService.config.brandConfig.id !== 'imh'"
					matBadge="{{ accountTickets.openTickets }}"
					matBadgeSize="medium"
					color="accent"
				>
					{{ accountTickets.label }}
				</span>
			</ng-template>
			<ng-container *ngIf="accountTickets.tickets.length < 1">
				<ng-container
					*ngTemplateOutlet="
						noRequestsFound;
						context: { ownerLabel: accountTickets.label }
					"
				></ng-container>
			</ng-container>

			<ng-container *ngIf="accountTickets.tickets.length > 0">
				<ng-container
					*ngTemplateOutlet="
						listTickets;
						context: { tickets: accountTickets.tickets }
					"
				></ng-container>
			</ng-container>
		</mat-tab>
	</mat-tab-group>
</div>

<ng-template #listTickets let-tickets="tickets">
	<table mat-table [dataSource]="tickets">
		<ng-container matColumnDef="subject">
			<th mat-header-cell *matHeaderCellDef>Subject</th>
			<td mat-cell *matCellDef="let element">
				<a routerLink="../requests/{{ element.id }}">
					<mat-icon
						class="error emergency-ticket"
						*ngIf="element.priority !== 'normal'"
						matTooltip="Emergency Request"
						>warning</mat-icon
					>
					{{ element.subject }}</a
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="created_at">
			<th class="justify-right" mat-header-cell *matHeaderCellDef>
				{{
					this.configService.config.brandConfig.id === 'imh'
						? 'Created'
						: 'Created At'
				}}
			</th>
			<td class="justify-right" mat-cell *matCellDef="let element">
				{{ element.created_at | amTimeAgo }}
			</td>
		</ng-container>

		<ng-container matColumnDef="updated_at">
			<th class="justify-right" mat-header-cell *matHeaderCellDef>
				{{
					this.configService.config.brandConfig.id === 'imh'
						? 'Updated'
						: 'Last Activity'
				}}
			</th>
			<td class="justify-right" mat-cell *matCellDef="let element">
				{{ element.updated_at | amTimeAgo }}
			</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th class="justify-right" mat-header-cell *matHeaderCellDef>
				Status
			</th>
			<td class="justify-right" mat-cell *matCellDef="let element">
				<span class="ticket-status {{ element.status }}">{{
					element.status
				}}</span>
			</td>
		</ng-container>

		<tr
			mat-header-row
			*matHeaderRowDef="['subject', 'created_at', 'updated_at', 'status']"
		></tr>
		<tr
			mat-row
			*matRowDef="
				let row;
				columns: ['subject', 'created_at', 'updated_at', 'status']
			"
		></tr>
	</table>
</ng-template>

<ng-template #noRequestsFound let-ownerLabel="ownerLabel">
	<div
		*ngIf="this.configService.config.brandConfig.id === 'imh'"
		class="no-ticket ctw-max-w-lg ctw-mx-auto ctw-text-center"
	>
		<img
			class="ctw-mx-auto svg"
			src="https://inmotionhosting.github.io/static-assets/illustrations/small/cx-service-support.svg"
		/>
		<h3>{{ ownerLabel }}'s Tickets</h3>
		<p>
			Any ticket you open for <strong>{{ ownerLabel }}</strong> will
			appear here.
		</p>
	</div>
	<div
		*ngIf="this.configService.config.brandConfig.id !== 'imh'"
		class="no-ticket ctw-max-w-lg ctw-mx-auto ctw-text-center"
	>
		<img
			class="ctw-mx-auto svg"
			src="https://inmotionhosting.github.io/static-assets/illustrations/small/cx-service-support.svg"
		/>
		<h3>No Requests Found</h3>
		<p>
			Any support requests that you create will appear here. You're
			currently viewing the requests for <strong>{{ ownerLabel }}</strong
			>.
		</p>
	</div>
</ng-template>
