import crioPremium from '../products/crio/premium';
import premium from './premium';

const features: any[] = [...premium.features, ...crioPremium];

export default {
	label: 'Crio + Premium Connect Key',
	productKeys: ['wpc_crio', 'wpc_premium'],
	licenseNames: ['crio', 'boldgrid-premium'],
	name: 'crio-premium',
	tags: ['crio', 'key', 'premium'],
	color: 'primary',
	features,
};
