import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	AuthenticationModule,
	CoreModule,
	OrganizationModule,
	StepperModule,
} from '@central/ng-shared';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { GuideRoutingModule } from './guide-routing-module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SignupComponent } from './project-create/signup/signup.component';
import { PurchaseComponent } from './project-create/purchase/purchase.component';
import { CheckoutModule } from '../checkout/checkout.module';
import { SizeComponent } from './project-create/size/size.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ProjectModule } from '../project/project.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { DomainModule } from '../domain/domain.module';
import { ProjectService } from '../project/project.service';

@NgModule({
	providers: [
		ProjectService
	],
	declarations: [
		ProjectCreateComponent,
		SignupComponent,
		PurchaseComponent,
		SizeComponent,
	],
	imports: [
		CoreModule,
		StepperModule,
		ProjectModule,
		ReactiveFormsModule,
		FormsModule,
		CheckoutModule,
		AuthenticationModule,
		GuideRoutingModule,
		MatFormFieldModule,
		MatProgressBarModule,
		OrganizationModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatDialogModule,
		MatCardModule,
		MatIconModule,
		MatButtonModule,
		CommonModule,
		DomainModule,
		MatDividerModule,
	],
})
export class GuideModule {}
