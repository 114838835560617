import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatBadgeModule } from '@angular/material/badge';

import { AuthenticationModule } from '@central/ng-shared';
import { CheckoutModule } from '../checkout/checkout.module';
import { SharedModule } from '../shared/shared.module';

import { DomainComponent } from './domain.component';
import { DomainRegisterComponent } from './domain-register/domain-register.component';
import { DomainDetailsComponent } from './domain-details/domain-details.component';
import { DomainChangeConfirmationComponent } from './domain-change/domain-change-confirmation.component';
import { DnsManagerDeleteConfirmationDialogComponent } from './dns-manager/dns-manager-delete-confirmation-dialog.component';
import { DomainAddComponent } from './domain-add/domain-add.component';
import { ContactSetComponent } from './contact-set/contact-set.component';
import { ContactSetEditComponent } from './contact-set-edit/contact-set-edit.component';
import { DnsManagerComponent } from './dns-manager/dns-manager.component';
import { DomainRegisterSharedComponent } from './domain-register-shared/domain-register-shared.component';
import { DomainAddSharedComponent } from './domain-add-shared/domain-add-shared.component';
import { DomainThirdPartyAddDialogComponent } from './domain-third-party-add-dialog/domain-third-party-add-dialog.component';
import { DomainThirdPartyDeleteDialogComponent } from './domain-third-party-delete-dialog/domain-third-party-delete-dialog.component';
import { DomainSelectComponent } from './domain-select/domain-select.component';
import { SubdomainComponent } from './subdomain/subdomain.component';
import { DomainChangeComponent } from './domain-change/domain-change.component';
import { DomainValidator } from './domain-add-shared/domain.validator';

@NgModule({
	imports: [
		AuthenticationModule,
		CommonModule,
		FormsModule,
		HttpClientModule,
		RouterModule,
		CheckoutModule,
		SharedModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
		MatTableModule,
		MatTabsModule,
        MatBadgeModule,
	],
	declarations: [
		DomainComponent,
		DomainRegisterComponent,
		DomainDetailsComponent,
		DomainAddComponent,
		ContactSetComponent,
		ContactSetEditComponent,
		DnsManagerComponent,
		DomainRegisterSharedComponent,
		DomainAddSharedComponent,
		DomainThirdPartyAddDialogComponent,
        DomainThirdPartyDeleteDialogComponent,
		DomainSelectComponent,
		DomainChangeConfirmationComponent,
		DnsManagerDeleteConfirmationDialogComponent,
		SubdomainComponent,
		DomainChangeComponent,
	],
	exports: [
		DomainChangeComponent,
		DomainSelectComponent,
		DomainRegisterSharedComponent,
		DomainAddSharedComponent,
		SubdomainComponent,
	],
	providers: [DomainValidator],
})
export class DomainModule {}
