<layout-base
	[pageTitle]="this.getPageTitle()"
	container="container-full"
	[isLoading]="this.status === 'pending'"
>
	<div class="create-snap-wizard" *ngIf="this.status === 'success'">
		<mat-horizontal-stepper
			linear="true"
			#stepper
			[hidden]="view !== 'wizard'"
		>
			<mat-step>
				<ng-template matStepLabel>Choose Your Theme</ng-template>
				<theme-selector
					[selectedThemes]="this.savedSnapData.themes"
					(selectThemes)="stepper.next()"
					#themeSelector
				></theme-selector>
			</mat-step>
			<mat-step>
				<ng-template matStepLabel>Choose Plugins</ng-template>
				<plugin-selector
					[selectedItems]="this.savedSnapData.plugins"
					(back)="stepper.previous()"
					(selectPlugins)="stepper.next()"
					#pluginSelector
				></plugin-selector>
			</mat-step>
			<mat-step>
				<ng-template matStepLabel>Save As</ng-template>
				<bgc-snap-apply
					[themes]="themeSelector.selectedThemes"
					[plugins]="pluginSelector.selectedItems"
					[snap]="this.snap"
					(back)="stepper.previous()"
					(saved)="this.snap = $event; this.view = 'snap'"
				></bgc-snap-apply>
			</mat-step>
		</mat-horizontal-stepper>
		<div *ngIf="view === 'snap'">
			<h2 class="ctw-text-center title">We've saved your Snap!</h2>
			<bgc-snap-view
				[snap]="snap"
				[currentEnv]="currentEnvironment ? 1 : 0"
			></bgc-snap-view>
		</div>
	</div>
	<wp-content-ui
		hidden
		#existingSnap
		*ngIf="this.loadExistingSnap"
		(loaded)="this.applyExistingConfig($event)"
		[snap]="this.snap"
	></wp-content-ui>
</layout-base>
