import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../project.service';
import { Observable, concat } from 'rxjs';

@Component({
	selector: 'bgc-w3',
	templateUrl: './w3.component.html',
	styleUrls: ['./w3.component.scss'],
})
export class W3Component implements OnInit {
	public state = 'loading';
	public plugins = [];
	public hasPlugin = false;
	public pluginName = 'w3-total-cache.php';
	public enableState = 'pending';
	public deleteState = 'pending';
	public pageCachingEnabled = false;

	constructor(public projectService: ProjectService) {}

	ngOnInit() {
		this.getPlaybookStatus();
		this.projectService.connected.subscribe((msg: any) => {
			if (
				msg === 'ready' &&
				this.projectService.client &&
				this.state !== 'success' &&
				this.state !== 'blocked'
			) {
				this.fetchStatus();
			}

			if (msg !== 'ready') {
				this.state = 'blocked';
			}
		});
	}

	/**
	 * Gets status of project's playbook runs
	 */
	getPlaybookStatus() {
		this.projectService.playbookStatus.subscribe((msg: any) => {
			this.state = msg !== 'playbook-not-running' ? 'blocked' : this.state;
			if (
				this.state === 'blocked' &&
				(msg === 'playbook-not-running' || msg === 'complete')
			) {
				this.state = 'loading';
				this.fetchStatus();
			}
		});
	}

	/**
	 * Get the status of the page caching setting.
	 */
	public fetchStatus(): void {
		concat(this.fetchPluginStatus(), this.getSetting()).subscribe(
			() => {},
			() => (this.state = 'failed'),
			() => (this.state = 'success')
		);
	}

	/**
	 * Enable the page caching setting.
	 */
	public enablePageCaching(): void {
		this.enableState = 'submitted';

		concat(this.activatePlugin()).subscribe(
			() => {},
			() => (this.enableState = 'failed'),
			() => (this.enableState = 'success')
		);
	}

	/**
	 * Delete the plugin.
	 */
	public disableFeature() {
		this.deleteState = 'submitted';

		let pluginFile = '';
		this.plugins.map((val) => {
			if (val['File'].endsWith(this.pluginName) && val['IsActive']) {
				pluginFile = val['File'];
			}
		});

		this.projectService.client
			.namespace('bgc/v1')
			.plugins()
			.update({ active: false, files: [pluginFile] })
			.then(() => this.projectService.client
					.namespace('bgc/v1')
					.plugins()
					.delete({ files: [pluginFile] }))
			.then(() => {
				this.pageCachingEnabled = false;
				this.hasPlugin = false;
				this.deleteState = 'success';
			})
			.catch((error) => {
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.projectService.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								this.disableFeature();
							},
							() => {
								this.projectService.connected.next('failed');
								this.deleteState = 'pending';
								this.state = 'failed';
							}
						);
				} else {
					this.projectService.connected.next('failed');
					this.deleteState = 'pending';
					this.state = 'failed';
				}
			});
	}

	/**
	 * Active the plugin.
	 */
	private activatePlugin() {
		return new Observable((observer) => {
			if (this.hasPlugin) {
				observer.next();
				observer.complete();
				return;
			}
			console.log('this.hasPlugin');
			this.projectService.client
				.namespace('bgc/v1')
				.plugins()
				.create({
					activate: true,
					plugins: [this.pluginName.replace('.php', '')],
				})
				.then((plugins) => {
					this.plugins = plugins;
					this.hasPlugin = this.checkPlugin(plugins);
					observer.next();
					observer.complete();
				})
				.catch((error) => {
					if ('restx_logged_out' === error.code) {
						// Re-authenticating.
						this.projectService.wpRestService
							.reloadClient(this.projectService.environment)
							.subscribe(
								(client) => {
									this.projectService.client = client;
									this.activatePlugin();
								},
								() => {
									this.projectService.connected.next(
										'failed'
									);
									observer.error();
								}
							);
					} else {
						this.projectService.connected.next('failed');
						observer.error();
					}
				});
		});
	}

	/**
	 * Get the status of all plugins on this site.
	 */
	private fetchPluginStatus() {
		return new Observable((observer) => {
			this.projectService.client
				.namespace('bgc/v1')
				.plugins()
				.get()
				.then((plugins) => {
					this.plugins = plugins;
					this.hasPlugin = this.checkPlugin(plugins);
					observer.next();
					observer.complete();
				})
				.catch((error) => {
					if ('restx_logged_out' === error.code) {
						// Re-authenticating.
						this.projectService.wpRestService
							.reloadClient(this.projectService.environment)
							.subscribe(
								(client) => {
									this.projectService.client = client;
									this.fetchPluginStatus();
								},
								() => {
									this.projectService.connected.next(
										'failed'
									);
									observer.error();
								}
							);
					} else {
						this.projectService.connected.next('failed');
						observer.error();
					}
				});
		});
	}

	/**
	 * Get the status of all plugins on this site.
	 */
	private getSetting() {
		return new Observable((observer) => {
			if (!this.hasPlugin) {
				observer.next();
				observer.complete();
				return;
			}

			this.projectService.client
				.namespace('bgc/v1')
				.w3cache()
				.get()
				.then((settings) => {
					this.pageCachingEnabled = !!settings['pgcache.enabled'];

					observer.next();
					observer.complete();
				})
				.catch((error) => {
					if ('restx_logged_out' === error.code) {
						// Re-authenticating.
						this.projectService.wpRestService
							.reloadClient(this.projectService.environment)
							.subscribe(
								(client) => {
									this.projectService.client = client;
									this.getSetting();
								},
								() => {
									this.projectService.connected.next(
										'failed'
									);
									observer.error();
								}
							);
					} else {
						this.projectService.connected.next('failed');
						observer.error();
					}
				});
		});
	}

	/**
	 * Check if the current plugin is active.
	 *
	 * @param plugins Is this plugin active.
	 */
	private checkPlugin(plugins: any[]): boolean {
		let hasPlugin = false;
		plugins.map((val) => {
			if (val['File'].endsWith(this.pluginName) && val['IsActive']) {
				hasPlugin = true;
			}
		});

		return hasPlugin;
	}
}
