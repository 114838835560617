export class PostAffiliatePro {
	/**
	 * Track a purchase made.
	 *
	 * @since 1.3
	 */
	public purchase(data: any) {
		if (!window['PostAffTracker']) {
			console.log('Unable to track affiliate. Error: Script not loaded.');
			return;
		}

		const sale = window['PostAffTracker'].createSale();
		const items = data.cart.getPayload();

		sale.setTotalCost(+data.response.total);
		sale.setOrderID(data.response.orderId);
		sale.setProductID(
			items.map((product: any) => product.product_term_id).join(',')
		);

		window['PostAffTracker'].register();
	}
}
