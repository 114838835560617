<div class="ctw-flex ctw-mb-4">
    <mat-icon *ngIf="this.data.icon" class="ctw-mr-4 ctw-mb-5 contextual-help-card-icon">{{
        this.data.icon
        }}</mat-icon>
    <h3 mat-dialog-title class="ctw-font-bold ctw-mb-auto">
        {{ this.data.title }}
    </h3>
</div>
<div mat-dialog-content class="ctw-flex ctw-flex-col">
    <div *ngIf="this.data.before_video" [innerHTML]="this.data.before_video"></div>
    <div *ngIf="this.data.youtube" class="ctw-aspect-w-16 ctw-aspect-h-9 ctw-my-4">
        <iframe [src]="this.sanitizeUrl(this.data.youtube)" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>
    <div *ngIf="this.data.after_video" class="ctw-mb-4" [innerHTML]="this.data.after_video"></div>
</div>
<div mat-dialog-actions class="ctw-justify-end ctw-text-right dialog-footer action-buttons">
    <button mat-raised-button color="primary" mat-dialog-close>OK</button>
</div>