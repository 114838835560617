<mat-progress-bar
	*ngIf="status === 'pending'"
	mode="indeterminate"
	color="primary"
></mat-progress-bar>
<layout-base
	[isLoading]="status === 'pending'"
	pageTitle="My Purchases"
	[failedLoading]="status === 'fail'"
>
	<div
		class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8 ctw-mb-8"
	>
		<mat-card
			*ngIf="
				status === 'success' &&
				receipts?.length === 0 &&
				invoices?.length === 0
			"
			class="no-messages ctw-text-center card-medium success card-back"
		>
			<div class="card-back-top" [routerLink]="['/']">
				<span class="back-link">Back</span>
			</div>
			<mat-icon>receipt</mat-icon>
			<h4>No Purchases Yet</h4>
			<p>All your purchases will appear here.</p>
		</mat-card>
		<mat-card
			*ngIf="
				status === 'success' &&
				(receipts?.length !== 0 || invoices?.length !== 0)
			"
			class="success card-medium"
		>
			<table class="bordered bg-table">
				<thead>
					<tr>
						<th class="ctw-text-left">Order Number</th>
						<th class="ctw-text-left">Total</th>
						<th class="ctw-text-left">Order Placed</th>
						<th>View Order</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let invoice of invoices">
						<td>
							<a
								[routerLink]="[
									singleLink,
									invoice['invoice_number']
								]"
								>{{ invoice.invoice_number }}</a
							>
						</td>
						<td>
							<ng-container>$</ng-container
							>{{ this.moneyFormat(invoice.total_in_cents) }}
						</td>
						<td>{{ invoice.created_at | amDateFormat : 'LL' }}</td>
						<td class="more">
							<a
								[routerLink]="[
									singleLink,
									invoice['invoice_number']
								]"
								><mat-icon>more_horiz</mat-icon></a
							>
						</td>
					</tr>
					<tr *ngFor="let receipt of receipts">
						<td>
							<a
								[routerLink]="[
									singleLink,
									receipt['order_number']
								]"
								>{{ receipt.order_number }}</a
							>
						</td>
						<td>
							<ng-container>$</ng-container>{{ receipt.total }}
						</td>
						<td>{{ receipt.order_date | amDateFormat : 'LL' }}</td>
						<td class="more">
							<a
								[routerLink]="[
									singleLink,
									receipt['order_number']
								]"
								><mat-icon>more_horiz</mat-icon></a
							>
						</td>
					</tr>
				</tbody>
			</table>
		</mat-card>
	</div>
</layout-base>
