import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { ApiService } from '../../../../core/api/api.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
	selector: 'central-remove-device',
	templateUrl: './remove-device.component.html',
	styleUrls: ['./remove-device.component.scss'],
})
export class RemoveDeviceComponent {
	public state = 'pending';

	constructor(
		public httpClient: HttpClient,
		public apiService: ApiService,
		public dialogRef: MatDialogRef<RemoveDeviceComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	public deleteDevice() {
		this.state = 'submitted';

		const url = this.apiService.formatter.getUrl(
			`/v1/twofactor/device/${this.data.device.name}`
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.httpClient.delete(url, { headers }).subscribe({
			next: () => {
				this.state = 'success';
				this.dialogRef.close();
			},
			error: () => {
				this.state = 'failed';
			},
		});
	}
}
