import { Component, Inject, Output, EventEmitter } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'app-restoration-confirm',
	templateUrl: './restoration-confirm.component.html',
	styleUrls: ['./restoration-confirm.component.scss'],
})
export class RestorationConfirmComponent {
	@Output() public restorationConfirmed = new EventEmitter();
	public state = 'pending';

	public constructor(
		public matDialogRef: MatDialogRef<RestorationConfirmComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
}
