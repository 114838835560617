import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	ApiService,
	AuthService,
	RegistrationComponent,
	TitleService,
} from '@central/ng-shared';
import { ProjectCreateService } from '../project-create.service';

@Component({
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
	@ViewChild('registration') registration: RegistrationComponent;

	public componentState = 'signupsEnabled';

	constructor(
		public projectCreateService: ProjectCreateService,
		public apiService: ApiService,
		public authService: AuthService,
		private titleService: TitleService,
		public router: Router,
	) {}

	ngOnInit(): void {
		this.titleService.setTitle('Signup');
		setTimeout(() => {
			this.registration.loginForm.enableNameInput = true;
		});


		this.authService.onLogin.subscribe(() => {
			if (this.projectCreateService.skipPurchase) {
				this.apiService.post('/v1/guide/override', {
					coupon_code: this.projectCreateService.couponData?.coupon_code
				}).subscribe({
					next: (res: any) => {
						if (res.login_token) {
							this.authService.setLogin(res.login_token, true);
							if (this.projectCreateService.couponData?.coupon_code.match(/^partner-.*-free$/)) {
								this.projectCreateService.projectService.organizationId =
									this.authService.profile.data.account_access[1]['account_id'];
								this.projectCreateService.projectService.createProject({
									label: 'My First Project',
									fields: {
										'coupon_code': this.projectCreateService.couponData?.coupon_code,
									},
								}).subscribe(
									(response: any) => {
										this.router.navigate(['/projects']);
									},
									(error) => {
										console.log(error);
									}
								);
							} else {
								this.router.navigate(['/projects']);
							}
						}
					},
					error: (err: any) => {
						console.error(err);
					},
				});
			} else {
				// This is called twice upon login and successful purchase
				if (this.projectCreateService.couponData &&
					this.projectCreateService.couponData.coupon_code.match(/-free$/)) {
					// This is a freemium entry coupon and this user is an existing customer
					this.router.navigate(['/']);
				} else if (this.projectCreateService.getSelections().currentStep === 3) {
					this.projectCreateService.next();
				} else {
					this.projectCreateService.navigateTo(3);
				}
			}
		});
	}
}
