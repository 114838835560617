import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
	NavigationStart,
	NavigationEnd,
	Router,
	ActivatedRoute,
} from '@angular/router';
import { AuthService, MatomoAnalyticsService } from '@central/ng-shared';
import { filter } from 'rxjs/operators';
import {
	SharedStorageService,
	BrandingService,
	TrackingService,
	ZendeskService,
	LogrocketService,
	CouponService
} from '@central/ng-shared';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'sd-app',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	public dismissedNag = false;
	public forceGuestView = false;
	public disableGuestView = false;

	// Get the side bar element.

	constructor(
		private router: Router,
		private location: Location,
		private route: ActivatedRoute,
		private sharedStorage: SharedStorageService,
		private trackingService: TrackingService,
		private matomoService: MatomoAnalyticsService,
		public brandingService: BrandingService,
		public zendeskService: ZendeskService,
		public authService: AuthService,
		private cookieService: CookieService,
		public logrocketService: LogrocketService,
		public couponService: CouponService
	) {
		this.iframeNavigation();
		this.storeReferrer();
	}

	ngOnInit() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (event.url.endsWith('beta')) {
					this.disableGuestView = true;
				}
			}
		});

		this.trackSalesperson();
		this.setCrossAccountCookie();
		this.trackingService.loadRouting();
		this.couponService.getOffers();
	}

	private trackSalesperson() {
		// check for salesperson ID query parameter ('spid') and set cookie if not otherwise found
		const salespersonId = new URL(document.location.href).searchParams.get(
			'spid'
		);
		if (
			salespersonId &&
			/[a-z]+/i.test(salespersonId)
		) {
			const value = {
				salesperson: salespersonId.toLowerCase(),
				created_at: new Date().toISOString(),
			};

			const expires = new Date();
			expires.setTime(
				expires.getTime() + 7776000000 /* 90 days in milliseconds */
			);
			this.cookieService.set('spt', JSON.stringify(value), expires, '/');
		}
	}

	private setCrossAccountCookie() {
		// Used for AMP <-> Central reference
		this.authService.onLogin.subscribe(() => {
			const ttl = new Date();
			ttl.setTime(
				ttl.getTime() + 7776000004 /* 90 days in milliseconds */
			);
			this.cookieService.set('central', 'true', {
				path: '/',
				domain: '.inmotionhosting.com',
				expires: ttl,
			});
		});
	}

	public displayAuthView() {
		return true !== this.forceGuestView && 'user' === this.authService.role;
	}

	public storeReferrer() {
		this.route.queryParams.subscribe((params) => {
			if (params['ref']) {
				this.sharedStorage.setItem('ref', params['ref']);
			}
		});
	}

	/**
	 * When the user navigates to a new page while within the iframe. Break out of the iframe.
	 *
	 * @since 1.15.1
	 */
	public iframeNavigation() {
		let breakoutAttempted = false;
		this.router.events
			.pipe(filter((event: any) => event instanceof NavigationStart))
			.subscribe((event: any) => {
				// If inside of an iframe and navigation leads away from page, new tab.
				if (
					event.id === 2 &&
					!breakoutAttempted &&
					window.location !== window.parent.location
				) {
					breakoutAttempted = true;
					const url = this.location.prepareExternalUrl(event.url);
					const win = window.open(url, '_blank');
					if (win) {
						win.focus();
					}

					window.location.href = window.location.href;
				}
			});
	}

	public getSidebarMode() {
		return window.innerWidth <= 768 ? 'push' : 'side';
	}

	public getSidebarOpened() {
		return !(window.innerWidth <= 768);
	}


}
