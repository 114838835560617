<div class="ctw-flex mobile-menu" *ngIf="this.isMobile && !this.router.url.includes('/guide/')">
	<span class="ctw-flex ctw-items-center">
		<a routerLink="/"><img [src]="this.brandingService.getThemedAssetUrl('logo2.svg')" /></a>
	</span>
	<button mat-icon-button color="primary" aria-label="Expand Menus" (click)="openMenus()">
		<mat-icon>menu</mat-icon>
	</button>
</div>
<mat-sidenav-container class="primary-sidenav">
	<mat-sidenav [mode]="this.getMode()" [opened]="this.authenticated">
		<mat-nav-list class="expanded">
			<div class="nav-groups">
				<div>
					<div class="logo-container"
						[class.dark]="this.authService.isAdmin() && this.optimizelyService.getExperiment('central-test') === 'Original'">
						<a class="logo" routerLink="/">
							<img *ngIf="!this.authService.isAdmin() || this.optimizelyService.getExperiment('central-test') !== 'Original'"
								[src]="
									this.brandingService.getThemedAssetUrl('logo2.svg')
								" class="ctw-mx-auto" />
							<img *ngIf="this.authService.isAdmin() && this.optimizelyService.getExperiment('central-test') === 'Original'"
								[src]="
								this.brandingService.getThemedAssetUrl('logo3.svg')
							" class="ctw-mx-auto" />
						</a>
					</div>
					<div class="main-nav">
						<a mat-list-item routerLink="/teams" [class.router-link-active]="
								this.activeMenuItem === 'team'
							">
							<mat-icon>groups</mat-icon>
							<span class="label">Teams</span>
						</a>
						<a mat-list-item routerLink="/projects" [routerLinkActive]="['router-link-active']">
							<mat-icon>web_asset</mat-icon>
							<span class="label">Projects</span>
						</a>
						<a mat-list-item routerLink="/domains" [routerLinkActive]="['router-link-active']">
							<mat-icon>language</mat-icon>
							<span class="label">Domains</span>
						</a>
						<a mat-list-item routerLink="/email" [routerLinkActive]="['router-link-active']">
							<mat-icon>alternate_email</mat-icon>
							<span class="label">Email</span>
						</a>
					</div>
				</div>
				<div class="footer-nav">
					<a mat-list-item routerLink="/domains" *ngIf="0" [routerLinkActive]="['router-link-active']">
						<mat-icon>double_arrow</mat-icon>
					</a>
					<a *ngIf="this.authService.isAdmin()" mat-list-item routerLink="/agent"
						[routerLinkActive]="['router-link-active']">
						<mat-icon class="ctw-text-red-700">admin_panel_settings</mat-icon>
						<span class="label ctw-text-red-700">Agent Tools</span>
					</a>
					<a mat-list-item [matMenuTriggerFor]="subMenu" [class.router-link-active]="
							this.activeMenuItem === 'user'
						"><img class="profile-image" [src]="this.authService.profile.data.image" /><span class="label">My
							Account</span></a>
					<a mat-list-item routerLink="/notifications/all" [routerLinkActive]="['router-link-active']">
						<mat-icon [attr.data-icon-count]="
								this.authService.profile.data.notifications
							">notifications</mat-icon>
						<span class="label">Notifications</span>
					</a>
				</div>
			</div>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<ng-content></ng-content>
	</mat-sidenav-content>
</mat-sidenav-container>
<mat-menu #subMenu="matMenu" class="account-menu">
	<div class="menu-items">
		<div class="current-user">
			<img [src]="this.authService.profile.data.image" />

			<div class="identity">
				<div class="name">
					{{ this.authService.profile.data.display_name }}
				</div>
				<div class="username">
					{{ this.authService.profile.data.email }}
				</div>
			</div>
		</div>
		<button mat-menu-item routerLinkActive="router-link-active" routerLink="/account/settings">
			<mat-icon>settings</mat-icon> Profile
		</button>
		<button mat-menu-item routerLink="/logout">
			<mat-icon>exit_to_app</mat-icon> Sign Out
		</button>
	</div>
</mat-menu>
