import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService, AuthService } from '@central/ng-shared';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
import { EditDialogComponent } from '../../shared/edit-dialog/edit-dialog.component';

import config from './config';
import { AppService } from '../../app.service';

@Component({
	selector: 'cloud-snap-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
	@ViewChild('dashcard') public dashcard: any;

	public displaySidebar = false;
	public itemOptions = config.menuOptions;
	public snaps: object[] = [];
	public status = 'loading';
	public activeSnap: object;

	public linkActions = {
		details: (snap: any) =>
			this.router.navigate(['./snaps/' + snap.snap_id], {
				relativeTo: this.activatedRoute,
			}),
		edit: (snap: any) =>
			this.router.navigate(['./' + snap.snap_id + '/save'], {
				relativeTo: this.activatedRoute,
			}),
		rename: (snap) => this.rename(snap),
		install: (snap) =>
			this.router.navigate(['./install'], {
				queryParams: { snap_id: snap.snap_id },
				relativeTo: this.activatedRoute,
			}),
		delete: (snap) => this.delete(snap),
	};

	constructor(
		private apiService: ApiService,
		public activatedRoute: ActivatedRoute,
		public appService: AppService,
		public authService: AuthService,
		public dialog: MatDialog,
		public router: Router
	) {}

	ngOnInit() {
		this.fetchSnaps();
	}

	public rename(snap): void {
		const ref = this.dialog.open(EditDialogComponent, {
			width: '500px',
			disableClose: true,
			data: {
				label: 'Snap',
				resource: snap,
				apiCall: 'snapEdit',
				updateAction: (component) => {
					let headers = this.apiService.getHeaders({
						contentType: 'application/json',
					});
					headers = headers.append(
						'X-Organization-Id',
						snap.account_id
					);
					const url = this.apiService.formatter.getUrl(
						`/v1/snaps/${snap['snap_id']}`
					);
					this.apiService.http
						.post(
							url,
							{ label: component.resource['label'] },
							{ headers }
						)
						.subscribe(
							(response: object) => {
								component.resource = response;
								component.state = 'success';
								component.dialogRef.close(component.resource);
								component.snackBar.open(
									'Update Successful',
									'',
									{
										duration: 2000,
									}
								);
							},
							() => (component.state = 'failed')
						);
				},
			},
		});

		ref.afterClosed().subscribe((snapUpdated: any) => {
			if (snapUpdated) {
				snap['label'] = snapUpdated.label;
			}
		});
	}

	/**
	 * Delete a snap.
	 *
	 * @since 1.26.0
	 *
	 * @param Snap Resource.
	 */
	public delete(snap: object): void {
		const ref = this.dialog.open(DeleteDialogComponent, {
			width: '500px',
			data: {
				requireNameConfirmation: true,
				label: 'Snap',
				nameConfirmation: snap['label'],
				apiCall: 'snapDelete',
				apiData: { snap_id: snap['snap_id'] },
			},
		});

		ref.componentInstance.deleteSuccess.subscribe(() => {
			this.snaps = this.snaps.filter((val) => snap !== val);
		});
	}

	public assignOwner() {
		this.snaps.forEach((snap: any) => {
			snap.owner = this.authService.profile.data.account_access.find(
				(access) => access.account_id === snap.account_id
			);
		});
	}

	/**
	 * Fetch The users snaps.
	 *
	 * @since 1.26.0
	 */
	public fetchSnaps(): void {
		this.apiService.get('listSnaps', {}).subscribe(
			(response: any) => {
				this.snaps = response;
				this.assignOwner();
				this.status = 'success';

				setTimeout(() => (this.displaySidebar = true));
			},
			() => (this.status = 'failed')
		);
	}
}
