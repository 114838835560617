<div class="primary-heading">
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<h2>Snaps</h2>
		<div>
			<button
				mat-raised-button
				color="primary"
				routerLink="./save"
				*ngIf="this.snaps.length"
			>
				<mat-icon>add</mat-icon> New Snap
			</button>
		</div>
	</div>
</div>

<div class="spinner-container" *ngIf="'loading' === status">
	<mat-progress-spinner
		mode="indeterminate"
		color="accent"
		diameter="50"
	></mat-progress-spinner>
</div>

<failed-request *ngIf="'failed' === status"></failed-request>

<div class="content ctw-mx-auto ctw-max-w-6xl">
	<ng-container *ngIf="!this.snaps.length && status === 'success'">
		<div class="snaps-not-found">
			<img
				class="ctw-mx-auto ctw-mb-1 ctw-w-40"
				src="https://inmotionhosting.github.io/static-assets/illustrations/small/live-state-snapshot.svg"
			/>
			<h2>Fast and Easy Templates</h2>
			<p>
				A WordPress Snap lets you easily install your preferred
				WordPress plugins, themes and configurations. You can quickly
				create new WordPress sites by starting with a Snap.
			</p>
			<div class="action-buttons">
				<button mat-raised-button color="primary" routerLink="./save">
					<mat-icon>add</mat-icon> New Snap
				</button>
			</div>
		</div>
	</ng-container>
	<div
		*ngIf="this.status === 'success' && this.snaps.length"
		class="animate__animated animate__fadeIn"
	>
		<div
			class="ui-wrap"
			*ngIf="this.status === 'success' && this.snaps.length"
		>
			<mat-accordion>
				<ng-container *ngFor="let snap of snaps; let i = index">
					<dash-card
						#dashcard
						(opened)="this.activeSnap = snap"
						[expanded]="i === 0"
					>
						<ng-container subTitleHTML>
							<span>{{ snap.label }}</span>
							<span class="sub-title created-by">
								<em>Created By</em>
								<span class="ctw-ml-2">{{
									snap.owner?.display_name || 'You'
								}}</span>
							</span>
							<span
								class="sub-title rename"
								(click)="
									$event.stopPropagation(); this.rename(snap)
								"
							>
								<span class="action">
									<mat-icon>edit</mat-icon> Rename
								</span>
							</span>
						</ng-container>
						<ul class="dash-card-list row flex flex-wrap">
							<ng-container
								*ngFor="let option of this.itemOptions"
							>
								<li
									class="{{
										option.id
									}}-action col l2 m4 s6 card-item"
								>
									<div
										class="dash-card-action"
										mat-ripple
										(click)="
											this.linkActions[option.id](snap)
										"
									>
										<mat-icon *ngIf="option.icon">{{
											option.icon
										}}</mat-icon>
										<div
											*ngIf="option.img"
											class="svg-icon"
											[inlineSVG]="option.img"
										></div>
										<p>{{ option.label }}</p>
									</div>
								</li>
							</ng-container>
						</ul>
					</dash-card>
				</ng-container>
			</mat-accordion>
			<div class="details-col" *ngIf="this.displaySidebar">
				<bgc-snap-view
					[includeActions]="false"
					[snap]="this.activeSnap"
				></bgc-snap-view>
			</div>
		</div>
	</div>
</div>
