<bgc-app-access
	helpLink="https://www.boldgrid.com/free-wordpress-demo/"
	image="assets/img/icons/files.svg"
	appLink="files.php"
>
	<h3>Edit your Files and Databases effortlessly!</h3>
	<p>
		Your {{ this.environment }}
		includes a web-based File and Database manager. Modify your site's
		files, directories and permissions or perform common database operations
		like edit, optimize, repair and drop tables. You can also execute SQL
		commands, or export/import database records.
	</p>
	<p class="ctw-max-w-lg ctw-mx-auto ctw-text-imh-gray-200 ctw-text-sm">
		Recommended for advanced users to perform in-depth testing of themes,
		plugins, or other functionality in a familiar interface.
	</p>
</bgc-app-access>
