import ppbPremium from '../products/ppb/premium';

export default {
	label: 'Post & Page Builder Premium',
	productKeys: ['wpc_ppbp'],
	licenseNames: ['post-and-page-builder'],
	name: 'post-and-page-builder',
	tags: ['post-and-page-builder', 'premium'],
	color: 'primary',
	features: ppbPremium,
};
