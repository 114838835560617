import { Component, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../../core/api/api.service';
import { UpdatePasswordModel } from './update-password.model';
import { BrandingService } from '../../core/branding/branding.service';
import { Router } from '@angular/router';
@Component({
	selector: 'central-update-password',
	templateUrl: 'password-update.component.html',
	styleUrls: ['password-update.component.scss'],
})
export class UpdatePasswordComponent {
	@Input() public appearance = 'outline';

	public model = new UpdatePasswordModel('', '', '');
	public submitted = false;
	public state = 'pending';
	public email: string;
	public errorMessage = '';
	public genericError = 'Unable to update password. Please try again.';

	constructor(
		public apiService: ApiService,
		public authService: AuthService,
		public brandingService: BrandingService,
		public router: Router
	) {
		this.email = authService.jwtHelper.getTokenVal('email');
	}

	public success() {
		this.state = 'success';
	}

	public reset() {
		this.state = 'pending';
		this.submitted = false;
		this.errorMessage = '';
	}

	public fail(response?: object) {
		this.state = 'fail';

		this.errorMessage = this.genericError;
		if (
			response &&
			response['result'] &&
			response['result']['data'] &&
			response['result']['data']['error']
		) {
			this.errorMessage = response['result']['data']['error'];
		}
	}

	public onSubmit(newPassword: string) {
		if (this.state !== 'submitted' && this.passwordIsValid(newPassword)) {
			this.updatePassword(newPassword);
		} else {
			this.state = 'fail';
		}
	}

	public passwordIsValid(newPassword: string) {
		let isValid = false;
		if (
			newPassword &&
			newPassword.match(/(?=.*?[A-Z])(?=.*\d)(?=.*?[a-z]).{8,}$/g)
		) {
			isValid = true;
		}

		return isValid;
	}

	public updatePassword(newPassword: string) {
		this.state = 'submitted';
		this.errorMessage = '';

		this.apiService
			.post('changePassword', {
				old_password: this.model.oldPassword,
				new_password: newPassword,
			})
			.subscribe(
				(response) => {
					const result = response['result'];

					if (result && result.data && result.data.success) {
						if (result.data.token) {
							this.authService.setLogin(result.data.token, true);
						} else {
							this.authService.logout();
							this.router.navigate(['/login']);
						}
						this.success();
					} else {
						this.fail(response);
					}
				},
				(err) => {
					this.fail();
				}
			);
	}
}
