import { EventEmitter, Injectable } from '@angular/core';
import { ApiService, AuthService } from '@central/ng-shared';
import { Observable } from 'rxjs';

@Injectable()
export class LicenseService {
	public licenseChange = new EventEmitter();
	private licenses: object;
	private fetching = false;

	constructor(
		private apiService: ApiService,
		private authService: AuthService
	) {
		this.fetch();
	}

	/**
	 * Get licenses.
	 *
	 * @since 1.23.0
	 *
	 * @return License data.
	 */
	public getLicenses(): object {
		return this.licenses;
	}

	/**
	 * Does the current user own the connect key.
	 *
	 * @since
	 * @return [description]
	 */
	public isKeyOwner(connectId: number): boolean {
		return Array.isArray(this.getConnectKeyLicenses(connectId));
	}

	/**
	 * Get license names only for a connect key.
	 *
	 * @since  1.23.0
	 * @param  connectId Connect Id.
	 * @return           License names
	 */
	public getKeyLicensesNames(connectId: number): string[] {
		const licenses = this.getConnectKeyLicenses(connectId) || [];
		return licenses.map((val) => val['name']);
	}

	/**
	 * Get a list of license names.
	 *
	 * @since 1.23.0
	 *
	 * @return A list of licenses unique to an account.
	 */
	public getLicenseNames() {
		let names = this.getAccountLicenses().map((val: any) => val['name']);
		for (const connectKey of this.licenses['connect_keys'] || []) {
			names = [
				...names,
				...this.getKeyLicensesNames(connectKey['connect_id']),
			];
		}

		// Filter out duplicates
		names = names.filter(
			(item: any, index: number) => names.indexOf(item) === index
		);

		return names;
	}

	/**
	 * Does the user have the licesnse on any of their keys or the account.
	 *
	 * @param name Name of license.
	 * @return Whether or not the user has the license.
	 */
	public hasLicense(name: string): boolean {
		return -1 !== this.getLicenseNames().indexOf(name);
	}

	/**
	 * Does this account have the free version of all licenses?
	 *
	 * @return Has all free licenses?
	 */
	public hasAllFreeProducts(): boolean {
		const freeCWP =
			1 === this.getAccountLicense('cloud-wordpress')['data']['limit'];
		const freeSpeedcoach =
			0 === this.getAccountLicense('speedcoach')['data']['limit'];
		const noLicences =
			0 ===
			this.getLicenseNames().filter(
				(val) => !['cloud-wordpress', 'speedcoach'].includes(val)
			).length;

		return freeCWP && freeSpeedcoach && noLicences;
	}

	/**
	 * Get the link the user should visit to upgrade their account.
	 *
	 * This generally applies to users without any connect keys.
	 *
	 * @return array Upgrade Link.
	 */
	public getUpgradeLink(): any[] {
		let upgradeLink = ['/connect-key/new'];
		const connectKeys = this.getBasicConnectKeys();
		if (connectKeys.length) {
			upgradeLink = [
				'/connect-key',
				connectKeys[0]['connect_id'],
				'addons',
			];
		}

		return upgradeLink;
	}

	/**
	 * Get the user connect keys.
	 *
	 * @return List of connect keys and license data.
	 */
	public getConnectKeys(): any[] {
		return this.licenses['connect_keys'];
	}

	/**
	 * Get all free connect keys.
	 *
	 * @since 1.23.0
	 *
	 * @return Conncet key licenses that are not premium.
	 */
	public getBasicConnectKeys() {
		return this.licenses['connect_keys'].filter(
			(val: any) =>
				!val.licenses.find(
					(license: any) => 'boldgrid-premium' === license.name
				)
		);
	}

	/**
	 * Does this user have a connect key.
	 *
	 * @return Has Connect Key?
	 */
	public hasConnectKey(): boolean {
		return !!this.licenses['connect_keys'].length;
	}

	/**
	 * Does the user have multple connect key.
	 *
	 * @return Has Multiple keys?
	 */
	public hasMultipleKeys(): boolean {
		return this.licenses['connect_keys'].length > 1;
	}

	/**
	 * Given a connect id return all licenses for that key.
	 *
	 * @since 1.23.0
	 *
	 * @param  connectId Connect Id.
	 * @return           Licenses.
	 */
	public getConnectKeyLicenses(connectId: number): object[] {
		let licenses;
		if (this.licenses) {
			const data = this.licenses['connect_keys'].find(
				(val: any) => val.connect_id === connectId
			);

			if (data) {
				licenses = data['licenses'] || [];
			}
		}

		return licenses;
	}

	/**
	 * Get all account licenses.
	 *
	 * @since 1.23.0
	 *
	 * @return list of licenses.
	 */
	public getAccountLicenses() {
		return this.licenses ? this.licenses['account']['licenses'] : [];
	}

	/**
	 * Get license for an account.
	 *
	 * @since 1.23.0
	 *
	 * @param  name License name.
	 * @return      Data.
	 */
	public getAccountLicense(name: string) {
		return this.licenses
			? this.licenses['account']['licenses'].find(
					(val: any) => name === val.name
			  )
			: null;
	}

	/**
	 * Get a license for an individual connect id.
	 *
	 * @since 1.23.0
	 *
	 * @param  connectId Connect key Id.
	 * @param  name      License Name.
	 * @return           License Data.
	 */
	public getConnectLicense(connectId: number, name: string) {
		let license = null;
		if (this.licenses) {
			const licenses = this.getConnectKeyLicenses(connectId);
			license = licenses.find((val: any) => name === val.name) || null;
		}

		return license;
	}

	/**
	 * Setter for auth service.
	 *
	 * @since 1.23.0
	 *
	 * @param  authService Auth Service.
	 */
	public setAuthService(authService: AuthService): void {
		this.authService = authService;
	}

	/**
	 * An observable that will resolve when the data is ready.
	 *
	 * @since 1.23.0
	 */
	public onReady() {
		return new Observable((observer: any) => {
			const resolver = () => {
				if (this.licenses) {
					observer.next();
					observer.complete();
				} else {
					observer.error();
				}
			};

			if (this.fetching) {
				this.licenseChange.subscribe(() => resolver());
			} else {
				resolver();
			}
		});
	}

	/**
	 * Make an API call to get license data.
	 *
	 * @since 1.23.0
	 */
	public fetch(): void {
		if (
			this.fetching ||
			!this.authService ||
			!this.authService.isLoggedIn()
		) {
			return;
		}

		this.fetching = true;

		this.apiService.get('getLicenses', {}).subscribe(
			(licenses: any) => {
				this.licenses = licenses;
				this.licenseChange.emit();
				this.fetching = false;
			},
			() => {
				this.licenses = null;
				this.licenseChange.emit();
				this.fetching = false;
			}
		);
	}
}
