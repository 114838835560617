import { Component, Input } from '@angular/core';

@Component({
	selector: 'bgc-rating',
	templateUrl: './rating.component.html',
	styleUrls: ['./rating.component.scss'],
})
export class RatingComponent {
	@Input() score = 0;
}
