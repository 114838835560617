<h2>Add Public SSH Key</h2>
<p>
	Add your public ssh key below to automatically update your server's
	<b>authorized_keys</b> file and grant you root access to the server.
</p>
<p>
	Not sure
	<a
		href="https://www.inmotionhosting.com/support/server/ssh/how-to-generate-ssh-keys/"
		target="_blank"
		>how to get a public SSH key</a
	>?
</p>
<p *ngIf="serverDown" class="ctw-text-red-700">
	Server is currently down, so we can't update your SSH keys. Start the server
	to update the keys!
</p>
<p *ngIf="badKey" class="ctw-text-red-700">
	The key you entered is an invalid format for a public SSH key!
</p>
<p *ngIf="duplicate" class="ctw-text-red-700">
	This public key is already added to the server!
</p>
<mat-form-field class="add-public-key" appearance="fill">
	<mat-label>Public SSH Key</mat-label>
	<textarea
		#pubKey
		spellcheck="false"
		rows="7"
		matInput
		placeholder="Ex. ssh-ed25519 AAAAC3Nz..."
	></textarea>
</mat-form-field>

<div class="ctw-flex ctw-justify-end">
	<button [mat-dialog-close]="'cancel'" mat-button mat-dialog-close>
		Cancel
	</button>
	<central-progress-button
		class="ctw-ml-2"
		(click)="this.processKey(pubKey.value)"
		mat-raised-button
		color="primary"
		[state]="this.status"
		>Update</central-progress-button
	>
</div>
