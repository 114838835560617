import backupPremium from '../products/backup/premium';
import connectPremium from '../products/connect/premium';
import cwpPremium from '../products/cwp/premium';
import ppbPremium from '../products/ppb/premium';

export default {
	name: 'premium',
	label: 'Premium Connect Key',
	licenseNames: ['boldgrid-premium'],
	productKeys: ['wpc_premium'],
	tags: ['key', 'premium', 'boldgrid-backup', 'post-and-page-builder'],
	color: 'accent',
	features: [
		...connectPremium,
		...backupPremium,
		...ppbPremium,
		...cwpPremium,
	],
};
