import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Formatter } from '../../../domain/dns-manager/formatter';
import { ProjectService } from '../../project.service';
import { PollingService } from '@central/ng-shared';

@Component({
	selector: 'central-apply-domain',
	templateUrl: './apply-domain.component.html',
	styleUrls: ['./apply-domain.component.scss'],
})
export class ApplyDomainComponent implements OnInit {
	@Input() dnsView = 'state-based';
	@Input() mode = 'changeUrl';
	@Output() applyDomainReady = new EventEmitter<any>();

	public domain: string;
	public domainState = 'needs-dns';
	public domainCheckState = 'pending';
	public forceContinueState = 'pending';
	public state = 'pending';
	public failedDns = false;
	public usedDomains: string[] = [];
	public usedState = 'loading';
	public hostname: '';
	public dnsEntry: string;
	public status: string;

	constructor(
		public apiService: ApiService,
		public projectService: ProjectService,
		public pollingService: PollingService,
		public clipboard: Clipboard,
		public snackbar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.setStatus(this.projectService.environment);
		this.fetchProjects();
	}

	public isReady() {
		this.applyDomainReady.emit();
		this.status = 'ready';
	}

	public copy() {
		this.clipboard.copy(this.dnsEntry.trim());
		this.snackbar.open('Copied DNS Record to Clipboard!', '', {
			duration: 4000,
		});
	}

	public fetchProjects() {
		this.apiService.get('/v1/projects', {}).subscribe({
			next: (projects) => {
				const allEnvs = [];
				for (const project in projects) {
					if (projects[project].children) {
						for (const child in projects[project].children) {
							if (projects[project].children.hasOwnProperty(child)) {
								allEnvs.push(projects[project].children[child]);
							}
						}
					}
				}
				this.hostname = this.projectService.environment.fields.hostname;
				this.usedDomains = allEnvs
					.filter((env) => env.fields.domain)
					.map((env) => env.fields.domain);
				this.usedState = 'success';
			},
			error: () => {
				this.usedState = 'failed';
			},
		});
	}

	public hasDomain() {
		return this.projectService.environment.fields.domain;
	}

	public setStatus(environment) {
		this.domainState = environment.fields.domain_info?.state || 'needs-dns';

		if (this.domainState === 'needs-dns') {
			this.dnsEntry = `     ${
				this.projectService.environment.fields.domain
			}.     A     ${this.getIpAddress()}`;
		}

		// Update Project Service fields.
		this.projectService.environment.fields = {
			...this.projectService.environment.fields,
			...environment.fields,
		};

		if (
			this.projectService.environment.fields.playbook_running ===
			'in-progress'
		) {
			this.setupPolling();
		} else {
			this.projectService.playbookStatus.next('playbook-not-running');
		}
	}

	setupPolling() {
		this.projectService.playbookStatus.next('playbook-running');
		this.doPolling();
	}

	public getIpAddress() {
		return this.projectService.environment.fields.ipAddress;
	}

	public checkDomainStatus(changeDomain = {}) {
		this.domainCheckState = 'submitted';

		let data = {};
		if (changeDomain['fqdn']) {
			data = changeDomain;
			this.domain = changeDomain['fqdn'];
		}

		this.apiService.http
			.post(
				this.apiService.formatter.getUrl(
					`/v1/environments/${this.projectService.environment.id}/url-validation`
				),
				data,
				{
					headers: this.apiService.getHeaders({
						contentType: 'application/json',
					}),
				}
			)
			.subscribe({
				next: (response: any) => {
					this.domainCheckState = 'pending';
					this.domainState = response?.fields?.domain_info?.state;
					this.dnsView = 'state-based';

					if (
						this.domainState === 'needs-dns' &&
						this.forceContinueState === 'pending'
					) {
						this.dnsEntry = `${
							this.domain
						}.     A     ${this.getIpAddress()}`;
					}
				},
				error: (response) => {
					this.domainCheckState = 'failed';
					console.log(response);
				},
			});
	}

	public getUrl() {
		return this.domain;
	}

	public applyUrl() {
		//@TODO temp fix until IMC-497 is implemented.
		if (this.state === 'submitted') {
			return;
		}

		this.state = 'submitted';
		this.projectService.reloadProject().subscribe(() => {
			this.setStatus(this.projectService.environment);
			const fields = this.projectService.environment.fields;

			console.log(fields);
			console.log(fields.domain_info?.state);
			let updateARecord: any = of([]);
			// If the domain can be managed, but the dns is not set yet. Set it now.
			if (fields.domain_info?.state === 'ready') {
				if (
					fields.domain_info?.dns_management_enabled &&
					!fields.domain.includes('inmotionhosting.com')
				) {
					updateARecord = this.updateARecord(
						fields.domain_info,
						fields.machine_details.machine_ip
					);
					console.log(updateARecord);
				}
			}

			updateARecord
				.pipe(
					mergeMap(() =>
						this.apiService
							.post(
								`/v1/environments/${this.projectService.environment.id}/domain`,
								{
									applySsl: this.getAutoSslSetting(),
								}
							)
							.pipe(
								tap((response) => {
									this.projectService.playbookStatus.next(
										'playbook-started'
									);
								})
							)
					)
				)
				.subscribe({
					complete: () => {},
					error: () => {
						this.state = 'pending';
					},
				});
		});
	}

	public getAutoSslSetting() {

	}

	public doPolling() {
		this.pollingService
			.startPoll(
				() => this.projectService.reloadProject(false),
				() =>
					!this.projectService.environment.fields?.playbook_running ||
					this.projectService.environment.fields.playbook_running ===
						'complete',
				{
					interval: 60000,
					timeout: 600000,
				}
			)
			.subscribe(
				() => {
					this.setStatus(this.projectService.environment);
					this.projectService.playbookStatus.next(
						'playbook-not-running'
					);
					this.state = 'pending';
				},
				(error) => {
					this.projectService.playbookStatus.next(
						'playbook-request-failed'
					);
					this.state = 'pending';
				}
			);
	}

	public forceContinue() {
		this.forceContinueState = 'submitted';

		const domainInfo =
			this.projectService.environment.fields.domain_info || {};
		domainInfo.force_continue = true;
		domainInfo.state = 'ready';

		this.projectService
			.updateEnvironmentField({ domain_info: domainInfo })
			.subscribe({
				next: (environment) => {
					this.domainState = environment?.fields?.domain_info?.state;
					this.dnsView = 'state-based';
				},
				error: (response) => {
					this.forceContinueState = 'failed';

					console.error(response);
				},
			});
	}

	public updateARecord(domainInfo, ipAddress) {
		const domainId = domainInfo?.domain.id;
		this.failedDns = false;

		// Fetch the current DNS for a domain.
		return this.getDns(domainId)
			.pipe(this.replaceARecord(domainInfo, ipAddress))
			.pipe(mergeMap((dnsZone) => this.patchDns(domainId, dnsZone)))
			.pipe(this.setFailedState());
	}

	protected patchDns(domainId, dnsZone) {
		const url = this.apiService.formatter.getUrl(
			`/v1/domain-service/dns-zone`
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		return this.apiService.http.patch(
			url,
			{ domainName: domainId, zoneData: dnsZone },
			{ headers }
		);
	}

	protected replaceARecord(domainInfo, ipAddress: string) {
		return map((dnsZone: any) => {
			dnsZone = dnsZone.filter((entry) => {
				const domainPrefix = entry.name === '@' ? '' : entry.name + '.';
				return (
					entry.type !== 'A' ||
					domainPrefix + domainInfo.domain.baseDomain !==
						domainInfo.domain.fqdn
				);
			});

			const fqdn = domainInfo.domain.fqdn;

			dnsZone.push({
				name: fqdn.startsWith('www.') ? fqdn.substring(4) : fqdn,
				type: 'A',
				rdata: { aValue: ipAddress },
				ttl: 14400,
			});

			if (!domainInfo.domain.subDomain) {
				dnsZone.push({
					name: 'www.' + fqdn,
					type: 'CNAME',
					rdata: { cnameValue: fqdn },
					ttl: 14400,
				});
			} else if (domainInfo.domain.subDomain === 'www') {
				dnsZone.push({
					name: fqdn,
					type: 'CNAME',
					rdata: { cnameValue: fqdn.substring(4) },
					ttl: 14400,
				});
			}

			new Formatter().formatRecordsForApi(
				dnsZone,
				domainInfo.domain.baseDomain
			);

			return dnsZone;
		});
	}

	protected setFailedState() {
		const url = this.apiService.formatter.getUrl(
			`/v1/environments/${this.projectService.environment.id}`
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		return catchError(() => {
			this.projectService.environment.fields.domain_info.state =
				'needs-dns';

			return this.apiService.http
				.patch(
					url,
					{
						fields: {
							domain_info:
								this.projectService.environment.fields
									.domain_info,
						},
					},
					{ headers }
				)
				.pipe(
					map((environment) => {
						this.failedDns = true;
						console.warn('Failed to update DNS');
					})
				);
		});
	}

	protected getDns(domainId) {
		const url = this.apiService.formatter.getUrl(
			'/v1/domain-service/dns-zone/' + domainId
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		return this.apiService.http.get(url, { headers });
	}
}
