<ng-container *ngIf="!this.settingsHelper.hasAutoUpdates(this.data.settings)">
	<ng-container *ngIf="this.state !== 'failed'">
		<div class="title ctw-mb-5">Automatically Update Plugins</div>
		<p>
			Make sure you have the latest code running on your site and prevent
			unwanted errors.
		</p>
		<p>
			We suggest turning on automatic updates for our trusted collection
			of BoldGrid plugins.
			<a
				(click)="
					this.data.projectService.login(
						'wp-admin/admin.php?page=boldgrid-backup-settings'
					)
				"
				>Manage in WordPress</a
			>
			for more options.
		</p>
		<div class="action-buttons">
			<button
				mat-button
				mat-raised-button
				[disabled]="this.state === 'submitted'"
				(click)="this.matDialogRef.close()"
			>
				Cancel
			</button>
			<central-progress-button [state]="this.state" (click)="this.enable()"
				>Enable</central-progress-button
			>
		</div>
	</ng-container>
	<ng-container *ngIf="this.state === 'failed'">
		<div class="title ctw-mb-5">An unexpected error occurred</div>
		<p>
			We were unable to update your settings. Try again or manage from
			WordPress.
		</p>
		<div class="action-buttons">
			<button
				mat-button
				mat-raised-button
				color="accent"
				(click)="this.matDialogRef.close()"
			>
				Okay
			</button>
		</div>
	</ng-container>
</ng-container>
<ng-container *ngIf="this.settingsHelper.hasAutoUpdates(this.data.settings)">
	<div class="title ctw-mb-5">Automated Updates Enabled</div>
	<p>
		Your site is setup to automatically update trusted plugins. If you want
		to enable this for more plugins or themes please,
		<a
			(click)="
				this.data.projectService.login(
					'wp-admin/admin.php?page=boldgrid-backup-settings'
				)
			"
			>Manage in WordPress</a
		>.
	</p>
	<div class="action-buttons">
		<button
			mat-button
			mat-raised-button
			(click)="this.matDialogRef.close()"
			color="accent"
		>
			Okay, Got It!
		</button>
	</div>
</ng-container>
