import { Injectable, Injector } from '@angular/core';
import { AuthService } from '@central/ng-shared';

@Injectable()
export class AccountService {
	public accountId = null;

	constructor(public authService: AuthService) {}

	public getAccountId() {
		return this.accountId || this.authService.getAccountId();
	}
}
