import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ApiService } from '@central/ng-shared';
import { ProjectService } from './project.service';
import { WpRestService } from './site/rest.service';
import { LocalStorageService } from '@central/ng-shared';
@Component({
	selector: 'bgc-project',
	templateUrl: 'project.component.html',
	providers: [ProjectService],
})
export class ProjectComponent implements OnDestroy {
	public accountProjects = [];
	public status = 'loading';
	public queryEnvironmentId: string;
	public isProjectRoute = false;
	public routerSubscription;

	public constructor(
		public apiService: ApiService,
		public wpRestService: WpRestService,
		private router: Router,
		private projectService: ProjectService,
		private activeRoute: ActivatedRoute,
		private localStorage: LocalStorageService
	) {
		this.activeRoute.queryParams.subscribe((params) => {
			if (
				this.queryEnvironmentId !== params.environment_id &&
				this.projectService.project
			) {
				this.queryEnvironmentId = params.environment_id;
				this.setCurrentEnvironment();
			} else {
				this.queryEnvironmentId = params.environment_id;
			}

			this.setProject();
		});

		this.routerSubscription = this.router.events.subscribe((e) => {
			if (e instanceof NavigationEnd) {
				// Routes that do not need to wait for a connection.
				this.isProjectRoute = !!e.url.match(
					/\/projects\/[a-zA-Z\d]+($|\?|\/settings|\/snaps|\/hosting|\/whats\-new|\/vz\-manager|\/overview|\/nginx-settings|\/php-settings|\/domain|\/sites|\/add\/hosting|\/resize-server)/
				);
			}
		});

		// Fix for sidebar width bug.
		setTimeout(() => window.dispatchEvent(new Event('resize')), 50);
	}

	public ngOnDestroy(): void {
		this.routerSubscription.unsubscribe();
	}

	public setCurrentEnvironment() {
		const cachedEnv = this.localStorage.getItem('environment_id');

		if (this.queryEnvironmentId && this.queryEnvironmentId !== cachedEnv) {
			this.projectService.setEnvironment(this.queryEnvironmentId);
			this.localStorage.setItem(
				'environment_id',
				this.queryEnvironmentId
			);
		} else if (cachedEnv) {
			this.projectService.setEnvironment(cachedEnv);
		}
	}

	public setProject() {
		this.activeRoute.params.subscribe((routeParams) => {
			const projectId = routeParams.project_id;

			this.projectService.fetchProject(projectId).subscribe(
				(data) => {
					this.setCurrentEnvironment();
					this.status = 'success';
				},
				() => {
					this.router.navigateByUrl('/404', { replaceUrl: true });
				}
			);
		});
	}
}
