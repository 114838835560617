import { Injectable } from '@angular/core';
import { ApiService, AuthService } from '@central/ng-shared';

@Injectable()
export class NotificationService {
	public unreadCount = 0;
	public unreadMax = 99;
	public accountId;

	constructor(
		public apiService: ApiService,
		public authService: AuthService
	) {}

	public updateCount(count: number) {
		if (this.getAccountId() !== this.authService.getAccountId()) {
			return;
		}

		this.authService.profile.data.notifications = Math.max(0, count);
		this.authService.profile.data.notifications = Math.min(
			this.authService.profile.data.notifications,
			this.unreadMax
		);
	}

	public getAccountId() {
		return this.authService.getAccountId();
	}

	public decrementUnreadCount() {
		if (this.getAccountId() !== this.authService.getAccountId()) {
			return;
		}

		this.updateCount(this.authService.profile.data.notifications - 1);
	}

	/**
	 * Mark notifcation as read.
	 *
	 * @since 1.0.0
	 * @param  notificationId Notification ID.
	 */
	public updateNotification(notificationId: number) {
		this.apiService
			.post(
				`/v1/notifications/` + notificationId.toString() + '/read',
				{}
			)
			.subscribe();
	}
}

/*
* When do we get the count?
In the toolbar compoenent.
Count.
Messages
Where should Count be stored?
window.localStorage - persistance between pages laoded, not important to me
variable - wel will be updating this frequently
When is count updated?
On Dashboard visit or new toolbar render.
Must check expired
When are messages updated?
When you go to the notifcations Page.
*/
