<mat-progress-bar *ngIf="!ready && state !== 'success' && this.state !== 'blocked' && this.state !== 'failed'"
	mode="indeterminate" color="primary"></mat-progress-bar>

<failed-request *ngIf="this.state === 'failed'"></failed-request>

<div *ngIf="this.isMultisite" class="ctw-text-center">
	<h3>Multisite Not Supported</h3>
	<p>
		We're sorry, backups are not currently supported in multisite
		environments.
	</p>
	<a routerLink="../site" mat-button mat-raised-button color="primary">Okay</a>
</div>
<ng-container *ngIf="(state === 'success' || state === 'submitted') && !this.isMultisite">
	<ng-container *ngIf="!hasPlugin">
		<div class="plugin-page wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mb-12">
			<div class="plugin-page__image"></div>
			<div class="plugin-page__info">
				<h3>Protect your Website</h3>
				<h3>Total Upkeep</h3>
				<p>
					You're looking for a tool to protect your site. We can help.
					Total Upkeep comes with automated remote backups, tools to
					restore your site even if your WordPress installation is
					corrupt, the ability to automatically roll back failed
					updates, and more.
				</p>
				<div class="action-buttons">
					<a mat-button mat-raised-button
						href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/addons/total-upkeep-premium/#backups/"
						target="_blank">Learn More <mat-icon>open_in_new</mat-icon></a>
					<central-progress-button [state]="state" (click)="activatePlugin()" color="primary">Enable
						Plugin</central-progress-button>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="this.hasPlugin">
		<div class="plugin-page wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mb-12">
			<div class="plugin-page__image"></div>
			<div class="plugin-page__info">
				<h3>Great! Total Upkeep is Enabled</h3>
				<p>
					You're looking for a tool to protect your site. We can help.
					Total Upkeep comes with automated remote backups, tools to
					restore your site even if your WordPress installation is
					corrupt, the ability to automatically roll back failed
					updates, and more.
				</p>
				<div class="action-buttons">
					<!--<central-progress-button [state]="this.state" color="secondary"
						(click)="this.disableFeature()">Remove
						Plugin & Disable</central-progress-button>-->
					<button mat-button mat-raised-button color="primary" [disabled]="this.state === 'submitted'"
						(click)="
							this.projectService.login(
								'wp-admin/admin.php?page=boldgrid-backup-dashboard'
							)
						">
						Manage Backups <mat-icon>open_in_new</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</ng-container>
	<span class="actions" *ngIf="hasPlugin && 0">
		<div>
			<button mat-button mat-stroked-button color="primary" (click)="
					this.projectService.login(
						'wp-admin/admin.php?page=boldgrid-backup-dashboard'
					)
				">
				Manage in WordPress
			</button>
		</div>
		<ng-container *ngIf="this.backups.length && this.settings">
			<div>
				<button (click)="openSchedulePrompt()" mat-button>
					<mat-icon>{{
						this.hasScheduledBackups ? 'check' : 'close'
						}}</mat-icon>
					Automatic Backups
				</button>
			</div>
			<div>
				<button (click)="openAutoUpdatePrompt()" mat-button>
					<mat-icon>{{
						this.hasAutoUpdates ? 'check' : 'close'
						}}</mat-icon>
					Automatic Updates
				</button>
			</div>
			<div *ngIf="false === testResults['passed']">
				<button (click)="this.openFunctionalityTests()" mat-button mat-raised-button color="warn">
					<mat-icon>close</mat-icon> Functionality Tests Failed
				</button>
			</div>
		</ng-container>
	</span>
	<ng-container *ngIf="hasPlugin && 0 && this.backups.length">
		<div class="wrap animate__animated animate__fadeIn">
			<h3 class="ctw-text-center">Site Backups</h3>
			<mat-list>
				<mat-list-item *ngFor="let backup of backups">
					<mat-icon mat-list-icon matTooltip="Stored on your Wordpress Server"
						*ngIf="!backup.location || backup.location === 'server'">cloud</mat-icon>
					<span mat-list-icon class="svg-icon" matTooltip="Stored on Google Drive"
						*ngIf="backup.location === 'google_drive'" inlineSVG="/assets/img/icons/gdrive.svg"></span>
					<h4 mat-line>{{ backup.title }}</h4>
					<p mat-line>
						{{ backup.creation_date | amDateFormat : 'LLLL' }}
					</p>
					<button mat-icon-button [matMenuTriggerFor]="actions" [matMenuTriggerData]="{ backup: backup }">
						<mat-icon>more_vert</mat-icon>
					</button>
				</mat-list-item>
			</mat-list>
		</div>

		<mat-menu #category #actions="matMenu">
			<ng-template matMenuContent let-backup="backup">
				<button mat-menu-item (click)="
						this.projectService.login(
							'wp-admin/admin.php?page=boldgrid-backup-archive-details&filename=' +
								backup.filename
						)
					">
					<mat-icon>remove_red_eye</mat-icon> View Details
				</button>
				<button mat-menu-item *ngIf="testResults['passed']" (click)="this.openRestoreConfirm(backup)">
					<mat-icon>cloud_upload</mat-icon> Restore
				</button>
			</ng-template>
		</mat-menu>

		<div class="test-new-page">
			<button *ngIf="testResults['passed']" mat-fab color="primary" class="fab-text-button"
				[disabled]="this.backupStatus === 'submitted'" (click)="createBackup()">
				<mat-icon>add</mat-icon> New Backup
			</button>
		</div>
	</ng-container>
	<div class="no-backups" *ngIf="hasPlugin && 0 && !this.backups.length">
		<ng-container *ngIf="this.backupStatus === 'submitted'">
			<mat-icon class="backup-started">backup</mat-icon>
			<h3>Awesome, It's Started!</h3>
			<p>
				Backing up your site can take several minutes if you have a
				large site. Feel free to check back later.
			</p>
		</ng-container>

		<ng-container *ngIf="this.backupStatus === 'pending' && testResults['passed']">
			<div class="plugin-page__image"></div>
			<h3>No Backups Found</h3>
			<h3>Lets create your first backup!</h3>
			<div class="action-buttons">
				<central-progress-button [state]="backupStatus" (click)="createBackup()"
					color="primary"><mat-icon>add</mat-icon> New Backup</central-progress-button>
			</div>
		</ng-container>

		<ng-container *ngIf="
				this.backupStatus === 'pending' &&
				false === testResults['passed']
			">
			<div class="plugin-page__image"></div>
			<h3>Functionality Tests Failed</h3>
			<p>
				You'll need to address an issue with your environment before
				backups can work correctly.
			</p>
			<div class="action-buttons">
				<button color="primary" mat-raised-button mat-button (click)="this.openFunctionalityTests()">
					View Issues
				</button>
			</div>
		</ng-container>
	</div>
</ng-container>
