export default {
	name: 'speedcoach-tier-2-yearly',
	productKeys: ['speedc_tier2'],
	licenseNames: [],
	label: '1800 Scans / Month',
	color: 'accent',
	tags: ['speedcoach', 'premium'],
	features: [
		{
			label: 'Speed Coach Business',
			type: 'separator',
		},
		{
			label: '1800 Additional Scans',
		},
	],
};
