import {
	AfterViewInit,
	Component,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EmailInputComponent } from '../email-input/email-input.component';
import { map, startWith } from 'rxjs/operators';
import { countries } from 'typed-countries';
import { AuthService } from '../../authentication/services/auth.service';
import { ApiService } from '../../core/api/api.service';
import { ContactModel } from './contact.model';
import { validate as postcodeValidator } from 'postcode-validator';
import * as provinces from 'provinces/provinces.json';
import { ConfigurationService } from '../../core/configuration.service';

@Component({
	// eslint-disable-next-line
	selector: 'bg-contact',
	templateUrl: 'contact.component.html',
	styleUrls: ['contact.component.scss'],
})
export class ContactComponent implements OnInit, AfterViewInit {
	@ViewChild('firstName') public firstName: any;
	@ViewChild('lastName') public lastName: any;
	@ViewChild('phoneNumber') public phoneNumber: any;
	@ViewChild('address1') public address1: any;
	@ViewChild('address2') public address2: any;
	@ViewChild('city') public city: any;
	@ViewChild('postalCode') public postalCode: any;
	@ViewChild('emailInput') public emailInput: EmailInputComponent;

	@Input() public excludedInputs = ['emails'];
	@Input() public readOnlyForm = false;
	@Input() public minimalForm = false;
	@Input() public forceLogin = false;
	@Input() public loginModalTitle: string;
	@Input() public loginModalSubTitle: string;

	public status = 'pending';
	public states: object;
	public updateStatus = 'pending';
	public model = new ContactModel();
	public countryList = { ...countries, ...{} };
	public regionOptions: any;
	public autofillControls: {};
	public unrecognizedCountry = false;

	private accountAddressApplied = false;


	constructor(
		public apiService: ApiService,
		private authService: AuthService,
		public configurationService: ConfigurationService
	) {
		this.regionOptions = [];

		this.autofillControls = {
			state: {
				control: new UntypedFormControl(),
				options: 'regionOptions',
				filteredOptions: null,
				listKey: 'display',
				listId: 'id',
			},

			country: {
				control: new UntypedFormControl(),
				options: 'countryList',
				filteredOptions: null,
				listKey: 'name',
				listId: 'iso',
			},
		};
	}

	public ngOnInit(): void {
		this.countryList = countries;
		this.indexStates(provinces);
		this.updateRegionOptions(this.model.country);
		this._fetchDefaultAddress();

		// Note: Running these without a timeout causes the second to fail.
		this._setupAutofill('country');
		setTimeout(() => {
			this._setupAutofill('state');
		});

		this._setupStateCountry();
	}

	public ngAfterViewInit() {
		this._setupEmailInput();
	}

	/**
	 * Is a an input excluded.
	 *
	 * @since 1.13.0
	 *
	 * @param  name Name of input.
	 * @return      Whether or not the input is excluded.
	 */
	public isDisabled(name: string): boolean {
		return -1 !== this.excludedInputs.indexOf(name);
	}

	/**
	 * Callback to format the countries displayed value.
	 *
	 * @since 1.13.0
	 *
	 * @param  option Selected Option.
	 * @return        UI Country name.
	 */
	public displayCountry(option: string): string {
		let label = '';

		if (option) {
			label = countries.find((country) => country.iso === option).name;
		}

		return label;
	}

	/**
	 * Return the text used for state field.
	 *
	 * @since 1.0.0
	 *
	 * @return Label.
	 */
	public getStateLabel() {
		return 'US' === this.model.country ||
			'United States' === this.model.country
			? 'State'
			: 'State/Region/Province';
	}

	/**
	 * Check if the contact form is valid.
	 *
	 * @since 1.0.0
	 *
	 * @return Whether or not the contact form is valid.
	 */
	public isFormValid() {
		if (this.minimalForm) {
			return (
				this.firstName.valid &&
				this.lastName.valid &&
				this.phoneNumber.valid &&
				this.address1.valid &&
				this.postalCode.valid &&
				this.isZipValid() &&
				this.isValidCountry()
			);
		}
		return (
			this.firstName.valid &&
			this.lastName.valid &&
			this.phoneNumber.valid &&
			this.address1.valid &&
			this.city.valid &&
			this.postalCode.valid &&
			this.autofillControls['state'].control.valid &&
			this.isZipValid() &&
			this.isValidCountry()
		);
	}

	/**
	 * Check the current modal postal code is valid.
	 *
	 * Will return true if it the county code does not have a matching regex.
	 *
	 * @since 1.0
	 *
	 * @return Whether or not the postcode is valid.
	 */
	public isZipValid(): boolean {
		const countryCode = this.model.getCountryCode(this.model.country);

		let postcodeValid = false;
		try {
			postcodeValid = postcodeValidator(
				this.model.postalCode,
				countryCode
			);
			this.unrecognizedCountry = false;
		} catch {
			// Country Code is not recognized.
			postcodeValid = true;
			this.unrecognizedCountry = true;
		}

		return postcodeValid;
	}

	/**
	 * Validate the zip input. This will determine if the input should show an error message.
	 *
	 * @since 1.0
	 *
	 * @param  input Input from the form.
	 * @return       Is the input invalid?
	 */
	public invalidZipInput(input: any): boolean {
		return !!(
			this.invalidInput(input) ||
			(!this.isZipValid() && this.model.postalCode)
		);
	}

	/**
	 * Is an input in an invalid state?
	 *
	 * @since 1.0
	 *
	 * @param  input Input from the form.
	 * @return       Is the input invalid?
	 */
	public invalidInput(input: any): boolean {
		const dirty =
			(input.dirty && input.touched) || this.updateStatus === 'failed';
		return dirty && !input.valid && this.updateStatus !== 'submitted';
	}

	/**
	 * Is a generic input in an invalid state?
	 *
	 * @since 1.0
	 *
	 * @param  input Input from the form.
	 * @return       Is the input invalid?
	 */
	public invalidInputState(input: any): boolean {
		return !input.valid && this.invalidInput(input);
	}

	/**
	 * Is the country name valid.
	 *
	 * @since 1.26.0
	 */
	public isValidCountry() {
		return !!this.model.getCountryCode(this.model.country);
	}

	/**
	 * Bind listeneres to the email input.
	 *
	 * Update the copntact form after login.
	 *
	 * @since 1.13.0
	 */
	private _setupEmailInput(): void {
		if (!this.isDisabled('email')) {
			this.emailInput.onLogin.subscribe((data) => {
				this.updateForm(data);
				this._fetchDefaultAddress(data);
			});

			this.emailInput.onLogout.subscribe(() => {
				// Reset the form if we filled out the address of a logged in user.
				if (this.accountAddressApplied || this.forceLogin) {
					this.model = new ContactModel();
					this.autofillControls['state'].control.setValue(
						this.model.state
					);
					this.autofillControls['country'].control.setValue(
						this.model.country
					);
					this.accountAddressApplied = false;
				}
			});
		}
	}

	/**
	 * After fetcing default address, update model.
	 *
	 * @since 1.13.0
	 *
	 * @param results API Results.
	 */
	private _doneFetching(results: any): void {
		this.updateRegionOptions(results.address.country);
		this.model.update(results.address);
		this.autofillControls['state'].control.setValue(results.address.state);
		this.autofillControls['country'].control.setValue(
			results.address.country
		);
		this.accountAddressApplied = true;
	}

	private _fetchDefaultAddress(data: any = {}): void {
		// Only prefill the address if the user is logged in.
		if (!this.authService.isLoggedIn()) {
			return;
		}

		this.status = 'fetching';

		this.apiService.post('billingGetDefault', {}).subscribe(
			(response) => {
				if (
					response['result'] &&
					response['result'].data &&
					response['result'].data.success
				) {
					this._doneFetching(response['result'].data);
				}
			},
			() => (this.status = 'done'),
			() => {
				this.status = 'done';
				this.updateForm(data);
			}
		);
	}

	private indexStates(response: any): void {
		const states = {};
		for (const value of response.default) {
			states[value['country']] = states[value['country']] || [];
			value['id'] = value['short'] || value['english'] || value['name'];
			value['display'] = value['english'] || value['name'];
			states[value['country']].push(value);
		}

		this.states = states;
	}

	private updateRegionOptions(event: any): void {
		if (this.model.country !== event) {
			this.model.state = '';
		}

		// Find the counrties code, remap to event
		const longNameGiven = this.countryList.find(
			(country: object) => country['name'] === event
		);
		event = longNameGiven ? longNameGiven.iso : event;

		this.regionOptions =
			event && this.states ? this.states[event] || [] : [];
	}

	/**
	 * Setup linking between the state chosen and the country.
	 *
	 * @since 1.13.0
	 */
	private _setupStateCountry(): void {
		this.autofillControls['country'].control.valueChanges.subscribe(
			(value: string) => {
				this.updateRegionOptions(value);
			}
		);
		this.autofillControls['state'].filteredOptions = this.autofillControls[
			'country'
		].control.valueChanges.pipe(
			startWith(''),
			map(() =>
				this._filterAutofill(
					this.autofillControls['state'],
					this.model.state || ''
				)
			)
		);
	}

	/**
	 * Udpate the available options based on typed text.
	 *
	 * @since 1.13.0
	 *
	 * @param  controlName Control Name.
	 */
	private _setupAutofill(controlName: string): void {
		const control = this.autofillControls[controlName];

		control.control.setValue(this.model[controlName] || '');

		control.filteredOptions = control.control.valueChanges.pipe(
			startWith(''),
			map((value: string) => this._filterAutofill(control, value))
		);

		control.control.valueChanges.subscribe((value: string) => {
			this.model[controlName] = value;
		});
	}

	/**
	 * Filter the autofill list.
	 *
	 * @since 1.13.0
	 *
	 * @param  control Form Control element.
	 * @param  value   Value from form.
	 * @return         Updated Array.
	 */
	private _filterAutofill(control: object, value: string): string[] {
		const filterValue = (value || '').toLowerCase().trim();

		return this[control['options']].filter(
			(option: any) =>
				!filterValue ||
				option[control['listKey']]
					.toLowerCase()
					.includes(filterValue) ||
				option[control['listId']].toLowerCase().includes(filterValue)
		);
	}

	/**
	 * Prepopulate form during signup
	 *
	 * @param data
	 */
	public updateForm(data: any = {}) {
		if (this.forceLogin) {
			if (data?.user) {
				this.model.update({
					first_name: data.user.displayName.split(' ')[0],
					last_name: data.user.displayName.split(' ')[1],
					phone: data.user.phoneNumber
				});
			} else {
				this.model.update({
					first_name: this.authService.profile.data.display_name.split(' ')[0],
					last_name: this.authService.profile.data.display_name.split(' ')[1]
				});
			}
		}
	}

	/**
	 * Auto identify city/state
	 *
	 * @returns
	 */
	public async setAddress() {
		const key = this.apiService.configService.config.googleMaps.publicKey;
		const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.model.postalCode}, ${this.model.country}&sensor=true&key=${key}`
		await new Promise((resolve) => {
			this.apiService.http.get(url).subscribe({
				next: (address: any) => {
					if (address.results.length) {
						address.results[0].address_components.forEach(component => {
							const type = component.types[0];
							if ((type === 'sublocality_level_1' || type === 'locality' || type === 'administrative_area_level_2')) {
								this.model.city = component.long_name.trim();
							}
							if (type === 'administrative_area_level_1') {
								this.model.state = component.long_name.trim();
							}
						});

						if (!this.model.city || !this.model.state) {
							this.unrecognizedCountry = true;
						}
						resolve(this.model);
					}
				},
				error: (error) => {
					console.log(error)
				},
			});
		});

		return this.model;
	}
}
