<mat-progress-bar *ngIf="this.state !== 'success' && this.state !== 'blocked' && this.state !== 'failed'"
	mode="indeterminate" color="primary"></mat-progress-bar>

<failed-request *ngIf="this.state === 'failed'"></failed-request>

<div *ngIf="this.state !== 'blocked' && this.state !== 'failed'"
	class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8 ctw-px-8 ctw-mb-12">
	<h2>Site URL</h2>
	<dash-card cardTitle="Change Domain" icon="language" [disabled]="true" class="ctw-mb-8">
		<mat-progress-bar *ngIf="this.state !== 'success' && this.state !== 'blocked'" mode="indeterminate"
			color="primary"></mat-progress-bar>
		<central-apply-domain (applyDomainReady)="this.state = 'success'"
			dnsView="change-domain"></central-apply-domain>
	</dash-card>
	<central-auto-ssl *ngIf="this.hasChangedDomain"></central-auto-ssl>
</div>
