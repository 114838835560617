import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
	ApiService,
	AuthService,
	ProfileService,
	ConfigurationService,
} from '@central/ng-shared';
import { TeamCreateDialogComponent } from './dialogs/create-team-dialog/create-team-dialog.component';
import { LeaveTeamDialogComponent } from './dialogs/leave-team-dialog/leave-team-dialog.component';

@Component({
	selector: 'central-team',
	templateUrl: './team.component.html',
	styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnInit {
	public status = 'loading';
	public teams: any[] = [];

	constructor(
		public apiService: ApiService,
		public authService: AuthService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		public configurationService: ConfigurationService
	) {}

	ngOnInit(): void {
		this.fetchTeams();
	}

	private fetchTeams() {
		this.apiService.get('/v1/organizations/memberships', {}).subscribe({
			next: (teams: any[]) => {
				this.teams = teams || [];

				teams.map((team) => {
					team.role = team.members.find(
						(element) =>
							element.account.account_id ===
							this.authService.getAccountId()
					).role;
				});

				this.status = 'success';
			},
			error: (error) => {
				this.status = 'failed';
			},
		});
	}

	public openCreateDialog() {
		const dialogRef = this.dialog.open(TeamCreateDialogComponent, {
			maxWidth: '500px'
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.fetchTeams();

                    this.snackBar.open('Team Created', '', {
                        duration: 4000,
                    });
				}
			},
		});
    }
    
    public openLeaveDialog(team) {
		const dialogRef = this.dialog.open(LeaveTeamDialogComponent, {
			maxWidth: '500px',
			data: {
				teamId: team.organization_id || this.getPrivateTeam(team),
				teamName: team.name,
				membershipId: this.getMembershipId(team),
				canLeave: this.isSoleOwner(team),
				isPrivate: team.account_id ? true : false,
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.fetchTeams();

					this.snackBar.open('Left Team', '', {
						duration: 4000,
					});
				}
			},
		});
	}

	getRoleLabel(role: string) {
		return (
			this.configurationService.config.groupPermissions[role]?.label || ''
		);
	}

	// private openDeleteDialog( team ) {
	// stub for future implementation
	// }

	private getPrivateTeam(team): string {
		return this.authService.profile.data.account_access.filter(
			(account) => (
					account.type === 'user' &&
					account.role === 'maintainer' &&
					account.display_name === team.name &&
					team.account_id
				)
		)[0].account_id;
	}

	private getMembershipId(team): string {
		return team.members.filter(
			(member) =>
				member.account.account_id === this.authService.getAccountId()
		)[0].membership_id;
	}

	private isSoleOwner(team): boolean {
		const ownerCount = team.members.filter(
			(member) => member.role === 'owner'
		).length;
		if (team.role === 'owner' && ownerCount === 1) {
			return false;
		}
		return true;
	}
}
