import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'central-contextual-help-dialog',
	templateUrl: './contextual-help-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextualHelpDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public sanitizer: DomSanitizer
	) {}

	sanitizeUrl(url): SafeHtml {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
