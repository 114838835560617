import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from '../core';
import { AuthService } from '../authentication/services';

@Injectable()
export class AccountService {
	public authAccountUpdate = new EventEmitter();
	public id;
	public account;
	public access;
	public roles = {
		read: ['owner', 'maintainer', 'member'],
		write: ['owner', 'maintainer'],
		manage: ['owner'],
	};

	private messageSource = new BehaviorSubject('default message source');
	currentMessage = this.messageSource.asObservable();

	constructor(
		public apiService: ApiService,
		public authService: AuthService
	) {}

	public fetch(accountId: string) {
		return new Observable((observer) => {
			if (this.account && this.account.id === accountId) {
				observer.next(this.account);
				observer.complete();
			} else {
				this.apiService.get('/v1/accounts/' + accountId, {}).subscribe(
					(account) => {
						this.id = accountId;
						this.account = account;

						observer.next(this.account);
						observer.complete();
					},
					(error) => {
						this.account = null;
						this.id = null;
						observer.error(error);
					}
				);
			}
		});
	}

	public getResourceId() {
		return this.id;
	}

	public isUserAccountOwner() {
		// eslint-disable-next-line eqeqeq
		return this.id == this.authService.getAccountId();
    }

	public updateAccount(updateAccount) {
		this.account = updateAccount;
		this.messageSource.next(updateAccount);
	}

    public isOrganization() {
		return !!this.account.organization_id;
    }

    public isUserOrganization() {
        return this.authService.profile.data.type === 'org'
    }

	public hasAccess(action) {
		const currentAccount =
			this.authService.profile.data.account_access.find(
				(account) => account.account_id === this.getResourceId()
			);

		return -1 !== this.roles[action].indexOf(currentAccount?.role);
	}
}
