import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, ConfigurationService } from '../../core';
import { ProfileService } from '../../authentication/services';
import { Location } from '@angular/common';
import { ZendService } from '../zendesk/zend.service';
import { MessageComponent } from '../message/message.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SupportService } from '../support.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'central-ticket',
	templateUrl: './ticket.component.html',
	styleUrls: ['./ticket.component.scss', '../ticket-view.scss'],
})
export class TicketComponent implements OnInit {
	@ViewChild('replyInput') public replyInput: ElementRef;

	public status = 'loading';
	public errorMessage: string;
	public ticketId: number;
	public ticket: any;
	public newComment: string;
	public cloudId: string;
	public dialogRef: MatDialogRef<MessageComponent>;

	constructor(
		private route: ActivatedRoute,
		public apiService: ApiService,
		public zendService: ZendService,
		public supportService: SupportService,
		public location: Location,
		public dialog: MatDialog,
		public profileService: ProfileService,
		public configService: ConfigurationService
	) {}

	ngOnInit(): void {
		this.route.params.subscribe((routeParams) => {
			this.ticketId = routeParams.id;
		});

		this.cloudId = this.supportService.getCloud()
			? this.supportService.getCloud().id
			: null;

		this.fetchTicket();
	}

	public fetchTicket() {
		const account = this.supportService.getAccount();
		const organization_id = account.organization_id
			? account.organization_id
			: account.account_id;
		this.zendService
			.ticketInfo(organization_id, this.ticketId)
			.subscribe((ticket_info: any[]) => {
				this.ticket = ticket_info['ticket'];
				this.ticket.comments = ticket_info['comments'];
				this.status = 'complete';
			});
	}

	public focusReply() {
		if (
			this.replyInput.nativeElement &&
			this.replyInput.nativeElement.scrollIntoView
		) {
			this.replyInput.nativeElement.scrollIntoView();
		}

		if (this.replyInput.nativeElement) {
			this.replyInput.nativeElement.focus();
		}
	}

	public addReply() {
		const account = this.supportService.getAccount();
		this.errorMessage = '';
		this.status = 'submitted';

		this.zendService
			.addReply(account, {
				comment: this.newComment,
				ticket_id: this.ticketId,
			})
			.subscribe({
				complete: () => {
					this.newComment = '';
					this.fetchTicket();
				},
				error: () => {
					this.errorMessage =
						'Unable to submit ticket. Please try again later.';
				},
			});
	}
	public openDialog(userSetting = null) {
		this.dialogRef = this.dialog.open(MessageComponent, {
			width: '600px',
			data: {
				userSetting,
				cloudId: this.cloudId,
				cloud: this.supportService.getCloud(),
				priorities: this.supportService.ticketPriorities,
				typeOptions: this.configService.config.zendeskTicketTypes,
				selectedType: this.configService.config.zendeskDefaultType,
				inputs: this.configService.config.zendesk.inputs,
				account: this.supportService.getAccount(),
			},
		});

		this.dialogRef.componentInstance.submitTicket.subscribe((ticket_data) => {
			this.submitTicket(ticket_data);
		});
	}
	public submitTicket(ticket_data) {
		this.dialogRef.componentInstance.state = 'submitted';
		this.supportService.submitTicket(ticket_data)
			.subscribe({
				next: (val) => {
					if (val.hasOwnProperty('ticket_id')) {
						this.dialogRef.componentInstance.state = 'success';
					} else {
						this.dialogRef.componentInstance.state = 'failed';
						if (400 === val['status']) {
							this.dialogRef.componentInstance.state = 'ratelimited';
						}
					}
				},
				error: () => {
					this.dialogRef.componentInstance.state = 'failed';
				},
			});
	}
}
