export default [
	{
		label: 'Crio - Premium SuperTheme',
		type: 'separator',
		helpLink: 'https://www.boldgrid.com/crio-wordpress-theme-framework/',
	},
	{
		label: 'Full Blog Customization',
	},
	{
		label: 'Advanced Header Display',
	},
	{
		label: 'Additional Menu Styles',
	},
	{
		label: 'Advanced Customizer Controls',
	},
];
