import { Component, Inject } from '@angular/core';

import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
@Component({
	selector: 'central-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
	title = 'Confirm Deletion';
	message = 'Are you sure want to delete?';
	confirmButtonText = 'Continue';
	cancelButtonText = 'Cancel';
	confirmButtonColor = 'primary';
	constructor(
		@Inject(MAT_DIALOG_DATA)
		private data: any,
		private dialogRef: MatDialogRef<ConfirmationDialogComponent>
	) {
		if (data) {
			this.message = data.message || this.message;
			this.title = data.title || this.title;
			this.confirmButtonColor = data?.important
				? 'warn'
				: this.confirmButtonColor;
			if (data.buttonText) {
				this.confirmButtonText =
					data.buttonText.ok || this.confirmButtonText;
				this.cancelButtonText =
					data.buttonText.cancel || this.cancelButtonText;
			}
		}
	}

	onConfirmClick(): void {
		this.dialogRef.close(true);
	}
}
