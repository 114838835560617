import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import intlTelInput from 'intl-tel-input';
import { ConfigurationService } from '../../core';

@Component({
	selector: 'central-phone-number',
	templateUrl: './phone-number.component.html',
	styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent implements AfterViewInit {
	@ViewChild('phone') public phoneInput;
	@Input() public value;
	@Input() public isRequired = false;

	public iti;
	public errorMessage;

	constructor(public configurationService: ConfigurationService) {}

	public ngAfterViewInit() {
		this.setupPhone();
	}

	public setupPhone() {
		this.iti = intlTelInput(this.phoneInput.nativeElement, {
			utilsScript:
				'https://inmotionhosting.github.io/static-assets/js/phone-utils.js',
		});

		this.iti.setNumber(this.value || '');
	}

	public getNumber() {
		return this.iti.getNumber(
			window['intlTelInputUtils'].numberFormat.E164
		);
	}

	public isValid() {
		this.errorMessage = '';

		if (!this.isRequired && !this.getNumber()) {
			this.errorMessage = 'A phone number is required.';
			return true;
		}

		if (!this.iti.isValidNumber()) {
			this.errorMessage =
				'Please enter a valid phone number including area code.';
		}

		return this.iti.isValidNumber();
	}
}
