export default {
	name: 'speedcoach-tier-3-yearly',
	productKeys: ['speedc_tier3'],
	licenseNames: [],
	label: '3600 Scans / Month',
	color: 'primary',
	tags: ['speedcoach', 'premium'],
	features: [
		{
			label: 'Speed Coach Pro',
			type: 'separator',
		},
		{
			label: '3600 Additional Scans',
		},
	],
};
