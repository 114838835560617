import { Component } from '@angular/core';

@Component({
	selector: 'central-create-snapshot-dialog',
	templateUrl: './create-snapshot-dialog.component.html',
	styleUrls: ['../password-reset/password-reset.component.scss'],
})
export class CreateSnapshotDialogComponent {
	snapshotName = '';
	placeholder = 'Snapshot Name';
	status = 'pending';

	constructor() {}
}
