import { Component, Inject, OnInit } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ApiService } from '../../../core';
import { TrackingService } from '../../../tracking';
import { ConfigurationService } from '../../../core';

@Component({
	selector: 'central-add-member-dialog',
	templateUrl: './add-member-dialog.component.html',
	styleUrls: ['./add-member-dialog.component.scss'],
})
export class AddMemberDialogComponent implements OnInit {
	public email = '';
	public state = 'pending';
	public role = 'member';
	public permissions = [];

	constructor(
		private apiService: ApiService,
		private trackingService: TrackingService,
		public configurationService: ConfigurationService,
		public dialogRef: MatDialogRef<AddMemberDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {
		if (this.data.email) {
			this.email = this.data.email;
		}

		this.permissions = this.sortPermissions();
		this.filterPermissions();
	}

	filterPermissions() {
		// if the user is not an owner, remove the owner permission
		if (this.data.currentRole !== 'owner') {
			this.permissions = this.permissions.filter(
				(permission) => permission.role !== 'owner'
			);
		}
	}

	sortPermissions() {
		const permissions = [];
		const defaultPermission = ['owner', 'maintainer', 'member'];
		defaultPermission.forEach((role) => {
			if (this.configurationService.config.groupPermissions[role]) {
				permissions.push({
					role,
					...this.configurationService.config.groupPermissions[role],
				});
			}
		} );

		const customPermissions = [];
		for (const role in this.configurationService.config.groupPermissions) {
			if (!defaultPermission.includes(role)) {
				customPermissions.push({
					role,
					...this.configurationService.config.groupPermissions[role],
				});
			}
		}

		return [ ...permissions,  ...customPermissions ];
	}

	addMember() {
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl(
			`/v1/${this.data.resourceType}/` +
				this.data.resourceId +
				'/memberships'
		);

		this.state = 'submitted';

		this.apiService.http
			.post(
				url,
				{
					email: this.email,
					role: this.role,
				},
				{ headers }
			)
			.subscribe({
				next: () => {
					this.state = 'success';
					this.dialogRef.close();
					this.trackingService.track('memberInvite');
				},
				error: () => {
					this.state = 'failed';
				},
			});
	}
}
