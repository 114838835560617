import { Component, Input } from '@angular/core';
import { BrandingService } from '@central/ng-shared';
import { Config } from '../../shared/config/env.config';
import { Post } from '../../shared/forms/post';

@Component({
	selector: 'post-purchase',
	templateUrl: 'post-purchase.component.html',
	styleUrls: ['post-purchase.component.scss'],
})
export class PostPurchaseComponent {
	public config: any;
	public post = new Post();

	public productTypes = [
		{
			name: 'key',
			label: 'Connect Key',
			icon: 'vpn_key',
		},
		{
			name: 'service',
			label: 'BoldGrid Services',
			icon: 'room_service',
		},
		{
			name: 'crio',
			label: 'Crio SuperTheme',
			icon: 'palette',
		},
		{
			name: 'cloud-wordpress',
			label: 'Cloud WordPress',
			icon: 'cloud',
		},
		{
			name: 'speedcoach',
			label: 'Speed Coach',
			icon: 'record_voice_over',
		},
		{
			name: 'coin',
			label: 'Copyright Coins',
			icon: 'monetization_on',
		},
		{
			name: 'theme',
			label: 'Themes',
			icon: 'save_alt',
		},
		{
			name: 'plugin',
			label: 'Plugins',
			icon: 'save_alt',
		},
		{
			name: 'asset',
			label: 'Downloads',
			icon: 'save_alt',
		},
	];

	@Input() public account: any;
	@Input() public project: any;
	@Input() public receipt: any[] = [];
	@Input() public products: any[] = [];
	@Input() public returnWpUrl: string;
	@Input() public paymentPage = true;

	private customUIProducts = ['crio', 'speedcoach', 'cloud-wordpress'];

	public constructor(public brandingService: BrandingService) {
		this.config = Config;
	}

	/**
	 * Does the product have a custom UI?
	 *
	 * @since 1.23.0
	 *
	 * @param  serviceCategory Product Name.
	 * @return                 Has CustomUI?
	 */
	public hasCustomUI(serviceCategory: string) {
		return this.customUIProducts.includes(serviceCategory);
	}

	public isInvoice(receipt): boolean {
		return !!receipt.invoice_number;
	}

	public isType(product: object, productType: string): boolean {
		let isType = false;

		if (product['subtype']) {
			if (product['subtype'] === productType) {
				isType = true;
			}
		} else if (product['type'] === productType) {
			isType = true;
		}

		return isType;
	}

	public isAssetType(productType: string): boolean {
		return -1 !== ['plugin', 'theme', 'asset'].indexOf(productType);
	}

	public getProductDetails(productName: string) {
		return this.products.find((val: any) => val.name === productName);
	}

	public getPurchaseRoute() {
		let returnUrl = '/';

		this.products.forEach((item) => {
			switch (item.label) {
				case 'Domain Registration':
					returnUrl = '/domains';
					break;
				case 'Email Account':
					returnUrl = '/email';
					break;
				case 'Connected Website - Monthly':
					returnUrl = '/projects/' + this.project;
					break;
				case 'Connected Website - Yearly':
					returnUrl = '/projects/' + this.project;
					break;
			}
		});

		return returnUrl;
	}

	public getCoinBalance() {
		let balance = 0;
		for (const product of this.products) {
			if (this.isType(product, 'coin')) {
				balance = product.purchased_item.new_balance;
				break;
			}
		}

		return balance;
	}

	/**
	 * Get the activation URL.
	 *
	 * @since 1.21.0
	 *
	 * @param  connectKey Connect Key Object.
	 * @return            URL to wordpress.
	 */
	public addToWordpress(connectKey: any) {
		this.post.postForm(this.returnWpUrl, {
			activateKey: connectKey.purchased_item.api_key,
		});
	}

	/**
	 * Count the number of upgraded products by type.
	 *
	 * @since 1.13.0
	 *
	 * @param  productType Product Type.
	 * @return             Count of products.
	 */
	public countUpgradeByType(productType: string): number {
		return this.products.filter((product: any) => (
				this.isType(product, productType) &&
				'upgrade' === product.purchase_type
			)).length;
	}

	/**
	 * Is the given product type included in the list of purchased products.
	 *
	 * @since 1.13.0
	 *
	 * @param  productType Product Type to test.
	 * @return             Whether or not the product type was purchased.
	 */
	public countTypePurchased(productType: string): number {
		return this.products.filter(
			(product: any) =>
				(this.isType(product, productType) &&
					!this.hasCustomUI(product.service_category)) ||
				(this.hasCustomUI(product.service_category) &&
					product.service_category === productType)
		).length;
	}
}
