import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	Input,
	OnDestroy,
	ElementRef,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InfiniteLoad } from '../infinite-load';

@Component({
	selector: 'theme-selector',
	templateUrl: './theme-selector.component.html',
	styleUrls: ['./theme-selector.component.scss'],
})
export class ThemeSelectorComponent implements OnInit, OnDestroy {
	@Output() public selectThemes = new EventEmitter();
	@Input() public selectedThemes: object[] = [];
	@Input() public action: object;
	@Input() public allowedFilters = [
		'featured',
		'popular',
		'latest',
		'custom',
	];
	@Input() public steps = ['next', 'back'];

	public infiniteLoad = new InfiniteLoad();
	public response = {};
	public status = 'loading';
	public view = 'search';
	public currentPage = 1;
	public request = '';
	public filters = [
		{
			label: 'Featured',
			name: 'featured',
			default: false,
			search: '&request[browse]=featured',
		},
		{
			label: 'Popular',
			name: 'popular',
			search: '&request[browse]=popular',
			default: true,
		},
		{
			label: 'Latest',
			name: 'latest',
			default: false,
			search: '&request[browse]=new',
		},
		{
			label: 'Custom URL',
			name: 'custom',
			default: false,
			search: '',
		},
	];

	private apiCall =
		'https://api.wordpress.org/themes/info/1.1/?action=query_themes';

	constructor(public http: HttpClient, private elRef: ElementRef) {
		this.infiniteLoad.load.subscribe(() => {
			if (this.view !== 'customURL' && this.status !== 'loading') {
				if (
					'visible' ===
					getComputedStyle(this.elRef.nativeElement).visibility
				) {
					if (this.currentPage < this.response['info'].pages) {
						this.currentPage++;
						this.fetch(this.request);
					}
				}
			}
		});
	}

	public ngOnInit(): void {
		this.infiniteLoad.setup();
		this.filters = this.filters.filter((filter: any) =>
			this.allowedFilters.includes(filter.name)
		);
		this.search(this.filters.find((val) => val.default));
	}

	public ngOnDestroy(): void {
		this.infiniteLoad.shutdown();
	}

	public remove(item: object): void {
		this.selectedThemes = this.selectedThemes.filter((val) => val !== item);
	}

	public isSelected(theme: object): boolean {
		return !!this.selectedThemes.find(
			(val: object) => val['slug'] === theme['slug']
		);
	}

	public search(filter: object): void {
		this.currentPage = 1;
		if ('custom' !== filter['name']) {
			this.fetch(filter['search']);
			this.view = 'search';
		} else {
			this.view = 'customURL';
		}
	}

	public select(theme): void {
		this.selectedThemes = [theme];
	}

	public onNext(): void {
		this.selectThemes.emit(this.selectedThemes);
	}

	public fetch(request: string): void {
		this.request = request;
		this.status = 'loading';

		request = request + `&request[page]=${this.currentPage}`;

		this.http.get(this.apiCall + request).subscribe(
			(response) => {
				if (response['themes']) {
					if (this.currentPage !== 1) {
						this.response['info'] = response['info'];
						this.response['themes'] = this.response[
							'themes'
						].concat(response['themes']);
					} else {
						this.response = response;
					}

					this.status = 'success';
				} else {
					this.status = 'failed';
				}
			},
			() => (this.status = 'failed')
		);
	}
}
