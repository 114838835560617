<h3>Name your {{ this.type }} Project</h3>
<form class="project-details" (ngSubmit)="this.save()">
	<mat-form-field>
		<mat-label>Name</mat-label>
		<input name="project-name" matInput [(ngModel)]="this.projectService.appService.project.label" />
	</mat-form-field>
	<div class="action-buttons ctw-text-right">
		<button type="button" [mat-dialog-close]="this.status === 'success'" [disabled]="state === 'submitted'"
			mat-button (click)="this.reset()">
			Cancel
		</button>
		<central-progress-button [mat-dialog-close]="this.status === 'success'" [state]="this.status"
			color="primary">Update</central-progress-button>
	</div>
</form>
