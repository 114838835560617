<dash-card cardTitle="Updates" icon="update" class="dash-card dash-card-updates" disabled="true">
	<div class="dash-card-content" *ngIf="!this.hasUpdates">
		<div class="task-complete">
			<span class="status-icon" inlineSVG="./assets/temp/cloud-done.svg"></span>
			<p>Everything is up to date</p>
			<p>The latest and greatest is up and running!</p>
		</div>
	</div>
	<div *ngIf="this.hasUpdates">
		<div class="update-info">
			<div class="ctw-text-center ctw-mb-5">
				The following items are out of date.
				<a (click)="
						this.projectService.login('wp-admin/update-core.php')
					"><strong>Update In WordPress</strong></a>
			</div>

			<div class="ctw-mb-5" *ngIf="this.hasWpUpdate()">
				<h3>WordPress</h3>
				<ul>
					<li>
						WordPress -
						{{
						this.stats['debug']['wp-core']['fields']['version'][
						'value'
						]
						}}
					</li>
				</ul>
			</div>
			<div class="ctw-mb-5" *ngIf="this.updateThemes.length">
				<h3>Themes</h3>
				<ul>
					<ng-container *ngFor="let theme of this.updateThemes">
						<li *ngIf="theme.version">
							{{
							stripSlug(theme.name.value) +
							' - ' +
							theme.version.value
							}}
						</li>
						<li *ngIf="!theme.version">
							{{ stripSlug(theme.label) + ' - ' + theme.value }}
						</li>
					</ng-container>
				</ul>
			</div>
			<ng-container *ngIf="this.updatePlugins.length">
				<h3>Plugins</h3>
				<ul>
					<ng-container *ngFor="let plugin of this.updatePlugins">
						<li *ngIf="plugin.version">
							{{
							stripSlug(plugin.name.value) +
							' - ' +
							plugin.version.value
							}}
						</li>
						<li *ngIf="!plugin.version">
							{{ stripSlug(plugin.label) + ' - ' + plugin.value }}
						</li>
					</ng-container>
				</ul>
			</ng-container>
		</div>
	</div>
</dash-card>
