import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'bgc-snap-view',
	templateUrl: './view.component.html',
	styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit {
	@Input() snapId: string;
	@Input() snap: object;
	@Input() currentEnv = 0;
	@Input() includeActions = true;

	public status = 'pending';

	constructor(public apiService: ApiService) {}

	public ngOnInit(): void {
		if (this.snapId) {
			this.fetchSnap();
		} else {
			this.status = 'success';
		}
	}

	private fetchSnap(): void {
		this.status = 'loading';
		this.apiService.get('getSnap', { id: this.snapId }).subscribe(
			(response) => {
				this.snap = response;
				this.status = 'success';
			},
			() => (this.status = 'failed')
		);
	}
}
