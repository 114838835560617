import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'central-site-setup-warn-dialog',
	templateUrl: './site-setup-warn-dialog.component.html',
	styleUrls: ['./site-setup-warn-dialog.component.css'],
})
export class SiteSetupWarnDialogComponent {
	@Output() skipWarn = new EventEmitter();

	public failures = [];
	public warnings = [];

	constructor() {}

	getAllErrors() {
		return this.failures.concat(this.warnings);
	}

	skipWarnings() {
		this.skipWarn.emit(true);
	}
}
