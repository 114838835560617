<div
	class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray"
>
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<h2>Notifications</h2>
	</div>

	<mat-progress-bar
		*ngIf="status !== 'success'"
		mode="indeterminate"
		color="primary"
	></mat-progress-bar>
</div>

<failed-request *ngIf="'failed' === status"></failed-request>

<ng-container *ngIf="status === 'success'">
	<div
		class="content animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl"
	>
		<mat-card
			*ngIf="status === 'success' && notifications?.length === 0"
			class="no-messages center-align"
		>
			<div class="info">
				<mat-icon class="ctw-overflow-visible"
					>notifications_none</mat-icon
				>
				<h2>No Notifications</h2>
			</div>
		</mat-card>
		<mat-card
			class="ctw-flex"
			*ngIf="status === 'success' && notifications?.length"
		>
			<div class="list-options">
				<ul>
					<li
						*ngFor="let message of notifications; let i = index"
						mat-ripple
						[class.active]="i === this.activeIndex"
						[class.unread]="message?.has_read === false"
						[ngClass]="message.display_type"
						(click)="readNotification(i)"
					>
						<p class="title">{{ message.subject }}</p>
						<p>To: {{ getToName(message.account_id) }}</p>
						<p>
							Date:
							{{ message.date_sent | amDateFormat : 'LL HH:mm' }}
						</p>
					</li>
				</ul>
			</div>
			<div class="notification">
				<h4 class="notification-title">
					{{ notifications[activeIndex]?.subject }}
				</h4>
				<hr />
				<div class="notification-details">
					<span>{{
						notifications[activeIndex]?.date_sent
							| amDateFormat : 'LL HH:mm:ss'
					}}</span>
				</div>
				<div
					class="notification-body"
					[innerHTML]="notifications[activeIndex]?.body"
				></div>
			</div>
		</mat-card>
	</div>
</ng-container>
