<div class="dialog-header">
	<h6>Upgrade Existing Key?</h6>
</div>
<div class="dialog-content">
	<p>
		It looks like you have multiple Connect Keys under
		<strong>{{ this.authService.profile.data.account_name }}</strong
		>.
	</p>
	<p>
		If you would like to upgrade an existing Connect Key, please choose
		Premium Addon from the {{ brandingService.getAppName() }} Connect Keys
		page. Otherwise, continue to make this purchase on a new Connect Key.
	</p>
</div>
<div class="dialog-footer action-buttons ctw-text-right">
	<button
		color="primary"
		mat-button
		mat-raised-button
		(click)="this.matDialogRef.close()"
	>
		Continue
	</button>
	<button mat-button mat-raised-button (click)="this.goToDashboard()">
		Upgrade
	</button>
</div>
