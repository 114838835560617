import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'central-domain-add',
	templateUrl: './domain-add.component.html',
	styleUrls: ['./domain-add.component.scss'],
})
export class DomainAddComponent {
	public ownerAccountId = '';
	public isReady = false;

	constructor(public router: Router) {}

	public accountChanged($event) {
		setTimeout(() => {
			this.ownerAccountId = $event['account_id'];
			this.isReady = true;
		}, 0);
	}

	public domainAdded(event) {
		if (event.success) {
			this.router.navigate(['/domains']);
		}
	}
}
