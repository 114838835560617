import { Component, Inject, EventEmitter } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AutoBackup } from '../Settings/AutoBackup';

@Component({
	selector: 'app-backups-dialog-schedule',
	templateUrl: './backups-dialog-schedule.component.html',
	styleUrls: ['../dialog/dialog.scss'],
})
export class BackupsDialogScheduleComponent {
	public state = 'pending';
	public onComplete = new EventEmitter();

	constructor(
		private snackBar: MatSnackBar,
		public matDialogRef: MatDialogRef<BackupsDialogScheduleComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	public enable() {
		this.state = 'submitted';

		const schedule = new AutoBackup().enable(this.data.settings);
		this.data.projectService.client
			.namespace('bgbkup/v1')
			.settings()
			.create({ settings: { schedule } })
			.then((response) => {
				this.onComplete.emit(response);
				this.state = 'success';
				this.matDialogRef.close();
				this.snackBar.open('Autobackups enabled', '', {
					duration: 3000,
				});
			})
			.catch(() => (this.state = 'failed'));
	}
}
