import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	ViewChild,
	OnDestroy,
	Input,
	ElementRef,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PluginSheetComponent } from './plugin-sheet/plugin-sheet.component';
import { CustomUrlComponent } from '../custom-url/custom-url.component';
import { InfiniteLoad } from '../infinite-load';
import _each from 'lodash/each';

@Component({
	selector: 'plugin-selector',
	templateUrl: './plugin-selector.component.html',
	styleUrls: ['./plugin-selector.component.scss'],
})
export class PluginSelectorComponent implements OnInit, OnDestroy {
	@Input() public selectedItems = [];
	@Input() public action: object;
	@Input() public steps = ['next', 'back'];
	@Input() public allowedFilters = [
		'featured',
		'popular',
		'recommended',
		'custom',
	];
	@Output() public selectPlugins = new EventEmitter();
	@Output() public back = new EventEmitter();
	@ViewChild('customURL') public customURL: CustomUrlComponent;

	public infiniteLoad = new InfiniteLoad();
	public response = {};
	public status = 'loading';
	public view = 'search';
	public currentPage = 1;
	public request = '';

	public filters = [
		{
			label: 'Featured',
			name: 'featured',
			default: false,
			search: '&request[browse]=featured',
		},
		{
			label: 'Popular',
			name: 'popular',
			default: true,
			search: '&request[browse]=popular',
		},
		{
			label: 'Recommended',
			name: 'recommended',
			default: false,
			search: '&request[browse]=recommended',
		},
		{
			label: 'Custom URL',
			name: 'custom',
			default: false,
			search: '',
		},
	];

	private apiCall =
		'https://api.wordpress.org/plugins/info/1.1/?action=query_plugins' +
		'&request[fields][sections]=0&request[fields][icons]=1&request[fields][active_installs]=1&request[fields][description]=0';

	constructor(
		public http: HttpClient,
		private bottomSheet: MatBottomSheet,
		private elRef: ElementRef
	) {
		this.infiniteLoad.load.subscribe(() => {
			if (
				this.view !== 'customURL' &&
				this.status !== 'loading' &&
				this.elRef.nativeElement.offsetParent
			) {
				if (
					'visible' ===
					getComputedStyle(this.elRef.nativeElement).visibility
				) {
					if (this.currentPage < this.response['info'].pages) {
						this.currentPage++;
						this.fetch(this.request);
					}
				}
			}
		});
	}

	public ngOnInit(): void {
		this.infiniteLoad.setup();
		this.filters = this.filters.filter((filter: any) =>
			this.allowedFilters.includes(filter.name)
		);
		this.search(this.filters.find((val) => val.default));
	}

	public ngOnDestroy(): void {
		this.infiniteLoad.shutdown();
	}

	public search(filter: object): void {
		this.currentPage = 1;
		if ('custom' !== filter['name']) {
			this.fetch(filter['search']);
			this.view = 'search';
		} else {
			this.view = 'customURL';
		}
	}

	public onNext(): void {
		this.selectPlugins.emit(this.selectedItems);
	}

	public openSheet(): void {
		const pluginSheet = this.bottomSheet.open(PluginSheetComponent, {
			data: { plugins: this.selectedItems },
		});

		pluginSheet.instance.remove.subscribe((plugin) =>
			this.deselect(plugin)
		);
		pluginSheet.instance.next.subscribe(() => this.onNext());
	}

	public selection(item: object): void {
		this.selectedItems.push(item);
		this.selectedItems = Array.from(new Set(this.selectedItems));
	}

	public deselect(plugin: object): void {
		this.selectedItems = this.selectedItems.filter((val) => {
			if (plugin['slug']) {
				return val.slug !== plugin['slug'];
			} else if (plugin['name']) {
				return val.name !== plugin['name'];
			} else if (plugin['label']) {
				return val.label !== plugin['label'];
			}
		});

		this.customURL.removeURL(plugin);
	}

	public isSelected(plugin: object): boolean {
		return !!this.selectedItems.find(
			(val) => val && val.slug === plugin['slug']
		);
	}

	public decodeData(response: object) {
		const plugins = [];
		_each(response['plugins'], (plugin) => {
			plugin.name = this.decode(plugin.name);
			plugin.short_description = this.decode(plugin.short_description);
			plugins.push(plugin);
		});

		response['plugins'] = plugins;

		return response;
	}

	/**
	 * Decode plugin api field.
	 *
	 * @since 1.15.0
	 *
	 * @param  html Decodeable string.
	 * @return      Decoded string.
	 */
	public decode(html: string): string {
		const input = document.createElement('textarea');
		input.innerHTML = html;
		return input.value;
	}

	public addCustom(plugin: object): void {
		this.selectedItems.push(plugin);
	}

	public removeCustom(plugin: object): void {
		this.selectedItems = this.selectedItems.filter((val) => val !== plugin);
	}

	public getCount(installs: number): string {
		let activeInstallText = installs.toString();
		if (installs >= 1000000) {
			activeInstallText = activeInstallText[0] + '+ Million';
		} else {
			// Add commas to large numbers.
			activeInstallText = activeInstallText.replace(
				/\B(?=(\d{3})+(?!\d))/g,
				','
			);
		}

		return activeInstallText;
	}

	public getDate(date: string): number {
		return Date.parse(date.split(' ')[0]);
	}

	public fetch(request: string): void {
		this.request = request;
		this.status = 'loading';

		request = request + `&request[page]=${this.currentPage}`;

		if (this.currentPage === 1) {
			this.response['plugins'] = [];
		}

		this.http.get(this.apiCall + request).subscribe(
			(response) => {
				if (response['plugins']) {
					response = this.decodeData(response);
					if (this.currentPage !== 1) {
						this.response['info'] = response['info'];
						this.response['plugins'] = this.response[
							'plugins'
						].concat(response['plugins']);
					} else {
						this.response = response;
					}
					this.status = 'success';
				} else {
					this.status = 'failed';
				}
			},
			() => (this.status = 'failed')
		);
	}

	public getAuthorProfile(str) {
		return str.replace(/\/$/, '').split('/').pop();
	}
}
