import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { SharedModule } from '../../shared/shared.module';

import { SubscriptionComponent } from './index';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MomentModule } from 'ngx-moment';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

@NgModule({
	declarations: [SubscriptionComponent, ConfirmationDialogComponent],
	imports: [CommonModule, MomentModule, MatSnackBarModule, SharedModule],
	exports: [MatDialogModule],
})
export class SubscriptionModule {}
