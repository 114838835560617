<div
	class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray"
>
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<h2>Domain Management</h2>
		<a
			*ngIf="'succeeded' === requestState"
			href="https://{{ domainName }}"
			target="_blank"
			class="mat-stroked-button animate__animated animate__fadeIn ctw-text-imh-gray-200"
		>
			<mat-icon>language</mat-icon> {{ domainName }}
		</a>
	</div>
	<mat-progress-bar
		*ngIf="'loading' === requestState"
		mode="indeterminate"
		color="primary"
	></mat-progress-bar>
</div>
<div
	class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-pt-16 content domain-details"
>
	<failed-request *ngIf="'failed' === requestState"></failed-request>

	<ng-container *ngIf="'succeeded' === requestState">
		<dash-card cardTitle="Domain Details" icon="info" disabled="true">
			<div class="ctw-p-8">
				<div class="grid-container">
					<div>
						<p>
							Status:
							<mat-progress-spinner
								*ngIf="'loading' === domainStatusRequestState"
								class="inline-spinner"
								mode="indeterminate"
								color="primary"
								diameter="16"
							></mat-progress-spinner>

							<span
								*ngIf="'succeeded' === domainStatusRequestState"
								class="value"
								[class.ctw-text-red-700]="
									domainStatus !== 'Active'
								"
								[class.ctw-text-green-700]="
									domainStatus === 'Active'
								"
								>{{ domainStatus }}</span
							>

							<span
								*ngIf="'failed' === domainStatusRequestState"
								class="error-label ctw-text-red-700"
								>Error retrieving domain status</span
							>
						</p>

						<p>
							<span
								class="tooltip"
								matTooltip="When a domain is registered or transferred, or when the registrant contact information is changed, the registrant must reply to an email requesting them to confirm that the submitted contact information is correct."
								>Verification Status:
							</span>

							<mat-progress-spinner
								*ngIf="
									'loading' === verificationStatusRequestState
								"
								class="inline-spinner"
								mode="indeterminate"
								color="primary"
								diameter="16"
							></mat-progress-spinner>

							<span
								*ngIf="
									'succeeded' ===
									verificationStatusRequestState
								"
								class="value"
								[class.ctw-text-red-700]="
									verificationStatus === 'Suspended'
								"
								[class.ctw-text-green-700]="
									verificationStatus !== 'Suspended'
								"
								>{{ verificationStatus }}</span
							>

							<ng-container
								*ngIf="
									'failed' === verificationStatusRequestState
								"
							>
								<span class="error-label ctw-text-red-700"
									>Error retrieving verification status</span
								>
								<button
									class="simple-button spacer"
									(click)="getVerificationStatus()"
								>
									[try again]
								</button>
							</ng-container>
						</p>

						<p>
							Registered:
							<span class="value">{{ domainCreateDate }}</span>
						</p>
						<p>
							Expires:
							<span class="value">{{ domainExpireDate }}</span>
						</p>

						<!-- <p>Auto Renew:
							<mat-progress-spinner *ngIf="'loading' === autoRenewRequestState || 'loading' === domainStatusRequestState" class="inline-spinner" mode="indeterminate" color="primary" diameter="16"></mat-progress-spinner>

							<ng-container *ngIf="'succeeded' === autoRenewRequestState || 'inactive' === autoRenewRequestState && 'succeeded' === domainStatusRequestState">
								<span class="value">{{ autoRenewEnabled ? 'On' : 'Off' }}</span>
								<button class="simple-button spacer" (click)="toggleAutoRenew()">[{{ autoRenewEnabled ? 'disable' : 'enable' }}]</button>
							</ng-container>

							<ng-container *ngIf="'failed' === domainStatusRequestState || 'update-failed' === autoRenewRequestState">
								<span class="error-label">Error {{ 'failed' === domainStatusRequestState ? 'retrieving' : 'updating' }} auto renew</span>
								<button class="simple-button spacer" (click)="toggleAutoRenew()">[try again]</button>
							</ng-container>
						</p> -->

						<!-- <button mat-raised-button color="primary" (click)="renewDomain()">Renew</button> -->
					</div>
					<div>
						<!-- <p>
							<span class="tooltip" matTooltip="Replaces your information with that of our forwarding service">Domain Privacy: </span>

							<ng-container *ngIf="domainName.endsWith( '.us' )">
								<span class="value">Prohibited <a href="https://www.about.us/policies/us-privacy-services-policy" target="_blank">[?]</a></span>
							</ng-container>

							<ng-container *ngIf="! domainName.endsWith( '.us' )">
								<span class="value">{{ domainPrivacyEnabled ? 'On' : 'Off' }}</span>
								<button class="simple-button spacer" (click)="toggleDomainPrivacy()">[{{ domainPrivacyEnabled ? 'disable' : 'enable' }}]</button>
							</ng-container>
						</p> -->

						<p>
							<span
								class="tooltip"
								matTooltip="Helps prevent unwanted changes to the domain"
								>Domain Lock:
							</span>

							<mat-progress-spinner
								*ngIf="
									'loading' ===
										domainLockStatusRequestState ||
									'loading' === domainStatusRequestState
								"
								class="inline-spinner"
								mode="indeterminate"
								color="primary"
								diameter="16"
							></mat-progress-spinner>

							<ng-container
								*ngIf="
									'succeeded' ===
										domainLockStatusRequestState ||
									('inactive' ===
										domainLockStatusRequestState &&
										'succeeded' ===
											domainStatusRequestState)
								"
							>
								<span *ngIf="!domainLockSupported" class="value"
									>Unsupported</span
								>

								<ng-container *ngIf="domainLockSupported">
									<span class="value">{{
										domainLockEnabled ? 'On' : 'Off'
									}}</span>
									<button
										class="simple-button spacer"
										(click)="toggleDomainLock()"
									>
										[{{
											domainLockEnabled
												? 'disable'
												: 'enable'
										}}]
									</button>
								</ng-container>
							</ng-container>

							<ng-container
								*ngIf="
									'failed' === domainStatusRequestState ||
									'update-failed' ===
										domainLockStatusRequestState
								"
							>
								<span class="error-label"
									>Error
									{{
										'failed' === domainStatusRequestState
											? 'retrieving'
											: 'updating'
									}}
									lock status</span
								>
								<button
									class="simple-button spacer"
									(click)="getDomainLock()"
								>
									[try again]
								</button>
							</ng-container>
						</p>

						<p>
							<span
								class="tooltip"
								matTooltip="Used for transferring the domain to another registrar"
								>Auth-Code:
							</span>

							<button
								class="simple-button"
								*ngIf="'inactive' === authCodeRequestState"
								(click)="getAuthCode()"
							>
								[retrieve]
							</button>

							<mat-progress-spinner
								*ngIf="'loading' === authCodeRequestState"
								class="inline-spinner"
								mode="indeterminate"
								color="primary"
								diameter="16"
							></mat-progress-spinner>

							<central-text-copy
								*ngIf="'succeeded' === authCodeRequestState"
								>{{ domainAuthCode }}</central-text-copy>

							<ng-container
								*ngIf="'failed' === authCodeRequestState"
							>
								<span class="error-label"
									>Error retrieving auth-code</span
								>
								<button
									class="simple-button spacer"
									(click)="getAuthCode()"
								>
									[try again]
								</button>
							</ng-container>
						</p>
					</div>
				</div>
			</div>
		</dash-card>

		<dash-card
			cardTitle="Name Servers"
			icon="dns"
			disabled="true"
			[subTitle]="[
				'The authoritative source of the domain\'s DNS records'
			]"
		>
			<div class="ctw-p-8">
				<ng-container *ngIf="!editingNameServers">
					<ul>
						<li
							*ngFor="let nameServer of nameServers"
							class="monospace"
						>
							{{ nameServer }}
						</li>
					</ul>
					<div class="ctw-py-4">
						<button
							mat-stroked-button
							color="primary"
							*ngIf="!domainLockEnabled"
							(click)="editingNameServers = true"
						>
							Edit</button
						><span *ngIf="domainLockEnabled" class="spacer"
							>(Disable domain lock to edit name servers)</span
						>
					</div>
				</ng-container>

				<ng-container *ngIf="editingNameServers">
					<ng-container
						*ngFor="
							let nameServer of editedNameServers;
							index as i;
							trackBy: trackByIndex
						"
					>
						<div>
							<mat-form-field
								class="name-server-input"
								appearance="fill"
							>
								<mat-label>Name Server {{ i + 1 }}</mat-label>
								<input
									matInput
									[(ngModel)]="editedNameServers[i]"
									placeholder="{{ nameServer }}"
									value="{{ nameServer }}"
									pattern="^((?!-)([-0-9a-z]{0,62})?[0-9a-z](\.(?!$)|$)){3,}"
								/>
							</mat-form-field>
							<button
								*ngIf="i > 1"
								class="delete-name-server-button"
								mat-icon-button
								color="warn"
								(click)="editedNameServers.splice(i, 1)"
							>
								<mat-icon>delete_forever</mat-icon>
							</button>
						</div>
					</ng-container>

					<p
						*ngIf="'update-failed' === nameServerRequestState"
						class="error-label inline-error"
					>
						Error updating name servers
					</p>

					<div class="name-server-buttons">
						<div class="expand">
							<button
								mat-raised-button
								(click)="revertNameServerChanges()"
							>
								Cancel
							</button>
							<button
								mat-raised-button
								(click)="
									editedNameServers = [
										'ns1.inmotionhosting.com',
										'ns2.inmotionhosting.com'
									]
								"
							>
								<mat-icon>settings_backup_restore</mat-icon>
								Default
							</button>
							<button
								*ngIf="editedNameServers.length < 13"
								mat-raised-button
								(click)="editedNameServers.push('')"
							>
								<mat-icon>add</mat-icon> Add
							</button>
						</div>
						<div>
							<central-progress-button
								color="primary"
								[state]="nameServerProgressButtonState"
								(click)="saveNameServers()"
								>{{
									nameServerProgressButtonTitle
								}}</central-progress-button
							>
						</div>
					</div>
				</ng-container>

				<div class="ctw-mt-4">
					<button
						mat-stroked-button
						color="primary"
						routerLink="./dns-manager"
					>
						Manage DNS Records
					</button>
				</div>
			</div>
		</dash-card>

		<dash-card
			cardTitle="WHOIS Information"
			icon="contact_page"
			disabled="true"
			[subTitle]="['Contact information for the domain']"
		>
			<div class="ctw-p-8">
				<mat-tab-group>
					<mat-tab label="Owner">
						<central-contact-set
							*ngIf="!editingContactSets"
							[(contactSet)]="contactSets.owner"
						></central-contact-set>
						<central-contact-set-edit
							*ngIf="editingContactSets"
							[(editedContactSet)]="editedContactSets.owner"
							[disableAll]="'loading' === contactSetRequestState"
						></central-contact-set-edit>
					</mat-tab>
					<mat-tab
						*ngIf="contactSets.hasOwnProperty('admin')"
						label="Administrator"
					>
						<central-contact-set
							*ngIf="!editingContactSets"
							[(contactSet)]="contactSets.admin"
						></central-contact-set>
						<central-contact-set-edit
							*ngIf="editingContactSets"
							[(editedContactSet)]="editedContactSets.admin"
							[disableAll]="'loading' === contactSetRequestState"
						></central-contact-set-edit>
					</mat-tab>
					<mat-tab
						*ngIf="contactSets.hasOwnProperty('billing')"
						label="Billing"
					>
						<central-contact-set
							*ngIf="!editingContactSets"
							[(contactSet)]="contactSets.billing"
						></central-contact-set>
						<central-contact-set-edit
							*ngIf="editingContactSets"
							[(editedContactSet)]="editedContactSets.billing"
							[disableAll]="'loading' === contactSetRequestState"
						></central-contact-set-edit>
					</mat-tab>
					<mat-tab
						*ngIf="contactSets.hasOwnProperty('tech')"
						label="Technical"
					>
						<central-contact-set
							*ngIf="!editingContactSets"
							[(contactSet)]="contactSets.tech"
						></central-contact-set>
						<central-contact-set-edit
							*ngIf="editingContactSets"
							[(editedContactSet)]="editedContactSets.tech"
							[disableAll]="'loading' === contactSetRequestState"
						></central-contact-set-edit>
					</mat-tab>
				</mat-tab-group>

				<button
					mat-stroked-button
					color="primary"
					*ngIf="!editingContactSets"
					(click)="editingContactSets = true"
				>
					Edit
				</button>

				<div *ngIf="editingContactSets" class="contact-set-buttons">
					<div class="expand">
						<button
							mat-raised-button
							(click)="revertContactSetsChanges()"
							[disabled]="'loading' === contactSetRequestState"
						>
							Cancel
						</button>
					</div>
					<div>
						<span
							*ngIf="'update-failed' === contactSetRequestState"
							class="error-label inline-error"
							>Error updating WHOIS information</span
						>
						<central-progress-button
							color="primary"
							[state]="contactSetProgressButtonState"
							(click)="saveContactSets()"
							>{{
								contactSetProgressButtonTitle
							}}</central-progress-button
						>
					</div>
				</div>
			</div>
		</dash-card>
	</ng-container>
</div>
