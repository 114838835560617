import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Inject,
} from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
	selector: 'central-new-project-dialog',
	templateUrl: './new-project-dialog.component.html',
	styleUrls: ['./new-project-dialog.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewProjectDialogComponent {
	public ownerAccountId: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: string,
		private dialogRef: MatDialogRef<NewProjectDialogComponent>
	) {}

	public accountChanged($event) {
		setTimeout(() => {
			this.ownerAccountId = $event['account_id'];
		}, 0);
	}

	onConfirm() {
		this.dialogRef.close({ data: this.ownerAccountId });
	}
}
