import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AuthService } from '../../authentication/services/auth.service';
import { LoginModalComponent } from '../../authentication/login-modal/login-modal.component';
import { InvalidInputService } from '../../core/forms/invalid-input.service';
import { ApiService } from '../../core/api/api.service';

@Component({
	// eslint-disable-next-line
	selector: 'email-input',
	templateUrl: 'email-input.component.html',
	styleUrls: ['email-input.component.scss'],
})
export class EmailInputComponent {
	// Inputs.
	@ViewChild('input') public input: any;

	@Input() public forceLogin = false;
	@Input() public loginModalTitle: string;
	@Input() public loginModalSubTitle: string;

	// Events.
	// eslint-disable-next-line
	@Output() public change = new EventEmitter();
	// eslint-disable-next-line
	@Output() public onLogin = new EventEmitter();
	// eslint-disable-next-line
	@Output() public onLogout = new EventEmitter();

	/**
	 * Current value of the field.
	 *
	 * @since 1.13.0
	 */
	public value = '';

	/**
	 * Whether or not to show as disabled.
	 *
	 * @since 1.13.0
	 */
	public disabled = false;

	/**
	 * Have we force displayed the model already?
	 *
	 * @since 1.20.0
	 */
	public forcedLoginModal = false;

	/**
	 * How many times have we chaecked an email?
	 *
	 * @since 1.20.0
	 */
	public checkedEmailCount = 0;

	/**
	 * A list of account that we know exist.
	 *
	 * @since 1.20.0
	 */
	private existingAccount = {};

	constructor(
		public invalidInput: InvalidInputService,
		public apiService: ApiService,
		public authService: AuthService,
		private dialog: MatDialog
	) {
		// Set the field if the user is logged in.
		if (this.authService.isLoggedIn()) {
			setTimeout(() => this._setEmail());
		}
	}

	public ngOnInit() {
		if (this.forceLogin && !this.authService.isLoggedIn()) {
			setTimeout(() => this.openDialog(this.forceLogin), 2000);
		}
	}
	/**
	 * Is the email a known account?
	 *
	 * @since 1.20.0
	 *
	 * @return       Is the email a known account?
	 */
	public requiresSignin(): boolean {
		return (
			this.existingAccount[this.value] && !this.authService.isLoggedIn()
		);
	}

	/**
	 * Open the disalog with any login errors.
	 *
	 * @since 1.20.0
	 */
	public opendDialogWithErrors(): void {
		if (this.existingAccount[this.value]) {
			const dialog = this.openDialog();

			setTimeout(() => {
				dialog.loginForm.postEmailCheck(
					this.existingAccount[this.value]
				);
				dialog.loginForm.model.email = this.value;
			});
		}
	}

	/**
	 * Check if an email exists in the system and prompt to log in.
	 *
	 * @since 1.20.0
	 */
	public checkExistingEmail(input: any): void {
		// Only for unauthenticated users, check 3 times max. 1 display only. valid input only.
		if (
			!input.valid ||
			this.authService.isLoggedIn() ||
			this.checkedEmailCount > 2
		) {
			return;
		}

		const email = this.value;
		const accountFound = (response: any) => {
			this.existingAccount[email] = response;

			// Make sure the user didnt change the email while we waited for a response.
			if (email === this.value && !this.forcedLoginModal) {
				this.forcedLoginModal = true;
				this.opendDialogWithErrors();
			}
		};

		this.checkedEmailCount = this.checkedEmailCount + 1;

		this.apiService.get('authEmail', { email: this.value }).subscribe(
			(response) => {
				// Account found, but recapctha not required.
				if (response['message'] !== 'Invalid Captcha.') {
					accountFound(response);
				}
			},
			(errors: any) => {
				// Errors from the API, anything but account_not_found means an account is found but needs auth..
				if (errors.errors && errors.errors.length) {
					if (
						errors.errors.find(
							(val: object) => val['name'] !== 'account_not_found'
						)
					) {
						accountFound(errors);
					}
				}
			}
		);
	}

	/**
	 * Open the login modal.
	 *
	 * @since 1.13.0
	 */
	public openDialog(disableClose: boolean = false): LoginModalComponent {
		const matDialog = new MatDialogConfig();

		// Set the modal dimensions.
		matDialog.width = '400px';
		matDialog.height = '420px';
		matDialog.disableClose = disableClose;
		matDialog.data = {
			forceLogin: this.forceLogin,
			title: this.loginModalTitle,
			subTitle: this.loginModalSubTitle,
		}

		// Open the modal and subscribe to the login event.
		const loginModal = this.dialog.open(
			LoginModalComponent,
			matDialog,
		).componentInstance;

		setTimeout(() => {
			loginModal.loginForm.onLoginSuccess.subscribe((data) => {
				this._setEmail();
				this.onLogin.emit(data);
			});
		});

		return loginModal;
	}

	/**
	 * Log the user out and reset the form.
	 *
	 * @since 1.13.0
	 */
	public logOut(): void {
		this.authService.silentLogout();
		this.disabled = false;
		this.value = '';
		this.input.reset();
		this.onLogout.emit();
		if (this.forceLogin) {
			this.openDialog(this.forceLogin);
		}
	}

	/**
	 * Set the value of the input field.
	 *
	 * @since 1.13.0
	 */
	private _setEmail(): void {
		this.value = this.authService.getEmail();
		this.disabled = true;
	}
}
