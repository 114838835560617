<failed-request *ngIf="status === 'failed'"></failed-request>
<mat-progress-bar *ngIf="
		stoppedBy !== 'Systems' &&
		this.status !== 'success' &&
		this.state !== 'blocked'
	" mode="indeterminate" color="primary"></mat-progress-bar>

<div *ngIf="stoppedBy === 'Systems'" class="alert ctw-mb-8">
	{{ this.stoppedBy }} has stopped your server for {{ this.stoppedFor }}.
</div>
<div *ngIf="
		status === 'success' &&
		stoppedBy !== 'Systems' &&
		this.state !== 'blocked'
	" class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8 ctw-px-8 ctw-mb-12">

	<ng-container *ngIf="env === 'vps'; then vpsTitle; else playgroundTitle">
	</ng-container>

	<ng-template #vpsTitle>
		<h2>
			{{ this.plans[this.projectService.environment.fields?.package_code?.replace('wphosting_tier_',
			'')?.replace('a', '')]?.name }}
		</h2>
	</ng-template>

	<ng-template #playgroundTitle>
		<h2>Playground</h2>
	</ng-template>

	<div class="details">
		<p class="domain ctw-flex">
			<span>Region: </span>
			<span class="flag" [class.us]="this.projectService.environment.fields?.data_center === 'lax'
					|| this.projectService.environment.fields?.data_center === 'iad'
					|| !this.projectService.environment.fields?.data_center
					|| this.env === 'playground'" [class.eu]="this.projectService.environment.fields?.data_center === 'ams'"></span>
			<strong>
				<span
					*ngIf="this.projectService.environment.fields?.data_center === 'lax' || this.env === 'playground'">US
					West</span>
				<span
					*ngIf="this.projectService.environment.fields?.data_center === 'iad' || (this.env === 'vps' && !this.projectService.environment.fields?.data_center)">US
					East/Central</span>
				<span *ngIf="this.projectService.environment.fields?.data_center === 'ams'">EU Central</span>
			</strong>
		</p>
		<p class="domain" *ngIf="this.projectService.environment.fields?.package_code">
			Performance Level: <strong>{{
				this.projectService.environment.fields?.package_code.replace(
				'wphosting_tier_',
				'Tier '
				)
				}}</strong> <span *ngIf="this.configService.hasFeature('resize')"
				class="ctw-text-imh-blue ctw-ml-2 ctw-cursor-pointer"
				[routerLink]="[ '/projects', this.projectService.project.id, 'resize-server' ]"
				[queryParams]="{ environment_id: this.projectService.environment.id }">[resize]</span>
		</p>
		<p class="domain">
			Hostname: <central-text-copy>{{this.getHostname()}}</central-text-copy>
		</p>
		<p class="domain">
			IP Address: <central-text-copy>{{this.getIp()}}</central-text-copy>
		</p>
		<p class="status">
			Server Status:
			<span class="indicator running" *ngIf="containerStatus === 'running' || env !== 'vps'">Running</span>
			<span class="indicator" *ngIf="containerStatus === 'stopping'">Stopping</span>
			<span class="indicator" *ngIf="containerStatus === 'starting'">Starting</span>
			<span class="indicator" *ngIf="containerStatus === 'restarting'">Restarting</span>
			<span class="indicator stopped" *ngIf="containerStatus === 'stopped'">Stopped</span>
		</p>
	</div>
	<div *ngIf="stoppedBy" class="alert">
		<p>
			{{ this.stoppedBy }} has stopped your server for
			{{ this.stoppedFor }}
		</p>
		.
	</div>
	<ng-template #subTitleTemplate>
		<code class="sub-title ctw-text-base">
			<central-text-copy [cssClasses]="'ctw-font-normal ctw-border ctw-border-neutral-500 ctw-rounded ctw-py-1 ctw-pl-2 ctw-bg-neutral-100'">
				{{this.sshUser + '@' + this.getIp()}}
			</central-text-copy>
		</code>
	</ng-template>
	<dash-card *ngIf="stoppedBy !== 'Systems'" icon="terminal" cardTitle="Server Access" [subTitle]="subTitleTemplate"
		class="dash-card dash-card-vz-manager ctw-mb-8" disabled="true">
		<div class="dash-card-content ctw-pb-4">
			<ul class="row flex flex-wrap">
				<li *ngIf="isBusy" class="screener ctw-w-full">
					<mat-spinner class="component-loader" diameter="50" color="primary"></mat-spinner>
				</li>
				<li *ngIf="env === 'vps'">
					<div class="vz-manager-action" (click)="this.restartServer()">
						<mat-icon mat-list-icon>restart_alt</mat-icon>
						<div>Restart Server</div>
					</div>
				</li>
				<li *ngIf="env === 'vps'">
					<div class="vz-manager-action" (click)="this.updateServer()">
						<mat-icon mat-list-icon>power_settings_new</mat-icon>
						<div *ngIf="buttonStatus === 'stop'">Stop Server</div>
						<div *ngIf="buttonStatus === 'start'">Start Server</div>
					</div>
				</li>
				<li>
					<div class="vz-manager-action" (click)="this.openAddSshDialog(this.env)">
						<mat-icon mat-list-icon>key</mat-icon>
						<div>Add SSH Key</div>
					</div>
				</li>
			</ul>
		</div>
	</dash-card>

	<ng-container *ngIf="env === 'vps'">
		<central-snapshots></central-snapshots>
	</ng-container>

	<app-ansible-card *ngIf="this.projectService.hasCapability('write') && this.env === 'vps'"
		class="stat-card"></app-ansible-card>
</div>
