import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import stepConfig from './config-steps';
import { ApiService, AuthService } from '@central/ng-shared';
import { ReplaySubject } from 'rxjs';
import { ProjectService } from '../../project/project.service';
import { LicenseService } from '../../shared/license/license.service';
import { SharedStorageService, TrackingService } from '@central/ng-shared';
import { HubspotService } from 'libs/ng-shared/src/lib/tracking/hubspot/hubspot.service';

interface Selections {
	siteType: string;
	snapName: string;
	serverTier: number;
	dataCenter: string,
	domainName: string;
	currentStep: number;
	projectId: string;
	environmentId: string;
	organizationId: string;
	connectSiteUrl: string;
	connectSiteFound: boolean;
	connectEnvironment: string;
	connectType: string;
	numberOfEmails: number;
	projectAddons: string[];
	term: string;
	installationState: string;
	primarySubscriptionUuid: string;
	mode: string;
}
@Injectable()
export class ProjectCreateService {
	public state = 'loading';
	public stepConfigs;
	public steps = [];
	private allSteps: any = {};
	public catalog: any[] = [];
	public getCatalog = new ReplaySubject();
	public getProject = new ReplaySubject();
	public getConnectEnvironment = new ReplaySubject();
	public project;
	public environment;
	public connectEnvironment;
	private selections: Selections;
	private initialSelections: Selections = {
		siteType: 'existing',
		snapName: '',
		organizationId: '',
		serverTier: 5,
		dataCenter: 'lax',
		domainName: '',
		term: 'yearly',
		projectAddons: [],
		connectType: 'credentials',
		projectId: '',
		environmentId: '',
		numberOfEmails: 0,
		connectSiteUrl: '',
		connectSiteFound: null,
		connectEnvironment: null,
		currentStep: 1,
		primarySubscriptionUuid: null,
		installationState: 'pending',
		mode: 'purchase',
	};
	public couponData = null;
	public pricingPage = '';
	public skipPurchase = false;
	public skipPlanSelector = false;
	public history
	public shouldLoadSavedSettings;

	public constructor(
		public router: Router,
		private apiService: ApiService,
		private authService: AuthService,
		private route: ActivatedRoute,
		public licenseService: LicenseService,
		public projectService: ProjectService,
		private trackingService: TrackingService,
		private hubspotService: HubspotService
	) {
		if ( ! this.stepConfigs ) {
			this.stepConfigs = stepConfig;
		}

		this.parseCoupon();
	}

	private initializeSelections() {
		this.route.queryParams.subscribe((params) => {
			if (
				params?.selected &&
				!isNaN(params?.selected) &&
				params?.selected >= 1 &&
				params?.selected <= 8
			) {
				this.initialSelections.serverTier = params?.selected;
				this.skipPlanSelector = true;
			} else if (
				params?.project_id
			) {
				this.initialSelections.projectId = params?.project_id;
			}
		});

		this.selections = { ...this.initialSelections };
	}

	private async setup() {
		this.initializeSelections();
		this.mergeStepData();

		await this.loadState();

		this.chooseConfigVariation();
		this.bindOnNavigation();
		this.fetchCatalog();
		this.restartIfNeeded();
		this.requirePlanSelection();
		this.fetchProject();
		//this.fetchConnectEnvironment();
		this.initializeSelections();
	}

	private loadState() {
		return new Promise<void>((resolve) => {
			if (this.authService.isLoggedIn()) {
				this.shouldLoadSavedSettings = this.authService.profile.onReady().subscribe({
					complete: () => {
						this.loadSavedSettings();
						this.state = 'ready';
						resolve();
					},
					error: () => {
						this.state = 'error';
					},
				});
			} else {
				this.authService.onLogin.subscribe(() => {
					this.authService.profile
						.onReady()
						.subscribe(() => {
							this.selections.organizationId = this.authService.profile.data.account_access[1].account_id
						});
				});

				this.state = 'ready';
				resolve();
			}
		});
	}

	private loadSavedSettings() {
		const remotePurchaseSteps =
			this.authService.profile.data.options['public_purchase_steps'];
		if (remotePurchaseSteps) {
			// save steps and navigate to the last step
			this.selections = remotePurchaseSteps;
		}

		this.chooseConfigVariation();

		this.projectService.organizationId = this.selections.organizationId || null;

		if (this.couponData?.coupon_code.match(/-free$/) &&
			(this.authService.isLoggedIn() && !this.authService.profile.data.trial_available)) {
			// This is a freemium entry coupon and this user is an existing customer
			this.router.navigate(['/']);
		} else {
			this.navigateTo(0);
		}

		try {
			this.shouldLoadSavedSettings.unsubscribe();
		} catch (e) {
			// Profile not let loaded...
		}
	}

	private requirePlanSelection() {
		// Display plan selector unless selction was passed in.
		if (!this.skipPlanSelector &&
			this.getSelections().currentStep >= 1
		) {
			this.navigateTo(1);
		}
		if (this.skipPlanSelector) {
			this.next();
		}
	}

	/**
	 * If the saved steps have different values than the initial selections, restart.
	 */
	private restartIfNeeded(): void {
		const keys = Object.keys(this.initialSelections);
		const savedKeys = Object.keys(this.selections);
		const difference = keys
			.filter((x) => !savedKeys.includes(x))
			.concat(savedKeys.filter((x) => !keys.includes(x)));

		if (difference.length) {
			this.restart();
		}
	}

	private mergeStepData() {
		if (!this.pricingPage) {
			this.pricingPage = this.stepConfigs.pricingPage.default;
		}
		for (const type of Object.keys(this.stepConfigs.stepTypes)) {
			this.allSteps[type] = this.stepConfigs.stepTypes[type].map((stepName) => ({
					...this.stepConfigs.steps.find((item) => stepName === item.name),
				}));

			this.allSteps[type].sort((a, b) => a.priority - b.priority);

			this.allSteps[type].forEach((step, index) => {
				step.stepNumber = index + 1;
			});
		}
	}

	private bindOnNavigation() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.updateStep(event.url);
			}
		});
	}

	private updateStep(url: string): void {
		url = url.split('?')[0];

		const currentStep = this.steps.find((val) => url === val.route);
		if (currentStep) {
			this.updateSelections('currentStep', currentStep.stepNumber);
		}
	}

	private chooseConfigVariation(): void {
		if (this.selections.mode === 'purchase') {
			if (this.selections.siteType === 'new') {
				this.steps = this.allSteps.newSiteSteps;
			} else {
				this.steps = this.allSteps.connectSteps;
			}
		} else if (this.selections.mode === 'post-purchase') {
			if (this.selections.siteType === 'new') {
				this.steps = this.allSteps.postPurchaseNew;
			} else {
				this.steps = this.allSteps.postPurchaseTransfer;
			}
		}
	}

	public updateSelections(key: string, value) {
		this.selections[key] = value;

		if (key === 'siteType') {
			this.chooseConfigVariation();
		}
	}

	public getSelections() {
		return this.selections;
	}

	back() {
		this.navigateTo(this.selections.currentStep - 1 ?? 1);
	}

	next() {
		this.navigateTo(this.selections.currentStep + 1);
	}

	navigateTo(step: number) {

		const theSteps = this.steps.find((item) => item.stepNumber === step);
		if (theSteps?.route) {
			this.router.navigate([theSteps.route], {
				queryParamsHandling: 'merge',
			}).then(() => {
				this.updateSelections('currentStep', theSteps.stepNumber);
				this.saveStepData(step);
			});
		}
	}

	saveStepData(step: number) {
		if (this.authService.isLoggedIn()) {
			this.authService.profile
				.update({
					public_purchase_steps: this.selections,
				})
				.subscribe();
		}
	}

	private fetchCatalog() {
		this.apiService.get('/v1/wpcr/catalog', {}).subscribe({
			next: (data: any) => {
				this.catalog = data;
				this.getCatalog.next(data);
			},
			error: (error) => {
				console.log(error);
				this.router.navigate(['/guide/project/error']);
			},
		});
	}

	public restart() {
		this.initializeSelections();
		if (this.getSelections().projectId !== '') {
			this.navigateTo(0);
		} else {
			if (this.couponData &&
				(this.couponData.coupon_code.match(/-free$/) ||
				(this.authService.isLoggedIn() && !this.authService.profile.data.trial_available))) {
				// This is a freemium entry coupon and this user is an existing customer
				this.router.navigate(['/']);
			} else {
				this.authService.silentLogout()
				if (this.couponData) {
					this.router.navigate([`/guide/project/${this.couponData.coupon_code}`]);
				} else {
					this.router.navigate(['/guide/project/size']);
				}
			}
		}
	}

	public startPostPurchase() {
		this.updateSelections('mode', 'post-purchase');

		if (this.getSelections().projectId !== '') {
			this.router.navigate([ '/projects', this.getSelections().projectId ])
		} else {
			if (this.couponData &&
				(this.couponData.coupon_code.match(/-free$/) ||
				(this.authService.isLoggedIn() && !this.authService.profile.data.trial_available))) {
				// This is a freemium entry coupon and this user is an existing customer
				this.router.navigate(['/']);
			} else {
				this.chooseConfigVariation();
				this.navigateTo(0);
			}
		}
	}

	public fetchProject() {
		const projectId = this.getSelections().projectId;
		if (projectId) {
			this.apiService.get('/v1/projects/' + projectId, {}).subscribe({
				next: (data: any) => {
					this.project = data;
					this.projectService.project = this.project;
					this.getProject.next(data);
				},
				error: (error) => {
					this.router.navigate(['/guide/project/error']);
					console.log(error);
				},
			});
		}
	}

	public fetchConnectEnvironment() {
		this.getProject.subscribe({
			next: (project: any) => {
				project.children.find((child) => {
					if (child.id === this.getSelections().connectEnvironment) {
						this.getConnectEnvironment.next(this.environment);
					}
				});
			},
		});
	}

	public completeOnboarding() {
		this.authService.profile
			.update({
				bgc_account_welcome: 1,
				bgc_default_project_type: 'advanced',
			})
			.subscribe();
	}

	private parseCoupon() {
		const incomingRoute = this.router.url;
		const routeArray = incomingRoute.split('/');
		if (routeArray.length > 2) {
			const couponCode = routeArray[3];
			this.getCouponData(couponCode);
		} else {
			this.setup();
		}
	}

	private updateSteps(couponData) {
		this.pricingPage = this.stepConfigs.pricingPage[couponData.coupon_code];
		this.stepConfigs.steps = this.stepConfigs.steps.map((n) => {
			let re = n;
			if (this.skipPurchase && n.name === 'signup') {
				re = {
					...n,
					title: couponData.description ? couponData.description : n.title,
					label: couponData.invoice_description ? couponData.invoice_description : n.label,
				};
			}
			if (n.name === 'payment') {
				re = {
					...n,
					title: couponData.description ? couponData.description : n.title,
					label: couponData.invoice_description ? couponData.invoice_description : n.label,
				};
			}
			return re;
		});
	}

	private getCouponData(couponCode) {
		if (couponCode) {
			this.apiService
				.get('/v1/wpcr/coupons/' + couponCode, {})
				.subscribe({
					next: (data: any) => {
						const couponData = data;
						if (couponData && couponData.coupon_code && couponData.state === 'redeemable') {
							if (couponData.coupon_code.match(/-free$/)) {
								this.skipPurchase = true;
							}
							this.couponData = couponData;
							this.updateSteps(couponData);
						}
						this.setup();
					},
					error: (error) => {
						console.log(error);
					},
				});
		}
	}

	public getDiscountObject() {
		if (this.couponData &&
			(this.couponData.discount_type === 'dollars' || this.couponData.discount_type === 'percent')) {
			return JSON.stringify({
				type: this.couponData.discount_type,
				amount: this.couponData.discount_in_cents?.amount ?? this.couponData.discount_percent
			});
		}
	}
}
