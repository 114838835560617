<mat-progress-bar class="loader" mode="indeterminate" *ngIf="this.status === 'loading'; else loaded"></mat-progress-bar>

<ng-template #loaded>
	<div class="ctw-mb-10" *ngIf="this.configService.config.brandConfig.id !== 'imh'">
		<a mat-raised-button color="accent" routerLink=".."><mat-icon>keyboard_arrow_left</mat-icon> My Requests</a>
	</div>
	<div class="ticket-header animate__animated animate__fadeIn"
		[class.ctw-mt-10]="this.configService.config.brandConfig.id === 'imh'">
		<h2>
			<mat-icon class="error" *ngIf="ticket.priority !== 'normal'"
				matTooltip="Emergency Request">warning</mat-icon>
			{{ ticket.subject }}
		</h2>

		<mat-chip-list>
			<mat-chip class="reply" (click)="this.focusReply()" *ngIf="this.ticket.status !== 'closed'">Reply
				<mat-icon>keyboard_arrow_down</mat-icon></mat-chip>
			<mat-chip disableRipple="true" class="ticket-status {{ ticket.status }}">{{ ticket.status }}</mat-chip>
		</mat-chip-list>
	</div>

	<div class="ticket-comments animate__animated animate__fadeIn">
		<div *ngFor="let comment of ticket.comments">
			<container-element [ngSwitch]="comment.type">
				<mat-card class="{{
						'mat-card ' +
							(comment.author_info['agent'] ? 'agent' : 'user')
					}}" *ngSwitchCase="'Comment'">
					<mat-card-header>
						<div mat-card-avatar [ngStyle]="{
								backgroundSize: 'cover',
								backgroundImage:
									'url(' +
									(comment.author_info['photo'][
										'content_url'
									] ||
										'https://www.gravatar.com/avatar?f=y&d=mm') +
									')'
							}"></div>
						<mat-card-title>
							<span class="author-name">
								{{ comment.author_info['name'] }}
								{{
								comment.author_info['agent']
								? ' - ' + this.configService.config.brandConfig.reseller + ' Agent'
								: ''
								}}
							</span>
						</mat-card-title>
						<mat-card-subtitle>
							<span class="comment-created">
								{{ comment.created_at | amCalendar }}
							</span>
						</mat-card-subtitle>
					</mat-card-header>
					<mat-card-content>
						{{ comment.body }}
					</mat-card-content>
				</mat-card>
				<mat-card class="comment-update ctw-text-center" *ngSwitchCase="'Change'">
					<div class="comment-header">
						<span>
							Status updated:
							<strong>{{ comment.previous_value }}</strong>
							<mat-icon>arrow_right_alt</mat-icon>
							<strong>{{ comment.value }}</strong>
						</span>
						<span class="comment-created">
							{{ comment.created_at | amCalendar }}
						</span>
					</div>
				</mat-card>
			</container-element>
		</div>
	</div>

	<div *ngIf="this.ticket.status !== 'closed'; else closed" class="reply-block animate__animated animate__fadeIn"
		[class.ctw-mb-16]="this.configService.config.brandConfig.id === 'imh'">
		<h3>Reply</h3>
		<mat-form-field class="reply-text" appearance="{{
			this.configService.config.inputAppearance || fill
		}}">
			<textarea matInput #replyInput rows="4" [(ngModel)]="newComment" #newCommentField="ngModel"
				name="newComment" placeholder="Reply to your support request"></textarea>

			<mat-error>
				<ng-container *ngIf="newCommentField.errors?.required">This comment is required</ng-container>
			</mat-error>
		</mat-form-field>
		<progress-button mat-raised-button color="primary" [state]="this.status"
			(click)="addReply()">Submit</progress-button>
		<p *ngIf="this.errorMessage" class="error">{{ this.errorMessage }}</p>
	</div>
	<ng-template #closed>
		<div class="ticket-footer animate__animated animate__fadeIn"
			[class.ctw-mb-16]="this.configService.config.brandConfig.id === 'imh'">
			<p>
				This request is now closed, if you have any follow up concerns
				please open a new request.
			</p>
			<button mat-raised-button (click)="$event.preventDefault(); this.openDialog()"
				color="{{ this.configService.config.buttonColor || 'secondary' }}">
				Open New Request
			</button>
		</div>
	</ng-template>
</ng-template>