<ng-container *ngIf="this.status === 'success' && this.snap">
	<wp-content-ui [snap]="this.snap"></wp-content-ui>
	<div class="details">
		<dl>
			<dt>Name</dt>
			<dd>{{ this.snap.label }}</dd>
			<ng-container *ngIf="this.snap.description">
				<dt>Description</dt>
				<dd>{{ this.snap.description }}</dd>
			</ng-container>
			<dt>Created On</dt>
			<dd>{{ this.snap.created_at | amDateFormat : 'LL' }}</dd>
		</dl>
	</div>
	<ng-container *ngIf="includeActions">
		<p class="ctw-text-center finished-info">
			Choose one of the following options to continue
		</p>
		<ul class="dash-card-list row flex flex-wrap">
			<li class="ctw-text-center">
				<div class="dash-card-action" [routerLink]="['../../snaps']">
					<mat-icon>camera_alt</mat-icon>
					<p>My Snaps</p>
				</div>
			</li>
			<li class="ctw-text-center">
				<div
					class="dash-card-action"
					[routerLink]="['../../snaps/install']"
					[queryParams]="{
						snap_id: snap.snap_id,
						current_env: this.currentEnv
					}"
				>
					<mat-icon>cloud_upload</mat-icon>
					<p>Install</p>
				</div>
			</li>
		</ul>
	</ng-container>
</ng-container>
<div *ngIf="this.status === 'loading'">
	<mat-spinner color="accent" diameter="50"></mat-spinner>
</div>
