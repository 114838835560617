import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ApiService, ConfigurationService, MessageComponent, SupportService, AccountService } from '@central/ng-shared';
import { ProjectService } from '../../project.service';
import { Router } from '@angular/router';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
	selector: 'central-managed-hosting',
	templateUrl: './managed-hosting.component.html',
	styleUrls: ['./managed-hosting.component.scss'],
})
export class ManagedHostingComponent implements OnInit {

	public dialogRef: MatDialogRef<MessageComponent>;
	public clarityMinutes;
	public clarityHours;
	public environments = [];

	constructor(
		private router: Router,
		public apiService: ApiService,
		public projectService: ProjectService,
		public configService: ConfigurationService,
		public supportService: SupportService,
		public accountService: AccountService,
		public dialog: MatDialog,
	) {}

	ngOnInit() {
		const hid = this.projectService.environment?.fields?.clarity_hid;
		this.supportService.getAvailableClarityHours(hid).subscribe((minutes) => {
			this.clarityMinutes = minutes;
			this.clarityHours = (this.clarityMinutes/60) - ((this.clarityMinutes/60) % 1);
		});
		this.projectService.fetchProjects().subscribe((projects) => {
			this.environments = [];
			(projects as any).forEach((project) => {
				if (project.children.length > 0) {
					this.environments = [...this.environments, ...project.children];
				}
			});
		});
	}

	public openDialog(userSetting = null) {
		this.accountService.fetch(this.projectService.owner.account_id).subscribe();
		this.dialogRef = this.dialog.open(MessageComponent, {
			width: '600px',
			data: {
				userSetting,
				priorities: this.supportService.ticketPriorities,
				typeOptions: this.configService.config.zendeskTicketTypes,
				selectedType: this.configService.config.zendeskDefaultType,
				inputs: this.configService.config.zendesk.inputs,
				account: this.projectService.owner,
				selectedEnvironment: this.projectService.environment,
				environments: this.environments,
				hasMH: this.configService.hasFeature('managedHosting'),
			},
		});
		this.dialogRef.componentInstance.submitTicket.subscribe((ticket_data) => {
			this.submitTicket(ticket_data);
		});
	}

	public submitTicket(ticket_data) {
		this.dialogRef.componentInstance.state = 'submitted';
		this.supportService.submitTicket(ticket_data)
			.subscribe({
				next: (val) => {
					console.log(val);
					if (val.hasOwnProperty('ticket_id')) {
						this.dialogRef.componentInstance.state = 'success';
					} else {
						this.dialogRef.componentInstance.state = 'failed';
						if (400 === val['status']) {
							this.dialogRef.componentInstance.state = 'ratelimited';
						}
					}
				},
				error: (error) => {
					console.log(error);
					this.dialogRef.componentInstance.state = 'failed';
				},
			});
	}

}
