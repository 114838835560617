<!-- Load State -->
<div *ngIf="state === 'submitted'">
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<!-- Success State -->
<div *ngIf="state === 'success'" class="main-content">
	<table mat-table [dataSource]="organizations">
		<ng-container matColumnDef="display_name">
			<th mat-header-cell *matHeaderCellDef>Company</th>
			<td mat-cell *matCellDef="let element">
				{{ element.display_name }}
			</td>
		</ng-container>

		<ng-container matColumnDef="type">
			<th mat-header-cell *matHeaderCellDef>Type</th>
			<td mat-cell *matCellDef="let element">{{ element.type }}</td>
		</ng-container>

		<ng-container matColumnDef="account_id">
			<th mat-header-cell *matHeaderCellDef>Account ID:</th>
			<td mat-cell *matCellDef="let element">{{ element.account_id }}</td>
		</ng-container>

		<ng-container matColumnDef="role">
			<th mat-header-cell *matHeaderCellDef>Role:</th>
			<td mat-cell *matCellDef="let element">{{ element.role }}</td>
		</ng-container>

		<ng-container matColumnDef="has_billing">
			<th mat-header-cell *matHeaderCellDef>Billing:</th>
			<td mat-cell *matCellDef="let element">
				{{ element.has_billing ? 'Yes' : 'No' }}
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
		<tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
	</table>
</div>

<!-- Fail State -->
<div *ngIf="state === 'fail'">
	<p>Sorry, could not find data. Please refresh the page.</p>
</div>
