import {
	AfterViewInit,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { AccountService } from './account.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { merge } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService, ProfileService } from '../authentication/services';
import { ConfigurationService } from '../core';

@Component({
	// eslint-disable-next-line
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['../../sidebar.scss', './account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(MatSidenav) public sidenav;
	public accountId;
	public profileSub;
	public state = 'loading';
	public currentAccount;
	public projectMenuItems = [];
	public isMobile = false;
	private routerSub;

	public capabilities = {
		owner: ['manage', 'write', 'read', 'support', 'billing'],
		maintainer: ['write', 'read', 'support'],
		member: ['read', 'support'],
		orgSupport: ['support'],
		orgBilling: ['billing'],
	};

	constructor(
		public accountService: AccountService,
		public authService: AuthService,
		public profileService: ProfileService,
		private mediaQuery: BreakpointObserver,
		public configService: ConfigurationService,
		public router: Router,
		private activeRoute: ActivatedRoute
	) {
		this.setAccount();
		this.projectMenuItems = this.getProjectMenuItems();
	}

	ngOnInit(): void {
		this.profileSub = this.profileService.profileChange.subscribe(() => {
			this.applyAuthAccounts();
		});

		this.routerSub = this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				if (this.isMobile) {
					this.sidenav.close();
				}
			}
		});
	}

	ngAfterViewInit() {
		this.mediaQuery.observe('(max-width: 1000px)').subscribe((res) => {
			this.isMobile = res.matches;
			this.applyResponsiveness();
		});

		// Fix for sidebars in sidebars.
		setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
	}

	ngOnDestroy(): void {
		if (this.profileSub) {
			this.profileSub.unsubscribe(); // Causing speed on reload issues + subscriber error !
		}

		if (this.routerSub) {
			this.routerSub.unsubscribe();
		}
	}

	public hasAccess(item) {
		let hasAccess = true;
		if (item.permission) {
			hasAccess = this.capabilities[this.currentAccount.role].includes(
				item.permission
			);
		}

		return hasAccess;
	}

	/**
	 * Find additional routes with an account prefix.
	 *
	 * @returns array
	 */
	public getProjectMenuItems(): any[] {
		let projectMenuItems = [];
		this.router.config.find((val) => {
			if ('account' === val.path) {
				// InMotion Central
				projectMenuItems = val.children.filter(
					(child) => !!child['icon']
				);
			} else if ('' === val.path && val.children) {
				// OpenMetal Central
				val.children.find((item) => {
					if (
						item.path.startsWith('account') &&
						!item.path.includes('checkout')
					) {
						projectMenuItems = item.children.filter(
							(child) => !!child['icon']
						);
					}
				});
			}
		});

		return projectMenuItems;
	}

	public applyResponsiveness() {
		if (this.sidenav) {
			if (this.isMobile) {
				this.sidenav.close();
			} else {
				this.sidenav.open();
			}
		}
	}

	setCurrentAccount(currentId) {
		this.currentAccount = this.profileService.data.account_access.find(
			(account) => account.account_id === currentId
		);
	}

	public navigate(account) {
		const defaultRoute = ['settings'];
		if (this.currentAccount.account_id !== account.account_id) {
			this.currentAccount = account;
			let curRoute = this.router.url.split('/').slice(3);
			switch (curRoute[0]) {
				case 'members':
					if ('organization' !== this.currentAccount.type) {
						curRoute = defaultRoute;
					}
					break;
				case 'billing':
					if (
						-1 ===
						this.accountService.roles['manage'].indexOf(
							this.currentAccount.role
						)
					) {
						curRoute = defaultRoute;
					}
					break;
			}
			this.router.navigate(
				['/account/', this.currentAccount.account_id].concat(curRoute)
			);
		}
	}

	public applyAuthAccounts() {
		// Preselect the current account from the drop down.
		this.setCurrentAccount(this.accountId);
	}

	public getMixedRoute(name) {
		const segments = name.split('/');
		let route = ['/account', this.accountService.getResourceId()];
		if (!this.activeRoute.snapshot.params.account_id) {
			route = ['/account'];
		}

		return [...route, ...segments];
	}

	public setAccount() {
		this.activeRoute.params.subscribe((routeParams) => {
			this.accountId =
				routeParams.account_id || this.authService.getAccountId();
			this.state = 'loading';
			merge(
				...[
					this.accountService.fetch(this.accountId),
					this.profileService.onReady(),
				]
			).subscribe({
				complete: () => {
					this.applyAuthAccounts();
					this.state = 'success';
					setTimeout(() => this.applyResponsiveness());
				},
				error: () => {
					this.router.navigateByUrl('/404', { replaceUrl: true });
				},
			});
		});
	}
}
