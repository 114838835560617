import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
	OnInit,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContextualHelpDialogComponent } from './help-dialog/contextual-help-dialog.component';
import { Router } from '@angular/router';

@Component({
	// eslint-disable-next-line
	selector: 'contextual-help-card',
	templateUrl: './contextual-help-card.component.html',
	styleUrls: ['./contextual-help-card.component.scss'],
})
export class ContextualHelpCardComponent implements OnInit {
	@Input() public cardTitle: string;
	@Input() public icon: string;
	@Input() public customIcon: string;
	@Input() public subTitle: any[] = [];
	@Input() public iframe: string;
	@Input() public help = false;
	@Input() public showPopup = false;
	@Input() public disabled = false;
	@Input() public showHeader = true;
	@Input() public expanded = true;
	@Output() public opened = new EventEmitter<string>();

	@ViewChild('matPanel') public matPanel: any;

	private endpoint: string =
		'https://www.inmotionhosting.com/support/wp-json/wp/v2/central?per_page=100&' +
		new Date().getTime();
	public centralArticle: object = {};
	public localStorageKey: string;

	public constructor(
		private dialog: MatDialog,
		private http: HttpClient,
		private router: Router
	) {}

	public ngOnInit(): void {
		this.setupHelp();
	}

	setupHelp() {
		this.http.get(this.endpoint).subscribe((centralArticles: any) => {
			// Check if a card's title matches a central article.
			centralArticles.forEach((article) => {
				if (article.acf.title.trim() === this.cardTitle?.trim()) {
					// Checks given string for being valid HTML and attempts to fix it for display output.
					const isValid = (content) => {
						// String passed contains no HTML tags.
						if (!/<[a-z][\s\S]*>/i.test(content)) {
							content = '<div>' + content + '</div>';
						}

						const doc = new DOMParser().parseFromString(
							content,
							'text/html'
						);

						return [].slice
							.call(doc.body.childNodes)
							.some((node) => node.nodeType === 1);
					};

					// Pass through card's icon to dialog.
					if (this.icon) {
						this.centralArticle['icon'] = this.icon;
					}

					if (this.customIcon) {
						this.centralArticle['icon'] = this.customIcon;
					}

					this.centralArticle['icon'] = this.icon;

					// Text fields often contain line breaks without HTML content.  Convert these breaks to HTML for display.
					// We also are checking if HTML is valid for display output.
					this.centralArticle['before_video'] =
						article.acf.before_video.replace(
							/(\\r\\n|\\r|\\n)/gm,
							'<br />'
						);
					this.centralArticle['before_video'] = isValid(
						this.centralArticle['before_video']
					)
						? this.centralArticle['before_video']
						: '';

					// Youtube video embed.
					this.centralArticle['youtube'] = '';

					if (article.acf.youtube) {
						this.centralArticle['youtube'] =
							'https://www.youtube.com/embed/' +
							this.getYoutubeId(article.acf.youtube);
					}

					// Text fields often contain line breaks without HTML content.  Convert these breaks to HTML for display.
					// We also are checking if HTML is valid for display output.
					this.centralArticle['after_video'] =
						article.acf.after_video.replace(
							/(\\r\\n|\\r|\\n)/gm,
							'<br />'
						);
					this.centralArticle['after_video'] = isValid(
						this.centralArticle['after_video']
					)
						? this.centralArticle['after_video']
						: '';

					this.centralArticle = {
						...article.acf,
						...this.centralArticle,
					};

					if (this.showPopup) {
						// Make key unique based on router URL + article title so we don't re-popup or popup at wrong times.
						const key =
							this.router.url.replace(/\//g, '') +
							this.cardTitle.trim().replace(/\s/g, '');

						if (
							!localStorage.getItem(key) ||
							localStorage.getItem(key) === 'false'
						) {
							this.openHelp();
							localStorage.setItem(key, 'true');
						}
					}

					this.help = true;

					return;
				}
			});
		});
	}

	/**
	 * Gets the ID from a youtube URL.
	 *
	 * This accounts for the follow scenarios:
	 *  - Writer copy and pastes URL from address bar ( youtube.com/watch?v=XXXX )
	 *  - Writer uses the share button and presses copy ( youtu.be/XXXX )
	 *  - Writer just enters in the youtube video ID ( XXX )
	 *
	 * @param   string url youtube URL to video to embed.
	 * @returns string    Youtube video ID.
	 */
	getYoutubeId(url: string) {
		const delimiter = url.includes('youtu.be') ? '/' : '=';
		return url.split(delimiter).pop();
	}

	getCards() {
		return document
			.querySelectorAll('contextual-help-card[ng-reflect-card-title]')
			.forEach((cardTitle) => {
				console.log(cardTitle.getAttribute('ng-reflect-card-title'));
			});
	}

	panelOpened(event: Event) {
		this.opened.emit('opened');
	}

	openHelp() {
		const ref = this.dialog.open(ContextualHelpDialogComponent, {
			width: '500px',
			disableClose: false,
			data: this.centralArticle,
			autoFocus: false,
		});
	}
}
