import _debounce from 'lodash/debounce';
import { EventEmitter } from '@angular/core';

export class InfiniteLoad {
	public sideNav;
	public debouncedScroll;
	public load = new EventEmitter();

	public constructor() {
		this.debouncedScroll = _debounce(() => {
			const height = this.sideNav.clientHeight;
			const scrollHeight = this.sideNav.scrollHeight - height;
			const scrollTop = this.sideNav.scrollTop;
			const percent = Math.floor((scrollTop / scrollHeight) * 100);
			if (percent >= 85) {
				this.load.emit();
			}
		}, 50);
	}

	public setup() {
		setTimeout(() => {
			this.sideNav = document.documentElement;
			window.addEventListener('scroll', this.debouncedScroll);
		});
	}

	public shutdown() {
		window.removeEventListener('scroll', this.debouncedScroll);
	}
}
