import {
	Component,
	Directive,
	ElementRef,
	Input,
	OnInit,
	Renderer2,
} from '@angular/core';
import { ApiService, AuthService } from '@central/ng-shared';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { LicenseService } from '../../shared/license/license.service';
import { ProjectService } from '../project.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NewProjectDialogComponent } from './new-project-dialog/new-project-dialog.component';
import { filter } from 'rxjs/operators';

@Directive({
	selector: '[centralCachedSrc]',
})
export class CachedSrcDirective {
	@Input()
	public get centralCachedSrc(): string {
		return this.elRef.nativeElement.src;
	}
	public set centralCachedSrc(src: string) {
		if (this.elRef.nativeElement.src !== src) {
			this.renderer.setAttribute(this.elRef.nativeElement, 'src', src);
		}
	}

	constructor(private elRef: ElementRef, private renderer: Renderer2) {}
}

@Component({
	selector: 'bgc-collection',
	templateUrl: './collection.component.html',
	styleUrls: [
		'./collection.component.scss',
	],
	providers: [ProjectService],
})
export class ListComponent implements OnInit {
	public displayedColumns: string[] = ['name', 'owner', 'environments'];
	public status = 'pending';
	public view = 'grid';
	public projects = [];
	public environmentId = null;
	public newProjectState = '';
	public newProjectTitle = 'My New Project';
	public teams = [];
	public offer;

	constructor(
		public router: Router,
		public apiService: ApiService,
		public authService: AuthService,
		public projectService: ProjectService,
		public licenseService: LicenseService,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.status = 'loading';
		this.environmentId = null;
		this.route.queryParams
			.pipe(filter((params) => params.environment_id))
			.subscribe((params) => {
				this.environmentId = params.environment_id;
			});
		this.fetchProjects();
		this.createProjectTitle();
		this.teamCount();
	}

	public getOffer() {
		this.offer = this.projectService.couponService.couponData['server'];
		return this.offer;
	}

	public fetchProjects() {
		this.apiService.get('/v1/projects', {}).subscribe(
			(response: any[]) => {
				this.projects = response;
				if (null !== this.environmentId) {
					this.projects.forEach((project) => {
						project.children.forEach((environment) => {
							if (environment.id === this.environmentId) {
								this.router.navigate(
									['/projects/', project.id, 'site'],
									{
										queryParams: {
											environment_id: environment.id,
										},
									}
								);
							}
						});
					});
				}
			},
			() => {
				this.status = 'failed';
			},
			() => {
				this.status = 'success';
			}
		);
	}

	public toggleView() {
		this.view = 'grid' === this.view ? 'table' : 'grid';
	}

	public getStatus(project) {
		const env = this.projectService.getDefaultEnvironment(project);
		if (!env) {
			return 'empty';
		}
		return env.fields?.cloud_id ? 'play' : 'paid';
	}

	public getOwnerName(project) {
		const access = this.authService.profile.data.account_access.find(
			(acc) => acc.account_id === project.fields.organization_id
		);

		let name = this.authService.profile.data.display_name || 'You';
		if (access) {
			name = access.display_name;
		}

		return name;
	}

	public getUrl(project) {
		let url: string;
		const defaultEnvironment = this.projectService.getDefaultEnvironment(project);

		if (defaultEnvironment?.fields?.has_publish_setup_user || defaultEnvironment?.fields?.cloud_id) {
			url = defaultEnvironment.fields.site_url + '?central=1';
		}

		return url;
	}

	public getScreenshotId(project) {
		const env = this.projectService.getVpsEnvironment(project);
		if (!env) {
			return;
		}

		let screenshotId = env.fields.site_url;
		if (!screenshotId) {
			const envrionmentWithScreenshot = project.children.find(
				(environment) => !!environment.fields.site_url
			);

			if (envrionmentWithScreenshot) {
				screenshotId = envrionmentWithScreenshot.fields.site_url;
			}
		}

		return screenshotId;
	}

	public refreshCollection() {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate(['/projects']));
	}

	/**
	 * Create a new project.
	 */
	public newProject(selectServer = false) {
		// Create project without opening dialog when there are no additional teams.
		if (this.teams.length === 1) {
			this.projectService.organizationId = this.teams[0].organization_id;
			this.createProject(selectServer);
			return;
		}

		const ref = this.dialog.open(NewProjectDialogComponent, {
			width: '500px',
		});

		ref.afterClosed().subscribe((res) => {
			// Cancelled dialog, abort.
			if (!res) {
				return;
			}
			this.projectService.organizationId = res.data;
			this.createProject(selectServer);
		});
	}

	public createProject(selectServer = false) {
		// Set state of progress button to be spinning.
		this.newProjectState = 'submitted';
		this.projectService.createProject({
				label: this.newProjectTitle,
				state: 'live',
			})
			.subscribe(
				(response: any) => {
					this.newProjectState = 'pending';
					this.router.navigate(['/projects', response.id]).then(() => {
						if (selectServer) {
							this.router.navigate(['/projects', response.id, 'add', 'hosting']);
						}
					});

				},
				() => {
					this.status = 'failed';
				}
			);
	}

	/**
	 * Checks existing titles, and prepares the new title for projects.
	 */
	public createProjectTitle() {
		let title = this.newProjectTitle;

		this.apiService.get('/v1/projects', {}).subscribe(
			(response: any) => {
				const result = response
					.map(({ label }) => label)
					.filter((label) => label.includes(title));

				// Loop through all titles for matches, and append N + 1
				for (let i = 0; i < result.length; i++) {
					if (result.includes(title)) {
						// regexr: https://regexr.com/77qo7  - match My Project 1, My Project 38748, ect.
						title =
							title.replace(/(\s\d+)(?!.*\s\d)/, '') +
							' ' +
							Math.abs(i + 1);
						this.newProjectTitle = title;
					}
				}
			},
			() => {
				this.status = 'failed';
			}
		);
	}

	teamCount() {
		this.apiService.get('/v1/organizations/memberships', {}).subscribe({
			next: (teams: any[]) => {
				this.teams = teams || [];
			},
			error: () => {},
		});
	}

	isWordpress(project) {
		if (project.fields.project_type === 'wordpress') {
			return true;
		}
	}
}
