import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

@Component({
	selector: 'snack-bar-component-example-snack',
	template: `
		<mat-spinner
			color="primary"
			class="spinner"
			diameter="20"
		></mat-spinner>
		{{ data?.label || 'Configuring WordPress' }}
	`,
	styles: [
		`
			.mat-spinner {
				display: inline-block;
				vertical-align: middle;
				margin-right: 1em;
			}
		`,
	],
})
export class SnackLoadingComponent {
	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
