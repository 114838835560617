import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomainChangeConfirmationComponent } from '../domain-change/domain-change-confirmation.component';

@Component({
	selector: 'central-subdomain',
	templateUrl: './subdomain.component.html',
	styleUrls: ['./subdomain.component.css'],
})
export class SubdomainComponent {
	@Input() public currentDomain: string;
	@Input() public state = 'pending';
	@Input() public usedDomains: string[] = [];

	@Output() subdomainBack = new EventEmitter();
	@Output() subdomainSubmit = new EventEmitter();

	public subdomain = '';

	constructor(public dialog: MatDialog) {}

	public formatNewDomain() {
		let domain = this.currentDomain;
		if (this.subdomain) {
			domain = this.subdomain + '.' + domain;
		}

		return domain;
	}

	public cleanSubdomain() {
		this.subdomain = this.subdomain.replace(/[^a-z0-9-\.]/gi, '');
	}

	public doSubmit() {
		console.log(this.formatNewDomain());
		if (this.usedDomains.includes(this.formatNewDomain())) {
			const dialogRef = this.dialog.open(
				DomainChangeConfirmationComponent,
				{
					width: '500px',
				}
			);
			dialogRef.afterClosed().subscribe((result) => {
				if (result) {
					this.subdomainSubmit.emit({
						baseDomain: this.currentDomain,
						subDomain: this.subdomain,
						fqdn: this.formatNewDomain(),
					});
				}
			});
		} else {
			this.subdomainSubmit.emit({
				baseDomain: this.currentDomain,
				subDomain: this.subdomain,
				fqdn: this.formatNewDomain(),
			});
		}
	}
}
