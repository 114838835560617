import backupPremium from '../products/backup/premium';

export default {
	label: 'Total Upkeep Premium',
	productKeys: ['wpc_tukp'],
	licenseNames: ['boldgrid-backup'],
	name: 'boldgrid-backup',
	tags: ['boldgrid-backup', 'premium'],
	color: 'primary',
	features: backupPremium,
};
