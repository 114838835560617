<mat-progress-bar
	*ngIf="'loading' === state"
	mode="indeterminate"
></mat-progress-bar>
<mat-sidenav-container
	class="sidenav-container"
	[class.organization-view]="this.accountService.isOrganization()"
	*ngIf="state === 'success'"
>
	<mat-sidenav [mode]="isMobile ? 'over' : 'side'" opened>
		<mat-nav-list>
			<mat-list-item routerLink="/" class="home">
				<mat-icon mat-list-icon>keyboard_arrow_left</mat-icon>
				<span matLine>Home</span>
			</mat-list-item>

			<div class="nav-items helper-nav">
				<mat-list-item
					routerLinkActive="router-link-active"
					*ngIf="this.accountService.isOrganization()"
					[routerLink]="[
					'/account/',
					this.authService.getAccountId(),
					'settings'
				]"
				>
					<mat-icon mat-list-icon>info</mat-icon>
					<span matLine>Personal Profile</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item
					routerLinkActive="router-link-active"
					*ngIf="this.accountService.isOrganization()"
					[routerLink]="this.getMixedRoute('organizations')"
				>
					<mat-icon mat-list-icon>people</mat-icon>
					<span matLine
						>My
						{{
							this.configService.config.groupLabels[
								'group-label-title-case'
							]
						}}s</span
					>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
			</div>

			<div
				class="identity"
				[class.organization]="this.accountService.isOrganization()"
			>
				<img
					class="ctw-text-center"
					*ngIf="this.accountService.isUserAccountOwner()"
					[src]="profileService.data.image"
				/>
				<div class="label">
					<p
						*ngIf="
							!this.accountService.isOrganization() ||
							!this.configService.config.brandConfig.applicationName.includes(
								'InMotion'
							)
						"
					>
						{{
							this.accountService.isOrganization()
								? this.configService.config.groupLabels[
										'group-label-title-case'
								  ] + ' Account'
								: 'Your Personal Account'
						}}
					</p>
					<h3>{{ this.currentAccount.display_name }}</h3>
				</div>
			</div>

			<div class="nav-items">
				<mat-list-item
					routerLinkActive="router-link-active"
					[routerLink]="this.getMixedRoute('settings')"
				>
					<mat-icon mat-list-icon>info</mat-icon>
					<span matLine>Profile</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item
					routerLinkActive="router-link-active"
					*ngIf="
						this.accountService.isOrganization() &&
						!this.accountService.isUserAccountOwner()
					"
					[routerLink]="this.getMixedRoute('members')"
				>
					<mat-icon mat-list-icon>people</mat-icon>
					<span matLine>Members</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item
					class="organizations"
					routerLinkActive="router-link-active"
					*ngIf="!this.accountService.isOrganization()"
					[routerLink]="this.getMixedRoute('organizations')"
				>
					<mat-icon mat-list-icon>people</mat-icon>
					<span matLine
						>{{
							this.configService.config.groupLabels[
								'group-label-title-case'
							]
						}}s</span
					>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item
					class="notifications"
					routerLinkActive="router-link-active"
					[routerLink]="this.getMixedRoute('notifications')"
					*ngIf="this.hasAccess({permission: 'support'})"
				>
					<mat-icon mat-list-icon>notifications</mat-icon>
					<span matLine>Notifications</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item
					class="support"
					routerLinkActive="router-link-active"
					[routerLink]="this.getMixedRoute('help/requests')"
					*ngIf="
						!this.profileService.data.requires_email_verification &&
						this.hasAccess({permission: 'support'})
					"
				>
					<mat-icon mat-list-icon>confirmation_number</mat-icon>
					<span matLine>Requests</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item
					routerLinkActive="router-link-active"
					[routerLinkActiveOptions]="{ exact: true }"
					[routerLink]="this.getMixedRoute('help')"
					*ngIf="this.hasAccess({permission: 'support'})"
				>
					<mat-icon mat-list-icon>help</mat-icon>
					<span matLine>Support</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<ng-container *ngFor="let item of this.projectMenuItems">
					<mat-list-item
						routerLinkActive="router-link-active"
						*ngIf="
							(this.accountService.isOrganization() &&
								item.organizationRoute &&
								this.hasAccess(item)) ||
							(!this.accountService.isOrganization() &&
								!this.configService.config.brandConfig.applicationName.includes(
									'InMotion'
								)) ||
							this.accountService.isUserOrganization()
						"
						[routerLink]="this.getMixedRoute(item.path)"
					>
						<mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
						<span matLine>{{ item.label }}</span>
						<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
					</mat-list-item>
				</ng-container>
			</div>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content class="ctw-p-8">
		<div class="mobileBar" *ngIf="this.isMobile">
			<button mat-raised-button color="primary" (click)="sidenav.open()">
				<mat-icon>menu</mat-icon> Menu
			</button>
		</div>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
