import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'central-install',
	templateUrl: './install.component.html',
	styleUrls: ['./install.component.scss'],
})
export class InstallComponent {
	constructor(public activatedRoute: ActivatedRoute) {}
}
