<div>
	<h3>Two Factor Authentication</h3>
	<p>
		Update Devices Authorized for Two Factor Authentication. When logging
		in, you will be able to select an enabled device to increase the
		security of your account.
	</p>
	<mat-spinner
		class="ctw-mx-auto ctw-mt-10"
		*ngIf="this.fetchSettingsState === 'loading'"
		[diameter]="30"
		[strokeWidth]="4"
	></mat-spinner>
	<div class="error" *ngIf="this.fetchSettingsState === 'failed'">
		Unable to load 2FA settings. Please try again later.
	</div>
	<table
		mat-table
		[dataSource]="this.dataSource"
		*ngIf="'success' === this.fetchSettingsState"
		[class.imh]="this.configService.config.brandConfig.id === 'imh'"
	>
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef>Type</th>
			<td mat-cell *matCellDef="let device">
				<mat-icon *ngIf="device.name === 'email'">email</mat-icon>
				<mat-icon *ngIf="device.name === 'sms'">phone</mat-icon>
			</td>
		</ng-container>

		<ng-container matColumnDef="message">
			<th mat-header-cell *matHeaderCellDef>Device</th>
			<td mat-cell *matCellDef="let device">
				<span *ngIf="device.message">{{ device.message }}</span>
				<span *ngIf="!device.message" class="error"
					>Device not enabled</span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="action" stickyEnd>
			<th mat-header-cell *matHeaderCellDef aria-label="row actions">
				&nbsp;
			</th>
			<td mat-cell *matCellDef="let device" class="ctw-text-right">
				<button
					mat-stroked-button
					*ngIf="!device.message"
					(click)="this.updateDevice(device, 'enable-device')"
					[class.mat-primary]="
						this.configService.config.brandConfig.id === 'imh'
					"
				>
					<mat-icon>add</mat-icon>
					<span>Enable Device</span>
				</button>
				<button
					mat-stroked-button
					color="warn"
					*ngIf="device.message"
					(click)="this.deleteDevice(device, 'enable-device')"
				>
					<span>Disable</span>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>

<ng-template #twoFaModal>
	<div class="two-fa-modal">
		<div
			[hidden]="!(this.state === 'enableDevice' && this.deviceToUpdate)"
			class="update-device-prompt"
		>
			<h3>Enable Two Factor Authentication</h3>
			<p>
				Enter the
				{{
					this.getDeviceDescription(this.deviceToUpdate?.name) ||
						'device'
				}}
				to use for two factor authentication below.
			</p>
			<div
				*ngIf="this.deviceToUpdate?.name === 'sms'"
				class="phone-input"
			>
				<central-phone-number
					value=""
					[isRequired]="true"
					#phoneNumber
				></central-phone-number>
			</div>
			<mat-form-field
				data-private
				appearance="fill"
				*ngIf="this.deviceToUpdate?.name === 'email'"
			>
				<input
					[(ngModel)]="this.emailInput"
					email
					type="email"
					#emailField="ngModel"
					required
					matInput
					name="primaryEmail"
					placeholder="jdoe@company.com"
				/>
				<mat-error>
					<ng-container *ngIf="emailField.errors?.required"
						>This field is required.</ng-container
					>
					<ng-container *ngIf="emailField.errors?.email"
						>Invalid Format. Please use the following format
						user@domain.tld</ng-container
					>
				</mat-error>
			</mat-form-field>

			<div class="update-message ctw-text-center ctw-mt-5">
				<div
					class="error"
					[class.visible]="this.sendCodeState === 'failed'"
				>
					{{ this.sendError }}
				</div>
			</div>

			<button
				mat-ripple
				mat-raised-button
				color="accent"
				class="btn-spinner send-code-button"
				(click)="this.handleSendCodeSubmit()"
				[disabled]="this.sendCodeState === 'submitted'"
			>
				<mat-spinner
					diameter="20"
					*ngIf="this.sendCodeState == 'submitted'"
				></mat-spinner>
				Send Code
			</button>
			<button
				type="button"
				(click)="this.handletwoFaCancel()"
				class="cancel-button"
				mat-raised-button
			>
				<mat-icon>arrow_back</mat-icon> Cancel
			</button>
		</div>

		<ng-container *ngIf="this.state === 'validateCode'">
			<central-two-factor-auth
				[twoFaUserData]="this.twoFaUserData"
				[action]="'update-twofactor'"
				inputAppearance="outline"
				[showDeviceSelection]="false"
				[showRememberMe]="false"
				[codeFailure]="this.sendCodeError"
				[state]="this.verifyState"
				[selection]="this.deviceToUpdate?.name"
				[customRetry]="true"
				(retry)="this.handleSendCodeSubmit()"
				(twoFaCancel)="this.handletwoFaCancel()"
				(twoFaSubmit)="this.handleVerifyCodeSubmit($event)"
			></central-two-factor-auth>
		</ng-container>

		<ng-container *ngIf="this.state === 'recoveryCodes'">
			<h3>Recovery Codes</h3>
			<p>
				Recovery codes are used to access your account in the event that
				you cannot access your device to receive a two-factor
				authentication code.
			</p>

			<div class="alert">
				<mat-icon>report_problem</mat-icon>Save your recovery codes
				before continuing.
			</div>

			<div class="recovery-codes">
				<ul>
					<li *ngFor="let code of this.recoveryCodes">{{ code }}</li>
				</ul>
			</div>
			<button
				type="button"
				color="primary"
				(click)="this.handletwoFaCancel()"
				class="cancel-button"
				mat-raised-button
			>
				Complete
			</button>
		</ng-container>
	</div>
</ng-template>
