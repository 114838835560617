<failed-request *ngIf="this.state === 'failed'"></failed-request>

<div class="wrap animate__animated animate__fadeIn ctw-mt-8 ctw-mx-auto ctw-max-w-4xl ctw-px-8 ctw-mb-12"
	*ngIf="this.state !== 'blocked' && this.state !== 'failed'">
	<h2>Server Cache</h2>
	<dash-card icon="manage_accounts" cardTitle="Nginx Profiles" class="dash-card profile" disabled="true">
		<div class="dash-card-content ctw-p-8">
			<p>
				An Nginx profile is a configuration for Nginx that is custom
				tailored to match your website's needs.
			</p>
			<form #f="ngForm" (ngSubmit)="updateProfile(f)" novalidate>
				<mat-radio-group [(ngModel)]="selectedProfile" (ngModelChange)="onProfileSelect($event)" name="profile"
					aria-label="Select your Nginx profile" class="ctw-flex ctw-flex-col ctw-py-4">
					<mat-radio-button *ngFor="let op of profiles" [checked]="selectedProfile === op.value"
						value="{{ op.value }}" name="profile">
						<div class="ctw-inline-flex ctw-w-24">
							{{ op.viewValue }}
						</div>
						<span class="ctw-text-sm" *ngIf="setProfile === op.value">&nbsp; (Active)</span>
					</mat-radio-button>
				</mat-radio-group>
				<p class="ctw-py-4">
					<i>{{ selectedDescription }}</i>
				</p>
				<div class="ctw-text-right">
					<central-progress-button [state]="state" type="submit" mat-raised-button color="primary" [disabled]="
							this.state === 'disabled' ||
							selectedProfile === setProfile
						">
						Switch to{{
						selectedProfile === setProfile
						? '...'
						: ' ' + this.getSeleted()
						}}
					</central-progress-button>
				</div>
				<p class="ctw-text-red-700 ctw-py-4" *ngIf="this.state === 'failed'">
					Please try again. {{ this.error }}
				</p>
				<p class="ctw-text-green-700 ctw-py-4" *ngIf="this.state === 'success'">
					Your Nginx settings have successfully been updated!
				</p>
				<p class="ctw-text-red-700 ctw-py-4" *ngIf="this.state === 'disabled'">
					Your Nginx settings are unable to be updated at this time,
					please contact
					<a href="/account/help/requests?open=chat">Support</a>.
				</p>
			</form>
		</div>
	</dash-card>
</div>
