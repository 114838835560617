<div class="sign-up ctw-absolute ctw-right-0 ctw-top-0">
	<div>New to the <strong>Platform</strong>?</div>
	<div><a class="mat-primary" routerLink="/sign-up">Create an Account</a></div>
</div>
<div class="login valign">
	<a href="{{ this.brandingService.getBrandOpt('marketingSite') }}" tabindex="-1">
		<img class="bg-logo ctw-mx-auto" src="{{ this.brandingService.getThemedAssetUrl('logo2.svg') }}" />
	</a>
	<ng-content></ng-content>
	<login-form [allowSignup]="false" *ngIf="state === 'pending'"></login-form>
	<div class="authenticating" *ngIf="state === 'authenticating'">
		<h3>Authenticating</h3>
		<mat-spinner diameter="50" color="accent"></mat-spinner>
	</div>
	<ng-container class="failed" *ngIf="state === 'failed'">
		<h3>Unable to Authenticate</h3>
		<p>Please go back and try again</p>
	</ng-container>
	<div class="links">
		<a href="{{ this.brandingService.getBrandOpt('supportUrl') }}" target="_blank" mat-button>Help</a>
		<a href="{{ this.brandingService.getBrandOpt('privacyPolicy') }}" target="_blank" mat-button>Privacy</a>
		<a href="{{ this.brandingService.getBrandOpt('termsOfService') }}" target="_blank" mat-button>Terms</a>
	</div>
</div>
