<failed-request *ngIf="this.state === 'failed'"></failed-request>

<div class="wrap animate__animated animate__fadeIn ctw-mt-8 ctw-mx-auto ctw-max-w-4xl ctw-px-8 ctw-mb-12"
	*ngIf="this.state !== 'blocked' && this.state !== 'failed'">
	<h2>PHP Settings</h2>
	<dash-card icon="code" cardTitle="PHP Version" class="dash-card profile" disabled="true">
		<div class="dash-card-content ctw-p-8">
			<p>
				The WordPress community currently
				<a href="https://wordpress.org/about/requirements/" target="_blank">
					recommends at least PHP 7.4</a>
				for maximum compatibility with older plugins. However, PHP
				versions less than PHP 8.2 have reached official
				<a href="https://www.php.net/eol.php" target="_blank">End Of Life</a>
				and thus are
				<a href="https://www.php.net/supported-versions.php" target="_blank">not recommended by PHP</a>.
				WordPress core is compatible with PHP 8.2+. PHP 8.2+ also has
				some speed improvements over 7.4.
			</p>
			<p>
				We recommend the most recent version of PHP (8.3) that your site is
				compatible with but a minimum of PHP 8.2.
			</p>
			<form #f="ngForm" (ngSubmit)="openDialog(f)" novalidate>
				<mat-radio-group [(ngModel)]="selectedProfile" name="profile" aria-label="Select your PHP version"
					class="ctw-flex ctw-flex-col ctw-py-4">
					<ng-container *ngFor="let op of versions">
						<mat-radio-button *ngIf="op.allowed || selectedProfile === op.version"
							[checked]="selectedProfile === op.version" [disabled]="!op.allowed" value="{{ op.version }}"
							name="profile" [class]="op.eol ? 'ctw-text-imh-gray-200' : ''">
							<div class="ctw-inline-flex ctw-w-24">
								PHP {{ op.version }}
							</div>
							<span class="ctw-text-sm ctw-mr-2 mat-stroked-button ctw-text-green-700"
								*ngIf="op.recommended">
								Recommended</span>
							<span class="ctw-text-sm ctw-mr-2 mat-stroked-button ctw-text-yellow-700"
								*ngIf="op.securityFixesOnly">Security Fixes Only</span>
							<span class="ctw-text-sm ctw-mr-2 mat-stroked-button ctw-text-red-700"
								*ngIf="op.eol">EOL</span>
							<span class="ctw-text-sm" *ngIf="setProfile === op.version">&nbsp; (Active)</span>
						</mat-radio-button>
					</ng-container>
				</mat-radio-group>
				<div class="ctw-text-right">
					<button *ngIf="this.projectService.environment.fields.environment_usage === 'unknown'" mat-button
						mat-ripple type="button" routerLink="../site" class="ctw-mr-2">
						Back
					</button>
					<central-progress-button [state]="state" type="submit" mat-raised-button color="primary" [disabled]="
							'disabled' === this.state ||
							selectedProfile === setProfile
						">
						Switch to{{
						selectedProfile === setProfile
						? '...'
						: ' PHP ' + selectedProfile
						}}</central-progress-button>
				</div>
				<p class="ctw-text-red-700" *ngIf="this.state === 'failed'">
					Please try again. {{ this.error }}
				</p>
				<p class="ctw-text-green-700" *ngIf="this.state === 'success'">
					Your PHP settings have successfully been updated!
				</p>
				<p class="ctw-text-red-700" *ngIf="this.state === 'disabled'">
					Your PHP Settings are currently unable to be changed. Please
					contact
					<a href="/account/help/requests?open=chat">Support</a>
					for assistance.
				</p>
			</form>
		</div>
	</dash-card>
</div>
