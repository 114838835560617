<div
	class="wrap animate__animated animate__fadeIn ctw-mt-8 ctw-mx-auto ctw-max-w-4xl ctw-px-8 ctw-mb-12"
>
	<h2>Connection Error</h2>
	<dash-card [icon]="this.icon" [cardTitle]="this.title">
		<ng-container subTitleHTML *ngIf="this.issue !== ''">
			<span class="sub-title ctw-text-base">
				<span class="ctw-text-red-700">{{ this.issue }}</span>
			</span>
		</ng-container>
		<mat-progress-bar
			*ngIf="
				state !== 'unavailable' &&
				(!this.recommended.length ||
					state === 'loading' ||
					status === 'submitted')
			"
			mode="indeterminate"
			color="primary"
		></mat-progress-bar>
		<div
			class="ash-card-content ctw-p-8"
			*ngIf="
				this.state === 'unavailable' ||
				this.recommended.length ||
				status === 'submitted'
			"
		>
			<div
				class="ctw-mb-4"
				*ngIf="this.status === 'pending' || status === 'submitted'"
			>
				We were unable to establish a connection with your site:
				<a [href]="this.url" target="_blank">{{ this.url }}</a
				>.
			</div>
			<ng-container *ngIf="state === 'unavailable'">
				<div class="ctw-mt-5 ctw-text-right">
					<button
						mat-button
						mat-raised-button
						color="primary"
						(click)="this.connect.emit()"
					>
						Retry Connection
					</button>
				</div>
			</ng-container>
			<central-connection-error-dialog
				*ngIf="this.state !== 'unavailable'"
				[state]="this.status"
				[diag]="this.recommended"
				(fix)="this.runDiag($event)"
			></central-connection-error-dialog>
		</div>
	</dash-card>
</div>
