<bgc-filter-nav
	[steps]="this.steps"
	[filters]="this.filters"
	(search)="this.search($event)"
	(next)="this.onNext()"
	(back)="this.back.emit()"
>
</bgc-filter-nav>
<custom-url
	#customURL
	[customUrls]="this.selectedItems"
	[hidden]="this.view !== 'customURL'"
	(removeEvent)="this.removeCustom($event)"
	(addEvent)="this.addCustom($event)"
></custom-url>

<div [hidden]="this.view !== 'search'">
	<div class="plugins">
		<mat-card
			*ngFor="let plugin of this.response['plugins']"
			class="plugin"
		>
			<div class="plugin__info">
				<div class="plugin-icon">
					<img
						*ngIf="plugin.icons"
						[src]="plugin.icons.default || plugin.icons['1x']"
					/>
				</div>
				<div class="details">
					<div class="plugin__title">
						<a
							target="_blank"
							[href]="
								'https://wordpress.org/plugins/' + plugin.slug
							"
							>{{ this.decode(plugin.name) }}</a
						>
					</div>
					<div>
						{{ this.decode(plugin.short_description) }}
					</div>
					<p>
						By
						<a href="{{ plugin.author_profile }}" target="_blank">{{
							this.getAuthorProfile(plugin.author_profile)
						}}</a>
					</p>
				</div>
				<div class="fill"></div>
				<div class="actions">
					<ng-container *ngIf="!action">
						<button
							*ngIf="!isSelected(plugin)"
							mat-button
							mat-raised-button
							(click)="selection(plugin)"
						>
							Select
						</button>
						<button
							*ngIf="isSelected(plugin)"
							mat-button
							mat-raised-button
							color="primary"
							(click)="deselect(plugin)"
						>
							<mat-icon>check</mat-icon>
						</button>
					</ng-container>
					<ng-container *ngIf="action">
						<central-progress-button
							[state]="plugin.state"
							(click)="action.install.event(plugin)"
							*ngIf="action.pluginType(plugin) === 'installable'"
							color="default"
							>{{ action.install.label }}</central-progress-button
						>
						<central-progress-button
							[state]="plugin.state"
							(click)="action.delete.event(plugin)"
							*ngIf="action.pluginType(plugin) === 'activatable'"
							color="default"
							>{{ action.delete.label }}</central-progress-button
						>
						<central-progress-button
							[state]="plugin.state"
							(click)="action.activate.event(plugin)"
							*ngIf="action.pluginType(plugin) === 'activatable'"
							color="default"
							>{{ action.activate.label }}</central-progress-button
						>
						<central-progress-button
							[state]="plugin.state"
							(click)="action.deactivate.event(plugin)"
							*ngIf="
								action.pluginType(plugin) === 'deactivatable'
							"
							color="default"
							>{{ action.deactivate.label }}</central-progress-button
						>
					</ng-container>
					<a
						target="_blank"
						[href]="'https://wordpress.org/plugins/' + plugin.slug"
						>More Details</a
					>
				</div>
			</div>
			<div class="ratings">
				<div>
					<bgc-rating [score]="plugin.rating"></bgc-rating>
					<span class="rating-count">({{ plugin.num_ratings }})</span>
					<div>
						{{ this.getCount(plugin.active_installs) }} Active
						Installations
					</div>
				</div>
				<div class="updated">
					Last Updated: {{ getDate(plugin.last_updated) | amTimeAgo }}
				</div>
			</div>
		</mat-card>
	</div>
	<div
		class="ctw-text-center no-results"
		*ngIf="
			this.status !== 'loading' &&
			this.response['plugins'] &&
			!this.response['plugins'].length
		"
	>
		<div class="title">No Results Found</div>
		<p>We did not find any themes with the given search term</p>
	</div>
	<ng-container *ngIf="this.status === 'failed'">
		<div class="ctw-text-center no-results">
			<div class="title">An unexpected error occurred</div>
			<p>
				Your results where not returned successfully. Please refresh the
				page and try again.
			</p>
		</div>
	</ng-container>
</div>
<div
	class="bottom-drawer"
	(click)="this.openSheet()"
	*ngIf="this.selectedItems.length"
>
	<span *ngIf="!this.selectedItems.length">No Plugins Selected</span>
	<span *ngIf="this.selectedItems.length"
		>{{ this.selectedItems.length }}
		{{ this.selectedItems.length === 1 ? 'Plugin' : 'Plugins' }}
		Selected</span
	>
	<mat-icon>keyboard_arrow_up</mat-icon>
</div>
<mat-spinner
	*ngIf="this.status === 'loading'"
	color="accent"
	diameter="50"
></mat-spinner>
