import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AccountService, ApiService, AuthService } from '@central/ng-shared';
import currency from 'currency.js';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

@Component({
	selector: 'app-subscription',
	templateUrl: './subscription.component.html',
	styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
	public currencyJs = currency;
	public status = 'loading';
	public subscriptions = [];
	public projects = {
		active: [],
		inactive: [],
	};

	constructor(
		public dialog: MatDialog,
		public accountService: AccountService,
		public apiService: ApiService,
		public authService: AuthService
	) {}

	ngOnInit() {
		this.getSubscriptions();
	}

	fetchProjects(): void {
		this.apiService.get('/v1/projects', {}).subscribe(
			(active: any) => {
				this.projects.active = active;
				this.apiService.get('/v1/projects/history', {}).subscribe(
					(inactive: any) => {
						this.projects.inactive = inactive;
						this.status = 'success';
					},
					() => {
						this.status = 'success';
					}
				);
			},
			() => (this.status = 'failed')
		);
	}

	getSubscriptions(): void {
		const accountId = this.accountService.id;
		this.apiService
			.get(`/v1/wpcr/accounts/${accountId}/subscriptions`, {})
			.subscribe(
				(subscriptions: any) => {
					this.subscriptions = subscriptions;
					this.fetchProjects();
				},
				() => (this.status = 'failed')
			);
	}

	getProjectLabelById(projectId): object {
		const active = this.projects.active.find((prj) => prj.id === projectId);
		const inactive = this.projects.inactive.find(
			(prj) => prj.id === projectId
		);
		const status = active ? 'active' : 'inactive';
		const label = active ? active.label : inactive.label;
		return { status, label };
	}

	openDialog(data) {
		// Pass in organization ID for the various methods in dialog component to use.
		data.organizationId = this.accountService.id;
		data.isOrg =
			this.authService.profile.data.type === 'org' &&
			this.authService.profile.data.account_access[0].account_id !==
				this.accountService.id;

		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			data,
			width: '500px',
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.subscriptions = this.subscriptions.map((obj) => obj.uuid === result.uuid ? result : obj);
			}
		});
	}

	getRenewalDate(subscription): string {
		const now = new Date();
		const startedAt = new Date(subscription.current_term_started_at);
		return now < startedAt
			? subscription.current_term_started_at
			: subscription.current_term_ends_at;
	}
}
