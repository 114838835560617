import { Component, OnInit } from '@angular/core';
import { ApiService, ConfigurationService } from '../../core';
import { ProfileService } from '../../authentication/services';
import { ZendService } from '../zendesk/zend.service';
import { ActivatedRoute } from '@angular/router';
import { SupportService } from '../support.service';
import { MessageComponent } from '../message/message.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AgentAccessDialogComponent } from '../agent-access-dialog/agent-access-dialog.component';

import { ScriptService } from '../../core/script/script.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'central-list-tickets',
	templateUrl: './list-tickets.component.html',
	styleUrls: ['./list-tickets.component.scss', '../ticket-view.scss'],
})
export class ListTicketsComponent implements OnInit {
	public status = 'start';
	public selectedTabIndex;
	public openingChat = false;

	public accountTickets = {
		tickets: [],
		openTickets: 0,
		label: 'cloud',
	};

	public contextTabTickets = {
		tickets: [],
		openTickets: 0,
		label: '',
	};

	public jwt;
	public dialogRef: MatDialogRef<MessageComponent>;

	constructor(
		public zendService: ZendService,
		public apiService: ApiService,
		public supportService: SupportService,
		public profileService: ProfileService,
		public activeRoute: ActivatedRoute,
		public dialog: MatDialog,
		public scriptService: ScriptService,
		public configService: ConfigurationService
	) {}

	ngOnInit(): void {
		this.fetchTickets();

		if ('chat' === this.activeRoute.snapshot.queryParams.open) {
			this.openChat();
		} else if ('ticket' === this.activeRoute.snapshot.queryParams.open) {
			this.openDialog();
		}
	}

	public openAgentAccessDialog() {
		this.dialog.open(AgentAccessDialogComponent, {
			width: '600px',
		});
	}

	public fetchTickets() {
		this.status = 'start';

		const account = this.supportService.getAccount();
		const cloudId = this.supportService.getCloud()
			? this.supportService.getCloud().id
			: null;
		const customFieldCloudId = this.zendService.customFieldCloudId;

		const accountTickets = {
			tickets: [],
			openTickets: null,
			label: account.name ? account.name : account.display_name,
		};

		const cloudTickets = {
			tickets: [],
			openTickets: null,
			label: cloudId ? this.supportService.getCloud().label : '',
		};

		this.zendService
			.listTickets(
				account.organization_id
					? account.organization_id
					: account.account_id
			)
			.subscribe(
				(ticketList: any[]) => {
					let cloud: string;
					this.status = 'success';
					ticketList['tickets'].forEach(function (ticket) {
						cloud = '';

						const customField = ticket['custom_fields'].find(
							(val) => val.id === customFieldCloudId
						);
						cloud = customField?.value || null;

						if (cloud && cloud === cloudId) {
							cloudTickets.tickets.push(ticket);
							if (ticket.status !== 'closed') {
								cloudTickets.openTickets++;
							}
						} else {
							accountTickets.tickets.push(ticket);
							if (ticket.status !== 'closed') {
								accountTickets.openTickets++;
							}
						}
					});

					this.contextTabTickets = cloudTickets;
					this.accountTickets = accountTickets;
				},
				(error) => {
					this.status = 'failed';
				}
			);
	}

	public openDialog() {
		let cloudId;

		// If on the first tab, set cloud. First tab is cloud tab.
		if (this.selectedTabIndex === 0) {
			cloudId = this.supportService.getCloud()
				? this.supportService.getCloud().id
				: null;
		}

		this.dialogRef = this.dialog.open(MessageComponent, {
			width: '600px',
			data: {
				userSetting: null,
				cloudId,
				cloud: this.supportService.getCloud(),
				priorities: this.supportService.ticketPriorities,
				typeOptions: this.configService.config.zendeskTicketTypes,
				selectedType: this.configService.config.zendeskDefaultType,
				inputs: this.configService.config.zendesk.inputs,
				account: this.supportService.getAccount(),
			},
		});

		this.dialogRef.afterClosed().subscribe(() => {
			if (this.dialogRef.componentInstance.state === 'success') {
				this.fetchTickets();
			}
		});


		this.dialogRef.componentInstance.submitTicket.subscribe((ticket_data) => {
			this.submitTicket(ticket_data);
		});
	}

	openChat() {
		this.openingChat = true;
		if (window['zE']) {
			if (window['zE'].widget === 'classic') {
				window['zE']('webWidget', 'show');
				window['zE']('webWidget', 'open');
			}
			if (window['zE'].widget === 'messenger') {
				window['zE']('messenger', 'show');
				window['zE']('messenger', 'open');
			}
			setTimeout(() => (this.openingChat = false), 2000);
		}
	}

	public submitTicket(ticket_data) {
		this.dialogRef.componentInstance.state = 'submitted';
		this.supportService.submitTicket(ticket_data)
			.subscribe({
				next: (val) => {
					if (val.hasOwnProperty('ticket_id')) {
						this.dialogRef.componentInstance.state = 'success';
					} else {
						this.dialogRef.componentInstance.state = 'failed';
						if (400 === val['status']) {
							this.dialogRef.componentInstance.state = 'ratelimited';
						}
					}
				},
				error: () => {
					this.dialogRef.componentInstance.state = 'failed';
				},
			});
	}
}
