import { WpRestService } from '../project/site/rest.service';
import { Observable, concat } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';

export class Installer {
	public state = 'available';

	constructor(
		private wpRestService: WpRestService,
		private environment: Environment,
		private snap: Snap,
		private messenger = new EventEmitter()
	) {
		// Foward all events from the rest api to the messager.
		this.wpRestService.connection.subscribe((event) => {
			this.messenger.emit(event.message);
		});
	}

	public getSiteUrl() {
		return this.environment.fields.site_url;
	}

	public installSnap() {
		this.state = 'installing_snap';

		const observables = [];
		(this.snap?.config.plugins || []).map((plugin) => {
			observables.push(this.installResource('plugin', plugin));
		});

		(this.snap?.config.themes || []).map((theme) => {
			observables.push(this.installResource('theme', theme));
		});

		return concat(...observables).pipe(
			map((result) => {
				this.state = 'available';
				return result;
			})
		);
	}

	private installResource(type: string, resource: any) {
		const payload = {};
		const file = resource.url || resource.slug;
		payload[`${type}s`] = [file];

		if ('plugin' === type) {
			payload['activate'] = true;
		} else {
			payload['active_stylesheet'] = file;
		}

		return new Observable((observer) => {
			this.wpRestService.getClient(this.environment, true).subscribe(
				(rest: any) => {
					this.messenger.emit('Installing: ' + file);

					rest.namespace('bgc/v1')
						[`${type}s`]()
						.create(payload)
						.then((response) => {
							this.messenger.emit('Installing Complete: ' + file);

							observer.next({
								request: file,
								response,
							});
							observer.complete();
						})
						.catch((error) => {
							console.log(error);
							observer.error(error);
						});
				},
				(error) => {
					console.log('Unable to fetch client', error);
					observer.error(error);
				}
			);
		});
	}
}
