<link href="https://js.recurly.com/v4/recurly.css" rel="stylesheet" type="text/css" />

<form data-private class="field" #field>
    <label>Credit Card</label>
    <div id="recurly-elements"></div>
    <input type="hidden" name="recurly-token" data-recurly="token" />

    <ng-container *ngIf="this.address">
        <ng-container *ngFor="let item of address | keyvalue">
            <input *ngIf="item.value" [name]="item.key" type="hidden" [attr.value]="item.value"
                [attr.data-recurly]="item.key" autocomplete="{{ item.key }} name" />
        </ng-container>
    </ng-container>
</form>