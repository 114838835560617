export default {
	name: 'cloud-wp-tier-2-yearly',
	productKeys: ['cwp_tier2'],
	licenseNames: [],
	label: '10 Instances',
	color: 'accent',
	tags: ['cloud-wordpress', 'premium'],
	features: [
		{
			label: 'Cloud WordPress',
			type: 'separator',
		},
		{
			label: '10 Additional Instances',
		},
		{
			label: 'Attribution Removal',
		},
	],
};
