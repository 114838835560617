import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FacebookService } from './facebook/facebook.service';
import { GoogleAnalyticsService } from './google/google.service';
import { OptimizelyService } from './optimizely/optimizely.service';
import { TwitterAnalyticsService } from './twitter/twitter.service';
import { BingService } from './bing/bing.service';
import { HubspotService } from './hubspot/hubspot.service';
import { LinkedinService } from './linkedin/linkedin.service';
import { ConfigurationService } from '../core/configuration.service';
import { CoreModule } from '../core/core.module';

@NgModule({
	imports: [CommonModule, CoreModule],
	providers: [
		FacebookService,
		TwitterAnalyticsService,
		BingService,
		OptimizelyService,
		GoogleAnalyticsService,
		HubspotService,
		LinkedinService,
	],
})
export class TrackingModule {
	static forRoot(config): ModuleWithProviders<TrackingModule> {
		return {
			ngModule: TrackingModule,
			providers: [
				{
					provide: ConfigurationService,
					useFactory: () => new ConfigurationService(config),
				},
			],
		};
	}
}
