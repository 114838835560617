import {
	Component,
	Output,
	EventEmitter,
	ViewChild,
	Input,
	OnInit,
} from '@angular/core';

@Component({
	selector: 'custom-url',
	templateUrl: './custom-url.component.html',
	styleUrls: ['custom-url.component.scss'],
})
export class CustomUrlComponent {
	@ViewChild('form') public form;
	@Input() public resourceType = 'Plugin';
	@Input() public limit = 99;
	@Input() public customUrls = [];
	@Output() public addEvent = new EventEmitter();
	@Output() public removeEvent = new EventEmitter();

	public zip = '';
	public name = '';
	public description = '';

	public addURL(): void {
		const config = {
			type: 'custom',
			url: this.zip,
			name: this.name,
			short_description: this.description,
		};

		this.addEvent.emit(config);

		this.zip = '';
		this.name = '';
		this.description = '';
		this.form.resetForm();
	}

	public customUrlsCount(): number {
		return this.customUrls.filter((val) => val.type === 'custom').length;
	}

	public removeURL(config: object): void {
		this.removeEvent.emit(config);
	}
}
