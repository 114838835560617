export const DEFAULT_THIRD_PARTY_DNS_ZONE_DATA = [
	{
		name: '@',
		type: 'SOA',
		ttl: 3600,
		rdata: {
			soaMname: 'ns1.inmotionhosting.com.',
			soaRname: 'null.inmotionhosting.com.',
			soaSerial:
				new Date().toISOString().substr(0, 10).replace(/-/g, '') + '01',
			soaRefresh: 86400,
			soaRetry: 7200,
			soaExpire: 3600000,
			soaMinimum: 172800,
		},
	},
	{
		name: '@',
		type: 'NS',
		ttl: 14400,
		rdata: {
			nsValue: 'ns1.inmotionhosting.com.',
		},
	},
	{
		name: '@',
		type: 'NS',
		ttl: 14400,
		rdata: {
			nsValue: 'ns2.inmotionhosting.com.',
		},
	},
];

export const DEFAULT_REGISTERABLE_TLDS = [
	'.com',
	'.net',
	'.org',
	'.biz',
	'.info',
];
