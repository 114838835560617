<div class="ctw-mx-auto ctw-max-w-4xl ctw-px-8">
	<div class="ctw-text-right ctw-mb-8">
		<button
			mat-button
			mat-raised-button
			color="secondary"
			routerLink="../{{ this.projectService.project.id }}"
		>
			I'll Do This Later
		</button>
	</div>
	<dash-card
		*ngIf="this.mode === 'new' || this.mode === 'migrate'"
		[showHeader]="true"
		cardTitle="Migrate Automatically"
		icon="cloud_upload"
		disabled="true"
	>
		<div
			*ngIf="this.mode === 'new'"
			class="site-setup-option ctw-mb-8"
			(click)="this.mode = 'migrate'"
		>
			<!--<mat-icon>cloud_upload</mat-icon>-->
			<h3>Let Us Migrate Your Existing Site For You</h3>
			<p>Best for small sites</p>
		</div>
		<div
			*ngIf="this.mode === 'migrate' && this.state !== 'success'"
			class="ctw-grid ctw-grid-cols-1 domain-input"
		>
			<p class="alert ctw-text-base ctw-mb-8 ctw-text-left ctw-mt-0">
				<strong>IMPORTANT:</strong> Your Ultrastack VPS comes
				provisioned with PHP 8.0. If the site you are migrating is not
				running on PHP 8.0 or higher, we recommend that you first
				<a routerLink="php-settings">change your PHP version</a> to
				match the version where you are migrating from before
				proceeding. You can always switch back to PHP 8.0 or higher
				after you’ve confirmed compatibility with your site.
			</p>
			<h4>
				We just need a few details about the website you wish to migrate
			</h4>
			<mat-form-field appearance="standard" floatLabel="always">
				<mat-label>Site URL</mat-label>
				<!--<input required matInput type="text" [(ngModel)]="this.siteUrl" [formControl]="domainValidator" autofocus
          placeholder="https://example.com">-->
				<input
					required
					matInput
					type="text"
					[(ngModel)]="this.siteUrl"
					autofocus
					placeholder="https://example.com"
				/>
				<!--<mat-error *ngIf="domainValidator.hasError('pattern')">Not a valid domain name</mat-error>-->
			</mat-form-field>
			<div class="ctw-flex ctw-justify-between ctw-gap-4">
				<mat-form-field appearance="standard" floatLabel="always">
					<mat-label>Administrator Username</mat-label>
					<input
						required
						matInput
						name="username"
						[(ngModel)]="this.username"
						type="text"
					/>
				</mat-form-field>
				<mat-form-field appearance="standard" floatLabel="always">
					<mat-label>Administrator Password</mat-label>
					<input
						required
						matInput
						name="password"
						[(ngModel)]="this.password"
						[type]="inputType"
					/>
					<button
						type="button"
						mat-button
						matSuffix
						mat-icon-button
						aria-label="Show Password"
						(click)="
							inputType =
								'password' === inputType ? 'text' : 'password'
						"
					>
						<mat-icon>remove_red_eye</mat-icon>
					</button>
				</mat-form-field>
			</div>
			<div class="ctw-flex ctw-justify-between">
				<div>
					<button
						mat-stroked-button
						mat-ripple
						type="button"
						routerLink="php-settings"
						class="ctw-mr-2"
					>
						Change PHP Version
					</button>
				</div>
				<div class="ctw-text-right">
					<button
						[disabled]="
							state === 'submitted' || state === 'success'
						"
						mat-button
						type="button"
						(click)="this.mode = 'new'"
						class="ctw-mr-2"
					>
						Back
					</button>
					<central-progress-button
						[disabled]="
							state === 'submitted' || state === 'success'
						"
						mat-raised-button
						color="primary"
						[disabled]="!formValid()"
						[state]="progressButtonState"
						(click)="submitTransfer()"
						>Migrate</central-progress-button
					>
				</div>
			</div>
			<p class="ctw-text-red-700 ctw-py-4" *ngIf="this.state === 'failed'">
				There was an error connecting.<br />{{ this.error }}
			</p>
			<p
				class="ctw-text-green-700 ctw-py-4"
				*ngIf="this.state === 'success'"
			>
				Connection success!
			</p>
		</div>

		<div *ngIf="this.mode === 'migrate' && this.state === 'success'">
			<mat-progress-bar
				mode="indeterminate"
				color="primary"
			></mat-progress-bar>
			<div class="in-progress ctw-p-8">
				<h2 class="ctw-text-center">Site Transfer In Progress</h2>
				<p>
					We're copying over your site now. Since sites can vary in
					size, this process may take several minutes. For this
					process to fully complete, please leave this tab open. We'll
					show you a notification when it completes.
				</p>
			</div>
		</div>
	</dash-card>
	<dash-card
		*ngIf="this.mode === 'new' || this.mode === 'confirm-manual'"
		[showHeader]="true"
		cardTitle="Migrate Manually"
		icon="build"
		disabled="true"
	>
		<div
			*ngIf="this.mode === 'new'"
			class="site-setup-option ctw-mb-8"
			(click)="this.mode = 'confirm-manual'"
		>
			<!--<mat-icon>build</mat-icon>-->
			<h3>Copy Your Content From Another WordPress Yourself</h3>
			<p>Best for large or ecommerce sites</p>
		</div>
		<div
			*ngIf="this.mode === 'confirm-manual'"
			class="ctw-grid ctw-grid-cols-1 domain-input"
		>
			<h4>You're all set!</h4>
			<p class="ctw-mb-0">
				We've already installed a fresh copy of the latest WordPress on
				your server. All you need to do now to migrate your site’s files
				and data is to use your preferred method of transferring. You
				can use
				<a
					href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/projects/migrate-wordpress-manually"
					target="_blank"
					>Plugins, sFTP and other command line options</a
				>.
			</p>
			<p class="alert ctw-text-base ctw-mb-8 ctw-text-left">
				<strong>IMPORTANT:</strong> Your Ultrastack VPS comes
				provisioned with PHP 8.0. If the site you are migrating is not
				running on PHP 8.0 or higher, we recommend that you first
				<a routerLink="php-settings">change your PHP version</a> to
				match the version where you are migrating from before
				proceeding. You can always switch back to PHP 8.0 or higher
				after you’ve confirmed compatibility with your site.
			</p>
			<div class="ctw-flex ctw-justify-between">
				<div>
					<button
						mat-stroked-button
						mat-ripple
						type="button"
						routerLink="php-settings"
						class="ctw-mr-2"
					>
						Change PHP Version
					</button>
				</div>
				<div class="ctw-text-right">
					<button
						mat-button
						mat-ripple
						type="button"
						(click)="this.mode = 'new'"
						class="ctw-mr-2"
					>
						Back
					</button>
					<central-progress-button
						mat-raised-button
						color="primary"
						(click)="setUsage('Manual Migrate')"
						>Ok, Continue</central-progress-button
					>
				</div>
			</div>
		</div>
	</dash-card>
	<dash-card
		*ngIf="this.mode === 'new' || this.mode === 'confirm-new'"
		[showHeader]="true"
		cardTitle="Start From Scratch"
		icon="edit"
		disabled="true"
	>
		<div
			*ngIf="this.mode === 'new'"
			class="site-setup-option ctw-mb-8"
			(click)="this.mode = 'confirm-new'"
		>
			<!--<mat-icon>edit</mat-icon>-->
			<h3>Get Started With A Fresh WordPress Installation</h3>
			<p>Jump right into your Dashboard</p>
		</div>
		<div
			*ngIf="this.mode === 'confirm-new'"
			class="ctw-grid ctw-grid-cols-1 domain-input"
		>
			<h4>Let’s get started!</h4>
			<p class="ctw-mb-8">
				We've already installed a fresh copy of the latest WordPress on
				your server. It’s been fine-tuned for performance and lean
				speed. All you need to do is start creating!
			</p>
			<div class="ctw-text-right">
				<button
					mat-button
					mat-ripple
					type="button"
					(click)="this.mode = 'new'"
					class="ctw-mr-2"
				>
					Back
				</button>
				<central-progress-button
					mat-raised-button
					color="primary"
					(click)="setUsage('New Site')"
					>Let's Go</central-progress-button
				>
			</div>
		</div>
	</dash-card>
</div>
