<div class="valign-wrapper centered-content page-not-found">
	<div class="container ctw-text-center valign">
		<bgc-header-logo></bgc-header-logo>
		<h1>404: Page Not Found</h1>
		<p>The page you requested could not be found.</p>
		<button [routerLink]="['/']" mat-raised-button color="primary">
			Back Home
		</button>
	</div>
</div>
