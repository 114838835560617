export default [
	{
		label: 'Connect Account',
		type: 'separator',
	},
	{
		label: '1 Website',
	},
	{
		label: '1 Cloud WordPress Install',
	},
	{
		label: 'BoldGrid Attribution',
	},
];
