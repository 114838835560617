<span *ngFor="let count of [1, 2, 3, 4, 5]">
	<mat-icon *ngIf="score >= count * 20 || count * 20 - score < 5"
		>star</mat-icon
	>
	<mat-icon *ngIf="count * 20 - score <= 15 && count * 20 - score >= 5"
		>star_half</mat-icon
	>
	<mat-icon *ngIf="count * 20 - score > 15 && score < count * 20"
		>star_border</mat-icon
	>
</span>
