import { Component, ViewChild, OnInit } from '@angular/core';
import { AccountService } from '@central/ng-shared';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'central-addons',
	templateUrl: './addons.component.html',
	styleUrls: ['./addons.component.scss'],
})
export class AddonsComponent implements OnInit {
	@ViewChild('payment', { static: true }) public payment: any;

	public isReady = false;
	public title = '';

	constructor(
		public accountService: AccountService,
		public route: ActivatedRoute,
	) {}

	ngOnInit(): void {
		this.route.data.subscribe( data => {
			this.payment.useCartService = true;
			this.title = data.title;
			this.isReady = true;
		});
	}
}
