<div class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray">
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<h2>{{title}}</h2>
	</div>
	<mat-progress-bar *ngIf="!this.isReady" mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-pt-16 ctw-mb-12 content ctw-text-center"
	[class.ctw-hidden]="!this.isReady">
	<payment-form
		#payment
		cardClass=""
		[includeReceiptQuery]="true"
		[excludedInputs]="['organization']"
		[includeBackButton]="false"
	>
	</payment-form>
</div>
