import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectService } from '../../project.service';

@Component({
	selector: 'app-preview',
	templateUrl: './preview.component.html',
	styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
	public url: string;
	public sanitizedUrl;

	constructor(
		public projectService: ProjectService,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.url = this.projectService.environment.fields.site_url;
		this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
			this.url
		);
	}
}
