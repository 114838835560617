export default {
	name: 'cloud-wp-tier-3-yearly',
	productKeys: ['cwp_tier3'],
	licenseNames: [],
	label: '20 Instances',
	color: 'primary',
	tags: ['cloud-wordpress', 'premium'],
	features: [
		{
			label: 'Cloud WordPress',
			type: 'separator',
		},
		{
			label: '20 Additional Instances',
		},
		{
			label: 'Attribution Removal',
		},
	],
};
