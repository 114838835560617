export default {
	name: 'cloud-wp-tier-1-yearly',
	productKeys: ['cwp_tier1'],
	licenseNames: [],
	label: '5 Instances',
	color: 'secondary-accent',
	tags: ['cloud-wordpress', 'premium'],
	features: [
		{
			label: 'Cloud WordPress',
			type: 'separator',
		},
		{
			label: '5 Additional Instances',
		},
		{
			label: 'Attribution Removal',
		},
	],
};
