<dash-card cardTitle="Ansible Access">
	<ng-container subTitleHTML *ngIf="this.state === 'success'">
		<span class="sub-title ctw-text-base">
			<span
				class="{{
					this.overrideState === 'Disabled'
						? 'ctw-text-green-700'
						: 'ctw-text-red-700'
				}}"
			>
				{{ this.overrideState === 'Disabled' ? 'Enabled' : 'Disabled' }}
				<span *ngIf="this.overrideState === 'Enabled'"
					>By {{ this.overrideBy }}</span
				></span
			>
		</span>
	</ng-container>
	<mat-progress-bar
		*ngIf="this.state !== 'success'"
		mode="indeterminate"
		color="primary"
	></mat-progress-bar>

	<div class="ctw-p-8" *ngIf="this.state === 'success'">
		<p *ngIf="this.overrideBy !== 'User'">
			All VPS environments are managed by Ansible via Semaphore. Running
			playbooks and troubleshooting playbook runs for this Customer should
			be done through our Semaphore application using
			<a target="_blank" color="accent" [href]="this.getSemaphoreUrl()"
				>This Environment's Playbook</a
			>.
		</p>
		<p *ngIf="this.overrideBy === 'User'">
			Central's control of this server's software and configurations via
			Semaphore have been disabled by the Customer. You do not have any
			Semaphore capabilities at this time.
		</p>
		<div class="ctw-mt-8">
			<div
				*ngIf="
					this.projectService.environment.fields
						.semaphore_template_id;
					else unmanaged
				"
			>
				<ng-container *ngIf="this.overrideBy !== 'User'">
					<central-agent-override-form
						[overrideState]="this.overrideState"
						[overrides]="this.overrideHistory"
						(overridenBy)="this.checkState()"
					></central-agent-override-form>
				</ng-container>
			</div>
			<ng-template #unmanaged>
				<span class="error"
					>This environment is not managed by Ansible.</span
				>
				Only VPS environments are managed by Ansible.
			</ng-template>
		</div>
	</div>
</dash-card>
