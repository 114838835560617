export default [
	{
		label: 'Total Upkeep Premium',
		type: 'separator',
		helpLink: 'https://www.boldgrid.com/boldgrid-backup',
	},
	{
		label: 'Google Drive Uploads',
	},
	{
		label: 'Amazon S3 Uploads',
	},
	{
		label: 'Single File Restorations',
	},
];
