<div class="wrap animate__animated animate__fadeIn ctw-mt-8 ctw-mx-auto ctw-max-w-4xl ctw-mb-8">
	<dash-card [icon]="this.icon" [cardTitle]="this.title">
		<ng-container subTitleHTML *ngIf="this.issue !== ''">
			<span class="sub-title ctw-text-base">
				<span class="{{
					this.issue === 0
						? 'ctw-text-green-700'
						: 'ctw-text-red-700'
				}}">{{ this.issue === 0 ? 'OK' : 'Needs Attention' }}</span>
			</span>
		</ng-container>
		<mat-progress-bar *ngIf="
				(state !== 'unavailable' && state !== 'ok') &&
				(!this.recommended.length ||
					state === 'loading' ||
					status === 'submitted')
			" mode="indeterminate" color="primary"></mat-progress-bar>
		<div class="dash-card-content" *ngIf="
				this.state === 'unavailable' ||
				this.recommended.length ||
				status === 'submitted'
			" [class.ctw-p-8]="this.stats || this.issue">
			<div class="ctw-mb-4" *ngIf="this.state !== 'ok' && (this.status === 'pending' || status === 'submitted')">
				We've detected a potential issue with your site:
				<a [href]="this.url" target="_blank">{{ this.url }}</a>.
			</div>
			<div *ngIf="this.stats && this.state === 'ok'">
				<app-wpstats-card [stats]="this.stats" (updatesPending)="this.issue = $event"
					class="stat-card"></app-wpstats-card>
			</div>
			<ng-container *ngIf="state === 'unavailable'">
				<div class="ctw-mt-5 ctw-text-right">
					<button mat-button mat-raised-button color="primary" (click)="this.runDiag()">
						Retry Connection
					</button>
				</div>
			</ng-container>
			<central-connection-error-dialog *ngIf="this.state !== 'unavailable' && this.state !== 'ok'"
				[state]="this.status" [diag]="this.recommended"
				(fix)="this.runDiag($event)"></central-connection-error-dialog>
		</div>
	</dash-card>
</div>
