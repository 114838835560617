<div *ngIf="this.canContact()" [class.imh]="this.configService.config.brandConfig.id === 'imh'">
    <form (ngSubmit)="subjectField.valid && bodyField.valid && this.submit()" [hidden]="this.state === 'success'">
        <p *ngIf="this.hasPremiumSupport">
            Your subscription to <em>premium support</em> gives you access to
            our specialists.
        </p>
        <p *ngIf="this.configService.config.brandConfig.id !== 'imh'">
            You are submitting a message as <strong>{{ username }}</strong>.
        </p>
        <h3 *ngIf="this.configService.config.brandConfig.id === 'imh'">
            How can we help <strong>{{ username }}</strong>?
        </h3>
        <p *ngIf="this.cloud">
            For the cloud <strong> {{ this.cloud.label }}</strong>
        </p>
        <p class="description" *ngIf="this.configService.config.brandConfig.id !== 'imh'">
            Please enter the details of your request.
            <ng-container *ngIf="this.data?.userSetting"><strong>{{ this.data?.userSetting.user.name }}</strong> will
                respond as soon as possible.</ng-container>
        </p>
        <p *ngIf="this.state === 'failed'" class="error">
            There was an issue sending your message please try again later.
        </p>
        <p *ngIf="this.state === 'ratelimited'" class="error">
            You have reached the rate limit for submitting requests. Please
            allow an hour from your last request before submitting another.
        </p>
        <mat-form-field appearance="{{
			this.configService.config.inputAppearance || fill
		}}">
            <mat-label>Subject</mat-label>
            <input matInput [(ngModel)]="this.subject" #subjectField="ngModel" name="subject" required />
            <mat-error>
                <ng-container *ngIf="subjectField.errors?.required">This field is required</ng-container>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="{{
			this.configService.config.inputAppearance || fill
		}}" *ngIf="this.hasInput('type')">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="this.selectedType" name="type">
                <mat-option *ngFor="let option of typeOptions" [value]="option.value">
                    {{ option.label }}
                </mat-option>
            </mat-select>
            <mat-error>
                <ng-container *ngIf="bodyField.errors?.required">This field is required</ng-container>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="{{
			this.configService.config.inputAppearance || fill
		}}" *ngIf="this.hasInput('project') && data?.environments?.length > 0">
            <mat-label>Related Environment</mat-label>
            <mat-select #groupField [(ngModel)]="this.selectedEnvironment" (selectionChange)="updateClarityHours($event)" name="environment">
                <mat-option *ngFor="let option of data.environments" [value]="option.id">
					{{ option.label }} - {{option.fields.site_url }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="{{
			this.configService.config.inputAppearance || fill
		}}" *ngIf="this.hasInput('department')">
            <mat-label>Category</mat-label>
            <mat-select required #groupField [(ngModel)]="this.department" name="type">
                <mat-option *ngFor="let option of departmentOptions" [value]="option.value">
                    {{ option.label }}
                </mat-option>
            </mat-select>
            <mat-error>
                <ng-container *ngIf="groupField.errors?.required">This field is required</ng-container>
            </mat-error>
        </mat-form-field>
        <span *ngIf="this.department == 'managed_hosting'">
			<span *ngIf="this.clarityMinutes !== -1 && this.clarityMinutes !== undefined" class="{{
				this.clarityMinutes > 0
					? ''
					: 'ctw-text-red-700'
			}}">
				You have {{ this.clarityHours }} hour<span *ngIf="this.clarityHours > 1 || this.clarityHours === 0">s</span><span *ngIf="(this.clarityMinutes % 60) > 0"> {{ (this.clarityMinutes % 60) }} Minutes</span> remaining with the Professional Services team.
			</span>
			<span *ngIf="this.clarityMinutes === 0">
				<br>You can still reach out to our Professional Services team, but please be aware that this is a paid service, so you may need to purchase additional hours for assistance.
			</span>
			<span *ngIf="this.clarityMinutes === -1" class="ctw-text-red-700">
				Unfortunately we were unable to retrieve information regarding your Professional Services account. You can still reach out to the team, but we are unable to confirm how many hours you currently have available.
			</span>
        </span>
        <mat-form-field appearance="{{
			this.configService.config.inputAppearance || fill
		}}" *ngIf="
				this.selectedType === 'flex_metal_help' &&
				this.hasInput('priority') &&
				priorityOptions.length > 1
			">
            <mat-label>Priority</mat-label>
			<mat-hint class="emergency-hint" *ngIf="this.priority === 'urgent'">
				Emergency: Only select for critical issues needing immediate action, like system outages or security concerns.
			</mat-hint>
            <mat-select [(ngModel)]="this.priority" name="priority">
                <mat-option *ngFor="let option of priorityOptions" [value]="option.value">
                    {{ option.label }}
                </mat-option>
            </mat-select>
            <mat-error>
                <ng-container *ngIf="bodyField.errors?.required">This field is required</ng-container>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="ctw-mt-5" appearance="{{
			this.configService.config.inputAppearance || fill
		}}">
            <mat-label>Description</mat-label>
            <textarea matInput [(ngModel)]="this.description" #bodyField="ngModel" name="description" rows="4"
                type="textarea" required></textarea>
            <mat-error>
                <ng-container *ngIf="bodyField.errors?.required">This field is required</ng-container>
            </mat-error>
        </mat-form-field>
        <div class="ctw-text-right ctw-mt-5 action-buttons">
            <button mat-button (click)="this.matDialogRef.close()" class="ctw-mr-2" type="button">
                Cancel
            </button>
            <progress-button [state]="state" color="primary" type="submit">Send</progress-button>
        </div>
    </form>
</div>
<div class="ctw-text-center p-5" *ngIf="this.state === 'success'">
    <h3>Thank you for reaching out.</h3>
    <p *ngIf="this.data?.userSetting?.user.name">
        {{ this.data?.userSetting.user.name }} received your message and will
        get back to you as soon as possible.
    </p>
    <p *ngIf="!this.data?.userSetting?.user.name">
        We've received your message and will get back to you as soon as
        possible.
    </p>
    <div class="ctw-mt-5" [class.ctw-text-right]="this.configService.config.brandConfig.id === 'imh'">
        <button mat-button mat-raised-button (click)="this.matDialogRef.close()" color="primary" type="button">
            Okay
        </button>
    </div>
</div>
<div *ngIf="!this.canContact()">
    <div class="ctw-text-center p-5">
        <h3>Premium Support Required</h3>
        <p>
            Reaching out directly to
            <strong>{{ this.data.userSetting.user.name }}</strong> requires a
            premium support subscription.
        </p>
        <ng-container *ngIf="this.cloudId">
            <ng-container *ngIf="this.cloud.fields.is_trial">
                <p>
                    Direct messages to our leadership team is only available for
                    paid Cloud projects. Visit this page while viewing the paid
                    Cloud you need assistenace with to upgrade.
                </p>
            </ng-container>
            <ng-container *ngIf="!this.cloud.fields.is_trial">
                <div class="action-buttons ctw-mt-5">
                    <button mat-button mat-raised-button (click)="this.matDialogRef.close()" type="button">
                        Cancel
                    </button>
                    <button mat-button mat-raised-button (click)="this.upgrade()" color="primary" type="button">
                        Upgrade
                    </button>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!this.cloudId">
            <p>
                To purchase premium support visit the paid Cloud project you
                need help with and try to contact the agent again.
            </p>
            <div class="ctw-mt-5">
                <button mat-raised-button color="primary" (click)="this.matDialogRef.close()">
                    Close
                </button>
            </div>
        </ng-container>
    </div>
</div>
