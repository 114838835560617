import {
	Injectable,
	OnDestroy,
	ApplicationRef,

} from '@angular/core';
import { ApiService, AuthService, SocketService, ConfigurationService } from '@central/ng-shared';
import { Observable, ReplaySubject, concat } from 'rxjs';
import { WpRestService } from './site/rest.service';
import { AppService } from '../app.service';
import { HttpClient } from '@angular/common/http';
import { map, flatMap, tap } from 'rxjs/operators';
import moment from 'moment';
import { Config } from '../shared';
import { AccountService } from '../checkout/account.service';
import { LicenseService } from '../shared/license/license.service';
import { RollbarService, CouponService } from '@central/ng-shared';

@Injectable()
export class ProjectService implements OnDestroy {
	public connected = new ReplaySubject(1);
	public playbookStatus = new ReplaySubject(1);
	public onEnvironmentChange = new ReplaySubject(1);
	public updateState = 'pending';
	public environmentState = 'pending';
	public environment;
	public screenshotRefreshHours = 0.25;
	public project;
	public projects;
	public client;
	public siteStats;
	public getSubscription = new ReplaySubject();
	private subscription;
	public owner;
	public projectAccess;
	public socket;
	public observer;
	public currentUserCanPurchase = false;
	public reconnectAttempts = 0;
	public stagingWordPress;
	public organizationId;
	public stagingEnvironment;

	public capabilities = {
		owner: ['manage', 'write', 'read'],
		maintainer: ['write', 'read'],
		member: ['read'],
	};

	public constructor(
		public httpClient: HttpClient,
		public appService: AppService,
		public wpRestService: WpRestService,
		public socketService: SocketService,
		private accountService: AccountService,
		private authService: AuthService,
		private apiService: ApiService,
		private appRef: ApplicationRef,
		public licenseService: LicenseService,
		public rollbarService: RollbarService,
		public configService: ConfigurationService,
		public couponService: CouponService
	) {}

	public ngOnDestroy(): void {
		this.stopListeningToWebsocket();

		if (this.observer) {
			this.observer.unsubscribe();
		}
	}

	public isAdvanced() {
		return this.project.fields.mode === 'advanced';
	}

	public hasEnvironments() {
		return this.project.children.length;
	}

	public hasPlaygrounds() {
		return this.project.children.filter(
			(project) => project.fields.environment_type !== 'vps'
		).length;
	}

	public standardizeFields() {
		this.project.fields.mode = this.project.fields.mode || 'novice';

		// Used for testing.
		if (Config.overrideProjectUrl) {
			this.project.children.map((val) => {
				val.fields.site_url = Config.overrideProjectUrl;
			});
		}
	}

	public fetchProjects() {
		return new Observable((observer) => {
			this.apiService.get('/v1/projects', {}).subscribe({
				next: (projects) => {
					observer.next(projects);
					observer.complete();
				},
				error: (error) => {
					console.log(error.message);
					observer.error(error);
				},
			});
		});
	}

	public fetchProject(projectId: string) {
		return new Observable((observer) => {
			if (this.project && this.project.id === projectId) {
				observer.next(this.project);
				observer.complete();
			} else {
				this.apiService.get('/v1/projects/' + projectId, {}).subscribe(
					(project) => {
						this.project = project;
						this.appService.project = project;
						this.standardizeFields();
						this.updateRollbarProject();
						this.authService.profile.onReady().subscribe(() => {
							this.owner = this.authService.profile.data.account_access.find(
								(access) =>	access.account_id === this.project.fields.organization_id
							);
							if (this.owner?.account_id) {
								this.accountService['accountId'] =
									this.owner.account_id;
							}

							this.setProjectAccess();
							this.fetchSubscription();
							this.listenToWebsocket();

							if (this.project.fields.project_type === 'wordpress') {
								this.project.children.forEach((environment) => {
									setTimeout(() => {
										this.wpRestService.getClient(environment).subscribe()
									}, 300);
								});
							}

							observer.next(this.project);
							observer.complete();
						});
					},
					(error) => {
						console.log(error.message);
						observer.error(error);
					}
				);
			}
		});
	}

	/**
	 * Configures Rollbar project with current project details
	 */
	private updateRollbarProject() {
		if (this.project?.fields) {
			this.rollbarService.rollbar.configure({
				payload: {
					project: {
						id: this.project.id,
						label: this.project.label,
						subscription_uuid: this.project.fields.primary_subscription_uuid,
						organization_id: this.project.fields.organization_id,
						connect_id: this.project.fields.connect_id,
						project_type: this.project.fields.project_type,
					},
				},
			});
		}
	}

	/**
	 * Clears existing environment_details in Rollbar to prevent mixing of environment data
	 */
	private clearRollbarEnvironment() {
		this.rollbarService.rollbar.configure({
			payload: {
				environment_details: {}
			}
		});
	}

	/**
	 * Configures Rollbar environment with current environment details
	 */
	private updateRollbarEnvironment() {
		this.clearRollbarEnvironment();

		if (this.environment?.fields) {
			//Check for cloud_id to determine if environment is vps or playground
			if (this.environment.fields.cloud_id) {
				// Playground Environment
				this.updateRollbarEnvironmentPlayground();
			} else {
				// VPS Environment
				this.updateRollbarEnvironmentWordpress();
			}
		}
	}

	/**
	 * Updates Rollbar conifguration with WordPress Environment Fields
	 */
	private updateRollbarEnvironmentWordpress() {
		this.rollbarService.rollbar.configure({
			payload: {
				environment_details: {
					id: this.environment.id,
					label: this.environment.label,
					hostname: this.environment.fields.hostname,
					site_url: this.environment.fields.site_url,
					package_code: this.environment.fields.package_code,
					machine_details: {
						veid: this.environment.fields.machine_details?.veid,
						machine_ip: this.environment.fields.machine_details?.machine_ip,
						machine: this.environment.fields.machine_details?.machine,
					},
				}
			}
		});
	}

	/**
	 * Updates Rollbar with Playground Environment Fields
	 */
	private updateRollbarEnvironmentPlayground() {
		this.rollbarService.rollbar.configure({
			payload: {
				environment_details: {
					id: this.environment.id,
					label: this.environment.label,
					site_url: this.environment.fields.site_url,
					cloud_id: this.environment.fields.cloud_id,
					organization_id: this.environment.fields.organization_id,
				}
			}
		});
	}

	protected listenToWebsocket() {
		this.stopListeningToWebsocket();
		this.socket = this.socketService.getSocket();
		const listener = () => {
			this.stopListeningToWebsocket();
			this.socket.emit('authenticateResource', {
				name: 'projects',
				id: this.project.id,
				token: this.authService.getToken(),
			});

			this.socket.on(
				`resource_update_projects_${this.project.id}`,
				(data) => {
					const response = data.payload;
					this.project.label = response.label;
					this.project.fields = {
						...this.project.fields,
						...response.fields,
					};
					this.project.children = response.children;
				}
			);
		};

		if (this.observer) {
			this.observer.unsubscribe();
		}

		this.observer = this.socketService.onAuthenticated.subscribe(listener);
	}

	public stopListeningToWebsocket() {
		if (this.socket && this.project?.id) {
			this.socket.removeAllListeners(
				`resource_update_projects_${this.project.id}`
			);
		}
	}

	public login(url?: string) {
		return this.wpRestService.login(this.environment, url || '');
	}

	public loadEnvironmentClient() {
		return this.wpRestService.getClient(this.environment);
	}

	public setEnvironment(environmentId) {
		const changed = environmentId !== this.environment?.id;

		this.environment = this.project.children.find((environment) => environmentId === environment.id);
		this.updateRollbarEnvironment();
		if (!this.environment || !changed) {
			return;
		}

		this.siteStats = null;
		this.environment.is_dev_cloud = !this.environment.fields.cloud_id;
		this.playbookStatus.next(
			this.environment.fields?.playbook_running || 'playbook-not-running'
		);

		this.setReachableState();

		if (changed) {
			this.onEnvironmentChange.next(this.environment);
		}
	}

	public setReachableState() {
		let isPublic = this.environment.fields.is_publicly_accessible;

		this.apiService
			.get(`/v1/environments/connection`, {
				url: this.environment.fields.site_url,
			})
			.pipe(
				map((result: any) => {
					isPublic = !!result.is_success;
					if (isPublic) {
						this.environmentState = 'accessible';
					}
				})
			)
			.pipe(
				flatMap(() => this.updateEnvironmentField({
						is_publicly_accessible: isPublic,
					})
				)
			)
			.subscribe();

		if (this.configService.hasFeature('IMC-683')) {
			const env = this.environment.fields.cloud_id
				? this.environment.fields.site_url
				: this.environment.fields.connection_url;
			this.httpClient.get(env).subscribe(
				(response: any) => {
					this.environmentState = 'accessible';
				},
				(error) => {
					// console.log(error);
				}
			);
		} else {
			this.environmentState = 'accessible';
		}
	}

	public getSiteStats(cached = true) {
		if (this.environment.fields?.playbook_running !== 'is-running') {
			return new Observable((observer) => {
				if (this.siteStats && cached) {
					observer.next(this.siteStats);
					observer.complete();
				} else {
					this.client
						.namespace('bgc/v1')
						.stats()
						.get()
						.then((stats) => {
							this.siteStats = stats;
							observer.next(this.siteStats);
							observer.complete();
						})
						.catch((error) => {
							if ('restx_logged_out' === error.code) {
								// Re-authenticating.
								this.wpRestService
									.reloadClient(this.environment)
									.subscribe(
										(client) => {
											this.client = client;
											this.getSiteStats(cached);
										},
										() => {
											this.connected.next('failed');
											observer.error(error);
										}
									);
							} else {
								this.connected.next('failed');
								observer.error(error);
							}
						});
				}
			});
		}

		return new Observable((observer) => {
			observer.next({});
			observer.complete();
		});
	}

	public updateScreenshot(force = false) {
		const createdAt = this.environment.fields['screenshot_created_at'] || 0;
		const screenshotExpired =
			moment().diff(createdAt, 'hours') > this.screenshotRefreshHours;

		if (screenshotExpired || force) {
			this.apiService
				.post(`/v1/environments/${this.environment.id}/screenshot`, {})
				.subscribe(
					(environment: any) => {
						this.environment.fields = {
							...this.environment.fields,
							...environment.fields,
						};
					},
					() => {
						this.environment.fields.screenshot_created_at =
							new Date().toISOString();
					}
				);
		}
	}

	public getDefaultEnvironment(project): Environment {
		let environment = this.getVpsEnvironment(project);

		// If no vps environment is found grab the first playground.
		if (!environment) {
			environment = project.children[0];
		}

		return environment;
	}

	public getVpsEnvironment(project): Environment {
		let environment = project.children.find((val) => val.fields.environment_type === 'vps');
		if (!environment) {
			environment = project.children[0];
		}
		return environment;
	}

	public getProductionEnvironment(): Environment {
		return this.project.children.find((val) => !+val.fields.is_development);
	}

	public currentEnvironmentIsProduction(): boolean {
		return !+this.environment.fields.is_development;
	}

	public resetClient() {
		this.client = this.wpRestService.getSite(this.environment).rest;
	}
;
	public setEnvironmentState(state) {
		this.environment.state = state;
	}

	public updateEnvironmentField(fields) {
		const url = this.apiService.formatter.getUrl(
			'/v1/environments/' + this.environment.id
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		return this.httpClient.patch(url, { fields }, { headers }).pipe(
			map((response: any) => {
				this.environment.fields = {
					...this.environment.fields,
					...response.fields,
				};
				this.appRef.tick();
				return response;
			})
		);
	}

	public reloadProject(setEnvironment = true) {
		return this.apiService.get('/v1/projects/' + this.project.id, {}).pipe(
			map((response: any) => {
				this.project.fields = {
					...this.project.fields,
					...response.fields,
				};
				this.project.children = response.children;

				if (setEnvironment) {
					if (this.environment) {
						this.setEnvironment(this.environment.id);
					} else {
						this.setEnvironment(
							this.getDefaultEnvironment(this.project).id
						);
					}
				}

				return response;
			})
		);
	}

	public updateProjectFields(fields) {
		const url = this.apiService.formatter.getUrl(
			'/v1/projects/' + this.project.id
		);

		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		return this.httpClient.patch(url, { fields }, { headers }).pipe(
			map((response: any) => {
				this.project.fields = {
					...this.project.fields,
					...response.fields,
				};
				return response;
			})
		);
	}

	public resolveEnvironmentTodo(todo) {
		const fields = {};
		fields[todo] = true;
		return this.updateEnvironmentField(fields);
	}

	private fetchSubscription() {
		if (!this.hasCapability('write')) {
			return;
		}

		const subscriptionUuid = this.project.fields.primary_subscription_uuid;
		const organizationId = this.project.fields.organization_id;

		const url = this.apiService.formatter.getUrl(
			`/v1/wpcr/accounts/${this.getAccountId()}/subscriptions/${subscriptionUuid}`
		);
		let headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		headers = headers.append('X-Organization-Id', organizationId)

		if (!subscriptionUuid) {
			this.getSubscription.error({
				error: {
					message: 'No subscription found.',
				},
			});

			return;
		}

		this.apiService.http.get(url, { headers }).subscribe({
			next: (subscription) => {
				this.subscription = subscription;
				this.getSubscription.next(this.subscription);
			},
			error: (error) => {
				this.getSubscription.error(error);
			},
		});
	}

	public getAccountId() {
		return (
			this.project.fields.organization_id ||
			this.authService.getAccountId()
		);
	}

	public setProjectAccess() {
		this.projectAccess = this.authService.profile.data.account_access.find(
			(val) => val.account_id === this.owner.account_id
		);

		if (
			this.projectAccess.role === 'owner' ||
			(this.projectAccess.role === 'maintainer' && this.owner.has_billing)
		) {
			this.currentUserCanPurchase = true;
		}
	}

	public hasCapability(capability: string): boolean {
		const role = this.projectAccess?.role || '';

		let hasCapability = false;
		if (
			this.capabilities[role] &&
			this.capabilities[role].includes(capability)
		) {
			hasCapability = true;
		}

		return hasCapability;
	}

	public hasEnvironmentControl(environment = null): boolean {
		environment = environment || this.environment;

		if (+environment?.fields.is_development) {
			return true;
		} else {
			return this.hasCapability('write');
		}
	}
	public addEnvironment(options: any) {
		return concat(
			this.createCWP(options),
			this.createEnvironment(() => {
				const fields = {
					is_development: true,
					site_url: this.stagingWordPress.site_url,
					cloud_id: this.stagingWordPress.cloud_id,
					environment_usage: "unknown",
				};

				if (options.connect_id) {
					fields['connect_id'] = options.connect_id;
				}

				return {
					property: 'stagingEnvironment',
					label: options.label,
					fields,
				};
			})
		);
	}

	/**
	 * Create a new Cloud WordPress.
	 *
	 * @return Observable
	 */
	public createCWP(options?: any): Observable<any> {
		return new Observable((observer) => {
			const url = this.apiService.formatter.getUrl('createEnvironment');

			let headers = this.apiService.getHeaders({
				contentType: 'application/x-www-form-urlencoded',
			});

			// Check options so project can be passed into this method.
			if (!this.project && options.project) {
				this.project = options.project;
			}

			if (this.project?.fields?.organization_id) {
				headers = headers.append('X-Organization-Id', this.project.fields.organization_id);
			}

			this.apiService.http
				.post(
					this.apiService.formatter.getUrl('installCloud'),
					{
						type: 'wordpress',
						connect_id: this.project?.fields?.connect_id,
					},
					{ headers }
				)
				.subscribe(
					(response) => {
						this.stagingWordPress = response;
						observer.next(response);
						observer.complete();
					},
					(error) => {
						observer.error(error);
					}
				);
		});
	}

	/**
	 * Create the Environment resource.
	 *
	 * @param getOptions Callback to get environment details.
	 * @return Observable
	 */
	public createEnvironment(getOptions): Observable<any> {
		return new Observable((observer) => {
			const options = getOptions();
			const url = this.apiService.formatter.getUrl('createEnvironment');

			let headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});

			if (this.project?.fields?.organization_id) {
				headers = headers.append(
					'X-Organization-Id',
					this.project.fields.organization_id
				);
			}

			this.apiService.http
				.post(
					url,
					{
						label: options.label,
						parentId: this.project.id,
						fields: options.fields || null,
					},
					{ headers }
				)
				.subscribe(
					(response) => {
						this[options.property] = response;
						observer.next(response);
						observer.complete();
					},
					(error) => {
						observer.error(error);
					}
				);
		});
	}

	/**
	 * Create a user project resource.
	 *
	 * @return Observable
	 */
	public createProject(options = {}): Observable<any> {
		return new Observable((observer) => {
			const url = this.apiService.formatter.getUrl('/v1/projects');
			let headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});

			// Add organization id to the header.
			if (this.organizationId) {
				headers = headers.append(
					'X-Organization-Id',
					this.organizationId
				);
			}

			this.httpClient.post(url, options, { headers }).subscribe(
				(response) => {
					this.project = response;
					// Update licenses because we created a key.
					this.licenseService.fetch();
					observer.next(response);
					observer.complete();
				},
				(error) => {
					observer.error(error);
				}
			);
		});
	}
}
