<p *ngIf="'loading' === componentState">
	<!--
--><mat-progress-spinner
		class="inline-spinner"
		mode="indeterminate"
		color="primary"
		diameter="16"
	></mat-progress-spinner
	><!--
-->Loading details...<!--
--></p>

<p *ngIf="'error' === componentState">
	<!--
--><span class="error">Error loading details</span
	><!--
--><button
		class="simple-button spacer"
		tabindex="0"
		(click)="getCpanelDetails()"
	>
		[try again]</button
	><!--
--></p>

<ng-container *ngIf="'success' === componentState">
	<p *ngIf="'member' !== organizationRole">
		Create, edit, and delete email accounts in cPanel:<button
			mat-stroked-button
			color="primary"
			(click)="openCpanel()"
		>
			Manage Email Accounts <mat-icon>open_in_new</mat-icon>
		</button>
	</p>

	<p [ngClass]="{ 'box-open': showQuantityManager }">
		<!--
	--><span class="bold">Email account usage:</span
		><!--
	-->
		{{ emailAccountUsage }} of {{ emailAccountLimit
		}}<!--
	--><button
			class="simple-button spacer"
			*ngIf="'member' !== organizationRole && !showQuantityManager"
			(click)="toggleQuantityManagerVisibility()"
		>
			[change limit]</button
		><!--
	-->
	</p>
	<div class="gray-box" *ngIf="showQuantityManager">
		<ng-container *ngIf="'inactive' === updateQuantityRequestState">
			<p>
				Email accounts are $1.99 each per month. It may take a few
				minutes for changes to be reflected in cPanel.
			</p>
			<p>
				<!--
			--><span class="bold">Email account limit:</span
				><!--
			--><button
					mat-stroked-button
					class="stepper-button"
					[disabled]="stepperQuantity === 0"
					(click)="updateStepperQuantity(false)"
				>
					-</button
				><!--
			--><span class="bold stepper-quantity">{{ stepperQuantity }}</span
				><!--
			--><button
					mat-stroked-button
					class="stepper-button"
					[disabled]="stepperQuantity === 99"
					(click)="updateStepperQuantity(true)"
				>
					+</button
				><!--
			--><button
					mat-stroked-button
					color="primary"
					(click)="updateSubscriptionQuantity()"
					[disabled]="
						stepperQuantity === emailAccountLimit ||
						stepperQuantity < emailAccountUsage
					"
				>
					Change</button
				><!--
			--><button mat-stroked-button (click)="toggleQuantityManagerVisibility()">
					Cancel</button
				><!--
			-->
			</p>
			<p class="error" *ngIf="stepperQuantity < emailAccountUsage">
				Error: Cannot set limit below current email account usage
			</p>
		</ng-container>

		<p class="error" *ngIf="'failed' === updateQuantityRequestState">
			Error: Failed to change quantity, try again
		</p>

		<p *ngIf="'loading' === updateQuantityRequestState">
			<!--
		--><mat-progress-spinner
				class="inline-spinner"
				mode="indeterminate"
				color="primary"
				diameter="16"
			></mat-progress-spinner
			><!--
		-->Changing quantity...<!--
		-->
		</p>

		<p *ngIf="'succeeded' === updateQuantityRequestState">Success!</p>
	</div>

	<p *ngIf="'member' !== organizationRole">
		<!--
		--><span class="bold">Email DNS Status:</span
		><!--
		--><button
			class="simple-button spacer"
			tabindex="0"
			*ngIf="'inactive' === dnsStatusState"
			(click)="checkDnsRecords()"
		>
			[run check]</button
		><!--
		--><span *ngIf="'loading' === dnsStatusState"
			><mat-progress-spinner
				class="inline-spinner"
				mode="indeterminate"
				color="primary"
				diameter="16"
			></mat-progress-spinner
			>Checking DNS...</span
		><!--
		--><ng-congainer *ngIf="'success' === dnsStatusState"
			><!--
			--><span>
				{{
					dnsIssuesCount
						? dnsIssuesCount +
						  ' issue' +
						  (dnsIssuesCount !== 1 ? 's' : '') +
						  ' detected'
						: 'No issues detected'
				}}</span
			><!--
			--><button
				class="simple-button spacer"
				tabindex="0"
				(click)="toggleDnsRecordVisibility()"
			>
				[{{ showDnsRecords ? 'hide' : 'show' }} details]</button
			><!--
			--><button
				class="simple-button spacer"
				tabindex="0"
				(click)="checkDnsRecords()"
			>
				[re-check]</button
			><!--
		--></ng-congainer
		><!--
		--><ng-container *ngIf="'error' === dnsStatusState"
			><!--
			--><span class="error-label"> Error checking DNS status</span
			><!--
			--><button
				class="simple-button spacer"
				tabindex="0"
				(click)="checkDnsRecords()"
			>
				[try again]</button
			><!--
		--></ng-container
		><!--
	-->
	</p>
	<div class="dns-records-container" *ngIf="showDnsRecords">
		<p>
			Email sent to any @{{ domainName }} address will not be delivered
			here unless the domain's DNS records are properly configured.
			Changes to DNS records can take several hours to propagate.
		</p>

		<button
			*ngIf="
				('member' !== organizationRole &&
					undefined !== dnsZoneData &&
					'inactive' === fixIssuesRequestState) ||
				'failed' === fixIssuesRequestState
			"
			mat-stroked-button
			color="primary"
			(click)="fixDnsIssues()"
		>
			Fix Issues
		</button>
		<p *ngIf="'loading' === fixIssuesRequestState">
			<mat-progress-spinner
				class="inline-spinner"
				mode="indeterminate"
				color="primary"
				diameter="16"
			></mat-progress-spinner
			>Fixing issues...
		</p>
		<p *ngIf="'success' === fixIssuesRequestState">
			Issues fixed! Note that it can take a few hours for the DNS changes
			to propagate.
		</p>
		<p class="error" *ngIf="'failed' === fixIssuesRequestState">
			Error updating DNS records, try again
		</p>

		<div class="dns-records" *ngFor="let record of dnsRecords">
			<mat-divider></mat-divider>
			<table>
				<caption>
					{{
						record.description
					}}
					<mat-icon
						[class.discrepancy]="record.discrepancy"
						[class.no-discrepancy]="!record.discrepancy"
						>{{
							record.discrepancy ? 'warning' : 'check_circle'
						}}</mat-icon
					>
				</caption>
				<thead>
					<tr>
						<th></th>
						<th>Name</th>
						<th>Type</th>
						<th>Value</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Expected Record:</td>
						<td>{{ record.name + domainName }}</td>
						<td>{{ record.type }}</td>
						<td *ngIf="'MX' === record.type">
							<span class="label">Priority:</span>
							{{ record.expectedValue.priority }}<br /><span
								class="label"
								>Destination:</span
							><span #mxDestinationSpan>{{
								record.expectedValue.target
							}}</span
							><button
								class="simple-button"
								(click)="copyValue(mxDestinationSpan)"
							>
								[copy destination value]
							</button>
						</td>
						<td *ngIf="'MX' !== record.type">
							<span #valueSpan>{{ record.expectedValue }}</span
							><button
								class="simple-button"
								(click)="copyValue(valueSpan)"
							>
								[copy value]
							</button>
						</td>
					</tr>
					<ng-container *ngIf="record.currentValues.length">
						<ng-container *ngIf="record.currentValues.length > 1">
							<tr
								*ngFor="
									let currentValue of record.currentValues;
									index as i
								"
							>
								<td
									class="multiple-records"
									[attr.rowspan]="record.currentValues.length"
									*ngIf="0 === i"
								>
									Current Records:
								</td>
								<td>{{ record.name + domainName }}</td>
								<td>{{ record.type }}</td>
								<td *ngIf="'MX' === record.subtype">
									<span class="label">Priority:</span>
									{{ currentValue.priority }}<br /><span
										class="label"
										>Destination:</span
									>{{ currentValue.target }}
								</td>
								<td *ngIf="'MX' !== record.subtype">
									{{ currentValue }}
								</td>
							</tr>
						</ng-container>
						<ng-container *ngIf="record.currentValues.length === 1">
							<tr
								*ngFor="
									let currentValue of record.currentValues
								"
							>
								<td>Current Record:</td>
								<td>{{ record.name + domainName }}</td>
								<td>{{ record.type }}</td>
								<td *ngIf="'MX' === record.subtype">
									<span class="label">Priority:</span>
									{{ currentValue.priority }}<br /><span
										class="label"
										>Destination:</span
									>{{ currentValue.target }}
								</td>
								<td *ngIf="'MX' !== record.subtype">
									{{ currentValue }}
								</td>
							</tr>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="!record.currentValues.length">
						<tr>
							<td>Current Record:</td>
							<td class="no-record" colspan="3">
								No record found
							</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
	</div>
</ng-container>
