<mat-progress-bar
	*ngIf="status === 'pending'"
	mode="indeterminate"
	color="primary"
></mat-progress-bar>
<layout-base
	[isLoading]="status === 'pending'"
	pageTitle="Receipt"
	[failedLoading]="status === 'fail'"
>
	<div class="container">
		<post-purchase
			*ngIf="this.receipt"
			[paymentPage]="false"
			[receipt]="this.receipt"
		></post-purchase>
	</div>
</layout-base>
