import { Component } from '@angular/core';

@Component({
	selector: 'domain-change-confirmation',
	template: `
		<h3 mat-dialog-title>Are you sure?</h3>
		<mat-dialog-content>
			<p>
				This domain is already in use on another server in your account.
				Continuing will update your domain to direct traffic to this
				server.
			</p>
		</mat-dialog-content>
		<mat-dialog-actions class="ctw-flex ctw-justify-end">
			<button mat-button [mat-dialog-close]="false">Cancel</button>
			<button mat-raised-button [mat-dialog-close]="true" color="accent">
				Continue
			</button>
		</mat-dialog-actions>
	`,
})
export class DomainChangeConfirmationComponent {}
