import { Component, OnInit } from '@angular/core';
import { WpRestService } from '../rest.service';
import { ProjectService } from '../../project.service';

@Component({
	selector: 'app-health',
	templateUrl: './health.component.html',
	styleUrls: ['./health.component.scss'],
})
export class HealthComponent implements OnInit {
	public status = 'loading';
	public siteHealth;

	constructor(
		private projectService: ProjectService,
		public wpRestService: WpRestService
	) {}

	ngOnInit() {
		this.getPlaybookStatus();
		this.projectService.connected.subscribe((msg: any) => {
			if (
				msg === 'ready' &&
				this.projectService.client &&
				this.status !== 'success' &&
				this.status !== 'blocked'
			) {
				this.getSiteHealth();
			}

			if (msg !== 'ready') {
				this.status = 'blocked';
			}
		});
	}

	/**
	 * Gets status of project's playbook runs
	 */
	getPlaybookStatus() {
		this.projectService.playbookStatus.subscribe((msg: any) => {
			this.status = msg === 'playbook-running' ? 'blocked' : this.status;
			if (
				this.status === 'blocked' &&
				(msg === 'playbook-not-running' || msg === 'complete')
			) {
				this.status = 'loading';
				this.getSiteHealth();
			}
		});
	}

	public decodeHtml(html) {
		const txt = document.createElement('textarea');
		txt.innerHTML = html;
		return txt.value;
	}

	public getSiteHealth() {
		this.projectService.client
			.namespace('bgc/v1')
			.health()
			.get()
			.then((result) => {
				const siteHealth = {};
				result.map((val) => {
					// Add tailwind screen reader classes where WP screen reader classes are.
					val.description = val.description.replace(
						/screen-reader-text/g,
						'ctw-sr-only'
					);
					siteHealth[val.status] = siteHealth[val.status] || [];
					siteHealth[val.status].push(val);
				});

				this.siteHealth = siteHealth;
				this.status = 'success';
			})
			.catch((error) => {
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								this.getSiteHealth();
							},
							() => {
								this.status = 'failed';
							}
						);
				} else {
					this.status = 'failed';
				}
			});
	}
}
