<mat-progress-bar
	*ngIf="'loading' === state"
	mode="indeterminate"
	color="{{ this.configurationService.config.buttonColor || '' }}"
></mat-progress-bar>
<form
	#form="ngForm"
	(ngSubmit)="form.valid && submit()"
	class="form"
	*ngIf="'loading' !== state"
	[class]="
		this.configurationService.config.brandConfig.id === 'imh'
			? 'animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-2xl ctw-mt-8 ctw-mb-8'
			: ''
	"
>
	<div class="ctw-mb-10">
		<h2>
			{{
				this.configurationService.config.groupLabels[
					'group-label-title-case'
				]
			}}
			Profile
		</h2>
		<p [hidden]="this.configurationService.config.brandConfig.id === 'imh'">
			Update your
			{{
				this.configurationService.config.groupLabels[
					'group-label-lower-case'
				]
			}}
			contact information.
		</p>
	</div>
	<mat-card class="ctw-mb-5" *ngIf="!this.accountService.hasAccess('write')"
		>Member read only access</mat-card
	>
	<mat-form-field
		appearance="{{
			this.configurationService.config.inputAppearance || fill
		}}"
	>
		<mat-label
			>{{
				this.configurationService.config.groupLabels[
					'group-label-title-case'
				]
			}}
			Name</mat-label
		>
		<input
			matInput
			[(ngModel)]="organizationName"
			[disabled]="!this.accountService.hasAccess('write')"
			required
			#name="ngModel"
			name="organizationName"
			placeholder="Your Organization Name"
		/>
		<mat-error>
			<ng-container *ngIf="name.errors?.required"
				>This field is required.</ng-container
			>
		</mat-error>
	</mat-form-field>
	<ng-container *ngIf="!this.websiteOptional">
		<mat-form-field
			appearance="{{
				this.configurationService.config.inputAppearance || fill
			}}"
		>
			<mat-label>Website</mat-label>
			<input
				matInput
				required
				(blur)="this.fixUrls()"
				type="url"
				[disabled]="!this.accountService.hasAccess('write')"
				centralValidationUrl
				[(ngModel)]="orgWebsite"
				required
				#website="ngModel"
				name="orgWebsite"
				placeholder="www.corporate-website.com"
			/>
			<mat-error>
				<ng-container *ngIf="website.errors?.required"
					>This field is required.</ng-container
				>
				<ng-container *ngIf="!website.errors?.required"
					>Invalid URL format.</ng-container
				>
			</mat-error>
		</mat-form-field>
	</ng-container>
	<ng-container *ngIf="this.websiteOptional">
		<mat-form-field
			appearance="{{
				this.configurationService.config.inputAppearance || fill
			}}"
		>
			<mat-label>Website</mat-label>
			<input
				matInput
				(blur)="this.fixUrls()"
				type="url"
				[disabled]="!this.accountService.hasAccess('write')"
				centralValidationUrlOptional
				[(ngModel)]="orgWebsite"
				#website="ngModel"
				name="orgWebsite"
				placeholder="www.corporate-website.com"
			/>
			<mat-error>
				<ng-container *ngIf="website.errors?.required"
					>This field is required.</ng-container
				>
				<ng-container *ngIf="!website.errors?.required"
					>Invalid URL format.</ng-container
				>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<mat-form-field
		appearance="{{
			this.configurationService.config.inputAppearance || fill
		}}"
	>
		<mat-label>Contact Email</mat-label>
		<input
			[(ngModel)]="contactEmail"
			[disabled]="!this.accountService.hasAccess('write')"
			email
			type="email"
			#contact="ngModel"
			required
			matInput
			name="contactEmail"
			placeholder="engineering@company.com"
		/>
		<mat-error>
			<ng-container *ngIf="contact.errors?.required"
				>This field is required.</ng-container
			>
			<ng-container *ngIf="contact.errors?.email"
				>Invalid Format. Please use the following format
				user@domain.tld</ng-container
			>
		</mat-error>
	</mat-form-field>

	<mat-form-field
		appearance="{{
			this.configurationService.config.inputAppearance || fill
		}}"
	>
		<mat-label>Billing Email</mat-label>
		<input
			[(ngModel)]="billingEmail"
			[disabled]="!this.accountService.hasAccess('write')"
			type="email"
			#billing="ngModel"
			name="billingEmail"
			email
			matInput
			placeholder="billing@company.com"
		/>
		<mat-error>
			<ng-container *ngIf="billing.errors?.email"
				>Invalid Format. Please use the following format
				user@domain.tld</ng-container
			>
		</mat-error>
	</mat-form-field>
	<div class="update-message">
		<div class="success" [class.visible]="this.state === 'success'">
			Successfully updated your organization details.
		</div>
		<div class="error" [class.visible]="this.state === 'failed'">
			An error occurred while processing your request.
		</div>
	</div>
	<div
		class="action-buttons ctw-text-right"
		*ngIf="this.accountService.hasAccess('write')"
	>
		<progress-button
			[state]="state"
			color="{{
				this.configurationService.config.buttonColor || 'primary'
			}}"
			>Update Your
			{{
				this.configurationService.config.groupLabels[
					'group-label-title-case'
				]
			}}'s Contact Info
		</progress-button>
	</div>
</form>
