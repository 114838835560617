export default [
	{
		label: 'Post and Page Builder Premium',
		type: 'separator',
		helpLink: 'https://www.boldgrid.com/boldgrid-editor',
	},
	{
		label: 'Sliders',
	},
	{
		label: 'Premium Blocks',
	},
	{
		label: 'Blog Widgets',
	},
];
