import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'central-invoice',
	templateUrl: './invoice.component.html',
	styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {
	@Input() public invoice;

	public state = 'loading';
	public columns = [
		'date',
		'description',
		'quantity',
		'unit_amount_in_cents',
		'total_in_cents',
	];

	constructor(
		protected apiService: ApiService,
		protected activatedRoute: ActivatedRoute
	) {}

	ngOnInit(): void {
		if (!this.invoice) {
			this.fetch();
		} else {
			this.state = 'success';
		}
	}

	protected fetch() {
		this.state = 'loading';

		this.apiService
			.get(
				`/v1/wpcr/invoices/${this.activatedRoute.snapshot.params.invoice_id}`,
				{}
			)
			.subscribe({
				next: (invoice: any[]) => {
					this.invoice = invoice;
					this.state = 'success';
				},
				error: () => {
					this.state = 'failed';
				},
			});
	}
}
