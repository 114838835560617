<failed-request *ngIf="'failed' === this.state"></failed-request>
<mat-card
	class="invoice animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8 ctw-mb-8"
	*ngIf="'success' === this.state"
>
	<div class="ctw-flex">
		<div class="ctw-w-1/2">
			<div class="from-address address">
				<ul>
					<li>{{ this.invoice['from_address'].name }}</li>
					<li>{{ this.invoice['from_address'].address1 }}</li>
					<li>{{ this.invoice['from_address'].address2 }}</li>
					<li>
						{{ this.invoice['from_address'].city }},
						{{ this.invoice['from_address'].state }}
						{{ this.invoice['from_address'].zip }}
					</li>
					<li>{{ this.invoice['from_address'].country }}</li>
					<li>Phone: {{ this.invoice['from_address'].phone }}</li>
					<li>Email: {{ this.invoice['from_address'].email }}</li>
				</ul>
			</div>
			<div class="to-address address ctw-w-1/2">
				<div>Bill To</div>
				<ul>
					<li>{{ this.invoice['to_address'].name }}</li>
					<li>{{ this.invoice['to_address'].address1 }}</li>
					<li *ngIf="this.invoice['to_address'].address2">
						{{ this.invoice['to_address'].address2 }}
					</li>
					<li>
						{{ this.invoice['to_address'].city }},
						{{ this.invoice['to_address'].state }}
						{{ this.invoice['to_address'].zip }}
					</li>
					<li>{{ this.invoice['to_address'].country }}</li>
				</ul>
			</div>
		</div>
		<div class="ctw-w-1/2">
			<strong>Invoice</strong>
			<dl class="invoice-info">
				<dt>Invoice #</dt>
				<dd>{{ this.invoice['invoice_number'] }}</dd>
				<dt>Billed On</dt>
				<dd>{{ this.invoice['created_at'] | amDateFormat : 'LL' }}</dd>
				<dt>Due On</dt>
				<dd>{{ this.invoice['due_on'] | amDateFormat : 'LL' }}</dd>
			</dl>

			<div class="amount ctw-mt-10">
				<div class="ctw-flex info">
					<div
						class="status ctw-uppercase ctw-w-4/12 ctw-text-center ctw-p-2"
					>
						{{ this.invoice['state'].replace('_', ' ') }}
					</div>
				</div>
				<div class="ctw-text-3xl ctw-text-center ctw-p-5">
					${{ (this.invoice['total_in_cents'] / 100).toFixed(2) }}
				</div>
			</div>
		</div>
	</div>
	<div class="line-items ctw-mt-5">
		<table mat-table [dataSource]="this.invoice.line_items">
			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef>Date</th>
				<td mat-cell *matCellDef="let element">
					{{ element.created_at | amDateFormat : 'LL' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="description">
				<th mat-header-cell *matHeaderCellDef>Description</th>
				<td mat-cell *matCellDef="let element">
					{{ element.description }}<br />
					<ng-container
						*ngFor="let discount of this.invoice.redemptions"
					>
						<div
							*ngIf="
								discount.amount_in_cents ===
								element.discount_in_cents
							"
							class="ctw-text-sm"
						>
							<strong>{{ discount.name }} Discount Applied</strong
							>: ${{ (discount.amount_in_cents / 100).toFixed(2)
							}}<br />
						</div>
					</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="quantity">
				<th mat-header-cell *matHeaderCellDef>Qty</th>
				<td mat-cell *matCellDef="let element">
					{{ element.quantity }}
				</td>
			</ng-container>

			<ng-container matColumnDef="unit_amount_in_cents">
				<th mat-header-cell *matHeaderCellDef>Price</th>
				<td mat-cell *matCellDef="let element">
					${{ (element.unit_amount_in_cents / 100).toFixed(2) }}
				</td>
			</ng-container>
			<ng-container matColumnDef="discount_in_cents">
				<th mat-header-cell *matHeaderCellDef>Discount</th>
				<td mat-cell *matCellDef="let element">
					<ng-container *ngIf="element.discount_in_cents">
						(${{ (element.discount_in_cents / 100).toFixed(2) }})
					</ng-container>
				</td>
			</ng-container>
			<ng-container matColumnDef="total_in_cents">
				<th mat-header-cell *matHeaderCellDef>Subtotal</th>
				<td mat-cell *matCellDef="let element">
					${{ (element.total_in_cents / 100).toFixed(2) }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="columns"></tr>
			<tr mat-row *matRowDef="let row; columns: columns"></tr>
		</table>
	</div>
	<div class="totals">
		<div>
			<span>Subtotal</span>
			<div>${{ (invoice.subtotal_in_cents / 100).toFixed(2) }}</div>
		</div>
		<div *ngIf="invoice.tax_in_cents">
			<span>Tax</span>
			<div>${{ (invoice.tax_in_cents / 100).toFixed(2) }}</div>
		</div>
		<div>
			<span>Total</span>
			<div>${{ (invoice.total_in_cents / 100).toFixed(2) }}</div>
		</div>
		<div *ngFor="let creditPayment of invoice.credit_payments">
			<span>Credit Applied</span>
			<div>(${{ (creditPayment.amount_in_cents / 100).toFixed(2) }})</div>
		</div>
		<div *ngFor="let transaction of invoice.transactions">
			<span>Paid</span>
			<div>(${{ (transaction.amount_in_cents / 100).toFixed(2) }})</div>
		</div>
	</div>
	<div class="ctw-mt-10 ctw-text-sm">
		<div>Payments</div>
		<ul>
			<li
				class="ctw-text-sm"
				*ngFor="let transaction of this.invoice.transactions"
			>
				{{ transaction.collected_date }} &nbsp; &nbsp; ${{
					(transaction.amount_in_cents / 100).toFixed(2)
				}}
				applied to {{ transaction.card_type }} &middot;&middot;&middot;
				{{ transaction.last_four }}
			</li>
		</ul>
	</div>
	<div class="ctw-mt-10 ctw-text-sm">
		<div>Notes</div>
		<ul>
			<li class="ctw-text-sm">
				Any past due amounts can be paid by updating your payment
				method.
			</li>
			<li class="ctw-text-sm">All amounts listed in USD</li>
		</ul>
	</div>
</mat-card>
