import {
	Component,
	ViewChild,
	Input,
	Output,
	AfterViewInit,
	EventEmitter,
} from '@angular/core';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { PaymentComponent } from 'apps/wordpress-central/src/app/checkout';
import { ProjectService } from '../../../project.service';
import { PublishService } from '../publish.service';
import { WizardComponent } from '../wizard/wizard.component';
@Component({
	selector: 'deploy-checkout',
	templateUrl: './deploy-checkout.component.html',
	styleUrls: ['./deploy-checkout.component.scss'],
})
export class DeployCheckoutComponent implements AfterViewInit {
	public paymentSuccess = false;
	public loadBilling = false;
	public reloadingBilling = false;
	public ownerAccount = {}

	@Input() public plan;
	@Input() public isUpdatePlan = false;
	@Input() public isAddPlan = false;
	@Input() term = 'yearly';
	@Input() subscriptionUuid: string;
	@Input() couponData;
	@Output() deployCheckoutReady = new EventEmitter();
	@ViewChild('paymentForm') public paymentForm: PaymentComponent;

	constructor(
		public publishService: PublishService,
		public projectService: ProjectService,
		public wizardComponent: WizardComponent,
		public stepper: MatStepper,
		public step: MatStep
	) {
		this.ownerAccount = {
			account_id: this.projectService.project.fields.organization_id,
		};
	}

	ngAfterViewInit() {
		// On stepper change
		this.stepper.selectionChange.subscribe((event) => {
			if (event.selectedStep === this.step) {
				this.reloadProducts();

				// The user has nothing to purchase, skip to next step.
				if (!this.publishService.packages.length) {
					this.loadBilling = true;
					this.step.completed = true;
					setTimeout(() => this.stepper.next());
				}
			}
		});
	}

	public reloadProducts() {
		this.reloadingBilling = true;
		setTimeout(() => {
			this.deployCheckoutReady.emit();
			this.reloadingBilling = false;
			this.loadBilling = true;
		}, 1000);
	}

	public getProjectTerm() {
		return this.term;
	}

	public getDiscountObject() {
		if (this.couponData &&
			(this.couponData.discount_type === 'dollars' || this.couponData.discount_type === 'percent')) {
			return JSON.stringify({
				type: this.couponData.discount_type,
				amount: this.couponData.discount_in_cents?.amount ?? this.couponData.discount_percent
			});
		}
	}


	public isComplete() {
		// Complete if current steps has loaded and payment is successful.
		return (
			this.loadBilling &&
			(this.paymentSuccess || !this.publishService.packages.length)
		);
	}

	public onPaymentSuccess() {
		this.paymentForm.receipt.products.find((product) => {
			if (product.code.startsWith('wpc_project')) {
				this.projectService
					.updateProjectFields({
						primary_subscription_uuid:
							product.resource_ids.subscription_uuid,
					})
					.subscribe();
			}
		});

		this.paymentSuccess = true;
		this.step.completed = true;
		this.wizardComponent.isComplete = true;
		//setTimeout(() => this.stepper.next());
	}
}
