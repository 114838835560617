import {
	Component,
	Inject,
	ChangeDetectorRef,
	AfterViewInit,
	OnInit,
} from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../account.service';
import { CartService } from '../cart/cart.service';

@Component({
	selector: 'product-catalog-dialog',
	templateUrl: 'catalog-dialog.component.html',
})
export class CatalogDialogComponent implements AfterViewInit, OnInit {
	public ownerAccount;
	public ownerAccountId;
	public teamName: string;
	public projectId: string;

	public constructor(
		public matDialogRef: MatDialogRef<CatalogDialogComponent>,
		public cartService: CartService,
		public accountService: AccountService,
		public router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		public route: ActivatedRoute,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.ownerAccountId =
			this.data?.team || this.accountService.getAccountId();
	}
	ngOnInit() {
		const getLastChild = (route) => {
			let child = route;
			while (child.firstChild) {
				child = child.firstChild
			}
			return child;
		}
		const primary = this.route.snapshot.root;
		const routeParams = getLastChild( primary ).params;
		this.projectId = routeParams.project_id;
	}

	ngAfterViewInit(): void {
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Turn a catalog item into a package item.
	 *
	 * @since 1.23.0
	 *
	 * @param  selectedItem Item data.
	 */
	public select(selectedItem: any): void {
		if (selectedItem.action) {
			this.matDialogRef.close();
			selectedItem.action();
			return;
		}

		const packages = [];
		for (const product of selectedItem.products) {
			packages.push(product.term);
		}

		this.cartService.set(packages);
		this.router.navigate([`/projects/${this.projectId}/add/playgrounds`]);
		this.matDialogRef.close();
	}

	public setAccount(account) {
		this.teamName = account.display_name;
		this.ownerAccount = account;
		this.ownerAccountId = account.account_id;
	}
}
