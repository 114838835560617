<mat-form-field>
	<input matInput maxlength="40" class="validated" (change)="this.change.emit(); this.checkExistingEmail(input)"
		name="email" #input="ngModel" [(ngModel)]="this.value" email placeholder="Email" [disabled]="this.disabled"
		type="email" required />

	<mat-hint class="animated error" *ngIf="!this.authService.isLoggedIn()" align="start"
		[class.fadeIn]="invalidInput.isInvalid(input)">
		<span *ngIf="!this.value">Email is required.</span>
		<span *ngIf="this.value && !input.valid">Email is not valid.</span>
	</mat-hint>
	<a [hidden]="this.forceLogin" class="user-action" *ngIf="!this.authService.isLoggedIn()" (click)="this.openDialog()"
		matSuffix>Already a User?</a>
	<a class="user-action" *ngIf="this.authService.isLoggedIn()" (click)="this.logOut()" matSuffix>Not You?</a>
</mat-form-field>
