import { BreakpointObserver } from '@angular/cdk/layout';
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService, BrandingService } from '@central/ng-shared';
import { OptimizelyService } from 'libs/ng-shared/src/lib/tracking/optimizely/optimizely.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'central-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit {
	@ViewChild(MatSidenav) public sidenav;
	@Input() public authenticated = false;
	@Output() public toggleMenu = new EventEmitter();

	public hasChildMenu = false;
	public openedDropdown = false;
	public isMobile = false;
	public activeMenuItem: string;

	constructor(
		public brandingService: BrandingService,
		private mediaQuery: BreakpointObserver,
		public authService: AuthService,
		public optimizelyService: OptimizelyService,
		public router: Router
	) {}

	ngOnInit(): void {
		this.authService.profile.onReady().subscribe(() => {
			this.authenticated = this.authService.profile.data.onboarded;
		})

		this.mediaQuery.observe('(max-width: 800px)').subscribe((res) => {
			this.isMobile = res.matches;
			this.applyResponsiveness();
		});
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (
					event.urlAfterRedirects.match(/^\/teams/) ||
					(event.urlAfterRedirects.match(/^\/account/) &&
						!event.urlAfterRedirects.match(
							/^\/account\/(settings|help|billing|subscriptions|receipts)/
						))
				) {
					this.activeMenuItem = 'team';
				} else if (
					event.urlAfterRedirects.match(
						/^\/account\/(settings|help|billing|subscriptions|receipts)/
					)
				) {
					this.activeMenuItem = 'user';
				} else {
					this.activeMenuItem = '';
				}
			}
		});
	}

	ngAfterViewInit(): void {
		setTimeout(() => this.applyResponsiveness());
	}

	public applyResponsiveness() {
		if (this.sidenav) {
			if (this.isMobile) {
				this.sidenav.close();
			} else if (this.authenticated) {
				this.sidenav.open();
			}
		}
	}

	public getMode() {
		let mode = 'side';
		if (this.isMobile && !this.hasChildMenu) {
			mode = 'push';
		}

		return mode;
	}

	public openMenus() {
		this.sidenav.toggle();
		this.toggleMenu.emit();
	}
}
