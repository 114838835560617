import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Formatter } from '../../../../domain/dns-manager/formatter';
import { ProjectService } from '../../../project.service';
import { Router } from '@angular/router';

@Component({
	selector: 'central-diagnostic-tool-dialog',
	templateUrl: './diagnostic-tool-dialog.component.html',
	styleUrls: ['./diagnostic-tool-dialog.component.scss'],
})
export class DiagnosticToolDialogComponent implements OnInit {
	@Input() public diag;
	@Output() public connect = new EventEmitter();
	@Output() public fix = new EventEmitter();

	public loading = true;
	public state = 'pending';

	//public validDomain;
	public selectedDomain = '';
	public environment;
	public errorMessage = '';
	public genericError = 'An error occurred. Please try again.';

	constructor(
		public apiService: ApiService,
		public router: Router,
		public projectService: ProjectService
	) {}

	ngOnInit() {
		this.environment = this.projectService.environment;
	}
	public getSubmitInput() {
		const fixButton = this.diag[0].input.filter(
			(input) =>
				input.action === 'submit' || input.label === 'Update Site URL'
		);
		return fixButton.length === 0
			? false
			: this.diag[0].input.filter(
					(input) =>
						input.action === 'submit' ||
						input.label === 'Update Site URL'
			  )[0];
	}

	public useDomainSelector() {
		return (
			this.diag[0].input.filter(
				(input) =>
					input.type === 'domain-select' ||
					input.label === 'Update Site URL'
			).length > 0
		);
	}

	public updateDomain(data) {
		const regex =
			/^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/g;

		this.selectedDomain = '';

		if (data && data.match(regex)) {
			this.apiService
				.get(
					`/v1/domain-service/domain/availability/${data.trim()}`,
					{}
				)
				.subscribe((response: any) => {
					if (response.object) {
						this.selectedDomain = data.trim();
					}
				});
		}

		if (data && data.includes(this.environment.fields.hostname)) {
			this.selectedDomain = this.environment.fields.hostname;
		}
	}

	public disableFix() {
		return this.useDomainSelector() && '' === this.selectedDomain;
	}

	public submitFix() {
		if (!this.disableFix()) {
			const diagnosis = { fix_id: this.diag[0].diagId };
			this.state = 'submitted';

			if (this.useDomainSelector() && this.selectedDomain !== '') {
				diagnosis['input'] = this.selectedDomain;

				this.apiService.http
					.post(
						this.apiService.formatter.getUrl(
							`/v1/environments/${this.environment.id}/url-validation`
						),
						{ fqdn: this.selectedDomain },
						{
							headers: this.apiService.getHeaders({
								contentType: 'application/json',
							}),
						}
					)
					.subscribe({
						next: (environment: any) => {
							this.projectService
								.reloadProject(false)
								.subscribe((project) => {
									let updateARecord: any = of([]);
									const fields = environment.fields;

									// If the domain can be managed, but the dns is not set yet. Set it now.
									if (fields.domain_info?.state === 'ready') {
										if (
											fields.domain_info
												?.dns_management_enabled &&
											!fields.domain.includes(
												'inmotionhosting.com'
											)
										) {
											updateARecord = this.updateARecord(
												fields.domain_info,
												fields.machine_details
													.machine_ip
											);
										}
									}

									updateARecord.subscribe(() =>
										this.runFix(diagnosis)
									);
								});
						},
						error: (response) => {
							console.log(response);
						},
					});
			} else {
				this.runFix(diagnosis);
			}
		}
	}

	protected runFix(diagnosis) {
		this.fix.emit(this.diag[0]?.fix_delay || 1000);
		this.apiService
			.post('/v1/fix/' + this.environment.id, diagnosis)
			.subscribe({
				next: () => {
					//this.state = 'success';
				},
				error: () => {
					this.state = 'fail';
					this.errorMessage = this.genericError;
				},
			});
		this.diag[0] = false;
	}

	protected updateARecord(domainInfo, ipAddress) {
		const domainId = domainInfo?.domain.id;

		// Fetch the current DNS for a domain.
		return this.getDns(domainId)
			.pipe(this.replaceARecord(domainInfo, ipAddress))
			.pipe(mergeMap((dnsZone) => this.patchDns(domainId, dnsZone)))
			.pipe(this.setFailedState());
	}

	protected getDns(domainId) {
		const url = this.apiService.formatter.getUrl(
			'/v1/domain-service/dns-zone/' + domainId
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		return this.apiService.http.get(url, { headers });
	}

	protected patchDns(domainId, dnsZone) {
		const url = this.apiService.formatter.getUrl(
			`/v1/domain-service/dns-zone`
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		return this.apiService.http.patch(
			url,
			{ domainName: domainId, zoneData: dnsZone },
			{ headers }
		);
	}

	protected replaceARecord(domainInfo, ipAddress: string) {
		return map((dnsZone: any) => {
			dnsZone = dnsZone.filter((entry) => {
				const domainPrefix = entry.name === '@' ? '' : entry.name + '.';
				return (
					entry.type !== 'A' ||
					domainPrefix + domainInfo.domain.baseDomain !==
						domainInfo.domain.fqdn
				);
			});

			const fqdn = domainInfo.domain.fqdn;

			dnsZone.push({
				name: fqdn.startsWith('www.') ? fqdn.substring(4) : fqdn,
				type: 'A',
				rdata: { aValue: ipAddress },
				ttl: 14400,
			});

			if (!domainInfo.domain.subDomain) {
				dnsZone.push({
					name: 'www.' + fqdn,
					type: 'CNAME',
					rdata: { cnameValue: fqdn },
					ttl: 14400,
				});
			} else if (domainInfo.domain.subDomain === 'www') {
				dnsZone.push({
					name: fqdn,
					type: 'CNAME',
					rdata: { cnameValue: fqdn.substring(4) },
					ttl: 14400,
				});
			}

			new Formatter().formatRecordsForApi(
				dnsZone,
				domainInfo.domain.baseDomain
			);

			return dnsZone;
		});
	}

	protected setFailedState() {
		const url = this.apiService.formatter.getUrl(
			`/v1/environments/${this.environment.id}`
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		return catchError(() => {
			this.environment.fields.domain_info.state = 'needs-dns';

			return this.apiService.http
				.patch(
					url,
					{
						fields: {
							domain_info: this.environment.fields.domain_info,
						},
					},
					{ headers }
				)
				.pipe(
					map((environment) => {
						console.warn('Failed to update DNS');
					})
				);
		});
	}
}
