<form #form="ngForm" (ngSubmit)="form.valid && this.addMember()">
	<h3 mat-dialog-title>
		Invite <span *ngIf="this.data.project">a Team </span>Member
	</h3>
	<ng-container *ngIf="this.data?.project">
		<p>
			Billing, Subscriptions and Collaboration is handled in
			<strong><a href="/teams">Teams</a></strong>.
		</p>
		<p>
			Invite a user now to join
			<strong>
				<a href="/account/{{ this.data.ownerId }}/members">
					{{this.data.ownerName}}
				</a>
			</strong>.
		</p>
		<p>
			Team members can see all Projects assigned to a Team and you can
			assign a role to the user after sending your invitation.
		</p>
	</ng-container>
	<p *ngIf="!this.data?.project">
		Send a user an invitation to collaborate.
	</p>
	<p class="error error-message" *ngIf="this.state === 'failed'">
		An error occurred inviting team member. Check the provided email and try
		again.
	</p>
	<mat-dialog-content class="dialog-content">
		<mat-form-field class="ctw-mt-5" appearance="fill">
			<mat-label>Member's E-mail</mat-label>
			<input matInput type="email" #emailField="ngModel" name="email" [(ngModel)]="this.email"
				placeholder="email@address.com" required />
			<mat-error *ngIf="emailField.errors?.required">
				This field is required.
			</mat-error>
		</mat-form-field>
	</mat-dialog-content>
	<mat-form-field class="ctw-mt-5 ctw-w-full" appearance="fill">
		<mat-label>Permission</mat-label>
		<mat-select name="permission" [(ngModel)]="this.role">
			<ng-container *ngFor="let item of this.permissions">
				<mat-option [value]="item.role">
					{{item.label}}
				</mat-option>
			</ng-container>
		</mat-select>
	</mat-form-field>
	<div class="action-buttons ctw-text-right ctw-mt-10">
		<button type="button" mat-button (click)="this.dialogRef.close()">
			Cancel
		</button>
		<progress-button color="primary" [state]="state">Send Invite</progress-button>
	</div>
</form>
