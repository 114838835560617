import { DecoderService } from '../jwt/decoder';
import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PublicStorageService extends LocalStorageService {
	/**
	 * This class is the same as local storage except it uses a different namespace as the LocalStorageService.
	 * This means that it will not be cleared when the user logs out.
	 *
	 * This will not work before login.
	 *
	 */
	public baseNamespace = 'BGCentralPublic';

	public constructor(public jwtHelper: DecoderService) {
		super();

		// Temporarily update the namespace to the base namespace.
		this.updateNamespace();
	}

	/**
	 * When the user logs in set the namespace for this local storage to be user specific.
	 * Also when the application inititalizes.
	 */
	public updateNamespace() {
		const accountId = this.jwtHelper.getTokenVal('user_id');
		let namespaceSuffix = '';

		if (accountId) {
			namespaceSuffix = '_' + accountId;
		}

		this.namespace = this.baseNamespace + namespaceSuffix;
	}
}
