import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Config } from '../../../shared/config/env.config';
@Component({
	selector: 'central-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent {
	apiUrl = (Config.hostVzManager || Config.host) + '/server';
	veid = '73849';
	status = 'pending';
	hasError = false;
	isValid = false;
	serverDown = false;

	constructor(
		public dialogRef: MatDialogRef<PasswordResetComponent>,
		private httpClient: HttpClient
	) {}

	resetPassword(pass) {
		this.httpClient
			.post(this.apiUrl + '/root-reset', {
				veid: this.veid,
				root_pass: pass,
			})
			.subscribe(
				(response: any) => {
					// returns 200 for success and 803 for fail(there may be other status codes).
					if (200 === response.status) {
						this.status = 'success';
						this.dialogRef.close('success');
					} else if (803 === response.status) {
						this.serverDown = true;
						this.status = 'failed';
						this.hasError = true;
					} else {
						this.status = 'failed';
						this.hasError = true;
					}
				},
				(e: HttpErrorResponse) => {
					this.status = 'failed';
					this.hasError = true;
				}
			);
	}

	closeDialog(pass) {
		if (this.isValid) {
			this.status = 'submitted';
			this.resetPassword(pass);
		}
	}

	onStrengthChanged(e) {
		this.isValid = 100 === e;
	}
}
