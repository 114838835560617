import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account.component';
import { SettingsComponent } from './settings/settings.component';
import { SupportComponent } from '../support/support.component';
import { NotificationsComponent } from '../notifications';
import { organizationRoutes } from '../organization';
import { childRoutes as supportRoutes } from '../support/support-routing.module';
import { GuestGuard } from '../authentication/services';

const accountRoutes = [
	...[
		{
			path: 'notifications',
			component: NotificationsComponent,
		},
		{
			path: 'settings',
			component: SettingsComponent,
		},
		{
			path: 'help',
			// component: SupportComponent
			children: supportRoutes,
		},
	],
	...organizationRoutes,
];

const AccountRoutes: Routes = [
	{
		path: 'account',
		component: AccountComponent,
		canActivate: [GuestGuard],
		children: accountRoutes,
	},
	{
		path: 'account/:account_id',
		component: AccountComponent,
		canActivate: [GuestGuard],
		children: accountRoutes,
	},
];

@NgModule({
	imports: [RouterModule.forChild(AccountRoutes)],
	exports: [RouterModule],
})
export class AccountRoutingModule {}
export { AccountRoutes };
