<ng-container *ngIf="'member' === organizationRole">
	<p>You are not authorized to configure this subscription.</p>
</ng-container>
<ng-container *ngIf="'member' !== organizationRole">
	<p>Select a domain to use when creating email accounts:</p>
	<p>
		<!--
	--><span class="username-label">example@</span
		><!--
	--><central-domain-select
			#centralDomainSelect
			[context]="accountId"
			[usedDomains]="this.usedDomains"
		></central-domain-select
		><!--
	--><central-progress-button
			mat-stroked-button
			color="primary"
			(click)="provisionCpanel(centralDomainSelect.selectedDomainName)"
			[disabled]="undefined === centralDomainSelect.selectedDomainName"
			[state]="progressButtonState"
			>{{ progressButtonTitle }}</central-progress-button
		><!--
	-->
	</p>
	<p *ngIf="provisionError" class="error">
		Error saving configuration, try again
	</p>
	<p>
		Don't see the domain you're looking for?
		<a [routerLink]="['/domains']">Add it here</a>
	</p>
</ng-container>
