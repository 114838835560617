import {
	Component,
	OnInit,
	ViewChild,
	Output,
	Input,
	EventEmitter,
} from '@angular/core';
import {
	ApiService,
	AuthService,
	ContactComponent,
	RecurlyCreditCardComponent,
	AccountService,
} from '@central/ng-shared';

@Component({
	selector: 'central-payment-method',
	templateUrl: './billing.component.html',
	styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
	@Input() public cancelable = false;
	@Input() public saveText = 'Update Team Billing Information';

	@Output() public update = new EventEmitter();
	@Output() public cancel = new EventEmitter();

	@ViewChild('contact', { static: false }) contact: ContactComponent;
	@ViewChild('creditCard', { static: false })
	creditCard: RecurlyCreditCardComponent;

	public paymentMethod: any = {};
	public fetchState = 'pending';
	public changeCard = false;
	public updateState = 'pending';
	public errorMessage = '';
	public hasInternalBilling = false;
	public isOrganization = true;

	constructor(
		public apiService: ApiService,
		public authService: AuthService,
		public accountService: AccountService // public configurationService: ConfigurationService,
	) {
		// this.hasInternalBilling = this.configurationService.config.hasVantiveBilling;
	}

	ngOnInit(): void {
		if (
			this.accountService.isOrganization() &&
			!this.accountService.hasAccess('manage')
		) {
			this.fetchState = 'unauthorized';
		} else {
			this.fetchBillingInformation();
		}

		if (
			!this.accountService.isOrganization() &&
			this.authService.profile.data.type === 'org'
		) {
			this.isOrganization = false;
		}
	}

	public submit() {
		const address = this.getInfo();
		this.errorMessage = '';

		if (this.changeCard) {
			this.updateState = 'submitted';

			// Send update when use CC.
			this.creditCard
				.getToken(address)
				.then((token) => {
					if ('vantiv' === token['type']) {
						this.updateBilling({ address, vantiv: token });
					} else {
						this.updateBilling({ address, card: token['id'] });
					}
				})
				.catch(() => (this.updateState = 'failed'));
		} else {
			// Send Update for address fields only.
			this.updateBilling({ address });
		}
	}

	public getInfo() {
		const address = this.contact.model.getData();
		address['zip'] = address.postal_code;

		return address;
	}

	public hasCard() {
		return !!this.paymentMethod.card_type;
	}

	public fetchBillingInformation() {
		this.fetchState = 'loading';
		const accountId = this.accountService.id;

		this.apiService
			.get(`/v1/wpcr/accounts/${accountId}/payments/method`, {})
			.subscribe({
				next: (paymentMethod: any) => {
					this.loadForm(paymentMethod);

					if (!paymentMethod.zip) {
						this.changeCard = true;
					}

					this.fetchState = 'success';
				},
				error: () => {
					this.fetchState = 'failed';
				},
			});
	}

	public updateBilling(params) {
		this.updateState = 'submitted';
		const accountId = this.accountService.id;
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl(
			`/v1/wpcr/accounts/${accountId}/payments/method`
		);
		this.apiService.http.post(url, params, { headers }).subscribe({
			next: (newInfo) => {
				this.loadForm(newInfo);
				this.updateState = 'success';
				this.update.emit();
			},
			error: (response) => {
				this.errorMessage = this.apiService.getPublicError(response);
				this.updateState = 'failed';
			},
		});
	}

	private loadForm(apiBillingInfo) {
		this.paymentMethod = apiBillingInfo;
		this.paymentMethod.postal_code = this.paymentMethod.zip;

		this.contact.model.update(this.paymentMethod);
		this.contact.autofillControls['country'].control.setValue(
			this.paymentMethod.country
		);
		this.contact.autofillControls['state'].control.setValue(
			this.paymentMethod.state
		);
	}
}
