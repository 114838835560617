<ng-container *ngIf="display === 'tables'">
	<ng-container *ngIf="this.status === 'loading'">
		<mat-progress-spinner
			class="component-loader"
			mode="indeterminate"
			color="accent"
		></mat-progress-spinner>
	</ng-container>
	<div class="container" *ngIf="this.status === 'fail'">
		<failed-request></failed-request>
	</div>
	<ng-container *ngIf="this.status === 'pending-selection'">
		<div
			class="ctw-text-center premium-feature animate__animated animate__zoomIn"
			*ngIf="this.view === 'premium-only'"
		>
			<h3>Premium Feature</h3>
			<p>
				You already have a Free Connect Key. Free keys are limited to
				one key per email address unless you have at least one Premium
				Key in your account. You can upgrade your current key or
				purchase a new Premium Connect Key to access additional Free
				Keys.
			</p>
			<button
				mat-raised-button
				mat-ripple
				type="button"
				color="accent"
				(click)="this.view = 'compare'"
			>
				Okay, Got It!
			</button>
		</div>
		<div *ngIf="this.view !== 'premium-only'" class="price-wrap">
			<mat-card
				*ngFor="let table of tables"
				[attr.color]="table.color"
				class="pricing-table animate__animated animate__fadeIn"
			>
				<div class="pricing-header" *ngIf="planTerm === 'monthly'">
					<h3>
						{{
							table['price']
								? '$' + (table['price'] / 10).toFixed(2)
								: 'Free'
						}}
						<span class="term" *ngIf="table['price']">/ month</span>
					</h3>
					<div class="name">{{ table.label }}</div>
				</div>
				<div class="pricing-header" *ngIf="planTerm === 'yearly'">
					<h3>
						{{
							table['price']
								? '$' + table['price'].toFixed(2)
								: 'Free'
						}}
						<span class="term" *ngIf="table['price']">/ year</span>
					</h3>
					<div class="name">{{ table.label }}</div>
				</div>
				<div class="pricing-body">
					<button
						mat-raised-button
						mat-ripple
						type="button"
						(click)="select(table)"
					>
						Select
					</button>
					<ul>
						<li
							*ngFor="let feature of table.features"
							[class.separator]="
								feature.type && feature.type === 'separator'
							"
						>
							<mat-icon class="item-icon">{{
								feature.icon || 'done'
							}}</mat-icon>
							<span class="label"
								>{{ feature.label }}
								<a
									class="help-link"
									target="_blank"
									[href]="feature.helpLink"
									*ngIf="feature.helpLink"
									><mat-icon>help</mat-icon></a
								></span
							>
							<span *ngIf="feature.desc" class="desc">{{
								feature.desc
							}}</span>
						</li>
					</ul>
				</div>
			</mat-card>
		</div>
	</ng-container>
</ng-container>

<div *ngIf="display === 'radio'" class="radio-selections">
	<mat-progress-spinner
		*ngIf="this.status === 'loading'"
		class="component-loader"
		mode="indeterminate"
		color="accent"
		diameter="20"
	></mat-progress-spinner>
	<failed-request *ngIf="this.status === 'fail'"></failed-request>
	<ng-container *ngIf="this.status === 'pending-selection'">
		<mat-radio-group [(ngModel)]="this.selection">
			<ng-container *ngFor="let option of additionalOptions">
				<mat-radio-button [value]="option">
					<span class="name">{{ option.label }}</span>
					<span class="price">{{ option['price'] }}</span>
				</mat-radio-button>
			</ng-container>
			<ng-container *ngFor="let table of tables; let i = index">
				<mat-radio-button [value]="table">
					<span class="name">{{ table.label }}</span>
					<span class="price"
						>{{
							table['price']
								? '$' + (table['price'] / 12).toFixed(2)
								: 'Free'
						}}
						<span class="term" *ngIf="table['price']"
							>/ mo</span
						></span
					>
				</mat-radio-button>
			</ng-container>
		</mat-radio-group>
	</ng-container>
</div>
