import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../project.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SnackLoadingComponent } from '../../snack-loading/snack-loading.component';
import { RestorationConfirmComponent } from './dialog-restoration/restoration-confirm.component';
import { BackupsDialogScheduleComponent } from './dialog-schedule/backups-dialog-schedule.component';
import { BackupsUpdateProtectionComponent } from './dialog-update-protection/backups-update-protection.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { AutoUpdate } from './Settings/AutoUpdate';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
	selector: 'app-backups',
	templateUrl: './backups.component.html',
	styleUrls: ['../w3/w3.component.scss', './backups.component.scss'],
})
export class BackupsComponent implements OnInit {
	public hasPlugin = false;
	public state = 'loading';
	public backupStatus = 'pending';
	public pluginName = 'boldgrid-backup';
	public pluginZip =
		'https://downloads.wordpress.org/plugin/boldgrid-backup.zip';
	public backups = [];
	public loadingSnackbar;
	public testResults = {};
	public settings;
	public hasScheduledBackups = false;
	public hasAutoUpdates = false;
	public settingsHelper = new AutoUpdate();
	public isMultisite = false;
	public ready = false;
	public plugins = [];

	constructor(
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		public projectService: ProjectService
	) {}

	async ngOnInit() {
		if (this.projectService.environmentState === 'pending') {
			this.state = 'failed';
		} else {
			this.getPlaybookStatus();

			this.projectService.connected.subscribe(async (msg: any) => {
				if (
					msg === 'ready' &&
					this.projectService.client &&
					this.state !== 'success' &&
					this.state !== 'blocked'
				) {
					this.hasPlugin = await this.checkPlugin();
					this.postPluginActive();
				}

				if (msg !== 'ready') {
					this.state = 'blocked';
				}
			});

			this.projectService.onEnvironmentChange.subscribe(() => {
				if (this.loadingSnackbar) {
					this.loadingSnackbar.dismiss();
				}
			});
		}
	}

	/**
	 * Gets status of project's playbook runs
	 */
	getPlaybookStatus() {
		this.projectService.playbookStatus.subscribe(async (msg: any) => {
			this.state = msg !== 'playbook-not-running' ? 'blocked' : this.state;
			if (
				this.state === 'blocked' &&
				(msg === 'playbook-not-running' || msg === 'complete')
			) {
				this.state = 'loading';
				this.hasPlugin = await this.checkPlugin();
				this.postPluginActive();
			}
		});
	}

	public postPluginActive() {
		this.projectService.getSiteStats().subscribe(
			(stats: any) => {
				this.projectService.connected.next('ready');
				this.isMultisite =
					'Yes' === stats.debug['wp-core'].fields.multisite.value;

				if (!this.isMultisite && this.hasPlugin) {
					this.fetchBackups();
					this.fetchSettings();
					this.fetchPreflight();
				} else {
					this.state = 'success';
				}
			},
			() => {
				this.projectService.connected.next('failed');
			}
		);
	}

	public openFunctionalityTests() {
		this.projectService.login(
			'wp-admin/admin.php?page=boldgrid-backup-test'
		);
	}

	public fetchPreflight() {
		this.projectService.client
			.namespace('bgbkup/v1')
			.test()
			.get()
			.then((testResults) => (this.testResults = testResults));
	}

	public openSchedulePrompt() {
		const matRef = this.dialog.open(BackupsDialogScheduleComponent, {
			width: '500px',
			data: {
				projectService: this.projectService,
				hasScheduledBackups: this.hasScheduledBackups,
				settings: this.settings,
			},
		});

		matRef.componentInstance.onComplete.subscribe((settings) => {
			this.updateSettings(settings);
		});
	}

	public openAutoUpdatePrompt() {
		const matRef = this.dialog.open(BackupsUpdateProtectionComponent, {
			width: '500px',
			data: {
				projectService: this.projectService,
				settings: this.settings,
			},
		});

		matRef.componentInstance.onComplete.subscribe((settings) => {
			this.updateSettings(settings);
		});
	}

	private updateSettings(settings) {
		this.settings = settings;
		this.hasScheduledBackups = this.findHasScheduledBackups();
		this.hasAutoUpdates = this.settingsHelper.hasAutoUpdates(this.settings);
	}

	private fetchSettings() {
		this.projectService.client
			.namespace('bgbkup/v1')
			.settings()
			.get()
			.then((settings) => {
				this.updateSettings(settings);
			});
	}

	public async activatePlugin() {
		this.state = 'submitted';

		await this.projectService.client
			.namespace('bgc/v1')
			.plugins()
			.create({ activate: true, plugins: [this.pluginZip] })
			.then((plugins) => {
				this.plugins = plugins;
				this.hasPlugin = this.mapPlugin(plugins);
			})
			.then(() => this.projectService.wpRestService
					.rediscoverSite(this.projectService.environment)
					.then(() => this.projectService.resetClient()))
			.then(() => {
				this.checkPlugin().then((hasPlugin) => {
					//this.hasPlugin = hasPlugin;
					if (this.hasPlugin) {
						this.postPluginActive();
					} else {
						console.warn('Install Failed');
					}
				});
			})
			.catch(() => (this.state = 'failed'));
	}

	/**
 	* Delete the plugin.
 	*/
	public disableFeature() {
		this.state = 'submitted';

		this.projectService.client
			.namespace('bgc/v1')
			.plugins()
			.update({ active: false, files: [this.pluginName] })
			.then(() => this.projectService.client
					.namespace('bgc/v1')
					.plugins()
					.delete({ files: [this.pluginName] }))
			.then(() => {
				this.hasPlugin = false;
				this.state = 'success';
			})
			.catch((error) => {
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.projectService.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								this.disableFeature();
							},
							() => {
								this.projectService.connected.next('failed');
								this.state = 'failed';
							}
						);
				} else {
					this.projectService.connected.next('failed');
					this.state = 'failed';
				}
			});
	}


	public fetchBackups() {
		return this.projectService.client
			.namespace('bgbkup/v1')
			.archives()
			.get()
			.then((backups) => {
				this.backups = backups;
				this.state = 'success';
			})
			.catch((error) => {
				this.state = 'failed';
			});
	}

	public createBackup() {
		this.backupStatus = 'submitted';
		this.loadingSnackbar = this.snackBar.openFromComponent(
			SnackLoadingComponent,
			{
				duration: 3000,
				data: {
					label: 'Backing Up Website',
				},
			}
		);

		this.projectService.client
			.namespace('bgbkup/v1')
			.archives()
			.create()
			.then((job) => {
				this.poll(job).subscribe(() => {
					this.fetchBackups().then(() => {
						this.backupStatus = 'pending';
						this.loadingSnackbar.dismiss();
						this.snackBar.open('Site Backup Complete!', '', {
							duration: 3000,
						});
					});
				});
			})
			.catch(() => {
				this.loadingSnackbar.dismiss();
				this.snackBar.open('Failed Creating Backup', '', {
					duration: 3000,
				});
			});
	}

	public openRestoreConfirm(backup) {
		const ref = this.dialog.open(RestorationConfirmComponent, {
			width: '500px',
			data: {
				backup,
			},
		});

		ref.componentInstance.restorationConfirmed.subscribe(() => {
			ref.componentInstance.state = 'submitted';

			this.projectService.client
				.namespace('bgbkup/v1')
				.archives()
				.update({ id: backup.id })
				.then((job) => {
					ref.close();
					this.loadingSnackbar = this.snackBar.openFromComponent(
						SnackLoadingComponent,
						{
							duration: 5000,
							data: {
								label: 'Restoring Website',
							},
						}
					);

					// Set the state for the environment.
					this.projectService.setEnvironmentState('updating');

					// Wait for job to be done.
					this.poll(job).subscribe(
						() => this.restorationSuccess(),
						(error) => this.restorationError(job, error)
					);
				})
				.catch(() =>
					this.projectService.setEnvironmentState('pending')
				);
		});
	}

	public restorationError(job, error) {
		const fail = () => {
			this.projectService.setEnvironmentState('pending');
		};

		if ('restx_logged_out' === error.code && 'restore' === job.type) {
			// Re-authenticating.
			this.projectService.wpRestService
				.reloadClient(this.projectService.environment)
				.subscribe(
					(client) => {
						this.projectService.client = client;
						this.projectService.client
							.namespace('bgbkup/v1')
							.jobs()
							.id(job.id)
							.get()
							.then((jobUpdated) => {
								if ('done' === jobUpdated['status']) {
									this.restorationSuccess();
								} else {
									fail();
								}
							});
					},
					() => fail()
				);
		} else {
			fail();
		}
	}

	public restorationSuccess() {
		this.projectService.setEnvironmentState('pending');
		this.loadingSnackbar.dismiss();
		this.snackBar.open('Restoration Complete!', '', { duration: 3000 });
	}

	public poll(job) {
		return new Observable((observer) => {
			const interval = setInterval(() => {
				this.projectService.client
					.namespace('bgbkup/v1')
					.jobs()
					.id(job.id)
					.get()
					.then((jobUpdated) => {
						if ('done' === jobUpdated['status']) {
							clearInterval(interval);
							observer.next(jobUpdated);
							observer.complete();
						}
					})
					.catch((error) => {
						clearInterval(interval);
						observer.error(error);
					});
			}, 8000);
		});
	}

	private findHasScheduledBackups() {
		let hasScheduledBackups = false;
		for (const [label, enabled] of Object.entries(this.settings.schedule)) {
			if (label.startsWith('dow') && enabled) {
				hasScheduledBackups = true;
			}
		}

		return hasScheduledBackups;
	}

	/**
	 * Check if the current plugin is active.
	 *
	 * @param plugins Is this plugin active.
	 */
	private async checkPlugin() {
		return this.projectService.wpRestService
			.rediscoverSite(this.projectService.environment)
			.then(() => this.projectService.resetClient())
			.then(() =>
				this.projectService.client._ns.hasOwnProperty('bgbkup/v1')
			);
	}

	/**
	 * Check if the current plugin is active.
	 *
	 * @param plugins Is this plugin active.
	 */
	private mapPlugin(plugins: any[]): boolean {
		let hasPlugin = false;
		plugins.map((val) => {
			if (val['File'].endsWith(this.pluginName) && val['IsActive']) {
				hasPlugin = true;
			}
		});

		return hasPlugin;
	}
}
