<div [hidden]="this.state === 'failed'">
	<mat-form-field class="user-select" [appearance]="this.inputAppearance">
		<mat-label>Project</mat-label>
		<mat-select
			[(ngModel)]="this.resource"
			(selectionChange)="change($event.value)"
		>
			<ng-container *ngFor="let project of this.projects">
				<mat-option [value]="project">{{ project.label }}</mat-option>
			</ng-container>
		</mat-select>
	</mat-form-field>
</div>
<div class="error" *ngIf="this.state === 'failed'">
	We were unable to load your projects. Please try again later.
</div>
