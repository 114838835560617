// logged-in.guard.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ConfigurationService } from '../../../core/configuration.service';

@Injectable()
export class GuestGuard implements CanActivate {
	constructor(
		private auth: AuthService,
		private configService: ConfigurationService,
		private router: Router
	) {}

	// A user must be logged in in order to activate this action.
	// Otherwise: send the user to logout, this will clear session token and redirect to login.
	public canActivate(activatedRouteSnapshot) {
		let canActivate = false;

		// Check to see if a user has a valid JWT
		if (this.auth.isLoggedIn()) {
			if (this.auth.isGuideLocked() && !this.getCurrenRoute(activatedRouteSnapshot).includes('/guide/project') &&
				!this.getCurrenRoute(activatedRouteSnapshot).includes('/domains/search')) {
				// User locked to the guide, redirect them to the guide.
				this.router.navigate(['/sign-up/']);
			}
			// If they do, return true and allow the user to load the home component
			canActivate = true;
		} else {
			// User tries to access a page that needs authentication, save the page
			// they wanted to visit.
			this.auth.loginUrl = this.getCurrenRoute(activatedRouteSnapshot);

			// If not, they redirect them to the login page
			this.auth.silentLogout();
			this.router.navigate([this.configService.config.routes.login]);
		}

		return canActivate;
	}

	/**
	 * Get the app relative route name.
	 *
	 * @since 1.18.0
	 *
	 * @return Route name.
	 */
	private getCurrenRoute(
		activatedRouteSnapshot: ActivatedRouteSnapshot
	): string {
		const urlPath = activatedRouteSnapshot['_routerState'].url;
		const currentRoute = urlPath + location.search;
		return currentRoute;
	}
}
