<mat-spinner
	*ngIf="status === 'loading'"
	class="component-loader"
	diameter="50"
	color="primary"
></mat-spinner>
<failed-request *ngIf="status === 'failed-loading'"></failed-request>
<ng-template #installedPlugins let-data="data">
	<h4 class="ctw-text-center" *ngIf="!data.length">No Plugins Found</h4>
	<table
		mat-table
		[dataSource]="data"
		*ngIf="data.length"
		class="mat-elevation-z plugins"
	>
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef>Plugin</th>
			<td mat-cell *matCellDef="let element">{{ element.Name }}</td>
		</ng-container>

		<ng-container matColumnDef="version">
			<th mat-header-cell *matHeaderCellDef>Version</th>
			<td mat-cell class="update-button" *matCellDef="let element">
				<span class="version">{{ element.Version }}</span
				><span *ngIf="element.Update" class="ctw-mr-3"
					><button
						class="update-button"
						(click)="
							this.wpRestService.login(
								this.projectService.environment,
								'wp-admin/update-core.php'
							)
						"
						mat-button
						mat-stroked-button
						color="primary"
					>
						Update Available
					</button></span
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="deactivated">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let element">
				<label id="deact-label" *ngIf="!element.IsActive"
					>Deactivated</label
				>
			</td>
		</ng-container>

		<ng-container matColumnDef="options">
			<th mat-header-cell *matHeaderCellDef>options</th>
			<td mat-cell *matCellDef="let element">
				<mat-menu
					id="opt-menu"
					#optionsMenu="matMenu"
					yPosition="below"
				>
					<button
						*ngIf="element.IsActive"
						mat-menu-item
						(click)="this.uninstall(element)"
					>
						Deactivate
					</button>
					<button
						*ngIf="!element.IsActive"
						mat-menu-item
						(click)="this.activate(element)"
					>
						Activate
					</button>
					<button
						*ngIf="!element.IsActive"
						mat-menu-item
						(click)="this.delete(element)"
					>
						Delete
					</button>
				</mat-menu>

				<button
					id="installed-buttons"
					mat-icon-button
					[matMenuTriggerFor]="optionsMenu"
				>
					<mat-icon *ngIf="element.state !== 'submitted'"
						>more_vert</mat-icon
					>
					<mat-spinner
						diameter="20"
						color="primary"
						*ngIf="element.state === 'submitted'"
					></mat-spinner>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</ng-template>
<mat-tab-group
	#tabGroup
	(selectedTabChange)="
		this.tabSelected = this.tabSelected || tabGroup.selectedIndex === 1
	"
	*ngIf="status === 'ready'"
	class="site-plugin--component"
>
	<mat-tab label="My Plugins">
		<div class="ctw-mt-8 animate__animated animate__fadeIn">
			<div class="installed">
				<h3>Active</h3>
				<a
					mat-button
					mat-stroked-button
					color="primary"
					(click)="this.projectService.login('wp-admin/plugins.php')"
					>Manage in WordPress</a
				>
			</div>
			<ng-container
				*ngTemplateOutlet="
					installedPlugins;
					context: { data: this.getActivePlugins() }
				"
			></ng-container>

			<ng-container *ngIf="this.recommended.length">
				<h3>Recommended</h3>
				<table
					mat-table
					[dataSource]="recommended"
					class="mat-elevation-z recommended"
				>
					<!--- Note that these columns can be defined in any order.
							The actual rendered columns are set as a property on the row definition" -->

					<!-- Position Column -->
					<ng-container matColumnDef="position">
						<th mat-header-cell *matHeaderCellDef>Plugin</th>
						<td mat-cell *matCellDef="let element">
							<div class="plugin-info">
								<div class="plugin-icon">
									<img [src]="element.assets.icon_normal" />
								</div>
								<div>
									<strong>{{ element.name }}</strong>
									<div class="plugin-description">
										{{ element.sections.description }}
									</div>
								</div>
							</div>
						</td>
					</ng-container>
					<!-- Symbol Column -->
					<ng-container matColumnDef="symbol">
						<th mat-header-cell *matHeaderCellDef>Install</th>
						<td mat-cell *matCellDef="let element">
							<central-progress-button
								color=""
								[state]="element.state"
								(click)="this.install(element)"
								>Activate</central-progress-button
							>
						</td>
					</ng-container>

					<tr
						mat-header-row
						*matHeaderRowDef="displayedSuggested"
					></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: displayedSuggested"
					></tr>
				</table>
			</ng-container>

			<h3>Inactive</h3>
			<ng-container
				*ngTemplateOutlet="
					installedPlugins;
					context: { data: this.getInactivePlugins() }
				"
			></ng-container>
		</div>
	</mat-tab>
	<mat-tab label="Add New Plugin">
		<plugin-selector
			*ngIf="tabSelected"
			[action]="this.action"
			[steps]="[]"
			[allowedFilters]="['featured', 'popular', 'recommended']"
		></plugin-selector>
	</mat-tab>
</mat-tab-group>
