<div class="delete-confirm">
	<div>
		<h3>Turn off 2-factor Verification?</h3>
		<p>
			Are you sure you want to remove this device as a secondary
			authentication device?
		</p>
		<p class="error" [class.invisible]="'failed' !== this.state">
			Unable to remove device. Please try again later.
		</p>
		<div class="ctw-text-right ctw-mt-2">
			<span class="ctw-mr-5"
				><button matDialogClose mat-button>Cancel</button></span
			>
			<progress-button color="warn" (click)="this.deleteDevice()"
				>Yes, Disable</progress-button
			>
		</div>
	</div>
</div>
