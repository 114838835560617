import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'bg-checkout',
	templateUrl: 'checkout.component.html',
	styleUrls: ['checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
	@ViewChild('payment', { static: true }) public payment: any;
	public cookieCode = '';
	public paramCode = '';
	public organizationId;

	public constructor(
		public apiService: ApiService,
		private cookieService: CookieService,
		private route: ActivatedRoute
	) {
		this.cookieCode = this.cookieService.get('bgc_discountCode');
	}

	public ngOnInit() {
		// Grab the products from the route and format them into a cart object.
		this.route.params.subscribe((params) => {
			const packages = params['products']
				? params['products'].split(',')
				: null;
			this.payment.useCartService = !packages;
			this.payment.packages = packages;
			this.payment.organizationId = params['accountId'] || null;
		});

		this.route.queryParams.subscribe((params) => {
			this.paramCode = params['code'];
			this.setDiscountCode();
		});
	}

	public setDiscountCode() {
		if (this.paramCode) {
			this.payment.discountCode = this.paramCode;
		} else if (this.cookieCode) {
			this.payment.discountCode = this.cookieCode;
		}
	}
}
