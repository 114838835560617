<ng-container *ngFor="let type of ['basic', 'sticky']">
	<mat-card
		#tools
		class="tools"
		[class.sticky-nav]="type === 'sticky'"
		[class.enabled]="type === 'sticky' && showSticky"
	>
		<button
			class="previous"
			*ngIf="-1 !== this.steps.indexOf('back')"
			mat-button
			mat-raised-button
			(click)="this.back.emit()"
		>
			<mat-icon>keyboard_arrow_lefty</mat-icon>
			<span class="text">Back</span>
		</button>
		<ul class="filters">
			<li
				*ngFor="let filter of this.filters"
				[class]="filter.name + '--filter'"
				(click)="this.changeFilter(filter)"
				[class.active]="this.view === filter"
			>
				{{ filter.label }}
			</li>
		</ul>
		<span class="fill"></span>
		<div class="search">
			<input
				placeholder="Search..."
				(input)="this.debouncedSearch()"
				type="search"
				name="query"
				[(ngModel)]="this.query"
			/>
			<button
				class="next"
				*ngIf="-1 !== this.steps.indexOf('next')"
				mat-button
				mat-raised-button
				color="accent"
				(click)="this.next.emit()"
			>
				<span class="text">Next Step</span>
				<mat-icon>keyboard_arrow_right</mat-icon>
			</button>
		</div>
	</mat-card>
</ng-container>
