import wpapi from 'wpapi';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ApiService } from '@central/ng-shared';
import { Post } from '../../shared/forms/post';
import { ConfigurationService } from '@central/ng-shared';

interface DiscoveredSite {
	url: string;
	accessToken: object;
	rest: any;
	environment: Environment;
}

@Injectable()
export class WpRestService {
	public constructor(
		public apiService: ApiService,
		public configService: ConfigurationService
	) {}

	public requireProxy = false;
	public connection = new EventEmitter();
	public sitesDiscovered: DiscoveredSite[] = [];
	public sitesRequested = [];
	public authedSite = null;
	public hasReturnedAuthedSite = false;

	public getSite(environment: Environment) {
		const wordPressUrl = this.getConnectionUrl(environment);
		const site = this.sitesDiscovered.filter((siteDiscovered) => siteDiscovered.url === wordPressUrl);

		return site[0] ? site[0] : null;
	}

	public authenticate(rest, environment: Environment, authToken) {
		return new Observable((observer) => {
			const wordPressUrl = this.getConnectionUrl(environment);
			rest.namespace('bgc/v1')
				.auth()
				.create({ token: authToken, environment_id: environment.id })
				.then((response) => {
					const currentSite = {
						url: wordPressUrl,
						environment,
						rest,
						accessToken: response,
					};
					const site = this.getSite(environment);
					if (!site) {
						this.sitesDiscovered.push(currentSite);
					} else {
						site.rest = rest;
						site.accessToken = response;
					}

					rest.setHeaders(
						'X-BGC-Auth',
						'Bearer ' + currentSite.accessToken.access_token
					);
					this.setProxyHeader(currentSite, rest);

					this.connection.emit({
						status: 'authenticated',
						message: 'Successfully Authenticated',
						url: wordPressUrl,
					});

					observer.next(rest);
					observer.complete();
				})
				.catch((error) => {
					console.log('Failed to authenticate.');
					observer.error(error);
				});
		});
	}

	public discover(wordPressUrl: string) {
		return new Observable((observer) => {
			this.discoverOverride(wordPressUrl)
				.then((rest) => {
					rest.namespace('bgc/v1');
					this.connection.emit({
						status: 'connected',
						message: 'Discovered WordPress install',
						url: wordPressUrl,
					});
					observer.next(rest);
					observer.complete();
				})
				.catch((error) => {
					if (error.toString().match(/Access-Control-Allow-Origin/)) {
						// Force connection via proxy if site responds with
						// a bad Access-Control-Allow-Origin header violating CORS
						this.requireProxy = true;
						this.discoverOverride(wordPressUrl)
							.then((rest) => {
								this.connection.emit({
									status: 'connected',
									message: 'Discovered WordPress install',
									url: wordPressUrl,
								});
								observer.next(rest);
								observer.complete();
							})
							.catch((e) => {
								console.log('error');
								console.log(e);
								observer.error(e);
							});
					} else {
						console.log(error.message);
						observer.error(error);
					}
				});
		});
	}

	public fetchToken(environment: Environment) {
		return this.apiService.post('createEnvironmentToken', {
			environment_id: environment.id,
		});
	}

	public rediscoverSite(environment: Environment) {
		const site = this.getSite(environment);
		if (site) {
			return this.discoverOverride(site.url).then((rest) => {
				site.rest = rest;
				rest.setHeaders(
					'X-BGC-Auth',
					'Bearer ' + site.accessToken['access_token']
				);
				this.setProxyHeader(site, rest);
				return rest;
			});
		}

		return site;
	}

	public setProxyHeader(site, rest) {
		if (this.requiresProxy(site.url)) {
			rest.setHeaders('X-Wordpress-Url', site.url);
		}
	}

	public reloadClient(environment: Environment) {
		return this.getClient(environment, true);
	}

	public getClient(environment: Environment, refresh = false) {
		const site = this.getSite(environment);
		this.sitesRequested.push(environment.id);

		if (site && !refresh) {
			return new Observable((observer: any) => {
				observer.next(site.rest);
				observer.complete();
			});
		}

		return this.fetchToken(environment).pipe(
			mergeMap((tokenResponse) => this.discover(this.getConnectionUrl(environment)).pipe(
				mergeMap((rest) => this.authenticate(
					rest,
					environment,
					tokenResponse['token']
				))
			))
		);
	}

	public login(environment, redirectUrl = '', newTab = true) {
		const formPost = new Post();
		const discoveredSite = this.getSite(environment);

		if (discoveredSite) {
			const params = {
				has_access_token: 1,
				user_id: discoveredSite.accessToken['wp_user_id'],
				environment_id: environment.id,
				token: discoveredSite.accessToken['access_token'],
			};

			if (redirectUrl) {
				params['redirect_url'] = redirectUrl;
			}

			formPost.postForm(
				discoveredSite.accessToken['login_url'],
				params,
				newTab
			);
		}
	}

	private requiresProxy(url: string): boolean {
		return (
			this.requireProxy ||
			// if is not dev mode and missing ssl
			(url.indexOf('https://') !== 0 &&
				this.configService.config.environment !== 'development')
		);
	}

	public discoverOverride(url) {
		const requiresProxy = this.requiresProxy(url);

		let apiUrl = url;
		if (requiresProxy) {
			apiUrl = this.configService.config.host + '/wp-rest-proxy/';
		}

		// Use WPAPI.site to make a request using the defined transport
		const req = wpapi.site(apiUrl).root().param('rest_route', '/');

		if (requiresProxy) {
			req.setHeaders('X-Wordpress-Url', url);
		}

		return req.get().then((apiRootJSON) => {
			let routes = apiRootJSON.routes;

			if (requiresProxy) {
				for (const [path, route] of Object.entries(routes)) {
					const href = route['_links']?.self[0].href;

					if (href) {
						routes[path]['_links'].self[0].href = href.replace(
							url,
							apiUrl
						);
					}
				}
			}

			const newObj = {};
			for (const key in routes) {
				if (key.indexOf('bgbkup') !== -1 || key.indexOf('(') === -1) {
					newObj[key] = routes[key];
				}
			}

			routes = newObj;

			const rest = new wpapi({
				// Derive the endpoint from the self link for the / root
				// endpoint: routes['/']._links.self[0].href.replace( '/wp-json/', '/?rest_route=/' ),
				endpoint: this.configService.hasFeature('connectionUrl') && url.includes('inmotionhosting.com')
					? routes['/']._links.self[0].href.replace(/^........[^\/]*/, requiresProxy ? apiUrl : url)
					: routes['/']._links.self[0].href,
				// Bootstrap returned WPAPI instance with the discovered routes
				routes: routes,
			});

			if (this.requiresProxy(url)) {
				rest.setHeaders('X-Wordpress-Url', url);
			}

			return rest;
		});
	}

	public getConnectionUrl(environment: Environment) {
		if (this.configService.hasFeature('connectionUrl')
			&& environment.fields.environment_type === 'vps') {
			if (environment.fields.connection_url) {
				return environment.fields.connection_url;
			} else {
				return 'https://' + environment.fields.hostname;
			}
		} else {
			return environment.fields.site_url;
		}
	}
}
