<h3 mat-dialog-title>Delete {{ data.thirdPartyDomain.domainName }}?</h3>
<mat-dialog-content class="ctw-mb-4">
	<p>
		This will permanently delete all DNS records associated with the domain.
	</p>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="ctw-text-right ctw-w-full">
		<button mat-button [mat-dialog-close]="false">Cancel</button>
		<button mat-raised-button [mat-dialog-close]="true" color="warn">
			Delete
		</button>
	</div>
</mat-dialog-actions>
