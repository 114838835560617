import { ProjectService } from '../../project.service';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ApiService } from '@central/ng-shared';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SiteSetupWarnDialogComponent } from './site-setup-warn-dialog.component';

@Component({
	selector: 'central-site-setup',
	templateUrl: './site-setup.component.html',
	styleUrls: ['./site-setup.component.scss'],
})
export class SiteSetupComponent implements OnChanges, OnDestroy {
	@Input() public environment;

	public interval;
	public polling = false;
	public state = '';
	public mode = 'new';
	public error = '';
	public siteUrl;
	public username;
	public password;
	public skipWarn = false;
	public inputType = 'password';
	public progressButtonState = '';
	public domainValidator = new UntypedFormControl('', [
		Validators.pattern('^((?!-)[-0-9a-z]{0,62}[0-9a-z](.(?!$)|$)){2,3}'),
	]);

	constructor(
		public projectService: ProjectService,
		private dialog: MatDialog,
		private apiService: ApiService
	) {}
	ngOnChanges() {
		if (this.environment.fields.transfer_status) {
			if (this.environment.fields.transfer_status?.success === false) {
				this.progressButtonState = '';
				this.state = 'failed';
				this.mode = 'migrate';
				this.polling = false;
				this.error = this.environment.fields.transfer_status.errors[0];
				clearTimeout(this.interval);
			} else {
				this.state = 'success';
				this.mode = 'migrate';
				this.startPolling();
			}
		}

	}

	ngOnDestroy() {
		clearTimeout(this.interval);
	}

	public startPolling() {
		if (this.polling) {
			return;
		}
		this.polling = true;
		this.interval = setTimeout(() => this.poll(), 5000);
	}

	private poll() {
		if ( this.polling ) {
			this.projectService.reloadProject().subscribe((project) => {
				this.interval = setTimeout(() => this.poll(), 5000);
			});
		}
	}

	setUsage(usage: string) {
		this.projectService
			.updateEnvironmentField({ environment_usage: usage })
			.subscribe();
	}

	public openWarningDialog(failures, warnings) {
		const ref = this.dialog.open(SiteSetupWarnDialogComponent, {
			width: '800px',
			data: {},
		});
		const instance = ref.componentInstance;
		instance.failures = failures;
		instance.warnings = warnings;
		instance.skipWarn.subscribe(result => {
			if (result === true) {
				this.skipWarn = true;
				this.submitTransfer();
			}
		});
	}

	public submitTransfer() {
		this.state = 'submitted';
		this.progressButtonState = 'submitted';
		this.apiService
			.post('/v1/environments/remote/transfer', {
				url: this.siteUrl,
				username: this.username,
				password: this.password,
				project_id: this.projectService.project.id,
				to_environment_id: this.projectService.environment.id,
				skip_warn: this.skipWarn,
			})
			.subscribe(
				(response) => {
					this.state = '';
					this.progressButtonState = '';
					if (response['status'] === 'success') {
						this.state = 'success';
						this.startPolling();
					} else {
						this.state = 'failed';
						this.progressButtonState = '';
						this.openWarningDialog(response['failures'], response['warnings']);
					}
				},
				(error: any[]) => {
					this.state = 'failed';
					this.error = error['errors'][0]['message'];
					this.progressButtonState = '';
				}
			);
	}

	formValid() {
		return !this.domainValidator.invalid && this.username && this.password;
	}
}
