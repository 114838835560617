import { Component, OnInit } from '@angular/core';
import { ConfigurationService, ApiService } from '@central/ng-shared';
import { ProjectService } from '../../../project.service';
import { AgentOverrideComponent } from './agent-override/agent-override.component';
@Component({
	selector: 'central-ansible',
	templateUrl: './ansible.component.html',
	styleUrls: ['./ansible.component.scss'],
})
export class AnsibleComponent implements OnInit {
	public state = 'loading';
	public overrideState: string;
	public overrideHistory: [];
	public overrideBy: any;

	constructor(
		public projectService: ProjectService,
		public configService: ConfigurationService,
		public apiService: ApiService
	) {}

	public ngOnInit() {
		this.checkState();
	}

	public getSemaphoreUrl(): string {
		return (
			this.configService.config.semaphoreUrl +
			'/project/' +
			this.projectService.environment.fields.semaphore_project_id +
			'/templates/' +
			this.projectService.environment.fields.semaphore_template_id
		);
	}

	public checkState() {
		this.apiService
			.get(
				'/v1/override/' + this.projectService.project.id + '/state',
				{}
			)
			.subscribe((response) => {
				this.apiService
					.get('/v1/override/' + this.projectService.project.id, {})
					.subscribe((history: any) => {
						this.overrideHistory = history;
						if (true === response['state']) {
							this.overrideState = 'Enabled';
							this.overrideBy =
								history[0]['Comment'] !== 'User Disabled'
									? history[0].UserAdmin
									: 'User';
						} else {
							this.overrideBy = '';
							this.overrideState = 'Disabled';
						}
						this.state = 'success';
					});
			});
	}
}
