<mat-card class="ctw-mt-10 ctw-max-w-xl ctw-mx-auto ctw-mb-16">
	<h3>Admin Settings</h3>
	<p>
		This section is only visible to administrators. It's available to agents
		who access accounts through zendesk.
	</p>
	<hr>
	<ng-container *ngIf="this.allowBetaTesting">
		<central-beta-tester></central-beta-tester>
		<hr class="ctw-mt-6">
	</ng-container>
	<central-suspension></central-suspension>
</mat-card>
