<dash-card cardTitle="Server Software" icon="book" class="dash-card dash-card-ansible" disabled="true">
	<ng-container subTitleHTML *ngIf="this.state === 'success' && !this.overrideState">
		<span class="sub-title ctw-text-base">
			<span>Current Version:
				<span class="{{
						this.currentVersion === this.latestVersion
							? 'ctw-text-green-700'
							: 'ctw-text-red-700'
					}}">{{ this.currentVersion }}</span></span>
		</span>
	</ng-container>
	<ng-container subTitleHTML *ngIf="this.state === 'success' && this.overrideState">
		<span class="sub-title editable ctw-text-base ctw-cursor-pointer">
			<span class="ctw-text-red-700" *ngIf="this.overrideBy === 'Agent'">Central Management Disabled</span>
			<span class="ctw-text-red-700" *ngIf="this.overrideBy !== 'Agent'">Central Management Disabled</span>
		</span>
	</ng-container>
	<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
	<div *ngIf="this.state !== 'loading'" class="dash-card-content ctw-p-8">
		<div *ngIf="
				this.state === 'success' &&
				(!this.overrideState || this.feature === 'files-0.22') &&
				(this.hasUpdates || this.needsUpdate)
			">
			<div class="ctw-text-left">
				<p class="title" *ngIf="this.needsUpdate">Update Required!</p>
				<p class="title" *ngIf="!this.needsUpdate">
					Updates Available!
				</p>
				<p>
					Your environment is running an older version of our software
					stack.<br />
				</p>

				<p>
					<strong>The latest version is:
						<span class="ctw-text-green-700">{{
							this.latestVersion
							}}</span></strong>
				</p>
				<p *ngIf="this.feature === 'files-0.22'" class="ctw-text-imh-gray-200">
					The latest version includes a web-based File and Database
					manager where you can modify your site's files, directories
					and permissions or perform common database operations like
					edit, optimize, repair and drop tables.
				</p>
			</div>
		</div>
		<div *ngIf="this.state === 'success'">
			<div *ngIf="
					!this.hasUpdates &&
					!this.needsUpdate &&
					this.latestVersion &&
					!this.overrideState
				">
				<p class="title">Your server's software is up to date!</p>
			</div>
		</div>
		<div class="update-info">
			<div [class]="!this.overrideState ? 'ctw-mb-5 ctw-pb-4' : ''">
				<div *ngIf="
						this.state === 'success' &&
						!this.overrideState &&
						this.feature !== 'files-0.22'
					">
					We utilize Ansible to manage your server's software and
					configurations automatically for you. If you've made any
					custom modifications to the server or plan to, you may want
					to disable management to prevent Central from reverting your
					changes.
					<ng-container *ngIf="
							!this.overrideState &&
							this.latestVersion !== this.currentVersion &&
							((this.hasUpdates && !this.needsUpdate) ||
								(!this.hasUpdates && !this.needsUpdate))
						">
					</ng-container>
				</div>
				<div *ngIf="
						this.state === 'success' &&
						this.overrideState &&
						this.feature === 'files-0.22'
					">
					Change your
					<strong>
						<a [routerLink]="[
								'/projects',
								this.projectService.project.id,
								'vz-manager'
							]">Server Software</a></strong>
					settings to enable this feature on your server.
				</div>
				<div *ngIf="
						this.state === 'success' &&
						this.overrideState &&
						this.feature !== 'files-0.22'
					">
					<span *ngIf="this.overrideBy === 'Agent'">You've asked us to disable</span>
					<span *ngIf="this.overrideBy !== 'Agent'">You've disabled</span>
					Central's ability to manage your server's software and
					configurations. You can continue to make server level
					modifications however via SSH by adding an SSH Key.
					<br />
					<br />
					<p *ngIf="this.overrideBy === 'Agent'" class="ctw-text-center">
						If you feel this was disabled in error, you can
						<a [routerLink]="[
								'/account',
								this.projectService.project.fields
									.organization_id,
								'help',
								'requests'
							]">open a Support Ticket</a>
					</p>
				</div>
			</div>
			<div class="ctw-text-right" *ngIf="!this.overrideState || this.overrideBy !== 'Agent'">
				<div class="ctw-inline-flex" *ngIf="this.state === 'success' && this.isRunningPlaybook">
					<mat-spinner diameter="25"></mat-spinner>
					<span class="ctw-ml-5">Playbook running...</span>
				</div>
				<ng-container *ngIf="
						this.state === 'success' &&
						!this.isRunningPlaybook &&
						this.latestVersion
					">
					<ng-container *ngIf="
						this.state === 'success' &&
						!this.isRunningPlaybook &&
						this.feature !== 'files-0.22'
					">
						<button mat-raised-button *ngIf="this.overrideState" [state]="state" color="primary"
							(click)="toggleOverride()" class="ctw-mr-4">Resume Management</button>
						<button mat-stroked-button *ngIf="!this.overrideState" [state]="state" color="warn"
							(click)="toggleOverride()" class="ctw-mr-4">Disable Management</button>
					</ng-container>

					<central-progress-button *ngIf="
							!this.hasUpdates &&
							!this.needsUpdate &&
							!this.overrideState &&
							0
						" [state]="state" mat-raised-button color="success" (click)="runPlaybook()">Force
						Update</central-progress-button>
					<central-progress-button *ngIf="
							(this.hasUpdates || this.needsUpdate) &&
							!this.overrideState
						" [state]="state" mat-raised-button color="primary" (click)="runPlaybook()">
						Update Now</central-progress-button>
					<p class="version-outdated" *ngIf="this.state === 'failed'">
						{{ this.error }}
					</p>
				</ng-container>

			</div>
		</div>
	</div>
</dash-card>
