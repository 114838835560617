import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApiService } from '../../core';

@Component({
	selector: 'central-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	public state = 'pending';
	public errorMessage: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private apiService: ApiService
	) {}

	public loginAsUser() {
		this.state = 'submitted';
		this.errorMessage = '';
		this.apiService
			.get(`/v1/agents/accounts/${this.data.user_id}/login-token`, {})
			.subscribe({
				next: (response: any) => {
					this.state = 'success';
					if (location.hostname.match(/(200.225.44.119|inmotionhosting)/)) {
						// Cross-environment impersonation
						location.href = location.href.split('agent')[0] + 'token' + response.token.split('/token')[1];
					} else {
						window.location = response.token;
					}
				},
				error: (error: any) => {
					this.errorMessage = this.apiService.getPublicError(error);
					this.state = 'error';
				},
			});
	}
}
