import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BrandingService } from '@central/ng-shared';

@Injectable()
export class MetaService {
	public baseDescription = '`';
	public configs = {};

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private meta: Meta,
		private brandingService: BrandingService,
		private titleService: Title
	) {
		this.baseDescription = this.getBaseDescription();
		this.configs = this.getConfigs();
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.navigationChange(event);
			}
		});
	}

	public getBaseDescription() {
		return `By using ${this.brandingService.getAppName()}, you can create Cloud WordPress installs, upgrade to
			a Premium Connect Key, purchase Connect Coins and more!`;
	}

	public getConfigs() {
		return {
			default: [
				{
					name: 'title',
					content: this.brandingService.getAppName(),
				},
				{
					name: 'og:title',
					content: this.brandingService.getAppName(),
				},
				{
					name: 'description',
					content:
						'Manage your WordPress websites. ' +
						this.baseDescription,
				},
			],
			'/forgot-password': [
				{
					name: 'title',
					content:
						'Reset Password | ' + this.brandingService.getAppName(),
				},
				{
					name: 'og:title',
					content:
						'Reset Password | ' + this.brandingService.getAppName(),
				},
				{
					name: 'description',
					content:
						'Reset your InMotion Central Password. ' +
						this.baseDescription,
				},
			],
			'/login': [
				{
					name: 'title',
					content: 'Login | ' + this.brandingService.getAppName(),
				},
				{
					name: 'og:title',
					content: 'Login | ' + this.brandingService.getAppName(),
				},
				{
					name: 'description',
					content:
						'Log in to your InMotion Central account. ' +
						this.baseDescription,
				},
			],
			'/checkout': [
				{
					name: 'title',
					content: 'Checkout | ' + this.brandingService.getAppName(),
				},
				{
					name: 'og:title',
					content: 'Checkout | ' + this.brandingService.getAppName(),
				},
				{
					name: 'robots',
					content: 'noindex, nofollow',
				},
			],
			'/welcome/central': [
				{
					name: 'title',
					content: 'Welcome | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/site': [
				{
					name: 'title',
					content:
						'Site Overview | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/preview': [
				{
					name: 'title',
					content: 'Preview | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/transfer': [
				{
					name: 'title',
					content: 'Copy To | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/snapshots': [
				{
					name: 'title',
					content: 'Snapshots | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/snaps': [
				{
					name: 'title',
					content: 'Snaps | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/plugins': [
				{
					name: 'title',
					content: 'Plugins | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/themes': [
				{
					name: 'title',
					content: 'Themes | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/addons': [
				{
					name: 'title',
					content: 'Addons | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/connect-key': [
				{
					name: 'title',
					content:
						'Connect Key | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/coins': [
				{
					name: 'title',
					content: 'Coins | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/w3': [
				{
					name: 'title',
					content:
						'Page Speed | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/seo': [
				{
					name: 'title',
					content: 'SEO | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/speedcoach': [
				{
					name: 'title',
					content:
						'Performance Test | ' +
						this.brandingService.getAppName(),
				},
			],
			'/projects/.*/backups': [
				{
					name: 'title',
					content: 'Backups | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/health': [
				{
					name: 'title',
					content:
						'Site Health | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/settings': [
				{
					name: 'title',
					content: 'Settings | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/files': [
				{
					name: 'title',
					content:
						'File Manager | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*/database': [
				{
					name: 'title',
					content:
						'Database Manager | ' +
						this.brandingService.getAppName(),
				},
			],
			'/projects/setup': [
				{
					name: 'title',
					content:
						'Project Setup | ' + this.brandingService.getAppName(),
				},
			],
			'/projects/.*': [
				{
					name: 'title',
					content:
						'Project Dashboard | ' +
						this.brandingService.getAppName(),
				},
			],
			'/projects': [
				{
					name: 'title',
					content:
						'My Projects | ' + this.brandingService.getAppName(),
				},
			],
			'/playgrounds': [
				{
					name: 'title',
					content:
						'Playground Subscriptions | ' +
						this.brandingService.getAppName(),
				},
			],
			'/domains/add': [
				{
					name: 'title',
					content:
						'Add Domain | ' + this.brandingService.getAppName(),
				},
			],
			'/domains/register': [
				{
					name: 'title',
					content:
						'Register Domain | ' +
						this.brandingService.getAppName(),
				},
			],
			'/domains/.*/dns-manager': [
				{
					name: 'title',
					content:
						'Manage DNS | ' + this.brandingService.getAppName(),
				},
			],
			'/domains/.*': [
				{
					name: 'title',
					content:
						'Manage Domain | ' + this.brandingService.getAppName(),
				},
			],
			'/domains': [
				{
					name: 'title',
					content: 'Domains | ' + this.brandingService.getAppName(),
				},
			],
			'/email/new': [
				{
					name: 'title',
					content:
						'New Email Subscription | ' +
						this.brandingService.getAppName(),
				},
			],
			'/email': [
				{
					name: 'title',
					content: 'Email | ' + this.brandingService.getAppName(),
				},
			],
			'/cloud-wordpress/snaps/.*': [
				{
					name: 'title',
					content: 'Snaps | ' + this.brandingService.getAppName(),
				},
			],
			'/cloud-wordpress/snaps': [
				{
					name: 'title',
					content: 'Snaps | ' + this.brandingService.getAppName(),
				},
			],
			'/account/update-password': [
				{
					name: 'title',
					content:
						'Update Password | ' +
						this.brandingService.getAppName(),
				},
			],
			'/account/notifications': [
				{
					name: 'title',
					content:
						'Notifications | ' + this.brandingService.getAppName(),
				},
			],
			'/account/.*/receipts/.*': [
				{
					name: 'title',
					content: 'Receipt | ' + this.brandingService.getAppName(),
				},
			],
			'/account/.*/receipts': [
				{
					name: 'title',
					content: 'Receipts | ' + this.brandingService.getAppName(),
				},
			],
			'/account/receipts': [
				{
					name: 'title',
					content: 'Receipts | ' + this.brandingService.getAppName(),
				},
			],
			'/account/.*/subscriptions': [
				{
					name: 'title',
					content:
						'Subscriptions | ' + this.brandingService.getAppName(),
				},
			],
			'/account/subscriptions': [
				{
					name: 'title',
					content:
						'Subscriptions | ' + this.brandingService.getAppName(),
				},
			],
			'/account/.*/billing': [
				{
					name: 'title',
					content: 'Billing | ' + this.brandingService.getAppName(),
				},
			],
			'/account/billing': [
				{
					name: 'title',
					content: 'Billing | ' + this.brandingService.getAppName(),
				},
			],
			'/account/.*/help/requests': [
				{
					name: 'title',
					content: 'Requests | ' + this.brandingService.getAppName(),
				},
			],
			'/account/help/requests': [
				{
					name: 'title',
					content: 'Requests | ' + this.brandingService.getAppName(),
				},
			],
			'/account/.*/settings': [
				{
					name: 'title',
					content:
						'Team Profile | ' + this.brandingService.getAppName(),
				},
			],
			'/account/.*/members': [
				{
					name: 'title',
					content:
						'Team Members | ' + this.brandingService.getAppName(),
				},
			],
			'/account': [
				{
					name: 'title',
					content:
						'My Account | ' + this.brandingService.getAppName(),
				},
			],
			'/teams/new': [
				{
					name: 'title',
					content:
						'Create Team | ' + this.brandingService.getAppName(),
				},
			],
			'/teams': [
				{
					name: 'title',
					content: 'Teams | ' + this.brandingService.getAppName(),
				},
			],
			'/help': [
				{
					name: 'title',
					content:
						'Documentation | ' + this.brandingService.getAppName(),
				},
			],
			'/notifications/.*': [
				{
					name: 'title',
					content:
						'Notifications | ' + this.brandingService.getAppName(),
				},
			],
		};
	}

	/**
	 * Each time the user navigates to a new page update the meta values.
	 *
	 * @since 1.4.3
	 *
	 * @param event Navigation Event.
	 */
	public navigationChange(event: NavigationEnd) {
		let url = event.urlAfterRedirects ? event.urlAfterRedirects : event.url;
		url = url && url.split ? url.split('?')[0] : '';
		const metaValues =
			this.findMatchingRoute(url) || this.configs['default'];

		for (const metaValue of metaValues) {
			this.meta.updateTag(metaValue);

			if ('title' === metaValue.name) {
				this.titleService.setTitle(metaValue.content);
			}
		}
	}

	/**
	 * Find a route from the config.
	 *
	 * @since 1.13.0
	 *
	 * @param  url URL of route
	 * @return     Route Name.
	 */
	private findMatchingRoute(url: string): any[] {
		let route;

		for (const routeName of Object.keys(this.configs)) {
			if (-1 !== url.search(routeName)) {
				route = this.configs[routeName];
				break;
			}
		}

		return route;
	}
}
