import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPageComponent } from './user-page/user-page.component';
import { OrganizationTableComponent } from './user-page/organizations-table/organizations-table.component';
import { UserInspectionComponent } from './user-page/user-inspection/user-inspection.component';
import { AgentSearchComponent } from './agent-search/agent-search.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { FormsModule } from '@angular/forms';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MomentModule } from 'ngx-moment';
import { CoreModule } from '../core/core.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { AccountModule } from '../account';
import { LoginComponent } from './login/login.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { AccountOptionsComponent } from './user-page/account-options/account-options.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
	declarations: [
		UserPageComponent,
		OrganizationTableComponent,
		AccountOptionsComponent,
		UserInspectionComponent,
		AgentSearchComponent,
		LoginComponent,
	],
	imports: [
		CommonModule,
		AccountModule,
		CoreModule,
		FormsModule,
		MatIconModule,
		RouterModule,
		MatInputModule,
		MomentModule,
		MatCheckboxModule,
		MatIconModule,
		MatButtonModule,
		MatPaginatorModule,
		MatMenuModule,
		MatSelectModule,
		MatFormFieldModule,
		MatTableModule,
		MatDialogModule,
	],
	exports: [
		UserPageComponent,
		OrganizationTableComponent,
		UserInspectionComponent,
		AgentSearchComponent,
	],
})
export class AgentModule {}
