import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
	ApiService,
	AuthService,
	ProfileService,
	ConfigurationService,
} from '@central/ng-shared';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
	selector: 'central-team-create',
	templateUrl: './create.component.html',
	styleUrls: ['./create.component.scss'],
})
export class TeamCreateComponent {
	public teamName = '';
	public contactEmail = '';
	public billingEmail = '';
	public state = 'pending';
	public orgWebsite = '';
	public websiteOptional = false;

	constructor(
		public authService: AuthService,
		public apiService: ApiService,
		public profileService: ProfileService,
		public snackbar: MatSnackBar,
		public router: Router,
		public configurationService: ConfigurationService
	) {
		this.websiteOptional =
			this.configurationService.config.teamForm?.websiteOptional;
	}

	public fixUrls() {
		if (this.orgWebsite && !/^https?:\/\//i.test(this.orgWebsite)) {
			this.orgWebsite = this.orgWebsite.replace(/^.*:\/\//, '');
			this.orgWebsite = 'http://' + this.orgWebsite;
		}
	}

	public submit() {
		this.state = 'submitted';
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl('/v1/organizations');
		const orgDetails = {
			name: this.teamName,
			primary_email: this.contactEmail,
			billing_email: this.billingEmail || '',
			website: this.orgWebsite,
			members: [],
		};

		this.apiService.http.post(url, orgDetails, { headers }).subscribe(
			() => {
				this.profileService.fetch();
				this.router.navigate(['/teams']);

				this.snackbar.open('Team Created', '', {
					duration: 3000,
				});
			},
			() => {
				this.state = 'failed';
			}
		);
	}
}
