<div class="usage-header">
	<h2>Project Jobs</h2>
</div>

<!-- Load State -->
<mat-progress-bar *ngIf="'submitted' === this.state" mode="indeterminate"></mat-progress-bar>

<!-- Success State -->
<div class="main-content">
	<div *ngIf="state === 'fail'" class="failed">
		<h3 class="error">Failed to load users.</h3>
		<button mat-raised-button (click)="this.fetch()">Reload</button>
	</div>

	<div class="jobs_table ctw-p-4">
        <table mat-table [dataSource]="job_data" matSort>
                <ng-container matColumnDef="UserAdminId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
                    <td mat-cell *matCellDef="let element">
						<a [routerLink]="['/agent/user', element.UserAdminId]">{{element.Email}}</a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td mat-cell *matCellDef="let element">
						{{ element.Name }}
                    </td>
                </ng-container>
				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
					<td mat-cell *matCellDef="let element">
						{{ element.status }}
					</td>
				</ng-container>
				<ng-container matColumnDef="StartedAt">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Started At</th>
					<td mat-cell *matCellDef="let element">
						{{ element.StartedAt }} UTC
					</td>
				</ng-container>
				<ng-container matColumnDef="Duration">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
					<td mat-cell *matCellDef="let element">
						{{ getDuration(element) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="CompletedAt">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Completed At</th>
					<td mat-cell *matCellDef="let element">
						{{ element.CompletedAt }} <span *ngIf="element.CompletedAt">UTC</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="UpdatedAt">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Updated At</th>
					<td mat-cell *matCellDef="let element">
						{{ element.UpdatedAt }} UTC
					</td>
				</ng-container>
				<ng-container matColumnDef="Action">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
					<td mat-cell *matCellDef="let element">
						<button *ngIf="element.status==='processing'||element.status==='pending'" mat-raised-button (click)="this.action(element, 'Stop')">Stop</button>
						<button *ngIf="element.status!=='processing'&&element.status!=='pending'" mat-raised-button (click)="this.action(element, 'Restart')">Restart</button>
					</td>
				</ng-container>
			<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
			<tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
		</table>
    </div>
</div>
