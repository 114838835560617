<div class="ctw-mb-10" *ngIf="0">
	<div class="ctw-font-bold">Your New Site URL</div>
	<code>
		<pre>{{ this.formatNewDomain() }}</pre>
	</code>
</div>
<div><strong>Do you want to use a subdomain?</strong></div>
<div class="ctw-mb-5">
	You can create subdomains to help organize and navigate to different
	sections of your main website.
</div>
<form (ngSubmit)="this.doSubmit()">
	<mat-form-field>
		<mat-label>Subdomain</mat-label>
		<input
			autocomplete="false"
			(input)="this.cleanSubdomain()"
			type="text"
			matInput
			name="subdomain"
			[(ngModel)]="this.subdomain"
		/>
		<span matSuffix>.{{ this.currentDomain }}</span>
	</mat-form-field>
	<div class="ctw-mt-5 ctw-text-right">
		<span class="ctw-mr-3"
			><button type="button" mat-button (click)="subdomainBack.emit()">
				Cancel
			</button></span
		>
		<central-progress-button color="primary" [state]="state">
			<span *ngIf="this.subdomain">Use subdomain</span>
			<span *ngIf="!this.subdomain">Continue Without Subdomain</span>
		</central-progress-button>
	</div>
</form>
