import { Component, Input } from '@angular/core';

import { ContactSet } from '../domain-types';

@Component({
	selector: 'central-contact-set-edit',
	templateUrl: './contact-set-edit.component.html',
	styleUrls: ['./contact-set-edit.component.scss'],
})
export class ContactSetEditComponent {
	@Input() editedContactSet: ContactSet;
	@Input() disableAll: boolean;

	constructor() {}
}
