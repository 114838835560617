<div
	[class]="
		this.configService.config.brandConfig.id === 'imh'
			? 'ctw-mx-auto ctw-max-w-6xl'
			: 'wrap'
	"
>
	<mat-progress-bar
		*ngIf="
			this.configService.config.brandConfig.id !== 'imh' &&
			this.status === 'pending'
		"
		mode="indeterminate"
		color="primary"
	></mat-progress-bar>
	<mat-card
		*ngIf="status === 'success' && notifications?.length === 0"
		class="no-messages center-align"
	>
		<div class="info">
			<mat-icon>notifications_none</mat-icon>
			<h2>No notifications Yet</h2>
			<p>Notifications that you receive will appear here.</p>
		</div>
	</mat-card>
	<mat-card
		class="ctw-flex"
		*ngIf="status === 'success' && notifications?.length"
	>
		<div class="list-options">
			<ul>
				<li
					*ngFor="let message of notifications; let i = index"
					mat-ripple
					[class.active]="i == this.activeIndex"
					[class.unread]="message?.has_read === false"
					[ngClass]="message.display_type"
					(click)="readNotification(i)"
				>
					<p class="title">{{ message.subject }}</p>
					<p>To: {{ getToName(message.account_id) }}</p>
					<p>
						Date:
						{{ message.date_sent | amDateFormat : 'LL HH:mm' }}
					</p>
				</li>
			</ul>
		</div>
		<div class="notification">
			<mat-progress-bar
				*ngIf="
					this.configService.config.brandConfig.id !== 'imh' &&
					showLoader
				"
				color="primary"
				mode="indeterminate"
			>
			</mat-progress-bar>
			<h4 class="notification-title">
				{{ notifications[activeIndex]?.subject }}
			</h4>
			<hr />
			<div class="notification-details">
				<span>{{
					notifications[activeIndex]?.date_sent
						| amDateFormat : 'LL HH:mm:ss'
				}}</span>
			</div>
			<div
				class="notification-body"
				[innerHTML]="notifications[activeIndex]?.body"
			></div>
		</div>
	</mat-card>
</div>
