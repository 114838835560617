import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '@central/ng-shared';
import { Config } from '../../shared/config/env.config';
@Component({
	selector: 'central-domain-change',
	templateUrl: './domain-change.component.html',
	styleUrls: ['./domain-change.component.css'],
})
export class DomainChangeComponent {
	public view = 'change-domain';
	public currentDomain: string;
	public isDisabled = true;
	public invalid = false;
	public validating = 'pending';
	public status = '';

	@Input() public state = 'pending';
	@Input() context: string;
	@Input() current: string;
	@Input() hostname: string;
	@Input() usedDomains: string[] = [];
	@Output() domainChangeReady = new EventEmitter();
	@Output() domainChangeBack = new EventEmitter();
	@Output() domainChangeSubmit = new EventEmitter();

	constructor(
		private httpClient: HttpClient,
		private apiService: ApiService
	) {}

	isReady() {
		this.domainChangeReady.emit();
		this.status = 'ready';
	}

	getIsDisabled(selectedDomain) {
		return !selectedDomain || selectedDomain === ' ' || this.isDisabled;
	}

	updateIsDisabled(data) {
		this.isDisabled = !!!data.trim();
	}

	useSelected() {
		if (this.currentDomain.includes(this.hostname)) {
			this.domainChangeSubmit.emit({
				baseDomain: this.hostname,
				subDomain: '',
				fqdn: this.hostname,
			});
			this.validating = 'pending';
		} else {
			this.view = 'sub-domain';
			this.validating = 'pending';
		}
	}

	validateSelected(selectedDomain) {
		this.validating = 'submitted';
		this.invalid = false;
		if (selectedDomain.trim().split(' ')[0].includes(this.hostname)) {
			this.currentDomain = selectedDomain.trim();
			this.useSelected();
		} else {
			const url =
				Config['host'] +
				'/v1/domain-service/domain/availability/' +
				selectedDomain.trim();
			const headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});
			this.httpClient.get(url, { headers }).subscribe(
				(response) => {
					this.currentDomain = selectedDomain.trim();
					if ('price' in response['attributes']) {
						this.view = 'register-domain';
					} else if (
						'status' in response['attributes'] &&
						response['attributes']['status'] === 'invalid'
					) {
						this.invalid = true;
						this.validating = 'pending';
					} else {
						this.useSelected();
					}
				},
				() => {
					this.invalid = true;
					this.validating = 'pending';
				}
			);
		}
	}
}
