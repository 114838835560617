import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../project.service';
import { Router } from '@angular/router';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EditDialogComponent } from '../../shared/edit-dialog/edit-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ApiService, AuthService, ConfigurationService, PollingService } from '@central/ng-shared';
import { LicenseService } from '../../shared/license/license.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AddMemberDialogComponent } from 'libs/ng-shared/src/lib/membership/dialogs/add-member-dialog/add-member-dialog.component';
import { ProjectDetailsDialogComponent } from './project-details-dialog/project-details-dialog.component';
import { ConnectKeyDialogComponent } from './connect-key-dialog/connect-key-dialog.component';
import { CatalogDialogComponent } from '../../checkout/catalog-dialog/catalog-dialog.component';
import { WpRestService } from '../site/rest.service';

import config from '../site/publish/plan-selector/config';
@Component({
	selector: 'project-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
	public status = 'loading';
	public currentUserRole = 'member';
	public environments = [];
	public stats = [];
	public displayedColumns = ['name', 'actions'];
	public showJobQueueButton = false;

	// public status = 'pending';
	public avatarSelected;
	public avatarOptions = [
		{
			value: 'novice',
			label: 'Beginner - Easy Website Builder',
		},
		{
			value: 'advanced',
			label: 'Advanced - Complete Website Manager',
		},
	];

	public vpsServers = [];
	public vpsStates: string[] = [];
	public playgrounds;
	public addPlaygroundState = 'pending';
	public addVpsState = 'pending';
	public addPlaygroundTitle = 'Playground';
	public ownerAccount;
	public playgroundLimit;
	public playgroundUsed;
	public playgroundAvailabilityRequestState;
	public requestState: string;
	public connectKey;
	public vpsStatus = 'loading';
	public playgroundStatus = 'loading';
	public isAnOrg = false;
	public openingChat = false;
	public dashboardLogo = '';
	public plans = config.plans;
	public subscription = '';
	public serversNotReady = [];
	public offer;

	constructor(
		public router: Router,
		private dialog: MatDialog,
		private apiService: ApiService,
		private authService: AuthService,
		public httpClient: HttpClient,
		public projectService: ProjectService,
		private licenseService: LicenseService,
		private snackBar: MatSnackBar,
		public configService: ConfigurationService,
		public pollingService: PollingService,
		public wpRestService: WpRestService,
	) {
		this.setSelectedAvatar(this.projectService.project.fields.mode);
		this.ownerAccount = {
			account_id: this.projectService.project.fields.organization_id,
		};
		this.isAnOrg =
		this.projectService.project.fields.organization_id !==
		this.authService.getAccountId();
	}

	public ngOnInit() {
		this.showJobQueueButton = this.authService.jwtHelper.getTokenVal('admin_login');
		this.licenseService.onReady().subscribe(() => {
			this.stats = this.licenseService.getAccountLicenses();
			this.playgrounds = this.projectService.project.children.filter(
				(project) =>
					project.fields.environment_type !== 'vps' &&
					project.fields.environment_type !== 'remote' &&
					project.fields.site_url.match(
						/(demo3\.cloudwp\.dev|demo-dev-new\.boldgrid\.com|demo-dev\.boldgrid\.com)/
					)
			);
			this.createPlaygroundTitle();
			this.checkPlaygroundAvailability();
			this.checkVpsStates();
			this.determineLogo();
			this.connectKey = this.licenseService
				.getLicenses()
				['connect_keys'].find(
					(key) =>
						+key.connect_id ===
						+this.projectService.project.fields.connect_id
				);

			this.pollingService
				.startPoll(
					() => this.projectService.reloadProject(false),
					() => this.serversNotReady.length > 0 || this.subscription === 'expired'
				)
				.subscribe(
					() => {
						this.checkVpsStates();
					},
					(error) => {
						this.projectService.rollbarService.rollbar.warn(error);
					}
				);
		});
	}

	public getOffer() {
		this.offer = this.projectService.couponService.couponData['server'];
		return this.offer;
	}

	private checkPlaygroundAvailability() {
		// get account playground limit
		this.apiService
			.get('/v1/licenses/playground-limits', {
				headerOptions: {
					xOrgId: this.ownerAccount.account_id,
				},
			})
			.subscribe(
				(licenses: any) => {
					const playground = licenses.filter(
						(license) => license.id === this.ownerAccount.account_id
					)[0];
					this.playgroundLimit = playground.limit;
					this.playgroundUsed = playground.used;
					this.playgroundAvailabilityRequestState = 'success';
					this.playgroundStatus = 'ready';
				},
				() => {
					this.playgroundAvailabilityRequestState = 'error';
				}
			);
	}

	private checkVpsStates() {
		this.vpsServers = this.projectService.project.children.filter(
			(project) =>
				project.fields.environment_type === 'vps' ||
				project.fields.machine_details
		);

		this.serversNotReady = this.projectService.project.children.filter(
			(project) => project.fields.environment_type === 'vps'
				&& project.fields.machine_details
				&& project.fields.has_publish_setup_user !== true
		);

		if (this.subscription !== 'expired' && this.subscription !== 'error') {
			this.checkSubscription();
		}
	}

	private setVpsStates() {
		this.vpsServers.forEach((server, index) => {
			if (server.fields?.machine_details?.veid) {
				this.apiService
					.get('/v1/vps/server/info', {
						veid: server.fields?.machine_details?.veid
					})
					.subscribe(
						(response) => {
							this.vpsStates[index] = 'ready';
							if (response['stoppedby'] && response['stoppedby'] === 'Systems') {
								this.vpsStates[index] = 'suspended';
							}
							if (!server.fields?.has_publish_setup_user) {
								this.vpsStates[index] = 'installing';
							}
						},
						(error) => {
							console.log(error);
						}
					);
			} else {
				this.vpsStates[index] = 'provisioning';
			}
		});
	}

	public changeEnvironment(environmentId: string) {
		this.projectService.setEnvironment(environmentId);
		this.router.navigate(
			[
				'/projects',
				this.projectService.project.id,
				this.configService.hasFeature('IMC-683') ? 'overview' : 'site'
			],
			{ queryParams: { environment_id: environmentId } }
		);
	}

	public openDialog(environmentId: string) {
		const environment = this.projectService.project.children.find(
			(val) => val.id === environmentId
		);

		const ref = this.dialog.open(DeleteDialogComponent, {
			width: '500px',
			data: {
				label: 'Environment',
				requireNameConfirmation: true,
				warning: environment.fields.cloud_id
					? 'Deleting this environment will also permanently delete the WordPress install and all its data.'
					: '',
				nameConfirmation: environment.label,
				apiCall: '/v1/environments/' + environmentId,
				apiData: {},
			},
		});

		ref.componentInstance.deleteSuccess.subscribe(() => {
			this.projectService.reloadProject(false).subscribe(() => {
				this.projectService.project.children =
					this.projectService.project.children.filter((val) => val.id !== environmentId);
				this.playgrounds = this.projectService.project.children.filter(
					(project) =>
						project.fields.environment_type !== 'vps' &&
						project.fields.environment_type !== 'remote' &&
						project.fields.site_url.match(
							/(demo3\.cloudwp\.dev|demo-dev-new\.boldgrid\.com|demo-dev\.boldgrid\.com)/
						)
				);
			});
		});
	}

	public openEditDialog(environment: any): void {
		const updateAction = (component) => {
			const url = this.apiService.formatter.getUrl(
				'/v1/environments/' + environment.id
			);
			const headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});

			this.httpClient
				.patch(url, { label: environment.label }, { headers })
				.subscribe(
					(response: any) => {
						this.projectService
							.reloadProject()
							.subscribe(() => {
								environment.label = response.label.trim();
								component.dialogRef.close();
								component.snackBar.open(
									'Rename Successful',
									'',
									{
										duration: 3000,
									}
								);
							});
					},
					() => (component.state = 'failed')
				);
		};

		const dialogRef = this.dialog.open(EditDialogComponent, {
			width: '500px',
			disableClose: true,
			data: {
				label: environment.fields.cloud_id ? 'Playground' : 'VPS Environment',
				name: 'Environment',
				resource: environment,
				updateAction,
			},
		});
	}

	public setSelectedAvatar(avatar) {
		this.avatarSelected = this.avatarOptions.find((option) => option.value === avatar);
	}

	public save() {
		this.status = 'submitted';

		const url = this.apiService.formatter.getUrl(
			'/v1/projects/' + this.projectService.project.id
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.httpClient
			.patch(
				url,
				{
					label: this.projectService.project.label,
					description: this.projectService.project.description,
					fields: {
						mode: this.avatarSelected.value,
					},
				},
				{ headers }
			)
			.subscribe(
				(projectUpdated) => {
					this.status = 'success';
					this.projectService.project.label = projectUpdated['label'];
					this.projectService.project.fields =
						projectUpdated['fields'];

					this.snackBar.open('Updated Successfully!', '', {
						duration: 3000,
						horizontalPosition: 'right',
					});
				},
				() => {
					this.status = 'failed';
				}
			);
	}

	openAddDialog(membership?: any) {
		const dialogRef = this.dialog.open(AddMemberDialogComponent, {
			width: '500px',
			data: {
				resourceType: 'organizations',
				resourceId: this.projectService.project.fields.organization_id,
				email: membership?.['invite_email'],
				project: true,
				ownerId: this.projectService.project.fields.organization_id,
				ownerName: this.getOwnerName(this.projectService.project),
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.snackBar.open(
						'Invitation e-mail sent successfully!',
						'',
						{
							duration: 4000,
						}
					);
				}
			},
		});
	}

	openProjectDetailsDialog() {
		const dialogRef = this.dialog.open(ProjectDetailsDialogComponent, {
			width: '500px',
			data: {
				resourceType: 'organizations',
				resourceId: this.projectService.project.fields.organization_id,
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.snackBar.open(
						'Project details successfully updated!',
						'',
						{
							duration: 4000,
						}
					);
				}
			},
		});
	}

	public getScreenshotId(project) {
		const defaultEnvironment =
			this.projectService.getDefaultEnvironment(project);

		if (!defaultEnvironment) {
			return;
		}

		let screenshotId = defaultEnvironment.fields.screenshot_path;
		if (!screenshotId) {
			const envrionmentWithScreenshot = project.children.find(
				(environment) => !!environment.fields.screenshot_path
			);

			if (envrionmentWithScreenshot) {
				screenshotId = envrionmentWithScreenshot.fields.screenshot_path;
			}
		}

		return screenshotId;
	}

	public addPlayground() {
		if (this.playgroundUsed >= this.playgroundLimit) {
			this.checkSubscription();

			this.dialog.open(CatalogDialogComponent, {
				width: '500px',
				data: {
					title: 'Choose a Plan',
					tags: ['cloud-wordpress'],
					notice: true,
					team: this.projectService.project.fields.organization_id,
					limit: this.playgroundLimit,
                    hasVps: this.requestState === 'error',
                    projectId: this.projectService.project.id,
				},
			});

			return;
		}

		this.addPlaygroundState = 'submitted';
		this.projectService
			.addEnvironment({
				label: this.addPlaygroundTitle,
				project: this.projectService.project,
			})
			.subscribe({
				complete: () => {
					this.addPlaygroundState = 'pending';
					this.projectService.reloadProject().subscribe((projects) => {
						this.playgrounds = projects.children.filter(
							(project) =>
								project.fields.environment_type !== 'vps' &&
								project.fields.environment_type !== 'remote' &&
								project.fields.site_url.match(
									/(demo3\.cloudwp\.dev|demo-dev-new\.boldgrid\.com|demo-dev\.boldgrid\.com)/
								)
						);
						// Update number of available playgrounds when done.
						this.checkPlaygroundAvailability();
						// Set next playground title.
						this.createPlaygroundTitle();
					});
				},
				error: (error) => {
					console.log(error);
				},
			});
	}

	/**
	 * Checks existing titles, and prepares the new title for playgrounds.
	 */
	public createPlaygroundTitle() {
		let title = this.addPlaygroundTitle;
		const result = this.playgrounds
			.map(({ label }) => label)
			.filter((label) => label.includes(title));

		// Loop through all titles for matches, and append N + 1
		for (let i = 0; i < result.length; i++) {
			if (result.includes(title)) {
				// regexr: https://regexr.com/77qo7  - match My Project 1, My Project 38748, ect.
				title =
					title.replace(/(\s\d+)(?!.*\s\d)/, '') +
					' ' +
					Math.abs(i + 1);
				this.addPlaygroundTitle = title;
			}
		}
	}

	/**
	 * Open delete dialog
	 * @param proj a Project with ID and label to delete.
	 */
	public openDeleteProjectDialog() {
		// Check if project has active subscriptions.
		this.checkSubscription();

		// Open dialog.
		const ref = this.dialog.open(DeleteDialogComponent, {
			width: '500px',
			data: {
				label: 'Project',
				error:
					this.requestState === 'error'
						? 'This project cannot be deleted because it has active subscriptions.'
						: '',
				requireNameConfirmation: true,
				warning:
					'Deleting this project will also delete any related environments.',
				nameConfirmation: this.projectService.project.label,
				apiCall: '/v1/projects/' + this.projectService.project.id,
				apiData: {},
			},
		});

		ref.componentInstance.deleteSuccess.subscribe(() => {
			this.router.navigateByUrl('/');
		});
	}

	public openConnectKeyDialog() {
		this.apiService
			.get(
				'/v1/connect-keys/' +
					this.projectService.project.fields.connect_id,
				{}
			)
			.subscribe((response: any) => {
				const ref = this.dialog.open(ConnectKeyDialogComponent, {
					width: '500px',
					data: {},
				});
				const instance = ref.componentInstance;
				instance.connectId =
					this.projectService.project.fields.connect_id;
				instance.connectKey = response.key;
			});
	}

	public checkSubscription() {
		this.projectService.getSubscription.subscribe({
			next: (subscription) => {
				this.subscription = subscription['state'];

				const orphanedServers = [];

				if (this.subscription === 'expired') {
					this.projectService.project.children.filter(
						(project) =>
							project.fields.environment_type === 'vps' ||
							project.fields.machine_details
					).forEach((server, index) => {
						orphanedServers.push(server);
						this.vpsStates[index] = this.subscription;
					});
				}

				if (orphanedServers.length) {
					this.projectService.rollbarService.rollbar
						.error('This project has an active vps yet an expired subscription');
				} else {
					this.setVpsStates();
				}

				this.vpsStatus = 'ready';
				this.requestState = 'error';
			},
			error: (error) => {
				if (error.error.message === 'No subscription found.') {
					// Check if project has complimentary VPS
					if (this.projectService.project.fields?.coupon_code?.includes('partner')) {
						this.requestState = 'success';
						this.subscription = 'comp';
						this.setVpsStates();
					} else {
						this.requestState = 'success';
						this.subscription = 'error';
						this.projectService.rollbarService.rollbar
							.critical('This project has an active vps yet a subscription could not be identified');
						this.setVpsStates();
					}
				} else {
					this.requestState = 'failed';
					this.subscription = 'error';
					this.projectService.rollbarService.rollbar
						.critical('An error occured retrieving subscription information');
				}
				this.vpsStatus = 'ready';
			},
		});
	}

	public getOwnerName(project) {
		const access = this.authService.profile.data.account_access.find(
			(accessed) => accessed.account_id === project.fields.organization_id
		);

		let name = this.authService.profile.data.display_name || 'You';
		if (access) {
			name = access.display_name;
		}

		return name;
	}

	openChat() {
		this.openingChat = true;
		if (window['zE']) {
			if (window['zE'].widget === 'classic') {
				window['zE']('webWidget', 'show');
				window['zE']('webWidget', 'open');
			}
			if (window['zE'].widget === 'messenger') {
				window['zE']('messenger', 'show');
				window['zE']('messenger', 'open');
			}
			setTimeout(() => (this.openingChat = false), 2000);
		}
	}

	determineLogo() {
		if (this.projectService.project.fields.project_type === 'wordpress') {
			this.dashboardLogo = 'wordpress';
		}
	}

	openAdmin(environmentId: string) {
		let login = true;
		this.projectService.setEnvironment(environmentId);

		if (this.projectService.project.fields.project_type === 'wordpress') {
			const wpAdmin = this.projectService.connected;
			wpAdmin.subscribe((msg: any) => {
				if (msg === 'ready') {
					if (environmentId === this.wpRestService.getSite(this.projectService.environment)?.environment?.id) {
						if (login) {
							this.projectService.login('wp-admin/');
							login = false;
						}
					}
				}
			})
		}
	}
}
