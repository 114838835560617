<div class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray">
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<h2>Teams</h2>
		<div>
			<button mat-raised-button color="primary" (click)="this.openCreateDialog()" *ngIf="0 !== teams.length"
				class="animate__animated animate__fadeIn">
				<mat-icon>add</mat-icon> New Team
			</button>
		</div>
	</div>

	<mat-progress-bar *ngIf="'success' !== status" mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<failed-request *ngIf="'failed' === status"></failed-request>

<ng-container *ngIf="status === 'success'">
	<div class="content animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl">
		<ng-container *ngIf="teams.length === 0">
			<div class="no-teams ctw-max-w-lg ctw-mx-auto ctw-text-center">
				<img
					src="https://inmotionhosting.github.io/static-assets/illustrations/small/enterprise-large-business.svg" />
				<h3>No Teams Found</h3>
				<p>
					Teams allow you to invite your team and share access to
					sites. Create a team or have an administrator of an existing
					team send an invite to this account.
				</p>
				<button mat-raised-button color="primary" [routerLink]="['/teams/new']">
					<mat-icon>add</mat-icon> New Team
				</button>
			</div>
		</ng-container>

		<dash-card *ngIf="teams.length > 0" cardTitle="Team Management" icon="groups" disabled="true"
			[subTitle]="['Collaboration, Billing and Subscriptions']">
			<table mat-table [dataSource]="teams" id="team-index">
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Name</th>
					<td mat-cell *matCellDef="let team" class="with-subtitle">
						<p *ngIf="team.account_id">{{ team.name }}</p>
						<p>
							<a *ngIf="team.organization_id" [routerLink]="[
									'/account',
									team.organization_id,
									'settings'
								]">{{ team.name }}</a>
						</p>
						<p *ngIf="team.organization_id">
							{{
							team.members.length +
							(team.members.length === 1
							? ' member'
							: ' members')
							}}<ng-container *ngIf="team.cloud_count">,
								{{
								team.cloud_count +
								(team.cloud_count === 1
								? ' cloud'
								: '
								clouds')
								}}</ng-container>
						</p>
					</td>
				</ng-container>

				<ng-container matColumnDef="billing">
					<th mat-header-cell *matHeaderCellDef>Billing</th>
					<td mat-cell *matCellDef="let team">
						<p *ngIf="'owner' !== team.role">
							{{ team.has_billing ? 'Active' : 'Inactive' }}
						</p>
						<p *ngIf="'owner' === team.role">
							<a [routerLink]="[
									'/account',
									team.organization_id,
									'billing'
								]">{{
								team.has_billing ? 'Active' : 'Inactive'
								}}</a>
						</p>
					</td>
				</ng-container>

				<ng-container matColumnDef="your-role">
					<th mat-header-cell *matHeaderCellDef>Your Role</th>
					<td mat-cell *matCellDef="let team">
						{{ getRoleLabel(team.role) | titlecase }}
					</td>
				</ng-container>

				<ng-container matColumnDef="action">
					<th mat-header-cell class="align-right" *matHeaderCellDef>
						Actions
					</th>
					<td mat-cell class="align-right" *matCellDef="let team">
						<ng-container *ngIf="true">
							<button mat-stroked-button [matMenuTriggerFor]="teamOptions">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #teamOptions="matMenu" xPosition="before">
								<button *ngIf="team.organization_id" mat-menu-item [routerLink]="[
										'/account',
										team.organization_id,
										'settings'
									]">
									<mat-icon>info</mat-icon>Profile
								</button>
								<button *ngIf="team.organization_id" mat-menu-item [routerLink]="[
										'/account',
										team.organization_id,
										'members'
									]">
									<mat-icon>people</mat-icon>Members
								</button>
								<mat-divider></mat-divider>
								<button mat-menu-item (click)="openLeaveDialog(team)">
									<mat-icon>door_front</mat-icon>Leave Team...
								</button>
								<!-- <button mat-menu-item (click)="openDeleteDialog( team )" *ngIf="team.role === 'owner'"><mat-icon>delete_forever</mat-icon>Delete Team...</button> -->
							</mat-menu>
						</ng-container>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="[
						'name',
						'billing',
						'your-role',
						'action'
					]"></tr>
				<tr mat-row *matRowDef="
						let row;
						columns: ['name', 'billing', 'your-role', 'action']
					"></tr>
			</table>
		</dash-card>
	</div>
</ng-container>
