<dash-card
	icon="history"
	cardTitle="Server Snapshots"
	class="dash-card snapshot"
	disabled="true"
>
	<mat-progress-bar
		*ngIf="this.status === 'loading'"
		mode="indeterminate"
		color="primary"
	></mat-progress-bar>

	<failed-request *ngIf="status === 'failed'"></failed-request>

	<div *ngIf="this.status !== 'loading'" class="dash-card-content ctw-p-8">
		<p>
			You can create a Snapshot of your server that preserves the state of
			your server files, databases, and configuration from a specific
			moment in time. If needed, you will be able to revert your server
			back to a specific point in time.
		</p>
		<div *ngIf="snapshotBusy" class="mat-spinner-wrapped">
			<mat-spinner
				class="component-loader"
				diameter="50"
				color="primary"
			></mat-spinner>
			<div class="ctw-text-center ctw-text-xl">
				{{ this.action }} Snapshot<br /><br />
				<strong
					>This can take a couple of minutes...<br />
					Feel free to leave this page and return later</strong
				>.<br />
			</div>
		</div>
		<table
			*ngIf="dataSource?.length"
			mat-table
			[dataSource]="dataSource"
			class="ctw-mt-8 ctw-mb-12 mat-elevation-z1"
		>
			<ng-container matColumnDef="description">
				<th mat-header-cell *matHeaderCellDef>Snapshot</th>
				<td mat-cell *matCellDef="let snapshot">
					{{ snapshot.description }}
				</td>
			</ng-container>
			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef>Date Created</th>
				<td mat-cell *matCellDef="let snapshot">
					{{ snapshot.date }}
					<span class="snapshot-controls">
						<mat-icon
							(click)="useSnapshot($event)"
							matTooltip="Use Snapshot"
							matTooltipPosition="above"
							aria-label="Use snapshot"
							>play_circle_filled</mat-icon
						>
						<mat-icon
							(click)="deleteSnapshot($event)"
							matTooltip="Delete Snapshot"
							matTooltipPosition="above"
							aria-label="Delete snapshot"
							>delete_forever</mat-icon
						>
					</span>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr
				mat-row
				*matRowDef="let snapshot; columns: displayedColumns"
				[attr.data-snapshot]="snapshot.id"
			></tr>
		</table>
		<div class="ctw-text-right">
			<button
				(click)="openSnapshotDialog()"
				mat-raised-button
				color="primary"
			>
				Take a Snapshot
			</button>
		</div>
	</div>
</dash-card>
