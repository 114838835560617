import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../project.service';

@Component({
	selector: 'app-file-manager',
	templateUrl: './file-manager.component.html',
})
export class FileManagerComponent implements OnInit {
	public environment;

	constructor(public projectService: ProjectService) {}

	public ngOnInit() {
		const environmentFields = this.projectService.environment.fields;

		if (environmentFields.hasOwnProperty('cloud_id')) {
			this.environment = 'Playground Environment';
		} else if (environmentFields.hasOwnProperty('machine_details')) {
			this.environment = 'UltraStack VPS';
		}
	}
}
