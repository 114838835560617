<mat-progress-bar
	*ngIf="status === 'loading'"
	mode="indeterminate"
	color="primary"
></mat-progress-bar>
<layout-base pageTitle="Subscriptions" container="container-lg">
	<failed-request *ngIf="'failed' === this.status"></failed-request>
	<div
		class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-4xl ctw-pb-8 ctw-mt-8 ctw-mb-8"
		*ngIf="'success' === this.status"
	>
		<ng-container *ngIf="!subscriptions.length">
			<div class="no-subscriptions">
				<mat-icon>paid</mat-icon>
				<h2>No Subscriptions Found</h2>
				<p>
					Looks like you don't have any subscriptions yet. Come back
					after you make your first purchase.
				</p>
				<a mat-button mat-raised-button color="primary" routerLink="/"
					>Home</a
				>
			</div>
		</ng-container>

		<div class="ctw-text-right" *ngIf="subscriptions.length">
			<button
				mat-button
				mat-raised-button
				color="primary"
				routerLink="/account/billing"
				*ngIf="
					this.authService.profile.data.type === 'org' &&
					this.authService.profile.data.account_access[0]
						.account_id === this.accountService.id
				"
			>
				Update Payment Method
			</button>
			<button
				mat-button
				mat-raised-button
				color="primary"
				routerLink="/account/{{ this.accountService.id }}/billing"
				*ngIf="
					this.authService.profile.data.type === 'org' &&
					this.authService.profile.data.account_access[0]
						.account_id !== this.accountService.id
				"
			>
				Update Payment Method
			</button>
		</div>
		<ng-container *ngFor="let subscription of subscriptions">
			<mat-card
				[attr.uuid]="subscription.uuid"
				class="{{ subscription.state }}"
			>
				<h3>
					{{ subscription.plan_name
					}}<span *ngIf="subscription.custom_fields.domain_name">
						&ndash;
						{{ subscription.custom_fields.domain_name }}</span
					><span class="parent-price"
						>{{
							currencyJs(subscription.total_amount_in_cents, {
								fromCents: true
							}).format()
						}}
						/ {{ subscription.plan_interval_length }}
						{{ subscription.plan_interval_unit_display }}</span
					>
				</h3>
				<p *ngIf="subscription.state !== 'canceled'">
					Renews
					{{
						this.getRenewalDate(subscription) | amDateFormat : 'LL'
					}}
					&bull; auto renewal enabled
					<a
						(click)="
							openDialog({
								action: 'cancel-auto-renewal',
								subscription: subscription
							})
						"
						class="ctw-ml-2"
						>[disable]</a
					>
				</p>
				<p *ngIf="subscription.state === 'canceled'">
					<mat-icon class="ctw-align-bottom ctw-text-imh-gray-200"
						>warning</mat-icon
					>
					Expires
					{{
						this.getRenewalDate(subscription) | amDateFormat : 'LL'
					}}
					&bull; auto renewal disabled
					<a
						(click)="
							openDialog({
								action: 'enable-auto-renew',
								subscription: subscription
							})
						"
						class="ctw-ml-2"
						>[enable]</a
					>
				</p>
				<div class="past-due" *ngIf="'past_due' === subscription.state">
					Your subscription is <strong>Past Due.</strong> Please
					update your billing information to keep your services
					active.
					<strong
						><a routerLink="/account/billing">Update now</a></strong
					>
				</div>
				<ng-container *ngIf="subscription.subscription_add_ons.length">
					<div class="add-on-container">
						<hr />
						<ng-container
							*ngIf="
								subscription.custom_fields.project_id &&
								getProjectLabelById(
									subscription.custom_fields.project_id
								).status === 'active'
							"
						>
							<h3>
								<a
									[routerLink]="[
										'/projects/',
										subscription.custom_fields.project_id
									]"
								>
									{{
										getProjectLabelById(
											subscription.custom_fields
												.project_id
										).label
									}}
								</a>
							</h3>
						</ng-container>
						<ng-container
							*ngIf="
								subscription.custom_fields.project_id &&
								getProjectLabelById(
									subscription.custom_fields.project_id
								).status === 'inactive'
							"
						>
							<h3 class="ctw-text-imh-gray-200">
								{{
									getProjectLabelById(
										subscription.custom_fields.project_id
									).label
								}}
								<span class="ctw-text-red-700 ctw-text-sm"
									>(Project Deleted)</span
								>
							</h3>
						</ng-container>
						<ng-container
							*ngFor="
								let addon of subscription.subscription_add_ons
							"
						>
							<p>
								<strong>{{ addon.name }}</strong> &bull;
								{{
									currencyJs(addon.unit_amount_in_cents, {
										fromCents: true
									}).format()
								}}
								<a
									*ngIf="
										'active' === addon.state &&
										addon.add_on_code !== 'wpc_basic' &&
										addon.add_on_code !==
											'wpc_premium_incl' &&
										'canceled' !== subscription.state
									"
									(click)="
										openDialog({
											action: 'remove-addon',
											subscription: subscription,
											addon: addon
										})
									"
									class="ctw-ml-2"
									>[remove]</a
								><ng-container
									*ngIf="
										'canceled' === addon.state ||
										'canceled' === subscription.state
									"
									>(removal pending)</ng-container
								>
							</p>
						</ng-container>
					</div>
				</ng-container>
			</mat-card>
		</ng-container>
	</div>
</layout-base>
