export class AutoUpdate {
	public autoUpdatePlugins = [
		'boldgrid-backup/boldgrid-backup.php',
		'boldgrid-backup-premium/boldgrid-backup-premium.php',
		'boldgrid-inspirations/boldgrid-inspirations.php',
		'post-and-page-builder/post-and-page-builder.php',
		'post-and-page-builder-premium/post-and-page-builder-premium.php',
		'boldgrid-connect/boldgrid-connect.php',
		'w3-total-cache/w3-total-cache.php',
		'weforms/weforms.php',
		'boldgrid-seo/boldgrid-easy-seo.php',
	];

	public hasAutoUpdates(settings: any) {
		let hasAutoUpdates = false;

		settings.autoupdate.plugins = settings.autoupdate.plugins || {};

		for (const plugin of this.autoUpdatePlugins) {
			if (settings.autoupdate.plugins[plugin]) {
				hasAutoUpdates = true;
			}
		}

		return hasAutoUpdates;
	}

	public enable(settings) {
		const autoupdate =
			settings.autoupdate && !Array.isArray(settings.autoupdate)
				? settings.autoupdate
				: {};
		autoupdate.plugins = autoupdate.plugins || {};

		for (const plugin of this.autoUpdatePlugins) {
			autoupdate.plugins[plugin] = true;
		}

		return autoupdate;
	}
}
