<div class="ctw-max-w-6xl ctw-mx-auto">
	<mat-spinner diameter="50" class="ctw-mx-auto ctw-mt-10" color="primary"
		*ngIf="state === 'loading' && 0"></mat-spinner>
	<ng-container *ngIf="state === 'success'">
		<plan-selector [altDisplay]="true" *ngIf="!this.hasServer || this.isUpdatePlan" class="plans" #planSelector
			[isUpdatePlan]="this.isUpdatePlan" [currentPlan]="this.currentPlan" [couponData]="this.couponData"
			[subscriptionUuid]="this.subscriptionUuid" [termType]="this.termType"
			[server]="this.hasServer && this.isUpdatePlan ? this.veid : ''" (planSelectorReady)="this.isReady()"
			(enableNext)="this.enableNext.emit($event)"></plan-selector>
		<mat-card *ngIf="this.hasServer && !this.isUpdatePlan">
			<div class="ctw-mb-5">
				<h3><span class="ctw-font-bold">Great!</span></h3>
			</div>
			You've already purchased a plan. We'll use the Production VPS you
			chose previously to host your site.
		</mat-card>
	</ng-container>
</div>
