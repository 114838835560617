<div class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray">
	<div class="ctw-flex ctw-mx-auto ctw-max-w-6xl ctw-justify-between ctw-items-center">
		<div class="project-heading ctw-cursor-pointer ctw-flex ctw-items-end"
			(click)="this.openProjectDetailsDialog()">
			<div>
				<h2>
					<img src="https://design.inmotionhosting.com/assets/icons/custom/platform-i-{{ this.projectService.project.fields.project_type
					}}.svg" class="ctw-float-left ctw-inline-block ctw-mr-4 ctw-w-10"> {{ this.projectService.project.label }}
				</h2>
			</div>
			<div class="ctw-relative edit-project-details">
				<a>[edit]</a>
			</div>
		</div>
		<div [ngClass]="
				this.vpsServers?.length ? 'ctw-grid-cols-3' : 'ctw-grid-cols-5'
			" class="actions ctw-grid-flow-row ctw-gap-4 ctw-pb-3.5 animate__animated animate__fadeIn" *ngIf="
				this.vpsStatus === 'ready' && this.playgroundStatus === 'ready'
			">
			<ng-container *ngIf="
					this.projectService.hasCapability('manage');
					then canManage;
					else cantManage
				"></ng-container>
			<ng-template #canManage>
				<button *ngIf="this.isAnOrg" color="accent" class="ctw-col-span-2 ctw-mr-4 invite" mat-stroked-button
					(click)="this.openAddDialog()">
					<mat-icon class="ctw-pr-8">groups</mat-icon>
					Invite Team Members
				</button>
			</ng-template>
			<ng-template #cantManage>
				<div class="ctw-col-span-2"></div>
			</ng-template>
			<ng-container *ngIf="!this.vpsServers?.length && this.isAnOrg">
				<button class="ctw-col-span-2 ctw-mr-4" mat-raised-button color="primary" [routerLink]="[
						'/projects',
						this.projectService.project.id,
						'add',
						'hosting'
					]"><mat-icon>add</mat-icon>
					Add Cloud Server
				</button>
			</ng-container>
			<button mat-stroked-button [matMenuTriggerFor]="projectOptions">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #projectOptions="matMenu" xPosition="before">
				<button mat-menu-item (click)="openConnectKeyDialog()">
					<mat-icon>vpn_key</mat-icon>Connect Key
				</button>
				<button mat-menu-item (click)="openDeleteProjectDialog()">
					<mat-icon>delete_forever</mat-icon>Delete Project
				</button>
			</mat-menu>
		</div>
	</div>

	<mat-progress-bar *ngIf="this.vpsStatus !== 'ready' || this.playgroundStatus !== 'ready'" mode="indeterminate"
		color="primary"></mat-progress-bar>
</div>

<div class="wrap animate__animated animate__fadeIn ctw-mb-12"
	*ngIf="this.vpsStatus === 'ready' && this.playgroundStatus === 'ready'">
	<div class="content ctw-mx-auto ctw-max-w-6xl ctw-px-5 ctw-grid">
		<div *ngIf="!this.vpsServers?.length && this.isAnOrg && this.getOffer()"
			class="ctw-flex mat-stroked-button ctw-px-4 ctw-py-2 ctw-mt-8 ctw-justify-center ctw-whitespace-pre-wrap"
			[routerLink]="['/projects', this.projectService.project.id, 'add', 'hosting']">
			<mat-icon *ngIf="this.offer.invoice_description !== 'gift'">{{ this.offer.invoice_description }}</mat-icon>
			<svg *ngIf="this.offer.invoice_description === 'gift'" xmlns="http://www.w3.org/2000/svg" width="24"
				height="24" viewBox="0 2 24 24" class="ctw-text-green-700">
				<path fill="currentColor"
					d="M9.06 1.93C7.17 1.92 5.33 3.74 6.17 6H3a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h9V8h2v3h9a1 1 0 0 0 1-1V8a2 2 0 0 0-2-2h-3.17C19 2.73 14.6.42 12.57 3.24L12 4l-.57-.78c-.63-.89-1.5-1.28-2.37-1.29M9 4c.89 0 1.34 1.08.71 1.71C9.08 6.34 8 5.89 8 5a1 1 0 0 1 1-1m6 0c.89 0 1.34 1.08.71 1.71c-.63.63-1.71.18-1.71-.71a1 1 0 0 1 1-1M2 12v8a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-8h-9v8h-2v-8H2Z" />
			</svg>
			<div [innerHTML]="this.offer.description" class="ctw-pl-2"></div>
		</div>
		<div *ngFor="let server of this.vpsServers; let i = index">
			<dash-card showPopup="true" icon="cloud"
				cardTitle="{{ this.plans[server.fields?.package_code?.replace('wphosting_tier_', '')?.replace('a', '')]?.name }}"
				disabled="true" class="ctw-mt-8 ctw-w-full" hostname="{{ server.fields?.site_url }}"
				ip="{{ server.fields?.machine_details?.machine_ip }}">
				<ng-container subTitleHTML>
					<span *ngIf="this.vpsStates[i] === 'suspended' || this.vpsStates[i] === 'expired'"
						class="sub-title">
						<button mat-raised-button class="suspended" color="warn" (click)="this.openChat()">
							{{ this.vpsStates[i].toUpperCase() }}
						</button>
					</span>
					<span class="sub-title editable ctw-text-base ctw-cursor-pointer"
						(click)="this.openEditDialog(server)">
						<span>{{ server.label
							}}<a class="ctw-mx-4">[edit]</a></span>
					</span>
				</ng-container>
				<div class="ctw-flex ctw-flex-wrap-reverse ctw-justify-center ctw-p-4">
					<dash-card icon="" cardTitle="" iframe="{{ server.fields?.site_url }}" class="ctw-m-0 ctw-w-1/2"
						disabled="true">
						<div class="site preview">
							<iframe [centralCachedSrc]="server.fields?.site_url"
								*ngIf="server.fields?.has_publish_setup_user" loading="lazy" sandbox></iframe>
							<div class="preview-placeholder" *ngIf="!server.fields?.site_url">
								<div class="message">
									<mat-icon>photo_size_select_actual</mat-icon>
									<div *ngIf="server.fields?.site_url" class="spinner-container">
										<mat-progress-spinner mode="indeterminate" color="primary"
											diameter="30"></mat-progress-spinner>
									</div>
									<div>
										Preview Unavailable
									</div>
								</div>
								<img width="450"
									src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9'%3E%3C/svg%3E" />
							</div>
						</div>
					</dash-card>
					<!-- Begin Environment Stats -->
					<div *ngIf="server.fields?.has_publish_setup_user" class="ctw-flex ctw-bg-gray-200 ctw-m-auto">
						<div>
							<div class="ctw-flex ctw-flex-col">

							</div>
						</div>
					</div>
					<!--// End Environment Stats -->
					<div class="ctw-ml-auto ctw-flex ctw-flex-col ctw-gap-2">
						<button
							*ngIf="this.vpsStates[i] === 'suspended' || this.vpsStates[i] === 'expired' || this.vpsStates[i] === 'error'"
							mat-stroked-button color="warn" (click)="this.openChat()">
							<mat-icon class="manage">warning</mat-icon>
							Contact Us
						</button>
						<button mat-raised-button color="primary"
							*ngIf="server.fields?.has_publish_setup_user && this.vpsStates[i] === 'ready' && server.fields?.environment_usage !== 'unknown' && dashboardLogo !== ''"
							(click)="openAdmin(server.id)" class="animate__animated animate__fadeIn"
							[disabled]="!this.wpRestService.getSite(server)">
							<mat-icon class="ctw-mr-2">{{ dashboardLogo }}</mat-icon>
							<span matLine>Admin</span>
						</button>
						<button *ngIf="server.fields?.has_publish_setup_user && this.vpsStates[i] === 'ready'"
							mat-raised-button [color]="
								server.fields?.environment_usage === 'unknown'
									? 'primary'
									: 'secondary'
							" [routerLink]="[
								'/projects',
								this.projectService.project.id,
								this.configService.hasFeature('IMC-683') ? 'overview' : 'site'
							]" [queryParams]="{ environment_id: server.id }" class="animate__animated animate__fadeIn">
							<mat-icon class="manage">settings</mat-icon>
							{{
							server.fields?.environment_usage === 'unknown'
							? 'Get
							Started'
							: 'Manage'
							}}
						</button>
						<central-progress-button [state]="submitted" *ngIf="!server.fields?.has_publish_setup_user"
							mat-raised-button [color]="secondary" class="animate__animated animate__fadeIn" disabled>
							<mat-spinner role="progressbar" mode="indeterminate" diameter="20" color="primary"
								class="mat-spinner mat-progress-spinner mat-primary mat-progress-spinner-indeterminate-animation"
								style="width: 20px; height: 20px"><svg preserveAspectRatio="xMidYMid meet"
									focusable="false" style="width: 20px; height: 20px" ng-reflect-ng-switch="true"
									viewBox="0 0 12 12">
									<circle cx="50%" cy="50%" style="
											animation-name: mat-progress-spinner-stroke-rotate-20;
											stroke-dasharray: 31.4159px;
											stroke-width: 10%;
										" r="5" class="ng-star-inserted"></circle>
								</svg></mat-spinner>
							<span *ngIf="!this.vpsStates[i]">Checking Status</span>
							<span *ngIf="this.vpsStates[i] === 'provisioning'">Provisioning...</span>
							<span *ngIf="this.vpsStates[i] === 'installing'">Configuring WordPress...</span>
						</central-progress-button>
						<button *ngIf="showJobQueueButton" mat-stroked-button color="secondary"
							[routerLink]="[ '/projects/', this.projectService.project.id, 'jobs' ]"
							class="animate__animated animate__fadeIn">
							<mat-icon class="manage">admin_panel_settings</mat-icon>
							<span class="ctw-text-imh-red">Job Queue</span></button>
					</div>
				</div>
			</dash-card>
		</div>

		<!-- Playgrounds -->
		<dash-card *ngIf="this.playgroundAvailabilityRequestState" icon="settings_system_daydream"
			cardTitle="Playground Sites" [subTitle]="[
			this.playgroundUsed + ' / ' + this.playgroundLimit,
			'Experiment with new ideas or create demo sites for clients!',
			]" disabled="true" class="ctw-mt-8 ctw-w-full">
			<div class="">
				<div class="ctw-flex ctw-justify-end ctw-mt-4 ctw-mr-4">
					<central-progress-button [state]="this.addPlaygroundState" color="primary" type="stroked"
						(click)="this.addPlayground()"><mat-icon class="add mat-primary"
							*ngIf="this.addPlaygroundState !== 'submitted'">add</mat-icon>
						<ng-container *ngIf="this.addPlaygroundState !== 'submitted'">New Playground</ng-container>
						<ng-container *ngIf="this.addPlaygroundState === 'submitted'">Creating
							Playground...</ng-container>
					</central-progress-button>
				</div>
				<p class="ctw-flex ctw-justify-center ctw-items-center ctw-text-2xl ctw-mb-10 ctw-text-imh-gray-200"
					*ngIf="!this.projectService.hasPlaygrounds()">
					No Active Playgrounds
				</p>
				<table *ngIf="this.projectService.hasPlaygrounds()" mat-table [dataSource]="this.playgrounds"
					class="mat-elevation-z">
					<!--- Note that these columns can be defined in any order.
						The actual rendered columns are set as a property on the row definition" -->

					<!-- Position Column -->
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>
							Active Playgrounds
						</th>
						<td class="ctw-w-full ctw-py-4" mat-cell *matCellDef="let element">
							<span class="sub-title editable ctw-text-base ctw-cursor-pointer"
								(click)="this.openEditDialog(element)">
								<span>{{ element.label
									}}<a class="ctw-mx-4">[edit]</a>
								</span>
							</span><br><a color="secondary" target="_blank" [href]="element.fields?.site_url">
								<span class="ctw-text-sm">{{ element.fields?.site_url }} <mat-icon
										class="ctw-text-sm ctw-h-3.5 ctw-w-3.5">open_in_new</mat-icon></span>
							</a>
						</td>
					</ng-container>

					<!-- Weight Column -->
					<ng-container matColumnDef="actions">
						<th mat-header-cell *matHeaderCellDef></th>
						<td class="ctw-flex ctw-py-4" mat-cell *matCellDef="let environment">
							<button mat-menu-item matTooltip="Admin" *ngIf="
									projectService.hasEnvironmentControl(
										environment
									)
								" (click)="openAdmin(environment.id)" [disabled]="!this.wpRestService.getSite(environment)">
								<mat-icon class="ctw-mr-0 mat-primary">{{
									this.projectService.project.fields.project_type
									}}</mat-icon>
							</button>
							<button mat-menu-item matTooltip="Manage Environment" *ngIf="
									projectService.hasEnvironmentControl(
										environment
									)
								" (click)="changeEnvironment(environment.id)">
								<mat-icon class="ctw-mr-0">settings</mat-icon>
							</button>
							<button mat-menu-item matTooltip="Delete Playground" *ngIf="
									projectService.hasEnvironmentControl(
										environment
									) && +environment?.fields?.is_development
								" (click)="openDialog(environment.id)">
								<mat-icon class="ctw-mr-0">delete_forever</mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr class="ctw-cursor-pointer hover:ctw-bg-black hover:ctw-bg-opacity-05" mat-row
						*matRowDef="let row; columns: displayedColumns"></tr>
				</table>
			</div>
		</dash-card>
	</div>
</div>
