<ng-container *ngIf="data.canLeave">
	<h4 *ngIf="!data.isPrivate" mat-dialog-title>
		Leave "{{ data.teamName }}"?
	</h4>
	<mat-dialog-content class="mat-typography">
		<p>
			You will lose access to all Projects, Domains and Email managed by
			this team.
		</p>
		<p class="error" *ngIf="this.errorMessage">{{ this.errorMessage }}</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
		<span class="spacer"></span>
		<central-progress-button
			color="warn"
			[disabled]="false"
			[state]="state"
			(click)="leaveTeam()"
			>Leave
			{{
				this.configurationService.config.groupLabels[
					'group-label-title-case'
				]
			}}</central-progress-button
		>
	</mat-dialog-actions>
</ng-container>
<ng-container *ngIf="!data.canLeave">
	<h4 mat-dialog-title>Cannot Leave "{{ data.teamName }}"</h4>
	<mat-dialog-content class="mat-typography">
		<p>
			You are the only Manager of this
			{{
				this.configurationService.config.groupLabels[
					'group-label-lower-case'
				]
			}}.<br />Every
			{{
				this.configurationService.config.groupLabels[
					'group-label-lower-case'
				]
			}}
			must have at least one Manager.
		</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button
			mat-raised-button
			color="primary"
			mat-dialog-close
			cdkFocusInitial
		>
			OK
		</button>
	</mat-dialog-actions>
</ng-container>
