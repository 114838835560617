<div class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray">
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<h2>
			DNS Management
		</h2>
		<div>
			<button *ngIf="'succeeded' === getRequestState" mat-raised-button color="primary" (click)="addRecord()"
				[disabled]="
					-1 !== editingRecordIndex ||
					'inactive' !== deleteRequestState
				" class="animate__animated animate__fadeIn">
				<mat-icon>add</mat-icon> Add Record
			</button>
		</div>
	</div>
	<mat-progress-bar *ngIf="'loading' === getRequestState || 'loading' === saveRequestState" mode="indeterminate"
		color="primary"></mat-progress-bar>
</div>

<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-mb-12 ctw-pt-16 content">
	<failed-request *ngIf="'failed' === getRequestState"></failed-request>

	<p *ngIf="'notfound' === getRequestState" class="empty-label">
		No DNS Records Found
	</p>

	<ng-container *ngIf="'succeeded' === getRequestState">
		<p>
			When hosting Projects or Email through Platform InMotion,
			we will automatically create the appropriate DNS records for your
			services. Adding manual records is usually only necessary
			when handling special situations or if your website or email is not
			hosted here.
		</p>
		<p class="subtitle" *ngIf="!this.isControlled && 0">
			These records only apply if the domain <a
				href="https://www.inmotionhosting.com/support/domain-names/changing-your-domains-nameservers/"
				target="_blank">points to our name servers</a>.
		</p>

		<dash-card cardTitle="Zone Editor" icon="dns" disabled="true" [subTitle]="[domainName]">
			<ng-container subTitleHTML *ngIf="!this.isControlled">
				<span class="sub-title ctw-text-base">
					<span class="ctw-px-2 mat-stroked-button ctw-font-bold third-party ctw-mr-2">3rd
						Party</span>
					These records only apply if the domain <a
						href="https://www.inmotionhosting.com/support/domain-names/changing-your-domains-nameservers/"
						target="_blank">points to our name servers</a>.
				</span>
			</ng-container>
			<table *ngIf="records.length" mat-table [dataSource]="records" class="mat-elevation-z1">
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Name</th>
					<td mat-cell *matCellDef="let element; index as i">
						<ng-container *ngIf="editingRecordIndex !== i">
							<span class="break-character">{{ element.name }}</span>
						</ng-container>

						<ng-container *ngIf="editingRecordIndex === i">
							<mat-form-field appearance="outline">
								<mat-label>Name</mat-label>
								<input matInput [(ngModel)]="editedRecord.name"
									[disabled]="'SOA' === editedRecord.type" />
							</mat-form-field>
						</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef="type">
					<th mat-header-cell *matHeaderCellDef>Type</th>
					<td mat-cell *matCellDef="let element; index as i">
						<ng-container *ngIf="editingRecordIndex !== i">
							{{ element.type }}
						</ng-container>

						<ng-container *ngIf="editingRecordIndex === i">
							<mat-form-field class="form-field-type" appearance="outline">
								<mat-label>Type</mat-label>
								<mat-select [disabled]="'SOA' === editedRecord.type" [(value)]="editedRecord.type">
									<ng-container *ngIf="'SOA' === editedRecord.type">
										<mat-option value="SOA">SOA</mat-option>
									</ng-container>
									<ng-container *ngIf="'SOA' !== editedRecord.type">
										<mat-option *ngFor="let recordType of recordTypes" [value]="recordType">
											{{ recordType }}
										</mat-option>
									</ng-container>
								</mat-select>
							</mat-form-field>
						</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef="time-to-live">
					<th mat-header-cell *matHeaderCellDef>
						<abbr title="Time To Live">TTL</abbr>
					</th>
					<td mat-cell *matCellDef="let element; index as i">
						<ng-container *ngIf="editingRecordIndex !== i">
							{{ element.ttl }}
						</ng-container>

						<ng-container *ngIf="editingRecordIndex === i">
							<mat-form-field class="form-field-ttl" appearance="outline">
								<mat-label>TTL</mat-label>
								<input matInput [formControl]="ttlFormControl" [(ngModel)]="editedRecord.ttl" />
								<mat-error *ngIf="
									ttlFormControl.hasError('min') ||
									ttlFormControl.hasError('max')
								">Must be a number between 0 and
									2147483647</mat-error>
							</mat-form-field>
						</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef="value">
					<th mat-header-cell *matHeaderCellDef>Value</th>
					<td mat-cell *matCellDef="let element; index as i">
						<ng-container *ngIf="editingRecordIndex !== i">
							<ng-container *ngIf="'A' === element.type">
								<span class="break-character">{{
									element.rdata.aValue
									}}</span></ng-container>
							<ng-container *ngIf="'AAAA' === element.type">
								<span class="break-character">{{
									element.rdata.aaaaValue
									}}</span></ng-container>
							<ng-container *ngIf="'CAA' === element.type">
								<span class="value-label">Flag:</span>
								{{ element.rdata.caaFlag }}<br /><span class="value-label">Tag:</span>
								{{ element.rdata.caaTag }}<br /><span class="value-label">Value:</span>
								{{ element.rdata.caaValue }}</ng-container>
							<ng-container *ngIf="'CNAME' === element.type">
								<span class="break-character">{{
									element.rdata.cnameValue
									}}</span></ng-container>
							<ng-container *ngIf="'NS' === element.type">
								<span class="break-character">{{
									element.rdata.nsValue
									}}</span></ng-container>
							<ng-container *ngIf="'MX' === element.type">
								<span class="value-label">Priority:</span>
								{{ element.rdata.mxPriority }}<br /><span class="value-label">Destination:</span>
								{{ element.rdata.mxDestination }}</ng-container>
							<ng-container *ngIf="'SOA' === element.type">
								<span class="value-label">Mname:</span>
								{{ element.rdata.soaMname }}<br /><span class="value-label">Rname:</span>
								{{ element.rdata.soaRname }}<br /><span class="value-label">Serial:</span>
								{{ element.rdata.soaSerial }}<br /><span class="value-label">Refresh:</span>
								{{ element.rdata.soaRefresh }}<br /><span class="value-label">Retry:</span>
								{{ element.rdata.soaRetry }}<br /><span class="value-label">Expire:</span>
								{{ element.rdata.soaExpire }}<br /><span class="value-label">Minimum:</span>
								{{ element.rdata.soaMinimum }}</ng-container>
							<ng-container *ngIf="'SRV' === element.type">
								<span class="value-label">Priority:</span>
								{{ element.rdata.srvPriority }}<br /><span class="value-label">Weight:</span>
								{{ element.rdata.srvWeight }}<br /><span class="value-label">Port:</span>
								{{ element.rdata.srvPort }}<br /><span class="value-label">Target:</span>
								{{ element.rdata.srvTarget }}</ng-container>
							<ng-container *ngIf="'TXT' === element.type">
								<span class="break-character">{{
									element.rdata.txtValue
									}}</span></ng-container>
						</ng-container>

						<ng-container *ngIf="editingRecordIndex === i">
							<ng-container *ngIf="'A' === editedRecord.type">
								<mat-form-field appearance="outline">
									<mat-label>IPv4 Address</mat-label>
									<input matInput [formControl]="ipv4FormControl"
										[(ngModel)]="editedRecord.rdata.aValue" />
									<mat-error *ngIf="ipv4FormControl.hasError('pattern')">Invalid IPv4
										address</mat-error>
								</mat-form-field>
							</ng-container>

							<ng-container *ngIf="'AAAA' === editedRecord.type">
								<mat-form-field appearance="outline">
									<mat-label>IPv6 Address</mat-label>
									<input matInput [(ngModel)]="editedRecord.rdata.aaaaValue" />
								</mat-form-field>
							</ng-container>

							<ng-container *ngIf="'CAA' === editedRecord.type">
								<div class="radio-row">
									Flag:
									<mat-radio-group [(ngModel)]="editedRecord.rdata.caaFlag">
										<mat-radio-button [value]="0">0</mat-radio-button>
										<mat-radio-button [value]="1">1</mat-radio-button>
									</mat-radio-group>
								</div>

								<div class="radio-row">
									Tag:
									<mat-radio-group [(ngModel)]="editedRecord.rdata.caaTag">
										<mat-radio-button value="issue">issue</mat-radio-button>
										<mat-radio-button value="issuewild">issuewild</mat-radio-button>
										<mat-radio-button value="iodef">iodef</mat-radio-button>
									</mat-radio-group>
								</div>

								<mat-form-field appearance="outline">
									<mat-label>Value</mat-label>
									<input matInput [(ngModel)]="editedRecord.rdata.caaValue" />
								</mat-form-field>
							</ng-container>

							<ng-container *ngIf="'CNAME' === editedRecord.type">
								<mat-form-field appearance="outline">
									<mat-label>Fully-Qualified Domain Name</mat-label>
									<input matInput [(ngModel)]="editedRecord.rdata.cnameValue" />
								</mat-form-field>
							</ng-container>

							<ng-container *ngIf="'NS' === editedRecord.type">
								<mat-form-field appearance="outline">
									<mat-label>Fully-Qualified Domain Name</mat-label>
									<input matInput [(ngModel)]="editedRecord.rdata.nsValue" />
								</mat-form-field>
							</ng-container>

							<ng-container *ngIf="'MX' === editedRecord.type">
								<mat-form-field appearance="outline">
									<mat-label>Priority</mat-label>
									<input matInput [formControl]="mxPriorityFormControl"
										[(ngModel)]="editedRecord.rdata.mxPriority" />
									<mat-error *ngIf="
										mxPriorityFormControl.hasError('min') ||
										mxPriorityFormControl.hasError('max')
									">Must be a number between 0 and
										65535</mat-error>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Destination</mat-label>
									<input matInput [(ngModel)]="
										editedRecord.rdata.mxDestination
									" />
								</mat-form-field>
							</ng-container>

							<ng-container *ngIf="'SOA' === editedRecord.type">
								<mat-form-field appearance="outline">
									<mat-label>Mname</mat-label>
									<input matInput [(ngModel)]="editedRecord.rdata.soaMname" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Rname</mat-label>
									<input matInput [(ngModel)]="editedRecord.rdata.soaRname" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Serial</mat-label>
									<input matInput [(ngModel)]="editedRecord.rdata.soaSerial" disabled />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Refresh</mat-label>
									<input matInput [formControl]="soaRefreshFormControl"
										[(ngModel)]="editedRecord.rdata.soaRefresh" />
									<mat-error *ngIf="
										soaRefreshFormControl.hasError('min') ||
										soaRefreshFormControl.hasError('max')
									">Must be a number between 0 and
										2147483647</mat-error>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Retry</mat-label>
									<input matInput [formControl]="soaRetryFormControl"
										[(ngModel)]="editedRecord.rdata.soaRetry" />
									<mat-error *ngIf="
										soaRetryFormControl.hasError('min') ||
										soaRetryFormControl.hasError('max')
									">Must be a number between 0 and
										2147483647</mat-error>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Expire</mat-label>
									<input matInput [formControl]="soaExpireFormControl"
										[(ngModel)]="editedRecord.rdata.soaExpire" />
									<mat-error *ngIf="
										soaExpireFormControl.hasError('min') ||
										soaExpireFormControl.hasError('max')
									">Must be a number between 0 and
										2147483647</mat-error>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Minimum</mat-label>
									<input matInput [formControl]="soaMinimumFormControl"
										[(ngModel)]="editedRecord.rdata.soaMinimum" />
									<mat-error *ngIf="
										soaMinimumFormControl.hasError('min') ||
										soaMinimumFormControl.hasError('max')
									">Must be a number between 0 and
										2147483647</mat-error>
								</mat-form-field>
							</ng-container>

							<ng-container *ngIf="'SRV' === editedRecord.type">
								<mat-form-field appearance="outline">
									<mat-label>Priority</mat-label>
									<input matInput [formControl]="srvPriorityFormControl"
										[(ngModel)]="editedRecord.rdata.srvPriority" />
									<mat-error *ngIf="
										srvPriorityFormControl.hasError(
											'min'
										) ||
										srvPriorityFormControl.hasError('max')
									">Must be a number between 0 and
										65535</mat-error>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Weight</mat-label>
									<input matInput [formControl]="srvWeightFormControl"
										[(ngModel)]="editedRecord.rdata.srvWeight" />
									<mat-error *ngIf="
										srvWeightFormControl.hasError('min') ||
										srvWeightFormControl.hasError('max')
									">Must be a number between 0 and
										65535</mat-error>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Port</mat-label>
									<input matInput [formControl]="srvPortFormControl"
										[(ngModel)]="editedRecord.rdata.srvPort" />
									<mat-error *ngIf="
										srvPortFormControl.hasError('min') ||
										srvPortFormControl.hasError('max')
									">Must be a number between 0 and
										65535</mat-error>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Target</mat-label>
									<input matInput [(ngModel)]="editedRecord.rdata.srvTarget" />
								</mat-form-field>
							</ng-container>

							<ng-container *ngIf="'TXT' === editedRecord.type">
								<mat-form-field appearance="outline">
									<mat-label>Value</mat-label>
									<textarea matInput [(ngModel)]="editedRecord.rdata.txtValue"></textarea>
								</mat-form-field>
							</ng-container>
						</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element; index as i" class="no-wrap">
						<ng-container *ngIf="i !== editingRecordIndex">
							<ng-container *ngIf="'inactive' === deleteRequestState">
								<button mat-button color="primary" [class.hide]="-1 !== editingRecordIndex"
									(click)="editRecord(i)">
									Edit</button><button mat-button color="warn" [class.hide]="
									'SOA' === element.type ||
									-1 !== editingRecordIndex
								" (click)="deleteRecord(i)">
									Delete
								</button>
							</ng-container>
							<ng-container *ngIf="
								'loading' === deleteRequestState &&
								i === deletingRecordIndex
							">
								<mat-progress-spinner class="inline-spinner" mode="indeterminate" color="primary"
									diameter="16"></mat-progress-spinner>
								Deleting...
							</ng-container>
						</ng-container>

						<ng-container *ngIf="i === editingRecordIndex">
							<ng-container *ngIf="'inactive' === saveRequestState">
								<button mat-button color="primary" (click)="saveRecord(i)">
									Save</button><button mat-button color="primary" (click)="cancelEditRecord()">
									Cancel
								</button>
							</ng-container>
							<ng-container *ngIf="'loading' === saveRequestState">
								<button mat-button color="primary" disabled>
									Saving...
								</button>
								<button mat-button color="primary" disabled>
									Cancel
								</button>
							</ng-container>
						</ng-container>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns" [class.disabled-row]="-1 !== editingRecordIndex">
				</tr>
				<tr mat-row *matRowDef="let row; index as i; columns: displayedColumns" [ngClass]="
					-1 !== editingRecordIndex
						? editingRecordIndex === i
							? 'highlighted-row'
							: 'disabled-row'
						: ''
				"></tr>
			</table>
		</dash-card>
	</ng-container>
</div>
