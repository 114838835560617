export class AutoBackup {
	public daysOfWeek = [
		'sunday',
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
	];

	public enable(settings) {
		const schedule = settings.schedule;
		const day =
			this.daysOfWeek[Math.floor(Math.random() * this.daysOfWeek.length)];
		const key = `dow_${day}`;
		schedule[key] = 1;

		return schedule;
	}

	public hasAutoBackups(settings) {
		let hasScheduledBackups = false;
		for (const [label, enabled] of Object.entries(settings.schedule)) {
			if (label.startsWith('dow') && enabled) {
				hasScheduledBackups = true;
			}
		}

		return hasScheduledBackups;
	}
}
