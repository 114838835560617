import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TitleService, AccountService } from '@central/ng-shared';
import { PaymentComponent } from '../../../checkout';
import { ProjectCreateService } from '../project-create.service';

@Component({
	templateUrl: './purchase.component.html',
	styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit, AfterViewInit {
	@ViewChild('paymentForm') paymentForm: PaymentComponent;

	public packages = [];
	public excludedInputs = ['organization'];
	public forceLogin = true;
	public loginModalTitle = 'Sign-Up';
	public loginModalSubTitle = 'First, let\'s connect you to the Platform';
	public minimalForm = true;

	constructor(
		public projectCreateService: ProjectCreateService,
		public titleService: TitleService
	) {
		this.titleService.setTitle('Payment');

		// If the user presses the back button, send them back to the first step.
		if (this.projectCreateService.getSelections().mode !== 'purchase') {
			this.projectCreateService.navigateTo(1);
		}
	}

	ngOnInit(): void {
		this.packages = [...this.packages, ...this.getDomainPlan()];
		this.packages = [...this.packages, ...this.getEmailPlan()];
		this.packages = [...this.packages, ...this.getServerPlan()];
		this.packages = [...this.packages, ...this.getAddons()];
	}

	ngAfterViewInit(): void {
		this.paymentForm.organizationId =
			this.projectCreateService.getSelections().organizationId;

		this.paymentForm.cart.cartUpdated.subscribe(() => {
			this.projectCreateService.updateSelections(
				'term',
				this.paymentForm.cart.term
			);
		});

		this.paymentForm.paymentSuccess.subscribe(() => {
			if (!this.getCouponData()) {
				// Hide Payment Title
				document.querySelector('h2').parentElement.style.display = 'none';
			}
			this.paymentForm.receipt.products.find((product) => {
				if (product.code.startsWith('wpc_project')) {
					this.projectCreateService.updateSelections(
						'primarySubscriptionUuid',
						product.resource_ids.subscription_uuid
					);
				}
			});
		});
	}

	private getDomainPlan() {
		const domainPlan = [];
		if (this.projectCreateService.getSelections().domainName) {
			domainPlan.push({
				planCode: 'domain-gtld-1',
				options: {
					allows_multiple: false,
					domain_name:
						this.projectCreateService.getSelections().domainName,
				},
			});
		}

		return domainPlan;
	}

	private getServerPlan() {
		const serverPlan = [];
		if (this.projectCreateService.getSelections().serverTier) {
			const planName =
				'wphosting_tier_' +
				this.projectCreateService.getSelections().serverTier;

			serverPlan.push({
				planCode: planName,
				options: {
					allows_multiple: false,
					data_center: this.projectCreateService.getSelections().dataCenter,
					project_id: this.projectCreateService.getSelections().projectId
				},
			});
		}

		return serverPlan;
	}

	private getAddons(): any[] {
		const addons = [];
		for (const addon of this.projectCreateService.getSelections()
			.projectAddons) {
			addons.push({
				planCode: addon,
				options: {
					allows_multiple: false,
				},
			});
		}

		return addons;
	}

	private getEmailPlan(): any[] {
		const emails = [];
		if (this.projectCreateService.getSelections().numberOfEmails > 0) {
			emails.push({
				planCode: 'email_account',
				quantity:
					this.projectCreateService.getSelections().numberOfEmails,
				options: {
					allows_multiple: true,
				},
			});
		}

		return emails;
	}

	public getCouponData() {
		if (this.projectCreateService.couponData) {
			return this.projectCreateService.couponData;
		}
	}

	public isTrial() {
		if (this.getCouponData()) {
			return this.getCouponData().coupon_code.match(/-trial$/) ? true : false;
		}
		return false;
	}
}
