<dash-card *ngIf="!this.projectService.configService.hasFeature('IMC-683')" icon="assessment" cardTitle="Site Stats"
	class="dash-card" disabled="true">
	<div class="dash-card-content dash-card-mat-list">
		<mat-list>
			<mat-list-item>
				<mat-icon mat-list-icon>local_hospital</mat-icon>
				<h4 mat-line>Site Health</h4>
				<p mat-line>
					<a routerLink="../health">{{ this.getHealthSummary() }}</a>
				</p>
			</mat-list-item>
			<mat-list-item>
				<mat-icon mat-list-icon>palette</mat-icon>
				<h4 mat-line>Active Theme</h4>
				<p mat-line>
					<a routerLink="../themes">{{
						this.stats['debug']['wp-active-theme']?.fields?.name
						?.value
						}}</a>
				</p>
			</mat-list-item>
			<mat-list-item>
				<mat-icon mat-list-icon>extensions</mat-icon>
				<h4 mat-line>Active Plugins</h4>
				<p mat-line>
					<a routerLink="../plugins">{{
						this.countPlugins()
						? this.countPlugins() + ' Plugins'
						: 'No Plugins Active'
						}}</a>
				</p>
			</mat-list-item>
			<mat-list-item>
				<span mat-list-icon inlineSVG="assets/img/wordpress.svg"></span>
				<h4 mat-line>WordPress Version</h4>
				<p mat-line>
					{{ this.stats['debug']['wp-core'].fields.version.value }}
				</p>
			</mat-list-item>
			<mat-list-item>
				<mat-icon mat-list-icon>code</mat-icon>
				<h4 mat-line>PHP Version</h4>
				<p mat-line>
					{{
					this.stats['debug']['wp-server'].fields.php_version
					.value
					}}
				</p>
			</mat-list-item>
		</mat-list>
	</div>
</dash-card>
<div *ngIf="this.projectService.configService.hasFeature('IMC-683')">
	<mat-list
		class="ctw-grid ctw-grid-cols-3 ctw-p-0 ctw-border-b ctw-border-gray-300 ctw-border-solid ctw-mb-8 ctw-pb-6">
		<mat-list-item>
			<span mat-list-icon inlineSVG="assets/img/wordpress.svg"></span>
			<h4 mat-line>{{ this.stats['debug']['wp-core'].fields.version.value.split('(')[0] }}</h4>
			<p mat-line class="ctw-text-imh-gray-200">
				WordPress Version
			</p>
		</mat-list-item>
		<mat-list-item>
			<mat-icon mat-list-icon>code</mat-icon>
			<h4 mat-line>{{
				this.stats['debug']['wp-server'].fields.php_version
				.value.split(' ')[0]
				}}</h4>
			<p mat-line class="ctw-text-imh-gray-200">
				<a routerLink="../php-settings">PHP Version</a>
			</p>
		</mat-list-item>
		<mat-list-item>
			<mat-icon mat-list-icon>warning</mat-icon>
			<h4 mat-line>{{ this.summary }}</h4>
			<p mat-line class="ctw-text-imh-blue ctw-cursor-pointer">
				<span (click)="
					this.projectService.wpRestService.login(
						this.projectService.environment,
						'wp-admin/site-health.php'
					)
				">Recommendation{{ this.recommendations > 1 ? 's' : '' }}</span>
			</p>
		</mat-list-item>
		<!--
		<mat-list-item>
			<mat-icon mat-list-icon>palette</mat-icon>
			<h4 mat-line>Active Theme</h4>
			<p mat-line>
				<a routerLink="../themes">{{
					this.stats['debug']['wp-active-theme']?.fields?.name
					?.value
					}}</a>
			</p>
		</mat-list-item>
		<mat-list-item>
			<mat-icon mat-list-icon>extensions</mat-icon>
			<h4 mat-line>Active Plugins</h4>
			<p mat-line>
				<a routerLink="../plugins">{{
					this.countPlugins()
					? this.countPlugins() + ' Plugins'
					: 'No Plugins Active'
					}}</a>
			</p>
		</mat-list-item>
	-->
	</mat-list>

	<div *ngIf="!this.hasUpdates">
		<div class="task-complete ctw-text-center">
			<span class="status-icon ctw-w-24 ctw-inline-block" inlineSVG="./assets/temp/cloud-done.svg"></span>
			<p>Everything is up to date</p>
			<p>The latest and greatest is up and running!</p>
		</div>
	</div>
	<div *ngIf="this.hasUpdates">
		<div class="update-info ctw-p-0">
			<div class="ctw-text-center ctw-mb-2 ctw-font-bold ctw-text-imh-red">
				The following items are {{ this.updates !== this.recommendations ? 'also ' : '' }}out of date!
			</div>

			<div class="ctw-mb-5" *ngIf="this.hasWpUpdate()">
				<h4 class="ctw-font-bold">WordPress</h4>
				<ul>
					<li>
						WordPress -
						{{
						this.stats['debug']['wp-core']['fields']['version'][
						'value'
						]
						}}
					</li>
				</ul>
			</div>
			<div class="ctw-mb-5" *ngIf="this.updateThemes.length">
				<h4 class="ctw-font-bold">Themes</h4>
				<ul>
					<ng-container *ngFor="let theme of this.updateThemes">
						<li *ngIf="theme.version">
							{{
							stripSlug(theme.name.value) +
							' - ' +
							theme.version.value
							}}
						</li>
						<li *ngIf="!theme.version">
							{{ stripSlug(theme.label) + ' - ' + theme.value }}
						</li>
					</ng-container>
				</ul>
			</div>
			<div class="ctw-mb-5" *ngIf=" this.updatePlugins.length">
				<h4 class="ctw-font-bold">Plugins</h4>
				<ul>
					<ng-container *ngFor="let plugin of this.updatePlugins">
						<li *ngIf="plugin.version">
							{{
							stripSlug(plugin.name.value) +
							' - ' +
							plugin.version.value
							}}
						</li>
						<li *ngIf="!plugin.version">
							{{ stripSlug(plugin.label) + ' - ' + plugin.value }}
						</li>
					</ng-container>
				</ul>
			</div>

			<div class="ctw-flex ctw-justify-between ctw-mt-12">
				<div></div>
				<button mat-raised-button color="primary" (click)="
						this.projectService.login('wp-admin/update-core.php')
					">Update In WordPress</button>
			</div>
		</div>
	</div>
</div>
