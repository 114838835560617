import { Component, Input } from '@angular/core';

import { ContactSet } from '../domain-types';

@Component({
	selector: 'central-contact-set',
	templateUrl: './contact-set.component.html',
	styleUrls: ['./contact-set.component.scss'],
})
export class ContactSetComponent {
	@Input() contactSet: ContactSet;

	constructor() {}
}
