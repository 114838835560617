import { Component } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'cloud-snap-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss'],
})
export class DetailsComponent {
	public snap: object;
	public status = 'pending';

	constructor(private route: ActivatedRoute, public apiService: ApiService) {
		this.route.params.subscribe((params) => {
			this.fetchSnap(params['snapId']);
		});
	}

	private fetchSnap(snapId: string): void {
		this.apiService.get('getSnap', { snap_id: snapId }).subscribe(
			(response) => {
				this.snap = response;
				this.status = 'success';
			},
			() => (this.status = 'failed')
		);
	}
}
