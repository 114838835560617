<div class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray">
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<h2>
			<img src="https://design.inmotionhosting.com/assets/icons/custom/platform-i-{{ this.projectService.project.fields.project_type
			}}.svg" class="ctw-float-left ctw-inline-block ctw-mr-4 ctw-w-10"> {{ this.projectService.project.label }}

		</h2>
		<h4 *ngIf="this.allowPurchase && this.chatEnabled()" class="animate__animated animate__fadeIn ctw-mb-0">
			Need help deciding?
			<button mat-raised-button color="primary" (click)="this.openChat()" class="ctw-ml-2">
				Speak With Us
			</button>
		</h4>
	</div>
	<mat-progress-bar *ngIf="!this.isReady" mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<mat-horizontal-stepper (selectionChange)="this.stepChange()"
	class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-pt-16 publish-stepper" #stepper
	linear="true">
	<mat-step *ngIf="this.allowPurchase" [editable]="billing?.isComplete() ? false : true">
		<form>
			<ng-template matStepLabel>
				<div class="step-label ctw-flex ctw-flex-col ctw-items-center">
					<mat-icon mat-list-icon>dns</mat-icon>
					<p>Server</p>
				</div>
			</ng-template>
			<div class="ctw-px-4 ctw-py-2 ctw-mt-8 ctw-text-center">
				<p class="ctw-text-3xl">{{ this.isUpdatePlan ? 'Resize Your' : 'Select Your New' }} Cloud Server</p>
			</div>
			<central-hosting [isUpdatePlan]="this.isUpdatePlan" [currentPlan]="this.currentPlan"
				[couponData]="this.couponData" #centralHosting (hostingComponentReady)="this.isReady = true"
				(enableNext)="this.enableNext = $event"></central-hosting>
			<div class="ctw-max-w-4xl ctw-mx-auto ctw-mt-10 ctw-flex ctw-justify-between">
				<button *ngIf="'error' === centralHosting.state || this.isReady" mat-button (click)="back()"
					color="primary" mat-stroked-button>
					Back
				</button>
				<button *ngIf="'success' === centralHosting.state && this.isReady" [disabled]="!this.enableNext"
					mat-button matStepperNext color="primary" mat-raised-button>
					Next
				</button>
				<button *ngIf="'error' === centralHosting.state && this.isReady" mat-button (click)="retryFailure()"
					color="primary" mat-raised-button>
					Retry <mat-icon>autorenew</mat-icon>
				</button>
			</div>
		</form>
	</mat-step>
	<mat-step *ngIf="this.allowPurchase" [completed]="billing?.isComplete()" editable="false">
		<ng-template matStepLabel>
			<div class="step-label ctw-flex ctw-flex-col ctw-items-center">
				<mat-icon mat-list-icon>monetization_on</mat-icon>
				<p>Billing</p>
			</div>
		</ng-template>
		<div class="payments">
			<deploy-checkout #billing [subscriptionUuid]="this.subscriptionUuid" [isAddPlan]="this.isAddPlan"
				[term]="this.termType" [isUpdatePlan]="this.isUpdatePlan" [couponData]="this.couponData"
				(deployCheckoutReady)="this.isReady = true"></deploy-checkout>
		</div>
	</mat-step>
	<mat-step>
		<ng-template matStepLabel>
			<div class="step-label ctw-flex ctw-flex-col ctw-items-center">
				<mat-icon mat-list-icon>publish</mat-icon>
				<p>Publish</p>
			</div>
		</ng-template>
	</mat-step>
</mat-horizontal-stepper>
