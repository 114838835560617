import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService, ProfileService } from '../../authentication/services';
import { ApiService } from '../../core';

@Component({
	selector: 'central-suspension',
	templateUrl: './suspension.component.html',
	styleUrls: ['./suspension.component.scss'],
})
export class SuspensionComponent implements OnInit {
	@Input() accountId = false;
	@Input() isSuspended = false;

	public state = 'pending';

	constructor(
		private profileService: ProfileService,
		public snackBar: MatSnackBar,
		private authService: AuthService,
		private httpClient: HttpClient,
		private apiService: ApiService
	) {
		this.isSuspended = this.profileService.data.is_disabled;
	}

	ngOnInit() {
		if (!this.accountId) {
			this.accountId = this.authService.getAccountId();
			this.isSuspended = this.profileService.data.is_disabled;
		}
	}

	toggleState() {
		this.state = 'submitted';

		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl(
			`/v1/accounts/${this.accountId}/suspension`
		);
		let call = this.httpClient.post(url, {}, { headers });
		if (this.isSuspended) {
			call = this.httpClient.delete(url, { headers });
		}

		call.subscribe({
			next: (response) => {
				this.isSuspended = !!response['is_disabled'];
				this.snackBar.open('Account Updated', 'Dismiss', {
					duration: 3000,
					horizontalPosition: 'right',
				});
				this.state = 'pending';
			},
			error: (error) => {
				// set location lower right
				this.snackBar.open('Error', 'Dismiss', {
					duration: 3000,
					horizontalPosition: 'right',
				});
				this.state = 'pending';
			},
		});
	}
}
