<h3>Create Snapshot</h3>
<p>Enter a name for your new snapshot to easily identify it later.</p>
<mat-form-field>
    <input [placeholder]="this.placeholder" name="project-name" matInput [(ngModel)]="this.snapshotName" />
</mat-form-field>
<div class="ctw-text-right">
    <button [mat-dialog-close]="'cancel'" mat-button mat-dialog-close class="ctw-mr-2">
        Cancel
    </button>
    <button [mat-dialog-close]="this.snapshotName" mat-raised-button color="primary">
        Update
    </button>
</div>