<div>
	<p>
		{{ contactSet.first_name }} {{ contactSet.last_name }}<br />
		{{ contactSet.org_name }}<br />
		{{ contactSet.address1 }}<br />
		<ng-container *ngIf="contactSet.address2"
			>{{ contactSet.address2 }}<br
		/></ng-container>
		<ng-container *ngIf="contactSet.address3"
			>{{ contactSet.address3 }}<br
		/></ng-container>
		{{ contactSet.city }}<br />
		{{ contactSet.state }}, {{ contactSet.country }}
		{{ contactSet.postal_code }}<br />
		{{ contactSet.phone }}<br />
		<ng-container *ngIf="contactSet.fax"
			>{{ contactSet.fax }}<br
		/></ng-container>
		{{ contactSet.email }}
	</p>
</div>
