<mat-spinner
	*ngIf="status === 'loading'"
	class="component-loader"
	diameter="50"
	color="primary"
></mat-spinner>
<failed-request *ngIf="status === 'failed'"></failed-request>
<div
	class="wrap animate__animated animate__fadeIn"
	*ngIf="status === 'success'"
>
	<h2 class="ctw-text-center title">Site Health</h2>
	<p>
		The site health check shows critical information about your WordPress
		configuration and items that require your attention.
	</p>
	<div class="actions">
		<button
			mat-stroked-button
			color="primary"
			(click)="
				this.wpRestService.login(
					this.projectService.environment,
					'wp-admin/site-health.php'
				)
			"
		>
			Manage in WordPress
		</button>
	</div>
	<ng-template
		#category
		let-issueType="issueType"
		let-level="level"
		let-issues="issues"
	>
		<ng-container *ngIf="issues.length">
			<div class="label">
				{{ issues.length }} {{ issueType
				}}{{
					(level === 'critical' || level === 'recommended') &&
					issues.length > 1
						? 's'
						: ''
				}}
			</div>
			<mat-accordion>
				<ng-container *ngFor="let item of issues">
					<mat-expansion-panel *ngIf="item">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<span class="item-label">{{ item.label }}</span
								><span
									class="badge badge-{{ item.badge.color }}"
									>{{ item.badge.label }}</span
								>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<p [innerHTML]="item.description"></p>
					</mat-expansion-panel>
				</ng-container>
			</mat-accordion>
		</ng-container>
	</ng-template>

	<ng-container
		*ngTemplateOutlet="
			category;
			context: {
				issueType: 'critical issue',
				level: 'critical',
				issues: siteHealth['critical'] || []
			}
		"
	></ng-container>
	<ng-container
		*ngTemplateOutlet="
			category;
			context: {
				issueType: 'recommended improvement',
				level: 'recommended',
				issues: siteHealth['recommended'] || []
			}
		"
	></ng-container>
	<ng-container
		*ngTemplateOutlet="
			category;
			context: {
				issueType: 'items with no issues detected',
				level: 'good',
				issues: siteHealth['good'] || []
			}
		"
	></ng-container>
</div>
