import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceComponent } from './invoice/invoice.component';
import { SharedModule } from '../shared/shared.module';
import { MomentModule } from 'ngx-moment';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

@NgModule({
	declarations: [InvoiceComponent],
	imports: [
		CommonModule,
		MomentModule,
		SharedModule,
		MatTableModule,
		MatCardModule,
	],
	exports: [InvoiceComponent],
})
export class BillingModule {}
