import { Component, Inject, Input } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'central-edit-dialog',
	templateUrl: 'edit-dialog.component.html',
})
export class EditDialogComponent {
	public resource: object;
	public state = 'pending';
	public originalResource: object;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public apiService: ApiService,
		public dialogRef: MatDialogRef<EditDialogComponent>,
		private snackBar: MatSnackBar
	) {
		this.originalResource = { ...data.resource };
		this.resource = data.resource;
	}

	/**
	 * Revert to original.
	 *
	 * @since 1.19.0
	 */
	public reset(): void {
		this.resource = this.originalResource;
		this.dialogRef.close(this.resource);
	}

	/**
	 * Update the name of a CWP.
	 *
	 * @since 1.19.0
	 */
	public update() {
		if (this.state === 'submitted') {
			return;
		}

		this.state = 'submitted';

		if (!this.data.updateAction) {
			this.apiService
				.post(this.data.apiCall, {
					...this.data.apiData,
					...{ label: this.resource['label'].trim() },
				})
				.subscribe(
					(response: object) => {
						this.resource = response;
						this.state = 'success';
						this.dialogRef.close(this.resource);
						this.snackBar.open(
							this.data.label + ' Name Updated Successfully!',
							'',
							{
								duration: 3000,
							}
						);
					},
					() => (this.state = 'failed')
				);
		} else {
			this.data.updateAction(this);
		}
	}
}
