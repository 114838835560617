<div class="checkout" class="ctw-mt-10" *ngIf="this.publishService.packages.length">
	<payment-form *ngIf="this.loadBilling && !reloadingBilling" #paymentForm (paymentSuccess)="this.onPaymentSuccess()"
		(paymentBack)="this.wizardComponent.stepper.previous()" [packages]="this.publishService.packages"
		[term]="this.getProjectTerm()" [isAddPlan]="this.isAddPlan" [isUpdatePlan]="this.isUpdatePlan"
		[hidden]="paymentForm.state === 'success'" [class.complete]="paymentForm.state === 'success'"
		[subscriptionUuid]="this.subscriptionUuid" [organizationId]="this.ownerAccount.account_id"
		discountObject="{{ this.getDiscountObject() }}" [couponData]="this.couponData"
		[hideGuarantee]="false"></payment-form>
</div>
<div class="ctw-text-center ctw-max-w-xl ctw-mx-auto ctw-mt-20"
	*ngIf="!this.publishService.packages.length && !this.reloadingBilling">
	<h2>Nothing to purchase.</h2>
	<p>No additional products selected, please continue to publish.</p>
	<div class="ctw-mt-20 ctw-flex ctw-justify-between">
		<button mat-button mat-stroked-button (click)="this.stepper.previous()">
			Back
		</button>
		<button mat-button mat-raised-button color="primary" (click)="this.stepper.next()">
			Next
		</button>
	</div>
</div>
