<!-- When processing free items we expect the transaction to be posted immediately. No Form shown. -->
<div class="loading" *ngIf="state === 'submitted'">
	<mat-progress-spinner class="component-loader" mode="indeterminate" color="primary"></mat-progress-spinner>
	<p>Processing</p>
</div>
<failed-request *ngIf="this.processingFreeCart && state === 'failed'"></failed-request>

<failed-request *ngIf="this.cartFailed()"></failed-request>

<div class="row payment-grid flex flex-wrap ctw-text-left" [hidden]="this.cartFailed()">
	<div class="col payment-column">
		<mat-card id="error-message" [hidden]="!showForms() || !hasError()" class="alert error-message">
			<strong class="error">ERROR:</strong> {{ this.errorMessage }}
		</mat-card>

		<form [hidden]="!showForms()" [class.animate__fadeIn]="showForms()" novalidate
			(ngSubmit)="this.formSubmission()" #form="ngForm">
			<dash-card *ngIf="this.useTeamSelector" disabled="1" cardTitle="Select the Team that will manage this {{
					this.teamPurchase
				}}" icon="groups" class="{{ this.cardClass }} team-selector">
				<div class="ctw-py-4 ctw-px-8">
					<central-account-selector inputAppearance="standard" permission="manage"
						(accountChanged)="accountChanged($event)"></central-account-selector>
				</div>
			</dash-card>
			<div [hidden]="!this.hasCard()">
				<dash-card *ngIf="this.fetchState === 'success'" disabled="1" cardTitle="Payment Method"
					icon="credit_card" class="{{ this.cardClass }} billing-address">
					<div class="payment-method">
						<mat-form-field appearance="fill">
							<mat-label>Saved Billing Details</mat-label>
							<mat-select [(value)]="selectedMethod">
								<mat-option [value]="this.paymentMethod.last_four" *ngIf="this.hasCard()">
									{{
									this.paymentMethod.card_type +
									' ending in ' +
									this.paymentMethod.last_four
									}}
								</mat-option>
								<mat-option [value]="'new'">
									Use New Card
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div *ngIf="selectedMethod !== 'new'" class="payment-terms">
						<div class="ctw-text-right alert alert-default terms-alert">
							<span *ngIf="hasRecurring">
								By ordering you are agreeing to our complete
								<a href="{{
										this.configurationService.config
											.brandConfig.termsOfService
									}}" target="_blank">Terms of Service</a>. You authorize us to charge your card now, and
								at the time of each renewal period. You can
								cancel this subscription at any time by
								e-mailing support@inmotionhosting.com.
							</span>
							<span *ngIf="!this.hasRecurring">
								By ordering you are agreeing to our complete
								<a href="{{
										this.configurationService.config
											.brandConfig.termsOfService
									}}" target="_blank">Terms of Service.</a>
							</span>
							<div class="error" *ngIf="invalidInputState(termsInput)">
								You must accept Terms of Service
							</div>
							<p>
								<mat-checkbox #termsInput="ngModel" name="accept-agreement"
									[(ngModel)]="this.termsAgree" class="accept-agreement" required>
									I Agree
								</mat-checkbox>
							</p>
						</div>
						<p class="ctw-text-right must-signin" [hidden]="
								!contactInputs.emailInput?.requiresSignin()
							">
							<mat-icon class="error">warning</mat-icon> You must
							<a class="sign-in" (click)="
									contactInputs.emailInput?.opendDialogWithErrors()
								">sign in</a>
							to submit an order for
							<strong>{{
								contactInputs.emailInput?.value || ''
								}}</strong>.
						</p>
						<div class="ctw-text-right action-buttons">
							<button *ngIf="includeBackButton" [disabled]="state === 'submitted'" mat-button mat-ripple
								type="button" (click)="this.paymentBack.emit()">
								Back
							</button>
							<central-progress-button color="primary" [disabled]="
									!!contactInputs.emailInput?.requiresSignin()
								" [state]="captchaSubmitted ? 'submitted' : ''">Submit Order</central-progress-button>
						</div>
					</div>
				</dash-card>
			</div>
			<dash-card [hidden]="selectedMethod !== 'new'" disabled="1" cardTitle="Billing Address" icon="location_on"
				class="{{ this.cardClass }} billing-address">
				<bg-contact class="bg-contact" #contactInputs [excludedInputs]="this.excludedInputs"
					[forceLogin]="this.forceLogin" [loginModalTitle]="this.loginModalTitle"
					[loginModalSubTitle]="this.loginModalSubTitle" [minimalForm]="this.minimalForm"></bg-contact>
			</dash-card>
			<dash-card [hidden]="selectedMethod !== 'new'" disabled="1" cardTitle="Payment Method" icon="credit_card"
				class="{{ this.cardClass }}">
				<img class="secure-server" src="assets/img/payment-icons/order-secure-server-lock.png"
					alt="Secure Server Lock" title="Secure Server Lock" />
				<div class="content">
					<central-recurly-credit-card class="central-recurly-credit-card"></central-recurly-credit-card>
					<div *ngIf="selectedMethod === 'new'" class="ctw-text-right alert alert-default terms-alert">
						<span *ngIf="hasRecurring">
							By ordering you are agreeing to our complete
							<a href="{{
									this.configurationService.config.brandConfig
										.termsOfService
								}}" target="_blank">Terms of Service</a>. You authorize us to charge your card now, and at
							the time of each renewal period. You can cancel this
							subscription at any time by e-mailing
							support@inmotionhosting.com.
						</span>
						<span *ngIf="!this.hasRecurring">
							By ordering you are agreeing to our complete
							<a href="{{
									this.configurationService.config.brandConfig
										.termsOfService
								}}" target="_blank">Terms of Service.</a>
						</span>
						<div class="error" *ngIf="invalidInputState(termsInput)">
							You must accept Terms of Service
						</div>
						<p>
							<mat-checkbox #termsInput="ngModel" name="accept-agreement" [(ngModel)]="this.termsAgree"
								class="accept-agreement" required>
								I Agree
							</mat-checkbox>
						</p>
					</div>
					<p class="ctw-text-right must-signin" [hidden]="!contactInputs.emailInput?.requiresSignin()">
						<mat-icon class="error">warning</mat-icon> You must
						<a class="sign-in" (click)="
								contactInputs.emailInput?.opendDialogWithErrors()
							">sign in</a>
						to submit an order for
						<strong>{{
							contactInputs.emailInput?.value || ''
							}}</strong>.
					</p>
					<div class="ctw-text-right action-buttons">
						<button *ngIf="includeBackButton" [disabled]="state === 'submitted'" mat-button mat-ripple
							type="button" (click)="this.paymentBack.emit()">
							Back
						</button>
						<central-progress-button color="primary"
							[disabled]="!!contactInputs.emailInput?.requiresSignin()"
							[state]="captchaSubmitted ? 'submitted' : ''">
							{{ this.isTrial() ? 'Start Trial' : 'Submit Order' }}
						</central-progress-button>
					</div>
					<re-captcha #captcha (resolved)="captchaSubmitted = false; submit($event)" class="g-recaptcha"
						size="invisible" captchaSubmitted siteKey="{{ captchaKey }}"></re-captcha>
				</div>
			</dash-card>
		</form>
	</div>
	<div class="col cart-column" [hidden]="!showForms()">
		<bg-cart *ngIf="!this.isPreviousOrder && this.packages" (cartValidation)="this.afterCartInit()"
			[term]="this.term" [packages]="this.packages" #cart [discountObject]="this.getDiscountObject()"
			[couponData]="this.getCouponData()" [isUpdatePlan]="this.isUpdatePlan"
			[subscriptionUuid]="this.subscriptionUuid" [organizationId]="this.organizationId"></bg-cart>
		<bg-discount #discount hidden></bg-discount>
		<div *ngIf="(!hideGuarantee && !this.isUpdatePlan) || this.isAddPlan" class="purchase-info">
			<div *ngIf="'monthly' === this.term">
				<p><strong>30-Day Money-Back Guarantee</strong></p>
				<p>
					Our industry leading money back guarantee allows you to have
					peace of mind and to try our services risk-free for 30 days.
				</p>
			</div>
			<div *ngIf="'yearly' === this.term">
				<p><strong>90-Day Money-Back Guarantee</strong></p>
				<p>
					Our industry leading money back guarantee allows you to have
					peace of mind and to try our services risk-free for 90 days.
				</p>
			</div>
		</div>
		<div *ngIf="!this.isAddPlan && this.getCouponData()" class="purchase-info">
			<p class="ctw-text-center">A credit card is required to experience the UltraStack VPS.</p>
			<p class="ctw-text-center">Don't want to use a card? <a (click)="this.skipPurchase()">Explore Platform
					InMotion</a> instead.</p>
		</div>
	</div>
</div>
<ng-container *ngIf="state === 'success' && !this.disablePostPurchase">
	<post-purchase [account]="this.organizationId" [project]="this.projectId" [returnWpUrl]="returnWpUrl"
		[products]="this.receipt.products" [receipt]="this.receipt.invoice">
		<p *ngIf="this.isNewUser">
			We've sent a confirmation email. Before you can log in to InMotion
			Central, you'll need to click the activation link within your email.
			If this address is incorrect, please
			<a [routerLink]="['/account/contact-info']">Update Your Email Address</a>
			now.
		</p>
	</post-purchase>
</ng-container>
