<div
	*ngIf="
		authService.profile.data.requires_email_verification &&
		displayAuthView()
	"
	[class.animate__slideInUp]="!dismissedNag"
	[class.animate__slideOutDown]="dismissedNag"
	class="nag-notice animate__animated"
>
	<span
		>Your email hasn't been verified yet. To keep your
		{{ brandingService.getBrandOpt('applicationName') }} account active,
		please click on the link in your welcome email.</span
	>
	<button mat-button mat-raised-button (click)="dismissedNag = true">
		Okay
	</button>
</div>

<central-sidebar [authenticated]="displayAuthView()">
	<div
		class="router-wrap"
		[class.guest-view]="!displayAuthView() && !this.disableGuestView"
	>
		<router-outlet></router-outlet>
	</div>
</central-sidebar>
