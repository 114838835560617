import { Component, ViewChild, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { HttpClient } from '@angular/common/http';
import { AccountService, ApiService, BrandingService, ConfigurationService } from '@central/ng-shared';

import { PaymentComponent } from '../../checkout/payment/payment.component';

import { Config } from '../../shared/config/env.config';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { DEFAULT_REGISTERABLE_TLDS } from '../domain-constants';

@Component({
	selector: 'central-domain-register',
	templateUrl: './domain-register.component.html',
	styleUrls: ['./domain-register.component.scss'],
})
export class DomainRegisterComponent implements OnInit {
	@ViewChild(PaymentComponent) public paymentComponent: PaymentComponent;

	public ownerAccountId = '';
	public isReady = true;
	public domainName = '';
	public domainAvailability = '';
	public DOMAIN_PRICE = 19.99;
	public domainPriceStatus = 'loading';
	public domainPrice;
	public domainTldsAllowed = [];
	public cachedDomainTldsAllowed;
	public state = 'pending';
	public searching = 0;
	public selected = [];
	public packages = [];
	public searched;
	public isOnboarding;
	private observer;

	public loginModalTitle = 'Sign-Up';
	public loginModalSubTitle = 'First, let\'s connect you to the Platform';

	public paymentSubmitted = false;
	message: string;
	subscription: Subscription;
	constructor(
		private httpClient: HttpClient,
		public accountService: AccountService,
		private apiService: ApiService,
		public route: ActivatedRoute,
		private configService: ConfigurationService,
		public brandingService: BrandingService,
	) {}

	ngOnInit(): void {
		this.accountService.authService.profile.onReady().subscribe(() => {
			this.isOnboarding =
				!this.accountService.authService.isLoggedIn() ||
				!this.accountService.authService.profile.data.onboarded;
		});

		this.domainName = this.route.snapshot.paramMap.get('domainName') ?? '';
		this.subscription = this.accountService.currentMessage.subscribe(
			(message) => {
				this.message = message;
			}
		);

		this.fetchTlds();
	}

	public fetchTlds() {
		const allowedTlds = this.configService.hasFeature('recommendedTlds')
			? 'recommended' : 'default'; // or all

		const url = `${Config['host']}/v1/domain-service/domain/tlds/${allowedTlds}`;
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.httpClient.get(url, { headers }).subscribe(
			(response: []) => {
				response.forEach(tld => {
					this.domainTldsAllowed.push({
						tld,
						availability: 'unknown',
						price: '',
						domainName: '',
						selected: false
					});
				});
			},
			(error) => {
				console.warn('Unable to fetch Registerable TLDs', error);
			},
			() => {
				this.fetchCatalog();
			});
	}

	public accountChanged($event) {
		setTimeout(() => {
			this.ownerAccountId = $event['account_id'];
			this.accountService.updateAccount($event['account_id']);
			this.isReady = true;
		}, 0);
	}

	public select(index, addToCart = true, event = null) {
		if (event && this.selected.length > 1) {
			event.stopPropagation();
		}
		if (addToCart) {
			const domain = this.domainTldsAllowed[index];
			this.domainTldsAllowed[index].selected = domain.selected ? false : true;
			if (this.domainTldsAllowed[index].selected) {
				this.selected.push({
					tld: domain.tld,
					availability: 'available',
					price: domain.price,
					domainName: domain.domainName,
					selected: true
				});
			} else {
				this.selected = this.selected.filter(tld => tld.tld !== domain.tld);
			}
		} else {
			const domain = this.selected[index];
			this.domainTldsAllowed.forEach((tld, idx) => {
				if (tld.domainName === domain.domainName) {
					this.domainTldsAllowed[idx].selected = false;
				}
			});
			this.selected = this.selected.filter(tld => tld.tld !== domain.tld);
		}
	}

	public checkout() {
		this.packages = [];
		this.selected.forEach((tld) => {
			this.packages.push({
				planCode: 'domain-gtld-1',
				options: {
					allows_multiple: false,
					allows_term_discount: false,
					domain_name: tld.domainName,
					price: tld.price * 100,
				},
			});
		})
		this.domainAvailability = 'available';
	}

	private search(domain, index) {
		const url =
			Config['host'] +
			'/v1/domain-service/domain/availability/' +
			domain;
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		this.searching++;
		setTimeout(() => {
			this.httpClient.get(url, { headers }).subscribe(
				(response) => {
					this.domainTldsAllowed[index].selected = this.selected.filter(tld => tld.tld === this.domainTldsAllowed[index].tld)[0]?.selected || false;
					if (response['is_success'] === '1') {
						if ('price' in response['attributes']) {
							this.domainTldsAllowed[index].availability = 'available';
							if (this.configService.hasFeature('dynamicTldPricing')) {
								this.domainTldsAllowed[index].price = parseFloat(response['attributes']['price']).toFixed(2);
							} else {
								this.domainTldsAllowed[index].price = this.DOMAIN_PRICE;
							}
						} else {
							if (
								'status' in response['attributes'] &&
								response['attributes']['status'] === 'taken'
							) {
								this.domainTldsAllowed[index].availability = 'taken';
							} else {
								this.domainTldsAllowed[index].availability = 'error';
							}
						}
					} else {
						this.domainTldsAllowed[index].availability = 'error';
					}
					this.searching--;

				},
				(error) => {
					console.log(error);
					this.domainTldsAllowed[index].availability = 'error';
					this.searching--;
				}
			);
		}, this.searching * 200);
	}

	updateDomainAvailability(data) {
		this.domainAvailability = '';
		if (this.configService.hasFeature('bulkTldSearch')) {
			if (data.domainName) {
				this.state = 'submitted';
				this.searched = data;
				this.observer = new IntersectionObserver((tlds, observer) => {
					tlds.map((tld) => {
						if (tld.isIntersecting && !tld.target.classList.contains('checked')) {
							this.search(
								tld.target.querySelector('span').textContent,
								parseInt(tld.target.id.replace('tld', ''), 10)
							);
							tld.target.classList.add('checked');
							observer.unobserve(tld.target);
						}
					})
				});

				this.domainTldsAllowed.forEach((tld, index) => {
					this.domainTldsAllowed[index].availability = 'pending';
					this.domainTldsAllowed[index].selected = false;
					this.domainTldsAllowed[index].domainName =
						data.domainName?.split('.')[0] + tld.tld;

					if (index === this.domainTldsAllowed.length - 1) {
						setTimeout(() => {
							// Check availability
							document.querySelectorAll('.tld').forEach(el => {
								this.observer.observe(el);
							})
						}, 1000);
					}
				});
			}
		} else {
			this.domainName = data.domainName;
			this.domainAvailability = data.domainAvailability;
			this.packages = [
				{
					planCode: 'domain-gtld-1',
					options: {
						allows_multiple: false,
						allows_term_discount: false,
						domain_name: this.domainName,
					},
				},
			];

			if (this.configService.hasFeature('dynamicTldPricing')) {
				this.domainPrice = this.DOMAIN_PRICE < parseFloat(data.domainPrice)
					? data.domainPrice
					: this.DOMAIN_PRICE;

				this.packages[0]['options']['price'] = this.domainPrice * 100;
			}

			this.paymentSubmitted = false;
		}
	}

	public paymentSubmit() {
		this.paymentSubmitted = true;
	}

	public fetchCatalog() {
		this.apiService.get('/v1/wpcr/catalog', []).subscribe(
			(catalog: any) => {
				this.DOMAIN_PRICE =
					catalog.filter((plan) => plan.code === 'domain-gtld-1')[0]
						.price_in_cents / 100;
				this.domainPriceStatus = 'success';
			},
			() => {
				this.domainPriceStatus = 'failed';
			}
		);
	}
}
