const config: any = {
	menuOptions: [
		{
			id: 'install',
			label: 'Install',
			icon: 'cloud_upload',
		},
		{
			id: 'details',
			label: 'Details',
			icon: 'format_list_bulleted',
		},
		{
			id: 'edit',
			label: 'Edit',
			icon: 'edit',
		},
		/*{
			id: 'share',
			label: 'Share',
			icon: 'share',
		},*/
		{
			id: 'delete',
			label: 'Delete',
			icon: 'delete',
		},
	],
};
export default config;
