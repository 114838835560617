<mat-card class="warning complete-notification card-small">
	<div class="animate__animated animate__fadeIn">
		<h3 class="">Oops! That's not right.</h3>
		<h4 class="">Something went wrong.</h4>
		<p>
			We're sorry. We're working on getting this fixed as soon as we can.
			Try again and see if this corrects your problem.
		</p>
		<button
			mat-raised-button
			mat-ripple
			type="button"
			color="primary"
			(click)="this.reload()"
		>
			Okay
		</button>
	</div>
</mat-card>
