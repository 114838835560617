import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ApiService } from '../../core';

export interface DeviceOption {
	name: string;
	message: string;
}

export enum TwoFaState {
	sendCode = 'sendCode',
	validateCode = 'validateCode',
	submitted = 'submitted',
	failure = 'failure',
}

export interface TwoFaUserData {
	username: string;
	invalidCode: boolean;
	deviceOptions: DeviceOption[];
	authData: any;
}

@Component({
	selector: 'central-two-factor-auth',
	templateUrl: './two-factor-auth.component.html',
	styleUrls: ['./two-factor-auth.component.scss'],
})
export class TwoFactorAuthComponent implements OnInit {
	constructor(private apiService: ApiService) {}
	@ViewChild('twoFaCode') public twoFaCode: any;

	@Input() public twoFaUserData: TwoFaUserData;
	@Input() public inputAppearance = 'outline';
	@Input() public action: string;
	@Input() public autoSend = false;
	@Input() public selection: string;
	@Input() public showDeviceSelection: boolean;
	@Input() public showBtnSpinner = true;
	@Input() public showRememberMe = true;
	@Input() public state: TwoFaState = TwoFaState.sendCode;
	@Input() public enableBackup = false;
	@Input() public customRetry = false;
	public useBackup = false;

	public codeFailure;
	public email: string;
	public deviceOptions: DeviceOption[];
	public defaultDevice: string;
	public sendCodeError: string;

	@Output() public retry = new EventEmitter();
	@Output() public twoFaSubmit = new EventEmitter();
	@Output() public twoFaCancel = new EventEmitter();

	public resendIsDisabled = true;
	public model = {
		deviceSelection: null,
		rememberMetwoFa: true,
		twoFaCode: '',
	};

	ngOnInit(): void {
		this.email = this.getUsername();
		this.enableResend();
		this.model.deviceSelection = this.twoFaUserData.deviceOptions[0];

		if (!this.showDeviceSelection) {
			if (this.autoSend) {
				this.sendCode(this.model.deviceSelection);
			}
			this.state = TwoFaState.validateCode;
		} else {
			this.state = TwoFaState.sendCode;
		}
	}

	/**
	 * Submit the form to validate the twoFa code
	 *
	 * @since 3.49.0
	 *
	 */
	public submit(): void {
		if (!this.twoFaCode.valid) {
			this.twoFaCode.control.markAsTouched();
			this.twoFaCode.control.markAsDirty();
		} else {
			this.twoFaSubmit.emit({
				multifactor: {
					code: this.model.twoFaCode,
					remember: this.model.rememberMetwoFa,
				},
			});
		}
	}
	/**
	 * Trigger code resend
	 *
	 * @since 3.49.0
	 *
	 */
	public triggerCode(selection) {
		if (this.customRetry) {
			this.retry.emit();
			this.resendIsDisabled = true;
			this.enableResend();
		} else {
			this.sendCode(selection);
		}
	}
	/**
	 * Make API call to resend a twoFa code
	 *
	 * @since 3.49.0
	 *
	 */
	public sendCode(selection = null) {
		this.state = TwoFaState.submitted;
		this.model.deviceSelection = selection
			? selection
			: this.model.deviceSelection;
		this.enableResend();
		this.apiService
			.post('/v1/two-factor', {
				type: this.getDeviceShortname(this.model.deviceSelection),
				username: this.email,
				token: this.twoFaUserData.authData?.token,
				provider: this.twoFaUserData.authData?.providerName,
				method: this.action,
			})
			.subscribe(
				() => {
					this.resendIsDisabled = true;
					this.enableResend();
					this.state = TwoFaState.validateCode;
				},
				(error) => {
					this.codeFailure = error?.errors[0] || [];
				}
			);
	}
	public getDeviceShortname(device) {
		return device['name'].split('_')[2];
	}
	/**
	 * Does a given field have an error.
	 *
	 * @since 1.17.0
	 *
	 * @param  field Angular form field.
	 * @return       SHould show error?
	 */
	public fieldHasError(field: any): boolean {
		return (
			!field.valid &&
			field.dirty &&
			field.touched &&
			this.state !== 'submitted'
		);
	}

	/**
	 * Handle Cancel button press
	 */
	public handleCancel() {
		if (!this.useBackup) {
			this.twoFaCancel.emit();
		} else {
			this.useBackup = false;
		}
	}

	/**
	 * Update state to validate code component when verification code is successfully sent
	 *
	 * @since 3.49.1
	 *
	 */
	public handleSendCodeSuccess() {
		this.state = TwoFaState.validateCode;
	}

	/**
	 * Return username if passed into component
	 *
	 * @since 3.49.0
	 *
	 * @returns username
	 */
	private getUsername() {
		return this.twoFaUserData.username;
	}

	/**
	 * 30 second delay to enable the resend twoFa code button
	 */
	private enableResend() {
		setTimeout(() => {
			this.resendIsDisabled = false;
		}, 30 * 1000);
	}
}
