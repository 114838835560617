import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@central/ng-shared';
import { ViewComponent } from '../view/view.component';

@Component({
	selector: 'bgc-snap-save',
	templateUrl: './save.component.html',
	styleUrls: ['./save.component.scss'],
})
export class SaveComponent implements OnInit {
	@ViewChild('existingSnap') public existingSnap: ViewComponent;
	public currentEnvironment = false;
	public status = 'pending';
	public loadExistingSnap = false;
	public snap: object;
	public view = 'wizard';
	public savedSnapData = { themes: [], plugins: [] };

	constructor(private route: ActivatedRoute, public apiService: ApiService) {}

	public ngOnInit(): void {
		this.route.params.subscribe((params) => {
			if (params['snapId']) {
				this.fetchSnap(params['snapId']);
			} else {
				this.status = 'success';
			}
		});

		this.route.queryParams.subscribe((params) => {
			this.currentEnvironment = !!parseInt(params['current_env'], 10);
		});
	}

	public applyExistingConfig(wpData: object) {
		this.savedSnapData.plugins = wpData['plugins'] || [];
		this.savedSnapData.plugins = this.savedSnapData.plugins.filter(
			(val) => val.slug
		);
		this.savedSnapData.plugins = this.savedSnapData.plugins.concat(
			this.snap['config'].plugins.filter((val) => val.type === 'custom')
		);

		this.savedSnapData.themes = wpData['themes'] || [];
		this.savedSnapData.themes = this.savedSnapData.themes.filter(
			(val) => val.slug
		);
		this.savedSnapData.themes = this.savedSnapData.themes.concat(
			this.snap['config'].themes.filter((val) => val.type === 'custom')
		);
	}

	/**
	 * Get current Page Tittle.
	 *
	 * @since 1.26.0
	 *
	 * @return Title for page.
	 */
	public getPageTitle(): string {
		let title = 'Save a Snap';
		if (this.loadExistingSnap && this.snap) {
			title = 'Updating - ' + this.snap['label'];
		}

		return title;
	}

	/**
	 * Fetch Fetch the snap.
	 *
	 * @since 1.26.0
	 *
	 * @param snapId Snap Number
	 */
	private fetchSnap(snapId: string): void {
		this.apiService.get('getSnap', { snap_id: snapId }).subscribe(
			(response) => {
				this.snap = response;
				this.status = 'success';
				this.loadExistingSnap = true;
			},
			() => (this.status = 'failed')
		);
	}
}
