<div class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray">
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<h2>Domains</h2>
		<div>
			<button mat-raised-button color="primary" [routerLink]="['./register']" *ngIf="
					'loading' !== thirdPartyRequestState &&
					'loading' !== firstPartyRequestState
				" class="animate__animated animate__fadeIn">
				<mat-icon>search</mat-icon> Start New Search
			</button>
		</div>
	</div>
	<mat-progress-bar *ngIf="
			'loading' === thirdPartyRequestState ||
			'loading' === firstPartyRequestState
		" mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-px-5 content domain-management" *ngIf="
		'loading' !== thirdPartyRequestState &&
		'loading' !== firstPartyRequestState
	">
	<dash-card cardTitle="DNS Management" icon="dns" disabled="true"
		[subTitle]="['Point your domain to our DNS for easier site management']">
		<mat-progress-spinner *ngIf="
				'loading' === thirdPartyRequestState ||
				'loading' === firstPartyRequestState
			" class="component-loader" mode="indeterminate" color="primary" diameter="50"></mat-progress-spinner>
		<div class="" *ngIf="
				'loading' !== thirdPartyRequestState &&
				'loading' !== firstPartyRequestState
			">
			<div class="flex-row">
				<div class="table-header">
					<p></p>
				</div>
				<div class="ctw-mt-4 ctw-mr-4 new">
					<button mat-stroked-button color="primary" (click)="this.addThirdPartyDomain()"
						[disabled]="'succeeded' !== thirdPartyRequestState">
						<mat-icon class="ctw-relative ctw-pb-8 ctw-pr-3 ctw-text-2xl mat-primary">add</mat-icon>
						Add Domain
					</button>
				</div>
			</div>

			<failed-request *ngIf="
					'failed' === thirdPartyRequestState ||
					'failed' === firstPartyRequestState
				"></failed-request>

			<ng-container *ngIf="
					'succeeded' === thirdPartyRequestState &&
					'succeeded' === firstPartyRequestState
				">
				<p *ngIf="!allDomains.length" class="empty-label ctw-mb-16">
					No DNS Zones
				</p>

				<table mat-table [dataSource]="allDomains" *ngIf="allDomains.length">
					<ng-container matColumnDef="domain">
						<th mat-header-cell *matHeaderCellDef>Domain</th>
						<td mat-cell *matCellDef="let element">
							{{ element.domainName }}
						</td>
					</ng-container>

					<ng-container matColumnDef="owner">
						<th mat-header-cell *matHeaderCellDef>Owner</th>
						<td mat-cell *matCellDef="let element">
							{{ getAccountNameFromId(element.accountId) }}
						</td>
					</ng-container>

					<ng-container matColumnDef="manage">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element; index as i" class="no-wrap">
							<ng-container *ngIf="
									'inactive' === thirdPartyDeleteRequestState
								">
								<ng-container *ngIf="null !== element.id"><button mat-button color="primary"
										[routerLink]="[
											'./dns-manager/',
											element.id
										]">
										Edit DNS</button><button mat-button color="warn" (click)="
											deleteThirdPartyDomain(element, i)
										">
										Delete
									</button></ng-container>
								<ng-container *ngIf="null === element.id"><button mat-button color="primary"
										[routerLink]="[
											'./',
											element.domainName,
											'dns-manager'
										]">
										Edit DNS</button><button [class.hide]="null === element.id" mat-button
										color="warn">
										Delete
									</button></ng-container>
							</ng-container>
							<ng-container *ngIf="
									'loading' ===
										thirdPartyDeleteRequestState &&
									i === deletingRecordIndex
								">
								<mat-progress-spinner class="inline-spinner" mode="indeterminate" color="primary"
									diameter="16"></mat-progress-spinner>
								Deleting...
							</ng-container>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				</table>
			</ng-container>
		</div>
	</dash-card>
	<p class="ctw-ml-2">
		<strong>Managing your own DNS?</strong> Locate your production server's
		IP address from the Project Dashboard.
	</p>

	<dash-card cardTitle="Domain Registrations" class="ctw-block ctw-mt-12" icon="language" disabled="true"
		[subTitle]="['Register domains through Platform I']">
		<mat-progress-spinner *ngIf="'loading' === firstPartyRequestState" class="component-loader" mode="indeterminate"
			color="primary" diameter="50"></mat-progress-spinner>
		<div class="" *ngIf="'loading' !== firstPartyRequestState">
			<div class="ctw-text-right ctw-mt-4 ctw-mr-4 new">
				<!-- <button mat-raised-button [routerLink]="['./transfer']"><mat-icon>swap_horiz</mat-icon> Transfer</button> -->
				<button mat-stroked-button color="primary" [routerLink]="['./register']">
					<mat-icon class="ctw-relative ctw-pb-8 ctw-pr-3 ctw-text-2xl mat-primary">add</mat-icon>
					New Registration
				</button>
			</div>

			<failed-request *ngIf="'failed' === firstPartyRequestState"></failed-request>

			<ng-container *ngIf="'succeeded' === firstPartyRequestState">
				<p *ngIf="!firstPartyDomains.length" class="empty-label ctw-mb-16">
					No Registered Domains
				</p>

				<table mat-table [dataSource]="firstPartyDomains" *ngIf="firstPartyDomains.length">
					<ng-container matColumnDef="domain">
						<th mat-header-cell *matHeaderCellDef>Domain</th>
						<td mat-cell *matCellDef="let element">
							{{ element.name }}
						</td>
					</ng-container>

					<ng-container matColumnDef="owner">
						<th mat-header-cell *matHeaderCellDef>Owner</th>
						<td mat-cell *matCellDef="let element">
							{{ getAccountNameFromId(element.account_id) }}
						</td>
					</ng-container>

					<ng-container matColumnDef="manage">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<button mat-button color="primary" [routerLink]="['./', element.name]">
								Manage
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				</table>
			</ng-container>
		</div>
	</dash-card>
</div>
