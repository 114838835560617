import crio from './crio';
import crioPremium from './crio-premium';
import free from './free';
import premium from './premium';

// Cloud WordPress
import cwp1 from './cwp1-pack';
import cwp2 from './cwp2-pack';
import cwp3 from './cwp3-pack';

// Speedcoach
import speedcoach1 from './speedcoach-1';
import speedcoach2 from './speedcoach-2';
import speedcoach3 from './speedcoach-3';

// Premium Plugins
import backupPremium from './backup-premium';
import editorPremium from './ppb-premium';

export default [
	free,
	backupPremium,
	editorPremium,
	premium,
	crio,
	crioPremium,
	cwp1,
	cwp2,
	cwp3,
	speedcoach1,
	speedcoach2,
	speedcoach3,
];
