import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService, ApiService } from '@central/ng-shared';

@Component({
	selector: 'receipt-view',
	templateUrl: 'view.component.html',
	styleUrls: ['view.component.scss'],
})
export class ReceiptViewComponent implements OnInit {
	public sub: any;
	public receiptId: string;
	public receipt: any;
	public status = 'pending';

	constructor(
		private apiService: ApiService,
		private accountService: AccountService,
		private route: ActivatedRoute
	) {}

	public ngOnInit() {
		this.route.params.subscribe((params) => {
			this.receiptId = params['receiptId'];

			if (this.receiptId.startsWith('BG-')) {
				this.getReceipt();
			} else {
				this.getInvoice();
			}
		});
	}

	private getInvoice() {
		this.apiService
			.get(
				`/v1/wpcr/accounts/${this.accountService.id}/invoices/${this.receiptId}`,
				{}
			)
			.subscribe({
				next: (invoice: any[]) => {
					this.receipt = invoice;
					this.status = 'success';
				},
				error: () => {
					this.status = 'failed';
				},
			});
	}

	private getReceipt() {
		this.apiService
			.post('receiptGet', { order_number: this.receiptId })
			.subscribe(
				(response) => {
					const result = response['result'];

					if (result && result['data'] && result['data']['success']) {
						this.receipt = result['data']['receipt'];
						this.status = 'success';
					} else {
						this.status = 'fail';
					}
				},
				() => (this.status = 'fail')
			);
	}
}
