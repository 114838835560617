import { Injectable } from '@angular/core';
import { ConfigurationService } from '../../core/configuration.service';

@Injectable()
export class OptimizelyService {
	public eventActionPrefix = '';
	public pageId;

	constructor(private configService: ConfigurationService) {
		this.eventActionPrefix =
			this.configService.config['optimizely']?.eventActionPrefix ||
			this.eventActionPrefix;

		this.pageId = this.configService.config.optimizely?.pageId;
	}

	public appendScript(trackingService) {
		trackingService.scriptService.load('optimizely');
	}

	/**
	 * Track a new user signup.
	 *
	 * @since 1.2
	 */
	public signup() {
		this.send('trackEvent', 'sign-up');
	}

	/**
	 * Track user signins.
	 *
	 * @since 1.2
	 */
	public signin() {
		this.send('trackEvent', 'sign-in');
	}

	/**
	 * Send cloud install event.
	 *
	 * @since 1.7.0
	 */
	public cloudInstall(): void {
		this.send('trackEvent', 'cloud-wp-install');
	}

	/**
	 * Email Confirmed.
	 *
	 * @since 1.7.0
	 */
	public cloudActivated(): void {
		this.send('trackEvent', 'email-confirm');
	}

	/**
	 * Send purchase events
	 *
	 * @since 1.7.0
	 */
	public purchase(data: any): void {

		// Only do custom events for Platform I.
		if (this.configService.config.appPrefix === 'imc') {
			const products = data.response.products;

			// Loop through each product to create dynamic event names.
			for (const product of products) {
				const event = this.getProductType(product) + '-' + this.getProductLine(product) + '-' + product.code + '-' + this.getTermLength(products, product) + '-sale';
				this.send('trackEvent', event);
			}
		}

		// It is crucial that you pass this value to Optimizely in cents, otherwise your revenue totals will be incorrect.
		if (+data.response.total) {
			this.send('trackEvent', 'revenue', {
				revenue: data.response.total * 100,
			});
		}
	}

	/**
	 * Get the product type of a singular product.
	 *
	 * @param product The singular product to get the product type for.
	 */
	public getProductType(product) {
		return 'hosting' === product.type ? product.type : 'addon';
	}

	/**
	 * Get the product line of a singular product
	 *
	 * @param product The singular product to get the product line for.
	 */
	public getProductLine(product) {
		let productLine = product.type;

		// "wpc" for Connected Websites and Connect Keys.
		if ( ( 'service' === product.type && product?.subtype === 'key' ) || product.code.startsWith( 'wpc_project_' ) ) {
			productLine = 'wpc';
		}

		// "cwp" for Playground Environment Purchases.
		if ( ( 'service' === product.type && product?.service?.category === 'cloud-wordpress' ) ) {
			productLine = 'cwp';
		}

		return productLine;
	}

	/**
	 * Get the term length of a product.
	 *
	 * @param products All products being sent from cart.
	 * @param product  The singular product to check for termLength first.
	 */
	public getTermLength(products, product) {
		let termLength = 0;

		if ( product?.plan_interval_unit ) {
			termLength = product.plan_interval_length;
		}

		// No termlength specified in data, so check a subscription item and use that since they coincide.
		if ( ! termLength ) {
			termLength = products.filter(item => item.type === 'subscription')[0].plan_interval_length;
		}

		return termLength;
	}

	/**
	 * Get the desired user experience for active experiments.
	 *
	 * @param   experiment Name of the experiment.
	 */
	public getExperiment(experiment: string) {
		let variation = 'Original';

		if (this.pageId && typeof window['optimizely']?.get === 'function') {
			// Fetch active campaigns
			const campaigns = window['optimizely'].get('data').campaigns;
			try {
				Object.entries(campaigns)
					.forEach((campaign: any) => {
						if (campaign[1].pageIds.includes(this.pageId) &&
							campaign[1].name === experiment) {
							// Get the name of the variation the user has been assigned to
							variation = window['optimizely'].get('state').getVariationMap()[
								campaign[1].experiments[0].id
							].name;
						}
					});

				return variation;
			} catch (error) {
				console.log(error);
			}
		}

		return variation;
	}

	/**
	 * Send the data.
	 *
	 * Simply a wrapper of the optimizely method.
	 *
	 * @since 1.2
	 *
	 * @param   method    Tracking string, usually 'track'.
	 * @param   eventName Event to track.
	 */
	public send(method: string, eventName: string, data?: object) {
		if (window['optimizely']) {
			const action = this.eventActionPrefix + eventName;
			const payload: any[] = [method, action];

			if (data) {
				payload.push(data);
			}

			window['optimizely'].push(payload);
		}
	}
}
