import { ListComponent } from './list/list.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MomentModule } from 'ngx-moment';
import { SaveComponent } from './save/save.component';
import { ThemeSelectorComponent } from './theme-selector/theme-selector.component';
import { CustomUrlComponent } from './custom-url/custom-url.component';
import { PluginSelectorComponent } from './plugin-selector/plugin-selector.component';
import { PluginSheetComponent } from './plugin-selector/plugin-sheet/plugin-sheet.component';
import { SaveNewComponent } from './save-new/save-new.component';
import { RatingComponent } from './rating/rating.component';
import { DetailsComponent } from './details/details.component';
import { ViewComponent } from './view/view.component';
import { FilterNavComponent } from './filter-nav/filter-nav.component';
import { AuthenticationModule } from '@central/ng-shared';
import { InstallComponent } from './install/install.component';

@NgModule({
	imports: [
		AuthenticationModule,
		CommonModule,
		SharedModule,
		MomentModule,
	],
	declarations: [
		ListComponent,
		SaveComponent,
		ThemeSelectorComponent,
		SaveNewComponent,
		PluginSelectorComponent,
		ThemeSelectorComponent,
		DetailsComponent,
		CustomUrlComponent,
		RatingComponent,
		FilterNavComponent,
		ViewComponent,
		PluginSheetComponent,
		InstallComponent,
	],
	exports: [PluginSelectorComponent, ThemeSelectorComponent],
})
export class CloudSnapModule {}
