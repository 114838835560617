import {
	Component,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	ElementRef,
	OnDestroy,
	OnInit,
} from '@angular/core';
import _debounce from 'lodash/debounce';

@Component({
	selector: 'bgc-filter-nav',
	templateUrl: './filter-nav.component.html',
	styleUrls: ['./filter-nav.component.scss'],
})
export class FilterNavComponent implements OnInit, OnDestroy {
	@ViewChild('tools', { read: ElementRef }) public tools: ElementRef;

	@Input() public steps = ['back', 'next'];
	@Input() public filters = [];

	@Output() public back = new EventEmitter();
	@Output() public next = new EventEmitter();
	@Output() public search = new EventEmitter();

	public view: any;
	public debouncedScroll: any;
	public query = '';
	public debouncedSearch: any;
	public showSticky = false;

	public constructor() {
		this.debouncedSearch = _debounce(() => {
			this.view = null;
			this.search.emit({
				search: this.query ? '&request[search]=' + this.query : '',
			});
		}, 500);

		this.debouncedScroll = _debounce(() => {
			const position = this.tools.nativeElement.getBoundingClientRect();
			this.showSticky = position.y < 35;
		}, 50);
	}

	public changeFilter(filter: object): void {
		if (filter !== this.view) {
			this.view = filter;
			this.query = '';
			this.search.emit(filter);
		}
	}

	public ngOnInit(): void {
		setTimeout(() => this.setupStickyNav());

		this.view = this.filters.find((val) => val.default);
	}

	public ngOnDestroy(): void {
		window.removeEventListener('scroll', this.debouncedScroll);
	}

	public setupStickyNav(): void {
		window.addEventListener('scroll', this.debouncedScroll);
	}
}
