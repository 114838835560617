export default [
	{
		label: 'Premium Connect Account',
		type: 'separator',
		desc: '',
	},
	{
		label: 'Premium Support',
		desc: '',
	},
	{
		label: 'Attribution Removal',
		desc: '',
	},
];
