import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { RecaptchaModule } from 'ng-recaptcha';
import { CustomFormsModule } from 'ngx-custom-validators';

// Modules.
import { SharedModule } from '../shared/shared.module';

import { LoginComponent } from './login/login.component';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { GuestComponent } from './guest.component';
import { CheckoutComponent } from './index';
import { ResetSentComponent } from './reset-sent/reset-sent.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';

// Sign Up.
import { SignupConfirmComponent } from './signup-confirm/signup-confirm.component';

// Confirmation.
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConfirmationService } from './confirmation/confirmation.service';
import { LogoutComponent } from './logout/logout.component';

import { CheckoutModule } from '../checkout/checkout.module';
import { AppAuthComponent } from './app-auth/app-auth.component';
import { AuthenticationModule } from '@central/ng-shared';
import { InviteComponent } from './invite/invite.component';

@NgModule({
	imports: [
		CommonModule,
		AuthenticationModule,
		SharedModule,
		FormsModule,
		CustomFormsModule,
		RecaptchaModule,
		MomentModule,
		CheckoutModule,
		MatPasswordStrengthModule,
	],
	declarations: [
		LoginComponent,
		ResetSentComponent,
		InviteComponent,
		ConfirmationComponent,
		CheckoutComponent,
		GuestComponent,
		UpdatePasswordComponent,
		ForgotPasswordComponent,
		SignupConfirmComponent,
		LogoutComponent,
		AppAuthComponent,
	],
	providers: [ConfirmationService],
})
export class GuestModule {}
