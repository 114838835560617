<ng-container *ngIf="this.status !== 'failed' && !this.data?.error">
	<h3>Permanently Delete {{ data.label || 'item' }}?</h3>
	<p *ngIf="data.warning">{{ data.warning }}</p>
	<ng-container *ngIf="data.requireNameConfirmation">
		<p>
			Confirm this action by typing the {{ data.label || 'item' }}'s name:
			<strong>{{ data.nameConfirmation }}</strong>
		</p>
		<mat-form-field appearance="outline">
			<mat-label>{{ data.label || 'Item' }} Name</mat-label>
			<input
				autocomplete="off"
				#confirmationInput="ngModel"
				matInput
				name="confirmation"
				[(ngModel)]="this.confirmation"
				required
			/>
			<mat-hint
				class="error"
				*ngIf="
					this.confirmation.toLowerCase().trim() !==
						data?.nameConfirmation.toLowerCase().trim() &&
					confirmationInput.dirty &&
					confirmationInput.touched
				"
			>
				The entered value does not match your
				{{ data.label || 'item' }} name.
			</mat-hint>
		</mat-form-field>
	</ng-container>
	<div class="actions ctw-text-right">
		<button
			mat-button
			class="ctw-mr-2"
			[disabled]="status === 'submitted'"
			(click)="this.matDialogRef.close()"
		>
			Cancel
		</button>
		<central-progress-button
			class="right"
			[state]="this.status"
			[disabled]="
				data.requireNameConfirmation &&
				this.confirmation.toLowerCase().trim() !==
					data?.nameConfirmation.toLowerCase().trim()
			"
			(click)="this.doDelete()"
			color="warn"
			>Delete</central-progress-button
		>
	</div>
</ng-container>

<!-- Error is passed into delete dialog. -->
<ng-container *ngIf="this.data?.error">
	<h3>Error Deleting {{ data.label || 'Item' }}</h3>
	<p class="alert ctw-mb-6">
		<strong class="error">WARNING</strong>: {{ this.data.error }}
	</p>

	<button
		mat-raised-button
		class="right"
		color="primary"
		(click)="this.matDialogRef.close()"
	>
		OK
	</button>
</ng-container>

<!-- Error performing delete from dialog. -->
<ng-container *ngIf="this.status === 'failed' && !this.data?.error">
	<h3>An unexpected error occurred</h3>
	<p>We were not able to delete your item. Please try again later.</p>
	<button
		mat-raised-button
		class="right"
		color="primary"
		(click)="this.matDialogRef.close()"
	>
		OK
	</button>
</ng-container>
