export default {
	name: 'speedcoach-tier-1-yearly',
	productKeys: ['speedc_tier1'],
	licenseNames: [],
	label: '900 Scans / Month',
	color: 'secondary-accent',
	tags: ['speedcoach', 'premium'],
	features: [
		{
			label: 'Speed Coach Starter',
			type: 'separator',
		},
		{
			label: '900 Additional Scans',
		},
	],
};
