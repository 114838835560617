import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ProjectService } from '../../project.service';
import { ApiService } from '@central/ng-shared';
import { HttpClient } from '@angular/common/http';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
	selector: 'central-project-details-dialog',
	templateUrl: './project-details-dialog.component.html',
	styleUrls: ['./project-details-dialog.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDetailsDialogComponent {
	public type = '';
	public state = 'loading';
	public status = 'loading';
	public resource: object;
	public originalResource: object;

	constructor(
		public projectService: ProjectService,
		private apiService: ApiService,
		public httpClient: HttpClient,
		private snackBar: MatSnackBar
	) {
		this.originalResource = this.projectService.appService.project.label;
		this.type = this.projectService.appService.project.fields?.project_type
			.replace(/wordpress/, 'WordPress')
	}

	/**
	 * Revert to original.
	 *
	 * @since 1.19.0
	 */
	public reset(): void {
		this.projectService.appService.project.label = this.originalResource;
		this.status = 'success';
	}

	public save() {
		this.status = 'submitted';

		const url = this.apiService.formatter.getUrl(
			'/v1/projects/' + this.projectService.appService.project.id
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.httpClient
			.patch(
				url,
				{
					label: this.projectService.appService.project.label.trim(),
					description:
						this.projectService.appService.project.description.trim(),
				},
				{ headers }
			)
			.subscribe(
				(projectUpdated) => {
					this.status = 'success';
					this.projectService.appService.project.label =
						projectUpdated['label'];
					this.projectService.appService.project.fields =
						projectUpdated['fields'];

					this.snackBar.open('Rename Successful', '', {
						duration: 3000,
						horizontalPosition: 'center',
					});
				},
				() => {
					this.status = 'failed';
				}
			);
	}
}
