import crioPremium from '../products/crio/premium';

export default {
	name: 'crio',
	productKeys: ['wpc_crio'],
	licenseNames: ['crio'],
	label: 'Crio SuperTheme',
	color: 'accent',
	tags: ['crio', 'premium'],
	features: [...crioPremium],
};
