<div class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray">
	<div class="column ctw-mx-auto ctw-max-w-6xl">
		<h2>New Email Subscription</h2>
		<div></div>
	</div>

	<mat-progress-bar *ngIf="!this.isReady" mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-px-5 ctw-mb-12 ctw-pt-16 content"
	[class.ctw-hidden]="!this.isReady">
	<ng-container *ngIf="!checkoutComplete && this.state === 'pending'">
		<h4 class="ctw-text-center ctw-mb-4">
			Confirm the quantity of email accounts to begin with
			before you checkout.
		</h4>
	</ng-container>
	<payment-form [packages]="packages" [organizationId]="ownerAccountId" [hideGuarantee]="true"
		(paymentBack)="router.navigateByUrl('/email')" (paymentSubmit)="this.state = 'processing'"
		(paymentSuccess)="paymentSucceeded()" (paymentFailure)="this.state = 'pending'"
		(paymentReady)="this.isReady = true"></payment-form>
</div>
