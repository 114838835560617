import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { DomainThirdPartyAddDialogComponent } from '../domain-third-party-add-dialog/domain-third-party-add-dialog.component';
import { DomainValidator } from './domain.validator';

@Component({
	selector: 'central-domain-add-shared',
	templateUrl: './domain-add-shared.component.html',
	styleUrls: ['./domain-add-shared.component.scss'],
})
export class DomainAddSharedComponent {
	@Input() public organizationId: string;

    public domainName = '';
    public domainInvalid = '';
    public domainStatus = '';
	public progressButtonState = '';
	public domainValidator = new UntypedFormControl('', [
        this.domainIsValid.isValid.bind(this),
        this.domainIsTaken.bind(this)
	]);

    @Output() domainAddedEvent = new EventEmitter<object>();
    @Output() domainUpdated = new EventEmitter<object>();

	constructor(
        private domainIsValid: DomainValidator,
        private addDomain: DomainThirdPartyAddDialogComponent,
    ) {
        this.domainIsValid.domain.subscribe((msg: any) => {
            this.domainUpdated.emit(msg);
            if (msg.invalidDomain !== '') {
                this.domainStatus = '';
                this.domainValidator.markAsPristine();
            }
        });
        this.addDomain.dnsAuthority.subscribe((msg: any) => {
            this.domainStatus = msg;
            this.domainInvalid = this.domainName;
            this.domainValidator.markAsDirty();
            this.domainName = '';
            
        })
    }

    domainIsTaken(control: AbstractControl): { [key: string]: boolean } | null {
        if (this.domainStatus !== '') {
            return { 'taken': true };
        }
    }
}
