import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, AuthService, PollingService } from '@central/ng-shared';
import { HubspotService } from 'libs/ng-shared/src/lib/tracking/hubspot/hubspot.service';

import _uniq from 'lodash/uniq';
import { CatalogSearch } from '../catalog/catalog-search';
import { Payload } from './payload';

interface ICartItem {
	label: string;
	quantity: number;
	product_key: string;
	name: string;
	price_in_cents: number;
	code: string;
	transaction_type: string;
	term: any;
	ref_code: string;
	options: any;
}

@Component({
	selector: 'bg-cart',
	templateUrl: 'cart.component.html',
})
export class CartComponent implements OnChanges, OnDestroy {
	@Input() public packages: any;
	@Input() public discountResource: any;
	@Input() public lockCartQuantity = false;
	@Input() public staticPackages = [];
	@Input() public term = 'monthly';
	@Input() public discountObject: any;
	@Input() public couponData: any;
	@Input() public isUpdatePlan: false;
	@Input() public subscriptionUuid: string;
	@Input() public organizationId: string;

	@Output() public cartValidation = new EventEmitter();
	@Output() public cartUpdated = new EventEmitter();

	private hubspotDeal;

	public displayedColumns: string[] = ['quantity', 'product', 'price'];
	public dataSource: any[] = [];
	public cart: ICartItem[] = [];
	public total = '';
	public subTotal = '';
	public discount = {};
	public hasDiscount = false;
	public allowsTermDiscount = true;
	public updateCredit = 0;
	public fetchedCredit = false;
	public lineItems;
	public status = 'loading';
	public subtitle = '';
	public catalog;
	public catalogFetched = false;
	public monthlyEnabled = true;
	public yearlyEnabled = true;
	public packageSubtitles = {
		'boldgrid-backup': 'Total Upkeep Premium',
		'post-and-page-builder': 'Post and Page Builder Premium',
		'cloud-wordpress': 'Cloud WordPress Premium',
	};

	public packageMap = {
		premium: ['wpc_premium'],
		crio: ['wpc_crio'],
		'crio-bundle': ['wpc_premium', 'wpc_crio'],
		'cloud-wordpress': ['wpc_premium'],
		'premium-boldgrid-backup': ['wpc_premium'],
		'premium-post-and-page-builder': ['wpc_premium'],
		'boldgrid-backup': ['wpc_tukp'],
		'post-and-page-builder': ['wpc_ppbp'],
		'10_coin': ['10_coin'],
		'20_coin': ['20_coin'],
		'40_coin': ['40_coin'],
		'speedcoach-tier-1-monthly': ['speedc_tier1_monthly'],
		'speedcoach-tier-1-yearly': ['speedc_tier1_yearly'],
		'speedcoach-tier-2-monthly': ['speedc_tier2_monthly'],
		'speedcoach-tier-2-yearly': ['speedc_tier2_yearly'],
		'speedcoach-tier-3-monthly': ['speedc_tier3_monthly'],
		'speedcoach-tier-3-yearly': ['speedc_tier3_yearly'],
		'cloud-wp-tier-1-monthly': ['cwp_tier1_monthly'],
		'cloud-wp-tier-1-yearly': ['cwp_tier1_yearly'],
		'cloud-wp-tier-2-monthly': ['cwp_tier2_monthly'],
		'cloud-wp-tier-2-yearly': ['cwp_tier2_yearly'],
		'cloud-wp-tier-3-monthly': ['cwp_tier3_monthly'],
		'cloud-wp-tier-3-yearly': ['cwp_tier3_yearly'],
	};

	public constructor(
		public apiService: ApiService,
		private route: ActivatedRoute,
		private authService: AuthService,
		private hubspotService: HubspotService,
		public pollingService: PollingService,
	) {
		this.route.params.subscribe((params) => {
			if (
				params['products'] &&
				this.packageSubtitles[params['products']]
			) {
				this.subtitle = this.packageSubtitles[params['products']];
			}
		});

		this.hubspotDeal = setInterval(() => {
			this.recordHubspotDeal();
		}, 10000)
	}

	public ngOnChanges(changes: any) {
		const packages = changes.packages;
		if (packages && packages.currentValue) {
			if (!this.catalogFetched) {
				this.catalogFetched = true;
				this.fetchCatalog();
			}

			this.disableCartQuantity();
		}

		setTimeout(() => {
			this.calculateTotal();
		});
	}

	public ngOnDestroy() {
		this.hubspotService.abandonCart();
		clearInterval(this.hubspotDeal);
	}

	public fetchCatalog() {
		this.status = 'loading';

		this.apiService.get('/v1/wpcr/catalog', []).subscribe(
			(catalog) => {
				this.catalog = catalog;
				this.setCart();
				this.setDataSource();

				if (this.cart.length) {
					if(this.fetchedCredit || !this.isUpdatePlan) {
						this.status = 'success';
					}
				} else {
					this.status = 'failed';
				}

				this.cartValidation.emit();
			},
			() => {
				this.status = 'failed';
				this.cartValidation.emit();
			}
		);
	}

	public setCart() {
		let products = [];
		this.packages.forEach((product) => {
			if (product.planCode) {
				if (this.couponData?.item_codes.includes(product.planCode)) {
					product.options = {
						...product.options,
						coupon_codes: [this.couponData?.coupon_code],
					};
				}
				products.push({
					code: product.planCode,
					quantity: product.quantity ?? 1,
					options: product.options,
				});
			} else if (this.packageMap[product]) {
				const formattedPackage = this.packageMap[product].map((val) => ({ code: val, options: {} }));

				products = [...products, ...formattedPackage];
			} else if ('string' === typeof product) {
				products.push({ code: product, options: {} });
			}
		});

		// Find the products in catalog
		const search = new CatalogSearch(this.catalog);
		this.cart = search.findCatalogItems(products, this.term);
	}

	public setDataSource() {
		const dataSource = [];
		this.cart.forEach((item) => {
			item.price_in_cents = item['options']?.price || item.price_in_cents;

			const existingEntry = dataSource.findIndex((product: any) => product.code === item.code && item.code !== 'domain-gtld-1');

			if (-1 !== existingEntry) {
				dataSource[existingEntry].quantity =
					dataSource[existingEntry].quantity + 1;
			} else {
				dataSource.push({
					quantity: item.quantity ?? 1,
					product: item.label,
					term: item.term.label,
					is_recurring: item.transaction_type === 'recurring',
					allows_multiple: item['options']?.allows_multiple ?? true,
					price: item.price_in_cents / 100,
					code: item.code,
					domain: item['options']?.domain_name,
				});
			}

			if (
				item.hasOwnProperty('options') &&
				item['options'].hasOwnProperty('allows_term_discount')
			) {
				this.allowsTermDiscount = item['options'].allows_term_discount;
			}
		});
		// Sort alphabetically.
		dataSource.sort((a, b) => (a.product < b.product ? -1 : 1));
		this.dataSource = dataSource;

		this.calculateTotal();
	}

	/**
	 * Get paramters to post on to backend payment.
	 *
	 * @since 1.13.0
	 *
	 * @return Payload.
	 */
	public getPayload() {
		return new Payload().format(this.cart);
	}
	/**
	 * Format discounts.
	 *
	 * @since 1.21.0
	 *
	 * @return Discounts.
	 */
	public getCartDiscounts() {
		return [];
	}
	/**
	 * Return a list of packages purchased.
	 *
	 * @since 1.13.0
	 *
	 * @return List of package labels.
	 */
	public getPackageLabels() {
		const packageNames = [];

		for (const item of this.cart) {
			packageNames.push(item.label);
		}

		return _uniq(packageNames);
	}

	public toCents(amt){
		return Math.round((Math.abs(amt) / 100) * 10000);
	}

	/**
	 * Calculate the cart total.
	 *
	 * @since 1.13.0
	 *
	 * @return Value of cart.
	 */
	public calculateTotal() {
		let subTotal = 0.0;
		this.cart.forEach((item) => {
			item.price_in_cents = item['options']?.price || item.price_in_cents;
			for (let i = 0; i < item.quantity; i++) {
				subTotal += item.price_in_cents / 100;
			}
		});

		// const discount = this.calcDiscount( subTotal );
		const discount = this.parseDiscountObject();

		if (discount.type === 'percent') {
			discount.amount = subTotal * discount.amount;
		}

		this.handleTermToggle();

		this.subTotal = subTotal.toFixed(2);
		this.total = Math.max(subTotal - discount.amount, 0).toFixed(2);
		this.hasDiscount = !!discount.amount;
		this.discount = {
			type: discount.type,
			amount: discount.amount.toFixed(2),
			value: discount.type === 'percent'
				? discount.value
				: (discount.amount / subTotal * 100).toFixed(0),
		};

		// Checking that this.total !== 0 prevents the credit from being applied twice.
		if ( this.isUpdatePlan && this.cart && Number(this.total) ) {
			const plan = this.cart.find((item) => item['type'] === 'hosting');
			this.getPartialPrice( plan.code );
		}

		this.recordHubspotDeal();
	}

	/**
	 * Change the quantity of an item in the cart. This clones an existing cart
	 * item in the array.
	 *
	 * @since 1.13.0
	 *
	 * @param  quantity      Number of products.
	 * @param  productTermId The terms ID.
	 */
	public changeQuantity(quantity: number, code: string, input: any): void {
		quantity = quantity && quantity > 0 ? quantity : 1;

		// Prevent the display from showing more than 99.
		if (quantity > 99) {
			quantity = 99;
			input.value = quantity;
		}

		input.value = quantity;

		// This is weird.. I had lol, when I copied it over.. But it works!
		const product = this.cart.find((val: any) => val.code === code);
		this.cart = this.cart.filter((val: any) => val.code !== code);

		product.quantity = quantity;
		this.cart.push({ ...{}, ...product });

		this.setDataSource();
		this.calculateTotal();
		this.getPayload();
	}

	private disableCartQuantity() {
		this.lockCartQuantity = false;
		for (const product of this.packages) {
			if (product['options'] && product['options']['connect_id']) {
				this.lockCartQuantity = true;
			}
		}
	}

	private fetchCartSuccess() {
		this.status = 'success';
		this.cartValidation.emit();
	}

	public hasRecurring(): boolean {
		return !!this.cart.find((val: any) => !!val.is_recurring);
	}

	public hasKeyService(): boolean {
		return !!this.cart.find((val) => val['type'] === 'service' && 'key' === val['service'].type);
	}

	public updateProducts() {
		this.setCart();
		this.setDataSource();
		this.cartUpdated.emit();
	}

	public parseDiscountObject() {
		let discountType = 'N/A';
		let discountNumber = 0;
		let discountObjectParsed: any;

		if (this.discountObject) {
			discountObjectParsed = JSON.parse(this.discountObject);
			discountType = discountObjectParsed.type;
			discountNumber = discountObjectParsed.amount / 100;
			this.handleDiscoutTerms();
		}

		return {
			type: discountType,
			amount: discountNumber,
			value: discountType === 'percent' ? discountObjectParsed.amount : discountNumber,
		};
	}

	public handleDiscoutTerms() {
		if (this.couponData) {
			const planCodes = this.couponData.plan_codes;
			this.monthlyEnabled = planCodes.includes('wpc_project_monthly');
			this.yearlyEnabled = planCodes.includes('wpc_project_yearly');
			this.term = this.monthlyEnabled ? 'monthly' : 'yearly';
			this.term = this.yearlyEnabled ? 'yearly' : 'monthly';
		}
	}

	public handleTermToggle() {
		if (this.isUpdatePlan) {
			this.monthlyEnabled = this.term === 'monthly' ? true : false;
			this.yearlyEnabled = this.term === 'yearly' ? true : false;
		}
	}

	getPartialPrice( plan ) {
		this.status = 'loading';
		let headers = this.apiService.getHeaders({});
		headers = headers.append('X-Organization-Id', this.organizationId);
		this.apiService.http.post(
			this.apiService.formatter.getUrl(
				'/v1/wpcr/subscriptions/' + this.subscriptionUuid + '/update-subscription/preview?plan_code=' + plan
			), {}, { headers }).subscribe(
			(res: any) => {
				this.updateCredit = res.invoice_collection.charge_invoice.paid;
				this.lineItems = res.invoice_collection.charge_invoice.line_items;
				for(const lineItem of this.lineItems) {
					const cartPlan = this.cart.find((item) => item['code'] === lineItem['add_on_code']);
					this.updateCredit += (cartPlan.price_in_cents/100) - lineItem.amount;
				}
				this.total = Math.max(Number(this.total) - this.updateCredit, 0).toFixed(2);
				this.fetchedCredit = true;
				if(this.cart.length) {
					this.status = 'success';
				}
			},
			() => {
				//this.status = 'failed';
			}
		);
	}

	recordHubspotDeal() {
		console.log("ping")
		if (this.cart.length && this.authService.getAccountId()) {
			if (this.hubspotService.getDeal() === '') {
				this.hubspotService.setDeal(
					this.authService.getAccountId(),
					this.authService.profile.data.options['public_hubspot_deal'] ?? ''
				);
			}

			this.hubspotService.updateCart({
				items: JSON.stringify(this.cart),
				amount: this.total,
			}).subscribe((dealId) => {
				this.authService.profile
					.update({
						public_hubspot_deal: dealId
					});
			});
		}
	}
}
