<div
	class="ctw-mx-auto ctw-max-w-2xl ctw-mt-20 animate__animated animate__fadeIn"
>
	<div class="ctw-mb-8">
		<h2 class="ctw-text-center">Select Snap Project</h2>
	</div>
	<p>
		Installing this Snap will create a new WordPress environment in the
		Project that you select.
	</p>

	<central-project-selector #resourceSelector></central-project-selector>
	<div class="ctw-text-right">
		<button mat-button routerLink="..">Back</button>
		<span class="ctw-ml-3">
			<button
				mat-raised-button
				color="primary"
				[disabled]="!resourceSelector.resource"
				[routerLink]="
					'/projects/' +
					this.resourceSelector.resource?.id +
					'/snaps/install'
				"
				[queryParams]="{
					snap_id: this.activatedRoute.snapshot.queryParams.snap_id
				}"
			>
				Install
			</button>
		</span>
	</div>
</div>
