import {
	Component,
	OnInit,
	Input,
} from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'app-connect-key',
	templateUrl: './connect-key.component.html',
	styleUrls: ['./connect-key.component.scss'],
})
export class ConnectKeyComponent implements OnInit {
	@Input() public connectId;

	public connectKey;
	public copyText = 'Copy to Clipboard';
	public loading = true;

	constructor(
		public apiService: ApiService,
		public clipboard: Clipboard,
		public snackbar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.apiService
			.get('/v1/connect-keys/' + this.connectId, {})
			.subscribe((response) => {
				console.log(response);
				this.connectKey = response;
				this.loading = false;
			});
	}

	public copy() {
		this.clipboard.copy(this.connectKey.key);
		this.snackbar.open('Copied Connect Key to Clipboard!', '', {
			duration: 4000,
		});
	}
}
