// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MomentModule } from 'ngx-moment';
import { MaterialModule } from '../material/material.module';
import { MatExpansionModule } from '@angular/material/expansion';


// Components
import { AddMemberDialogComponent } from './dialogs/add-member-dialog/add-member-dialog.component';
import { PermissionsdialogComponent } from './dialogs/permissionsdialog/permissionsdialog.component';
import { DeleteMemberDialogComponent } from './dialogs/delete-member-dialog/delete-member-dialog.component';
import { EditComponent } from './edit/edit.component';
import { CoreModule } from '../core/core.module';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ContextualHelpCardComponent } from '../contextual-help';
import { ContextualHelpDialogComponent } from '../contextual-help/help-dialog/contextual-help-dialog.component';

@NgModule({
	declarations: [
		AddMemberDialogComponent,
		PermissionsdialogComponent,
		DeleteMemberDialogComponent,
        EditComponent,
        ContextualHelpCardComponent,
        ContextualHelpDialogComponent,
	],
	imports: [
		CoreModule,
		CommonModule,
		MatSelectModule,
        MatIconModule,
        MaterialModule,
        MatExpansionModule,
		MatSnackBarModule,
		MatInputModule,
		MatMenuModule,
		MatRadioModule,
		MatCheckboxModule,
        MatDialogModule,
		FormsModule,
		MatButtonModule,
		MatTableModule,
		MatProgressSpinnerModule,
        MomentModule,
	],
    exports: [
        EditComponent,
        MaterialModule,
        ContextualHelpCardComponent,
        ContextualHelpDialogComponent,
    ],
	providers: [],
})
export class MembershipModule {}
