export default (config) => {
	config.brandConfig = {
		id: 'imh',
		applicationName: 'InMotion Central',
		reseller: 'InMotion Hosting',
		marketingSite: 'https://www.inmotionhosting.com',
		supportUrl: 'https://www.inmotionhosting.com/support/',
		privacyPolicy: 'https://www.inmotionhosting.com/privacy/',
		termsOfService: 'https://www.inmotionhosting.com/legal/',
		logo: 'https://inmotionhosting.github.io/static-assets/logo/imh-cloud/medium.png',
		supportDocs: 'https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/',
	};

	config.features.referrals = false;

	config.urls = {
		docsApi: 'https://docs.flexmetal.net',
		privacyPolicy: 'https://www.inmotionhosting.com/privacy/',
		support:
			'https://docs.flexmetal.net/day-1-getting-started-with-openstack/',
	};

	config.buttonColor = 'primary';
	config.inputAppearance = 'standard';

	return config;
};
