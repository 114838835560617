<form class="twoFa-form" (ngSubmit)="submit()" novalidate>
	<label class="ctw-font-bold"
		>Where should we send your security code?</label
	>
	<mat-radio-group
		aria-labelledby="Two Factor Authenticatin Device List"
		class="device-options"
		name="deviceSelection"
		[(ngModel)]="model.deviceSelection"
		#deviceSelection="ngModel"
		required
	>
		<mat-radio-button
			class="device-option-radio"
			*ngFor="let device of this.deviceOptions"
			[value]="device.name"
			[name]="device"
		>
			{{ device.message }}
		</mat-radio-button>
		<mat-hint
			align="start"
			class="animate__animated error"
			[class.animate__slideInUp]="
				this.fieldHasError(deviceSelection) || this.sendError
			"
		>
			<span *ngIf="this.fieldHasError(deviceSelection)"
				>A device selection is required.</span
			>
			<span
				*ngIf="
					this.sendError &&
					this.sendError?.name === 'rate_limit_exceeded'
				"
				>Number of resubmissions reached. Please try again later.</span
			>
			<span
				*ngIf="
					this.sendError &&
					this.sendError?.name !== 'rate_limit_exceeded'
				"
				>Unknown Error. Please try again later.</span
			>
		</mat-hint>
	</mat-radio-group>

	<button
		type="submit"
		name="sendCode"
		[disabled]="this.state === 'submitted'"
		class="send-code-button"
		mat-raised-button
		color="accent"
	>
		Send Code
	</button>
	<button
		type="button"
		[disabled]="this.state === 'submitted'"
		(click)="this.handleCancel()"
		class="cancel-button"
		mat-raised-button
	>
		<mat-icon>arrow_back</mat-icon> Cancel
	</button>
</form>
