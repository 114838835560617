<div class="primary-heading ctw-p-4 ctw-background-white ctw-border ctw-border-solid ctw-border-imh-gray">
    <div class="column ctw-mx-auto ctw-max-w-6xl">
        <h2>Add DNS</h2>
        <h4 *ngIf="this.isReady" class="animate__animated animate__fadeIn ctw-mb-0">
            Don't own a domain yet?
            <button mat-raised-button color="primary" routerLink="../register" class="ctw-ml-2">
                Register Here
            </button>
        </h4>
    </div>
    <mat-progress-bar *ngIf="!this.isReady" mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl content ctw-mb-12 ctw-pt-16"
    [class.ctw-hidden]="!this.isReady">

    <h4>Manage DNS for domains you already own</h4>
    <p>
        If you already have a domain name registered through a
        third party, you can manage it's DNS records here.
    </p>
    <p>Select the Team that will manage this Domain</p>
    <central-account-selector permission="manage" (accountChanged)="accountChanged($event)"
        inputAppearance="standard"></central-account-selector>
    <central-domain-add-shared [organizationId]="ownerAccountId"
        (domainAddedEvent)="domainAdded($event)"></central-domain-add-shared>
</div>