import { Component, OnInit, Input } from '@angular/core';
import { CatalogDialogComponent } from '../../../checkout/catalog-dialog/catalog-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '@central/ng-shared';
import { ProjectService } from '../../project.service';
import { Post } from '../../../shared/forms/post';
import { LicenseService } from '../../../shared/license/license.service';

@Component({
	selector: 'bgc-app-access',
	templateUrl: './app-access.component.html',
	styleUrls: ['./app-access.component.scss'],
})
export class AppAccessComponent implements OnInit {
	@Input() public appLink: string;
	@Input() public image: string;
	@Input() public helpLink: string;

	public state = 'loading';
	public ready = false;
	public hasPremium = true;
	public site;
	public hasUltrastack = false;
	public environmentType: string;
	public featue: string;
	interval;

	constructor(
		private dialog: MatDialog,
		private projectService: ProjectService,
		private licenseService: LicenseService,
		private apiService: ApiService
	) {}

	ngOnInit() {
		if (this.projectService.environmentState === 'pending') {
			this.state = 'failed';
		} else {
			if (this.projectService.environment.fields?.cloud_id) {
				this.environmentType = 'playground';
				this.licenseService.onReady().subscribe(() => {
					const cwpLicense =
						this.licenseService.getAccountLicense('cloud-wordpress');
				});
				this.fetchCloud();
			}

			if (
				this.projectService.environment.fields?.environment_type === 'vps'
			) {
				this.environmentType = 'server';
				this.getPlaybookStatus();
				this.projectService.connected.subscribe((msg: any) => {
					if (
						msg === 'ready' &&
						this.projectService.client &&
						this.state !== 'success' &&
						this.state !== 'blocked'
					) {

						this.ultrastackExists();
						this.fetchVps();
					}

					if (msg !== 'ready') {
						this.state = 'blocked';
					}
				});
			}
		}
	}

	/**
	 * Gets status of project's playbook runs
	 */
	getPlaybookStatus() {
		this.projectService.playbookStatus.subscribe((msg: any) => {
			this.state = msg !== 'playbook-not-running' ? 'blocked' : this.state;
			if (
				this.state === 'blocked' &&
				(msg === 'playbook-not-running' || msg === 'complete')
			) {
				this.state = 'loading';
				this.ultrastackExists();
				this.fetchVps();
			}
		});
	}

	public login(site, appName) {
		const post = new Post();
		const req = {
			account_id: site.account_id,
			token: site.login_token,
			path: appName,
			origin: window.location.origin,
		};

		if (!this.projectService.environment.fields.cloud_id) {
			req['path'] = appName.replace(/.php.*$/, '/');
			req['site_id'] = this.projectService.environment.id;
			req['connect_key'] = site.connect_key;
		}

		post.postForm(this.getAppUrl(site.login_url), req);
	}

	/**
	 * Give the standard authentication URL, return the Cloud Apps. Url.
	 *
	 * @param  authUrl Authentication URL.
	 * @return         Auth URL.
	 */
	public getAppUrl(authUrl: string) {
		if (this.projectService.environment.fields.cloud_id) {
			return authUrl.replace('wp-login.php', 'apps/auth.php');
		} else {
			if (this.projectService.environment.fields.connection_url) {
				return this.projectService.environment.fields.connection_url + '/ultrastack/';
			} else {
				return 'https://' + this.projectService.environment.fields.hostname + '/ultrastack/';
			}
		}
	}

	public fetchCloud() {
		this.apiService
			.get(
				'/v1/cloud-wordpress/' +
					this.projectService.environment.fields.cloud_id,
				{}
			)
			.subscribe(
				(cloud) => {
					this.site = cloud;
					this.state = 'success';
				},
				() => {
					this.state = 'failed';
				}
			);
	}

	public fetchVps() {
		this.apiService
			.get(
				'/v1/connect-keys/' +
					this.projectService.project.fields.connect_id,
				{}
			)
			.subscribe(
				(connect) => {
					this.apiService
						.post(
							'/v1/environments/' +
								this.projectService.environment.id +
								'/auth',
							{}
						)
						.subscribe((environment) => {
							this.site = {
								account_id:
									this.projectService.project.fields
										.connect_id,
								login_url:
									this.projectService
										?.wpRestService
										?.sitesDiscovered?.[0]
										?.accessToken['site_url'],
								login_token: environment['token'],
								connect_key: connect['key_hash'],
							};
						});
				},
				() => {
					this.state = 'failed';
				}
			);
	}

	public openUpgrade() {
		this.dialog.open(CatalogDialogComponent, {
			width: '450px',
			data: {
				title: 'Choose a Plan',
				tags: ['cloud-wordpress'],
			},
		});
	}

	public ultrastackExists() {
		this.apiService
			.post('/v1/playbook/url-status', {
				url: this.getAppUrl(
					this.projectService.environment.fields.site_url
				),
			})
			.subscribe(
				(response: any) => {
					this.hasUltrastack =
						response?.statusCode === 404 ? false : true;
					this.state = 'success';
				},
				(err) => {
					console.log('err:', err);
					this.state = 'failed';
				}
			);
	}
}
