<div class="custom-url">
	<ng-container *ngFor="let url of this.customUrls">
		<mat-card
			class="plugin-row added-plugins"
			*ngIf="url.type === 'custom'"
		>
			<span class="logo">
				<mat-icon>settings</mat-icon>
			</span>
			<div>
				<div class="zip-name">{{ url.name || url.label }}</div>
				<span class="zip-file">{{ url.url }}</span>
			</div>
			<mat-icon
				class="remove"
				aria-label="Remove"
				[matTooltip]="Remove"
				(click)="this.removeURL(url)"
			>
				close
			</mat-icon>
		</mat-card>
	</ng-container>

	<mat-card *ngIf="limit > this.customUrlsCount()">
		<p class="alert alert-default">
			Provide a URL to the ZIP file and we'll use the
			{{ this.resourceType.toLowerCase() }} from the zip at the time of
			installation.
		</p>

		<div>
			<form
				#form="ngForm"
				(ngSubmit)="form.valid ? this.addURL() : false"
			>
				<mat-form-field>
					<mat-label>Zip file URL</mat-label>
					<input
						required
						#url="ngModel"
						matInput
						placeholder="https://downloads.wordpress.org/plugin/boldgrid-backup.zip"
						name="zip-url"
						[(ngModel)]="this.zip"
					/>
					<mat-hint
						align="start"
						*ngIf="url.errors?.url"
						class="error"
					>
						URL is invalid
					</mat-hint>
				</mat-form-field>

				<mat-form-field>
					<input
						matInput
						required
						placeholder="Name"
						name="zip-name"
						[(ngModel)]="this.name"
					/>
				</mat-form-field>

				<mat-form-field>
					<textarea
						matInput
						placeholder="Description (Optional)"
						name="zip-description"
						[(ngModel)]="this.description"
					></textarea>
				</mat-form-field>

				<button mat-button mat-raised-button color="accent">
					Add {{ this.resourceType }}
				</button>
			</form>
		</div>
	</mat-card>
</div>
