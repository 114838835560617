import { Router, NavigationEnd } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

import { DecoderService } from '../core/jwt/decoder';
import { FacebookService } from './facebook/facebook.service';
import { GoogleAnalyticsService } from './google/google.service';
import { OptimizelyService } from './optimizely/optimizely.service';
import { PostAffiliatePro } from './post-affiliate-pro/post-affiliate-pro';
import { ScriptService } from '../core/script/script.service';
import { TwitterAnalyticsService } from './twitter/twitter.service';
import { BingService } from './bing/bing.service';
import { HubspotService } from './hubspot/hubspot.service';
import { LinkedinService } from './linkedin/linkedin.service';
import { ConfigurationService } from '../core/configuration.service';

@Injectable()
export class TrackingService {
	private history = [];

	public onEvent = new EventEmitter();
	public postAffiliatePro: PostAffiliatePro;
	public dynamicScripts = {
		hotjar: {
			loader: window['BG_Hotjar'],
			isLoaded: false,
		},
	};

	public services: any[] = [];

	constructor(
		private router: Router,
		public facebookService: FacebookService,
		public scriptService: ScriptService,
		public jwtHelper: DecoderService,
		public twitterService: TwitterAnalyticsService,
		public configurationService: ConfigurationService,
		public bingService: BingService,
		public googleAnalytics: GoogleAnalyticsService,
		public optimizelyService: OptimizelyService,
		public hubspotService: HubspotService,
		public linkedinService: LinkedinService,
	) {
		this.postAffiliatePro = new PostAffiliatePro();

		// Enable trackers by config.
		for (const [trackerName, enabled] of Object.entries(
			this.configurationService.config.trackers || {}
		)) {
			if (enabled && this[trackerName]) {
				this.services.push(this[trackerName]);
			}
		}

		this.setUser();

		// Request scripts after application is fully loaded.
		// setTimeout( () => this.scriptService.loadScript( 'getResponse' ), 1000 );

		// Load all dynamic scripts.
		this.track('appendScript', this);
	}

	/**
	 * Listen to router events “navigation” and saves each route into an array.
	 *
	 */
	public loadRouting(): void {
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(({urlAfterRedirects}: NavigationEnd) => {
				this.history = [...this.history, urlAfterRedirects];
			});
	}

	public getHistory(): string[] {
		return this.history;
	}

	public getPreviousUrl(): string {
		return this.history[this.history.length - 2] || '/';
	}

	public getCurrentUrl(): string {
		return this.history[this.history.length - 1] || '/';
	}

	/**
	 * Set the current user within analytic tracking objects.
	 *
	 * @since 1.4.0
	 */
	public setUser() {
		const accountId = this.jwtHelper.getTokenVal('user_id');

		if (accountId) {
			this.googleAnalytics.setUser(accountId);
		}

		// Email
		const email = this.jwtHelper.getTokenVal('email');
		if (email && window['gaSetUserId']) {
			window['gaSetUserId'](email);
		}
	}

	/**
	 * Load the script on demand.
	 *
	 * @since 1.20.0
	 *
	 * @param  scriptName Script name.
	 */
	public loadScript(scriptName: string) {
		if (this.configurationService.config.includeTracking) {
			if (
				!this.dynamicScripts[scriptName].isLoaded &&
				typeof this.dynamicScripts[scriptName].loader === 'function'
			) {
				this.dynamicScripts[scriptName].isLoaded = true;
				this.dynamicScripts[scriptName].loader();
			}
		}
	}

	/**
	 * General Tracking call.
	 *
	 * Everything comes in through this call. Idea is that all global tracking processes first pass through here.
	 *
	 * @since 1.1
	 *
	 * @param      event Method to call.
	 * @param      data Data to be used for tracking.
	 */
	public track(event: string, data?: object) {
		try {
			for (const service of this.services) {
				if (service[event]) {
					service[event](data);
				}
			}

			this.onEvent.emit({ event, data });
		} catch (e) {
			console.log(e, 'Error: Unable to send tracking');
		}
	}
}
