<mat-form-field class="user-select" [class.ctw-mb-0]="this.defaultType === 'organization'"
    [appearance]="this.inputAppearance" *ngIf="'select' === presentation">
    <mat-label>{{ this.getLabel() }}</mat-label>
    <mat-select [(ngModel)]="this.currentAccount" (selectionChange)="selectionChange($event.value)">
        <ng-container *ngFor="let account of accountAccess">
            <mat-option [value]="account" [disabled]="!this.hasPermission(account)">
                {{ account.display_name }}
                <span class="unauthorized" *ngIf="!this.hasPermission(account)">Unauthorized</span>
            </mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>

<div class="user-select" *ngIf="'radio' === presentation">
    <mat-radio-group [(ngModel)]="this.currentAccount" (change)="selectionChange($event.value)">
        <mat-radio-button [disabled]="!this.hasPermission(account)" color="primary"
            *ngFor="let account of accountAccess" [value]="account">
            <div class="label">
                {{
                'organization' === account.type ||
                'organization' === this.defaultType
                ? 'Organization'
                : 'Personal Account'
                }}
                <span class="unauthorized" *ngIf="!this.hasPermission(account)">Unauthorized - Request Maintainer
                    Access</span>
            </div>
            <div>{{ account.display_name }}</div>
        </mat-radio-button>
    </mat-radio-group>
</div>