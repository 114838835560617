<div class="actions animate__animated animate__fadeIn">
	<a
		*ngIf="this.projectService.environment.fields.machine_details"
		mat-button
		mat-raised-button
		class="mat-primary"
		target="_blank"
		[href]="this.projectService.environment.fields.site_url"
		><mat-icon>open_in_new</mat-icon> View Live Site</a
	>
	<a
		*ngIf="!this.projectService.environment.fields.machine_details"
		mat-button
		mat-raised-button
		class="playground"
		target="_blank"
		[href]="this.projectService.environment.fields.site_url"
		><mat-icon>open_in_new</mat-icon> View Playground Site</a
	>
	<button
		mat-raised-button
		class="mat-primary"
		(click)="
			this.projectService.wpRestService.login(
				this.projectService.environment,
				'wp-admin/'
			)
		"
	>
		<mat-icon>open_in_new</mat-icon> WP Admin
	</button>
</div>
<div class="preview-container">
	<div>
		<h3>Desktop</h3>
		<div class="desktop device">
			<div
				class="address-bar"
				[class]="this.url.includes('https') ? 'ssl' : ''"
			>
				{{ this.url }}
			</div>
			<div class="desktop-content">
				<iframe [src]="this.sanitizedUrl"></iframe>
			</div>
		</div>
	</div>
	<div>
		<h3>Mobile</h3>
		<div class="mobile device">
			<div class="address-bar">{{ this.url }}</div>
			<div class="mobile-content">
				<iframe [src]="this.sanitizedUrl" sandbox></iframe>
			</div>
		</div>
	</div>
</div>
