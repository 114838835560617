<div class="option ctw-mt-5">
	<div>
		<h4>Beta Tester</h4>
		<p>Enables an account to test new features early.</p>
		<p>Current State: {{ this.isTester ? 'Enabled' : 'Disabled' }}</p>
	</div>
	<div class="ctw-mt-2">
		<progress-button [state]="this.state" (click)="toggleState()">
			{{ this.isTester ? 'Disable' : 'Enable' }} Testing
		</progress-button>
	</div>
</div>
