<div class="inspection_screen">
	<button
		mat-raised-button
		color="primary"
		[routerLink]="['/agent/user']"
		class="back-button"
	>
		<mat-icon>keyboard_arrow_left</mat-icon>Return to Users Table
	</button>
	<div class="usage-header">
		<h2>User Information Page</h2>
		<div>
			<button
				mat-raised-button
				*ngIf="this.configService.hasFeature('loginAsUser')"
				(click)="openLoginAsUserDialog()"
				class="ctw-mr-5"
			>
				<mat-icon>login</mat-icon> Log In As User
			</button>
			<button mat-raised-button (click)="refresh()">
				<mat-icon>refresh</mat-icon> Refresh
			</button>
		</div>
	</div>

	<!-- Loading State -->
	<div *ngIf="state === 'submitted'">
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</div>

	<!-- Success State -->
	<div class="user-inspection-page" *ngIf="state === 'success'">
		<mat-card>
			<div class="user-inspection-header">
				<div class="user-header-info">
					<div class="header-content">
						<h2>
							{{ data.display_name }} (ID: {{ data.account_id }})
						</h2>
						<div class="fraud-panel">
							<p class="fraud-text">
								<b>Score:</b> {{ data.fraud_percent }}
								<span
									class="{{
										data.fraud_score > 30
											? 'warning'
											: 'success'
									}}"
									>&#9679;</span
								>
							</p>
						</div>
						<div class="time-panel">
							<p class="time-text">
								<b>Joined:</b>
								{{
									fingerprintjs.first_seen
										| date : 'MMMM d y, h:mm:ss a'
								}}
								<br />
								<b>Last Seen:</b>
								{{
									fingerprintjs.last_seen
										| date : 'MMMM d y, h:mm:ss a'
								}}
								<br />
							</p>
						</div>
						<div class="user-actions">
							<h3 class="ctw-mb-0">User Actions</h3>
							<div class="ctw-flex">
								<div class="fraud-review">
									<div
										*ngIf="hsPropertyState === 'success'"
										class="ctw-mt-5"
									>
										<h4>Fraud Review</h4>
										<p>Updates fraud status in HubSpot</p>
										<form (ngSubmit)="this.fraudUpdate()">
											<mat-form-field appearance="fill">
												<mat-label
													>Fraud Review</mat-label
												>
												<mat-select
													name="fraudState"
													[(ngModel)]="
														this.fraudStatus
													"
												>
													<mat-option value="Fraud"
														>Fraud</mat-option
													>
													<mat-option value="Valid"
														>Valid</mat-option
													>
													<mat-option
														value="Not Reviewed"
														>Not
														Reviewed</mat-option
													>
												</mat-select>
												<mat-hint
													class="error"
													*ngIf="
														this
															.fraudStatusState ===
														'failed'
													"
													>Error updating fraud
													status</mat-hint
												>
											</mat-form-field>
											<span class="ctw-ml-5">
												<progress-button
													[state]="
														this.fraudStatusState
													"
												>
													Update
												</progress-button>
											</span>
										</form>
									</div>
									<div
										*ngIf="hsPropertyState === 'failed'"
										class="error"
									>
										Unable to load Hubspot properties
									</div>
								</div>
								<div class="suspend ctw-ml-5">
									<central-suspension
										[accountId]="
											data.account_access[0].account_id
										"
										[isSuspended]="data.is_disabled"
									></central-suspension>
								</div>
								<div class="create-temp-auth ctw-ml-5">
									<h4>Create Temporary Password</h4>
									<p>Creates a temporary password that is valid for 15 minutes.</p>
									<div *ngIf="tempAuth">
										<p class="ctw-mt-5">
											<b>Temporary Password:</b>
											{{ this.tempAuth }}
										</p>
									</div>
									<button
										mat-raised-button
										color="primary"
										(click)="createTempAuth()"
									>
										<mat-icon>vpn_key</mat-icon> Create
										Temporary Password
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-card>

		<mat-card-content class="first-card">
			<div class="user-information">
				<div class="first-category">
					<div class="category">
						<h4><b>Basic Information</b></h4>
						<hr />
					</div>
					<div class="category-information">
						<div>
							<span class="metric-label">Username:</span>
							{{ data.username }}
						</div>
						<div>
							<span class="metric-label">Account ID:</span>
							{{ data.account_access[0].account_id }}
						</div>
						<div>
							<span class="metric-label">Email: </span
							><a
								href="mailto: {{ data.email }}"
								target="_blank"
								>{{ data.email }}</a
							>
						</div>
						<div>
							<span class="metric-label"
								>Email Verification:</span
							>
							<span
								[class.error]="data.requires_email_verification"
							>
								{{
									data.requires_email_verification
										? 'Pending'
										: 'Verified'
								}}
							</span>
						</div>
						<div>
							<span class="metric-label">Account Name: </span
							><a
								href="mailto: {{ data.account_name }}"
								target="_blank"
								>{{ data.account_name }}</a
							>
						</div>
						<div>
							<span class="metric-label">Redirect URL: </span>
							<a
								*ngIf="data.redirect_url"
								href="{{ data.redirect_url }}"
								target="_blank"
								>{{ data.redirect_url }}</a
							>
							<span *ngIf="!data.redirect_url">None</span>
						</div>
						<div>
							<span class="metric-label">Hubspot: </span>
							<a
								*ngIf="this.hubspotUrl"
								[href]="this.hubspotUrl"
								target="_blank"
								>{{ this.hubspotUrl }}</a
							>
							<span *ngIf="!this.hubspotUrl">N/A</span>
						</div>
						<div>
							<span class="metric-label">Zendesk: </span>
							<a
								*ngIf="this.zendeskUrl"
								[href]="this.zendeskUrl"
								target="_blank"
								>{{ this.zendeskUrl }}</a
							>
							<span *ngIf="!this.zendeskUrl">N/A</span>
						</div>
						<div>
							<span class="metric-label">Recurly: </span>
							<a
								*ngIf="this.recurlyUrl"
								[href]="this.recurlyUrl"
								target="_blank"
								>{{ this.recurlyUrl }}</a
							>
							<span *ngIf="!this.recurlyUrl">N/A</span>
						</div>
					</div>
				</div>
				<div class="second-category">
					<div class="category">
						<h4><b>Location Information</b></h4>
						<hr />
					</div>
					<div class="category-information">
						<div>
							<span class="metric-label">IP Address: </span>
							{{ data.signup_ip ? data.signup_ip : 'None' }}
						</div>
						<div>
							<span class="metric-label">Unique IP: </span>
							<span [class.error]="!data.is_unique_ip">
								{{ data.is_unique_ip ? 'Unique' : 'Shared' }}
							</span>
						</div>
						<div>
							<span class="metric-label">Latitude: </span>
							<a
								*ngIf="mapURL"
								href="{{ mapURL }}"
								target="_blank"
							>
								{{
									fingerprintjs.latitude
										? fingerprintjs.latitude
										: 'None'
								}}
							</a>
							<span *ngIf="!mapURL">None</span>
						</div>
						<div>
							<span class="metric-label">Longitude: </span>
							<a
								*ngIf="mapURL"
								href="{{ mapURL ? mapURL : '' }}"
								target="_blank"
							>
								{{
									fingerprintjs.longitude
										? fingerprintjs.longitude
										: 'None'
								}}
							</a>
							<span *ngIf="!mapURL">None</span>
						</div>
						<div>
							<span class="metric-label">Country: </span>
							{{
								fingerprintjs.country
									? fingerprintjs.country
									: 'None'
							}}
						</div>
						<div>
							<span class="metric-label">Country Code: </span>
							{{
								fingerprintjs.country_code
									? fingerprintjs.country_code
									: 'None'
							}}
						</div>
					</div>
				</div>
			</div>
		</mat-card-content>

		<mat-card-content>
			<div class="user-information">
				<div class="first-category">
					<div class="category">
						<h4><b>Sign In IP</b></h4>
						<hr />
					</div>
					<div class="category-information">
						<div>
							<span class="metric-label">Fraud Score: </span>
							<span [class.error]="ip_results.fraud_score >= 30">
								{{
									ip_results.fraud_score
										? ip_results.fraud_score
										: 'None'
								}}
							</span>
						</div>
						<div>
							<span class="metric-label">Crawler Status: </span>
							<span [class.error]="ip_results.is_crawler">
								{{ ip_results.is_crawler ? 'Crawler' : 'None' }}
							</span>
						</div>
						<div>
							<span class="metric-label">Proxy Status: </span>
							<span [class.error]="ip_results.proxy">
								{{ ip_results.proxy ? 'Proxy' : 'None' }}
							</span>
						</div>
						<div>
							<span class="metric-label">VPN Status: </span>
							<span [class.error]="ip_results.vpn">
								{{ ip_results.vpn ? 'VPN' : 'None' }}
							</span>
						</div>
						<div>
							<span class="metric-label">Recent Abuse: </span>
							<span [class.error]="ip_results.recent_abuse">
								{{
									ip_results.recent_abuse
										? 'Abuse Detected'
										: 'None'
								}}
							</span>
						</div>
						<div>
							<span class="metric-label">Request ID: </span>
							{{
								ip_results.request_id
									? ip_results.request_id
									: 'None'
							}}
						</div>
						<div>
							<span class="metric-label">Success Status: </span>
							{{
								ip_results.success == null
									? 'None'
									: ip_results.success
									? 'Success'
									: 'Failure'
							}}
						</div>
					</div>
				</div>
				<div class="second-category">
					<div class="category">
						<h4><b>Phone</b></h4>
						<hr />
					</div>
					<div class="category-information">
						<div>
							<span class="metric-label">Fraud Score: </span>
							<span [class.error]="phone_fraud.score >= 30">
								{{
									phone_fraud.score != null
										? phone_fraud.score
										: 'None'
								}}
							</span>
						</div>
						<div>
							<span class="metric-label">VOIP Status: </span>
							{{ phone_fraud.VOIP ? 'VOIP' : 'None' }}
						</div>
						<div>
							<span class="metric-label">Prepaid Status: </span>
							{{
								phone_fraud.prepaid == null
									? 'None'
									: phone_fraud.prepaid
									? 'Prepaid'
									: 'Non-Prepaid'
							}}
						</div>
						<div>
							<span class="metric-label">Risk Status: </span>
							{{
								phone_fraud.risky == null
									? 'None'
									: phone_fraud.risky
									? 'High'
									: 'Low'
							}}
						</div>
						<div>
							<span class="metric-label">Activity Status: </span>
							{{
								phone_fraud.active == null
									? 'None'
									: phone_fraud.active
									? 'Active'
									: 'Inactive'
							}}
						</div>
						<div>
							<span class="metric-label">Spammer Status: </span>
							{{
								phone_fraud.spammer == null
									? 'None'
									: phone_fraud.spammer
									? 'Spammer'
									: 'Non-spammer'
							}}
						</div>
						<div>
							<span class="metric-label">Success Status: </span>
							{{
								phone_fraud.success == null
									? 'None'
									: phone_fraud.success
									? phone_fraud.success
									: 'None'
							}}
						</div>
					</div>
				</div>
			</div>
		</mat-card-content>

		<mat-card-content>
			<div class="user-information">
				<div class="first-category" *ngIf="billing">
					<div class="category">
						<h4><b>Billing Information</b></h4>
						<hr />
					</div>
					<div class="category-information">
						<div>
							<span class="metric-label">Card Type: </span>
							{{ billing.card_type }}
						</div>
						<div>
							<span class="metric-label">Name on Card: </span>
							{{ billing.first_name }} {{ billing.last_name }}
						</div>
						<div>
							<span class="metric-label">Address: </span>
							{{ billing.address1 }} {{ billing.city }},
							{{ billing.state }} {{ billing.zip }}
							{{ billing.country }}
						</div>
						<div>
							<span class="metric-label">Expiration Date: </span>
							{{ billing.month }}/{{ billing.year }}
						</div>
						<div>
							<span class="metric-label">Last Four Digits: </span>
							{{ billing.last_four }}
						</div>
						<div>
							<span class="metric-label">Amount Status: </span>
							<span [class.error]="data.credit_card_amount">
								{{
									data.credit_card_amount
										? 'Has Amount'
										: 'None'
								}}
							</span>
						</div>
						<div>
							<span class="metric-label"
								>Pick Up Card Status:
							</span>
							<span [class.error]="data.pick_up_card">
								{{
									data.pick_up_card == null
										? 'None'
										: data.pick_up_card
										? 'Pick Up Card'
										: 'Valid'
								}}
							</span>
						</div>
						<div>
							<span class="metric-label">Billing Saved: </span>
							<span [class.error]="!data.billing_saved">
								{{
									data.billing_saved ? 'Saved' : 'None Saved'
								}}
							</span>
						</div>
						<div>
							<span class="metric-label">Past Due: </span>
							<span [class.error]="data.is_past_due.past_due">
								{{
									data.is_past_due.past_due
										? 'Past Due'
										: 'Valid'
								}}
							</span>
						</div>
					</div>
				</div>
				<div class="second-category">
					<div class="category">
						<h4><b>Options</b></h4>
						<hr />
					</div>
					<div class="category-information">
						<div>
							<span class="metric-label"
								>Internal Zdesk Account Organization ID:</span
							>
							{{
								options.internal_zdesk_account_org_id
									? options.internal_zdesk_account_org_id
									: 'None'
							}}
						</div>
						<div>
							<span class="metric-label"
								>Internal Phone Number:
							</span>
							<a
								*ngIf="options.internal_phone_number"
								href="tel:{{ options.internal_phone_number }}"
								target="_blank"
							>
								{{ options.internal_phone_number }}
							</a>
							<span *ngIf="!options.internal_phone_number"
								>None</span
							>
						</div>
						<div>
							<span class="metric-label"
								>Internal Secondary Email:
							</span>
							<a
								*ngIf="options.internal_secondary_email"
								href="mailto: {{
									options.internal_secondary_email
								}}"
								target="_blank"
							>
								{{ options.internal_secondary_email }}
							</a>
							<span *ngIf="!options.internal_secondary_email"
								>None</span
							>
						</div>
						<div>
							<span class="metric-label"
								>Public Completed Onboarding:
							</span>
							<span
								[class.error]="
									!options.public_completed_onboarding
								"
							>
								{{
									options.public_completed_onboarding
										? 'Completed'
										: 'Incomplete'
								}}
							</span>
						</div>
					</div>
				</div>
			</div>
		</mat-card-content>

		<div class="ctw-mt-10">
			<mat-card-content *ngIf="this.data['fingerprint_accessed']">
				<div class="second-category">
					<div class="category">
						<h4><b>Fingerprints on this Account</b></h4>
						<hr />
					</div>
					<div class="category-information">
						<ul>
							<li
								*ngFor="
									let fingerPrint of this.data[
										'fingerprint_accessed'
									]
								"
							>
								{{ fingerPrint }}
							</li>
						</ul>
					</div>
				</div>
				<div class="second-category">
					<div class="category">
						<h4>
							<b
								>Accounts accessed by fingerprints on this
								account</b
							>
						</h4>
						<hr />
					</div>
					<div class="category-information">
						<ul>
							<li
								*ngFor="
									let fingerPrintAccount of this.data[
										'fingerprint_accounts'
									]
								"
							>
								<a
									[routerLink]="[
										'/agent/user/',
										fingerPrintAccount.account_id
									]"
									>{{ fingerPrintAccount.email }}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</mat-card-content>

			<mat-card-content *ngIf="this.id">
				<div class=" ctw-mt-10 ctw-mb-10">
					<div class="category-table">
						<h4>Update Account Options</h4>
						<hr />
					</div>
					<central-account-options  [userId]="this.id"></central-account-options>
				</div>
			</mat-card-content>

			<mat-card-content>
				<div class="category-table">
					<h4>Organizations</h4>
					<hr />
				</div>
				<central-user-table
					class="organizations-table"
				></central-user-table>
			</mat-card-content>
		</div>
	</div>

	<!-- Fail State -->
	<div *ngIf="state === 'fail'" class="fail">
		<h3 class="error">Failed to load the user's profile.</h3>
		<button mat-raised-button (click)="refresh()">
			<mat-icon>refresh</mat-icon> Refresh
		</button>
	</div>
</div>
