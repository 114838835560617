<div class="heading" (click)="this.bottomSheetRef.dismiss()">
	{{ this.data.plugins.length }} Plugins to Install
	<mat-icon>keyboard_arrow_down</mat-icon>
</div>
<div class="plugin" *ngFor="let plugin of data.plugins">
	<mat-icon
		class="remove"
		aria-label="Remove"
		[matTooltip]="'Remove ' + (plugin.name || plugin.label)"
		(click)="this.removeAction(plugin)"
		>close</mat-icon
	>
	<img
		*ngIf="plugin.icons"
		class="plugin-icon"
		[src]="plugin.icons['default'] || plugin.icons['1x']"
	/>
	<span *ngIf="!plugin.icons" class="custom-icon"
		><mat-icon>settings</mat-icon></span
	>
	<div>
		<div class="plugin-title">{{ plugin.name || plugin.label }}</div>
		<div class="description">
			{{
				trimText(
					plugin.short_description ||
						plugin.description ||
						plugin.url ||
						''
				)
			}}
		</div>
	</div>
</div>
<div class="ctw-text-center action-button">
	<button
		mat-button
		mat-raised-button
		color="accent"
		(click)="this.bottomSheetRef.dismiss(); this.next.emit()"
	>
		Next Step <mat-icon>keyboard_arrow_right</mat-icon>
	</button>
</div>
