import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@central/ng-shared';
import { ApiService } from '@central/ng-shared';
import tables from './tables/index';
import { LicenseService } from '../../shared/license/license.service';

@Component({
	selector: 'product-catalog',
	templateUrl: 'catalog.component.html',
	styleUrls: ['catalog.component.scss'],
})
export class CatalogComponent implements OnInit {
	@Input() public connectId: number;
	@Input() public products: string[] = [];
	@Input() public display = 'tables';
	@Input() public tags: string[] = [];
	@Input() public packageData = { 'product-type': 'key' };
	@Output() public catalogSelect = new EventEmitter();

	public planTerm = 'yearly';
	public status = 'loading';
	public view = 'compare';
	public tables = tables;
	public selection: any;
	public restrictFreeKey = false;

	public additionalOptions = [];

	public constructor(
		public apiService: ApiService,
		public authService: AuthService,
		private licenseService: LicenseService,
	) {}

	public ngOnInit() {
		this._getPackages();
	}

	/**
	 * Run when a user selects.
	 *
	 * @since 1.23.0
	 *
	 * @param  selection Currently selected pricing table.
	 */
	public select(selection: any): void {
		if (this.restrictFreeKey && !selection.isFree) {
			this.view = 'premium-only';
		} else {
			this.selection = selection;
			this.catalogSelect.emit({ selection });
		}
	}

	/**
	 * Get valid tables to display, filter by tags.
	 *
	 * @since 1.23.0
	 *
	 * @return array List of tables.
	 */
	private _filterTables(): void {
		let filtered = this.tables;

		if (this.products.length) {
			filtered = this.tables.filter(
				(table) => !!this.products.find((val) => val === table.name)
			);
		} else if (this.tags.length) {
			filtered = this.tables.filter((table) => {
				const difference = this.tags.filter(
					(tag) => !table.tags.includes(tag)
				);
				return !difference.length;
			});
		}

		if (this.connectId) {
			// Make sure that the user doesn't own any licenses in the set.
			const licenseNames = this.licenseService.getKeyLicensesNames(
				this.connectId
			);
			filtered = filtered.filter((table) => {
				const difference = table.licenseNames.filter(
					(name) => !licenseNames.includes(name)
				);
				return difference.length === table.licenseNames.length;
			});
		}

		this.status = filtered.length ? 'pending-selection' : 'fail';
		this.tables = filtered;
	}

	/**
	 * Given a list of products from the API, update the table data.
	 *
	 * @since 1.23.0
	 *
	 * @param  packageData Package Data.
	 */
	private _assignProducts(products: object[]): void {
		for (const table of this.tables) {
			table['products'] = this.findProducts(products, table.productKeys);
			table['price'] = this.getPackagePrice(table['products']);
			table['isFree'] = !table.tags.includes('premium');
		}
	}

	private findProducts(catalog: any[], productKeys: any[]) {
		const products = [];
		for (const productCode of productKeys) {
			let product = catalog.find((val) => val.code === productCode + '_' + this.planTerm);

			if (product) {
				product.term = {
					planCode: product.code,
				};
			}

			if (!product) {
				for (const plan of catalog) {
					if (plan.items && plan.items.length) {
						product = plan.items.find((item) => item.code === productCode);

						product.term = {
							planCode: plan.code,
						};
					}
				}
			}

			products.push(product);
		}

		return products;
	}

	/**
	 * Get the price of a set of products.
	 *
	 * @since 1.23.0
	 *
	 * @param  products List of products.
	 * @return          Price
	 */
	private getPackagePrice(products: any[]): number {
		let price = 0;
		for (const product of products) {
			price = price + +product.price_in_cents;
		}

		return price / 100;
	}

	/**
	 * If there is only 1 plan available for any product. Select the product move on to payment.
	 *
	 * @since 1.23.0
	 */
	private _autoSelect(): void {
		if (this.tables.length === 1) {
			this.select(this.tables[0]);
		}
	}

	/**
	 * Fetch the licenses a user can use.
	 *
	 * @since 1.23.0
	 */
	private _fetchLicenses(): void {
		this.licenseService.onReady().subscribe(
			() => {
				if (
					!this.connectId ||
					this.licenseService.isKeyOwner(this.connectId)
				) {
					this._filterTables();
					this.selection =
						this.tables.length && this.tables[1]
							? this.tables[1]
							: null;
					this._autoSelect();
				} else {
					this.status = 'fail';
				}
			},
			() => (this.status = 'fail')
		);
	}

	/**
	 * Make an Api Call to list the available packes for the user to choose from.
	 *
	 * @since 1.23.0
	 */
	private _getPackages(): void {
		this.status = 'loading';

		this.apiService.get('/v1/wpcr/catalog', {}).subscribe(
			(products: any) => {
				this._assignProducts(products);
				this._fetchLicenses();
			},
			() => (this.status = 'fail')
		);
	}
}
