<div class="ctw-flex ctw-flex-col ctw-justify-center ctw-items-center" *ngIf="this.status === 'loading' && 0">
	<mat-spinner diameter="60" color="primary"></mat-spinner>
	<h3 class="ctw-pt-8">Loading...</h3>
</div>
<ng-container *ngIf="status === 'failed'">
	<p><strong>Sorry! An error occurred.</strong></p>
	<p>
		We were unable to retrieve your requested products. Please go back and
		try again.
	</p>
</ng-container>
<ng-container *ngIf="status === 'success'">
	<div class="plan-selector" [class.alternate]="altDisplay">
		<div class="selections">
			<div class="option slider">
				<h3><strong>Performance Tier</strong></h3>
				<ngx-slider [(value)]="value" [options]="options" (userChange)="this.setStats($event)"
					(userChangeEnd)="this.onUserChange($event)"></ngx-slider>
			</div>
		</div>
	</div>
	<div class="ctw-grid ctw-shadow-lg ctw-mt-4">
		<div
			class="ctw-bg-white ctw-text-xl ctw-border-l ctw-border-r ctw-border-t ctw-rounded-t-lg ctw-border-solid ctw-border-gray-300 ctw-pt-4 ctw-px-8">
			<div *ngIf="this.isUpdatePlan && (this.currentStats?.code !== this.stats.code); else isNotUpdatePlan"
				class="ctw-font-bold">
				{{ this.currentStats?.name || '' }} → <span class="ctw-text-imh-blue">{{
					this.stats?.name || '' }}</span>
			</div>
			<ng-template #isNotUpdatePlan>
				<span class="ctw-font-bold">{{ this.stats.name }}</span>
			</ng-template>
			<div class="ctw-text-base ctw-mt-2">
				{{ stats.recommendText }}
			</div>
			<hr class="ctw-mb-0 ctw-mt-4">
		</div>
	</div>
	<div class="stats" *ngIf="altDisplay">
		<mat-list
			class="ctw-grid ctw-bg-white ctw-shadow-lg ctw-grid-cols-3 ctw-px-8 ctw-border-l ctw-border-r ctw-border-solid ctw-border-gray-300">
			<mat-list-item>
				<mat-icon mat-list-icon>storage</mat-icon>
				<div mat-line class="label ctw-text-base ctw-font-bold">
					{{ stats.isNVMe ? 'NVMe Storage' : 'SSD Storage' }}
				</div>
				<div mat-line class="value ctw-text-xl"
					[innerHTML]="this.getStat(this.currentStats.storage, stats.storage)"></div>
			</mat-list-item>
			<mat-list-item>
				<mat-icon mat-list-icon class="ctw-transform ctw-rotate-90">developer_board</mat-icon>
				<div mat-line class="label ctw-text-base ctw-font-bold">RAM</div>
				<div mat-line class="value ctw-text-xl" [innerHTML]="this.getStat(this.currentStats.ram, stats.ram)">
				</div>
			</mat-list-item>
			<mat-list-item>
				<mat-icon mat-list-icon>memory</mat-icon>
				<div mat-line class="label ctw-text-base ctw-font-bold">vCPU</div>
				<div mat-line class="value ctw-text-xl" [innerHTML]="this.getStat(this.currentStats.cpu, stats.cpu)">
				</div>
			</mat-list-item>
			<mat-list-item>
				<mat-icon mat-list-icon>cloud</mat-icon>
				<div mat-line class="label ctw-text-base ctw-font-bold">Transfer</div>
				<div mat-line class="value ctw-text-xl"
					[innerHTML]="this.getStat(this.currentStats.transfer, stats.transfer)"></div>
			</mat-list-item>
            <mat-list-item>
                <mat-icon mat-list-icon>rocket</mat-icon>
                <div mat-line class="label ctw-text-base ctw-font-bold">Launch Assist</div>
                <div mat-line class="value ctw-text-xl"
                    [innerHTML]="this.getStat((this.currentStats.includeLaunchAssist ? 'Included' : 'No'), (stats.includeLaunchAssist ? 'Included' : 'No'))">
                </div>
            </mat-list-item>
			<mat-list-item>
				<mat-icon mat-list-icon>support_agent</mat-icon>
				<div mat-line class="label ctw-text-base ctw-font-bold">24/7 Support</div>
				<div mat-line class="value ctw-text-xl"
					[innerHTML]="this.getStat(this.currentStats.phoneSupport, stats.phoneSupport)"></div>
			</mat-list-item>
		</mat-list>
		<div class="price ctw-border-l ctw-border-r ctw-border-t ctw-border-solid ctw-border-gray-300 ctw-flex ctw-flex-row ctw-justify-center ctw-shadow-lg ctw-overflow-hidden"
			[class.ctw-rounded-b-lg]="this.isUpdatePlan" [class.ctw-border-b]="this.isUpdatePlan">
			<div class="ctw-flex ctw-flex-row ctw-justify-center ctw-w-full"
				*ngIf="!this.isUpdatePlan else updatePlanPrices">
				<ng-container *ngIf="!this.couponData">
					<div class="mat-list-item ctw-flex ctw-flex-row ctw-justify-center ctw-p-4">
						<div class="ctw-flex ctw-flex-row ctw-items-center ctw-font-bold">
							<div mat-line class="ctw-text-2xl">
								{{ this.getServerPrice('monthly') }}/mo.
							</div>
							<div mat-line class="ctw-font-normal ctw-px-4">OR</div>
							<div mat-line class="ctw-text-2xl">
								{{ this.getServerPrice('yearly') }}/yr.
							</div>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="this.couponData">
					<div class="coupon ctw-bg-black ctw-p-4 ctw-text-center ctw-w-4/12">
						<div class="ctw-flex ctw-items-center ctw-justify-center ctw-font-bold ctw-text-2xl ctw--ml-6">
							<mat-icon class="ctw-mr-2" mat-list-icon>loyalty</mat-icon>
							<span> {{ this.discount }} OFF </span>
						</div>
						<div class="ctw-mt-2 ctw-text-sm ctw-text-imh-gray-200 ctw--mr-6">
							<span> {{ this.coupon }} </span>
						</div>
					</div>
					<div class="mat-list-item ctw-text-center ctw-p-4 ctw-w-9/12">
						<div class="ctw-text-2xl ctw-font-bold">
							<span *ngIf="this.couponData.plan_codes.length > 1 && this.getTodaysPrice() !== '$0.00'">
								Starting At {{ this.getTodaysPrice() }}<span
									*ngIf="this.couponData.plan_codes.includes('wpc_project_yearly')">/mo.</span>
							</span>
							<span *ngIf="this.couponData.plan_codes.length === 1 || this.getTodaysPrice() === '$0.00'">
								You Pay
								<span *ngIf="this.getTodaysPrice() === '$0.00'"> Nothing </span>
								<span *ngIf="this.getTodaysPrice() !== '$0.00'"> Only {{ this.getTodaysPrice() }}<span
										*ngIf="this.couponData.plan_codes.includes('wpc_project_yearly')">/mo.</span>
								</span>
								Today
							</span>
						</div>
						<div class="ctw-mt-2 ctw-text-sm ctw-text-imh-gray-200">
							Renews at
							<span
								*ngIf="!this.couponData || this.couponData.plan_codes.includes('wpc_project_monthly')">
								{{ this.getServerPrice('monthly') }}/mo.
							</span>
							<span *ngIf="!this.couponData || this.couponData.plan_codes.length > 1">or</span>
							<span *ngIf="!this.couponData || this.couponData.plan_codes.includes('wpc_project_yearly')">
								{{ this.getServerPrice('yearly') }}/yr.
							</span>
						</div>
					</div>
				</ng-container>
			</div>
			<ng-template #updatePlanPrices>
				<div *ngIf="this.notAllowed" class="ctw-p-4 ctw-text-lg ctw-flex">
					<mat-icon class="ctw-text-imh-gray-200">warning</mat-icon><span
						class="ctw-text-2xl">&nbsp;</span><span>You are currently using
						<strong>{{ this.currentSize }} GB</strong> of
						storage
						and
						cannot scale
						down to {{
						this.stats.code.replace( 'wphosting_tier_', 'Tier ' )
						}}.</span>
				</div>
				<div *ngIf="!this.notAllowed" class="ctw-flex ctw-justify-center ctw-p-4">
					<div mat-line class="ctw-text-2xl">
						<div *ngIf="this.stats.code !== this.currentStats.code else samePrice">
							<div class="ctw-flex ctw-flex-row ctw-items-center">
								<div mat-line class="ctw-text-2xl">
									{{ getServerPrice(this.termType, 'currentServer') + (this.termType === 'monthly' ?
									'/mo.' : '/yr.') }}
								</div>
								<div mat-line class="ctw-text-lg ctw-px-4">→</div>
								<div mat-line class="ctw-text-2xl"><span class="ctw-font-bold ctw-text-imh-blue">{{
										getServerPrice(this.termType,
										'selected') + (this.termType === 'monthly' ? '/mo.' : '/yr.') }}</span>
								</div>
							</div>
						</div>
						<ng-template #samePrice>
							<div class="ctw-flex ctw-flex-row ctw-items-center">
								<div mat-line class="ctw-text-2xl">Current Size</div>
								<div mat-line class="ctw-text-lg ctw-px-4">@</div>
								<div mat-line class="ctw-text-2xl">
									{{ getServerPrice(this.termType, 'currentServer') + (this.termType ===
									'monthly' ? '/mo.' : '/yr.') }}
								</div>
							</div>
						</ng-template>
					</div>
				</div>
			</ng-template>
		</div>
		<ng-container *ngIf="!this.isUpdatePlan && this.configurationService.config.features.dataCenterSelection">
			<div class="ctw-shadow-lg ctw-rounded-b-lg">
				<mat-button-toggle-group
					class="ctw-grid ctw-grid-cols-3 ctw-shadow-none ctw-rounded-b-lg ctw-rounded-t-none ctw-border-l ctw-border-r ctw-border-b ctw-border-solid ctw-border-gray-300"
					#group="matButtonToggleGroup" [value]="this.dataCenter" (change)="onDataCenterChange(group.value)"
					aria-label="Data Center">
					<mat-button-toggle [disabled]="!this.isProvisionAvailable('lax')" value="lax">
						<div class="ctw-flex">
							<span class="us flag"></span>
							<span>US West</span>
						</div>
					</mat-button-toggle>
					<mat-button-toggle [disabled]="!this.isProvisionAvailable('iad')" value="iad">
						<div class="ctw-flex">
							<span class="us flag"></span>
							<span>US East/Central</span>
						</div>
					</mat-button-toggle>
					<mat-button-toggle [disabled]="!this.isProvisionAvailable('ams')" value="ams">
						<div class="ctw-flex">
							<span class="eu flag"></span>
							<span>EU Central</span>
						</div>
					</mat-button-toggle>
				</mat-button-toggle-group>
			</div>
		</ng-container>
		<div class="ctw-text-lg ctw-text-center ctw-mt-6" *ngIf="!this.isUpdatePlan && noneAvailable">
			This plan level is currently unavailable. Please check back later or choose a different server tier.
		</div>
	</div>
</ng-container>
