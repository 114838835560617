import { Route } from '@angular/router';
import { GuestGuard } from '@central/ng-shared';
import { SubscriptionComponent } from './index';

export const SubscriptionRoutes: Route[] = [
	{
		path: 'subscriptions',
		component: SubscriptionComponent,
		canActivate: [GuestGuard],
	},
];
