<dash-card *ngIf="enableSsl === false && playbookError === false && playbookRunning === false"  cardTitle="Auto SSL" icon="no_encryption" [disabled]="true">
	<ng-container subTitleHTML>
		<span class="sub-title ctw-text-base">
			<span class="ctw-text-red-700">
				Disabled
			</span>
		</span>
	</ng-container>
	<div class="dash-card-content ctw-p-8">
		<p class="ctw-mb-4">
			Auto SSL is currently disabled. Your site is currently using a <a href="https://www.inmotionhosting.com/support/website/ssl/self-signed-ssl-certificate-warning/">Self-Signed Certificate</a>. Enable Auto SSL to install a signed certifcate from LetsEncrypt and avoid browser warnings.
		</p>
		<div class="ctw-text-right">
			<form class="example-form" #form="ngForm" (ngSubmit)="setOverrideState(form.form)">
				<mat-slide-toggle [(ngModel)]="enableSsl" name="enableSSL" color="primary" (toggleChange)="setOverrideState(form.form)">Enable
					AutoSSL</mat-slide-toggle>
			</form>
		</div>
	</div>
</dash-card>
<dash-card *ngIf="enableSsl === true && playbookError === false && playbookRunning === false"  cardTitle="Auto SSL" icon="vpn_lock" [disabled]="true">
	<ng-container subTitleHTML>
		<span class="sub-title ctw-text-base">
			<span class="ctw-text-green-700">
				Enabled
			</span>
		</span>
	</ng-container>
	<div class="dash-card-content ctw-p-8">
		<p class="ctw-mb-4">
			Your LetsEncrypt SSL is installed and functioning. It will be automatically renewed every 3 months.
		</p>
		<div class="ctw-text-right">
			<form class="example-form" #form="ngForm" (ngSubmit)="setOverrideState(form.form)">
				<mat-slide-toggle [(ngModel)]="enableSsl" name="enableSSL" color="primary" (toggleChange)="openDialogue()">Disable AutoSSL</mat-slide-toggle>
			</form>
		</div>
	</div>
</dash-card>
<dash-card *ngIf="enableSsl === false && playbookError === false && playbookRunning === true"  cardTitle="Auto SSL" icon="no_encryption" [disabled]="true">
	<mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
	<ng-container subTitleHTML>
		<span class="sub-title ctw-text-base">
			<span class="ctw-text-red-700">
				In Progress
			</span>
		</span>
	</ng-container>
	<div class="dash-card-content ctw-p-8">
		<p class="ctw-mb-4">
			Your SSL is currently being removed. This process may take a few minutes. You may need to <a href="https://www.inmotionhosting.com/support/website/how-to-clear-browser-cache-for-major-browsers/">clear your browser's cache</a> after it has been uninstalled.
		</p>
		<div class="ctw-text-right">
			<form class="example-form" #form="ngForm" (ngSubmit)="setOverrideState(form.form)">
				<mat-slide-toggle disabled [(ngModel)]="enableSsl" name="enableSSL" color="primary" (toggleChange)="setOverrideState(form.form)">Enable
					AutoSSL</mat-slide-toggle>
			</form>
		</div>
	</div>
</dash-card>
<dash-card *ngIf="enableSsl === true && playbookError === false && playbookRunning === true"  cardTitle="Auto SSL" icon="lock" [disabled]="true">
	<mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
	<ng-container subTitleHTML>
		<span class="sub-title ctw-text-base">
			<span class="ctw-text-green-700">
				In Progress
			</span>
		</span>
	</ng-container>
	<div class="dash-card-content ctw-p-8">
		<p class="ctw-mb-4">
			Your SSL is currently being issued by LetsEncrypt. This process may take a few minutes. You may need to <a href="https://www.inmotionhosting.com/support/website/how-to-clear-browser-cache-for-major-browsers/">clear your browser's cache</a> after it has been installed.
		</p>
		<div class="ctw-text-right">
			<form class="example-form" #form="ngForm" (ngSubmit)="setOverrideState(form.form)">
				<mat-slide-toggle disabled [(ngModel)]="enableSsl" name="enableSSL" color="primary" (toggleChange)="setOverrideState(form.form)">Enable
					AutoSSL</mat-slide-toggle>
			</form>
		</div>
	</div>
</dash-card>
<dash-card *ngIf="playbookError"  cardTitle="Auto SSL" icon="warning" [disabled]="true">
	<ng-container subTitleHTML>
		<span class="sub-title ctw-text-base">
			<span class="ctw-text-red-700">
				Problem
			</span>
		</span>
	</ng-container>
	<div class="dash-card-content ctw-p-8">
		<p class="ctw-mb-4">
			A problem occurred while your SSL was being installed. Usually this is due to your DNS A record not being pointed or fully propagated, or sometimes may be caused by a conflict with a CDN. Learn more about <a href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/using-letsencrypt">troubleshooting LetsEncrypt</a>.
		</p>
		<div class="ctw-text-right">
			<form class="example-form" #form="ngForm" (ngSubmit)="setOverrideState(form.form)">
				<mat-slide-toggle [(ngModel)]="enableSsl" name="enableSSL" color="primary" (toggleChange)="setOverrideState(form.form)">Enable
					AutoSSL</mat-slide-toggle>
			</form>
		</div>
	</div>
</dash-card>
