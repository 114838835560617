import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../project.service';
import { WpRestService } from '../rest.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
	selector: 'bgc-themes',
	templateUrl: './themes.component.html',
	styleUrls: ['./themes.component.scss', '../plugins/resource-display.scss'],
})
export class ThemesComponent implements OnInit {
	public status = 'loading';
	public themes = [];

	public recommended = [
		{
			slug: 'crio',
			name: 'Crio Super Theme',
			ThemeURI: 'https://www.boldgrid.com/themes/crio',
			img: 'https://i0.wp.com/themes.svn.wordpress.org/crio/2.2.9/screenshot.png',
			description: `Crio is a WordPress SuperTheme that allows front-end designers, developers and other web
				professionals to create without bounds or restrictions. Crio’s advanced customization options
				are completely integrated with the WordPress Customizer API`,
		},
	];

	public displayedColumns: string[] = ['name', 'version'];
	public displayedSuggested: string[] = ['name', 'activate'];

	public action = {
		label: 'Activate',
		event: (theme) => this.install(theme),
	};

	constructor(
		public projectService: ProjectService,
		public wpRestService: WpRestService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.getPlaybookStatus();
		this.projectService.connected.subscribe((msg: any) => {
			if (
				msg === 'ready' &&
				this.projectService.client &&
				this.status !== 'ready' &&
				this.status !== 'blocked'
			) {
				this.fetch();
			}

			if (msg !== 'ready') {
				this.status = 'blocked';
			}
		});
	}

	/**
	 * Gets status of project's playbook runs
	 */
	getPlaybookStatus() {
		this.projectService.playbookStatus.subscribe((msg: any) => {
			this.status = msg === 'playbook-running' ? 'blocked' : this.status;
			if (
				this.status === 'blocked' &&
				(msg === 'playbook-not-running' || msg === 'complete')
			) {
				this.status = 'ready';
				this.fetch();
			}
		});
	}

	public install(theme) {
		theme.state = 'submitted';
		this.snackBar.open('Installing ' + theme.name, '', {
			duration: 2000,
			horizontalPosition: 'right',
		});

		this.projectService.client
			.namespace('bgc/v1')
			.themes()
			.create({ themes: [theme.slug], active_stylesheet: theme.slug })
			.then((themes) => {
				this.themes = themes;

				this.snackBar.open('Installed Successfully!', '', {
					duration: 3000,
					horizontalPosition: 'right',
				});

				theme.actionDisabled = true;
				theme.state = 'success';
				this.projectService.updateScreenshot(true);
				this.filterRecommended();
			})
			.catch((error) => {
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								this.install(theme);
							},
							() => {
								this.snackBar.open('Unable to Install', '', {
									duration: 3000,
									horizontalPosition: 'right',
								});

								theme.state = 'fail';
							}
						);
				} else {
					this.snackBar.open('Unable to Install', '', {
						duration: 3000,
						horizontalPosition: 'right',
					});

					theme.state = 'fail';
				}
			});
	}
	/**
	 * Remove plugins from recommended list that are already installed.
	 */
	private filterRecommended(): void {
		this.recommended = this.recommended.filter((theme) => (
				-1 ===
				this.themes.findIndex((val) => val.ThemeURI === theme.ThemeURI)
			));
	}

	private fetch() {
		this.projectService.client
			.namespace('bgc/v1')
			.themes()
			.get()
			.then((themes) => {
				this.themes = themes;
				this.filterRecommended();
				this.status = 'ready';
			})
			.catch((error) => {
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								this.fetch();
							},
							() => {
								this.status = 'failed-loading';
							}
						);
				} else {
					this.status = 'failed-loading';
				}
			});
	}
}
