import { Component } from '@angular/core';
import { AuthService } from '../../authentication/services';
import { ConfigurationService } from '../../core';
import { AccountService } from '../account.service';

@Component({
	selector: 'central-account-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
	public isAdmin;
	public features;

	constructor(
		public accountService: AccountService,
		public authService: AuthService,
		public configService: ConfigurationService
	) {
		this.isAdmin = this.authService.jwtHelper.getTokenVal('admin_login');
		this.features = this.configService.config.features;
	}
}
