<form
	#form="ngForm"
	(ngSubmit)="phoneNumber.isValid() && form.valid && submit()"
	class="form"
	[class]="
		this.configService.config.brandConfig.id === 'imh'
			? 'animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-2xl ctw-mt-8 ctw-mb-8'
			: ''
	"
>
	<div class="ctw-mb-10">
		<h2>Personal Profile</h2>
		<p [hidden]="this.configService.config.brandConfig.id === 'imh'">
			Update your personal information
		</p>
	</div>
	<mat-form-field
		appearance="{{ this.configService.config.inputAppearance || 'fill' }}"
	>
		<mat-label>Name</mat-label>
		<input
			matInput
			[(ngModel)]="name"
			[disabled]="!this.accountService.hasAccess('write')"
			required
			#nameField="ngModel"
			name="name"
			placeholder="Name"
		/>
		<mat-error>
			<ng-container *ngIf="nameField.errors?.required"
				>This field is required.</ng-container
			>
		</mat-error>
	</mat-form-field>

	<mat-form-field
		appearance="{{ this.configService.config.inputAppearance || 'fill' }}"
	>
		<mat-label>Primary Email</mat-label>
		<input
			[(ngModel)]="primaryEmail"
			[disabled]="!this.accountService.hasAccess('write')"
			email
			type="email"
			#primaryEmailField="ngModel"
			required
			matInput
			name="primaryEmail"
			placeholder="jdoe@company.com"
		/>
		<mat-error>
			<ng-container *ngIf="primaryEmailField.errors?.required"
				>This field is required.</ng-container
			>
			<ng-container *ngIf="primaryEmailField.errors?.email"
				>Invalid Format. Please use the following format
				user@domain.tld</ng-container
			>
		</mat-error>
	</mat-form-field>

	<mat-form-field
		appearance="fill"
		*ngIf="
			this.accountService.apiService.configService.config.stackName ===
			'openmetal_central'
		"
	>
		<mat-label>Business Contact Email</mat-label>
		<input
			[(ngModel)]="secondaryEmail"
			[disabled]="!this.accountService.hasAccess('write')"
			email
			type="email"
			#secondaryEmailField="ngModel"
			required
			centralFreeEmailValidator
			matInput
			name="secondaryEmail"
			placeholder="jdoe@company.com"
		/>
		<mat-error>
			<ng-container *ngIf="secondaryEmailField.errors?.required"
				>This field is required.</ng-container
			>
			<ng-container *ngIf="secondaryEmailField.errors?.email"
				>Invalid Format. Please use the following format
				user@domain.tld</ng-container
			>
			<ng-container *ngIf="secondaryEmailField.errors?.freeEmail"
				>Invalid Email. Please use an email from a non free email
				provider.</ng-container
			>
		</mat-error>
	</mat-form-field>

	<div [hidden]="!this.accountService.hasAccess('write')">
		<central-phone-number
			[value]="this.contactPhone"
			#phoneNumber
		></central-phone-number>
	</div>

	<div class="update-message ctw-text-center ctw-mt-5">
		<div class="success" [class.visible]="this.state === 'success'">
			Successfully updated your account details.
		</div>
		<div class="error" [class.visible]="this.state === 'failed'">
			An error occurred while processing your request.
		</div>
	</div>
	<div class="action-buttons ctw-text-right">
		<progress-button
			[state]="state"
			color="{{ this.configService.config.buttonColor || 'primary' }}"
			>Update
			{{
				this.accountService.apiService.configService.config.brandConfig
					.id === 'imh'
					? 'Contact Info'
					: ''
			}}</progress-button
		>
	</div>
</form>
