<div class="logged-in-as" *ngIf="this.isComplete() && !this.hideSignedInAs">
	<img class="gravitar" [src]="this.authService.profile.data.image" />
	<p class="lead">Currently logged in as:</p>
	<p class="email">
		<strong>{{ this.authService.profile.data.account_name }}</strong>
	</p>
	<hr />

	<a *ngIf="!this.isNewUser() && this.logoutEnabled" class="sign-out" (click)="this.reset()">Log out</a>
</div>
<div class="signup-login" [hidden]="this.isComplete()">
	<div [hidden]="hideIntro">
		<p *ngIf="
				!ref.innerHTML.trim() &&
				loginForm.state === 'pending' &&
				this.enableDescription
			">
			Set up a free
			<span class="bg-central">{{ brandingService.getAppName() }}</span>
			account or use an existing one.
		</p>
		<p #ref [hidden]="loginForm.state !== 'pending'">
			<ng-content></ng-content>
		</p>
	</div>
	<login-form #loginForm [isGuide]="this.isGuide" [disableRedirect]="true" [autoSignup]="true"
		[wasInvited]="wasInvited"></login-form>
</div>
