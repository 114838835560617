import { Component } from '@angular/core';

@Component({
	selector: 'central-dns-manager-delete-confirmation-dialog',
	template: `
		<h3 mat-dialog-title>Delete DNS Record?</h3>
		<mat-dialog-actions>
			<div class="ctw-text-right ctw-w-full">
				<button mat-button [mat-dialog-close]="false">Cancel</button>
				<button
					mat-raised-button
					[mat-dialog-close]="true"
					color="warn"
				>
					Delete
				</button>
			</div>
		</mat-dialog-actions>
	`,
	styles: [
		'.mat-dialog-actions { box-sizing: content-box; }',
		'.expand { flex-grow: 1; }',
	],
})
export class DnsManagerDeleteConfirmationDialogComponent {}
