import {
	Location,
	LocationStrategy,
	PathLocationStrategy,
} from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	AfterViewInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import {
	ApiService,
	AuthService,
	ConfigurationService,
	RecurlyCreditCardComponent,
	AccountService,
	ProfileService,
} from '@central/ng-shared';
import { Config } from '../../shared/config/env.config';
import { LocalStorageService } from '@central/ng-shared';
import { TrackingService } from '@central/ng-shared';
import { CartService } from '../cart/cart.service';
import { UpgradeDialogComponent } from '../upgrade-dialog/upgrade-dialog.component';
import { PaymentService } from './payment.service';
import { ScriptService } from '@central/ng-shared';
import { Subscription } from 'rxjs';

// tslint:disable-next-line:no-submodule-imports
import { filter } from 'rxjs/operators';
import { BrandingService } from '@central/ng-shared';
import { LicenseService } from '../../shared/license/license.service';
import { ProjectService } from '../../project/project.service';

@Component({
	selector: 'payment-form',
	templateUrl: 'payment.component.html',
	styleUrls: ['payment.component.scss'],
	providers: [
		Location,
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
	],
})
export class PaymentComponent implements OnDestroy, OnInit, AfterViewInit {
	@Input() public packages: any[] = null;
	@Input() public hideGuarantee = false;
	@Input() public includeReceiptQuery = false;
	@Input() public terms: string;
	@Input() public cardClass = 'card-medium';
	@Input() public includeBackButton = true;
	@Input() public useCartService = false;
	@Input() public excludedInputs = ['email'];
	@Input() public returnWpUrl: string;
	@Input() public disablePostPurchase: string;
	@Input() public discountCode: string;
	@Input() public term: string;
	@Input() public organizationId: string;
	@Input() public projectId: string;
	@Input() public discountObject: object;
	@Input() public couponData: any;
	@Input() public isUpdatePlan = false;
	@Input() public isAddPlan = false;
	@Input() public subscriptionUuid: string;
	@Input() public forceLogin = false;
	@Input() public loginModalTitle: string;
	@Input() public loginModalSubTitle: string;
	@Input() public minimalForm = false;

	@Output() public paymentBack = new EventEmitter();
	@Output() public paymentSubmit = new EventEmitter();
	@Output() public paymentSuccess = new EventEmitter();
	@Output() public paymentFailure = new EventEmitter();
    @Output() public paymentReady = new EventEmitter();

	@Output() public update = new EventEmitter();
	@Output() public cancel = new EventEmitter();

	@Output() public setProjectId = new EventEmitter();

	@ViewChild('contactInputs') public contactInputs: any;
	@ViewChild('form') public form: any;
	@ViewChild(RecurlyCreditCardComponent, { static: false })
	public recurlyCC: any;
	@ViewChild('discount', { static: true }) public discount: any;
	@ViewChild('cart') public cart: any;
	@ViewChild(RecaptchaComponent) public captcha: RecaptchaComponent;

	public errorMessage = '';
	public receipt: any;
	public isNewUser = false;
	public captchaSubmitted = false;
	public captchaRequired = true;
	public state = 'pending';
	public isPreviousOrder = false;
	public cartLoaded = false;
	public hasRecurring = false;
	public processingFreeCart = false;
	public defaultFailMessage =
		'We were unable to complete your request. Please try again later.';
	public captchaKey = Config['recaptcha']['publicKeyInvisible'];
	public paymentInfoCompleted = false;
	public termsAgree = false;
	public routeSubscription: any;
	private captchaResponse: string;
	private orderInitiated = false;
	public paymentMethod: any = {};
	public fetchState = 'pending';
	public changeCard = false;
	public updateState = 'pending';
	public hasInternalBilling = false;
	public selectedMethod = 'new';
	public message: string;
	public subscription: Subscription;
    public useTeamSelector = false;
    public teamPurchase = 'Purchase'

	constructor(
		public configurationService: ConfigurationService,
		public paymentService: PaymentService,
		public trackingService: TrackingService,
		public scriptService: ScriptService,
		public cartService: CartService,
		public dialog: MatDialog,
		public brandingService: BrandingService,
		private location: Location,
		private route: ActivatedRoute,
		private router: Router,
		private licenseService: LicenseService,
		private localStorage: LocalStorageService,
		private authService: AuthService,
		public apiService: ApiService,
		private projectService: ProjectService,
		private accountService: AccountService,
		public profileService: ProfileService,
	) {
		this.routeSubscription = this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => this.displayPreviousCompletion());
		if (
			Config.production &&
			'InMotion Hosting' !==
				this.configurationService.config.brandConfig.reseller
		) {
			this.scriptService.loadScript('postaffiliatepro').then(() => {
				try {
					window['PostAffTracker'].setAccountId('default1');
					window['PostAffTracker'].track();
				} catch (err) {
					console.log(err);
				}
			});
        }

        /**
		 * If this purchase is for a project,
		 * get the correct AccountId for purchases
		 */
		if (this.projectService.project) {
			this.organizationId = this.projectService.getAccountId();
			this.projectId = this.projectService.project.id;
		}
	}

	public ngOnInit() {
		this.authService.profile.onProfileUpdate.subscribe((profile: any) => {
			if (!this.projectService.project) {
				// Default to first team
				this.organizationId = profile.account_access[1].account_id;
				if (profile.account_access.length > 2) {
					this.useTeamSelector = true;
					this.packages.forEach((product) => {
						switch (product.planCode) {
							case 'email_account':
								this.teamPurchase = 'Email';
								break;
							case 'domain-gtld-1':
								this.teamPurchase = 'Domain';
								break;
						}
					});
				}
			}

			this.fetchBillingInformation();
		})

		// Pull packages from cart service if undefined.
		if (!this.packages || !this.packages.length) {
			// Pull in cart packages.
			if (this.useCartService) {
				this.packages = this.cartService.get() || [];
			}
		}

		this.recaptchaCheck();
		this.discount.autoload(this.discountCode);

		if (undefined === this.term) {
			this.term = 'yearly';
		}
		this.subscription = this.accountService.currentMessage.subscribe(
			(message) => {
				// Check for default message before fetching since we won't know ID right away.
				if (message !== 'default message source') {
					this.message = message;
					this.organizationId = message;
					this.fetchBillingInformation();
				}
			}
		);
	}

	ngAfterViewInit(): void {
		this.cart?.cartUpdated.subscribe(() => {
			this.term = this.cart.term;
		});
	}

	public ngOnDestroy() {
		this.routeSubscription.unsubscribe();
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

    public accountChanged($event) {
		setTimeout(() => {
			this.accountService.updateAccount($event['account_id']);
		}, 0);
	}

	public formSubmission() {
		if (this.captchaRequired) {
			this.captchaSubmitted = true;
			this.captcha.execute();
			setTimeout(() => (this.captchaSubmitted = false), 4000);
		} else {
			this.submit();
		}
	}

	public afterCartInit() {
		this.hasRecurring = this.cart.hasRecurring();
		this.cartLoaded = true;
		this._setupAuthEvent();

		// The cart has been loaded, see if this order is eligible for discounts.
		this.discount.findDiscounts(this.packages);

		if (!this.orderInitiated && !this.isPreviousOrder) {
			this.orderInitiated = true;
			this.trackOrderState('orderInitiated');
		}
	}

	public cartFailed() {
		return (
			this.cartLoaded &&
			'success' !== this.state &&
			this.cart &&
			'failed' === this.cart.status
		);
	}

	public invalidInputState(input: any) {
		const dirty = this.isDirty(input);
		return !input.valid && dirty && this.state !== 'submitted';
	}

	public isDirty(input: any) {
		return (input.dirty && input.touched) || this.state === 'failed';
	}

	/**
	 * Track the current state of an order.
	 *
	 * @since 1.1
	 *
	 * @param   state  The event to pass on to the tracking service.
	 */
	public trackOrderState(state: string, result?: any) {
		this.trackingService.track(state, {
			cart: this.cart,
			response: result,
		});
	}

	private flattenProducts() {
		let flattenedAddons = [];
		for (const product of this.receipt.products) {
			if (product.code === 'wpc_tukp') {
				product.article =
					'https://www.boldgrid.com/support/boldgrid-backup/';
			} else if (product.code === 'wpc_crio') {
				product.article =
					'https://www.boldgrid.com/support/boldgrid-crio/';
			} else if (product.code === 'wpc_ppbp') {
				product.article =
					'https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/addons/post-page-builder-premium/';
			}
			flattenedAddons = [...flattenedAddons, ...(product.addons || [])];
			delete product.addons;
		}

		return [...this.receipt.products, ...flattenedAddons];
	}

	public showForms() {
		return (
			this.state !== 'success' &&
			this.state !== 'submitted' &&
			!this.processingFreeCart
		);
	}

	public hasError() {
		return this.state === 'failed' && this.errorMessage;
	}

	public success(receipt: any) {
		this.receipt = receipt;
		this.receipt.products = this.flattenProducts();

		this.receipt.products.forEach((product) => {
			if (product.type === 'hosting') {
				if (undefined !== product.resource_ids?.project_id) {
					this.setProjectId.emit(product.resource_ids.project_id);
				}
			}
		});

		this.state = 'success';
		this.paymentSuccess.emit();
		this.licenseService.fetch();
		this.trackOrderState('purchase', receipt);
		this.scrollTop();

		this.trackingService.track('pageview', {
			page_path: '/checkout/order-complete',
		});

		// Store payment results.
		if (this.isUpdatePlan && this.includeReceiptQuery && receipt.invoice.invoice_number) {
			this.location.go(
				this.location.path().split('?')[0],
				'order=' + receipt.invoice.invoice_number
			);
			this.localStorage.setItem('recentPurchase', this.receipt);
		}

		if (receipt['login_token']) {
			this.authService.setLogin(receipt['login_token'], true);
		}

		if (this.isUpdatePlan || this.isAddPlan) {
			window.location.href = 'projects/' + this.projectService.project?.id;
		}
	}

	public fail(error: string) {
		this.paymentFailure.emit();
		this.state = 'failed';
		this.errorMessage = error;
		this.captcha.reset();
	}

	public scrollTop() {
		window.scrollTo(0, 0);
	}

	public processBasicKey() {
		this.processingFreeCart = true;
		this.state = 'submitted';

		this.process({
			cart: {
				plans: [
					{
						code: 'wpc_project_yearly',
						addons: [
						],
					},
				],
			},
		});
	}

	public recaptchaCheck() {
		this.authService
			.preAuthCheck('product-add')
			.subscribe(
				(validRequest) => (this.captchaRequired = !validRequest)
			);
	}

	public async submit(captchaResponse = null) {
		if (this.minimalForm) {
			await this.contactInputs.setAddress();
		}
		const submitValid = this.form.valid && this.contactInputs.isFormValid();

		this.captchaResponse = captchaResponse;

		// DONT CHARGE TWICE!!!!!
		const alreadyProcessed =
			this.state === 'submitted' || this.state === 'success';
		if (alreadyProcessed || (!captchaResponse && this.captchaRequired)) {
			return;
		}

		if (submitValid) {
			this.paymentSubmit.emit();
			this.state = 'submitted';
			this.isNewUser = !this.authService.isLoggedIn();
			if (this.authService.isGuideLocked()) {
				this.profileService.saveSalespersonTracking();
				this.profileService.saveImpactPartnerTracking();
			}
			this.contactInputs.updateStatus = 'submitted';
			this.errorMessage = '';
			this.scrollTop();
			this.processCreditOrder();
		} else {
			this.paymentFailure.emit();
			this.scrollTop();
			this.state = 'failed';
			this.contactInputs.updateStatus = 'failed';
			this.errorMessage = 'Please complete the form fields in red.';
			this.captcha.reset();
		}
	}

	/**
	 * If a hash was provided with a recent order number, pull the most recent order
	 * from local storage and display it.
	 *
	 * @since 1.13.0
	 */
	private displayPreviousCompletion() {
		const order = this.route.snapshot.queryParamMap.get('order') || '';
		this.isPreviousOrder = false;

		if (order) {
			const receipt = this.localStorage.getItem('recentPurchase');
			if (
				this.isUpdatePlan ||
				(
					receipt &&
					receipt.invoice &&
					+order === +receipt.invoice.invoice_number
				)
			) {
				this.receipt = receipt;
				this.state = 'success';
				this.isPreviousOrder = true;
			}
		}

		// User pressed back button after order completion.
		if (
			!this.isPreviousOrder &&
			this.receipt &&
			'CheckoutComponent' === this.route.component['name']
		) {
			window.location.reload();
		}
	}

	private process(chargeData: object) {
		// Because this may be called directly.
		this.state = 'submitted';

		chargeData['cart'] = chargeData['cart'] || this.cart.getPayload();
		chargeData['cart'].options.project_id = this.projectService.project?.id;

		if (this.authService.profile.data.onboarded) {
			chargeData['organizationId'] = this.organizationId;
		} else {
			chargeData['organizationId'] =
				this.authService.profile.data.account_access[1].account_id;
		}

		chargeData['subscriptionUuid'] = this.subscriptionUuid;

		this.paymentService.process(chargeData, this.isUpdatePlan).subscribe(
			(res: any) => {
				this.success(res);
			},
			(message: string) => {
				const errorMessage = message || this.defaultFailMessage;

				this.fail(errorMessage);
				this.scrollTop();
				this.trackingService.track('error', {
					category: 'payment',
					name: 'failed',
					data: errorMessage,
				});
			}
		);
	}

	private async processCreditOrder() {
		let tokenResponse;

		if (this.selectedMethod === 'new') {
			const purchaseData = this.contactInputs.model.getData();

			try {
				tokenResponse = await this.recurlyCC.getToken(purchaseData);
			} catch (e) {
				this.state = 'failed';
				this.errorMessage =
					e.message || 'Invalid form. Please complete all fields.';
				return;
			}
		}

		const chargeData = {
			token: tokenResponse,
			organizationId: this.organizationId || null,
			email: this.contactInputs.model.email,
			captcha: this.captchaResponse,
			couponCode: this.couponData?.coupon_code || null,
		};

		this.process(chargeData);
	}

	/**
	 * After a user is authenticated, make sure that we dont try to sell the user
	 * a new connect key when they actually wanted to upgrade.
	 *
	 * @since 1.23.0
	 */
	private displayUpgradeNotice(): void {
		this.licenseService.onReady().subscribe(() => {
			// If the user is purchasing a key service.
			if (this.cart.hasKeyService() && !this.cart.hasProductType('key')) {
				const licenses = this.licenseService.getLicenses();

				// User has multiple keys.
				if (licenses['connect_keys'].length > 1) {
					this.dialog.open(UpgradeDialogComponent, {
						width: '400px',
					});
				}
			}
		});
	}

	/**
	 * If on sign in, display an upgrade notice if needed.
	 *
	 * @since 1.23.0
	 */
	private _setupAuthEvent(): void {
		// Only do this on XOP.
		if (this.contactInputs.emailInput) {
			this.contactInputs.emailInput.onLogin.subscribe(() => {
				this.displayUpgradeNotice();
			});

			if (this.authService.isLoggedIn()) {
				this.displayUpgradeNotice();
			}
		}
	}

	public maybeUpdate() {
		const address = this.getInfo();
		this.errorMessage = '';

		if (this.changeCard) {
			this.updateState = 'submitted';

			// Send update when use CC.
			this.recurlyCC
				.getToken(address)
				.then((token) => {
					if ('vantiv' === token['type']) {
						this.updateBilling({ address, vantiv: token });
					} else {
						this.updateBilling({ address, card: token['id'] });
					}
				})
				.catch(() => (this.updateState = 'failed'));
		} else {
			// Send Update for address fields only.
			this.updateBilling({ address });
		}
	}

	public getInfo() {
		const address = this.contactInputs.model.getData();
		address['zip'] = address.postal_code;

		return address;
	}

	public hasCard() {
		return !!this.paymentMethod.card_type;
	}

	public fetchBillingInformation() {
		this.fetchState = 'loading';
		const accountId =
			this.organizationId ||
			this.authService.jwtHelper.getTokenVal('user_id');

		this.apiService
			.get(`/v1/wpcr/accounts/${accountId}/payments/method`, {})
			.subscribe({
				next: (paymentMethod: any) => {
					this.loadForm(paymentMethod);

					if (this.paymentMethod.last_four) {
						this.selectedMethod = this.paymentMethod.last_four;
					} else {
						this.selectedMethod = 'new';
					}

					if (!paymentMethod.zip) {
						this.changeCard = true;
					}

                    this.fetchState = 'success';
                    this.paymentReady.emit();
				},
				error: () => {
					this.fetchState = 'failed';
				},
			});
	}

	public updateBilling(params) {
		this.updateState = 'submitted';
		const accountId =
			this.organizationId ||
			this.authService.jwtHelper.getTokenVal('user_id');

		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		const url = this.apiService.formatter.getUrl(
			`/v1/wpcr/accounts/${accountId}/payments/method`
		);

		this.apiService.http.post(url, params, { headers }).subscribe({
			next: (newInfo) => {
				this.loadForm(newInfo);
				this.updateState = 'success';
				this.update.emit();
			},
			error: (response) => {
				this.errorMessage = this.apiService.getPublicError(response);
				this.updateState = 'failed';
			},
		});
	}

	private loadForm(apiBillingInfo) {
		this.paymentMethod = apiBillingInfo;
		this.paymentMethod.postal_code = this.paymentMethod.zip;

		if (this.paymentMethod.last_four) {
			this.contactInputs.model.update(this.paymentMethod);
		}
		this.contactInputs.autofillControls['state'].control.setValue(
			this.paymentMethod.state
		);
	}

	public getDiscountObject() {
		if (this.discountObject) {
			return this.discountObject;
		}
	}

	public getCouponData() {
		if (this.couponData) {
			this.hideGuarantee = true;
			return this.couponData;
		}
	}

	public isTrial() {
		if (this.getCouponData()) {
			return this.getCouponData().coupon_code.match(/-trial$/) ? true : false;
		}
		return false;
	}

	skipPurchase() {
		this.apiService.post( '/v1/guide/override', {
			coupon_code: this.couponData?.coupon_code
		} ).subscribe({
			next: (res: any) => {
				if ( res.login_token ) {
					this.router.navigate(
						[],
						{
						  relativeTo: this.route,
						  queryParams: { return: '/projects' },
						  queryParamsHandling: 'merge'
						}
					).then(()=>{
						this.authService.setLogin( res.login_token );
					});
				}
			},
			error: (err:any) => {
				console.error(err);
			},
		});
	}
}
