<mat-card class="receipt success" *ngIf="status === 'success'">
	<div class="row receipt-header">
		<div class="col s7">
			<div class="row">
				<div class="col s6 brand-logo">
					<img src="{{ this.brandingService.getThemedAssetUrl('logo2.svg') }}" />
				</div>
				<div class="col s6">
					<ul class="company-address">
						<li>BoldGrid</li>
						<li>360 N. Pacific Coast Highway</li>
						<li>Suite 1055</li>
						<li>El Segundo, CA 90245</li>
						<li>Email: support@boldgrid.com</li>
						<li>Web: www.boldgrid.com</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col s5 order-info">
			<h4 class="order-number">
				<span class="order-title">Order</span> {{ receipt.orderId }}
			</h4>
			<dl class="horizontal-dl">
				<dt>Order Date</dt>
				<dd>{{ receipt.date_iso | amDateFormat : 'LL' }}</dd>
				<dt>Total</dt>
				<dd><ng-container>$</ng-container>{{ receipt.total }}</dd>
				<ng-container *ngIf="receipt.card">
					<dt>Charge To</dt>
					<dd>{{ receipt.card }}</dd>
				</ng-container>
			</dl>
		</div>
	</div>
	<div class="receipt-body">
		<table class="sale-items">
			<thead>
				<tr>
					<th data-field="quantity">Quantity</th>
					<th data-field="name">Item Name</th>
					<th data-field="name" *ngIf="receipt.has_auto_renew">
						Renewal Date
					</th>
					<th data-field="price">Price</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let product of this.lineItems">
					<td>{{ product.quantity }}</td>
					<td>{{ product.label }}</td>
					<td *ngIf="receipt.has_auto_renew">
						<span *ngIf="product.renewal_date">{{
							product.renewal_date | amDateFormat : 'LL'
							}}</span>
					</td>
					<td>
						<ng-container>$</ng-container>{{ product.price || 0 }}
					</td>
				</tr>
			</tbody>
		</table>
		<table class="total-list">
			<tbody>
				<tr class="subtotal">
					<td>Subtotal</td>
					<td>
						<ng-container>$</ng-container>{{ receipt.sub_total }}
					</td>
				</tr>
				<tr class="discount">
					<td>Discount</td>
					<td>
						<ng-container>$</ng-container>{{ receipt.discount }}
					</td>
				</tr>
				<tr class="total">
					<td>Total</td>
					<td><ng-container>$</ng-container>{{ receipt.total }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<p class="more-info" *ngIf="receipt.has_auto_renew">
		In order to ensure you maintain access to premium features, any BoldGrid
		subscriptions are set to "Auto Renew", and the subscription will renew
		for successive periods until cancellation. Upon renewal, you authorize
		BoldGrid, to charge the standard subscription amount to the payment
		method provided by you, for as long as you choose to remain a
		subscriber.
	</p>
</mat-card>
