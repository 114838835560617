import baseConnect from '../products/connect/base';

export default {
	productKeys: ['wpc_basic'],
	licenseNames: [],
	label: 'Free Connect Key',
	name: 'free',
	color: 'secondary-accent',
	tags: ['key'],
	features: [
		...baseConnect,
		...[
			{
				label: 'WordPress Plugins',
				type: 'separator',
			},
			{
				label: 'BoldGrid Inspirations',
			},
			{
				label: 'Total Upkeep',
			},
			{
				label: 'Post and Page Builder',
			},
			{
				label: 'BoldGrid Easy SEO',
			},
			{
				label: 'Additional Features',
				type: 'separator',
			},
			{
				label: 'Free Theme Library',
			},
			{
				label: 'Free Block Library',
			},
			{
				label: 'Connect Photo Search',
			},
		],
	],
};
