import { Component, OnInit } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { ProjectService } from '../../project.service';

@Component({
	templateUrl: './domain.component.html',
	styleUrls: ['./domain.component.scss'],
})
export class DomainComponent implements OnInit {
	public changeDomain = true;
	public state = 'loading';
	public ready = false;
	public isOverriden = false;
	public hasChangedDomain = false;

	constructor(
		public apiService: ApiService,
		public projectService: ProjectService
	) {}

	ngOnInit(): void {
		if (this.projectService.environmentState === 'pending') {
			this.state = 'failed';
		} else {
			this.projectService.playbookStatus.subscribe((msg: any) => {
				this.state =
					msg === 'playbook-running' || msg === 'playbook-overridden'
						? 'blocked'
						: this.state;
				if (
					this.state === 'blocked' &&
					(msg === 'playbook-not-running' || msg === 'complete')
				) {
					this.state = 'success';
				}
			});
		}
		this.determineHasChangedDomain();
	}

	doChangeDomain(): void {
		this.changeDomain = true;
	}

	determineHasChangedDomain(): void {
		if (this.projectService.environment.fields.domain === undefined) {
			this.hasChangedDomain = false;
			return;
		}
		if (this.projectService.environment.fields.hostname !== this.projectService.environment.fields.domain) {
			this.hasChangedDomain = true;
			return;
		} else {
			this.hasChangedDomain = false;
			return;
		}
	}
}
