<payment-form [hidden]="paymentForm.state === 'success'" #paymentForm
	(paymentBack)="this.projectCreateService.getSelections().projectId !== '' ? this.projectCreateService.router.navigate([ '/projects', this.projectCreateService.getSelections().projectId ]) : projectCreateService.back()"
	[packages]="this.packages" [term]="this.projectCreateService.getSelections().term"
	discountObject="{{ this.projectCreateService.getDiscountObject() }}" [couponData]="this.getCouponData()"
	[organizationId]="this.projectCreateService.getSelections().organizationId"
	[projectId]="this.projectCreateService.getSelections().projectId"
	(setProjectId)="this.projectCreateService.updateSelections('projectId', $event);" [forceLogin]="this.forceLogin"
	[loginModalTitle]="this.loginModalTitle" [loginModalSubTitle]="this.loginModalSubTitle"
	[minimalForm]="this.minimalForm" [excludedInputs]="this.excludedInputs"></payment-form>
<div *ngIf="paymentForm.state === 'success'"
	class="ctw-mx-auto ctw-max-w-xl animate__animated animate__zoomInUp ctw-text-center">
	<img class="ctw-mx-auto ctw-mb-10 ctw-w-40"
		src="https://inmotionhosting.github.io/static-assets/illustrations/small/onboard-quickly.svg" />
	<h2 *ngIf="this.isTrial()">Trial Unlocked</h2>
	<ng-container *ngIf="!this.isTrial()">
		<h2>Payment Successful</h2>
		<p>
			Next, we need to setup your project.
		</p>
		<p>Your receipt will be available in the application when you're done.</p>
	</ng-container>
	<div class="ctw-text-center ctw-mt-10">
		<button mat-raised-button color="primary" (click)="this.projectCreateService.startPostPurchase()">
			Let's Go! <mat-icon class="next">navigate_next</mat-icon>
		</button>
	</div>
</div>
