import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MembershipModule } from '../membership.module';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { AddMemberDialogComponent } from '../dialogs/add-member-dialog/add-member-dialog.component';
import { DeleteMemberDialogComponent } from '../dialogs/delete-member-dialog/delete-member-dialog.component';
import { PermissionsdialogComponent } from '../dialogs/permissionsdialog/permissionsdialog.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService, ConfigurationService } from '../../core';
import { AuthService } from '../../authentication/services';

@Component({
	selector: 'central-membership-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
	@Input() resourceType: string;
	@Input() resourceId: string;
	@Input() resourceTitle: string;
	@Input() resourceOwner: string;
	@Input() public isReadOnly: boolean;
	@Output() ready = new EventEmitter<any>();

	public fetchStatus = 'loading';
	public displayedColumns = ['name', 'role', 'options'];
	public state = '';
	public members;
	public currentUserRole = 'member';
	public feature2fa = false;
	public isOwner = false;

	constructor(
		public authService: AuthService,
		public apiService: ApiService,
		public configurationService: ConfigurationService,
		public dialog: MatDialog,
		public snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.getMembers();
		this.isOwner = this.authService.getAccountId() === this.resourceId;
		if (this.configurationService.config.features?.twoFactor) {
			this.feature2fa =
				this.configurationService.config.features?.twoFactor;
			this.displayedColumns = this.isOwner
				? ['name', 'options']
				: ['name', 'role', 'has2fa', 'options'];
		}
	}

	canChangeAccess(member) {
		let canChangeAccess = true;

		if (this.isSelf(member)) {
			canChangeAccess = false;
		} else if (this.isHigherAccess(member)) {
			canChangeAccess = false;
		} else if (this.isPersonalOwner(member)) {
			canChangeAccess = false;
		}

		return canChangeAccess;
	}

	isHigherAccess(member) {
		const canAccess = {
			owner: ['owner', 'maintainer', 'member', 'orgSupport', 'orgBilling'],
			maintainer: ['member', 'orgSupport', 'orgBilling'],
			member: [],
			orgSupport: [],
			orgBilling: [],
		};

		let isHigherAccess = true;
		if (
			canAccess[this.currentUserRole].find((val) => val === member.role)
		) {
			isHigherAccess = false;
		}

		return isHigherAccess;
	}

	isSelf(member) {
		return member.account?.email === this.authService.getEmail();
	}

	isPersonalOwner(member) {
		return (
			member.account?.account_id &&
			member.account?.account_id === this.resourceOwner
		);
	}

	getMembers() {
		this.apiService
			.get(
				`/v1/${this.resourceType}/` + this.resourceId + '/memberships',
				{}
			)
			.subscribe({
				next: (members) => {
					this.members = members;
					this.members.forEach(this.setInviteStatus);
					this.setCurrentRole();
					this.fetchStatus = 'success';
					this.ready.emit(this.fetchStatus);
				},
				error: () => {
					this.fetchStatus = 'failed';
					this.ready.emit(this.fetchStatus);
				},
			});
	}

	setCurrentRole() {
		const email = this.authService.getEmail();
		const currentAccess = this.members.find(
			(val) => val.account?.email === email
		);

		this.currentUserRole = currentAccess ? currentAccess.role : 'member';
	}

	setInviteStatus(member) {
		if (member['invite_expired_at']) {
			const expirationDate = new Date(member['invite_expired_at']);
			const now = new Date();
			member['invite_status'] =
				expirationDate < now
					? { state: 'expired' }
					: { state: 'pending', expires: expirationDate };
		}
	}

	openDeleteDialog(membership) {
		const dialogRef = this.dialog.open(DeleteMemberDialogComponent, {
			width: '500px',
			data: {
				resourceType: this.resourceType,
				resourceId: this.resourceId,
				membership,
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.getMembers();

					this.snackBar.open('Member Removed', '', {
						duration: 4000,
					});
				}
			},
		});
	}

	getRoleLabel(role: string) {
		return (
			this.configurationService.config.groupPermissions[role]?.label || ''
		);
	}

	get2faLabel(has2fa: boolean) {
		if (has2fa) {
			return 'Enabled';
		} else {
			return 'Disabled';
		}
	}

	permissionsDialog(membership) {
		const dialogRef = this.dialog.open(PermissionsdialogComponent, {
			width: '500px',
			data: {
				resourceType: this.resourceType,
				resourceId: this.resourceId,
				membership,
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.getMembers();

					this.snackBar.open('Permissions Updated', '', {
						duration: 4000,
					});
				}
			},
		});
	}

	openAddDialog(membership?: any) {
		const dialogRef = this.dialog.open(AddMemberDialogComponent, {
			width: '500px',
			data: {
				resourceType: this.resourceType,
				resourceId: this.resourceId,
				email: membership?.['invite_email'],
				currentRole: this.currentUserRole,
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.getMembers();

					this.snackBar.open(
						'Invitation e-mail sent successfully!',
						'',
						{
							duration: 4000,
						}
					);
				}
			},
		});
	}

	public hasEditAccess() {
		const validRoles = ['owner', 'maintainer'];
		return validRoles.includes(this.currentUserRole);
	}
}
